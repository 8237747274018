import styled, { keyframes } from "styled-components";
import theme from "../../Themes/light";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ContainerModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  top: 0;
  right: 0;
  z-index: 300;
  //remove scroll
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  > .fundo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    position: fixed;
    z-index: 300;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    animation: ${opacityAnimation} 500ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
  }

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes comeFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .divAuxiliar {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 300;
    pointer-events: none;

    .fundo-branco-container {
      pointer-events: none;
      padding: 50px 0;
      flex-direction: column;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      min-height: 100vh;
      width: 100%;

      .fundo-branco {
        pointer-events: all;
        display: flex;
        flex-wrap: wrap;
        opacity: 1;
        max-width: 95%;

        .children {
          position: relative;
          pointer-events: all;
          opacity: 1;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
          border-radius: 40px;
          background-color: ${(props) =>
            props.theme.colors
              .modal}; // Assuming $modal is defined in "colors.scss"
          width: 100%;
          color: ${(props) => props.theme.colors.brandSecondary};
        }

        @media (max-width: 1024px) {
          .children {
            border-radius: 30px;
          }
        }
      }
    }
  }
`;
