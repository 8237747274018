import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';
import { Image } from '../MultipleImageUpload';

const errorsLabels = {
    PROPORCAO_INVALIDA: 'Proporção errada',
    TAMANHO_INVALIDO: 'Tamanho inválido',
    FORMATO_INVALIDO: 'Formato inválido',
    IMAGEM_MUITO_GRANDE: 'Imagem muito grande',
    PODE_CROPAR: 'Imagem pode ser cortada',
    ERROR_FILE_READER: 'Erro ao ler arquivo',
    ERRO_IMPORTACAO: 'Erro ao importar imagem',
}

const UploadStatusComponentContainer = styled.div<{theme: ThemeType, image: Image}>`
    padding: 1rem 0 1rem 0;
    border-bottom: 0.1rem solid ${({theme}) => theme.colors.placeholder};
    .main-container{
        width: 100%;
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem 0 1rem 0;
        .icone-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 5rem;
            .icone{
                cursor: ${({image}) => image.status !== 'erro' ? 'pointer' : 'default'};
                width: 5rem;
                height: 5rem;
            }
        }
        .name-progress-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            width: calc(100% - 14rem);
            .name{
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 2.5rem;
                color: ${({theme}) => theme.colors.brandSecondary};
            }
        }
        .status{
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.1rem solid ${({theme, image}) => {
                if(image.status === 'erro'){
                    return theme.colors.brandDanger;
                } else {
                    return theme.colors.brandPrimary;
                }
            }};
            border-radius: 50%;
            position: relative;
            &:hover{
                &:after{
                    content: "${({image}) => (image.error && image.status !== 'sucesso') && errorsLabels[image.error]}";
                    white-space: nowrap;
                    position: absolute;
                    bottom: calc(100% + 1rem);
                    padding: 1rem;
                    border-radius: 0.5rem;
                    background-color: ${({theme}) => theme.colors.elements};
                    color: ${({theme}) => theme.colors.brandSecondary};
                    display: ${({image}) => (image.error && image.status !== 'sucesso') ? 'block' : 'none'};
                    font-size: 2.5rem;
                }
                &:before{
                    position: absolute;
                    content: '';
                    display: ${({image}) => (image.error && image.status !== 'sucesso') ? 'block' : 'none'};
                    width: 2rem;
                    height: 2rem;
                    background-color: ${({theme}) => theme.colors.elements};
                    bottom: calc(100% - 1rem + 1.41421356237rem); // 1.41421356237 = raiz de 2
                    transform: rotate(45deg);
                }
            }
            svg{
                width: 2rem;
                height: 2rem;
            }
            .icon{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2.5rem;
                color: ${({theme, image}) => {
                if(image.status === 'erro'){
                    return theme.colors.brandDanger;
                } else {
                    return theme.colors.brandPrimary;
                }
            }};
    
            }
        }
    }
    .acoes{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .produtos{
            color: ${({theme}) => theme.colors.brandSecondary};
            font-size: 2.3rem;
        }
        .botoes{
            display: flex;
            gap: 2rem;
            font-size: 2.3rem;
            .editar, .remover{
                cursor: pointer;
            }
            .editar{
                color: ${({theme}) => theme.colors.brandSecondary};
            }
            .remover{
                color: ${({theme}) => theme.colors.brandDanger};
            }
        }
    }
`;

export { 
    UploadStatusComponentContainer,
};