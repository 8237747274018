import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { DropdownContainer } from "./styles";

interface DropdownProps {
  active: boolean;
  duration?: number;
  dropDownClassName?: string;
  className?: string;
  animated?: boolean;
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({
  active,
  children,
  duration = 500,
  className,
  dropDownClassName,
  animated,
}) => {
  const [height, setHeight] = useState({
    height: active ? "auto" : "0px",
    closing: false,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentlyClosing, setCurrentlyClosing] = useState(false);

  useEffect(() => {
    // Ensure ref is current
    if (containerRef.current) {
      if (active) {
        setCurrentlyClosing(true);
        // Opening
        setHeight({
          height: containerRef.current.scrollHeight + "px",
          closing: false,
        });
        // Reset to auto after transition ends
        const timeoutId = setTimeout(() => {
          setHeight({ height: "auto", closing: false });
        }, duration); // Match the transition duration
        return () => clearTimeout(timeoutId);
      } else {
        setHeight({
          height: containerRef.current.clientHeight + "px",
          closing: true,
        });
      }
    }
  }, [active]);

  useEffect(() => {
    if (height.closing && containerRef.current?.scrollHeight !== 0) {
      setHeight({ height: "0px", closing: false });
      const timeoutId = setTimeout(() => {
        setCurrentlyClosing(false);
      }, duration);

    }
  }, [height]);

  return (
    <DropdownContainer
      duration={duration}
      height={height.height}
      className={`${dropDownClassName ? dropDownClassName : ""}`}
    >
      <div
        ref={containerRef}
        className={`children-container ${className ? className : ""}`}
      >
        {(currentlyClosing || active) && (children)}
      </div>
    </DropdownContainer>
  );
};

export default Dropdown;
