import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const InformacoesERPContainer = styled.div<{theme: ThemeType}>`
    padding: 5rem 10rem 30rem 10rem;
    .filtros{
      display: flex;
      gap: 2rem;
      margin-bottom: 2rem;
    }
    .footer-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 2rem;
      row-gap: 2rem;
      flex-wrap: wrap;
      .selecionados{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
        color: ${({theme}) => theme.colors.brandSecondary};
      }
      @media (max-width: 615px) {
        justify-content: center;
        .selecionados{
          justify-content: center;
        }
      }
    }
    .informacoes{
      display: flex;
      font-size: 3rem;
      column-gap: 5rem;
      row-gap: 2rem;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;
      span{
        color: ${({theme}) => theme.colors.brandSecondary};
      }
    }
    @media (max-width: 840px) {
      .filtros{
        flex-wrap: wrap;
      }
    }

    @media (max-width: 1024px) {
    padding: 5rem 3rem 25rem 3rem;
  }
`;

export {
  InformacoesERPContainer
};

