import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';


const transitionTime = "1s";
const SkeletonCadastroProdutoContainer = styled.div<{theme: ThemeType}>`
width: 100%;
  .grid1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 10rem 7rem 12rem 7rem 7rem;
    row-gap: 8rem;
    column-gap: 5rem;
    width: 100%;
    grid-template-areas:
      "zika0 zika0 zika0 zika0"
      "zika1 zika1 zika1 zika1"
      "zika2 zika2 zika2 zika2"
      "zika3 zika3 zika4 zika4"
      "zika5 zika5 zika5 zika-1";
  }

  .item-skeleton {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 16px;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.1s);
    @keyframes pulsate {
      0% {
        background-color: ${(props) => props.theme.colors.skeleton1};
      }

      100% {
        background-color: ${(props) => props.theme.colors.skeleton2};
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 2rem 2rem;
    grid-template-columns: 1fr;
  }
`;

export { 
    SkeletonCadastroProdutoContainer,
};