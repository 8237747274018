import React from 'react';
import './styles';
import { PopoverAbsoluteContainer } from './styles';
import { useTheme } from '../../contexts/Theme/ThemeContext';

interface PopoverAbsoluteProps {
    mensagem: string;
    width: string;
}

const PopoverAbsolute: React.FC<PopoverAbsoluteProps> = ({width, mensagem}) => {

   const {theme} = useTheme();

    return (
        <PopoverAbsoluteContainer theme={theme} width={width} mensagem={mensagem}>
        </PopoverAbsoluteContainer >
    )
}

export default PopoverAbsolute;