import { max } from 'moment';
import styled from "styled-components";
import theme from "../../../Themes/light";

export const ContainerPedido = styled.div`
  padding: 10rem 10rem 20rem 10rem;

  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem 0;
    justify-content: space-between;
    width: 90%;
    gap: 2rem;

    .select-pedido-unico {
      width: 43rem;

      .view-select-pedidos {
        .select {
          transition: 0.3s;
          background-color: transparent;
          border-color: transparent;

          &:hover {
            border-color: transparent;
          }
        }

        .ativo {
          background-color: ${(props) => props.theme.colors.white};
          border-color: ${(props) => props.theme.colors.brandPrimary};
        }
      }
    }
  }
  .flex-titulo{
    padding: 2rem 5%;
    width: 100%;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 40rem;
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
    height: 8rem;

    .pix-div {
      display: flex;
      gap: 1rem;
      align-items: center;

      > .botao {
        background-color: ${(props) => props.theme.colors.elements};
        color: ${(props) => props.theme.colors.brandSecondary};
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:focus {
          opacity: 0.8 !important;
        }
      }

      > .icone {
        cursor: pointer;
        width: 3.5rem;
      }
    }
    &.center{
      justify-content: center;
      align-items: center;
    }
  }

  #form-gravar-obs {
    width: 100%;

    .obs-gravar {
      padding: 0 min(7vw, 15rem);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      column-gap: 8rem;
      row-gap: 2rem;
      justify-content: flex-end;
      margin-bottom: 2rem;

      .input-div {
        flex-grow: 1;

        input {
          background-color: ${(props) => props.theme.colors.background1};
          color: ${(props) => props.theme.colors.brandSecondary};
        }
      }
    }
  }

  .container-branco {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 24px;
    margin-top: 5rem;
    padding: 2rem 0;
  }

  .titulo-pedido {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    font-weight: normal;
  }

  .subtitulo-pedido {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    font-weight: bold;

    span {
      font-size: 3.5rem;
    }

    > .bolinha {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 1rem;
      z-index: 1;
    }
  }

  .linha {
    width: 100%;
    height: 2px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.elements};
  }

  .linha-menor {
    width: 70%;
    height: 2px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.elements};
  }

  .flex-botoes-pedido {
    display: flex;
    padding-top: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .flex-esquerda {
      gap: 2rem;
      display: flex;
      flex-wrap: wrap;
    }

    .botao-bottom {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      white-space: normal;
      &:not(:first-child) {
        color: ${(props) => props.theme.colors.brandSecondary};
        background-color: ${(props) => props.theme.colors.elements};
        font-weight: normal;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 5rem 3rem 30rem 3rem;
  }

  @media (max-width: 715px) {
    .flex-botoes-pedido {
      width: 100%;
      gap: 1rem;

      .flex-esquerda {
        display: contents;
      }

      .botao-bottom {
        flex-grow: 1;
        min-width: 10rem;
      }
    }
  }

  @media print {
    display: none;
  }

  @media screen {
    display: block;
  }
`;


export const ImagemZoom = styled.div`


  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
`;