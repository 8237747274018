import moment from "moment";
import PopoverAbsolute from "../components/PopoverAbsolute/PopoverAbsolute";

export function cpfMask(value: string) {
  const digitsOnly = value.replace(/\D+/g, ""); // remove non-digits from the input string
  if (digitsOnly.length === 0) {
    return "";
  }
  let formatted = digitsOnly;
  if (digitsOnly.length > 3) {
    // add period after the first three digits
    formatted = `${digitsOnly.slice(0, 3)}.${digitsOnly.slice(3)}`;
  }
  if (digitsOnly.length > 6) {
    // add period after the second three digits
    formatted = `${formatted.slice(0, 7)}.${digitsOnly.slice(6)}`;
  }
  if (digitsOnly.length > 9) {
    // add hyphen after the first nine digits
    formatted = `${formatted.slice(0, 11)}-${digitsOnly.slice(9)}`;
  }
  return formatted.slice(0, 14); // limit the formatted string to 14 characters (11 digits + 2 periods + 1 hyphen)
}

export function cnpjMask(value: string) {
  const digitsOnly = value.replace(/\D+/g, ""); // remove non-digits from the input string
  if (digitsOnly.length === 0) {
    return "";
  }
  let formatted = digitsOnly;
  if (digitsOnly.length > 2) {
    // add period after the first two digits
    formatted = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2)}`;
  }
  if (digitsOnly.length > 5) {
    // add period after the next three digits
    formatted = `${formatted.slice(0, 6)}.${digitsOnly.slice(5)}`;
  }
  if (digitsOnly.length > 8) {
    // add slash after the next three digits
    formatted = `${formatted.slice(0, 10)}/${digitsOnly.slice(8)}`;
  }
  if (digitsOnly.length > 12) {
    // add hyphen after the next four digits
    formatted = `${formatted.slice(0, 15)}-${digitsOnly.slice(12)}`;
  }
  return formatted.slice(0, 18); // limit the formatted string to 18 characters (14 digits + 2 periods + 1 slash + 1 hyphen)
}

export const phoneMask = (value: string) => {
  const digitsOnly = value.replace(/\D+/g, "");
  if (digitsOnly.length === 0) {
    return "";
  }
  const formatted = digitsOnly
    .replace(/^(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})$/, "$1$2$3$4")
    .replace(/^(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4,5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
  return formatted;
};

export const currencyMask = (value: string, currency: string = "R$") => {
  if (currency == "") {
    currency = "R$";
  }
  const digitsOnly = value.replace(/\D+/g, "").substring(0, 8);

  if (digitsOnly.length === 0) {
    return "";
  }

  if (digitsOnly === "0") {
    return `${currency} 00,00`;
  }

  let formatted = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD", // Defina o padrão para USD, mas será substituído
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number.parseFloat(digitsOnly) / 100);

  // Substitua o símbolo e o código da moeda com os valores fornecidos
  formatted = formatted.replace("US$", currency);

  return formatted;
};

export const cepMask = (value: string) => {
  const digitsOnly = value.replace(/\D+/g, ""); // remove non-digits from the input string
  if (digitsOnly.length === 0) {
    return "";
  }
  let formatted = digitsOnly;
  if (digitsOnly.length > 5) {
    // add hyphen after the first five digits
    formatted = `${digitsOnly.slice(0, 5)}-${digitsOnly.slice(5)}`;
  }
  return formatted.slice(0, 9); // limit the formatted string to 9 characters
};

export const hourMask = (value: string) => {
  const digitsOnly = value.replace(/\D+/g, "");
  if (digitsOnly.length === 0) {
    return "";
  }
  let formatted = digitsOnly;
  if (digitsOnly.length > 2) {
    formatted = `${digitsOnly.slice(0, 2)}:${digitsOnly.slice(2)}`;
  }
  return formatted.slice(0, 5);
};

export const percentageMask = (value: string) => {
  const digitsOnly = value.replace(/\D+/g, "");

  if (digitsOnly.length === 0) {
    return "";
  }
  let formatted = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(digitsOnly) / 100);

  formatted = `${formatted}%`;
  return formatted;
};

export const cupomMask = (value: string) => {
  return value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
};

export const stringNumber = (value: string) => {
  if (value == null || value === "") {
    return "0";
  }

  const numeros = value.match(/\d+/g);

  if (numeros === null) {
    return "0";
  }

  const numeroFinal = numeros.join("");

  if (numeroFinal === "0") {
    return "0";
  }

  return numeroFinal.replace(/^0+/, "");
};

export const noSpecialCharacters = (value: string) => {
  if (value == null || value === "") {
    return "";
  }
  return value.replace(/[^\w\sÀ-ÖØ-öø-ÿ]/g, "");
};

export const formatCurrencyInput = (value: string): string => {
  if (value == null || value === "") {
    return "";
  }

  // Mantenha apenas letras, espaços e símbolos de moeda
  const sanitizedValue = value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s$€£₹,.;!?()-]/g, "");

  // Remova espaços extras antes ou após símbolos de moeda
  const trimmedValue = sanitizedValue.replace(/\s*([$€£₹])\s*/g, "$1");

  return trimmedValue;
};

export const instagramMask = (value: string) => {
  if (value.length > 0 && value[0] !== "@") {
    return "@" + value;
  } else {
    return value;
  }
};

export const hexadecimalMask = (value: string): string => {
  const hexa = value.replace(/[^0-9A-Fa-f]/g, "").toUpperCase(); // limpar caracteres inválidos
  if (hexa.length === 0) {
    return "";
  } else {
    return `#${hexa.slice(0, 6)}`;
  }
};

export const ufMask = (value: string) => {
  let lettersOnly = value.replace(/[^a-zA-ZçÇ]/g, "");
  if (lettersOnly.length > 0 && lettersOnly.length < 3) {
    return lettersOnly.toUpperCase();
  } else {
    return lettersOnly;
  }
};

export function dateMask(value: string, format?: string) {
  if (!value) return "";
  return moment(value).format(format ? format : "DD/MM/YYYY");
}

export function situacaoErpMask(value: string) {
  if (!value) return "";
  const labels = {
    "-1": {
      color: "#FF0000",
      label: "Erro",
      width: "8rem",
    },
    "0": {
      color: "#FFD700",
      label: "Pendente",
      width: "12rem",
    },
    "1": {
      color: "#00FF00",
      label: "Sincronizado",
      width: "15rem",
    },
  };

  return (
    <td
      className="stickyTD"
      style={{ display: "flex", width: "100%", justifyContent: "center" }}
    >
      <div className="pai-bolinha">
        <div
          className="bolinha"
          style={{ backgroundColor: labels[value].color }}
        ></div>
        <PopoverAbsolute
          mensagem={labels[value].label}
          width={labels[value].width}
        />
      </div>
    </td>
  );
}
