import React from "react";
import { ReactComponent as IconeDominio } from "../../assets/newImages/compartilhar/dominio.svg";

interface ConfigurarDominioProps {
  dominio: any;
  empresa: any;
}

const ConfigurarDominio: React.FC<ConfigurarDominioProps> = ({
  dominio,
  empresa,
}) => {
  let dominio_configurado = verificaDominio();

  function verificaDominio() {
    if (dominio.includes("conectavenda") || (!dominio.includes("app") && !dominio.includes("localhost") && !dominio.includes("app")) ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="baixo">
      <div className="texto">
        {dominio_configurado
          ? "Domínio configurado"
          : "Configure seu domínio próprio"}
        <br />
        <span>{dominio}</span>
        <div className="configuracoes">
          <a
            href={`https://api.whatsapp.com/send?phone=555434434563&text=Ol%C3%A1,+gostaria+de+configurar+meu+dom%C3%ADnio+pr%C3%B3prio+no+Conecta+Venda.+Meu+usu%C3%A1rio+%C3%A9:+${empresa?.usuario}+da+empresa:+${empresa?.empresa}.`}
            target="_blank"
            className="botao"
          >
            {
                dominio_configurado
                    ? "Alterar domínio"
                    : "Saber mais informações"
            }
          </a>
        </div>
      </div>
      <IconeDominio />
    </div>
  );
};

export default ConfigurarDominio;
