import { PagSeguroContext } from "./PagSeguroContext";
import { useState, useEffect } from "react";
import { pagSeguroApi } from "../../hooks/pagSeguroApi";

export const PagSeguroProvider = ({ children }: { children: JSX.Element }) => {
  const api = pagSeguroApi();

  const [linkAutorizacao, setLinkAutorizacao] = useState<string>("");
  const [ativado, setAtivado] = useState<boolean>(false);

  useEffect(() => {
    async function init() {
      verificarAtivacao();
    }

    init();
  }, []);

  const verificarAtivacao = async () => {
    const { linkAutorizacao, ativado } =
      await pagSeguroApi().getLinkAutorizacao();

    setLinkAutorizacao(linkAutorizacao);
    setAtivado(ativado);

    return {
      linkAutorizacao: linkAutorizacao as string,
      ativado: ativado as boolean,
    };
  };

  return (
    <PagSeguroContext.Provider
      value={{ linkAutorizacao, ativado, verificarAtivacao }}
    >
      {children}
    </PagSeguroContext.Provider>
  );
};
