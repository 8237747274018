import React, { useEffect, useRef } from "react";
import { ContainerToggle, Switch } from "./styles";
import PopoverForm from "../PopoverForm/PopoverForm";
import { useTheme } from "../../contexts/Theme/ThemeContext";
interface ToggleProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
  required?: boolean;
  isValid?: boolean | ((value: boolean) => boolean);
  invalidText?: string;
  ignoreValidations?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  value,
  onChange,
  disabled,
  required,
  isValid,
  invalidText = "Selecione uma opção",
  ignoreValidations,
}) => {
  const { theme } = useTheme();

  const [errorMessage, setErrorMessage] = React.useState(invalidText);
  const input = useRef(null);

  useEffect(() => {
    const inputElement = input.current! as HTMLInputElement;

    if (isValid !== null && isValid !== undefined) {
      if (
        (typeof isValid === "function"
          ? isValid(inputElement.checked)
          : isValid) ||
        ignoreValidations
      ) {
        inputElement.setCustomValidity("");
        setErrorMessage("");
      } else {
        inputElement.setCustomValidity(
          invalidText ? invalidText : "Preencha este campo",
        );
        setErrorMessage(invalidText ? invalidText : "Preencha este campo");
      }
    }
  }, [isValid, value, ignoreValidations]);

  const handleChange = (e) => {
    const inputElement = e.target;
    const newValue = inputElement.checked;
    
    if (!disabled) {
      onChange(newValue);
    }
    
    e.stopPropagation();
  };

  return (
    <>
      <ContainerToggle style={{ opacity: disabled ? "0.5" : "1" }}>
        <Switch theme={theme}>
          <PopoverForm mensagem={invalidText} />
          <input
            ref={input}
            disabled={disabled}
            type="checkbox"
            onChange={handleChange}
            checked={value}
            required={ignoreValidations ? false : required}
          />
          <span className="slider"></span>
        </Switch>
      </ContainerToggle>
    </>
  );
};
