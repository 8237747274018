import React, { useEffect } from "react";
import "./styles";
import { ModosImportacaoContainer } from "./styles";
import { produtosApi } from "../../../hooks/produtosApi";
import { Link, useParams } from "react-router-dom";
import ImportarImagens from "../ImportarImagens/ImportarImagens";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface ModosImportacaoProps {}

const ModosImportacao: React.FC<ModosImportacaoProps> = ({}) => {
  const { theme } = useTheme();

  const ProdutosApi = produtosApi();
  const [loading, setLoading] = React.useState(false);
  const [modos, setModos] = React.useState<
    { id: string; titulo: string; descricao: string }[]
  >([]);
  const [modoAtivo, setModoAtivo] = React.useState<{
    id: string;
    titulo: string;
    descricao: string;
  } | null>(null);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const init = async () => {
      const response = await ProdutosApi.getModosImportacao();
      setModos(response);
    };

    init();
  }, []);

  useEffect(() => {
    if (id) {
      const modo = modos.find((modo) => modo.id === id);
      if (modo) {
        setModoAtivo(modo);
      }
    } else {
      setModoAtivo(null);
    }
  }, [id, modos]);

  return (
    <ModosImportacaoContainer theme={theme}>
      <div className="modos-container">
        <div className={`tipo ${!modoAtivo && "ativo"}`}>
          {modos.map((modo) => (
            <Link
              to={`/painel/produtos/importar-imagens/${modo.id}`}
              className={`modo-container 
                        ${!modoAtivo && "ativo"}
                        ${modoAtivo?.id === modo.id && "ativo-modo"}`}
              onClick={() => {
                setModoAtivo(modo);
              }}
            >
              <div className="titulo">{modo.titulo}</div>
              <div
                className="descricao"
                dangerouslySetInnerHTML={{ __html: modo.descricao }}
              ></div>
            </Link>
          ))}
        </div>
        {modoAtivo && (
          <>
            <div className="tipo ativo">
              <div className={`modo-container ativo`}>
                <div className="titulo">{modoAtivo.titulo}</div>
                <div
                  className="descricao"
                  dangerouslySetInnerHTML={{ __html: modoAtivo.descricao }}
                ></div>
              </div>
              <ImportarImagens modo={modoAtivo}></ImportarImagens>
            </div>
          </>
        )}
      </div>
    </ModosImportacaoContainer>
  );
};

export default ModosImportacao;
