import React, { useContext, useEffect, useState } from "react";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import ConfigurarDominio from "../../../pages/Compartilhar/ConfigurarDominio";
import { ContainerCompartilhar } from "../../../pages/Compartilhar/styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

export const VerificacoesDeDominio: React.FC = () => {
  const { empresa } = useContext(EmpresaContext);

  const [url_produtos, setUrlProdutos] = useState<string>("");
  function removeAfterLastSlash(str) {
    const lastSlashIndex = str.lastIndexOf("/");
    return lastSlashIndex !== -1 ? str.substring(0, lastSlashIndex) : str;
  }

  useEffect(() => {
    if (empresa?.dominio_https) {
      const dominio = removeAfterLastSlash(empresa?.dominio_https);
      setUrlProdutos(`${dominio}/produtos.xml`);
    }
  }, [empresa]);

  const { theme } = useTheme();

  return (
    <ContainerCompartilhar theme={theme} isGrid={false}>
      <div className="conteudo">
        <ConfigurarDominio dominio={empresa?.dominio_https} empresa={empresa} />
      </div>
      <div
        className="url"
      >
        <div className="titulo">XML de produtos</div>
        <a href={url_produtos} target="_blank">
          {url_produtos}
        </a>
      </div>
    </ContainerCompartilhar>
  );
};
