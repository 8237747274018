import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

const ImportarImagensContainer = styled.div<{ theme: ThemeType }>`
  width: 100%;
  padding: 5rem 0rem 25rem 0rem;
  text-align: left;

  .subtitulo {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    padding-bottom: 2rem;
  }

  .imagens {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    gap: 2rem;
  }
  .botoes-container {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: flex-end;
    .cinza {
      background-color: ${(props) => props.theme.colors.elements};
      font-weight: normal;
      color: ${(props) => props.theme.colors.brandSecondary};
    }
  }
  @media (max-width: 1024px) {
    padding: 5rem 0 23rem 0;
    .botoes-container {
      button {
        flex-grow: 1;
      }
    }
  }
`;

export { ImportarImagensContainer };
