import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ContainerFacebookPixel } from "./styles";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { stringNumber } from "../../../services/Masks";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";

export const FacebookPixel: React.FC = () => {
  const { theme } = useTheme();
  let contentValueFacebook = "tpv3500ekojh85gahlygv8hadjn4vj";

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  function getContentValue(metaTag) {
    const regex = /content="([^"]*)"/;
    const match = metaTag.match(regex);
    return match ? match[1] : null;
  }

  function extractFacebookPixelId(scriptString) {
    const match = scriptString.match(/fbq\('init',\s*'(\d+)'\)/);
    return match ? match[1] : null;
  }

  useEffect(() => {
    const init = async () => {
      if (configuracoes.facebook_dominio) {
        const contentValue = getContentValue(configuracoes.facebook_dominio);
        if (contentValue) {
          setConfiguracoes({
            ...configuracoes,
            verificacoes_de_dominio: {
              ...configuracoes.verificacoes_de_dominio,
              facebook: contentValue,
            },
          });
        }
      }
      if (configuracoes.facebook_pixel) {
        const facebookPixelId = extractFacebookPixelId(
          configuracoes.facebook_pixel
        );
        if (facebookPixelId) {
          setConfiguracoes({
            ...configuracoes,
            dados_facebook_pixel: {
              ...configuracoes.dados_facebook_pixel,
              id: facebookPixelId,
            },
          });
        }
      }
    };

    init();
  }, [configuracoes]);

  return (
    <ContainerFacebookPixel theme={theme}>
      <div className="box">
        <div className="input">
          <Input
            type="text"
            label="Verificação de Domínio"
            value={configuracoes.verificacoes_de_dominio.facebook}
            maxLength={30}
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                verificacoes_de_dominio: {
                  ...configuracoes.verificacoes_de_dominio,
                  facebook: newValue,
                },
              });
            }}
            placeholder="tpv3500ekojh85gahlygv8hadjn4vj"
          />
        </div>
        <div className="texto-input">
          Insira o content do código conforme destaque no exemplo:
          <br />
          {`<meta name="facebook-domain-verification" content="`}
          <strong>{contentValueFacebook}</strong>
          {`" />`}
        </div>
        <div className="input">
          <Input
            type="text"
            label="ID do Facebook Pixel"
            value={configuracoes.dados_facebook_pixel.id}
            maxLength={16}
            mask={{ mask: stringNumber }}
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_facebook_pixel: {
                  ...configuracoes.dados_facebook_pixel,
                  id: newValue,
                },
              });
            }}
            placeholder="Insira o código do Facebook Pixel"
          />
        </div>
        <div className="input">
          <Input
            type="text"
            label="Access Token"
            value={configuracoes.dados_facebook_pixel.token}
            maxLength={255}
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_facebook_pixel: {
                  ...configuracoes.dados_facebook_pixel,
                  token: newValue,
                },
              });
            }}
            placeholder="Insira o código do Access Token"
          />
        </div>
      </div>
    </ContainerFacebookPixel>
  );
};
