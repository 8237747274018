import { api } from "./useApi";

export const blingApi = () => ({
    verificarAutenticacao: async () => {
        const response = await api.post("/bling/verificar_autenticacao");
        return response.data;
    },

    buscarDepositos: async () => {
        const response = await api.get("/bling/buscar_depositos");
        return response.data;
    },
});
