import { AxiosProgressEvent } from "axios";
import { Produto } from "../components/Upload/MultipleImageUpload";
import { api } from "./useApi";

export const produtosApi = () => ({
  getProdutos: async (apenas_ativos: boolean, id_tabela?: string | number) => {
    let response = await api.post(
      "/produto/todos",
      { apenas_ativos: apenas_ativos, id_tabela: id_tabela },
    );
    return response.data;
  },
  getCategorias: async () => {
    let response = await api.post("/pasta/listar");
    return response.data;
  },

  importarProdutos: async (
    file: string,
    id_tabela: string,
    deletar_produtos: boolean,
  ) => {
    let response = await api.post("/produto/importar", {
      file: file,
      id_tabela: id_tabela,
      deletar_produtos: deletar_produtos,
    });
    return response.data;
  },

  importarProdutosEmMassa: async (
    produtos: { [key: string]: string }[],
    id_tabela: string,
    deletar_produtos: boolean,
  ) => {
    let response = await api.post("/produto/importarEmMassa", {
      produtos: produtos,
      id_tabela: id_tabela,
      deletar_produtos: deletar_produtos || false,
    });
    return response.data;
  },

  getTabelasDePreco: async () => {
    let response = await api.post("importacao/tabelas_preco");
    return response.data;
  },

  getModosImportacao: async () => {
    let response = await api.post("importacao/listar_modos");
    return response.data;
  },

  getProdutosImportacaoPorModo: async (modo: string, imagens: string[]) => {
    let response = await api.post("importacao/useModo", {
      id_modo: modo,
      imagens: imagens,
    });
    return response.data;
  },

  getInformacoesERP: async (
    pesquisa: string,
    filtro: string,
    limit: number,
    offset: number,
    cancelToken?: any,
  ) => {
    let response = await api.post("produto/informacoes_erp", {
      pesquisa: pesquisa,
      filtro: filtro,
      limit: limit,
      offset: offset,
    },
    {
      cancelToken: cancelToken,
    });
    
    return response.data;
  },

  forcarAtualizacaoERP: async (
    codigos_erp_produtos: string[],
  ) => {
    let response = await api.post("erp/atualizar_produtos", {
      codigos_erp_produtos: codigos_erp_produtos,
    });
    return response.data;
  },


  importarImagem: async (
    imagem: { base64: string; imagem: string; size: number },
    produtos: Produto[],
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
  ) => {
    let response = await api.post(
      "produto/upload_foto_importacao",
      { foto: imagem, produtos: produtos },
      {
        onUploadProgress: onUploadProgress,
      },
    );
    return response.data;
  },

  baixarModeloCsv: async () => {
    try {
      const response = await api.get("produto/baixar_modelo_csv", {
        responseType: "blob",
      });

      let blob = new Blob([response.data], { type: "text/csv" });

      //remover ultima linha, que é o "1" de retorno da api
      let lines = blob.slice(0, blob.size - 1);
      blob = new Blob([lines], { type: "text/csv" });

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Arquivo_modelo_para_importacao.csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    }
  },
});
