import { Controls, Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import LoadingSRC from "../../../assets/jsons/Loading.json";

interface LoaderProps {
}

const Loader: React.FC<LoaderProps> = ({}) => {
    const animation = LoadingSRC;

    return (
        <>
            <Player
                autoplay
                loop
                src={animation}
                style={{ height: "80px", width: "80px" }}
            >
                <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
                />
            </Player>
        </>
    )
}

export default Loader;