import styled from "styled-components";

const transitionTime = "1s";
const SkeletonCompartilharContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  .skeleton {
    width: 100%;
    height: 30rem;
    background-color: ${(props) => props.theme.colors.white};
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.01s);
    border-radius: 3rem;
  }
  .balls {
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    align-items: center;
    width: 100%;
    .ball {
      background-color: ${(props) => props.theme.colors.white};
      animation: pulsate ${transitionTime} ease-out infinite alternate;
      animation-delay: calc(var(--i) * 0.01s);
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
    }
  }
`;

export { SkeletonCompartilharContainer };
