import React, { useContext, useEffect } from "react";
import { Input } from "../../Input";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { MockupCatalogo } from "../../Mockups/MockupCatalogo/MockupCatalogo";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { EnderecoContainer } from "./styles";
import { copy } from "../../../services/Functions";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { cepMask, ufMask } from "../../../services/Masks";
import { validateCep } from "../../../services/MasksValidations";
import { unmaskToNumbers } from "../../../services/Unmasks";

interface EnderecoProps {
  configIn: "empresa" | "configuracoes";
}

const Endereco: React.FC<EnderecoProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const { theme } = useTheme();

  const handleInputChange = (newValue: string, name?: string) => {
    setConfiguracoes({
      ...configuracoes,
      dados_empresa: {
        ...configuracoes.dados_empresa,
        endereco: {
          ...configuracoes.dados_empresa.endereco,
          [name!]: newValue,
        },
      },
    });
  };

  return (
    <>
      <EnderecoContainer configIn={configIn} theme={theme}>
        <div className="Endereco">
          <div className="informacoes">
            <Titulo
              titulo="Endereço"
              subtitulo="Sua empresa"
              justifyContent="flex-start"
            />
            <div className="inputs-container">
              <div className="input-container">
                <span className="cep">
                  <Input
                    allowEmojis={false}
                    mask={{
                      mask: cepMask,
                      maskValidation: validateCep,
                      unmask: unmaskToNumbers,
                    }}
                    onChange={handleInputChange}
                    type={"text"}
                    name="cep"
                    value={configuracoes.dados_empresa.endereco.cep}
                    placeholder={"CEP"}
                    error={""}
                    required={true}
                  ></Input>
                </span>
                <span className="estado">
                  <Input
                    allowEmojis={false}
                    minLength={2}
                    maxLength={2}
                    showMaxLength={false}
                    onChange={handleInputChange}
                    mask={{
                      mask: ufMask
                    }}
                    name="estado"
                    type={"text"}
                    value={configuracoes.dados_empresa.endereco.estado.toUpperCase()}
                    placeholder={"Estado"}
                    required={true}
                  ></Input>
                </span>
              </div>
              <div className="input-container">
                <Input
                  allowEmojis={false}
                  onChange={handleInputChange}
                  type={"text"}
                  name="cidade"
                  value={configuracoes.dados_empresa.endereco.cidade}
                  placeholder={"Cidade"}
                  error={""}
                  required={true}
                  maxLength={30}
                  showMaxLength={false}
                ></Input>
              </div>
              <div className="input-container">
                <Input
                  allowEmojis={false}
                  onChange={handleInputChange}
                  type={"text"}
                  name="endereco"
                  value={configuracoes.dados_empresa.endereco.endereco}
                  placeholder={"Endereço"}
                  error={""}
                  required={true}
                  maxLength={100}
                  showMaxLength={false}
                ></Input>
              </div>
              <div className="input-container">
                <div className="numero">
                  <Input
                    allowEmojis={false}
                    onChange={handleInputChange}
                    type={"text"}
                    name="numero"
                    value={configuracoes.dados_empresa.endereco.numero}
                    placeholder={"Número"}
                    error={""}
                    required={true}
                    maxLength={6}
                    showMaxLength={false}
                  ></Input>
                </div>
                <div className="bairro">
                  <Input
                    allowEmojis={false}
                    onChange={handleInputChange}
                    type={"text"}
                    name="bairro"
                    value={configuracoes.dados_empresa.endereco.bairro}
                    placeholder={"Bairro"}
                    error={""}
                    required={true}
                    maxLength={50}
                    showMaxLength={false}
                  ></Input>
                </div>
              </div>
              <div className="input-container">
                <Input
                  allowEmojis={false}
                  onChange={handleInputChange}
                  type={"text"}
                  name="complemento"
                  value={configuracoes.dados_empresa.endereco.complemento}
                  placeholder={"Complemento"}
                  error={""}
                  maxLength={70}
                  showMaxLength={false}
                ></Input>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  defaultChecked={
                    configuracoes.dados_empresa.endereco
                      .ocultar_endereco_catalogo
                  }
                  id="ocultarEndereco"
                  onChange={(event) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_empresa: {
                        ...configuracoes.dados_empresa,
                        endereco: {
                          ...configuracoes.dados_empresa.endereco,
                          ocultar_endereco_catalogo: event.target.checked,
                        },
                      },
                    });
                  }}
                ></input>
                <label htmlFor="ocultarEndereco">
                  Ocultar endereço do catálogo
                </label>
              </div>
            </div>
          </div>
          <div className="mockup-container">
            <MockupCatalogo
              menu={true}
              banner={false}
              configuracoes={configuracoes}
            />
          </div>
        </div>
      </EnderecoContainer>
    </>
  );
};

export default Endereco;
