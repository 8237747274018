import React, { CSSProperties } from "react";
import SkeletonTableCestasContainer from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonTableCestasProps {}

interface CSSPropertiesWithMultiValues extends CSSProperties {
  "--i"?: string;
  theme: string;
}

export const SkeletonTableCestas: React.FC<SkeletonTableCestasProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <SkeletonTableCestasContainer theme={theme}>
        <table className="loadingState">
          <tbody>
            {Array(25)
              .fill(0)
              .map((_, index) => {
                return (
                  <tr
                    style={{ "--i": `${index}` } as React.CSSProperties}
                    key={index}
                  >
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </SkeletonTableCestasContainer>
    </>
  );
};
