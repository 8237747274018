import { api } from "./useApi";

export const listasDePrecoApi = () => ({
  getListas: async () => {
    let response = await api.post("/empresa/tabelas");
    return response.data;
  },
  criarListaDePreco: async (
    descricao_tabela: string,
    id_tabela: string,
    indice: number,
    moeda: string,
    id_tabela_indice: string
  ) => {
    let response = await api.post("/tabela/criar_tabela_preco", {
      descricao_tabela,
      id_tabela,
      indice,
      moeda,
      id_tabela_indice
    });
    return response.data;
  },
  editarListaDePreco: async (tabelas) => {
    let response = await api.post("/tabela/criar", { tabelas });
    return response.data;
  },
  reajustarPrecos: async (id_categoria: string, indice: number, id_tabela: string, descricao_tabela: string, moeda: string, id_tabela_indice: string) => {
    let response = await api.post("/reajuste-preco/reajustar-precos", {
      id_categoria,
      indice,
      id_tabela,
      descricao_tabela,
      moeda,
      id_tabela_indice
    });
    return response.data;
  }
});
