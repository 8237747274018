import styled from "styled-components";

const transitionTime = "0.3s";
const TextAreaComponentContainer = styled.label`
  width: 100%;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  .left {
    margin-left: 2rem;
    justify-content: flex-start;
  }

  .center {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
    margin-right: 2rem;
  }

  .label {
    font-size: 2.5rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
  }

  .textarea {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;

    label {
      color: ${(props) => props.theme.colors.brandSecondary};
      font-weight: normal;
    }

    textarea {
      font-size: 2.5rem;
      width: 100%;
      border-radius: 3rem;
      border: 1px solid transparent;
      padding: 1rem 4rem;
      height: 15rem;
      resize: none;
      background-color: ${(props) =>
        props.theme.mode == "light" ? "white" : props.theme.colors.white};
      color: ${(props) =>
        props.theme.mode == "light" ? "unset" : props.theme.colors.placeholder};

      &::placeholder {
        outline: none;
        color: ${(props) => props.theme.colors.placeholder};
      }

      &:focus {
        outline: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.brandPrimary};
        color: ${(props) =>
          props.theme.mode == "light"
            ? "unset"
            : props.theme.colors.brandSecondary};
      }

      &:disabled {
        color: ${(props) => props.theme.colors.placeholder};
      }
    }

    .maxLenght {
      position: absolute;
      color: #c7c7c7;
      right: 4rem;
    }
  }

  .comIcone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    textarea {
      width: auto;
    }

    .iconeContainer {
      width: 10%;
      height: 100%;

      .icone {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default TextAreaComponentContainer;
