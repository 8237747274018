import React, { useContext, useEffect, useRef, useState } from "react";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Swal from "sweetalert2";
import { ReactComponent as IconeDominio } from "../../assets/newImages/compartilhar/dominio.svg";
import { ReactComponent as IconeGuia } from "../../assets/newImages/compartilhar/icone_guia.svg";
import { ReactComponent as LinkIcon } from "../../assets/newImages/icons/link-h.svg";
import { ReactComponent as Mail } from "../../assets/newImages/icons/mail.svg";
import { ReactComponent as Telegram } from "../../assets/newImages/icons/telegram-alt.svg";
import { ReactComponent as WeChat } from "../../assets/newImages/icons/wechat.svg";
import { ReactComponent as WhatsApp } from "../../assets/newImages/icons/whatsapp.svg";
import Form, { FormRef } from "../../components/Form/index.tsx";
import { Select } from "../../components/Select/index.tsx";
import SkeletonCompartilhar from "../../components/Skeletons/SkeletonCompartilhar/SkeletonCompartilhar.tsx";
import { AuthContext } from "../../contexts/Auth/AuthContext.tsx";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { CatalogosApi } from "../../hooks/catalogos.ts";
import { PERMISSOES } from "../../services/Consts.tsx";
import { staginEnv } from "../../services/Functions.tsx";
import ConfigurarDominio from "./ConfigurarDominio.tsx";
import {
  ContainerCompartilhar,
  IconsContainer,
  IconWrapper,
  Middle,
} from "./styles.ts";

interface CompartilharProps {}

export const Compartilhar: React.FC<CompartilharProps> = ({}) => {
  const { theme } = useTheme();

  const catalogosApi = CatalogosApi();
  const {permissoesUsuario} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [catalogos, setCatalogos] = useState<any>();
  const [catalogoSelecionado, setCatalogoSelecionado] = useState<any>("");
  const [copiandoLink, setCopiandoLink] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const formRef = useRef<FormRef | null>(null);
  
  const {empresa, setEmpresa} = useContext(EmpresaContext);
  const mensagemWpp = empresa!.mensagem_whats ?? "Olá, veja o catálogo da minha empresa:";
  

  const init = async () => {
    setLoading(true);
    const catalogos = (await catalogosApi.getCatalogos()).catalogos;
    setCatalogos(
      catalogos.map((catalogo: any) => {
        return {
          value: catalogo.identificacao,
          name: catalogo.descricao,
        };
      }),
    );
    setLoading(false);
  };

  const icons = [
    {
      component: LinkIcon,
      label: `${copiandoLink ? "Link copiado" : "Link"}`,
      funcao: () => {
        navigator.clipboard
          .writeText(`${empresa?.dominio_https}${catalogoSelecionado}${staginEnv()}`)
          .then(
            () => {
              setCopiandoLink(true);
              setTimeout(() => {
                setCopiandoLink(false);
              }, 2000);
            },
            (err) => {
              Swal.fire({
                icon: "error",
                title: "Erro ao copiar link",
                text: `Mas você ainda pode tentar copiar o link manualmente! \n ${empresa?.dominio_https}${catalogoSelecionado}`,
              });
            },
          );
      },
    },
    {
      component: WhatsApp,
      label: "WhatsApp",
      funcao: () => {
        window.open(
          `https://api.whatsapp.com/send?text=${mensagemWpp} ${empresa?.dominio_https}${catalogoSelecionado}`,
        );
      },
    },
    {
      component: Telegram,
      label: "Telegram",
      funcao: () => {
        window.open(
          `https://telegram.me/share/url?url=${empresa?.dominio_https}${catalogoSelecionado}&text=${mensagemWpp}`,
        );
      },
    },
    {
      component: Mail,
      label: "E-mail",
      funcao: () => {
        window.open(
          `mailto:?subject=${mensagemWpp}&body=${empresa?.dominio_https}${catalogoSelecionado}`,
        );
      },
    },
    {
      component: WeChat,
      label: "WeChat",
      funcao: () => {
        window.open(
          `https://www.addtoany.com/add_to/wechat?linkurl=${empresa?.dominio_https}${catalogoSelecionado}`,
        );
      },
    },
  ];

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <ContainerCompartilhar theme={theme} isGrid={true}>
        {loading ? (
          <SkeletonCompartilhar />
        ) : (
          <>
            <div className="conteudo">
              <div className="topo">
                <IconeGuia />
                <div className="texto">
                  Compartilhe seu catálogo <br />
                  com seus clientes
                </div>
              </div>
              <div className="catalogos">
                <div className="titulo">Catálogos</div>
                <Form id={""} setFormValid={setIsValid} ref={formRef}>
                  <Select
                    sizeLabel="medium"
                    name="categoria"
                    value={catalogoSelecionado}
                    onChange={(newValue) => {
                      setCatalogoSelecionado(newValue);
                    }}
                    placeholder="Selecione um catálogo"
                    options={catalogos}
                    required={true}
                  />
                </Form>
              </div>
              <Middle theme={theme}>
                <div></div>
              </Middle>
              <div className="compartilhar">
                <div className="titulo">Compartilhe via</div>
                <IconsContainer theme={theme}>
                  {icons.map((icon, index) => (
                    <IconWrapper
                      key={index}
                      firstTwo={index < 2}
                      theme={theme}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        formRef.current && formRef.current.submitForm();
                        isValid && icon.funcao();
                      }}
                    >
                      <div className="bola">
                        <icon.component />
                      </div>
                      <div className="texto">{icon.label}</div>
                    </IconWrapper>
                  ))}
                </IconsContainer>
              </div>
              {permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
                <ConfigurarDominio dominio={empresa?.dominio_https} empresa={empresa} />
              )}
            </div>
          </>
        )}
      </ContainerCompartilhar>
    </>
  );
};

export default Compartilhar;
