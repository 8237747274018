import { createContext } from "react";
import { Plano } from "../../types/Plano";

export type HeaderContextType = {
  modalCriacao: boolean;
  setModalCriacao: (modalCriacao: boolean) => void;
  temNotificacaoNova: boolean;
  setTemNotificacaoNova: (temNotificacaoNova: boolean) => void;
};

export const HeaderContext = createContext<HeaderContextType>(null!);
