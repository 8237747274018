import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { Button } from '../../../components/Button';
import { FooterPaginas } from '../../../components/FooterPaginas/FooterPaginas';
import { Input } from '../../../components/Input';
import LoadingComFundo from '../../../components/Loading/LoadingComFundo/LoadingComFundo';
import Paginacao from '../../../components/Paginacao';
import { Select } from '../../../components/Select';
import { SkeletonTable } from '../../../components/Skeletons/SkeletonTable/SkeletonTable';
import Table from '../../../components/Table/Table';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { produtosApi } from '../../../hooks/produtosApi';
import { InformacoesERPContainer } from './styles';

type Situacao = 'Erro' | 'Atualizado' | 'Na fila'

interface Produto{
    imagem: string | null,
    sku: string,
    codigo_produto: string,
    codigo_erp: string,
    situacao: Situacao,
    data: string,
    info: string
}

interface Filtros {
    pesquisa: string,
    filtro: Situacao | 'Todos',
    offset: number
}

interface InformacoesERPProps {
}

const tableColumns = [
    {
        key: 'imagem',
        label: 'Imagem',
    },
    {
        key: 'sku',
        label: 'SKU',
    },
    {
        key: 'codigo_produto',
        label: 'Código do Produto',
    },
    {
        key: 'codigo_erp',
        label: 'Código ERP',
    },
    {
        key: 'situacao',
        label: 'Situação',
    },
    {
        key: 'data',
        label: 'Data',
        mask: (value: string) => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss');
        }
    },
    {
        key: 'info',
        label: 'Informações',
        mask: (value: string) => {
            return (
                <div style={{
                    maxWidth: '100rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}>
                    {value}
                </div>
            )
        }
    }
]

const optionsFiltro = [
    { value: 'Todos', name: 'Todos' },
    { value: 'Erro', name: 'Erro' },
    { value: 'Atualizado', name: 'Atualizado' },
    { value: 'Pendente', name: 'Pendente' }
]

const itensPorPagina = 60;

const InformacoesERP: React.FC<InformacoesERPProps> = ({}) => {

    const {theme} = useTheme();
    const api = produtosApi();
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingAtualizacao, setLoadingAtualizacao] = useState<boolean>(false);

    const [filtros, setFiltros] = React.useState<Filtros>({
        pesquisa: '',
        filtro: 'Todos',
        offset: 0
    });
    
    const [pesquisaAux, setPesquisaAux] = React.useState<string>('');

    const [selectedProdutos, setSelectedProdutos] = React.useState<Produto[]>([]);
    const [produtos, setProdutos] = React.useState<Produto[]>([]);
    const [infos, setInfos] = React.useState<{proxima_exec: null | string, ultima_exec: null | string}>({proxima_exec: null, ultima_exec: null});
    const cancelToken = useRef(axios.CancelToken.source());

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const atualizarProdutos = async () => {
        const codigos_erp_produtos = selectedProdutos.map(produto => produto.codigo_erp);
        Swal.fire({
            title: 'Forçar atualização',
            text: 'Deseja forçar a atualização dos produtos selecionados?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if(result.isConfirmed){
                setLoadingAtualizacao(true);
                try {
                    await api.forcarAtualizacaoERP(codigos_erp_produtos);
                    Swal.fire({
                        icon: 'success',
                        title: 'Atualização forçada',
                        text: 'A atualização foi forçada com sucesso'
                    })
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao forçar atualização',
                        text: 'Ocorreu um erro ao forçar a atualização, tente novamente mais tarde'
                    })
                }
                buscarProdutos(filtros);
            }
        })
    }

    const buscarProdutos = async (
        filtros: Filtros
    ) => {
        //cancelar se ja tiver um request em andamento
        cancelToken.current.cancel();
        cancelToken.current = axios.CancelToken.source();
        try {
            const response = await api.getInformacoesERP(
                filtros.pesquisa,
                filtros.filtro,
                itensPorPagina,
                filtros.offset,
                cancelToken.current.token
            );
            setProdutos(response.produtos);
            setInfos(response.infos);
            setTotalPages(Math.ceil(response.total_produtos / itensPorPagina));
            
            setLoading(false);
            setLoadingAtualizacao(false);
        } catch (error) {
            if(axios.isCancel(error)){
                console.log('Request canceled', error.message);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao buscar produtos',
                    text: 'Ocorreu um erro ao buscar os produtos, tente novamente mais tarde'
                })
            }
        }
    }

    const handleChangeFilter = (
        key: string,
        newValue: string | Situacao | number
    ) => {
        //resetar a pagina atual se o filtro mudar
        const offset = key === 'offset' ? newValue : 0;
        if(key !== 'offset') {
            setCurrentPage(1)
        };
        
        setFiltros({
            ...filtros,
            [key]: newValue,
            offset: offset as number
        })

        setLoading(true);
    }

    useEffect(() => {
        buscarProdutos(filtros);
    }, [filtros])

    const ultimaExec = moment(infos.ultima_exec);
    const proximaExec = moment(infos.proxima_exec);


    if(loadingAtualizacao){
        return (
            <LoadingComFundo 
                mensagem="Atualizando produtos..." 
                subMensagem='Isso pode levar alguns minutos'
                position="fixed"
            />
        )
    }

    return (
        <InformacoesERPContainer theme={theme}>
            <div className="informacoes">
            <span>
                Última atualização automática<br /> <b>{ultimaExec.isValid() ? ultimaExec.format('DD/MM/YYYY HH:mm:ss') : 'Sem informação'}</b>
            </span>
            <span>
                Próxima atualização automática<br /> <b>{proximaExec.isValid() ? proximaExec.format('DD/MM/YYYY HH:mm:ss') : 'Sem informação'}</b> 
            </span>
            </div>
            <div className="filtros">
                <Input
                    type={"text"}
                    icone="icons/search.svg"
                    placeholder={"Pesquisar por SKU, Código do Produto ou Código ERP"}
                    value={pesquisaAux}
                    onChange={(newValue) => {
                        newValue === filtros.pesquisa
                        ? setLoading(false)
                        : setLoading(true);
                        setPesquisaAux(newValue);
                    }}
                    onDebounce={(newValue) =>
                        handleChangeFilter('pesquisa', newValue)
                    }
                    debounceTime={500}
                />
                <Select
                    value={filtros.filtro}
                    options={optionsFiltro}
                    placeholder="Filtrar por"
                    onChange={(newValue) => handleChangeFilter('filtro', newValue)}
                />
            </div>
            {loading ? (
                <SkeletonTable />
            ) : (
                <Table 
                    data={produtos} 
                    columns={tableColumns} 
                    sortable={false} 
                    selectable={true}
                    onItemSelected={(selectedItens) => setSelectedProdutos(selectedItens)}
                    button={false}
                    page={1} 
                    itensPorPagina={itensPorPagina}
                />
            )}
            {((totalPages > 1) || selectedProdutos.length) && (
                <FooterPaginas>
                    <div className="footer-wrapper">
                        {totalPages > 1 && (
                            <div>
                                <Paginacao
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={(page) => {
                                        setCurrentPage(page)
                                        handleChangeFilter('offset', ((page - 1) * itensPorPagina))
                                    }}
                                />
                            </div>
                        )}
                        <div className="selecionados">
                            {selectedProdutos.length > 0 && (
                                <>
                                    <Button title='Forçar atualização' onClick={atualizarProdutos} />
                                    <span>
                                        {selectedProdutos.length} produtos selecionados
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </FooterPaginas>
            )}
        </InformacoesERPContainer >
    )
}

export default InformacoesERP;