import React, { memo, useCallback, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdowns/Dropdown.tsx";
import InfiniteScroll from "../../../../components/InfiniteScroll/InfiniteScroll";
import { Input } from "../../../../components/Input";
import Produto from "../../../../components/Produto";
import { Select } from "../../../../components/Select";
import SkeletonProdutos from "../../../../components/Skeletons/SkeletonProdutos/SkeletonProdutos";
import Slider from "../../../../components/Slider/Slider.tsx";
import { CatalogoContext } from "../../../../contexts/Catalogo/CatalogoContext.tsx";
import { ProdutosContext } from "../../../../contexts/Produtos/ProdutosContext";
import { Produto as ProdutoType } from "../../../../types/Produto";
import "./styles.ts";
import ProdutosListagem from "./styles.ts";
interface ProdutosProps {
  produtosCatalogo: number[];
  onAdicionarProdutos: (ids_produtos: number[]) => void;
  onRemoverProdutos: (ids_produtos: number[]) => void;
}

const Produtos: React.FC<ProdutosProps> = ({
  produtosCatalogo,
  onAdicionarProdutos,
  onRemoverProdutos,
}) => {
  const {
    loading,
    setLoading,
    produtosFiltrados,
    filtros,
    setFiltros,
    filtroPesquisaAux,
    setFiltroPesquisaAux,
    optionsVisualizacao,
    optionsEstoque,
    optionsOrdenacao,
    produtos,
    categorias,
    init,
  } = useContext(ProdutosContext);

  const context = useContext(CatalogoContext);
  const catalogo = context ? context.catalogo : null;
  const [maiorValor, setMaiorValor] = React.useState(100);
  const listasDePreco = context ? context.listasDePrecos : null;

  let moeda = "R$";
  if (context) {
    let listaCatalogo = listasDePreco?.filter(
      (lista) => lista.id === catalogo?.id_tabela
    );
    if (listaCatalogo?.length) moeda = listaCatalogo[0].moeda;
  }

  const handleAdicionarProdutos = useCallback(
    (ids_produtos: number[]) => {
      onAdicionarProdutos(ids_produtos);
    },
    [onAdicionarProdutos]
  );

  const handleRemoverProdutos = useCallback(
    (ids_produtos: number[]) => {
      onRemoverProdutos(ids_produtos);
    },
    [onRemoverProdutos]
  );

  const adicionarRemoverTodos = useCallback(
    (adicionar: boolean) => {
      if (adicionar) {
        handleAdicionarProdutos(produtosFiltrados.map((produto) => produto.id));
      } else {
        handleRemoverProdutos(produtosFiltrados.map((produto) => produto.id));
      }
    },
    [produtosFiltrados]
  );

  const duration = 250;
  const handleAplicarFiltro = () => {
    if (!catalogo?.id_tabela) {
      Swal.fire({
        title: "Atenção!",
        text: "Você precisa selecionar uma tabela de preços antes de aplicar os filtros.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    init();
    setFiltroAplicado(true);
    setTimeout(() => {
      window.scrollTo({
        top:
          window.scrollY +
          produtosScroll.current!.getBoundingClientRect().top -
          100,
        behavior: "smooth",
      });
    }, duration);
  };

  const [filtroAplicado, setFiltroAplicado] = React.useState(false);
  const produtosScroll = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (produtos.length > 0) {
      const maior = Math.max(
        ...produtos.map((produto) =>
          produto.preco ? parseFloat(produto.preco.replace(",", ".")) : 0
        )
      );
      setMaiorValor(maior);
      setFiltros({
        ...filtros,
        filtroPrecoMax: maior,
      });
    }
  }, [produtos]);

  const replicado =
    catalogo &&
    catalogo.id_catalogo_original &&
    catalogo.id_catalogo_original !== "0"
      ? true
      : false;

  return (
    <>
      <ProdutosListagem>
        <div className="grid-header">
          <div className="input">
            <Input
              sizeLabel="medium"
              label="Pesquisar"
              type={"text"}
              icone="icons/search.svg"
              placeholder={"Por SKU ou descrição"}
              value={filtroPesquisaAux}
              onChange={(newValue) => {
                newValue === filtros.filtroPesquisa
                  ? setLoading(false)
                  : setLoading(true);
                setFiltroPesquisaAux(newValue);
              }}
              onDebounce={(newValue) =>
                setFiltros({ ...filtros, filtroPesquisa: newValue })
              }
              debounceTime={500}
            />
          </div>
          <div className="select">
            <Select
              sizeLabel="medium"
              label="Filtrar por categoria"
              options={categorias}
              value={filtros.filtroCategoria}
              onChange={(newValue) => {
                setFiltros({ ...filtros, filtroCategoria: newValue });
              }}
            />
          </div>
        </div>
        <div>
          {!filtroAplicado && (
            <Button
              title="Aplicar filtros"
              className="button-filtrar"
              width="100%"
              onClick={() => handleAplicarFiltro()}
            ></Button>
          )}
        </div>
        <Dropdown active={filtroAplicado} animated={true} duration={duration}>
          <div className={`produtos-wrapper`}>
            <div className="informacoes">
              <div className="flex-container-produtos">
                <span>{produtosFiltrados.length} produtos encontrados</span>
                <div>{produtosCatalogo.length} adicionados ao seu catálogo</div>
              </div>
              <div className="flex-container-produtos">
                <Slider
                  min={0}
                  max={maiorValor}
                  step={0.01}
                  onDebounceStart={(value) => {
                    setLoading(true);
                  }}
                  onDebounce={(value) => {
                    setFiltros({
                      ...filtros,
                      filtroPrecoMin: value[0],
                      filtroPrecoMax: value[1],
                    });
                  }}
                />
              </div>
              <div className="flex-container-produtos">
                <div className="container-half">
                  <Select
                    options={optionsVisualizacao}
                    label="Visualizar"
                    value={filtros.filtroVisualizacao}
                    onChange={(newValue) => {
                      setFiltros({ ...filtros, filtroVisualizacao: newValue });
                    }}
                  />
                </div>
                <div className="container-half">
                  <Select
                    options={optionsOrdenacao}
                    label="Ordenar"
                    value={filtros.filtroOrdenacao}
                    onChange={(newValue) => {
                      setFiltros({ ...filtros, filtroOrdenacao: newValue });
                    }}
                  />
                </div>
                <div className="container-half">
                  <Select
                    options={optionsEstoque}
                    label="Estoque"
                    value={filtros.filtroEstoque}
                    onChange={(newValue) => {
                      setFiltros({ ...filtros, filtroEstoque: newValue });
                    }}
                  />
                </div>
              </div>
              <div className="flex-container-produtos">
                <div className="container-half">
                  <Button
                    width="100%"
                    disabled={replicado}
                    className="botao"
                    title="Adicionar Todos"
                    onClick={() => adicionarRemoverTodos(true)}
                  ></Button>
                </div>
                <div className="container-half">
                  <Button
                    width="100%"
                    disabled={replicado}
                    className="botao cinza"
                    title="Remover Todos"
                    onClick={() => adicionarRemoverTodos(false)}
                  ></Button>
                </div>
              </div>
            </div>
            <div ref={produtosScroll} className="produtos scrollBonito">
              {loading ? (
                <SkeletonProdutos />
              ) : (
                <>
                  <InfiniteScroll
                    array={produtosFiltrados}
                    itemsPerPage={50}
                    scrollableDiv={produtosScroll}
                    className="produtos-wrapper"
                  >
                    {(produto: ProdutoType, index) => (
                      <div className="produto-container">
                        <Produto
                          produto={produto}
                          key={index}
                          moeda={moeda}
                          openImage={catalogo ? true : false}
                        >
                          <div className="button-wrapper">
                            {produtosCatalogo.includes(produto.id) ? (
                              <Button
                                className="botao-remover"
                                disabled={replicado}
                                onClick={() =>
                                  handleRemoverProdutos([produto.id])
                                }
                                title={""}
                              />
                            ) : (
                              <Button
                                className="botao-adicionar"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAdicionarProdutos([produto.id]);
                                }}
                                title=""
                              />
                            )}
                          </div>
                        </Produto>
                      </div>
                    )}
                  </InfiniteScroll>
                </>
              )}
              {!loading && produtosFiltrados.length === 0 && (
                <div className="mensagem">
                  <div className="texto-titulo">
                    <div>Nenhum resultado encontrado para sua busca.</div>
                  </div>
                  <div className="texto-subtitulo">
                    <div>Tente novamente com uma busca diferente!</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Dropdown>
      </ProdutosListagem>
    </>
  );
};

export default memo(Produtos);
