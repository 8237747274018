import { ScrollDirectionContext } from "./ScrollDirectionContext";
import { useState, useEffect } from "react";
import { authApi } from "../../hooks/authApi";

export const ScrollDirectionProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [direction, setDirection] = useState<"up" | "down">("up");
  const [position, setPosition] = useState<"top" | "other" | "bottom">("top");

  useEffect(() => {
    const threshold = 0;
    const positionThreshold = 0; // threshold for position
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setDirection(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      // Update position
      if (scrollY === 0) {
        setPosition("top");
      } else if (
        scrollY + window.innerHeight + positionThreshold >=
        document.documentElement.scrollHeight
      ) {
        setPosition("bottom");
      } else {
        setPosition("other");
      }

      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <ScrollDirectionContext.Provider value={{ direction, position }}>
      {children}
    </ScrollDirectionContext.Provider>
  );
};
