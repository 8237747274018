import Swal from 'sweetalert2';
import { api } from "./useApi";

export const authApi = () => ({
  validateToken: async () => {
    try {
      const response = await api.post("/usuario/verifica_sessao");
      return response.data;
    } catch (error) {
      return false;
    }
  },

  signin: async (email: string, senha: string) => {
    try {
      const responseLogin = await api.post("/login/login", { email, senha });
      return responseLogin.data;
    } catch (error: any) {
      if((error.code === 'ERR_NETWORK' && error.request.status === 0) || error.response.data === 'BANCO_OFF'){
        Swal.fire({
          title: "Oops...",
          html: `
          <div style="text-align: justify; padding-top: 15px;">
          A infraestrutura do Conecta Venda está passando por uma manutenção programada. Sentimos muito pelo inconveniente. Contamos com a sua compreensão, estamos empenhados e trabalhando para que tudo ocorra da melhor maneira possível.
          </div>
        `,
          icon: "warning",
          confirmButtonText: "Ok"
        });
      } else {
        return error;
      }
    }
  },
    
  getPermissoes: async () => {
    let response: Array<number> = [];

    await api.post("/permissoes/permissoes_usuario").then(
      (res) => {
        response = Object.keys(res.data).map((key) => Number(key));
      },
      (err) => {
        console.log("err", err);
      },
    );

    return response;
  },

  logout: async () => {
    const response = await api.post("/login/logout");
    return response.data;
  },
  session_reload: async () => {
    const response = await api.post("/usuario/session_reload");
    return response.data;
  },
});
