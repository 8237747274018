import styled from "styled-components";

export const ContainerCestasAbandonadas = styled.div`
  width: 100%;
  padding: 5rem 10rem 13rem 10rem;
  font-size: 2.5rem;

  .filtros-container {
    display: flex;
    justify-content: space-between;

    .filtros {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      flex-grow: 1;

      .filtroPesquisa {
        min-width: 65rem;
        display: inline;
      }

      .containerSelects {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }

    .inf-visiveis-container {
      display: flex;

      .selectContainer {
        position: relative;
        width: 43rem;
        display: flex;
        align-items: center;
      }

      .inf-visiveis {
        cursor: pointer;
        display: flex;
        color: ${(props) => props.theme.colors.placeholder};
        white-space: normal;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;
        svg {
          fill: ${(props) =>
            props.theme.mode == "light"
              ? "#636466"
              : props.theme.colors.brandSecondary};
        }

        .texto {
          cursor: pointer;
        }
      }
    }
  }

  .container-toggles {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 10% 90%;

    .texto {
      height: 100%;
      font-size: 2.5rem;
      margin: 4rem 0;
      color: ${(props) =>
        props.theme.mode == "light"
          ? "unset"
          : props.theme.colors.brandSecondary};
    }

    .campos {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 2rem;

      .campo {
        display: flex;
        gap: 2rem;
        justify-content: flex-start;
        align-items: center;
        color: ${(props) =>
          props.theme.mode == "light"
            ? "unset"
            : props.theme.colors.brandSecondary};
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 5rem 3rem;

    .filtros-container {
      gap: 3rem;
      display: flex;
      flex-direction: column;

      .filtros {
        .filtroPesquisa {
          flex-grow: 9999;
          width: 100%;
          min-width: 0;
        }

        .filtro,
        .filtro-data {
          flex-grow: 1;
          max-width: 60rem;
        }
      }

      .inf-visiveis-container {
        width: 100%;
        justify-content: space-between;

        .inf-visiveis {
          .texto {
            display: none;
          }
        }
      }
    }

    .containerTable {
      padding-bottom: 12rem;
    }

    .paddingExtra {
      padding-bottom: 37rem;
    }
  }

  .containerSituacoes {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    gap: 1rem;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 50px;
      background-color: ${(props) => props.theme.colors.elements};
    }

    .situacoes {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: ${(props) => props.theme.colors.brandSecondary};
      font-size: 3rem;
      gap: 1rem;
      padding: 0 5rem;
      height: 100%;

      .quantidade {
        font-size: 2.5rem;
      }

      .containerSituacao {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .situacao {
          min-height: 8rem;
          transition: 0.4s;
          cursor: pointer;
        }

        .linha {
          position: absolute;
          top: calc(100% + 1rem);
          width: 120%;
          min-width: 15rem;
          border-radius: 50px;
          height: 2px;
          animation: abrir 0.2s linear;
        }

        @keyframes abrir {
          from {
            transform: scale(0);
          }
          to {
            transform: scale(1);
          }
        }

        .ativa {
          transform: scale(1.2);

          .nome {
            font-weight: bold;
          }
        }
      }
    }
  }

  .paginacao-table {
    .container-paginacao-table {
      width: 100%;
      position: relative;

      .flex-container {
        width: 100%;
        padding-top: 2rem;
        display: flex;
        gap: 3rem;
        position: relative;
        justify-content: space-between;
        align-items: center;
        color: ${(props) =>
          props.theme.mode == "light"
            ? "unset"
            : props.theme.colors.brandSecondary};

        .valor {
          color: ${(props) => props.theme.colors.brandSecondary};
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: 1rem;
          align-items: center;
          flex-shrink: 1111;

          .valor-flex {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;

            .texto {
              color: ${(props) => props.theme.colors.placeholder};
              white-space: normal;
            }
          }
        }

        .changeSituacao {
          display: flex;
          align-items: center;

          .flexItens {
            white-space: normal;
            display: flex;
            align-items: center;
            gap: 1rem;

            .select {
              min-width: 45rem;
            }

            .botao {
              min-width: 35rem;
            }

            button {
              background-color: ${(props) => props.theme.colors.elements};
              color: ${(props) => props.theme.colors.brandSecondary};
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .container-footer-sacolas {
        padding-left: 3rem;
        padding-right: 3rem;
      }

      .flex-container {
        .changeSituacao {
          position: absolute;
          z-index: 10;
          width: 100%;
          bottom: 100%;
          justify-content: center;

          .flexItens {
            position: relative;
            z-index: 100;
            background-color: ${(props) => props.theme.colors.modal};
            justify-content: center;
            flex-wrap: wrap;
            max-width: 100%;
            padding: 1rem 10rem;
            border-radius: 8px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            color: ${(props) => props.theme.colors.brandSecondary};

            button {
              background-color: ${(props) => props.theme.colors.elements};
              color: ${(props) => props.theme.colors.brandSecondary};
            }
          }
        }
      }
    }
  }
`;
