import { ReactComponent as Macro } from "../../assets/newImages/icons/macro.svg";
import ParceriaContainer from "./styles";

export const ParceriaMacro = () => {
  return (
    <div>
      <ParceriaContainer className="parceria">
        <Macro />
      </ParceriaContainer>
      <div>
        Conheça nosso parceiro especialista em fotografias de produtos.{" "}
        <a
          href="https://api.whatsapp.com/send?phone=555499212779&text=Ol%C3%A1!%20Estou%20no%20Conecta%20Venda.%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20fotografia%20de%20produto%20da%20Macro.%20"
          target="_blank"
        >
          <strong>Clique aqui e saiba mais.</strong>
        </a>
      </div>
    </div>
  );
};
