import React, { useContext } from "react";
import "./styles";
import { SkeletonConfiguracoesContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonConfiguracoesProps {}

const SkeletonConfiguracoes: React.FC<SkeletonConfiguracoesProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <SkeletonConfiguracoesContainer theme={theme}>
      <div
        className="tree-skeleton"
        style={{ "--i": `${1}` } as React.CSSProperties}
      />
      <div className="configs-skeleton">
        {Array(21)
          .fill(0)
          .map((_, i) => (
            <div
              className="config-skeleton"
              key={i}
              style={
                { "--i": `${i + 1}`, gridArea: `sk${i}` } as React.CSSProperties
              }
            />
          ))}
      </div>
    </SkeletonConfiguracoesContainer>
  );
};

export default SkeletonConfiguracoes;
