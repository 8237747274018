import React from "react";
import { UilInstagram } from "@iconscout/react-unicons";
import { UilWhatsapp } from "@iconscout/react-unicons";
import Configuracoes from "../../../types/Configuracoes";
import { hexToRgb, phoneMask, nacionalPhoneMask } from "../../../services/Functions";
import configuracoes from "../../../types/Configuracoes";
import { MockupCatalogoContainer } from "./styles";

interface MockupCatalogoProps {
  banner?: boolean;
  menu?: boolean;
  informacoesVariacoes?: boolean;
  categorias?: boolean;
  configuracoes: configuracoes;
}

export const MockupCatalogo: React.FC<MockupCatalogoProps> = ({
  banner,
  menu,
  configuracoes,
  informacoesVariacoes,
  categorias,
}) => {
  const [produtos, setProdutos] = React.useState<number>(8);
  const [mostrarVariacoes, setMostrarVariacoes] =
    React.useState<boolean>(false);

  const toggleMostrarVariacoes = () => {
    setMostrarVariacoes(!mostrarVariacoes);
  };

  const limiteVariacoes = Number.parseInt(
    configuracoes.catalogo.limite_variacoes
  );

  const numeroVariacoesAMostrar = mostrarVariacoes
    ? limiteVariacoes
    : Math.min(4, limiteVariacoes);

  const styles = {
    mockupCatalogo: {
      borderRadius: menu ? "40px 40px 0px 0px" : "20px 20px 0px 0px",
      overflow: menu ? "hidden" : "visible",
    },
    cabecalho: {
      backgroundColor: configuracoes.catalogo.cor_primaria,
      height: menu ? "5rem" : "4rem",
      borderRadius: menu ? "0px" : "40px",
    },
    produtos: {
      gridTemplateColumns: `repeat(${configuracoes.catalogo.quantidade_colunas_padrao.mobile}, 1fr)`,
      marginTop: "3rem",
      padding:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "0 3rem"
          : "0 2rem",
    },
    variacao: {
      width: "100%",
      height:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "5rem"
          : "2rem",
    },
    bolinha: {
      width:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "5rem"
          : "2rem",
      height:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "5rem"
          : "2rem",
      padding:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "5px"
          : "2px",
    },
    fundoBolinha: {
      backgroundColor: configuracoes.catalogo.cor_primaria,
    },
    expandir: {
      borderColor: configuracoes.catalogo.cor_primaria,
    },
    botao: {
      width:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "3.5rem"
          : "2.5rem",
      height:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "3rem"
          : "2rem",
      backgroundColor: configuracoes.catalogo.cor_primaria,
    },
    compartilhar: {
      width:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "2rem"
          : "1.7rem",
      height:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1.5
          ? "2rem"
          : "1.3rem",
    },
    botaozinho: {
      backgroundColor: configuracoes.catalogo.cor_primaria,
      width:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "4rem"
          : "3rem",
      height:
        Number.parseFloat(
          configuracoes.catalogo.quantidade_colunas_padrao.mobile
        ) === 1
          ? "2.5rem"
          : "2rem",
    },
    categoria: {
      backgroundImage: `linear-gradient(135deg, rgb(${Object.values(
        hexToRgb(configuracoes.catalogo.cor_primaria)!
      ).toString()}) 66%, rgb(50, 50, 50) 80%)`,
    },
  };

  return (
    <>
      <MockupCatalogoContainer>
        <div className="mockupCatalogo" style={styles.mockupCatalogo}>
          {menu && (
            <div className="menuFundo">
              <div
                className="menu"
                style={{ backgroundColor: configuracoes.catalogo.cor_primaria }}
              >
                <div
                  className="logoContainer"
                  style={{
                    border: `2px solid ${configuracoes.catalogo.cor_secundaria}`,
                  }}
                >
                  {configuracoes.dados_empresa.informacoes_gerais.logotipo && (
                    <img
                      src={
                        configuracoes.dados_empresa.informacoes_gerais.logotipo[
                          "base64"
                        ]
                          ? configuracoes.dados_empresa.informacoes_gerais
                              .logotipo["base64"]
                          : configuracoes.dados_empresa.informacoes_gerais
                              .logotipo
                      }
                      alt=""
                    />
                  )}
                </div>
                <div
                  className="nomeDaMarca"
                  style={{ color: configuracoes.catalogo.cor_secundaria }}
                >
                  {configuracoes.dados_empresa.informacoes_gerais.nome
                    ? configuracoes.dados_empresa.informacoes_gerais.nome
                    : "Nome da marca"}
                </div>
                <div
                  className="descricao"
                  style={{ color: configuracoes.catalogo.cor_secundaria }}
                >
                  {configuracoes.dados_empresa.informacoes_gerais.descricao ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          configuracoes.dados_empresa.informacoes_gerais
                            .descricao,
                      }}
                    ></div>
                  ) : (
                    "A descrição da sua marca aparecerá aqui, use esse espaço para descrever seus diferenciais."
                  )}
                </div>
                <div className="whatsInstaContainer">
                  <span
                    style={{ color: configuracoes.catalogo.cor_secundaria }}
                  >
                    <UilWhatsapp style={{ width: "2.5rem" }}></UilWhatsapp>
                    {configuracoes.dados_empresa.redes_sociais.whatsapp
                      ? nacionalPhoneMask(
                          configuracoes.dados_empresa.redes_sociais.whatsapp
                        )
                      : "(54) 99999-9999"}
                  </span>
                  <span
                    style={{ color: configuracoes.catalogo.cor_secundaria }}
                  >
                    <UilInstagram style={{ width: "2.5rem" }}></UilInstagram>
                    {configuracoes.dados_empresa.redes_sociais.instagram
                      ? "@" +
                        configuracoes.dados_empresa.redes_sociais.instagram
                      : "@instagram"}
                  </span>
                  {!configuracoes.dados_empresa.endereco
                    .ocultar_endereco_catalogo && (
                    <div
                      className="endereco "
                      style={{ color: configuracoes.catalogo.cor_secundaria }}
                    >
                      <span>
                        {configuracoes.dados_empresa.endereco.endereco
                          ? configuracoes.dados_empresa.endereco.endereco
                          : "Endereço completo"}
                        ,
                      </span>
                      <span>
                        {configuracoes.dados_empresa.endereco.numero
                          ? " " + configuracoes.dados_empresa.endereco.numero
                          : " número"}
                        ,
                      </span>
                      <span>
                        {configuracoes.dados_empresa.endereco.bairro
                          ? " " + configuracoes.dados_empresa.endereco.bairro
                          : " bairro"}
                        ,<br></br>
                      </span>
                      <span>
                        {configuracoes.dados_empresa.endereco.cidade
                          ? " " + configuracoes.dados_empresa.endereco.cidade
                          : " Cidade "}
                        -
                      </span>
                      <span>
                        {configuracoes.dados_empresa.endereco.estado
                          ? " " + configuracoes.dados_empresa.endereco.estado
                          : " sigla"}
                        <br></br>
                      </span>
                      <span>
                        CEP:
                        {configuracoes.dados_empresa.endereco.cep
                          ? " " + configuracoes.dados_empresa.endereco.cep
                          : " 00000-000"}
                      </span>
                      {configuracoes.dados_empresa.endereco.complemento && (
                        <div>
                          Complemento:{" "}
                          {configuracoes.dados_empresa.endereco.complemento}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mockupContainer">
            <div className="cabecalho" style={styles.cabecalho} />
            {categorias && (
              <div className="categorias">
                {Array(7)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <div
                        key={index}
                        className="categoria"
                        style={styles.categoria}
                      >
                        {index !== 0 &&
                          configuracoes.catalogo
                            .mostrar_botao_compartilhamento_categoria && (
                            <div className="botaoCompartilhar">
                              <div className="botao">
                                <svg
                                  style={styles.compartilhar}
                                  width="4"
                                  height="3"
                                  viewBox="0 0 4 3"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.99938 2.07781C1.66683 2.08742 1.34483 2.11453 1.03738 2.21785C0.752695 2.31397 0.540177 2.48024 0.391393 2.70938C0.345203 2.7805 0.302566 2.85355 0.258152 2.92554C0.222955 2.98321 0.164551 3.01022 0.102706 2.99647C0.0374184 2.98196 -0.000110788 2.93765 2.4568e-07 2.87268C2.4568e-07 2.60577 0.0085498 2.33934 0.0666202 2.07608C0.12358 1.81725 0.227951 1.57706 0.440025 1.38041C0.615902 1.21701 0.83386 1.11388 1.07625 1.04506C1.36105 0.964231 1.65539 0.935973 1.95341 0.9254C1.96718 0.9254 1.98083 0.923959 1.99971 0.922998V0.883591C1.99971 0.631259 1.99971 0.378926 1.99971 0.126594C1.99971 0.0733463 2.02314 0.0325938 2.07999 0.0106798C2.13683 -0.0112343 2.18835 0.00183728 2.23266 0.0383608C2.23665 0.0417248 2.24087 0.0448965 2.24487 0.0479722L3.72317 1.22874C3.79756 1.28823 3.87251 1.34734 3.94645 1.40732C4.01752 1.46499 4.01818 1.53496 3.94645 1.59195C3.37434 2.04933 2.80203 2.50655 2.22955 2.9636C2.18569 2.99859 2.13572 3.0107 2.08021 2.98946C2.02469 2.96822 1.99915 2.92727 1.99915 2.87412C1.9996 2.62422 1.9996 2.37433 1.99915 2.12443L1.99938 2.07781ZM2.26586 2.62086L3.66887 1.50036L2.26597 0.379182V0.428393C2.26597 0.625427 2.26597 0.82243 2.26597 1.0194C2.26597 1.11013 2.216 1.15242 2.11052 1.15396C2.01892 1.1555 1.92731 1.15665 1.83593 1.16155C1.58056 1.17511 1.32829 1.20596 1.0869 1.28458C0.819092 1.37185 0.605242 1.51525 0.470559 1.73776C0.372184 1.90115 0.326882 2.07704 0.300901 2.25783C0.293239 2.31195 0.288132 2.36625 0.280581 2.43084C0.294127 2.41815 0.298902 2.41431 0.302788 2.40989C0.505646 2.18123 0.776234 2.04138 1.09112 1.95882C1.43188 1.86934 1.78231 1.84627 2.13595 1.84618C2.21034 1.84618 2.26519 1.89673 2.2663 1.96248C2.26752 2.03331 2.2663 2.10415 2.2663 2.17508C2.26597 2.3206 2.26597 2.46583 2.26597 2.62086H2.26586Z"
                                    fill={configuracoes.catalogo.cor_secundaria}
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
            )}
            {banner && <div className="banner" />}
            <div className="filtroVariacoes">
              {(configuracoes.mostrar_grupos ||
                configuracoes.mostrar_variacoes) && (
                <div className="variacoes">
                  {configuracoes.mostrar_grupos && (
                    <div className="porCategoria">
                      {Array(5)
                        .fill(0)
                        .map((_, index) => {
                          return (
                            <div
                              key={index}
                              className="categoria"
                              style={{
                                backgroundColor:
                                  index === 1
                                    ? configuracoes.catalogo.cor_primaria
                                    : "#D9D9D9",
                              }}
                            />
                          );
                        })}
                    </div>
                  )}
                  {configuracoes.mostrar_variacoes && (
                    <div className="porVariacao">
                      {Array(5)
                        .fill(0)
                        .map((_, index) => {
                          return (
                            <div
                              key={index}
                              className="variacao"
                              style={{
                                backgroundColor:
                                  index === 0
                                    ? configuracoes.catalogo.cor_primaria
                                    : "#D9D9D9",
                              }}
                            />
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="produtos" style={styles.produtos}>
              {Array(produtos)
                .fill(0)
                .map((_, index) => {
                  return (
                    <div key={index} className="produto">
                      <div className="imagem">
                        <div className="fundoImagem" />
                        <div className="botaozinho" style={styles.botaozinho} />
                      </div>
                      {configuracoes.catalogo
                        .mostrar_botao_compartilhamento_produto && (
                        <div className="botaoCompartilhar">
                          <div className="botao" style={styles.botao}>
                            <svg
                              style={styles.compartilhar}
                              width="4"
                              height="3"
                              viewBox="0 0 4 3"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.99938 2.07781C1.66683 2.08742 1.34483 2.11453 1.03738 2.21785C0.752695 2.31397 0.540177 2.48024 0.391393 2.70938C0.345203 2.7805 0.302566 2.85355 0.258152 2.92554C0.222955 2.98321 0.164551 3.01022 0.102706 2.99647C0.0374184 2.98196 -0.000110788 2.93765 2.4568e-07 2.87268C2.4568e-07 2.60577 0.0085498 2.33934 0.0666202 2.07608C0.12358 1.81725 0.227951 1.57706 0.440025 1.38041C0.615902 1.21701 0.83386 1.11388 1.07625 1.04506C1.36105 0.964231 1.65539 0.935973 1.95341 0.9254C1.96718 0.9254 1.98083 0.923959 1.99971 0.922998V0.883591C1.99971 0.631259 1.99971 0.378926 1.99971 0.126594C1.99971 0.0733463 2.02314 0.0325938 2.07999 0.0106798C2.13683 -0.0112343 2.18835 0.00183728 2.23266 0.0383608C2.23665 0.0417248 2.24087 0.0448965 2.24487 0.0479722L3.72317 1.22874C3.79756 1.28823 3.87251 1.34734 3.94645 1.40732C4.01752 1.46499 4.01818 1.53496 3.94645 1.59195C3.37434 2.04933 2.80203 2.50655 2.22955 2.9636C2.18569 2.99859 2.13572 3.0107 2.08021 2.98946C2.02469 2.96822 1.99915 2.92727 1.99915 2.87412C1.9996 2.62422 1.9996 2.37433 1.99915 2.12443L1.99938 2.07781ZM2.26586 2.62086L3.66887 1.50036L2.26597 0.379182V0.428393C2.26597 0.625427 2.26597 0.82243 2.26597 1.0194C2.26597 1.11013 2.216 1.15242 2.11052 1.15396C2.01892 1.1555 1.92731 1.15665 1.83593 1.16155C1.58056 1.17511 1.32829 1.20596 1.0869 1.28458C0.819092 1.37185 0.605242 1.51525 0.470559 1.73776C0.372184 1.90115 0.326882 2.07704 0.300901 2.25783C0.293239 2.31195 0.288132 2.36625 0.280581 2.43084C0.294127 2.41815 0.298902 2.41431 0.302788 2.40989C0.505646 2.18123 0.776234 2.04138 1.09112 1.95882C1.43188 1.86934 1.78231 1.84627 2.13595 1.84618C2.21034 1.84618 2.26519 1.89673 2.2663 1.96248C2.26752 2.03331 2.2663 2.10415 2.2663 2.17508C2.26597 2.3206 2.26597 2.46583 2.26597 2.62086H2.26586Z"
                                fill={configuracoes.catalogo.cor_secundaria}
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                      <div className="variacoes">
                      {Array.from({ length: mostrarVariacoes ? 11 : Math.min(limiteVariacoes, 11) }).map((_, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="variacao"
                                style={styles.variacao}
                              >
                                <div className="fundoVariacao">
                                  {Number.parseInt(
                                    configuracoes.catalogo
                                      .quantidade_colunas_padrao.mobile
                                  ) === 1 &&
                                    informacoesVariacoes && (
                                      <>
                                        <div className="variacaoEstoque">
                                          <div className="nomeVariacao">
                                            {`Variação ${index + 1}`}
                                          </div>
                                          {configuracoes.catalogo
                                            .mostrar_estoque && (
                                            <div className="estoque">
                                              {`Estoque: ${Math.floor(
                                                Math.random() * 11
                                              )}`}
                                            </div>
                                          )}
                                        </div>
                                        <div>R$ 0,00</div>
                                        <div>0</div>
                                      </>
                                    )}
                                </div>
                                <div className="bolinha" style={styles.bolinha}>
                                  <div
                                    className="fundoBolinha"
                                    style={styles.fundoBolinha}
                                  ></div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                        {limiteVariacoes < 11 && (
                          <div
                            className="expandir"
                            style={styles.expandir}
                            onClick={toggleMostrarVariacoes}
                          >
                            {mostrarVariacoes
                              ? "Clique para reduzir"
                              : "Clique para expandir"}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </MockupCatalogoContainer>
    </>
  );
};
