import React, { useEffect } from "react";
import "./styles";
import { CodigoRastreioContainer, ModalContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Swal from "sweetalert2";
import { Input } from "../../Input";
import { PedidosApi } from "../../../hooks/pedidosApi";
import LoadingSRC from "../../../assets/jsons/Loading.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Modal from "../../Modal/Modal";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Button } from "../../Button";
import Form from "../../Form";
import { MelhorEnvioApi } from "../../../hooks/melhorEnvio";
import { Select } from "../../Select";

interface CodigoRastreioProps {
  pedido: any;
  frete: any;
  empresa: any;
  changeFretes: any;
}

const NotaFiscal: React.FC<CodigoRastreioProps> = ({
  pedido,
  frete,
  empresa,
  changeFretes,
}) => {
  const { theme } = useTheme();
  const melhorEnvioApi = MelhorEnvioApi();
  const animation = LoadingSRC;

  const [needNfe, setNeedNfe] = React.useState<boolean>(false);
  const [agencias, setAgencias] = React.useState<any>([]);
  const [agenciaSelecionada, setAgenciaSelecionada] = React.useState<any>({});

  const [nfe, setNfe] = React.useState<string>("");
  const [formValid, setFormValid] = React.useState<boolean>(false);

  const [mostrarNfe, setMostrarNfe] = React.useState<boolean>(true);

  const [modalNotaFiscal, setmodalNotaFiscal] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleGerarEtiqueta = async () => {
    if (!pedido) return;
    setLoading(true);
    melhorEnvioApi.gerarEtiqueta(pedido, nfe, agenciaSelecionada).then(
      (res) => {
        changeFretes(res.codigo_rastreio, res.url_etiqueta);
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Etiqueta gerada com sucesso!",
        });
        setmodalNotaFiscal(false);
        setLoading(false);
      },
      (err) => {
        Swal.fire({
          icon: "error",
          showConfirmButton: true,
          title: err.response.data.erro,
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const init = async () => {
      melhorEnvioApi.consultarAgencias(pedido?.frete.dados_frete).then(
        (res) => {
          if (res) {
            setAgencias(
              res.map((agencia: any) => {
                return {
                  value: agencia.id,
                  name: agencia.name,
                };
              })
            );
          }
        },
        (err) => {
          console.log(err);
        }
      );

      if (frete && frete.toLowerCase().includes("jadlog")) {
        setNeedNfe(true);
        setFormValid(false);
      } else {
        if (frete && frete.toLowerCase().includes("azul")) {
          setMostrarNfe(false);
        }
        setNeedNfe(false);
        setFormValid(true);
      }
    };

    init();
  }, [frete]);

  return (
    <>
      <Modal
        isOpen={modalNotaFiscal}
        setModal={setmodalNotaFiscal}
        width={"98vw"}
        maxWidth="75rem"
        onUseEffect={() => {}}
      >
        <ModalContainer theme={theme}>
          <Titulo titulo={`Nota Fiscal`} subtitulo="" justifyContent="center" />
          <div className="descricao">
            {needNfe ? (
              <>
                Como você está cadastrado como pessoa jurídica, com CNPJ, no
                Conecta Venda o frete exige o envio de nota fiscal. Para
                continuar, adicione a chave da nota fiscal.
              </>
            ) : (
              <>
                Você pode enviar o frete com a nota fiscal vinculada, caso tenha
                essa informação adicione a chave da nota fiscal abaixo. Caso
                envie apenas a declaração, ela será impressa junto com a
                etiqueta, basta gerar a etiqueta.
              </>
            )}
          </div>
          <Form
            id="formCodigoRastreio"
            onSubmit={handleGerarEtiqueta}
            setFormValid={setFormValid}
          >
            {agencias.length > 0 && (
              <Select
                value={agenciaSelecionada}
                onChange={(newValue) => setAgenciaSelecionada(newValue)}
                options={agencias}
                placeholder="Selecione a agência"
                required
              />
            )}
            {mostrarNfe && (
              <Input
                value={nfe}
                type="text"
                onChange={(newValue) => setNfe(newValue.replace(/\D/g, ""))}
                maxLength={255}
                showMaxLength={false}
                placeholder="Chave da nota fiscal"
              />
            )}
            <div className="botoes">
              <div
                className="cancelar"
                onClick={() => {
                  setmodalNotaFiscal(false);
                }}
              >
                Cancelar
              </div>
              {loading ? (
                <>
                  <Player
                    autoplay
                    loop
                    src={animation}
                    style={{ height: "3rem", width: "3rem" }}
                  >
                    <Controls
                      visible={false}
                      buttons={["play", "repeat", "frame", "debug"]}
                    />
                  </Player>
                </>
              ) : (
                <Button
                  title="Gerar etiqueta"
                  onClick={handleGerarEtiqueta}
                  disabled={!formValid}
                />
              )}
            </div>
          </Form>
        </ModalContainer>
      </Modal>
      <CodigoRastreioContainer theme={theme}>
        {loading ? (
          <>
            <Player
              autoplay
              loop
              src={animation}
              style={{ height: "3rem", width: "3rem" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          </>
        ) : (
          <>
            <Button
              title="Gerar etiqueta"
              className="botao"
              fontSize="2.5rem"
              onClick={() => {
                setmodalNotaFiscal(true);
              }}
            />
          </>
        )}
      </CodigoRastreioContainer>
    </>
  );
};

export default NotaFiscal;
