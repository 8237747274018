import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

const TituloContainer = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  .titulo {
    font-size: 2.5rem !important;
    color: ${({ theme }) => theme.colors.brandSecondary} !important;
  }
  .subtitulo {
    font-size: 3rem !important;
    font-weight: bold !important;
    color: ${({ theme }) => theme.colors.titleColor} !important;
  }
`;

export { TituloContainer };
