import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CatalogosApi } from "../../hooks/catalogos";
import { EmpresaApi } from "../../hooks/empresaApi";
import { CatalogoUnico } from "../../types/Catalogo";
import { Intermediadores } from "../../types/Configuracoes";
import { CatalogoContext } from "./CatalogoContext";
import { listasDePrecoApi } from "../../hooks/listasDePreco";
import ListaDePreco from "../../types/ListaDePreco";

interface CatalogoProviderProps {
  children: JSX.Element;
}

export const CatalogoProvider: React.FC<CatalogoProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const listaDePrecosApi = listasDePrecoApi();
  const [listasDePrecos, setListasDePrecos] = useState<ListaDePreco[]>([]);

  useEffect(() => {
    //forcar um update na pagina quando o catalogo for duplicados
    if (
      location.pathname.includes("duplicado") &&
      !location.pathname.includes("#avancado")
    ) {
      init();
    }
  }, [location]);

  const [loading, setLoading] = useState(false);
  const catalogoApi = CatalogosApi();
  const empresaApi = EmpresaApi();

  const optionsAjustePreco = [
    { value: "aumentar", name: "Aumentar" },
    { value: "diminuir", name: "Diminuir" },
  ];
  const [ajustePreco, setAjustePreco] = useState(optionsAjustePreco[0].value);
  const { id } = useParams<{ id: string }>();
  let { duplicated } = useParams<{ duplicated: string }>();

  if (duplicated != "duplicado") {
    duplicated = "";
  }

  const [indice, setIndice] = useState<string | number>(0);

  const [outrosUsuarios, setOutrosUsuarios] = useState<
    { email: string; id: string }[]
  >([]);
  const [empresaIntermediadores, setEmpresaIntermediadores] =
    useState<Intermediadores>();

  const [catalogo, setCatalogo] = useState<CatalogoUnico>({
    id: id || "0",
    id_empresa: "",
    id_tabela: "",
    id_usuario: "",
    id_catalogo_original: "",
    data_vencimento: "2100-01-01 00:00",
    indice: "1",
    descricao: "",
    imagem: [],
    imagem_desktop: [],
    minimo_valor: "",
    minimo_quantidade: "",
    intermediadores: {},
    identificacao: "",
    configuracoes: {
      adicionarTodosProdutos: false,
      mostrarPrecoOriginal: false,
    },
    produtos: [],
    principal: "false",
    outros_usuarios: [],
    duplicado: false,
  });

  const init = async () => {
    setLoading(true);

    const [catalogoData, outrosUsuariosData, intermediadores] =
      await Promise.all([
        catalogo.id != "0" ? catalogoApi.getCatalogo(catalogo.id) : catalogo,
        catalogoApi.getOutrosUsuarios(catalogo.id),
        empresaApi.getIntermediadores(),
      ]);

      const [listaDePrecosData] = await Promise.all([
        listaDePrecosApi.getListas(),
      ]);
  
      setListasDePrecos(
        listaDePrecosData.map((lista) => {
          return {
            ...lista,
            value: lista.id,
            name: lista.descricao,
          };
        })
      );
      if (catalogo.id === "0") {
        setCatalogo({
          ...catalogo,
          id_tabela: listaDePrecosData[0].id,
      });
    }

    // Se for duplicado
    if (duplicated && catalogoData) {
      catalogoData.id = "";
      catalogoData.identificacao = "";
      catalogoData.descricao = catalogoData.descricao + " - Cópia";
      catalogoData.outros_usuarios = [];
      catalogoData.principal = "false";
      catalogoData.duplicado = true;
      delete catalogoData.id_catalogo_original;
    }

    catalogoData && setCatalogo(catalogoData);
    !outrosUsuariosData.erro && setOutrosUsuarios(outrosUsuariosData || []);
    intermediadores && setEmpresaIntermediadores(intermediadores);

    // Ajusta o índice conforme se é desconto ou acréscimo
    if (catalogoData) {
      let indicePercentual: string | number = Number(catalogoData.indice);
      if (indicePercentual > 1 && indicePercentual !== 0) {
        indicePercentual = Number(
          ((indicePercentual - 1) * 100).toFixed(2)
        ).toFixed(2);
        setIndice(indicePercentual.replace(".", "")); // remove o ponto pra ficar inteiro sempre
        setAjustePreco("aumentar");
      } else if (indicePercentual < 1 && indicePercentual !== 0) {
        indicePercentual = Number(
          ((1 - indicePercentual) * 100).toFixed(2)
        ).toFixed(2);

        setIndice(indicePercentual.replace(".", ""));
        setAjustePreco("diminuir");
      } else {
        setIndice("");
        setAjustePreco("aumentar");
      }
    }

    setLoading(false);
  };

  const salvarCatalogo = async () => {
    setLoading(true);
    const identificacao = await catalogoApi.salvarCatalogo(catalogo);
    setLoading(false);

    return identificacao;
  };

  const removerCatalogo = async () => {
    setLoading(true);
    try {
      await catalogoApi.removerCatalogo(catalogo.id);
      Swal.fire({
        title: "Catálogo removido com sucesso!",
        icon: "success",
        confirmButtonText: "Ok",
        timer: 1250,
      });
      setLoading(false);
      navigate("/painel/catalogos");
    } catch (error) {
      Swal.fire({
        title: "Erro ao remover catálogo!",
        text: "Ocorreu um erro ao remover o catálogo, tente novamente.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (catalogo.descricao !== "") {
      setLoading(false);
    }
  }, [catalogo]);

  useEffect(() => {
    init();
  }, []);

  return (
    <CatalogoContext.Provider
      value={{
        init,
        listasDePrecos,
        optionsAjustePreco,
        ajustePreco,
        setAjustePreco,
        indice,
        empresaIntermediadores,
        setIndice,
        catalogo,
        setCatalogo,
        setLoading,
        removerCatalogo,
        salvarCatalogo,
        loading,
        outrosUsuarios,
        setOutrosUsuarios,
      }}
    >
      {children}
    </CatalogoContext.Provider>
  );
};
