import styled from "styled-components";

export const ContainerEditarListas = styled.div`
  .grid-listas {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    .lista {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-gap: 10px;
    }
  }
  .linha-pai {
    display: flex;
    justify-content: center;
    .linha {
      margin: 4rem 0;
      height: 2px;
      background-color: ${(props) => props.theme.colors.elements};
      width: 40%;
    }
  }
  .nova-tabela {
    text-align: center;
    color: ${(props) => props.theme.colors.brandPrimary};
    margin-bottom: 4rem;
    span {
      cursor: pointer;
    }
  }
`;

export const GridListas = styled.div`
  display: flex;
  flex-direction: column;
  .form{
    display: contents;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const Lista = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const TituloLista = styled.div`
  flex: 1;
  text-align: center;
  .center {
    display: flex;
    justify-content: center;
  }
  span{
    cursor: pointer;
  }
`;

export const Botoes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .cancelar {
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.colors.brandPrimary};
      transform: rotate(90deg);
    }
  }
`;

export const CriarCategoriaContainer = styled.div`
  &.hidden {
    display: none;
  }

  .inputs_nome_moeda {
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 10px;
  }

  .tabela {
    margin: 25px 0;
  }

  .ajustes {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 25px;
  }

  .texto {
    text-align: center;
    font-size: 3rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    margin-bottom: 5rem;
  }

  .toggle {
    display: flex;
    margin: 15px 0;
    align-items: center;

    .info {
      margin-left: 10px;
      color: ${(props) => props.theme.colors.brandSecondary};
    }
  }
`;
