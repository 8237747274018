import Swal from "sweetalert2";
import "./styles.scss";
import { useRef, useState } from "react";
import { useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { Button } from "../Button";
import React from "react";
import { PromiseFileReader, getCroppedImg } from "../../services/Functions";
interface CroppingComponentProps {
  aspectRatio: number;
  minWidth?: number;
  minHeight?: number;
  resize?: {
    width: number;
    height: number;
  };
  value: { base64: string; imagem: string; size: number };
  circularCrop?: boolean;
  onChange: (base64: string, imagem: string, size: number) => void;
  children?: React.ReactNode;
  onLoading: (loading: boolean) => void;
  onCloseCrop?: () => void;
  onOpenCrop?: (
    cropping: JSX.Element,
    cancelar: JSX.Element,
    salvar: JSX.Element,
  ) => JSX.Element;
}

export const CroppingComponent: React.FC<CroppingComponentProps> = ({
  onChange,
  onCloseCrop,
  aspectRatio,
  value,
  minWidth,
  minHeight,
  resize,
  circularCrop = false,
  onLoading,
  onOpenCrop,
}) => {
  const [base64, setBase64] = useState<string>("");
  const [imagem, setImagem] = useState<string>("");
  const [size, setSize] = useState<number>(0);

  const imageRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<Crop>();

  const [modal, setModal] = useState<boolean>(false);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectRatio) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspectRatio));
    }
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  const handleOnSaveCrop = (crop: Crop) => {
    onLoading(true);
    const imageExtension = imagem.split(".").pop()!;
    getCroppedImg(imageRef.current!, crop, imageExtension, resize).then(
      (croppedImageBlob) => {
        const file = new File([croppedImageBlob], imagem, {
          type: `image/${imageExtension}`,
        });
        PromiseFileReader(file).then(
          (result) => {
            // result.base64 is the base64 of cropped image
            // pass this to the onChange function
            onChange(result.base64, result.imagem, result.size);
            setModal(false);
            onLoading(false);
          },
          (err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              text: `Ocorreu um erro ao tentar recortar esta imagem!`,
            });
            onLoading(false);
          },
        );
      },
    );
  };

  useEffect(() => {
    if(!modal) {
      onLoading(false)
      onCloseCrop && onCloseCrop()
    }
  }, [modal]);

  useEffect(() => {
    if (base64 !== "") {
      setModal(true);
    }
  }, [base64]);

  useEffect(() => {
    if (value.base64 === "") {
      setBase64("");
      setImagem("");
      setSize(0);
    } else {
      setBase64(value.base64);
      setImagem(value.imagem);
      setSize(value.size);
      setModal(true);
    }
  }, [value]);

  return (
    <>
      {modal && (
        <>
          {onOpenCrop &&
            onOpenCrop(
              <>
                <div className="imagem-container">
                  <div className="imagem">
                    <ReactCrop
                      className="crop-custom-class"
                      aspect={aspectRatio}
                      minWidth={
                        minWidth
                          ? minWidth! /
                            (imageRef.current?.naturalWidth! /
                              imageRef.current?.width!)
                          : 0
                      } // para cropar conforme a escala
                      minHeight={
                        minHeight
                          ? minHeight! /
                            (imageRef.current?.naturalHeight! /
                              imageRef.current?.height!)
                          : 0
                      }
                      keepSelection={true}
                      circularCrop={circularCrop}
                      crop={crop}
                      onChange={(crop, percentCrop) => setCrop(percentCrop)}
                    >
                      <img ref={imageRef} src={base64} onLoad={onImageLoad} />
                    </ReactCrop>
                  </div>
                </div>
              </>,
              <>
                <div className="cancelar" onClick={() => setModal(false)}>
                  Cancelar
                </div>
              </>,
              <>
                <Button
                  title="Salvar"
                  className="salvar"
                  onClick={() => handleOnSaveCrop(crop!)}
                />
              </>,
            )}
        </>
      )}
    </>
  );
};
