import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../../assets/newImages/icons/plus.svg";
import { Button } from "../../components/Button/index.tsx";
import ComponentInfiniteScroll from "../../components/ComponentInfiniteScroll/ComponentInfiniteScroll.tsx";
import FloatingButton from "../../components/FloatingButton/FloatingButton.tsx";
import { Input } from "../../components/Input/index.tsx";
import Modal from "../../components/Modal/Modal.tsx";
import ProdutoComSkeleton from "../../components/Produto/index.tsx";
import { Select } from "../../components/Select/index.tsx";
import { SelectListagem } from "../../components/Select/SelectListagem/index.tsx";
import SkeletonProdutos from "../../components/Skeletons/SkeletonProdutos/SkeletonProdutos.tsx";
import { ConfiguracoesProvider } from "../../contexts/Configuracoes/ConfiguracoesProvider.tsx";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { IntegracaoProvider } from "../../contexts/Integracao/IntegracaoProvider.tsx";
import { ProdutosContext } from "../../contexts/Produtos/ProdutosContext.tsx";
import { ScrollDirectionContext } from "../../contexts/ScrollDirection/ScrollDirectionContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { configuracoesApi } from "../../hooks/configuracoesApi.ts";
import ImportarPorErp from "./ImportarPorErp/ImportarPorErp.tsx";
import "./styles.ts";
import { MeusProdutosView, ModalNovoProduto } from "./styles.ts";

interface ProdutosProps {}

const Produtos: React.FC<ProdutosProps> = ({}) => {
  const { theme } = useTheme();
  const [modalErp, setModalErp] = useState(false);
  const scrollDir = useContext(ScrollDirectionContext).direction;

  const {erp_empresa} = useContext(EmpresaContext);

  const ConfiguracoesApi = configuracoesApi();

  const [config_integracao, setConfigIntegracao] = useState({
    permitir_produtos: false,
    permitir_imagens: false,
    permitir_observacoes: false,
  });

  useEffect(() => {
    const init = async () => {
      const res = await ConfiguracoesApi.verifica_config_integracoes();
      setConfigIntegracao({
        permitir_produtos: res.upload_produtos,
        permitir_imagens: res.upload_imagens,
        permitir_observacoes: res.upload_configurar_observacao,
      });
    };
    init();
  }, []);

  const optionsCadastro = [
    {
      value: "individualmente",
      name: "Individualmente",
    },
    {
      value: "em_massa",
      name: "Em massa",
    },
    {
      value: "erp",
      name: "ERP",
    },
  ];

  const [seletorMobile, setSeletorMobile] = useState(false);

  const [cadastroSelecionado, setCadastroSelecionado] = useState(() => {
    const cadastro = localStorage.getItem("cadastro_prod");
    if (cadastro) {
      return cadastro;
    }
    return "individualmente";
  });

  const navigate = useNavigate();

  const [semCadastroSelecionado, setSemCadastroSelecionado] = useState(false);

  function cadastrarNovo() {
    if (cadastroSelecionado == "individualmente") {
      navigate("/painel/produtos/produto/0");
    } else if (cadastroSelecionado == "em_massa") {
      navigate("/painel/produtos/importar-produtos");
    } else if (cadastroSelecionado == "erp") {
      setModalErp(true);
    }
  }

  const {
    loading,
    setLoading,
    newProductModal,
    produtos,
    setNewProductModal,
    produtosFiltrados,
    filtros,
    setFiltros,
    filtroPesquisaAux,
    setFiltroPesquisaAux,
    categorias,
    optionsAtivos,
    optionsEstoque,
  } = useContext(ProdutosContext);

  useEffect(() => {
    localStorage.getItem("cadastro_prod") == null
      ? setSemCadastroSelecionado(true)
      : setSemCadastroSelecionado(false);
  }, [semCadastroSelecionado]);

  const ChildrenNewProduct = (
    <ModalNovoProduto theme={theme}>
      <div className="titulo">
        Como gostaria de
        <br />
        <span>adicionar seus produtos?</span>
        <div className="link">
          <Link
            to={"/painel/produtos/produto/0"}
            className="center"
            onClick={() => {
              localStorage.setItem("cadastro_prod", "individualmente");
            }}
          >
            Adicionar individualmente
          </Link>
        </div>
        <div className="link">
          <Link
            to={"/"}
            className="center"
            onClick={() => {
              localStorage.setItem("cadastro_prod", "em_massa");
            }}
          >
            Adicionar produtos em massa
          </Link>
        </div>
        <div className="link">
          <Link
            to={"/"}
            className="center"
            onClick={() => {
              localStorage.setItem("cadastro_prod", "erp");
            }}
          >
            Adicionar através do ERP
          </Link>
        </div>
      </div>
    </ModalNovoProduto>
  );

  return (
    <>
      <Modal
        backdrop={"static"}
        isOpen={modalErp}
        setModal={setModalErp}
        width={"auto"}
      >
        <ConfiguracoesProvider>
          <IntegracaoProvider>
            <ImportarPorErp onClose={() => setModalErp(!modalErp)} />
          </IntegracaoProvider>
        </ConfiguracoesProvider>
      </Modal>
      <MeusProdutosView theme={theme}>
        <div className="grid-header">
          <div className="input pesquisa">
            <Input
              type={"text"}
              icone="icons/search.svg"
              placeholder={"Pesquisar por nome do produto, descrição ou SKU"}
              value={filtroPesquisaAux}
              onChange={(newValue) => {
                newValue === filtros.filtroPesquisa
                  ? setLoading(false)
                  : setLoading(true);
                setFiltroPesquisaAux(newValue);
              }}
              onDebounce={(newValue) =>
                setFiltros({ ...filtros, filtroPesquisa: newValue })
              }
              debounceTime={500}
            />
          </div>
          <div className="select categorias">
            <Select
              options={categorias}
              value={filtros.filtroCategoria}
              onChange={(newValue) => {
                setFiltros({ ...filtros, filtroCategoria: newValue });
              }}
            />
          </div>
          <div className="select ativos">
            <Select
              options={optionsAtivos}
              value={filtros.filtroAtivos}
              onChange={(newValue) => {
                setFiltros({ ...filtros, filtroAtivos: newValue });
              }}
            />
          </div>
          <div className="select estoques">
            <Select
              options={optionsEstoque}
              value={filtros.filtroEstoque}
              onChange={(newValue) => {
                setFiltros({ ...filtros, filtroEstoque: newValue });
              }}
            />
          </div>
          <div className="botoes">
            {erp_empresa && (
              <Link className="botao" to={"informacoes-erp"}>
                <div className="botao">
                  <Button title="Informações ERP"></Button>
                </div>
              </Link>
            )}
            {!config_integracao.permitir_produtos && config_integracao.permitir_imagens && (
              <Link className="botao" to={"/painel/produtos/importar-imagens"}>
                <div className="botao">
                  <Button title="Importar Imagens"></Button>
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className="produtos">
          {config_integracao.permitir_produtos ? (
            <>
              <div
                className={`center hideMobile ${
                  semCadastroSelecionado ? "inativo" : ""
                }`}
              >
                <div
                  className="adicionar_produto"
                  onClick={() => {
                    if (semCadastroSelecionado) {
                      setNewProductModal(true);
                    } else {
                      cadastrarNovo();
                    }
                  }}
                >
                  <div className="pai">
                    <Plus className="plus" />
                  </div>
                </div>
                <div className="cadastro">
                  <div className="texto">Cadastro</div>
                  <div className="select">
                    <SelectListagem
                      options={optionsCadastro}
                      onChange={(newValue) => {
                        localStorage.setItem("cadastro_prod", newValue);
                        setCadastroSelecionado(newValue);
                      }}
                      value={cadastroSelecionado}
                      cores={"white"}
                      disabled={semCadastroSelecionado}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`options-mobile ${
                  seletorMobile && scrollDir === "up" ? "ativo" : ""
                } hideDesktop`}
              >
                <div
                  className={`option primeiro ${
                    cadastroSelecionado == "individualmente" ? "ativo" : ""
                  }`}
                  onClick={() => {
                    localStorage.setItem("cadastro_prod", "individualmente");
                    setCadastroSelecionado("individualmente");
                    setSeletorMobile(false);
                  }}
                >
                  Adicionar individualmente
                </div>
                <div
                  className={`option ${
                    cadastroSelecionado == "em_massa" ? "ativo" : ""
                  }`}
                  onClick={() => {
                    localStorage.setItem("cadastro_prod", "em_massa");
                    setCadastroSelecionado("em_massa");
                    setSeletorMobile(false);
                  }}
                >
                  Adicionar produtos em massa
                </div>
                <div
                  className={`option ultimo ${
                    cadastroSelecionado == "erp" ? "ativo" : ""
                  }`}
                  onClick={() => {
                    localStorage.setItem("cadastro_prod", "erp");
                    setCadastroSelecionado("erp");
                    setSeletorMobile(false);
                  }}
                >
                  Adicionar através do ERP
                </div>
              </div>
              <FloatingButton
                subButton={true}
                onClickSubButton={() => {
                  setSeletorMobile(!seletorMobile);
                }}
                className="hideDesktop"
                onClick={() => {
                  if (semCadastroSelecionado) {
                    setNewProductModal(true);
                  } else {
                    cadastrarNovo();
                  }
                }}
              />
            </>
          ) : null}

          {loading ? (
            <SkeletonProdutos />
          ) : (
            <>
              {produtosFiltrados.length > 0 && (
                <>
                  {produtosFiltrados.map((produto, index) => (
                    <ComponentInfiniteScroll
                      className="produto-container"
                      key={index}
                    >
                      <Link to={`/painel/produtos/produto/${produto.id}`}>
                        <ProdutoComSkeleton produto={produto} />
                      </Link>
                    </ComponentInfiniteScroll>
                  ))}
                </>
              )}
            </>
          )}
          {!loading &&
            produtosFiltrados.length === 0 &&
            produtos.length > 0 && (
              <>
                <div className="mensagem">
                  <div className="texto-titulo">
                    <div>Nenhum resultado encontrado para sua busca.</div>
                  </div>
                  <div className="texto-subtitulo">
                    <div>Tente novamente com uma busca diferente!</div>
                  </div>
                </div>
              </>
            )}
        </div>
      </MeusProdutosView>
      <Modal
        isOpen={newProductModal}
        setModal={setNewProductModal}
        children={ChildrenNewProduct}
        width="100rem"
        maxWidth="98vw"
        align="center"
        backdrop="static"
        justify="center"
        animation="opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        margin="0rem 2rem 0rem 2rem"
      />
    </>
  );
};

export default Produtos;
