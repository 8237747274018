import styled from "styled-components";

export const ContainerToggle = styled.div`
  display: flex;
  align-items: center;
`;

export const Switch = styled.label`
  position: relative !important;
  display: flex;
  justify-content: center;
  width: calc(30px + 1rem);
  height: calc(8px + 1rem);

  /* Hide default HTML checkbox */
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.elements};
    transition: 0.4s;
    border-radius: calc(8px + 1rem);

    &:before {
      position: absolute;
      content: "";
      height: calc(2px + 1rem);
      width: calc(2px + 1rem);
      left: 4px;
      bottom: 3px;
      background-color: ${(props) => props.theme.colors.brandPrimary};
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  & input:checked + .slider {
    background-color: ${(props) => props.theme.colors.brandPrimary};
  }

  & input:checked + .slider:before {
    transform: translateX(calc(14px + 1rem));
    background-color: ${(props) => props.theme.colors.white};
  }
`;
