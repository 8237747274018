import styled from "styled-components";

export const ContainerMelhorEnvio = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
    .containerInput {
        width: 100%;
    }
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 2rem;
    `;

export const Grids = styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2%;
        width: 100%;
        @media(max-width: 768px) {
            grid-template-columns: 100%;
        }
        
`;