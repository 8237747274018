import styled from "styled-components";
import theme from "../../../Themes/light";
import { ThemeType } from "../../../types/Theme";

const FreteEspecialMainContainer = styled.div<{theme: ThemeType}>`
  width: 100%;
  .dropdown {
    .novo {
      cursor: pointer;
      font-size: 2.5rem;
      margin-top: 0;
      text-align: left;
      color: ${({theme}) => theme.colors.brandPrimary};
    }
  }
`;

export { FreteEspecialMainContainer };
