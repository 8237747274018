import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const QuillContainer = styled.div<{theme: ThemeType, maxLength?: number, length: number}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .hidden-textarea{
        display: none;
    }
    .react-quill-length{
        color: ${({theme, maxLength, length}) => (maxLength && length > maxLength) ? theme.colors.brandDanger : theme.colors.brandSecondary};
        font-size: 2.5rem;
        position: absolute;
        top: 1.5rem;
        right: 3rem;
    }
`;

export { 
    QuillContainer,
};