import styled, { keyframes } from "styled-components";
import theme from "../../Themes/light";

const { colors } = theme;

export const ContainerFooterPaginas = styled.div`
  display: contents;
  .container-footer-paginas {
    position: fixed;
    z-index: 199;
    background: ${(props) => props.theme.colors.background1};
    bottom: 0;
    width: 100%;
    right: 10rem;
    padding-left: calc(clamp(120px, 7%, 9999px) + 20rem);
    padding-bottom: 8rem;
    font-size: 2.5rem;
    transition: 0.2s;
    .children-footer {
      padding-top: 3rem;
    }

    .linha-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 2px;
      background-color: ${(props) => props.theme.colors.elements};

      @media (max-width: 1024px) {
        > .arrow {
          width: 3.8rem;
          height: 3.8rem;
          overflow: hidden;
          background-color: ${(props) => props.theme.colors.elements};
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;

          > span {
            transition: all 0.3s linear;
            position: absolute;
            width: 1.5rem;
            height: 2px;
            background-color: ${(props) => props.theme.colors.brandPrimary};
            display: inline-block;
            border-radius: 50px;
            color: ${(props) =>
              props.theme.mode == "light"
                ? props.theme.colors.brandPrimary
                : props.theme.colors.brandSecondary};

            &:first-of-type {
              left: 0.69rem;
              transform: rotate(-45deg);
            }
            &:last-of-type {
              right: 0.69rem;
              transform: rotate(45deg);
            }
          }
        }

        .active {
          > span {
            &:first-of-type {
              transform: rotate(45deg);
            }
            &:last-of-type {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      right: 0;
      padding: 0 3rem 11rem 3rem;
    }
  }

  .container-footer-paginas-ativo {
    @media (max-width: 1024px) {
      padding-bottom: 3rem;
    }
  }

  .container-footer-paginas-animation {
    @media (min-width: 1024px) {
      animation: ${keyframes`
        from {
          transform: translateY(40%);
        }
        to {
          transform: translateY(0);
        }
      `} 0.1s linear;
    }
  }

  .container-footer-paginas-escondido {
    @media (max-width: 1024px) {
      transform: translateY(calc(100% - 11rem));
    }
  }

  .container-footer-paginas-escondido-ativo {
    @media (max-width: 1024px) {
      transform: translateY(calc(100% - 3rem));
    }
  }
`;
