import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const transitionTime = "1s";
const SkeletonBlingContainer = styled.div<{theme: ThemeType}>`
    display: grid;
    grid-template-columns: 10rem 1fr 1fr 1fr;
    
    grid-gap: 1rem;
    height: 70rem;
    grid-template-areas:
    "sk0 sk0 sk0 sk0"
    "sk1 sk1 sk1 none"
    "sk2 sk3 null none"
    "sk4 sk5 null none"
    "sk6 sk7 sk7 none"
    "sk8 sk9 null1 none"
    "sk10 sk11 sk11 sk11"
    "sk12 sk12 sk13 sk13"
    "sk14 sk14 sk14 sk14";
    >div {
    width: 100%;
    height: 5rem;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 16px;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.1s);
    @keyframes pulsate {
      0% {
        background-color: ${(props) => props.theme.colors.skeleton1};
      }

      100% {
        background-color: ${(props) => props.theme.colors.skeleton2};
      }
    }
  }
`;

export { 
    SkeletonBlingContainer,
};