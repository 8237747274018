import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "0.8s";

const SkeletonRelatoriosContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 3fr 3fr 3fr 3fr 3fr 3fr;
  grid-gap: 5rem;
  .skeleton-relatorios-item {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.white};
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.01s);
    &:nth-of-type(n + 4) {
      grid-column: 1 / 4;
    }
  }
`;

export default SkeletonRelatoriosContainer;
