import { PedidosContext } from "./PedidosContext";
import { useState, useEffect } from "react";
import { PedidosApi } from "../../hooks/pedidosApi";
import { FiltrosPedidos } from "../../types/PedidoListagem";
import moment from "moment";

export const PedidosProvider = ({ children }: { children: JSX.Element }) => {
  const api = PedidosApi();

  const saveToLocalStorage = (filtros: FiltrosPedidos) => {
    const dataToSave = { filtros };
    localStorage.setItem("pedidosSettings", JSON.stringify(dataToSave));
  };

  const getFromLocalStorage = () => {
    const storedData = localStorage.getItem("pedidosSettings");
    return storedData ? JSON.parse(storedData) : null;
  };

  // Estado inicial dos filtros e filtroPesquisaAux
  const localStorageData = getFromLocalStorage();

  const [filtros, setFiltros] = useState<FiltrosPedidos>(
    localStorageData?.filtros || {
      filtroPesquisa: "",
      filtroUsuario: "",
      FiltroCatalogo: "",
      FiltroDataInicio: `${moment().subtract(30, "days").format("YYYY-MM-DD")} 00:00:00`,
      FiltroDataFim: `${moment().format("YYYY-MM-DD")} 23:59:59`,
      placeholder: "Últimos 30 dias",
    }
  );
  

  useEffect(() => {
    saveToLocalStorage(filtros);
  }, [filtros]);


  return (
    <PedidosContext.Provider value={{ setFiltros, filtros }}>
      {children}
    </PedidosContext.Provider>
  );
};
