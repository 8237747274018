import React from "react";
import { TituloContainer } from "./styles";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";
interface TituloProps {
  titulo: string;
  subtitulo: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
  flexDirection?: "row" | "column" | "column-reverse" | "row-reverse";
}

const Titulo: React.FC<TituloProps> = ({
  titulo,
  subtitulo,
  justifyContent,
  flexDirection = "row",
  margin,
  padding,
}) => {
  const { theme } = useTheme();

  return (
    <>
      <TituloContainer theme={theme}>
        <div className="tituloContainer">
          <div
            className="titulo"
            style={{
              justifyContent: justifyContent && justifyContent,
              flexDirection: flexDirection && flexDirection,
              margin: margin && margin,
              padding: padding && padding,
              gap:
                flexDirection === "column" || flexDirection === "column-reverse"
                  ? "1rem"
                  : "2rem",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: subtitulo }}
              className="subtitulo"
              style={{
                width:
                  flexDirection === "column" ||
                  flexDirection === "column-reverse"
                    ? "100%"
                    : "auto",
              }}
            ></div>
            {flexDirection === "row" && subtitulo != "" && (
              <div className="barra">/</div>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: titulo }}
              className="laranja"
              style={{
                width:
                  flexDirection === "column" ||
                  flexDirection === "column-reverse"
                    ? "100%"
                    : "auto",
              }}
            ></div>
          </div>
        </div>
      </TituloContainer>
    </>
  );
};

export default Titulo;
