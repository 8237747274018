import React from "react";
import "./styles";
import { ModalNovidadesContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ReactComponent as Arrow } from "../../../assets/newImages/icons/arrowLeft.svg";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import Titulo from "../../../components/Titulo/Titulo";

interface ModalNovidadesProps {
  onClose?: () => void;
  href?: string;
  title: string;
  subtitle?: string;
  text: string;
}

const ModalNovidades: React.FC<ModalNovidadesProps> = ({
  onClose,
  href,
  title,
  subtitle,
  text,
}) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <ModalNovidadesContainer theme={theme}>
      <Titulo title={title} subtitle={subtitle} />
      <div className="texto" dangerouslySetInnerHTML={{ __html: text }}></div>
      <div className="botoes">
        <div className="arrow" onClick={onClose}>
          <Arrow />
          Voltar
        </div>
        <a href={href} target="_blank">
          {href ? <Button title="Conheça +" /> : null}
        </a>
      </div>
    </ModalNovidadesContainer>
  );
};

export default ModalNovidades;
