import styled, { css } from "styled-components";

export const ContainerChaveApi = styled.div`
  text-align: left;
  color: ${(props) => props.theme.colors.brandSecondary};

  .bloco-input {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 3rem;
    .pai-botao {
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      margin: 2rem 0;
    }
    @media (max-width: 470px) {
      flex-direction: column;
      align-items: flex-start;
      .pai-botao {
        margin-top: 2rem;
      }
    }
  }
`;

export const ContainerConfiguracoes = styled.div`
  .texto {
    text-align: justify;
  }
  margin-top: 4rem;
  color: ${(props) => props.theme.colors.brandSecondary};
  .config {
    display: flex;
    margin: 2rem 0;
    .title {
      margin-left: 2rem;
    }
  }
  .linha {
    width: 20%;
    height: 2px;
    background-color: ${(props) => props.theme.colors.elements};
    border-radius: 50px;
  }

  .title_ERP {
      font-weight: bold;
      margin-left: 2rem;
      text-align: left;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
`;
