import Swal from "sweetalert2";
import Pagamento from "../types/Pagamento";
import { api } from "./useApi";

export const PedidosApi = () => ({
  getCamposPersonalizados: async () => {
    try {
      const response = await api.get(
        "/usuario/configuracoes_campos_personalizados",
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  setCamposPersonalizados: async (campos: {}) => {
    try {
      const response = await api.post(
        "/usuario/atualizar_campos_personalizados_pedidos",
        { campos: campos },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  getPedidos: async (
    campos: string[],
    datas: { inicio: string; fim: string },
    filtro: {
      usuario: string;
      catalogo: string;
      pesquisa: string;
      erp_empresa: string | boolean;
    },
  ) => {
    try {
      const response = await api.post("pedidos/listar_todos", {
        campos: campos,
        datas: datas,
        filtro: filtro,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  getSituacoes: async () => {
    try {
      const response = await api.get("pedido/situacoes");
      return response.data as { id: number | string; descricao: string }[];
    } catch (error) {
      console.log(error);
      return [{ id: 0, descricao: "" }];
    }
  },

  alterarSituacao: async (
    ids_pedidos: number[] | string[],
    situacao_id: number,
  ) => {
    const response = await api
      .post("pedidos/altera_situacao_em_lote", {
        ids_pedidos: ids_pedidos,
        situacao_id: situacao_id,
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Erro ao alterar pedido(s).",
          showConfirmButton: false,
          timer: 1000,
        });
        throw error.response;
      });
    return response.data;
  },

  getPedido: async (id_pedido: string) => {
    try {
      const response = await api.post(`pedido/listar`, {
        ids_pedidos: [id_pedido],
      });
      return response.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  getPedidosImpressao: async (ids_pedidos: string[]) => {
    try {
      const [pedidos, informacoesPagamento] = await Promise.all([
        api.post(`pedido/listar`, { ids_pedidos: ids_pedidos }),
        api.post(`pedido/select_pagamento`, { ids_pedidos: ids_pedidos }),
      ]);

      const pedidosImpressao = pedidos.data.map((pedido: any) => {
        const pagamento = informacoesPagamento.data.find(
          (pagamento: any) => pagamento.id_pedido === pedido.id_pedido,
        );
        return { ...pedido, informacoesPagamento: { ...pagamento } };
      });
      return pedidosImpressao;
    } catch (error) {
      console.log(error);
    }
  },

  getInformacoesPagamento: async (id_pedido: string) => {
    try {
      const response = await api.post(`pedido/select_pagamento`, {
        ids_pedidos: [id_pedido],
      });
      return response.data[0] as Pagamento;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },

  atualizarObservacaoInternaPedido: async (
    id_pedido: string,
    observacao: string,
  ) => {
    try {
      const response = await api.post(`pedido/inserir_observacao_interna`, {
        id_pedido: id_pedido,
        observacao_interna: observacao,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  atualizarCodigoDeRastreio: async (
    id_pedido: string | number,
    codigo_rastreio: string,
  ) => {
    if(!id_pedido) return;
    const response = await api.post(`pedido/inserir_codigo_rastreio`, {
      id_pedido: id_pedido,
      codigo_rastreio: codigo_rastreio,
    });
    return response.data;
  },

  exportarPedidos: async (ids_pedidos: string[]) => {
    const response = await api.post("pedidos/exportar", {
      ids_pedidos: ids_pedidos,
    });
    console.log(response.data);
    return response.data;
  },
});
