import SkeletonProduto from "./SkeletonProduto";
import "./styles.ts";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import theme from "../../../Themes/light";

const SkeletonProdutos: React.FC = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      {Array(30)
        .fill(0)
        .map((_, index) => {
          return (
            <SkeletonProduto
              style={{ "--i": `${index}` } as React.CSSProperties}
              key={index}
            />
          );
        })}
    </>
  );
};

export default SkeletonProdutos;
