import ContainerCatalogoUnicoSkeleton from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonCatalogoProps {}

const SkeletonCatalogo: React.FC<SkeletonCatalogoProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <ContainerCatalogoUnicoSkeleton
        className="container-catalogo-unico-skeleton"
        theme={theme}
      >
        <div className="grid1">
          {Array(9)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  style={
                    {
                      "--i": `${index}`,
                      gridArea: `zika${index}`,
                    } as React.CSSProperties
                  }
                  key={index}
                  className="item-skeleton"
                />
              );
            })}
        </div>
        <div className="grid2">
          {Array(10)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  style={
                    {
                      "--i": `${index}`,
                      gridArea: `zika${index}`,
                    } as React.CSSProperties
                  }
                  key={index}
                  className="item-skeleton"
                />
              );
            })}
        </div>
      </ContainerCatalogoUnicoSkeleton>
    </>
  );
};

export default SkeletonCatalogo;
