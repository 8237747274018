import React from "react";
import "./styles";
import { SkeletonGuiaCriacaoContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonGuiaCriacaoProps {}

const SkeletonGuiaCriacao: React.FC<SkeletonGuiaCriacaoProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <SkeletonGuiaCriacaoContainer theme={theme}>
      <div className="subtitulo"></div>
      <div className="bolinhas">
        {Array(5)
          .fill(0)
          .map((step, index) => {
            return (
              <div className="bolinha-wrapper">
                <div className={`bolinha-container`}></div>
                <div className={`label`}></div>
              </div>
            );
          })}
      </div>
    </SkeletonGuiaCriacaoContainer>
  );
};

export default SkeletonGuiaCriacao;
