import React, { useContext, useEffect, useState } from "react";
import "./styles";
import { AjudaContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { Button } from "../Button";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext";
import { Toggle } from "../Toggle/Toggle";
import { PerfilApi } from "../../hooks/perfilApi";

interface AjudaProps {}

const Ajuda: React.FC<AjudaProps> = ({}) => {
  const { theme } = useTheme();
  const Perfil = PerfilApi();
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const [usuarioAtivo, setUsuarioAtivo] = useState(false);

  useEffect(() => {
    Perfil.verificaUsuarioSuporte().then((response) => {
      setUsuarioAtivo(response);
    });
  }, []);

  return (
    <>
      <AjudaContainer theme={theme}>
        <div className="titulo">Precisa de ajuda?</div>
        <div className="texto">Podemos te ajudar, escolha uma opção:</div>
        <div className="botoes">
          <a
            href={`https://api.whatsapp.com/send?phone=555434434563&text=Ol%C3%A1,+tenho+uma+d%C3%BAvida+sobre+o+ConectaVenda.+Meu+usu%C3%A1rio+%C3%A9:+${empresa?.usuario}+da+empresa:+${empresa?.empresa}.`}
            target="_blank"
          >
            <Button title="Atendimento via WhatsApp" width="100%" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC_hQtVZCfjkOfLMJLeeq74A"
            target="_blank"
          >
            <Button title="Vídeos do Youtube" width="100%" />
          </a>
        </div>
        <div className="suporte">
          <div className="toggle">
            <Toggle
              value={usuarioAtivo}
              onChange={() => {
                if (usuarioAtivo) {
                  Perfil.desabilitarUsuarioSuporte().then(() => {
                    setUsuarioAtivo(false);
                  });
                } else {
                  Perfil.habilitarUsuarioSuporte().then(() => {
                    setUsuarioAtivo(true);
                  });
                }
              }}
            />
          </div>
          <div>
            Usuário de suporte
            <br />
            <span>Libere acesso aos nossos especialistas</span>
          </div>
          {/*        <span>Usuário de atendimento</span> <br />
          <span>Libere acesso aos nossos especialistas</span> */}
        </div>
        <div className="titulo">Acompanhe nosso Instagram</div>
        <div className="texto">
          <a href="https://www.instagram.com/conectavenda/" target="_blank">
            @conectavenda
          </a>
        </div>
      </AjudaContainer>
    </>
  );
};

export default Ajuda;
