import styled from "styled-components";
import theme from "../../../../Themes/light";

export const TituloContainer = styled.div`
  width: 100%;

  .titulo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 5rem;
    width: 100%;
    padding: 0 3rem;

    .subtitulo {
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandSecondary};
    }

    .barra {
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandSecondary};
    }

    .laranja {
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.titleColor};
      font-size: 3rem;
    }
  }
  @media (max-width: 400px) {
    .titulo {
      padding: 0;
      gap: 1rem !important;
    }
  }
`;
