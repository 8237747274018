import styled, { css } from "styled-components";
import theme from "../../../Themes/light";

const { colors } = theme;

const commonStyles = css`
  padding: 0 5rem;
  width: 100rem;
  max-width: 90vw;

  .gridInput {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;

    @media (max-width: 435px) {
      grid-template-columns: 1fr;
    }
  }

  .exclusao {
    margin-top: 4rem;
    padding: 2rem 0;
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.brandPrimary};

    span {
      cursor: pointer;
    }
  }
`;

export const ContainerFreteEspecial = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      padding: 2rem 0rem;
      width: 100%;
      max-width: none;
      .exclusao {
        margin: 0;
        padding: 3rem 0 0 0;
      }
    `}
`;
