import { api } from "./useApi";

export const categoriasApi = () => ({
  criarCategoria: async (descricao: string, id_pasta: number) => {
    const pasta = {
      descricao: descricao,
      id_pasta: id_pasta,
    };
    const response = await api.post("pasta/criar", pasta);
    return response.data;
  },
  verificarNomeCategoria: async (descricao: string) => {


    const response = await api.post("pasta/verificar_nome", { descricao: descricao });

    return response.data;
  },
  editarCategorias: async (pastas) => {
    const response = await api.post("pasta/editar", { pastas: pastas });
    return response.data;
  },
});
