import React from "react";
import "./styles";
import { SkeletonPerfilContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonPerfilProps {}

const SkeletonPerfil: React.FC<SkeletonPerfilProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <SkeletonPerfilContainer theme={theme}>
      {Array(7)
        .fill(0)
        .map((_, i) => (
          <div
            className={`perfil-skeleton`}
            key={i}
            style={
              { "--i": `${i + 1}`, gridArea: `sk${i}` } as React.CSSProperties
            }
          />
        ))}
    </SkeletonPerfilContainer>
  );
};

export default SkeletonPerfil;
