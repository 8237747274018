import { SetStateAction, useEffect, useState } from "react";
import { Button } from "../Button";
import PopoverForm from "../PopoverForm/PopoverForm";
import { AsideMainContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface AsideMenuProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  titulo: string;
  subtitulo: string;
  children?: React.ReactNode;
  onAplicar?: () => void;
  onCancelar?: () => void;
  onOpen?: () => void;
  disabled?: boolean;
}

const AsideMenu: React.FC<AsideMenuProps> = ({
  isOpen,
  setIsOpen,
  titulo,
  subtitulo,
  onAplicar,
  onCancelar,
  children,
  onOpen,
  disabled,
}) => {
  const { theme } = useTheme();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOnAplicar = () => {
    if (!disabled) {
      if (onAplicar) {
        onAplicar();
      }
      handleClose();
    }
  };

  const handleOnCancelar = () => {
    if (onCancelar) {
      onCancelar();
    }
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      if (onOpen) {
        onOpen();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      event.preventDefault();
      if (event.key === "Escape") {
        handleOnCancelar();
        window.removeEventListener("keydown", handleEsc);
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    } else {
      window.removeEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen]);

  return (
    <AsideMainContainer className={`aside-main`} theme={theme}>
      <div
        className={`fundo ${isOpen && "fundo-ativo"}`}
        onClick={handleOnCancelar}
      ></div>
      <div className={`aside-container ${isOpen && "aside-ativo"}`}>
        <div className="titulo">{titulo}</div>
        <div className="subtitulo">{subtitulo}</div>
        <div className="children">
          {children}
          <PopoverForm
            position="95%"
            mensagem="Escolha uma opção"
            ativo={disabled}
          />
        </div>
        <div className="footer">
          <Button
            disabled={disabled}
            onClick={handleOnAplicar}
            title={"Aplicar"}
          />
          <div className="cancelar" onClick={handleOnCancelar}>
            Cancelar
          </div>
        </div>
      </div>
    </AsideMainContainer>
  );
};

export default AsideMenu;
