import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const CurseducaContainer = styled.div<{theme: ThemeType}>`
    padding: 5rem;
    .titulo{
        font-size: 3rem;
        text-align: center;
        font-weight: bold;
        color: ${({theme}) => theme.colors.titleColor};
        margin-bottom: 2rem;
    }
    .texto{
        font-size: 2.5rem;
        text-align: justify;
        margin-bottom: 3rem;
    }
    .inputs{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 3rem;
    }
`;

export { 
    CurseducaContainer,
};