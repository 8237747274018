import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

const transitionTime = "1s";
const SkeletonPerfilContainer = styled.div<{ theme: ThemeType }>`
  padding: 15rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
  grid-template-areas:
    "sk0 sk0"
    "sk1 sk1"
    "sk2 sk2"
    "sk3 sk3"
    "sk4 sk4"
    "sk5 sk6";
  .perfil-skeleton {
    width: 100%;
    height: 6rem;
    border-radius: 4rem;
    background: ${({ theme }) => theme.colors.white};
    animation-delay: calc(var(--i) * 0.01s);
    animation: pulsate ${transitionTime} ease-out infinite alternate;
  }
`;

export { SkeletonPerfilContainer };
