import React from 'react';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { ImportarArquivoContainer } from './styles';

interface ImportarArquivoProps {
  onVoltar?: () => void;
}

const ImportarArquivo: React.FC<ImportarArquivoProps> = ({onVoltar}) => {

  const {theme} = useTheme();

  return (
    <ImportarArquivoContainer  theme={theme}>
      <>
        <p>
          Você pode cadastrar vários produtos de uma vez, através da
          importação de um arquivo .CSV.
        </p>
        <p>
          <span>Orientações</span>
        </p>
        <ul>
          <li>
            Você vai precisar de um arquivo no formato .CSV (Excel), com os dados separados por ponto e vírgula (;), vírgula (,) ou ponto (.)
          </li>
          <li>
            Cada coluna do arquivo deve conter as informações que você vai
            cadastrar e cada linha representa um produto;
          </li>
          <li>
            <b>A primeira linha de cada coluna deve indicar qual é a informação a ser cadastrada. </b>
          </li>
          <li>
            Todo produto deve conter as seguintes informações: Nome | SKU | Categoria; <br />
            Os demais campos são opcionais, sendo eles: Variação | Preço | Peso (g) | Estoque | Descrição | Multiplicador | Quantidade Mínima
          </li>
          <li>
            O campo estoque é opcional, caso não seja informado nenhum valor, será considerado estoque ilimitado.
          </li>
          <li>
            As variações serão agrupadas pelo campo <b>SKU</b>.
          </li>
        </ul>
        
      </>
    </ImportarArquivoContainer >
  )
}

export default ImportarArquivo;