import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "1s";
const ContainerProdutosListagemSkeleton = styled.div`
  width: 100%;
  height: 50rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 16px;
  animation: pulsate ${transitionTime} ease-out infinite alternate;
  animation-delay: calc(var(--i) * 0.1s);
`;

export default ContainerProdutosListagemSkeleton;
