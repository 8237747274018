import React, { useContext } from 'react';
import { ReactComponent as ArrowLeft } from "../../assets/newImages/icons/arrowLeft.svg";
import { ReactComponent as Plus } from "../../assets/newImages/icons/plus.svg";
import { CatalogoContext } from '../../contexts/Catalogo/CatalogoContext';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import './styles';
import { BannersShowcaseContainer } from './styles';

interface BannersShowcaseProps {
    type: 'imagem_desktop' | 'imagem';
}

const BannersShowcase: React.FC<BannersShowcaseProps> = ({
    type
}) => {

    const {theme} = useTheme();

    const { catalogo, setCatalogo } = useContext(CatalogoContext);

    const onRemoveBanner = (index: number) => {
        setCatalogo((prevCatalogo) => {
          const imagens = [...prevCatalogo[type]];
          imagens.splice(index, 1);
          return {
            ...prevCatalogo,
            [type]: imagens,
          };
        });
      }
    
      const onChangeBannerOrder = (oldIndex: number, newIndex: number) => {
        setCatalogo((prevCatalogo) => {
          const imagens = [...prevCatalogo[type]];
          // Move the image directly without intermediate removal and insertion
          if (oldIndex < newIndex) {
            for (let i = oldIndex; i < newIndex; i++) {
              [imagens[i], imagens[i + 1]] = [imagens[i + 1], imagens[i]];
            }
          } else {
            for (let i = oldIndex; i > newIndex; i--) {
              [imagens[i], imagens[i - 1]] = [imagens[i - 1], imagens[i]];
            }
          }
          return {
            ...prevCatalogo,
            [type]: imagens,
          };
        });
      }


    return (
        <BannersShowcaseContainer theme={theme}>
            <div className="banners">
                {catalogo[type].map((image, index) => (
                    <div className="banner" key={index}>
                        <img src={typeof image === 'string' ? image : image.base64} alt="Banner" />
                        {catalogo[type].length > 1 && (
                            <>
                                {index !== 0 && (
                                    <div className="left" onClick={() => onChangeBannerOrder(index, index - 1)}>
                                        <ArrowLeft />
                                    </div>
                                )}
                                {index !== catalogo[type].length - 1 && (
                                    <div className="right" onClick={() => onChangeBannerOrder(index, index + 1)}>
                                        <ArrowLeft />
                                    </div>
                                )}
                            </>
                        )}
                        <div className="remove" onClick={() => onRemoveBanner(index)}>
                            <Plus />
                        </div>
                    </div>
                ))}
                {Array(3 - catalogo[type].length % 3).fill(0).map((_, index) => (
                    <div key={`empty-${index}`} className="emptyBanner" />
                ))}
            </div>
        </BannersShowcaseContainer>
    )
}

export default BannersShowcase;