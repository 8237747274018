import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

export const Button = styled.button<{ theme: ThemeType }>`
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.mode === 'dark' ? theme.colors.brandSecondary : theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brandPrimary};
  font-weight: ${({ theme }) => theme.mode === 'dark' ? 'bold' : 'normal'};
  border-radius: 3rem;
  padding: 1rem 5rem;
  z-index: 1;
  .loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;
