import { api } from "./useApi";

export const curseducaApi = () => ({
    cadastrar: async (email: string, senha: string) => {
        try {
            const response = await api.post('curseduca/ativar-membro', {email, senha});
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    }
});
