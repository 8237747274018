import styled from "styled-components";
import theme from "../../../Themes/light";

export const ContainerCesta = styled.div`
  padding: 10rem 10rem 20rem 10rem;

  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem min(7vw, 15rem);
    justify-content: space-between;
    width: 100%;
    gap: 2rem;

    .select-cesta-unico {
      width: 43rem;

      .view-select-cestas {
        .select {
          transition: 0.3s;
          background-color: transparent;
          border-color: transparent;

          &:hover {
            border-color: transparent;
          }
        }

        .ativo {
          background-color: ${(props) => props.theme.colors.white};
          border-color: ${(props) => props.theme.colors.brandPrimary};
        }
      }
    }
  }

  .column-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 40rem;
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
    height: 8rem;

    .pix-div {
      display: flex;
      gap: 1rem;
      align-items: center;

      > .botao {
        background-color: ${(props) => props.theme.colors.elements};
        color: ${(props) => props.theme.colors.brandSecondary};
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:focus {
          opacity: 0.8 !important;
        }
      }

      > .icone {
        cursor: pointer;
        width: 3.5rem;
      }
    }
  }

  #form-gravar-obs-cesta {
    width: 100%;

    .obs-gravar {
      padding: 0 min(7vw, 15rem);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      column-gap: 8rem;
      row-gap: 2rem;
      justify-content: flex-end;
      margin-bottom: 2rem;

      .input-div {
        flex-grow: 1;
        input {
          background-color: ${(props) => props.theme.colors.background1};
          color: ${(props) => props.theme.colors.brandSecondary};
        }
      }
    }
  }

  .container-branco {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 24px;
    margin-top: 5rem;
    padding: 2rem 0;

    .grid-container {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
      column-gap: 2rem;
      width: 100%;
      padding: 3rem min(7vw, 15rem);
      color: ${(props) => props.theme.colors.brandSecondary};
      font-size: 2.5rem;
      justify-items: center;

      > span {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 3rem;

        &:first-child {
          justify-self: start;
        }
      }

      > .flex-produto {
        width: 100%;
        display: grid;
        grid-template-columns: 15rem auto;
        gap: 2rem;
        align-items: start;
        justify-items: start;
        text-align: left;
        font-size: 2.5rem;
        color: ${(props) => props.theme.colors.brandSecondary};

        .produto-imagem {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }

      > .produto-obs {
        grid-column-start: 1;
        grid-column-end: 7;
        -ms-grid-column-span: 7;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;

        > label {
          font-weight: bold;
        }

        &:not(:last-child) {
          &::after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: ${(props) => props.theme.colors.elements};
            margin-bottom: 3rem;
            margin-top: 3rem;
            border-radius: 50px;
          }
        }
      }
    }
  }

  .titulo-cesta {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    font-weight: normal;
  }

  .subtitulo-cesta {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    font-weight: bold;

    span {
      font-size: 3.5rem;
    }

    > .bolinha {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 1rem;
      z-index: 1;
    }
  }

  .linha {
    width: 100%;
    height: 2px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.elements};
  }

  .linha-menor {
    width: 70%;
    height: 2px;
    border-radius: 50px;
    background-color: ${(props) => props.theme.colors.elements};
  }

  .flex-botoes-cesta {
    display: flex;
    padding-top: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .flex-direita {
      gap: 2rem;
      display: flex;
      flex-wrap: wrap;

      .botao-bottom {
        color: ${(props) => props.theme.colors.brandSecondary};
        background-color: ${(props) => props.theme.colors.elements};
        font-weight: normal;
      }
    }

    .botao-bottom {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      white-space: normal;
    }
  }

  @media (max-width: 1024px) {
    padding: 5rem 3rem 30rem 3rem;
  }
  
  .popover2 {
    transform: translateX(-100%);  
      &::after {
        transform: translateX(-97%);
      }
  }

  .popover1 {
    transform: translateX(-100%);
      &::after {
        transform: translateX(-97%);
      }
  }

  @media (max-width: 715px) {
    .flex-botoes-cesta {
      width: 100%;
      gap: 1rem;

      .flex-direita {
        display: contents;
      }

      .botao-bottom {
        flex-grow: 1;
        min-width: 10rem;
      }
    }
  }

  @media print {
    display: none;
  }

  @media screen {
    display: block;
  }
`;
