import { api } from "./useApi";

export const relatoriosApi = () => ({
  relatorios: async () => {
    let response = await api.post("/relatorios/select_all");
    response = response.data;
    return response as any;
  },

  gerarRelatorio: async (id_relatorio: string, variaveis: object) => {
    let response = await api.post("/relatorios/listar", {
      id_relatorio,
      variaveis,
    });
    return response.data;
  },

  exportarRelatorio: async (id_relatorio: string, variaveis: object) => {
    let response = await api.post("/relatorios/exportar", {
      id_relatorio,
      variaveis,
    });
    return response.data[0];
  },
});
