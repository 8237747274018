import React, { useContext } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Toggle } from "../../Toggle/Toggle";
import { copy } from "../../../services/Functions";
import { ContainerFreteEspecialListagem } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface FreteEspecialListagemProps {
  onEditarFrete: (index: number) => void;
  onNovoFrete?: () => void;
  configIn: "empresa" | "configuracoes";
}

const FreteEspecialListagem: React.FC<FreteEspecialListagemProps> = ({
  onEditarFrete,
  onNovoFrete,
  configIn,
}) => {
  const { theme } = useTheme();

  const { fretes_especiais, setFretes_especiais } =
    useContext(ConfiguracoesContext);

  return (
    <>
      <ContainerFreteEspecialListagem configIn={configIn} theme={theme}>
        <div className="containerFreteEspecialListagem">
          {configIn === "empresa" && (
            <Titulo
              titulo="Frete Especial"
              subtitulo={"Gerencie os seus fretes especiais"}
              flexDirection="column-reverse"
            />
          )}
          <div className="fretesFlexContainer">
            <div className="fretesContainer">
              {fretes_especiais.map((frete, indexFrete) => {
                return (
                  <>
                    <div className="freteContainer" key={indexFrete}>
                      <span className="descricao">{frete.descricao}</span>
                      <span className="editarContainer">
                        <Toggle
                          value={fretes_especiais[indexFrete].ativo}
                          onChange={(newValue) => {
                            const copia = copy(fretes_especiais);
                            copia[indexFrete].ativo = newValue;
                            setFretes_especiais(copia);
                          }}
                        ></Toggle>
                        <span
                          onClick={() => {
                            onEditarFrete(indexFrete);
                          }}
                          className={`editar ${
                            !fretes_especiais[indexFrete].ativo && "disabled"
                          }`}
                        >
                          Editar
                        </span>
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          {configIn === 'empresa' && (
            <div
              className="novo"
              onClick={() => {
                onNovoFrete && onNovoFrete();
              }}
            >
              Configure um novo frete especial
            </div>
          )}
        </div>
      </ContainerFreteEspecialListagem>
    </>
  );
};
export default FreteEspecialListagem;
