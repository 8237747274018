import React, { useEffect, useState } from "react";
import { ContainerOpcoesDeFrete } from "./styles";
import svgImage from "../../../../assets/newImages/modais/opcoesDeFrete.svg";
import svgImagePlaceholder from "../../../../assets/newImages/modais/opcoesDeFretePlaceholder.svg";
import LoadingComFundo from "../../../Loading/LoadingComFundo/LoadingComFundo";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";

const OpcoesDeFrete: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const { theme } = useTheme();

  return (
    <>
      <ContainerOpcoesDeFrete theme={theme}>
        <div className="containerImagem">
          {loading && <img className="imagem" src={svgImagePlaceholder}></img>}
          <img
            className="imagem"
            src={svgImage}
            onLoad={() => setLoading(false)}
          ></img>
        </div>
        <div className="containerTexto">
          Agora nos conte, <br className="hideMobile" />
          gostaria que no final <br className="hideMobile" />
          do pedido tenha
          <br />
          <span>opções de frete?</span>
        </div>
      </ContainerOpcoesDeFrete>
    </>
  );
};
export default OpcoesDeFrete;
