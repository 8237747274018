import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

const transitionTime = "1s";
const SkeletonGuiaCriacaoContainer = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 10rem;
  @keyframes pulsate {
    0% {
      background-color: ${(props) => props.theme.colors.skeleton1};
    }

    100% {
      background-color: ${(props) => props.theme.colors.skeleton2};
    }
  }
  .subtitulo {
    width: 30rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.colors.elements};
    border-radius: 0.5rem;
    margin: 1rem 0;
    justify-self: center;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
  }
  .bolinhas {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    .bolinha-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      .bolinha-container {
        animation: pulsate ${transitionTime} ease-out infinite alternate;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.elements};
      }
      &:not(:first-of-type) {
        .bolinha-container {
          &::before {
            content: "";
            position: absolute;
            background-color: ${({ theme }) => theme.colors.elements};
            top: -2rem;
            height: 2rem;
            width: 1px;
            z-index: 1;
          }
        }
      }
      &:not(:last-of-type) {
        .bolinha-container {
          &::after {
            content: "";
            position: absolute;
            background-color: ${({ theme }) => theme.colors.elements};
            height: 2rem;
            width: 1px;
            top: 100%;
            z-index: 1;
          }
        }
      }
    }
  }
  .label {
    font-weight: bold;
    display: block;
    font-size: 3rem;
    width: 30rem;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.elements};
    animation: pulsate ${transitionTime} ease-out infinite alternate;
  }
`;

export { SkeletonGuiaCriacaoContainer };
