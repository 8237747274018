import styled, { keyframes } from "styled-components";

const pulsateAnimation = keyframes`
  0% {
    background-color: ${(props) => props.theme.colors.skeleton1};
  }
  100% {
    background-color: ${(props) => props.theme.colors.skeleton2};
  }
`;

export const LoadingPadrao = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  min-height: 12rem;
  animation: ${pulsateAnimation} 1s linear infinite alternate;
`;

export const ImagemCarregada = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
