import React from "react";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { swallDadosSalvos } from "../../../services/Functions";
import { Titulo, Texto, Erro, InputsContainer, Botoes } from "../box_styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Form from "../../../components/Form";
import { validateEmail } from "../../../services/MasksValidations";

interface MailBoxProps {
    onChange: (newValue: string) => void;
    setMailBox: (newValue: boolean) => void;
}

export const MailBox: React.FC<MailBoxProps> = ({ onChange, setMailBox }) => {
    const { theme } = useTheme();

    const [formValid, setFormValid] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");

    const handleChange = (): void => {
        onChange(email);
        setMailBox(false);
        swallDadosSalvos();
    };

    return (
        <Form className="view-mailbox" id="form-mailbox" setFormValid={setFormValid} onSubmit={handleChange}>
            <Titulo theme={theme}>Alteração de e-mail</Titulo>
            <Texto theme={theme}>
                Informe o novo e-mail para ser vinculado a sua conta do Conecta
                Venda.
            </Texto>
            <InputsContainer theme={theme}>
                <Input
                    type="text"
                    value={email}
                    required={true}
                    isValid={{
                        isValidFunction: validateEmail,
                        dependencies: [email],
                    }}
                    invalidText="E-mail inválido"
                    onChange={(newValue) => {
                        setEmail(newValue);
                    }}
                    placeholder="E-mail"
                />
                <Botoes theme={theme}>
                    <Button
                        title="Alterar"
                        className={`botao_alterar ${!formValid ? "disabled" : ""}`}
                        width="50%"
                        fontSize="2.5rem"
                        type="submit"
                    />
                    <div
                        className="cancelar"
                        onClick={() => {
                            setMailBox(false);
                        }}
                    >
                        Cancelar
                    </div>
                </Botoes>
            </InputsContainer>
        </Form>
    );
};
