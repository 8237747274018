import styled, { css } from "styled-components";
import theme from "../../../Themes/light";

const { colors } = theme;

const commonStyles = css`
  width: 100rem;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .containerQuill {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 0rem 0 2rem 0;
    .hidden-input-wrapper {
      position: absolute;
      .hidden-input {
        width: 0;
        height: 0;
        background-color: rgba(0, 0, 0, 0);
        pointer-events: none;
      }
    }
  }

  .texto {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    text-align: justify;
    margin-bottom: 3rem;
    width: 80%;
  }
  .texto-obrigatorio {
    position: relative;
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    text-align: justify;
    width: 80%;
    padding: 4rem 0 0rem 0;
    display: flex;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 70%;
      height: 2px;
      border-radius: 50px;
      background-color: ${(props) => props.theme.colors.elements};
      font-size: 2.5rem;
    }
  }

  > .obrigatorio {
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 4rem 5rem;
    font-weight: normal;
  }
  input {
    accent-color: ${(props) => props.theme.colors.brandPrimary};
  }
`;

export const ContainerPoliticaDeTrocaeDevolucaoContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      max-width: 100%;
      .texto {
        width: 100%;
        text-align: justify;
        padding: 4rem 0 2rem 0;
        margin: 0;
      }
      .texto-obrigatorio {
        width: 100%;
        text-align: justify;
        justify-content: flex-start;
        margin: 0;
      }
      .containerQuill {
        width: 100%;
      }
      .obrigatorio {
        padding: 2rem 0 0 0;
        margin: 0;
        justify-content: flex-start;
        width: 100%;
      }
    `}
`;
