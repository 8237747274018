import styled, { css } from "styled-components";

export const ContainerIntegracaoBling = styled.div`
  margin: 5rem 0;
  color: ${(props) => props.theme.colors.brandSecondary};
  .config {
    display: flex;
    margin: 3rem 0;
    .title {
      margin-left: 2rem;
      text-align: left;
    }
  }

  .configs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin-top: 3rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .obs{
    font-size: 2rem;
    text-align: justify;
  }
  .urlContainer{
    text-align: left;
    .url{
      cursor: pointer;
      color: ${(props) => props.theme.colors.brandPrimary};
      text-align: left;
      word-break: break-all;
      &:hover{
        text-decoration: underline;
      }
    }
  }
`;
