import styled, { css } from "styled-components";

const commonStyles = css`
  display: contents;
  .containerCupom {
    padding: 0 10%;
    width: 100rem;
    max-width: 90vw;
    position: relative;
    .inputContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0rem 0 4rem 0;

      &::after {
        content: "";
        position: absolute;
        width: 35rem;
        height: 2px;
        border-radius: 50px;
        background-color: ${(props) => props.theme.colors.elements};
        bottom: 0;
      }
    }

    .containerToggles {
      padding: 4rem 0 0rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .containerTogglesInner {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .toggleConfigurar {
          .nome {
            color: ${(props) => props.theme.colors.brandSecondary};
          }
        }
      }
    }

    .exclusao {
      margin-top: 4rem;
      padding: 4rem 0;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandPrimary};
      cursor: pointer;
    }
  }
`;

export const ContainerCupom = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      .containerCupom {
        width: 100%;
        max-width: none;
        padding: 3rem 0;
        .inputContainer {
          width: 100%;
          padding: 0;
          &::after {
            display: none;
          }
        }
        .containerToggles {
          flex-direction: row;
          .containerTogglesInner {
            flex-direction: row;
            .toggleConfigurar {
              .nome {
                width: auto;
                margin-right: 1rem;
              }
            }
          }
        }
        .exclusao {
          margin-top: 0;
          padding: 2rem 0 0 0;
          text-align: left;
        }
      }
    `}
`;

