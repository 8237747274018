import styled from "styled-components";
import theme from "../../Themes/light";
import fotoLeo from "../../assets/newImages/login/fundo-login.jpg";

export const ViewLogin = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "D-DIN";
  overflow: auto;
  background-color: ${theme.colors.brandPrimary};
  background-image: linear-gradient(45deg, ${theme.colors.brandPrimary}, #000);

  @media (min-width: 992px) {
    background-image: url(${fotoLeo});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .texto {
    font-size: 34px;
    color: #ffffff;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    line-height: 1;
    font-family: "D-DIN-Bold";

    @media (min-width: 992px) {
      font-size: 38px;
      width: 350px;
    }
  }

  .login {
    border: 1px solid #9d9f9c;
    background-color: rgba(255, 255, 255, 0.075);
    border-radius: 5px;
    width: 100%;
    padding: 10px 15px;

    @media (min-width: 992px) {
      width: 350px;
    }

    .form-control {
      &:focus {
        border-color: ${theme.colors.brandPrimary};
        box-shadow: 0 0 5px ${theme.colors.brandPrimary};
      }
    }

    input {
      background-color: transparent;
      border: 1px solid ${theme.colors.elements};
      border-radius: 10px;
      color: white;
    }

    .logo {
      padding: 30px 0px;
      .logo-svg {
        width: 160px;
      }
    }

    .entrar {
      border-radius: 10px;
      color: ${theme.colors.white};
      font-size: 18px;
      line-height: 0.8;
      &:focus {
        outline: none;
      }
    }

    .esqueciSenha {
      border-radius: 10px;
      color: ${theme.colors.elements};
      background-color: transparent;
      font-size: 14px;
      line-height: 0.8;
      text-align: right;
      padding: 6px 5px;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .login-button {
      height: auto;
      border-radius: 10px;
      background-color: ${theme.colors.titleColor};
      color: ${theme.colors.white};
      width: 100%;
      font-size: 18px !important;
      padding: 2px 0;
    }
  }
`;
