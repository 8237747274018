import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

export const ContainerNovidades = styled.div<{ theme: ThemeType }>`
  padding: 10rem;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.brandSecondary};

  .banner {
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    cursor: pointer;
    svg,
    img {
      width: 100%;
      height: 100%;
    }
    .desktop{
      display: none;
    }
    .mobile{
      display: block;
    }
    @media (max-width: 600px) {
      .desktop{
        display: block;
      }
      .mobile{
        display: none;
      }
    }
  }
  .titulo-novidades {
    display: flex;
    position: relative;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    margin: 4rem 0 2rem 0;
    padding-right: 4rem;
    .arrow {
      cursor: pointer;
      padding: 1rem;
      position: absolute;
      right: 0;
      svg {
        stroke: ${({ theme }) => theme.colors.placeholder};
        transform: rotate(180deg);
      }
    }
    strong {
      color: ${({ theme }) => theme.colors.titleColor};
      font-size: 3rem;
    }
  }
  .atualizacoes {
    width: 100%;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(65rem, 1fr));
    .cardAtualizacao {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 4rem;
      padding: 4rem 4rem 10rem 4rem;
      display: flex;
      flex-direction: column;
      position: relative;
      .topo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        strong {
          font-size: 3rem;
          font-weight: bold;
        }
        .data {
          color: ${({ theme }) => theme.colors.placeholder};
        }
      }
      .texto {
        text-align: justify;
        span {
          font-weight: bold;
        }
      }
      .final {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 4rem;
        right: 4rem;
        .saiba-mais {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.elements};
          font-size: 2rem;
          padding: 0.5rem 3rem;
          border-radius: 5rem;
        }
      }
    }
  }
  #carousel-container {
    .conteudo {
      max-height: 46rem;
      border-radius: 2rem;
      overflow: hidden;
      img {
        width: 100%;
      }
      video {
        width: 100%;
      }
    }
    .parceiro {
      display: grid;

      grid-template-columns: 1fr;
      grid-template-rows:  40px 45px auto;
      background-color: ${({ theme }) => theme.colors.white};
      padding: 5rem;
      border-radius: 4rem;
      height: 100%;
      width: 100%;
      .imagem {
        max-width: 100%;
        svg,
        img {
          max-width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .titulo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .conhecer {
        margin-top: 4rem;
        background-color: ${({ theme }) => theme.colors.elements};
        font-size: 2rem;
        padding: 0.5rem 3rem;
        border-radius: 5rem;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 3rem;
    .titulo-novidades {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      justify-content: center;
    }
    .atualizacoes {
      .cardAtualizacao {
        padding: 2rem 2rem 10rem 2rem;
        .topo {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .atualizacoes {
      grid-template-columns: 1fr;
    }
  }
`;
