import React from "react";
import "./styles";
import { ProgressBarContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const { theme } = useTheme();

  return (
    <div
      style={{
        width: "100%",
        height: "1rem",
        backgroundColor: theme.colors.elements,
        position: "relative",
        borderRadius: "10rem",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          transition: "0.5s ease-in-out",
          position: "absolute",
          top: 0,
          left: 0,
          width: `${progress}%`,
          height: "100%",
          backgroundColor: theme.colors.brandPrimary,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
