import React, { useContext, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Input } from "../../Input";
import { copy, deepCopy } from "../../../services/Functions";
import { ContainerRetiradaNoLocalContainer } from "./styles";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import Dropdown from "../../Dropdowns/Dropdown";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface RetiradaNoLocalProps {
  configIn: "empresa" | "configuracoes";
}

const RetiradaNoLocal: React.FC<RetiradaNoLocalProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const dias = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];

  const { theme } = useTheme();

  const [diasParaUso, setDiasParaUso] = useState<string[]>(
    dias.filter(
      (dia) =>
        !configuracoes.frete.retirada_no_local.intervalos.some((intervalo) =>
          intervalo.dias.includes(dia),
        ),
    ),
  );

  return (
    <>
      <ContainerRetiradaNoLocalContainer configIn={configIn} theme={theme}>
        {configIn === "empresa" && (
          <Titulo
            titulo="Retirada no local"
            subtitulo={"Selecione os dias e os horários de retirada"}
            flexDirection="column-reverse"
          />
        )}
        <div className="dropdown">
          {configuracoes.frete.retirada_no_local.intervalos.map(
            (intervalo, indexIntervalo) => {
              return (
                <>
                  <div className="intervalos">
                    <div className="diasContainer">
                      <label>Dias da semana</label>
                      <div className="flexDias">
                        {dias.map((dia, indexDia) => {
                          return (
                            <div
                              key={indexDia}
                              className={`dia ${
                                !diasParaUso.includes(dia) && "ativo"
                              } ${
                                !diasParaUso.includes(dia) &&
                                !intervalo.dias.includes(dia) &&
                                "disabled"
                              }`}
                              onClick={() => {
                                if (
                                  diasParaUso.includes(dia) ||
                                  intervalo.dias.includes(dia)
                                ) {
                                  if (diasParaUso.includes(dia)) {
                                    setDiasParaUso(
                                      diasParaUso.filter(
                                        (item) => item !== dia,
                                      ),
                                    );
                                    const copia = copy(configuracoes);
                                    copia.frete.retirada_no_local.intervalos[
                                      indexIntervalo
                                    ].dias.push(dia);
                                    setConfiguracoes(copia);
                                  } else {
                                    const copiaDias = copy(diasParaUso);
                                    copiaDias.push(dia);
                                    setDiasParaUso(copiaDias);

                                    const copia = copy(configuracoes);
                                    copia.frete.retirada_no_local.intervalos[
                                      indexIntervalo
                                    ].dias.splice(
                                      copia.frete.retirada_no_local.intervalos[
                                        indexIntervalo
                                      ].dias.indexOf(dia),
                                      1,
                                    );

                                    if (
                                      copia.frete.retirada_no_local.intervalos[
                                        indexIntervalo
                                      ].dias.length === 0 &&
                                      copia.frete.retirada_no_local.intervalos
                                        .length > 1
                                    ) {
                                      copia.frete.retirada_no_local.intervalos.splice(
                                        indexIntervalo,
                                        1,
                                      );
                                    }

                                    setConfiguracoes(copia);
                                  }
                                }
                              }}
                            >
                              {dia[0].toUpperCase()}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="horariosContainer">
                      {intervalo.horarios.map((horario, indexHorario) => {
                        return (
                          <>
                            <div className="horariosFlex">
                              <div className="horarios">
                                <Input
                                  required={
                                    configuracoes.frete.retirada_no_local.ativo
                                  }
                                  label="Abre"
                                  alignLabel="center"
                                  type={"time"}
                                  value={horario.abre}
                                  placeholder={"8:00"}
                                  onChange={(newValue) => {
                                    const copia = copy(configuracoes);
                                    copia.frete.retirada_no_local.intervalos[
                                      indexIntervalo
                                    ].horarios[indexHorario].abre = newValue;
                                    setConfiguracoes(copia);
                                  }}
                                ></Input>
                                <Input
                                  required={
                                    configIn === "configuracoes" &&
                                    configuracoes.frete.retirada_no_local.ativo
                                      ? true
                                      : false
                                  }
                                  label="Fecha"
                                  alignLabel="center"
                                  type={"time"}
                                  value={horario.fecha}
                                  placeholder={"11:30"}
                                  onChange={(newValue) => {
                                    const copia = copy(configuracoes);
                                    copia.frete.retirada_no_local.intervalos[
                                      indexIntervalo
                                    ].horarios[indexHorario].fecha = newValue;
                                    setConfiguracoes(copia);
                                  }}
                                ></Input>
                                {intervalo.horarios.length > 1 && (
                                  <div
                                    className="x"
                                    onClick={() => {
                                      const copia = copy(configuracoes);
                                      copia.frete.retirada_no_local.intervalos[
                                        indexIntervalo
                                      ].horarios.splice(indexHorario, 1);
                                      setConfiguracoes(copia);
                                    }}
                                  >
                                    X
                                  </div>
                                )}
                              </div>
                            </div>
                            {intervalo.horarios.length < 2 && (
                              <div
                                className="adicionar"
                                onClick={() => {
                                  const copia = copy(configuracoes);
                                  copia.frete.retirada_no_local.intervalos[
                                    indexIntervalo
                                  ].horarios.push({ abre: "", fecha: "" });
                                  setConfiguracoes(copia);
                                }}
                              >
                                Adicionar outro intervalo de tempo
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            },
          )}
          {configuracoes.frete.retirada_no_local.intervalos[
            configuracoes.frete.retirada_no_local.intervalos.length - 1
          ].dias.length > 0 &&
            diasParaUso.length > 0 && (
              <div
                className="adicionar"
                onClick={() => {
                  const copia = copy(configuracoes);
                  copia.frete.retirada_no_local.intervalos.push({
                    dias: [],
                    horarios: [{ abre: "", fecha: "" }],
                  });
                  setConfiguracoes(copia);
                }}
              >
                Adicionar outros dias e horarios
              </div>
            )}
        </div>
      </ContainerRetiradaNoLocalContainer>
    </>
  );
};

export default RetiradaNoLocal;
