import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

const StepsCriacaoContainer = styled.div<{ theme: ThemeType }>`
  padding: 0rem 10rem;
  .subtitulo {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.brandSecondary};
    padding: 1rem 0;
  }
  .bolinhas {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    .bolinha-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      text-decoration: none;
      .bolinha-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.elements};
      }
      &:not(:first-of-type) {
        .bolinha-container {
          &::before {
            content: "";
            position: absolute;
            background-color: ${({ theme }) => theme.colors.elements};
            top: -2rem;
            height: 2rem;
            width: 1px;
            z-index: 1;
          }
        }
        .concluida {
          &::before {
            background-color: ${({ theme }) => theme.colors.brandPrimary};
            z-index: 2;
          }
        }
      }
      &:not(:last-of-type) {
        .bolinha-container {
          &::after {
            content: "";
            position: absolute;
            background-color: ${({ theme }) => theme.colors.elements};
            height: 2rem;
            width: 1px;
            top: 100%;
            z-index: 1;
          }
        }
        .concluida {
          &::after {
            background-color: ${({ theme }) => theme.colors.brandPrimary};
            z-index: 2;
          }
        }
      }
      .concluida {
        background-color: ${({ theme }) => theme.colors.brandPrimary};
        .check {
          width: 3rem;
          stroke: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
  .label {
    font-weight: bold;
    font-size: 3rem;
    color: ${({ theme }) => theme.colors.elements};
  }
  .concluido {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
  @media (max-width: 1024px) {
    padding: 0 5rem;
  }
`;

export { StepsCriacaoContainer };
