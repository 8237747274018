import { cloneElement, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Compartilhamento } from "../../components/Configuracoes/Compartilhamento/Compartilhamento";
import { ConfigCorreios } from "../../components/Configuracoes/ConfigCorreios/ConfigCorreios";
import Endereco from "../../components/Configuracoes/Endereco/Endereco";
import EntregaLocal from "../../components/Configuracoes/EntregaLocal/EntregaLocal";
import EntregaRetirada from "../../components/Configuracoes/EntregadaRetirada/EntregaRetirada";
import EstadosFreteGratis from "../../components/Configuracoes/EstadosFreteGratis/EstadosFreteGratis";
import FreteEspecial from "../../components/Configuracoes/FreteEspecial/FreteEspecial";
import FreteEspecialListagem from "../../components/Configuracoes/FreteEspecialListagem/FreteEspecialListagem";
import FreteGratis from "../../components/Configuracoes/FreteGratis/FreteGratis";
import FreteGratisListagem from "../../components/Configuracoes/FreteGratisListagem/FreteGratisListagem";
import InformacoesDaMarca from "../../components/Configuracoes/InformacoesDaMarca/InformacoesDaMarca";
import Intermediadores from "../../components/Configuracoes/Intermediadores/Intermediadores";
import Pagamento from "../../components/Configuracoes/Pagamento/Pagamento";
import { Pedido } from "../../components/Configuracoes/Pedido/Pedido";
import { PoliticaDePrivacidade } from "../../components/Configuracoes/PoliticaDePrivacidade/PoliticaDePrivacidade";
import RetiradaNoLocal from "../../components/Configuracoes/RetiradaNoLocal/RetiradaNoLocal";
import { Visual } from "../../components/Configuracoes/Visual/Visual";
import VisualColunas from "../../components/Configuracoes/VisualColunas/VisualColunas";
import VisualFiltros from "../../components/Configuracoes/VisualFiltros/VisualFiltros";
import VisualVariacoes from "../../components/Configuracoes/VisualVariacoes/VisualVariacoes";
import Cobranca from "../../components/Modal/ModaisConfigsPrimeiroAcesso/Cobranca/Cobranca";
import CriarProduto from "../../components/Modal/ModaisConfigsPrimeiroAcesso/CriarProduto/CriarProduto";
import OpcoesDeFrete from "../../components/Modal/ModaisConfigsPrimeiroAcesso/OpcoesDeFrete/OpcoesDeFrete";
import Parabens from "../../components/Modal/ModaisConfigsPrimeiroAcesso/Parabens/Parabens";
import { ModaisConfiguracoesProps } from "../../types/ModaisConfiguracoesProps";
import { ConfiguracoesContext } from "../Configuracoes/ConfiguracoesContext";
import { ConfigsPrimeiroAcessoContext } from "./ConfigsPrimeiroAcessoContext";
import CuponsMain from "../../components/Configuracoes/cuponsMain/CuponsMain";

export const ConfigsPrimeiroAcessoProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const paramsHistory = useRef<any[]>(
    localStorage.getItem("paramsHistory")
      ? JSON.parse(localStorage.getItem("paramsHistory") as string)
      : []
  );
  const goTo = (localizador: string, params?: any) => {
    if (params) paramsHistory.current.push(params);

    const updatedModal = modais.find(
      (modal) => modal.localizador === localizador
    );

    //tem que atualizar o modal ativo com as novas props e params se tiver
    if (updatedModal) {
      const updatedComponent = () =>
        cloneElement(updatedModal.component(params));

      setModalAtivo({
        ...updatedModal,
        component: updatedComponent,
      });
    }
  };

  const navigate = useNavigate();

  const modais: Array<ModaisConfiguracoesProps> = [
    {
      localizador: "parabens",
      component: () => <Parabens></Parabens>,
      onAvancar: () => {
        goTo("informacoesDaMarca");
      },
    },
    {
      localizador: "informacoesDaMarca",
      component: () => <InformacoesDaMarca configIn="empresa" />,
      onAvancar: () => {
        goTo("endereco");
      },
      onPular: () => {},
    },
    {
      localizador: "endereco",
      component: () => <Endereco configIn="empresa"></Endereco>,
      onAvancar: () => {
        if (localStorage.getItem("configurarFrete") !== null) {
          if (localStorage.getItem("configurarFrete") === "true") {
            goTo("entregaRetirada");
          } else {
            if (localStorage.getItem("configurarPagamento") !== null) {
              if (localStorage.getItem("configurarPagamento") === "true") {
                goTo("pagamento");
              } else {
                goTo("pedido");
              }
            } else {
              goTo("cobranca");
            }
          }
        } else {
          goTo("opcoesDeFrete");
        }
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("informacoesDaMarca");
      },
    },
    {
      localizador: "cupons",
      component: () => <CuponsMain></CuponsMain>,
      salvarCancelar: true,
      onAvancar: () => {
        goTo("opcoesDeFrete");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("opcoesDeFrete");
      },
      onVoltar: () => {
        goTo("opcoesDeFrete");
      },
    },
    {
      localizador: "opcoesDeFrete",
      component: () => <OpcoesDeFrete></OpcoesDeFrete>,
      simNao: true,
      onAvancar: () => {
        localStorage.setItem("configurarFrete", "true");
        goTo("entregaRetirada");
      },
      onPular: () => {
        localStorage.setItem("configurarFrete", "false");
        goTo("cobranca");
      },
      onVoltar: () => {},
    },
    {
      localizador: "entregaRetirada",
      component: () => (
        <EntregaRetirada
          onConfigurarCorreios={() => goTo("configCorreios")}
          onConfigurarEntregaLocal={() => goTo("entregaLocal")}
          onConfigurarFreteEspecial={(length) =>
            length !== 0
              ? goTo("freteEspecialListagem")
              : goTo("freteEspecial", { index: -1 })
          }
          onConfigurarFreteGratis={(length) =>
            length !== 0
              ? goTo("freteGratisListagem")
              : goTo("freteGratis", { index: -1 })
          }
          onConfigurarRetiradaNoLocal={() => goTo("retiradaNoLocal")}
        ></EntregaRetirada>
      ),
      onAvancar: () => {
        if (localStorage.getItem("configurarPagamento") !== null) {
          if (localStorage.getItem("configurarPagamento") === "true") {
            goTo("pagamento");
          } else {
            goTo("pedido");
          }
        } else {
          goTo("cobranca");
        }
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("endereco");
      },
    },
    {
      localizador: "configCorreios",
      component: () => <ConfigCorreios configIn="empresa"></ConfigCorreios>,
      salvarCancelar: true,
      onAvancar: () => {
        goTo("entregaRetirada");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("entregaRetirada");
      },
    },
    {
      localizador: "entregaLocal",
      component: () => <EntregaLocal configIn="empresa"></EntregaLocal>,
      salvarCancelar: true,
      onAvancar: () => {
        goTo("entregaRetirada");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("entregaRetirada");
      },
    },
    {
      localizador: "freteEspecialListagem",
      component: () => (
        <FreteEspecialListagem
          configIn="empresa"
          onEditarFrete={(indexFrete) =>
            goTo("freteEspecial", { index: indexFrete })
          }
          onNovoFrete={() => goTo("freteEspecial", { index: -1 })}
        ></FreteEspecialListagem>
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("entregaRetirada");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("entregaRetirada");
      },
    },
    {
      localizador: "freteEspecial",
      component: ({ index }) => (
        <FreteEspecial
          index={index}
          configIn="empresa"
          onExclusaoFreteEspecial={(newLenght) => {
            newLenght === 0
              ? goTo("entregaRetirada")
              : goTo("freteEspecialListagem");
          }}
        ></FreteEspecial>
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("freteEspecialListagem");
      },
      onPular: () => {},
      onCancelar: (_, __, fretes_especiais) => {
        fretes_especiais.length === 0
          ? goTo("entregaRetirada")
          : goTo("freteEspecialListagem");
      },
    },
    {
      localizador: "freteGratisListagem",
      component: () => (
        <FreteGratisListagem
          onEditarFrete={(index) => goTo("freteGratis", { index: index })}
          onNovoFrete={() => goTo("freteGratis", { index: -1 })}
        ></FreteGratisListagem>
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("entregaRetirada");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("entregaRetirada");
      },
    },
    {
      localizador: "freteGratis",
      component: ({ index }) => (
        <FreteGratis
          configIn="empresa"
          index={index}
          onConfigurarPorEstado={(indexFrete) =>
            goTo("estadosFreteGratis", { index: indexFrete })
          }
          onExclusaoFreteGratis={(length) => {
            length === 0
              ? goTo("entregaRetirada")
              : goTo("freteGratisListagem");
          }}
        />
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("freteGratisListagem");
      },
      onPular: () => {},
      onCancelar: (_, fretes_gratis) => {
        fretes_gratis.length === 0
          ? goTo("entregaRetirada")
          : goTo("freteGratisListagem");
      },
    },
    {
      localizador: "estadosFreteGratis",
      component: ({ index }) => (
        <EstadosFreteGratis index={index}></EstadosFreteGratis>
      ),
      salvarCancelar: true,
      onAvancar: (paramsHistory) => {
        goTo("freteGratis", {
          index: paramsHistory[paramsHistory.length - 1].index,
        });
      },
      onPular: () => {},
      onCancelar: (_, __, ___, paramsHistory) => {
        goTo("freteGratis", {
          index: paramsHistory[paramsHistory.length - 1].index,
        });
      },
    },
    {
      localizador: "retiradaNoLocal",
      component: () => <RetiradaNoLocal configIn="empresa"></RetiradaNoLocal>,
      salvarCancelar: true,
      onAvancar: () => {
        goTo("entregaRetirada");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("entregaRetirada");
      },
    },
    {
      localizador: "cobranca",
      component: () => <Cobranca></Cobranca>,
      simNao: true,
      onAvancar: () => {
        localStorage.setItem("configurarPagamento", "true");
        goTo("pagamento");
      },
      onPular: () => {
        localStorage.setItem("configurarPagamento", "false");
        goTo("pedido");
      },
      onVoltar: () => {},
    },
    {
      localizador: "pagamento",
      component: () => (
        <Pagamento
          configIn="empresa"
          onChange={(intermediador) => {
            goTo(intermediador);
          }}
        ></Pagamento>
      ),
      onAvancar: () => {
        goTo("pedido");
      },
      onPular: () => {},
      onVoltar: () => {
        if (localStorage.getItem("configurarFrete") === "true") {
          goTo("entregaRetirada");
        } else {
          goTo("endereco");
        }
      },
    },
    {
      localizador: "pagseguro",
      component: () => (
        <Intermediadores
          configIn="empresa"
          intermediador="pagseguro"
        ></Intermediadores>
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("pagamento");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("pagamento");
      },
    },
    {
      localizador: "pagarme",
      component: () => (
        <Intermediadores
          configIn="empresa"
          intermediador="pagarme"
        ></Intermediadores>
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("pagamento");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("pagamento");
      },
    },
    {
      localizador: "mercadopago",
      component: () => (
        <Intermediadores
          configIn="empresa"
          intermediador="mercadopago"
        ></Intermediadores>
      ),
      salvarCancelar: true,
      onAvancar: () => {
        goTo("pagamento");
      },
      onPular: () => {},
      onCancelar: () => {
        goTo("pagamento");
      },
    },
    {
      localizador: "pedido",
      component: () => <Pedido configIn="empresa"></Pedido>,
      onAvancar: () => {
        goTo("visual");
      },
      onPular: () => {},
      onVoltar: () => {
        if (localStorage.getItem("configurarPagamento") === "true") {
          goTo("pagamento");
        } else {
          if (localStorage.getItem("configurarFrete") === "true") {
            goTo("entregaRetirada");
          } else {
            goTo("endereco");
          }
        }
      },
    },
    {
      localizador: "visual",
      component: () => <Visual configIn="empresa"></Visual>,
      onAvancar: () => {
        goTo("compartilhamento");
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("pedido");
      },
    },
    {
      localizador: "compartilhamento",
      component: () => <Compartilhamento configIn="empresa"></Compartilhamento>,
      onAvancar: () => {
        goTo("politicaDePrivacidade");
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("visual");
      },
    },
    {
      localizador: "politicaDePrivacidade",
      component: () => (
        <PoliticaDePrivacidade configIn="empresa"></PoliticaDePrivacidade>
      ),
      onAvancar: () => {
        goTo("visualColunas");
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("compartilhamento");
      },
    },
    {
      localizador: "visualColunas",
      component: () => <VisualColunas configIn="empresa"></VisualColunas>,
      onAvancar: () => {
        goTo("visualVariacoes");
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("politicaDePrivacidade");
      },
    },
    {
      localizador: "visualVariacoes",
      component: () => <VisualVariacoes configIn="empresa"></VisualVariacoes>,
      onAvancar: () => {
        goTo("visualFiltros");
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("visualColunas");
      },
    },
    {
      localizador: "visualFiltros",
      component: () => <VisualFiltros configIn="empresa"></VisualFiltros>,
      onAvancar: () => {
        goTo("criarProduto");
      },
      onPular: () => {},
      onVoltar: () => {
        goTo("visualVariacoes");
      },
    },
    {
      localizador: "criarProduto",
      component: () => <CriarProduto></CriarProduto>,
      simNao: true,
      avancarText: "Criar produto",
      pularText: "Pular",
      onAvancar: () => {
        setModal(false);
        navigate("painel/produtos");
      },
      onPular: () => {
        setModal(false);
      },
    },
  ];

  const [modalAtivo, setModalAtivo] = useState<ModaisConfiguracoesProps>(
    localStorage.getItem("indexModais")
      ? paramsHistory.current.length
        ? {
            ...modais[Number(localStorage.getItem("indexModais"))],
            component: () =>
              modais[Number(localStorage.getItem("indexModais"))].component(
                paramsHistory.current[paramsHistory.current.length - 1]
              ),
          }
        : modais[Number(localStorage.getItem("indexModais"))]
      : modais[0]
  );
  const [modal, setModal] = useState(true);

  return (
    <ConfigsPrimeiroAcessoContext.Provider
      value={{
        goTo,
        modalAtivo,
        setModalAtivo,
        modal,
        setModal,
        modais,
        paramsHistory,
      }}
    >
      {children}
    </ConfigsPrimeiroAcessoContext.Provider>
  );
};
