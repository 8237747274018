import React from 'react';
import './styles';
import { PopoverInfoContainer } from './styles';
import { ReactComponent as InfoSvg } from '../../assets/newImages/icons/info.svg';
import { useTheme } from '../../contexts/Theme/ThemeContext';

interface PopoverInfoProps {
    mensagem: string;
    width: string;
    style?: React.CSSProperties;
    className?: string;
}

const PopoverInfo: React.FC<PopoverInfoProps> = ({width, mensagem, style, className }) => {

   const {theme} = useTheme();

    return (
        <PopoverInfoContainer theme={theme} width={width} mensagem={mensagem} style={style}  className={className}>
            <InfoSvg />
        </PopoverInfoContainer >
    )
}

export default PopoverInfo;