import styled from "styled-components";
import { rgba } from "../../services/Functions";

const transitionTime = "0.3s";
const ContainerTree = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 25px 0 0 25px;
  color: ${(props) => props.theme.colors.brandSecondary};
  font-size: 2.5rem;
  padding: 4rem 0;

  .node {
    text-align: left;
    padding: 1rem 0 0 0;

    .node-name {
      font-weight: bold;
      padding: 0 0 0 13%;
      cursor: pointer;
    }

    .node-children {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .child {
        padding: 1rem 0 1rem 20%;
        border-radius: 100px 0 0 100px;
        transition: ${transitionTime};
        cursor: pointer;

        .child-name {
        }

        &:hover {
          background-color: ${(props) =>
            rgba(props.theme.colors.titleColor, 0.3)};
        }
      }

      .child-active {
        background-color: ${(props) =>
          rgba(props.theme.colors.titleColor, 0.3)};
      }
    }
  }
`;

export default ContainerTree;
