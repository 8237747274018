import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import ModaisConfigsPrimeiroAcesso from "../../components/Modal/ModaisConfigsPrimeiroAcesso/ModaisConfigsPrimeiroAcesso";
import ModalTermosDeUso from "../../components/Modal/ModalTermosDeUso/ModalTermosDeUso.tsx";
import ModalRelativo from "../../components/ModalRelativo/ModalRelativo.tsx";
import { AuthContext } from "../../contexts/Auth/AuthContext.tsx";
import { ConfigsPrimeiroAcessoProvider } from "../../contexts/ConfigsPrimeiroAcesso/ConfigsPrimeiroAcessoProvider.tsx";
import { ConfiguracoesProvider } from "../../contexts/Configuracoes/ConfiguracoesProvider";
import { EmpresaProvider } from "../../contexts/Empresa/EmpresaProvider";
import { HeaderProvider } from "../../contexts/Header/HeaderProvider.tsx";
import { PagSeguroProvider } from "../../contexts/PagSeguro/PagSeguroProvider";
import { PedidosProvider } from "../../contexts/Pedidos/PedidosProvider";
import { ScrollDirectionProvider } from "../../contexts/ScrollDirection/ScrollDirectionProvider";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { UsuarioProvider } from "../../contexts/Usuario/UsuarioProvider";
import { configuracoesApi } from "../../hooks/configuracoesApi";
import { EmpresaApi } from "../../hooks/empresaApi.ts";
import { mailerApi } from "../../hooks/mailerApi.ts";
import { PERMISSOES } from "../../services/Consts.tsx";
import { limparConfigsLocalStorage } from "../../services/Functions.tsx";
import { Plano } from "../../types/Plano.ts";
import Header from "../Header";
import MenuLateral from "../MenuLateral/MenuLateral.tsx";
import "./styles.ts";
import { ContainerPrincipal } from "./styles.ts";

export const Dashboard = () => {
  const { theme } = useTheme();

  const apiConfiguracoes = configuracoesApi();
  const apiEmpresa = EmpresaApi();
  const mailer = mailerApi();

  const [primeiroAcesso, setPrimeiroAcesso] = useState(false);
  const [modalTermosDeUso, setModalTermosDeUso] = useState(false);
  const { permissoesUsuario } = useContext(AuthContext);
  const [modalAvisoGerenteConfigurar, setModalAvisoGerenteConfigurar] =
    useState(false);
  const [jaMostrouAviso, setJaMostrouAviso] = useState(false);

  const handleAceitarTermosDeUso = async (
    concordoTermos: boolean,
    plano: Plano
  ) => {
    if (!concordoTermos) {
      Swal.fire({
        title: "Você ainda não concordou com os termos de uso!",
        text: "Concorde com os nossos termos para prosseguir.",
      });
      return;
    }
    aceite_termosUso(plano);
  };

  const aceite_termosUso = async (plano: Plano) => {
    const res = await apiEmpresa.aceite_termosUso();
    if (res) {
      enviarEmailTermosUso(res, plano);
    }
    setModalTermosDeUso(false);
  };

  const enviarEmailTermosUso = async (dados, plano) => {
    let detalhes = JSON.parse(dados.detalhes);
    detalhes.data = dados.data;
    detalhes.dadosPlano = plano;
    mailer.enviar(detalhes.email, "templates/termosUso.php", detalhes, true);
  };

  useEffect(() => {
    const init = async () => {
      setModalTermosDeUso(await apiEmpresa.modalTermosDeUso());
      const response = await apiConfiguracoes.verificar_primeiro_accesso();
      if (response === false) {
        //pra garantir, caso acabe sendo aberto as configs em mais de um local
        limparConfigsLocalStorage();
      }
      setPrimeiroAcesso(response);
    };

    init();
  }, []);

  useEffect(() => {
    if (
      primeiroAcesso === true &&
      !modalTermosDeUso &&
      !permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) &&
      !jaMostrouAviso
    ) {
      setModalAvisoGerenteConfigurar(true);
      setJaMostrouAviso(true);
    }
  }, [primeiroAcesso, modalTermosDeUso, permissoesUsuario]);

  return (
    <>
      <ScrollDirectionProvider>
        <PagSeguroProvider>
          <EmpresaProvider>
            <UsuarioProvider>
              <PedidosProvider>
                <HeaderProvider>
                  <>
                    <ModalRelativo
                      width="100rem"
                      center={true}
                      isOpen={modalAvisoGerenteConfigurar}
                      setIsOpen={setModalAvisoGerenteConfigurar}
                      titulo={"Atenção"}
                      subtitulo={
                        "Ha configurações pendentes para o gerente da empresa, por favor, realize as configurações necessárias."
                      }
                      botaoConfirmar="Ok"
                      onUseEffect={() => {}}
                      onCancel={() => {
                        setModalAvisoGerenteConfigurar(false);
                        setJaMostrouAviso(true);
                      }}
                    ></ModalRelativo>
                    {primeiroAcesso === true &&
                      !modalTermosDeUso &&
                      permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
                        <ConfiguracoesProvider>
                          <ConfigsPrimeiroAcessoProvider>
                            <ModaisConfigsPrimeiroAcesso />
                          </ConfigsPrimeiroAcessoProvider>
                        </ConfiguracoesProvider>
                      )}
                    {modalTermosDeUso && (
                      <ModalTermosDeUso
                        onAceitarTermosDeUso={handleAceitarTermosDeUso}
                      />
                    )}
                    <ContainerPrincipal theme={theme}>
                      <MenuLateral />
                      <div className="containerPaginas">
                        <Header />
                        <Outlet />
                      </div>
                    </ContainerPrincipal>
                  </>
                </HeaderProvider>
              </PedidosProvider>
            </UsuarioProvider>
          </EmpresaProvider>
        </PagSeguroProvider>
      </ScrollDirectionProvider>
    </>
  );
};
