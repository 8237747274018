import React, { useContext, useEffect } from "react";
import { ContainerCupomListagem } from "./styles";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Toggle } from "../../Toggle/Toggle";
import { copy } from "../../../services/Functions";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { percentageMask } from "../../../services/Masks";

interface CupomListagemProps {
  onEditarCupom: (index: number) => void;
  configIn?: "empresa" | "configuracoes";
  onNovoCupom?: () => void;
}

const CupomListagem: React.FC<CupomListagemProps> = ({
  configIn = "empresa",
  onEditarCupom,
  onNovoCupom,
}) => {
  const { cupons, setCupons } = useContext(ConfiguracoesContext);

  const { theme } = useTheme();

  return (
    <>
      <ContainerCupomListagem theme={theme} configIn={configIn}>
        <div className="containerCupomListagem">
          {configIn === "empresa" && (
            <Titulo
            titulo="Cupons de Desconto"
            subtitulo="Sua empresa"
            justifyContent="flex-start"
          />
          )}
          {cupons.length > 0 ? (
            <div className="cuponsContainer">
              {cupons.map((cupom, indexCupom) => (
                <div className="cupomContainer" key={indexCupom}>
                  <span className="cupom">{cupom.cupom} |</span>
                  <span className="desconto">
                    {percentageMask(cupom.desconto.toString())} de desconto
                  </span>
                  <span className="editarContainer">
                    <Toggle
                      value={cupom.ativo}
                      onChange={(newValue) => {
                        const copia = copy(cupons);
                        copia[indexCupom].ativo = newValue;
                        setCupons(copia);
                      }}
                    ></Toggle>
                    <span
                      onClick={() => {
                        onEditarCupom(indexCupom);
                      }}
                      className={`editar ${!cupom.ativo && "disabled"}`}
                    >
                      Editar
                    </span>
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          {configIn === "empresa" && (
            <div
              className="novo"
              onClick={() => {
                onNovoCupom && onNovoCupom();
              }}
            >
              Adicionar um novo cupom
            </div>
          )}
        </div>
      </ContainerCupomListagem>
    </>
  );
};
export default CupomListagem;
