import styled, { keyframes } from "styled-components";
import { ThemeType } from "../../types/Theme";

const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const ringBell = keyframes`
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;

export const MainHeader = styled.header`
  width: 100%;
  padding: 5rem 10rem;

  .headerContainer {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    .logoHorizontal {
      height: 4rem;
      width: auto;

      svg {
        height: 100%;
        fill: ${(props) => props.theme.colors.brandPrimary};
      }
    }

    .paginacaoContainer {
      max-width: 65%;
    }

    .botoesContainer {
      display: flex;
      align-items: center;
      gap: 2rem;
      font-size: 2rem;
      color: ${(props) => props.theme.colors.brandPrimary};
      .foto {
        cursor: pointer;
      }
      .botaoGuia {
        display: flex;
        height: 4rem;
        align-items: center;
        justify-content: center;
        border: 1px solid ${(props) => props.theme.colors.titleColor};
        background: none;
        color: ${(props) => props.theme.colors.titleColor};
        border-radius: 20px;
        padding: 0px 6rem;
        gap: 4px;
        font-size: 2rem;

        .rocket {
          width: 3rem;
          height: 3rem;
        }
      }

      .icone{
        .blink{
          position: relative;
          &:after{ 
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 1rem;
            height: 1rem;
            background-color: ${props => props.theme.colors.titleColor};
            border-radius: 50%;
            z-index: 2;
          }
          &::before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: transparent;
            z-index: 1;
            top: 0;
            right: 0;
            box-shadow: 0px 0px 11px 1px ${props => props.theme.colors.titleColor};
          }
        }
      }
      .ringBell {
        animation: ${ringBell} 4.7s 8s ease-in-out infinite;
      }
      
      .botao {
        cursor: pointer;
        width: 4rem;
        height: 4rem;
      }

      

      .rotate {
        animation: rotate 0.5s cubic-bezier(0.69, 0.99, 0.49, 0.94);
      }
    }

    &::after {
      content: "";
      background-color: ${(props) => props.theme.colors.brandPrimary};
      width: 100%;
      position: absolute;
      bottom: -4rem;
      left: 0;
      height: 2px;
      border-radius: 100px;
    }
    
  }

  @media (max-width: 1024px) {
    padding: 0px;

    .headerContainer {
      padding: 2rem 2rem;

      &::after {
        bottom: 0;
      }
    }

    .botoesContainer {
      .botaoGuia {
        display: contents !important;

        .rocket {
          width: 4rem !important;
          height: 4rem !important;
        }

        .texto {
          display: none;
        }
      }
    }
  }
`;

export const ModalUsuarioContainer = styled.div`
  .modal-container {
    padding: 2rem 5rem;
    .icones-container {
      display: flex;
      flex-direction: column;
      .icone-container {
        color: ${(props) =>
          props.theme.mode == "light"
            ? props.theme.colors.brandPrimary
            : props.theme.colors.brandSecondary};
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        width: 100%;
        padding: 1rem 0;
        .icone {
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 1rem;
          > a {
            display: contents;
          }
          svg {
            width: 4rem;
          }
          span {
            white-space: nowrap;
            text-align: left;
            flex-grow: 1;
          }
          .rotate {
            animation: rotate 0.5s cubic-bezier(0.69, 0.99, 0.49, 0.94);
          }
        }
        &:first-child {
          position: relative;
          padding-bottom: 3rem;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            border-radius: 100px;
            background-color: ${(props) =>
              props.theme.mode == "light"
                ? props.theme.colors.elements
                : props.theme.colors.brandSecondary};
            width: 100%;
            height: 2px;
          }
        }
        &:nth-child(2) {
          padding-top: 3rem;
        }
      }
    }
  }
`;

export const ModalCriacaoContainer = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem;
  .titulo {
    font-size: 3rem;
    color: ${({ theme }) => theme.colors.titleColor};
    font-weight: bold;
  }
  .subtitulo-criacao {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.brandSecondary};
    padding: 1rem 0;
    &:nth-child(2) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        border-radius: 100px;
        background-color: ${({ theme }) => theme.colors.elements};
        width: 70%;
        height: 1px;
      }
    }
  }
`;
export const ModalAjudaContainer = styled.div<{ theme: ThemeType }>``;

export const ModalNotificacoesContainer = styled.div<{ theme: ThemeType }>``;