import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ProdutosPlanilhaContext } from '../../../contexts/ProdutosPlanilha/ProdutosPlanilhaContext';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { produtosApi } from '../../../hooks/produtosApi';
import { SeparatorTypes } from '../../../types/ImportacaoPlanilha';
import InputFileButton, { FileType } from '../../InputFileButton/InputFileButton';
import { Select } from '../../Select';
import { Toggle } from '../../Toggle/Toggle';
import { TabelaDePrecoContainer } from './styles';
interface TabelaDePrecoProps {
}

const TabelaDePreco: React.FC<TabelaDePrecoProps> = ({}) => {

  const {theme} = useTheme();
  const {
    arquivo,
    setColunas,
    setDados,
    campos,
    setEtapa,
    setArquivo,
    separator,
    setSeparator,
    tabelaSelecionada,
    setTabelaSelecionada,
    removerProdutos,
    setRemoverProdutos,
    slidingActive
  } = useContext(ProdutosPlanilhaContext);
   const api = produtosApi();
   
   const [loading, setLoading] = useState(false);
   const [separatorOptions, setSeparatorOptions] = useState<Array<{ name: string; value: SeparatorTypes }>>([
      { name: "Ponto e vírgula (;)", value: ";" },
      { name: "Vírgula (,)", value: "," },
      { name: "Ponto (.)", value: "." },
    ]);
    const [tabelasPreco, setTabelasPreco] = useState<
        Array<{ name: string; value: string }>
    >([]);
    const navigate = useNavigate();

    

    const getTabelasPreco = async () => {
      try {
        const response = await api.getTabelasDePreco();
        const newTabelas = response.map((tabela) => {
          return {
            name: tabela.descricao,
            value: tabela.id,
          };
        });
        setTabelasPreco(newTabelas);
      } catch (error) {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro inesperado, atualize a página e envie o arquivo novamente.",
        })
        window.location.reload();
      }
    };

    const onChangeFile = (file: FileType[]) => {
      if (!file[0]) return;
      const firstFile = file[0];

      const content = firstFile.content as string;

      if(content){
        setArquivo({name: firstFile.name, content});
        setEtapa('tabela-de-preco');
      }

    };

    useEffect(() => {
        const init = async () => {
          getTabelasPreco();
        }
        init();  
    }, []);

    return (
        <TabelaDePrecoContainer  theme={theme}>
          <div className="arquivo-container">
            
            {arquivo.content ? (
              <>
                <div className="arquivo">
                  {arquivo.name ? arquivo.name : "nome-do-arquivo.csv"}
                </div>
              </>
            ) : (
              <>
                <InputFileButton
                  title='Escolher arquivo'
                  className='escolher-arquivo-button'
                  accept={[".csv"]}
                  required={true}
                  onChange={onChangeFile}
                />
              </>
            )}
          </div>
          <div className="selects">  
            <Select
              label="Escolha a tabela de preço para qual deseja adicionar esses produtos."
              placeholder="Selecione uma tabela de preço"
              options={tabelasPreco || []}
              value={tabelaSelecionada}
              required={true}
              onChange={(newValue) => {
                setTabelaSelecionada(newValue);
              }}
            />
            <Select
              label="Separador de colunas do arquivo"
              placeholder="Selecione o separador"
              disabled={slidingActive}
              options={separatorOptions}
              value={separator}
              required={true}
              onChange={(newValue) => {
                setSeparator(newValue as SeparatorTypes);
              }}
            />
          </div>
          {/* <div className="remover-produtos">
            <label>
              <Toggle
                value={removerProdutos}
                onChange={(newValue) => {
                  if (newValue) {
                    Swal.fire({
                      title: "Tem certeza?",
                      text: "Ao habilitar essa opção, todos os produtos da conta cadastrados anteriormente serão excluídos.",
                      icon: "warning",
                      showCancelButton: true,
                      cancelButtonText: "Remover Produtos",
                      confirmButtonText: "Cancelar",
                    }).then((result) => {
                      console.log(result)
                      if (result.isDismissed && result.dismiss === "cancel") {
                        setRemoverProdutos(newValue);
                      } else {
                        setRemoverProdutos(false);
                      }
                    });
                  } else {
                    setRemoverProdutos(newValue);
                  }
                }}
              />
              Remover produtos existentes
            </label>
            <p>
              Se habilitada essa opção,{" "}
              <span>
              Se habilitada essa opção, serão excluídos todos os produtos da conta cadastrados anteriormente.
              </span>
              <br />
              Após a remoção, os produtos que estão no arquivo de importação
              serão cadastrados.
            </p>
          </div> */}
        </TabelaDePrecoContainer >
    )
}

export default TabelaDePreco;