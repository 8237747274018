import { useLocation } from "react-router-dom";
import { NotificacoesApi } from "../../hooks/notificacoesApi";
import { HeaderContext } from "./HeaderContext";
import { useEffect, useState } from "react";

export const HeaderProvider = ({ children }: { children: JSX.Element }) => {
  const notificacoesApi = NotificacoesApi();
  const location = useLocation();
  
  const [modalCriacao, setModalCriacao] = useState(false);
  const [temNotificacaoNova, setTemNotificacaoNova] = useState(false);

  useEffect(() => {
    const init = async () => {
      const res = await notificacoesApi.temNotificacaoNova();
      setTemNotificacaoNova(res || false);
    };
    init();
  }, [location.pathname]);

  return (
    <HeaderContext.Provider value={{ modalCriacao, setModalCriacao, temNotificacaoNova, setTemNotificacaoNova }}>
      {children}
    </HeaderContext.Provider>
  );
};
