import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { Input } from "../../Input";
import { ContainerIntegracaoBling } from "./styles";
import Swal from "sweetalert2";
import Dropdown from "../../Dropdowns/Dropdown";

const IntegracaoBling: React.FC = ({}) => {
  const { theme } = useTheme();

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const callBackUrl = 'https://conectavenda.com.br/postback.php?origem=bling&chave=';

  useEffect(() => {}, []);

  return (
    <ContainerIntegracaoBling theme={theme}>
      <div className="config">
        <Input
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                chave: newValue,
              },
            });
          }}
          type="text"
          value={configuracoes.dados_bling.chave}
          placeholder="Chave de integração"
          showMaxLength={false}
          maxLength={240}
          required
          ignoreValidations={!configuracoes.dados_bling.ativo}
          label="Chave de API"
        />
      </div>
      <Dropdown active={configuracoes.dados_bling.chave ? true : false}>
        <div className="urlContainer">
          <span>URL de callback:</span>
          <div className="url" onClick={() => {
            navigator.clipboard.writeText(callBackUrl + configuracoes.dados_bling.chave).then(res => {
              Swal.fire({
                title: "URL copiada!",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              })
            }, (err) => {
              Swal.fire({
                title: "Erro ao copiar URL!",
                text: "Mas você pode copiar manualmente: " + callBackUrl + configuracoes.dados_bling.chave,
                icon: "error",
                
              })
            })
          }}>
            <span>{callBackUrl}</span>
            <span>{configuracoes.dados_bling.chave}</span>
          </div>
        </div>
      </Dropdown>
      <label className="config">
        <ToggleConfigurar
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                upload_fotos_conecta: newValue,
              },
            });
          }}
          value={configuracoes.dados_bling.upload_fotos_conecta}
        />
        <div className="title">Upload de fotos pelo Conecta Venda</div>
      </label>

      <label className="config">
        <ToggleConfigurar
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                controle_estoque: newValue,
              },
            });
          }}
          value={configuracoes.dados_bling.controle_estoque}
        />
        <div className="title">Controle de estoque</div>
      </label>

      {configuracoes.dados_bling.controle_estoque && (
        <label className="config" style={{ marginLeft: "3rem" }}>
          <ToggleConfigurar
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_bling: {
                  ...configuracoes.dados_bling,
                  estoque_ilimitado: newValue,
                },
              });
            }}
            value={configuracoes.dados_bling.estoque_ilimitado}
          />
          <div className="title">
            Produtos com estoque zerado no Bling serão considerados com estoque
            ilimitado
          </div>
        </label>
      )}
      <label className="config">
        <ToggleConfigurar
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                status_pedido_atendido: newValue,
              },
            });
          }}
          value={configuracoes.dados_bling.status_pedido_atendido}
        />
        <div className="title">
          Pedido com status <strong>"Atendido"</strong>
        </div>
      </label>
      <label className="config">
        <ToggleConfigurar
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                descricao_curta: newValue,
              },
            });
          }}
          value={configuracoes.dados_bling.descricao_curta}
        />
        <div className="title">
          Utilizar descrição curta <strong>"Descrição Principal"</strong>
        </div>
      </label>

      <div className="configs">
        <Input
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                codigo_loja_bling: newValue,
              },
            });
          }}
          type="text"
          value={configuracoes.dados_bling.codigo_loja_bling}
          placeholder="Código da loja API Bling"
          showMaxLength={false}
          maxLength={64}
          required
          ignoreValidations={!configuracoes.dados_bling.ativo}
          label="Código da loja API Bling"
        />
        <Input
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_bling: {
                ...configuracoes.dados_bling,
                multiplicador: newValue,
              },
            });
          }}
          type="number"
          step={"any"}
          value={configuracoes.dados_bling.multiplicador}
          placeholder="Multiplicador"
          min={0.01}
          max={100}
          label="Multiplicador"
          ignoreValidations={!configuracoes.dados_bling.ativo}
          required
        />
      </div>
      <div className="obs">
        <br />
        <span>Observações:</span> <br />
        - Produtos que não possuem o Código (SKU) não serão importados no Conecta Venda. <br />
        - Produtos que não possuem Categoria (Grupo) serão colocados na categoria com o nome de 'Sem Categoria' no Conecta Venda. <br />
        - Ao excluir um produto no Bling, será necessário excluir manualmente no Conecta Venda (para exclusão de variações, a exclusão é feita automaticamente no Conecta Venda).
      </div>
    </ContainerIntegracaoBling>
  );
};
export default IntegracaoBling;
