import { createGlobalStyle } from "styled-components";
import { rgba } from "./services/Functions";
import { ThemeType } from "./types/Theme";

const GlobalStyles = createGlobalStyle<{theme: ThemeType}>`
  :root {
    font-size: 8px;
    @media (max-width: 3840px) { font-size: 8px; }
    @media (max-width: 2560px) { font-size: 7px; }
    @media (max-width: 1920px) { font-size: 6px; }
    @media (max-width: 1280px) { font-size: 5px; }
    @media (max-width: 1024px) { font-size: 6px; }
  }

  .loading, .loading * {
    cursor: wait !important;
  }

  .App {
    font-family: 'D-DIN';
    text-align: center;
    width: 100%;
  }

  .hideMobile { @media (max-width: 1024px) { display: none !important; } }
  .hideDesktop { @media (min-width: 1025px) { display: none !important; } }

  .modal-shown{
    overflow: hidden;
  }
  .modal-height-auto{
    height: auto !important;
  }

  .onesignal-override-class{
    #onesignal-slidedown-container{
        #onesignal-slidedown-dialog{
            background: ${({theme}) => theme.colors.modal} !important;
            border-radius: 40px 40px 40px 40px !important;
            #normal-slidedown{
                #slidedown-body{
                    display: flex;
                    align-items: center;
                    .slidedown-body-message{
                        color: ${({theme}) => theme.colors.brandSecondary} !important;
                        font-size: 2.5rem !important;
                    }
                }
                #slidedown-footer{
                    #onesignal-slidedown-allow-button{
                        font-size: 2.5rem !important;
                        padding: 1rem 5rem !important;
                        color: ${({ theme }) => theme.mode === 'dark' ? theme.colors.brandSecondary : theme.colors.white} !important;
                        background-color: ${({ theme }) => theme.colors.brandPrimary} !important;
                        border-radius: 3rem !important;
                    }
                    #onesignal-slidedown-cancel-button{
                        font-size: 2.5rem !important;
                        padding: 1rem 5rem !important;
                        background-color: ${({theme}) => theme.colors.elements} !important;
                        color: ${({theme}) => theme.colors.brandSecondary} !important;
                        border-radius: 3rem !important;
                    }
                }
            }
        }
    }
  }

  html,
    body {
    height: auto;
    min-height: 100vh;
    background-color: ${({theme}) => theme.colors.background1};
    scroll-behavior: smooth;
    .align-left{
        text-align: left !important;
    }
    .disabled
    {
        opacity: 0.7 !important;
    }
    .input-radio
    {
        margin: 0;
        position: relative;
        accent-color: ${({theme}) => theme.colors.brandPrimary};
    }
    .checkboxBonito{
        input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: ${({theme}) => theme.colors.elements};
            background-color: ${({theme}) =>
              theme.mode == "light" ? "unset" : theme.colors.black};
            width: 3rem;
            height: 3rem;
            border: 0.15em solid ${({theme}) => theme.colors.elements};
            border-radius: 5px;
            display: grid;
            place-content: center;
        }

        input[type="checkbox"]:focus{
            outline: none;  
        }
        
        input[type="checkbox"]::before {
            content: "";
            width: 2.1rem;
            height: 2.1rem;
            border-radius: 4px;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 2.5rem 2.5rem ${({theme}) =>
              theme.colors.brandPrimary};
        }
        
        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

    }

    a{
        text-decoration: none;
        color: inherit;
        &:hover{
            text-decoration: none;
            color: inherit;
        }
    }
    .flexTituloConfigs{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
        height: 5rem;
    }

    .scrollBonito {
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: ${({theme}) => theme.colors.elements} transparent;

        &::-webkit-scrollbar {
            width: 25px;
            display: block;
        }
        &::-webkit-scrollbar-button {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            border: 10px solid rgba(0, 0, 0, 0) !important;
            background-clip: padding-box;
            border-radius: 25px;
            background-color: ${({theme}) => theme.colors.elements};
        }
    }

    .scrollBonitoBottom {
        overflow-x: scroll; /* Change to overflow-x */
        scrollbar-width: thin;
        scrollbar-color: ${({theme}) => theme.colors.elements} transparent;
        
        &::-webkit-scrollbar {
            height: 25px; /* Change to height */
            display: block;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            border: 10px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 25px;
            background-color: ${({theme}) => theme.colors.elements};
        }
    }

    %modalPadrao {
        .swal-text {
            text-align: center;
        }
        .swal-footer {
            text-align: center;
            button {
                padding: 10px 30px;
                font-size: 16px;
                background-color: ${({theme}) => theme.colors.titleColor};
                &:focus {
                    border-color: none;
                    outline: none;
                    box-shadow: none;
                }
            }
            .swal-button--confirm {
                border-color: transparent;
            }
        }
    }
    input[type="checkbox"] {
        margin: 0px;
    }
    label {
        display: inline-block;
        margin: 0;
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        text-align: left;
    }
    button {
        cursor: pointer;
    }
    button[disabled] {
        cursor: not-allowed;
    }
    @keyframes grow {
        0% {
            transform: scale(0.1);
        }
        100% {
            transform: scale(1);
        }
    }
    .swal2-actions {
        direction: rtl;
    }

    .swal2-popup{
        font-size: 2.5rem;
        border-radius: 5rem;
        background-color: ${({theme}) => theme.colors.modal};
        .swal2-title{
            color: ${({theme}) => theme.colors.brandSecondary};
            font-weight: bold;
        }
        .swal2-warning{
            color: ${({theme}) => theme.colors.brandPrimary};
            border-color: ${({theme}) => theme.colors.brandPrimary};
            .swal2-icon-content{
                margin-bottom: 7px;
            }
        }
        .swal2-html-container{
            color: ${({theme}) => theme.colors.brandSecondary};
        }
        .swal2-confirm{
            border-radius: 10rem;
            padding-left: 7rem;
            padding-right: 7rem;
            background-color: ${({theme}) => theme.colors.brandPrimary};
            color: ${({theme}) => theme.colors.white};
            &:focus{
                box-shadow: 0 0 0 2px ${({theme}) => theme.mode === 'light' ? theme.colors.black : theme.colors.brandSecondary};
            }
        }
        .swal2-cancel{
            border-radius: 10rem;
            padding-left: 7rem;
            padding-right: 7rem;
            background-color: ${({theme}) => theme.colors.elements};
            color: ${({theme}) => theme.colors.brandSecondary};
        }
        .swal2-question{
            color: ${({theme}) => theme.colors.brandPrimary};
            border-color: ${({theme}) => theme.colors.brandPrimary};
        }
        .swal2-error{
            color: ${({theme}) => theme.colors.brandDanger};
            border-color: ${({theme}) => theme.colors.brandDanger};
            .swal2-x-mark{
                .swal2-x-mark-line-left, .swal2-x-mark-line-right{
                    background-color: ${({theme}) =>
                      theme.colors.brandDanger};
                }
            }
        }
        .swal2-success{
            color: ${({theme}) => theme.colors.brandPrimary};
            .swal2-success-line-tip, .swal2-success-line-long{
                background-color: ${({theme}) => theme.colors.brandPrimary};
            }
            .swal2-success-circular-line-right, .swal2-success-circular-line-left{
                background-color: ${({theme}) => theme.colors.brandPrimary};
                border-color: ${({theme}) => theme.colors.brandPrimary};
            }
            .swal2-success-ring{
                border-color: ${({theme}) =>
                  rgba(theme.colors.brandPrimary, 0.4)};
            }
        }
        @media(max-width: 1024px){
            font-size: 2rem;
        }
    }
 
    .quill-editor {
        border-radius: 15px;
        overflow: hidden;
        width: 100%;
        .ql-toolbar {
            display: flex;
            align-items: flex-start;
            width: 100%;
            border-radius: 16px 16px 0 0;
            .ql-header {
                display: none;
            }
            .ql-link {
                display: none;
            }
            .ql-clean {
                display: none;
            }
            .ql-formats{
             .ql-list,.ql-bold,.ql-italic,.ql-underline,.ql-stroke,.ql-link,.ql-image,.ql-video,.ql-blockquote,.ql-code-block,.ql-header,.ql-indent,.ql-script,.ql-direction,.ql-size,.ql-color,.ql-background,.ql-font,.ql-align,.ql-code,.ql-formula,.ql-insertImage,.ql-insertVideo,.ql-clean,.ql-list{
                svg{
                    .ql-stroke,.ql-fill{
                        stroke: ${({theme}) => theme.colors.brandSecondary};
                    }
                   
                }
             }
           }
        }
        .ql-container {
            border-radius: 0 0 16px 16px;
         
            .ql-editor {
                color: ${({theme}) => theme.colors.brandSecondary};
                font-size: 2.5rem;
                max-height: 30rem;
                &::-webkit-scrollbar {
                    width: 5px;
                    display: block;
                    height: 5px;
                    background-color: none;
                }
                &::-webkit-scrollbar-button {
                    display: none;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 50px;
                    background-color: ${({theme}) => theme.colors.elements};
                }
            }
        }
    }
    .quill-editor-2 {
        border-radius: 25px;
        width: 100%;
        background-color: ${({theme}) => theme.colors.white};
        .ql-toolbar {
            display: flex;
            align-items: flex-start;
            width: 100%;
            border-radius: 25px 25px 0 0;
            border: none;
            border-bottom: 1px solid ${({theme}) => theme.colors.elements};
            .ql-header {
                display: none;
            }
            .ql-link {
                display: none;
            }
            .ql-clean {
                display: none;
            }
           .ql-formats{
             .ql-list,.ql-bold,.ql-italic,.ql-underline,.ql-stroke,.ql-link,.ql-image,.ql-video,.ql-blockquote,.ql-code-block,.ql-header,.ql-indent,.ql-script,.ql-direction,.ql-size,.ql-color,.ql-background,.ql-font,.ql-align,.ql-code,.ql-formula,.ql-insertImage,.ql-insertVideo,.ql-clean,.ql-list{
                svg{
                    .ql-stroke,.ql-fill{
                        stroke: ${({theme}) => theme.colors.brandSecondary};
                    }
                   
                }
             }
           }
        }
        .ql-container {
            border-radius: 0 0 25px 25px;
            border: none;
            .ql-editor {
                font-size: 2.5rem;
                max-height: 50rem;
                &::before{
                    color: ${({theme}) => theme.colors.placeholder};
                }
                p{
                    color: ${({theme}) => theme.colors.brandSecondary};
                }
                &::-webkit-scrollbar {
                    width: 5px;
                    display: block;
                    height: 5px;
                    background-color: none;
                }
                &::-webkit-scrollbar-button {
                    display: none;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 50px;
                    background-color: ${({theme}) => theme.colors.elements};
                }
            }
        }
    }
}

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes pulsate {
        0% {
        background-color: ${({theme}) => theme.colors.skeleton1};
        }

        100% {
        background-color: ${({theme}) => theme.colors.skeleton2};
        }
    }

    nav a {
        color: black;
        text-decoration: none;
        padding: 0 10px;
    }
`;

export default GlobalStyles;
