import styled from 'styled-components';
import { ThemeType } from '../../../../types/Theme';

const ConfiguracoesAvancadasContainer = styled.div<{theme: ThemeType}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modal};
  color: ${({ theme }) => theme.colors.brandSecondary};
  padding: 4rem;
  margin: 0 auto;
  border-radius: 5rem;
  max-width: 485px;
  min-width: 400px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 1000;
  overflow: hidden;
  max-height: 80vh;
  overflow-y: auto;
  
  .confirm-class {
    background-color: rgb(255, 110, 40);
  }
  
  .remover-produtos {
    width: 100%;
    margin: 2rem 0 0 0;
    padding: 0 0 0 8rem;
    display: flex;
    background-color: ${({theme}) => theme.colors.modal} !important;
    color: ${({ theme }) => theme.colors.brandSecondary};
    gap: 2rem;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    .texto {
      padding-bottom: 15px;
    }

    Button {
    color: rgb(225, 225, 225);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    }
    label {
      font-family: "D-DIN-Bold";
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      color: ${({ theme }) => theme.colors.brandSecondary};
    }

    p {
      color: ${({ theme }) => theme.colors.brandSecondary};
      text-align: justify;

      span {
        font-weight: bold;
      }
    }
  }
`;

const ModalBackdropContainer = styled.div<{theme: ThemeType}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  z-index: 99999;
`;

const Botoes = styled.div<{theme: ThemeType}>`
  display: flex;
  justify-content: unset;
  align-items: start;
  cursor: pointer;
  width: 100%;

  .cancelar {
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.colors.brandPrimary};
      transform: rotate(90deg);
    }
  }
`;

export {
  ConfiguracoesAvancadasContainer, ModalBackdropContainer, Botoes
};
