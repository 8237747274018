import { useContext, useState } from "react";
import { Catalogo as CatalogoType } from "../../types/Catalogo";

import moment from "moment";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FavoriteIcon } from "../../assets/newImages/icons/favorite.svg";
import { ReactComponent as LinkH } from "../../assets/newImages/icons/link-h.svg";
import { ReactComponent as Mail } from "../../assets/newImages/icons/mail.svg";
import { ReactComponent as OlhoDeTandera } from "../../assets/newImages/icons/olho-de-tandera.svg";
import { ReactComponent as Options } from "../../assets/newImages/icons/options.svg";
import { ReactComponent as Plus } from "../../assets/newImages/icons/plus.svg";
import { ReactComponent as Telegram } from "../../assets/newImages/icons/telegram-alt.svg";
import { ReactComponent as Wechat } from "../../assets/newImages/icons/wechat.svg";
import { ReactComponent as ZapZap } from "../../assets/newImages/icons/whatsapp.svg";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { PERMISSOES, TIPO_MIDIA } from "../../services/Consts";
import { obterMiniaturaVideoYouTube, staginEnv } from "../../services/Functions";
import ComponentInfiniteScroll from "../ComponentInfiniteScroll/ComponentInfiniteScroll";
import ImgWithLoading from "../ImgWithLoading/ImgWithLoading";
import { CatalogoListagemWrapper } from "./styles";

interface CatalogoProps {
  catalogo: CatalogoType;
  onChangeCatalogoPrincipal: (catalogo: CatalogoType) => void;
}

const Catalogo: React.FC<CatalogoProps> = ({
  catalogo,
  onChangeCatalogoPrincipal,
}) => {
  const { theme } = useTheme();

  const [compartilharAtivo, setCompartilharAtivo] = useState(false);
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const mensagemWpp = empresa?.mensagem_whats;

  const { permissoesUsuario } = useContext(AuthContext);

  const linkCopiado = useRef<HTMLDivElement>(null);

  const [popoverAtivo, setPopoverAtivo] = useState<
    "Telegram" | "E-mail" | "WeChat" | "none"
  >("none");

  return (
    <CatalogoListagemWrapper
      theme={theme}
      replicado={
        Number.parseInt(catalogo.id_catalogo_original) > 0 ? true : false
      }
    >
      <ComponentInfiniteScroll className="catalogo-listagem-wrapper">
        <Link
          to={
              `catalogo/${catalogo.id + "/" + encodeURIComponent(catalogo.descricao)}`
          }
          className={"catalogo-listagem-container"}
        >
          <div
            className={`card-branco-catalogo ${
              catalogo.principal ? "principal" : ""
            } ${
              catalogo.data_vencimento < moment().format("YYYY-MM-DD HH:mm:ss")
                ? "expirado"
                : ""
            }`}
          >
            {catalogo.data_vencimento <
              moment().format("YYYY-MM-DD HH:mm:ss") && (
              <div className="expirado-glass" />
            )}
            <div className="imagens-grid">
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <>
                    {catalogo.imagens && catalogo.imagens.length > index ? (
                      <ImgWithLoading
                        src={
                          Number(catalogo.imagens[index].tipo) ===
                          TIPO_MIDIA.YOUTUBE
                            ? obterMiniaturaVideoYouTube(
                                catalogo.imagens[index].imagem_externa
                              )
                            : catalogo.imagens[index].imagem_externa
                        }
                        alt="Imagem do produto"
                        imageClass="imagem-catalogo"
                        loadingClass="gray-loading"
                      />
                    ) : (
                      <div className="image-placeholder"></div>
                    )}
                  </>
                ))}
            </div>
            {(!(Number.parseInt(catalogo.id_catalogo_original) > 0) && permissoesUsuario.includes(PERMISSOES.USUARIOS_GERENTE))  &&
              catalogo.data_vencimento >
                moment().format("YYYY-MM-DD HH:mm:ss") && (
                <div
                  className="estrela-container"
                  onClick={(e) => {
                    e.preventDefault();
                    onChangeCatalogoPrincipal(catalogo);
                  }}
                >
                  <FavoriteIcon />
                </div>
              )}
            <div className="informacoes">
              <div className="nome-catalogo">{catalogo.descricao}</div>
              <div className="flex-container">
                <div>{catalogo.qtd_produtos_catalogo} produtos</div>
                <div className="visualizacoes">
                  <OlhoDeTandera className="icone-svg olho" />
                  {catalogo.visualizacoes}
                </div>
              </div>
              <div className="links-container">
                {catalogo.data_vencimento >
                moment().format("YYYY-MM-DD HH:mm:ss") ? (
                  <>
                    <div
                      className="botao-link"
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard
                          .writeText(
                            `${empresa?.dominio_https}${catalogo.identificacao}${staginEnv()}`
                          )
                          .then(() => {
                            linkCopiado.current!.classList.add("overlay-ativo");
                            setTimeout(() => {
                              linkCopiado.current!.classList.remove(
                                "overlay-ativo"
                              );
                            }, 2000);
                          });
                      }}
                    >
                      <div ref={linkCopiado} className="overlay-copiado">
                        <span>Link copiado!</span>
                      </div>
                      <LinkH className="icone-link" />
                      Copiar link
                    </div>
                    <div
                      className="botao-zap"
                      onClick={(e) => {
                        //localhost a mensagem quebra por causa do #! na url mas online funciona
                        const mensagem = `${mensagemWpp} ${empresa?.dominio_https}${catalogo.identificacao}`;
                        e.preventDefault();
                        window
                          .open(
                            `https://api.whatsapp.com/send?text=${mensagem}`,
                            "_blank"
                          )
                          ?.focus();
                      }}
                    >
                      <ZapZap className="icone-svg" />
                    </div>

                    {/* desktop */}
                    <div
                      className="borda-reticencias hideMobile"
                      onClick={(e) => {
                        e.preventDefault();
                        setCompartilharAtivo(!compartilharAtivo);
                      }}
                      onDoubleClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className={`reticencias ${
                          compartilharAtivo ? "rodar-ativo" : ""
                        }`}
                      >
                        <Plus className="icone-svg options" />
                      </div>
                    </div>

                    {/* mobile */}
                    <div
                      className="borda-reticencias hideDesktop"
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.share({
                          title: catalogo.descricao,
                          text: `${mensagemWpp}`,
                          url: `${empresa?.dominio_https}${catalogo.identificacao}`,
                        });
                      }}
                      onDoubleClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className={`reticencias`}>
                        <Options className="icone-svg options" />
                      </div>
                    </div>

                    <div
                      className={`icones-compartilhar-container ${
                        compartilharAtivo ? "background-ativo" : ""
                      } hideMobile`}
                    >
                      <Telegram
                        onMouseEnter={() => setPopoverAtivo("Telegram")}
                        onMouseLeave={() => setPopoverAtivo("none")}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `https://t.me/share/url?url=${empresa?.dominio_https}${catalogo.identificacao}&text=${catalogo.descricao}`
                          );
                        }}
                        className="icone-svg"
                      />
                      <Mail
                        onMouseEnter={() => setPopoverAtivo("E-mail")}
                        onMouseLeave={() => setPopoverAtivo("none")}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `mailto:?subject=${catalogo.descricao}&body=${empresa?.dominio_https}${catalogo.identificacao}`
                          );
                        }}
                        className="icone-svg"
                      />
                      <Wechat
                        onMouseEnter={() => setPopoverAtivo("WeChat")}
                        onMouseLeave={() => setPopoverAtivo("none")}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `https://www.addtoany.com/add_to/wechat?linkurl=${empresa?.dominio_https}${catalogo.identificacao}&linkname=${catalogo.descricao}`
                          );
                        }}
                        className="icone-svg"
                      />
                      {popoverAtivo !== "none" && (
                        <div className="popover-compartilhar">
                          {popoverAtivo}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="botao-link">
                      Expirado desde{" "}
                      {moment(catalogo.data_vencimento).format("DD/MM/YYYY")}
                    </div>
                  </>
                )}
              </div>
              Gerenciado por: {catalogo.email}
            </div>
          </div>
        </Link>
      </ComponentInfiniteScroll>
    </CatalogoListagemWrapper>
  );
};

export default Catalogo;
