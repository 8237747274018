import { api } from "./useApi";

export const mailerApi = () => ({
  enviar: async (
    to: String,
    template: String,
    vars: Array<Object>,
    termosUso: Object | boolean = {},
  ) => {
    api
      .post("mailer/generic", {
        to: to,
        template: template,
        vars: vars,
        termosUso: termosUso,
      })
      .then(
        (res) => {
          return res.data;
        },
        (err) => {
          console.log("err", err);
        },
      );
  },
});
