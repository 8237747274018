import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import LoadingSRC from '../../../assets/jsons/Loading.json';
import { ReactComponent as GaleryIcon } from "../../../assets/newImages/icons/galery.svg";
import { ReactComponent as PlayIcon } from "../../../assets/newImages/icons/play.svg";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import Modal from "../../Modal/Modal.tsx";
import { CroppingComponent } from '../CroppingComponent';
import { Image, MultipleImageUpload } from "../MultipleImageUpload.tsx";
import {
  LogoInputContainerProdutoWrapper,
  ModalCropContainer,
} from "./styles.ts";

interface ImageUploadProdutoProps {
  value: string;
  onChange: (newImage: { base64: string; imagem: string; size: number }) => void;
  minWidth?: number;
  minHeight?: number;
  resize?: { width: number; height: number };
  aspectRatio: number;
  theme?: any;
}

export const ImageUploadProduto: React.FC<ImageUploadProdutoProps> = ({
  onChange,
  value,
  aspectRatio,
  minWidth,
  minHeight,
  resize,
}) => {
  const animation = LoadingSRC;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const imageCropped = useRef<{base64: string, imagem: string, size: number}>();
  const index = useRef<number>(0);
  const length = useRef<number>(0);

  const [croppingImage, setCroppingImage] = useState<{
    base64: string;
    imagem: string;
    size: number;
    resolve: (value: unknown) => void;
    reject: (value: unknown) => void;
  }>({
    base64: "",
    imagem: "",
    size: 0,
    resolve: () => {},
    reject: () => {},
  });

  const errorsLabels = {
      FORMATO_INVALIDO: "Formato inválido",
      IMAGEM_MUITO_GRANDE: "Imagem muito pesada",
      TAMANHO_INVALIDO: "Tamanho inválido (min: 800x800)",
      PROPORCAO_INVALIDA: "Proporção inválida",
      ERROR_FILE_READER: "Erro na leitura do arquivo",
      
    }

  const { theme } = useTheme();

  const onDragIn = (e) => {
    setIsDragging(true);
  };

  const onDragOut = (e) => {
    setIsDragging(false);
  };

  const onDragDrop = (e) => {
    setIsDragging(false);
  };

  const onUploadImages = async (newImages: Image[]) => {
    const imagesWithError = newImages.filter(image => image.status == 'erro') 
    if(imagesWithError.length) {
      const texto = `
        ${imagesWithError.length === newImages.length ?
          'Nenhuma das imagens é válida' :
          'As seguintes imagens não são válidas e foram removidas'}: <br />
          ${imagesWithError.map(image => image.imagem+`(${errorsLabels[image.error!]})`).join(`, <br />`)}
      `
      await Swal.fire({
        title: "Erro!",
        html: `${texto}`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    newImages = newImages.filter(image => image.status !== 'erro')
    length.current = newImages.length;
    setLoadingModal(true);
    for (let i=0; i<newImages.length; i++) {
      index.current = i;
      await new Promise((resolve, reject) => {
          setCroppingImage({
              base64: newImages[i].base64,
              imagem: newImages[i].imagem,
              size: newImages[i].size,
              resolve: resolve,
              reject: reject
          });
      });
    }
    setLoadingModal(false);
  };

  const onCloseCrop = () => {
    setLoadingModal(false);
  }
  
  const onImageCrop = (base64: string, imagem: string, size: number) => {
    imageCropped.current = { base64, imagem, size };
    onChange(imageCropped.current!);
    croppingImage.resolve(true);
  };

  const modal = (
    cropping: JSX.Element,
    cancelar: JSX.Element,
    salvar: JSX.Element,
  ) => {
    return (
      <Modal
        isOpen={true}
        background={{
          backgroundColor: 'transparent',
          backdropFilter: '',
        }}
        setModal={() => {}}
        width={"auto"}
        borderRadius="20px"
      >
        <ModalCropContainer theme={theme}>
          <div className="titulo">Editar imagem: {index.current + 1} de {length.current}</div>
          {cropping}
          <div className="botoes">
            {cancelar}
            {salvar}
          </div>
        </ModalCropContainer>
      </Modal>
    );
  };

  return (
    <>
      <LogoInputContainerProdutoWrapper theme={theme}>
        <MultipleImageUpload  
          onLoading={(loading) => setLoading(loading)}
          className={`logo-input-container-produto`}
          acceptFormats={".png, .jpeg, .jpg, .webp"}
          aspectRatio={aspectRatio}
          onChange={onUploadImages}
          onDragDrop={onDragDrop}
          errorMargin={50}
          minWidth={minWidth}
          minHeight={minHeight}
          style={{ width: "100%", height: "120px" }}
        >
          <div
            className={`wrapper ${!isDragging && "dragging"}`}
            onDragEnter={onDragIn}
            onDragLeave={onDragOut}
            onDragOver={onDragIn}
          >
            <>
              {loading ? (
                <div className="loading">Carregando...</div>
              ) : (
                <div className="icones">
                  <GaleryIcon
                    className={`galery-icon ${isDragging && "galery-ativo"}`}
                  />
                  <PlayIcon
                    className={`play-icon ${isDragging && "play-ativo"}`}
                  />
                </div>
              )}
              <div className="texto-wrapper">
                <div
                  className="texto"
                  style={{ opacity: !isDragging ? "1" : "0" }}
                >
                  Clique aqui ou arraste um arquivo para carregar
                </div>
                <div
                  className="texto"
                  style={{ opacity: isDragging ? "1" : "0" }}
                >
                  Solte o arquivo aqui
                </div>
              </div>
            </>
          </div>
        </MultipleImageUpload>
        <div className="linha_">
          <div></div>
        </div>
      </LogoInputContainerProdutoWrapper>
      <Modal
        isOpen={loadingModal}
        setModal={setLoadingModal}
        width={"auto"}
        borderRadius="20px"
      >
        <ModalCropContainer theme={theme}>
          <div></div>
          <div className="loading">
            <Player
                autoplay
                loop
                src={animation}
                style={{ height: "50px", width: "50px" }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
            </Player>
          </div>
          <div></div>
        </ModalCropContainer>
      </Modal>
      <CroppingComponent
        minWidth={800}
        minHeight={800}
        resize={{ width: 900, height: 900 }}
        aspectRatio={1 / 1}
        onCloseCrop={onCloseCrop}
        value={croppingImage}
        onChange={onImageCrop}
        onOpenCrop={modal}
        onLoading={(loading) => {}}
      />
    </>
  );
};
