import styled from "styled-components";
import theme from "../../Themes/light";

const transitionTime = "0.3s";
const inputWidth = "25rem";
const flexGap = "2rem";


const ListasPreco = styled.div`
  display: flex;
  gap: ${flexGap};
  .nomes_lista {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 0 1rem 0 0;
    width: ${inputWidth};
    .nome {
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .nova_lista {
    .mais {
      background-color: ${(props) => props.theme.colors.brandPrimary};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;

      .plus {
        width: 17px;
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const ViewVariacoes = styled.div`
  display: flex;
  margin: 4rem 0;
  align-items: center;
  gap: ${flexGap};

  .nome_variacao {
    display: flex;
    position: relative;
    justify-content: center;
    width: ${inputWidth};
    // Aqui você pode adicionar os estilos específicos para nome_variacao
    .inputComponentContainer {
      width: 150px;
    }
    .replicar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    position: absolute;
    cursor: default;
    bottom: -3rem;
    opacity: 0; /* Initially set to be invisible */
    transition: opacity 0.3s ease; /* Define the transition effect */
    svg {
        margin-right: 1rem;
    }
    &.visible {
      cursor: pointer;
        opacity: 1; /* When the 'visible' class is added, make it visible */
    }
}

  }

  .preco_variacao {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    width: ${inputWidth};
    justify-content: center;
    .inputComponentContainer {
      width: 100%;
    }
    .replicar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    position: absolute;
    cursor: default;
    bottom: -3rem;
    opacity: 0; /* Initially set to be invisible */
    transition: opacity 0.3s ease; /* Define the transition effect */
    svg {
        margin-right: 1rem;
    }
    &.visible {
      cursor: pointer;
        opacity: 1; /* When the 'visible' class is added, make it visible */
    }
  }
  }

  .espaco_final {
    width: 150px;
    margin-left: 15px;
    .teste {
      width: 250px;
    }
  }

  .deleta_variacao {
    cursor: pointer;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 1.5rem;
      height: 2px;
      border-radius: 5rem;
      background-color: ${(props) => props.theme.colors.brandSecondary};
    }
  }
`;

export { ListasPreco, ViewVariacoes };
