import { createContext } from "react";

export type PagSeguroContextType = {
  linkAutorizacao: string;
  ativado: boolean;
  verificarAtivacao: () => Promise<{
    linkAutorizacao: string;
    ativado: boolean;
  }>;
};

export const PagSeguroContext = createContext<PagSeguroContextType>(null!);
