import styled from "styled-components";

export const Middle = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.elements};
  border-radius: 15px;
  align-self: center;
  margin: 50px 0;
`;

interface IconWrapperProps {
  firstTwo?: boolean;
}

export const IconsContainer = styled.div`
  display: flex;
  row-gap: 4rem;
  @media (max-width: 1024px) {
    justify-content: center;
  }
  @media (max-width: 535px) {
    flex-wrap: wrap;
  }
`;
export const IconWrapper = styled.div<IconWrapperProps>`
  width: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  .bola {
    cursor: pointer;
    width: 11rem;
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.firstTwo
        ? props.theme.colors.brandPrimary
        : props.theme.colors.elements};
    border-radius: 50%;

    svg {
      width: 25px;
      fill: ${(props) =>
        props.firstTwo ? "white" : props.theme.colors.placeholder};
    }
  }

  .texto {
    text-align: center;
    margin-top: 10px;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
`;

interface ContainerCompartilharProps {
  isGrid?: boolean;
}

export const ContainerCompartilhar = styled.div<ContainerCompartilharProps>`
  padding: 10rem 10rem 10rem 10rem;
  display: ${(props) => (props.isGrid ? 'grid' : 'block')};
  grid-template-columns: ${(props) => (props.isGrid ? '50% 50%' : 'none')};

  .conteudo {
    .topo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${(props) => props.theme.colors.white};
      border-radius: 15px;
      padding: 1rem 5rem;
      @media (max-width: 1024px) {
        padding: 3rem 5rem;
      }
      svg {
        margin-top: -4rem;
      }

      .texto {
        font-family: "D-DIN";
        text-align: right;
        color: ${(props) => props.theme.colors.brandPrimary};
        font-size: 3rem;
        font-weight: bold;
        @media (max-width: 1024px) {
          font-size: 2.5rem;
        }
      }
    }
    .catalogos {
      .titulo {
        font-size: 3rem;
        text-align: left;
        color: ${(props) => props.theme.colors.titleColor};
        font-weight: bold;
        margin: 5rem 0;
      }
    }
    .compartilhar {
      .titulo {
        text-align: left;
        padding: 3rem 1rem;
        font-weight: bold;
        color: ${(props) => props.theme.colors.brandSecondary};
        @media (max-width: 1024px) {
          text-align: center;
          padding: 2rem 0;
        }
      }
    }
    .baixo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${(props) => props.theme.colors.white};
      border-radius: 15px;
      margin-top: 10rem;
      padding: 1rem 5rem;
      @media (max-width: 1024px) {
        padding: 3rem 5rem;
      }

      svg {
        margin-top: -4rem;
      }

      .texto {
        font-family: "D-DIN";
        text-align: left;
        color: ${(props) => props.theme.colors.brandPrimary};
        font-size: 3rem;
        font-weight: bold;
        @media (max-width: 1024px) {
          font-size: 2.5rem;
        }
        span {
          font-weight: normal;
          font-size: 2.5rem;
        }
        .configuracoes {
          display: flex;
          justify-content: flex-start;
          margin-top: 30px;
          align-items: center;
          .botao {
            cursor: pointer;
            font-size: 2.5rem;
            font-weight: normal;
            color: ${(props) => props.theme.colors.brandSecondary};
            background-color: ${(props) => props.theme.colors.elements};
            padding: 1rem 10rem;
            border-radius: 15px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .url{
    text-align: left;
    color: white;
    margin-top: 15px;
    a{
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
  @media (max-width: 1024px) {
    padding: 5rem 5rem 10rem 5rem;
    grid-template-columns: 100%;
  }
  @media (max-width: 535px) {
    .conteudo {
      .baixo {
        flex-wrap: wrap;
        padding: 1rem 1rem;
        gap: 2rem;
        .texto {
          flex-grow: 1;
          text-align: center;
          .configuracoes {
            justify-content: center;
            margin-top: 1rem;
          }
        }
        svg {
          margin-top: 0rem;
          flex-grow: 1;
        }
      }
    }
  }
`;
