import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { configuracoesApi } from "../../hooks/configuracoesApi";
import { cuponsApi } from "../../hooks/cuponsApi";
import { boolean, copy, deepMerge } from "../../services/Functions";
import Configuracoes from "../../types/Configuracoes";
import { FreteEspecial } from "../../types/FreteEspecial";
import { FreteGratis } from "../../types/FreteGratis";
import { EmpresaContext } from "../Empresa/EmpresaContext";
import { ConfiguracoesContext } from "./ConfiguracoesContext";
import {
  ConfiguracoesJson,
  politica_default,
  politicaTrocaeDevolucao_default,
} from "./ConfiguracoesJson";

export const ConfiguracoesProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const api = configuracoesApi();
  const cupomApi = cuponsApi();
  const [loading, setLoading] = useState<boolean>(true);

  const { empresa, setEmpresa } = useContext(EmpresaContext);

  const [configuracoes, setConfiguracoes] = useState<Configuracoes | null>(
    null
  );

  const [configuracoesDefault, setConfiguracoesDefault] =
    useState<Configuracoes | null>(null);
  const [erp_modificado, setErp_modificado] = useState<boolean>(false);
  const [fretes_especiais, setFretes_especiais] = useState<
    Array<FreteEspecial>
  >([]);
  const [fretes_gratis, setFretes_gratis] = useState<Array<FreteGratis>>([]);
  const [cupons, setCupons] = useState<any>([]);
  const [politica_de_privacidade, setPolitica_de_privacidade] =
    useState<string>(politica_default);
  const [politica_de_troca_e_devolucao, setPolitica_de_troca_e_devolucao] =
    useState<string>(politicaTrocaeDevolucao_default);

  const requestConfiguracoes = async () => {
    const response = await api.select_configuracoes_edicao();
    const response_cupons = await cupomApi.getCupons();
    if (response.configuracoes) {
      deepMerge(response.configuracoes, ConfiguracoesJson);
      setConfiguracoes(response.configuracoes);
      setConfiguracoesDefault(response.configuracoes);
      setFretes_especiais(response.fretes_especiais);
      setFretes_gratis(response.fretes_gratis);
      setPolitica_de_privacidade(
        response.politica_de_privacidade === null
          ? politica_default
          : response.politica_de_privacidade
      );
      setPolitica_de_troca_e_devolucao(
        response.politica_de_troca_e_devolucao === null
          ? politicaTrocaeDevolucao_default
          : response.politica_de_troca_e_devolucao
      );
      setCupons(response_cupons.data);
    }
  };

  const salvarConfiguracoes = async () => {
    setLoading(true);
    if (configuracoes === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erro ao inicializar as configurações!",
      });
      return false;
    }

    setConfiguracoes({ ...configuracoes, primeiro_acesso: false });

    const foramEditadas = foramFeitasEdicoes(
      configuracoesDefault,
      configuracoes
    );

    const newEmpresa = {
      configuracoes: { ...configuracoes, primeiro_acesso: false },
      fretes_especiais: fretes_especiais,
      fretes_gratis: fretes_gratis,
      politica_de_privacidade: politica_de_privacidade,
      cupons: cupons,
      politica_de_troca_e_devolucao: politica_de_troca_e_devolucao,
      erp_modificado: foramEditadas,
    };
    try {
      await api.salvar_configuracoes(newEmpresa);
      setEmpresa({
        ...empresa!,
        logo_empresa:
          typeof configuracoes.dados_empresa.informacoes_gerais.logotipo ===
          "object"
            ? (
                configuracoes.dados_empresa.informacoes_gerais.logotipo as {
                  base64: string;
                  imagem: string;
                  size: number;
                }
              ).base64
            : (configuracoes.dados_empresa.informacoes_gerais
                .logotipo as string),
        mensagem_whats: configuracoes.mensagem_whats,
      });

      setLoading(false);

      return true;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erro ao salvar as configurações!",
      });
      setConfiguracoes({ ...configuracoes, primeiro_acesso: true });
      return false;
    }
  };

  const processarLogo = async (base64: string) => {
    try {
      setLoading(true);
      const newLogo: string = await api.processar_logo(base64);
      if (configuracoes) {
        setConfiguracoes({
          ...configuracoes,
          dados_empresa: {
            ...configuracoes.dados_empresa,
            informacoes_gerais: {
              ...configuracoes.dados_empresa.informacoes_gerais,
              logotipo: newLogo,
            },
          },
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erro ao processar a logo!",
      });
      setLoading(false);
    }
  };



  function foramFeitasEdicoes(configuracoesDefault, configuracoesEditadas) {
    // Função interna para verificar as mudanças em um objeto específico
    function verificarMudancas(objDefault, objEditado) {
      for (let chave in objDefault) {
        if (Array.isArray(objDefault[chave])) {
          if (objDefault[chave].length !== objEditado[chave].length) {
            return true;
          }
          for (let i = 0; i < objDefault[chave].length; i++) {
            if (objDefault[chave][i] !== objEditado[chave][i]) {
              return true;
            }
          }
        } else {
          if (objDefault[chave] !== objEditado[chave]) {
            return true;
          }
        }
      }
      return false;
    }

    let mudancasBling = false;
    let mudancasBlingV2 = false;

    if (configuracoesDefault === null || configuracoesEditadas === null)
      return false;

    mudancasBling = verificarMudancas(
      configuracoesDefault.dados_bling,
      configuracoesEditadas.dados_bling
    );

    if (configuracoesDefault.dados_bling_v2) {
      mudancasBlingV2 = verificarMudancas(
        configuracoesDefault.dados_bling_v2,
        configuracoesEditadas.dados_bling_v2
      );
    }

    // Verifica as mudanças em dados_tiny_v2
    const mudancasTinyV2 = verificarMudancas(
      configuracoesDefault.dados_tiny_v2,
      configuracoesEditadas.dados_tiny_v2
    );

    // Retorna true se houve mudanças em qualquer um dos objetos, caso contrário, retorna false
    return mudancasBling || mudancasTinyV2 || mudancasBlingV2;
  }

  const init = async () => {
    if (
      localStorage.getItem("configuracoes") &&
      localStorage.getItem("fretes_gratis") &&
      localStorage.getItem("fretes_especiais") &&
      localStorage.getItem("politica_de_privacidade") &&
      localStorage.getItem("politica_de_troca_e_devolucao")
    ) {
      // se já tiver as configurações salvas no localStorage pegar elas, vem do stepper de modais de configs inicias
      setConfiguracoes(JSON.parse(localStorage.getItem("configuracoes")!));
      setFretes_especiais(
        JSON.parse(localStorage.getItem("fretes_especiais")!)
      );
      setFretes_gratis(JSON.parse(localStorage.getItem("fretes_gratis")!));
      setPolitica_de_privacidade(
        localStorage.getItem("politica_de_privacidade")!
      );
      setPolitica_de_troca_e_devolucao(
        localStorage.getItem("politica_de_troca_e_devolucao")!
      );
    } else {
      await requestConfiguracoes();
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  // transfomar campos do pedido em obrigatórios se o intermediador de pagamento ou o frete estiverem ativos
  useEffect(() => {
    if (configuracoes === null) return;

    const copiaConfig = copy(configuracoes);

    const camposPagamento = [
      "email",
      "telefone",
      "cep",
      "estado",
      "cidade",
      "endereco",
      "numero",
      "bairro",
    ];
    const camposFrete = [
      "cep",
      "estado",
      "cidade",
      "endereco",
      "numero",
      "bairro",
    ];

    const pagamentoAtivo =
      boolean(configuracoes.intermediadores.pagarme) ||
      boolean(configuracoes.intermediadores.pagseguro)
        ? true
        : false;
    const freteAtivo =
      boolean(configuracoes.frete.correiosAtivo) ||
      boolean(configuracoes.frete.freteEspecialAtivo) ||
      boolean(configuracoes.frete.freteGratisAtivo) ||
      boolean(configuracoes.frete.melhorEnvio.ativo) ||
      boolean(configuracoes.frete.entregaLocal.ativo)
        ? true
        : false;

    const atualizarCampos = (campos, camposAlvo, ativo) => {
      campos
        .filter((campo) => camposAlvo.includes(campo.campo))
        .forEach((campo) => {
          campo.obrigatorio = ativo;
          campo.ativo = ativo ? true : campo.ativo;
        });
    };

    [
      copiaConfig.campos_pedido_geral,
      copiaConfig.campos_pedido_pessoa_fisica,
      copiaConfig.campos_pedido_pessoa_juridica,
    ].forEach((campos) => {
      atualizarCampos(campos, camposPagamento, pagamentoAtivo);
      atualizarCampos(campos, camposFrete, freteAtivo || pagamentoAtivo);
    });

    setConfiguracoes(copiaConfig);
  }, [
    configuracoes?.frete.correiosAtivo,
    configuracoes?.frete.freteEspecialAtivo,
    configuracoes?.frete.melhorEnvio.ativo,
    configuracoes?.frete.freteGratisAtivo,
    configuracoes?.frete.entregaLocal.ativo,
    configuracoes?.pagamentoOnlineAtivo,
    configuracoes?.pagamentoCombinarAtivo,
  ]);

  return (
    <ConfiguracoesContext.Provider
      value={{
        configuracoes: configuracoes || ConfiguracoesJson,
        setConfiguracoes,
        fretes_especiais,
        processarLogo,
        setFretes_especiais,
        fretes_gratis,
        setFretes_gratis,
        politica_de_privacidade,
        setPolitica_de_privacidade,
        politica_de_troca_e_devolucao,
        setPolitica_de_troca_e_devolucao,
        salvarConfiguracoes,
        requestConfiguracoes,
        loading,
        setLoading,
        cupons,
        setCupons,
      }}
    >
      {children}
    </ConfiguracoesContext.Provider>
  );
};
