import styled from "styled-components";
import { rgba } from "../../services/Functions";

export const MeusProdutosView = styled.div`
  width: 100%;
  padding: 5rem 10rem 13rem 10rem;
  font-size: 2.5rem;
  overflow: hidden;

  .grid-header {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .select{
      flex-grow: 1;
    }

    .input{
      flex-grow: 1;
    }

    .pesquisa {
      width: 62rem;
    }

    .categorias{
      width: 35rem;
      .view-select {
        .select{
          .selecionado{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .ativos{
      width: 23rem;
    }

    .estoques{
      width: 40rem;
    }

    .botoes{
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
    }
  }

  .produtos {
    width: 100%;
    padding: 10rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    gap: 4rem;
    align-items: center;
    justify-content: space-between;
    justify-items: center;

    .options-mobile {
      transform: translateY(25%) translateX(20rem) scale(0);
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: ${(props) => props.theme.colors.white};
      align-items: center;
      position: fixed;
      z-index: 50;
      bottom: 20rem;
      right: 13rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px;
      border-radius: 16px;
      transition: all 0.2s ease-in-out;

      &.ativo {
        transform: translateY(0%) translateX(0%) scale(1);
      }
      .option {
        text-align: left;
        font-size: 2.5rem;
        width: 100%;
        color: ${(props) => props.theme.colors.placeholder};
        padding: 1rem 2rem;
        &.ativo {
          color: ${(props) => props.theme.colors.brandSecondary};
          background-color: ${(props) =>
            rgba(props.theme.colors.titleColor, 0.3)};
        }
        &.primeiro {
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
        }
        &.ultimo {
          border-bottom-right-radius: 16px;
          border-bottom-left-radius: 16px;
        }
      }
    }

    .mensagem {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      grid-column: 2 / -1;

      .texto-titulo {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-size: 3.5rem;
      }

      .texto-subtitulo {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-size: 2.5rem;
      }
      
      @media (max-width: 1024px) {
        grid-column: 1 / -1;
      }
    }

    .produto-container {
      width: 100%;
      height: 100%;

      a {
        width: 100%;
        height: 100%;
      }
    }

    .center {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px;
      transition: all 0.3s ease-in-out;
      border-radius: 16px;
      &.inativo {
        .cadastro {
          opacity: 0.3;
        }
      }
    }

    .adicionar_produto {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      position: relative;
      border-radius: 16px;
      min-height: 15rem;
      width: 100%;
      aspect-ratio: 1/1;
      border-bottom: 2px solid #c7c7c7;
      border-bottom: 2px solid #c7c7c7;
      position: relative;
      background-color: ${(props) => props.theme.colors.brandPrimary};

      .plus {
        fill: ${(props) => props.theme.colors.white};
      }
    }
    .cadastro {
      font-size: 2rem;
      padding: 2rem 4rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      background-color: ${(props) => props.theme.colors.white};
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      .texto {
        text-align: left;
        color: ${(props) => props.theme.colors.placeholder};
      }
      
      .select {
        text-align: left;
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 3rem 3rem 13rem 3rem;

    .produtos {
      grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    }
  }
`;

export const ModalNovoProduto = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  .titulo {
    line-height: 1;
    margin: 20px 0;

    span {
      color: ${(props) => props.theme.colors.titleColor};
      font-size: 3.5rem;
      font-weight: bold;
    }
  }

  .link {
    display: flex;
    justify-content: center;

    .center {
      display: flex;
      justify-content: center;
      width: 80%;
      background-color: ${(props) => props.theme.colors.brandPrimary};
      border-radius: 30px;
      margin: 7px 0;
      padding: 7px 0;
      cursor: pointer;
      text-decoration: none;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;
