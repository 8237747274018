import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

export const LoadingContainerRelativo = styled.div<{
  theme: ThemeType;
  position: "absolute" | "fixed";
}>`
  > .componente-carregando-pagina {
    position: ${({ theme, position }) => position};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    height: 100%;
    background-color: ${({ theme, position }) => theme.colors.background1};
    z-index: 200;
    .mensagem {
      font-size: 4rem;
      font-weight: bold;
      color: ${({ theme, position }) => theme.colors.brandSecondary};
    }
    .sub-mensagem {
      font-size: 2.5rem;
      color: ${({ theme, position }) => theme.colors.brandSecondary};
    }
  }
`;
