import { Controls, Player } from "@lottiefiles/react-lottie-player";
import SRC from "../../../assets/jsons/Loading.json";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { LoadingContainerRelativo } from "./styles";

interface LoadingComFundoProps {
  mensagem?: string;
  subMensagem?: string;
  position?: "absolute" | "fixed";
}

const LoadingComFundo: React.FC<LoadingComFundoProps> = ({
  mensagem,
  subMensagem,
  position = "absolute",
}) => {
  const { theme } = useTheme();
  const src = SRC;

  return (
    <>
      <LoadingContainerRelativo theme={theme} position={position}>
        <div
          className="componente-carregando-pagina"
          ng-show="$ctrl.carregando"
        >
          <Player
            autoplay
            loop
            src={src}
            style={{ height: "10rem", width: "10rem" }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
          {mensagem && <div className="mensagem">{mensagem}</div>}
          {subMensagem && <div className="sub-mensagem">{subMensagem}</div>}
        </div>
      </LoadingContainerRelativo>
    </>
  );
};

export default LoadingComFundo;
