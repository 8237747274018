import React, { useContext, useEffect, useState } from 'react';
import NumeroInternacionalContainer from './styles';
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";
import { useTheme } from '../../contexts/Theme/ThemeContext';
import { ConfiguracoesContext } from '../../contexts/Configuracoes/ConfiguracoesContext';


interface NumeroInternacionalProps {
  type: string;
  value?: string;
  required?: boolean;
  setConfiguracoes:any;
  name: String;
}

const NumeroInternacional: React.FC<NumeroInternacionalProps> = ({
  type,
  value,
  required,
  name,
  setConfiguracoes,
}) => {
  const { theme } = useTheme();


  const [number, setNumber] = useState<string | null>(null);
  const { configuracoes } = useContext(ConfiguracoesContext);

  const handlePhoneNumberChange = (number: string) => {
    if (configuracoes.dados_empresa.redes_sociais.whatsapp !== number) {
      setConfiguracoes({
        ...configuracoes,
        dados_empresa: {
          ...configuracoes.dados_empresa,
          redes_sociais: {
            ...configuracoes.dados_empresa.redes_sociais,
            whatsapp: number,
          },
        },
      });
    }
  };

  return (
    <NumeroInternacionalContainer theme={theme}>
      <IntlTelInput
      initialValue={value}
      onChangeNumber={handlePhoneNumberChange}
        initOptions={{
          allowDropdown: true,
          separateDialCode: true,
          formatAsYouType: true,
          formatOnDisplay: true,
          fixDropdownWidth: true,
          autoPlaceholder: "polite",
          initialCountry: "br",
          nationalMode: true,
          placeholderNumberType: "MOBILE",
          validationNumberType: "MOBILE",
          showFlags: true,
          i18n: {
            searchPlaceholder: 'Buscar'
          }
        }}
        inputProps={{
          name: name,
          required: required,
          type: type,
        }}
      />
    </NumeroInternacionalContainer>
  );
};

export default NumeroInternacional;
