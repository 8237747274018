import React, { useEffect, useState } from "react";
import Success from "../../../assets/jsons/Success.json";
import Error from "../../../assets/jsons/Error.json";
import Loading from "../../../assets/jsons/Loading.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { ModalSucessoContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface ModalSucessoProps {
  message: string;
  type: string;
}

const ModalSucesso: React.FC<ModalSucessoProps> = ({ message, type }) => {
  const [animation, setAnimation] = useState<any>(Success);

  const { theme } = useTheme();

  const handleAnimation = () => {
    switch (type) {
      case "success":
        setAnimation(Success);
        break;
      case "error":
        setAnimation(Error);
        break;
      case "loading":
        setAnimation(Loading);
        break;
    }
  };

  useEffect(() => {
    handleAnimation();
  }, [type]);

  return (
    <ModalSucessoContainer theme={theme}>
      <div className="modal-sucesso">
        <div className="mensagem">{message}</div>
        <Player
          autoplay
          loop
          src={animation}
          style={{ height: "80px", width: "80px" }}
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
      </div>
    </ModalSucessoContainer>
  );
};

export default ModalSucesso;
