import React, { useContext, useEffect } from "react";
import { ContainerVisualFiltrosContainer } from "./styles";
import { Input } from "../../Input";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { MockupCatalogo } from "../../Mockups/MockupCatalogo/MockupCatalogo";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { copy } from "../../../services/Functions";
import { Select } from "../../Select";
import { Toggle } from "../../Toggle/Toggle";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface VisualFiltrosProps {
  configIn: "empresa" | "configuracoes";
}

const VisualFiltros: React.FC<VisualFiltrosProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const [mostrarCategorias, setMostrarCategorias] = React.useState(true);

  const { theme } = useTheme();

  return (
    <>
      <ContainerVisualFiltrosContainer configIn={configIn} theme={theme}>
        <div className="informacoes">
          {configIn === "empresa" && (
            <Titulo
              titulo="Visual"
              subtitulo="Configure seu catálogo"
              justifyContent="flex-start"
            />
          )}
          <div className="compartilhamento">
            <label>
              <div className="toggleContainer">
                <Toggle
                  value={
                    configuracoes.catalogo
                      .mostrar_botao_compartilhamento_categoria
                  }
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      catalogo: {
                        ...configuracoes.catalogo,
                        mostrar_botao_compartilhamento_categoria: newValue,
                      },
                    });
                    setMostrarCategorias(true);
                  }}
                ></Toggle>
                Compartilhamento de categoria
              </div>
            </label>
          </div>
          <div className="subtitulo">Filtrar produtos no catálogo</div>
          <div className="texto">
            Se alguma destas opções estiver habilitada, os produtos aparecerão
            no catálogo para o cliente conforme os filtros habilitados.
          </div>
          <div className="toggles">
            <label>
              <div className="toggleContainer">
                <Toggle
                  value={configuracoes.mostrar_grupos}
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      mostrar_grupos: newValue,
                    });
                    setMostrarCategorias(false);
                  }}
                ></Toggle>
                Por categoria
              </div>
            </label>
            <label>
              <div className="toggleContainer">
                <Toggle
                  value={configuracoes.mostrar_variacoes}
                  onChange={(newValue) => {
                    setConfiguracoes({
                      ...configuracoes,
                      mostrar_variacoes: newValue,
                    });
                    setMostrarCategorias(false);
                  }}
                ></Toggle>
                Por variação
              </div>
            </label>
          </div>
        </div>
        <div className="mockup-container">
          <MockupCatalogo
            menu={false}
            banner={true}
            categorias={mostrarCategorias}
            configuracoes={configuracoes}
          />
        </div>
      </ContainerVisualFiltrosContainer>
    </>
  );
};

export default VisualFiltros;
