import { Outlet } from "react-router-dom";
import { ProdutosPlanilhaProvider } from "../../../contexts/ProdutosPlanilha/ProdutosPlanilhaProvider";
interface ImportarProdutosNovo {

}

const ImportarProdutos: React.FC<ImportarProdutosNovo> = ({  }) => {  

  return (
    <>
      <ProdutosPlanilhaProvider>
        <Outlet />
      </ProdutosPlanilhaProvider>
    </>
  );
};

export default ImportarProdutos;
