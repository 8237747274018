import styled from "styled-components";

const transitionTime = "1s";
const ContainerCatalogosListagemSkeleton = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 16px;
  animation: pulsate ${transitionTime} ease-out infinite alternate;
  animation-delay: calc(var(--i) * 0.1s);

  @keyframes pulsate {
    0% {
      background-color: ${(props) => props.theme.colors.skeleton1};
    }

    100% {
      background-color: ${(props) => props.theme.colors.skeleton2};
    }
  }
`;

export default ContainerCatalogosListagemSkeleton;
