import React, { useContext, useEffect, useState } from "react";
import "./styles";
import { ModaisConfigContainer } from "./styles";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";
import Titulo from "../../../../components/Titulo/Titulo";
import ChaveIntegracao from "../../../../components/Configuracoes/ChaveIntegracao/ChaveIntegracao";
import IntegracaoBling from "../../../../components/Configuracoes/IntegracaoBling/IntegracaoBling";
import IntegracaoTiny from "../../../../components/Configuracoes/IntegracaoTiny/IntegracaoTiny";
import { IntegracaoContext } from "../../../../contexts/Integracao/IntegracaoContext";
import Swal from "sweetalert2";
import { ReactComponent as Arrow } from "../../../../assets/newImages/icons/arrowLeft.svg";

import { ConfiguracoesContext } from "../../../../contexts/Configuracoes/ConfiguracoesContext";
import { Button } from "../../../../components/Button";
import { copy } from '../../../../services/Functions';
import { on } from "events";
import ReactDOM from 'react-dom';
import Form from "../../../../components/Form";
import IntegracaoBlingV2 from "../../../../components/Configuracoes/IntegracaoBlingV2/IntegracaoBlingV2";

interface ModaisConfigProps {
    selectedConfig: "bling" | "tiny" | "macro" | "";
    onClose: () => void;
    isTinyValid: () => boolean;
    isBlingValid: () => boolean;
    isIntegracaoValid: () => boolean;
}

const ModaisConfig: React.FC<ModaisConfigProps> = ({
    selectedConfig,
    onClose,
    isTinyValid,
    isBlingValid,
    isIntegracaoValid,
}) => {
    const { theme } = useTheme();

    const [titulos, setTitulos] = useState({
        titulo: "",
        subtitulo: "",
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const { chaveIntegracao, setChaveIntegracao } =
        useContext(IntegracaoContext);

    const { configuracoes, setConfiguracoes, salvarConfiguracoes, loading } =
        useContext(ConfiguracoesContext);

    const [configuracoesAuxiliar, setConfiguracoesAuxiliar] = useState(
        {} as any,
    );

    const onBeforeClose = (cancel: boolean) => {
        if (cancel) {
            setConfiguracoes(configuracoesAuxiliar);
        }
        onClose();
    };

    useEffect(() => {
        switch (selectedConfig) {
            case "bling":
                setTitulos({
                    titulo: "Meus produtos / Adicionar através do ERP",
                    subtitulo: "Integração Bling ERP",
                });
                break;
            case "tiny":
                setTitulos({
                    titulo: "Meus produtos / Adicionar através do ERP",
                    subtitulo: "Integração Tiny ERP",
                });
                break;
            case "macro":
                setTitulos({
                    titulo: "Meus produtos / Adicionar através do ERP",
                    subtitulo: "Chave de API",
                });
                break;
            default:
                setTitulos({
                    titulo: "",
                    subtitulo: "",
                });
                break;
        }
    }, [selectedConfig]);

    useEffect(() => {
        setConfiguracoesAuxiliar(copy(configuracoes));
    }, []);

    useEffect(() => {
        if(selectedConfig === 'bling' && !configuracoes.dados_bling.ativo){
            onBeforeClose(true);
        }
    }, [configuracoes.dados_bling.ativo]);   

    return (
        <ModaisConfigContainer theme={theme}>
            <Form id={"form-config-inner"} setFormValid={setIsFormValid} onSubmit={() => onBeforeClose(false)}>
                <Titulo title={titulos.titulo} subtitle={titulos.subtitulo} />
                {selectedConfig === "macro" && (
                    <ChaveIntegracao
                        chave={chaveIntegracao}
                        setChaveDeIntegracao={setChaveIntegracao}
                    />
                )}
                {selectedConfig === "bling" && configuracoes.dados_bling_v2 !== undefined ? <IntegracaoBlingV2 /> : <IntegracaoBling />}
                {selectedConfig === "tiny" && <IntegracaoTiny />}
                <div className="botoes">
                    <div className="arrow" onClick={() => onBeforeClose(true)}>
                        <Arrow />
                        voltar
                    </div>
                    <Button
                        title="Salvar"
                        loading={loading}
                        type="submit"
                        className={`${!isFormValid ? "disabled" : ""}`}
                    />
                </div>
            </Form>
        </ModaisConfigContainer>
    );
};

export default ModaisConfig;
