import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from "react";

interface FormProps {
  children: React.ReactNode;
  id: string;
  setFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
  className?: string;
  onInvalid?: (input: HTMLInputElement | HTMLSelectElement) => void;
  onSubmit?: (
    event: React.FormEvent<HTMLFormElement>,
    inputs: Array<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  scrollTimeout?: number;
}

export interface FormRef {
  submitForm: () => void;
}

const Form = forwardRef<FormRef, FormProps>(
  (
    {
      children,
      id,
      name,
      setFormValid,
      onSubmit,
      className,
      onInvalid,
      scrollTimeout,
    },
    ref,
  ) => {
    const form = useRef<HTMLFormElement>(null);

    const handleFormSubmit = (event) => {
      if (form !== null && form.current !== null) {
        event.preventDefault();
        event.stopPropagation();
        const isValid = form.current.checkValidity();
        setFormValid(isValid);
        const inputs = Array.from(form.current.elements) as Array<
          HTMLInputElement | HTMLSelectElement
        >;
        if (!isValid) {
          inputs.every((input) => {
            if (!input.validity.valid) {
              onInvalid && onInvalid(input);

              let errorSpan: HTMLSpanElement;
              errorSpan =
                input.nodeName === "SELECT" || input.type === "checkbox"
                  ? (input.previousElementSibling! as HTMLSpanElement)
                  : (input.nextElementSibling! as HTMLSpanElement);
              input.previousElementSibling as HTMLLabelElement; // pegar o label

              // clonar o elemento pra executar animação novamente sempre
              const clone = errorSpan.cloneNode(true) as HTMLElement;
              errorSpan.parentNode!.replaceChild(clone, errorSpan);
              clone.classList.add("popoverAtivo");
              errorSpan = clone as HTMLSpanElement;

              const removeAtivo = () => {
                errorSpan.classList.remove("popoverAtivo");
              };
              document.addEventListener("click", removeAtivo, { once: true });

              setTimeout(() => {
                errorSpan.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, scrollTimeout || 0);

              return false;
            }
            return true;
          });
        } else {
          onSubmit && onSubmit(event, inputs);
        }
      }
    };

    useEffect(() => {
      const formElement = form.current!;
      const isValid = formElement.checkValidity();
      setFormValid(isValid);
    }, [children]);

    // Exposing the submit function to parent component
    useImperativeHandle(ref, () => ({
      submitForm: () => {
        if (form.current) {
          const fakeEvent = {
            preventDefault: () => {},
            stopPropagation: () => {},
          };
          handleFormSubmit(fakeEvent as any);
        }
      },
    }));

    // Existing return statement
    return (
      <>
        <form
          ref={form}
          id={id}
          name={name}
          className={`form ${className ? className : ""}`}
          onSubmit={handleFormSubmit}
          noValidate
        >
          {children}
        </form>
      </>
    );
  },
);

export default Form;
