import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles";
import { FreteGratisMainContainer } from "./styles";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import FreteGratis from "../FreteGratis/FreteGratis";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import EstadosFreteGratis from "../EstadosFreteGratis/EstadosFreteGratis";
import { FreteGratis as FreteGratisType } from "../../../types/FreteGratis";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Form from "../../Form";
import { BotoesContainer, Titulo } from "../../../styled/Styled";
import { Button } from "../../Button";
import Modal from "../../Modal/Modal";
import FreteGratisListagem from "../FreteGratisListagem/FreteGratisListagem";

interface FreteGratisMainProps {}

const FreteGratisMain: React.FC<FreteGratisMainProps> = ({}) => {
  const { fretes_gratis, setFretes_gratis, configuracoes, setConfiguracoes } =
    useContext(ConfiguracoesContext);
  const [modalEstado, setModalEstado] = useState<boolean>(false);
  const [freteSelecionado, setFreteSelecionado] = useState<number>();
  const [fretes_auxiliar, setFretes_auxiliar] = useState<any>([]);
  const [freteAuxiliar, setFreteAuxiliar] = useState<FreteGratisType>();

  const { theme } = useTheme();

  const [modalFrete, setModalFrete] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const indexFrete = useRef<number>(-1);

  const handleAdicionarFrete = () => {
    indexFrete.current = -1;
    setModalFrete(true);
  };

  const cancelarEdicaoFrete = () => {
    setFretes_gratis(fretes_auxiliar);
    setModalFrete(false);
    if(fretes_auxiliar.length === 0) {
      setConfiguracoes({
        ...configuracoes,
        frete: {
            ...configuracoes.frete,
            freteGratisAtivo: false,
        },
    });
    }
  };

  const edicaoFrete = (index: number) => {
    indexFrete.current = index;
    setModalFrete(true);
  };

  const onExclusaoFrete = (newLenght) => {
    setModalFrete(false);
    if(newLenght === 0) {
      setConfiguracoes({
        ...configuracoes,
        frete: {
            ...configuracoes.frete,
            freteGratisAtivo: false,
        },
    });
    }
  }

  useEffect(() => {
    if(fretes_gratis.length === 0) {
      indexFrete.current = -1;
      setModalFrete(true);
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={modalFrete}
        setModal={setModalFrete}
        width={'100rem'}
        maxWidth="98vw"
        backdrop={'static'}
        padding="3rem 4rem"
        onUseEffect={() => {
          setFretes_auxiliar(JSON.parse(JSON.stringify(fretes_gratis)));
        }}
      >
        <Form 
          id="formFreteGratis" 
          onSubmit={() => {
            setModalFrete(false)
          }} 
          setFormValid={setFormValid}
        >
          <Titulo theme={theme}>
            {indexFrete.current === -1 ? "Adicionar" : "Editar"} frete grátis
          </Titulo>
          <FreteGratis
            configIn="configuracoes"
            onExclusaoFreteGratis={onExclusaoFrete}
            onConfigurarPorEstado={() => {
              setModalEstado(true);
            }}
            index={indexFrete.current}
          />
          <BotoesContainer theme={theme}>
            <div 
              className="cancelar" 
              onClick={() => {
                cancelarEdicaoFrete();
              }}>
              Cancelar
            </div>
            <Button
              title="Salvar"
              type="submit"
              className={`${!formValid && "disabled"}`}
            />
          </BotoesContainer>
        </Form>
      </Modal>
      <FreteGratisMainContainer theme={theme}>
        <div className="dropdown">
          {fretes_gratis.length > 0 && (
            <FreteGratisListagem
              configIn="configuracoes"
              onEditarFrete={edicaoFrete}
            />
          )}
          <div className="novo" onClick={handleAdicionarFrete}>
            Adicionar frete grátis
          </div>
        </div>
        <ModalRelativo
          normalModal={true}
          zIndex={9999}
          width="auto"
          background="none"
          isOpen={modalEstado}
          setIsOpen={setModalEstado}
          titulo={""}
          subtitulo={""}
          center={true}
          botaoCancelar="Cancelar"
          cancelar={true}
          onUseEffect={() => {
            setFreteAuxiliar(fretes_gratis[indexFrete.current === -1 ? (fretes_gratis.length-1) : indexFrete.current!]);
          }}
          onCancel={() => {
            let copia = [...fretes_gratis];
            copia[indexFrete.current === -1 ? (fretes_gratis.length-1) : indexFrete.current!] = freteAuxiliar!;
            setFretes_gratis(copia);
          }}
        >
          <EstadosFreteGratis index={indexFrete.current === -1 ? (fretes_gratis.length-1) : indexFrete.current} />
        </ModalRelativo>
        
      </FreteGratisMainContainer>
    </>
  );
};

export default FreteGratisMain;
