import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "0.3s";

const LogoInputContainerProdutoWrapper = styled.div`
  .logo-input-container-produto {
    width: 100%;
    height: 30rem;
    min-height: 12rem;
    cursor: pointer;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    position: relative;
    border-radius: 20px;

    .imagem-preview {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: contain;
      cursor: normal;
    }

    .icone-apagar {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 2px;
      background-color: ${(props) => props.theme.colors.white};
      right: 1rem;
      top: 1rem;
      cursor: pointer;

      .icone {
        height: 3rem;
        width: auto;
        fill: ${(props) => props.theme.colors.brandSecondary};
        transform: rotate(45deg);
      }
    }

    > .icones {
      padding: 2rem 2rem;
      width: 15rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 0;

      .galery-icon {
        width: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: ${transitionTime} ease-in-out;
        transform: scale(0.5) translateY(-10%) translateX(-20%) rotate(355deg);
      }

      .galery-ativo {
        transform: scale(0.5) translateY(-15%) translateX(-35%) rotate(345deg);
      }

      .play-icon {
        width: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: ${(props) => props.theme.colors.white};
        transition: ${transitionTime} ease-in-out;
        transform: scale(0.5) translateY(10%) translateX(20%) rotate(5deg);
      }

      .play-ativo {
        transform: scale(0.5) translateY(15%) translateX(35%) rotate(15deg);
      }
    }

    .flex-container {
      display: flex;
      gap: 3rem;
      align-items: center;
      padding: 1rem 2rem;
      height: 100%;
      max-width: 100%;
    }

    .texto-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 70%;
      max-width: 55rem;

      .texto {
        transition: ${transitionTime} ease-in-out;
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 2.5rem;
        color: ${(props) => props.theme.colors.brandSecondary};
        position: absolute;
      }

      &::after {
        transition: ${transitionTime} ease-in-out;
        max-height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 2.5rem;
        color: ${(props) => props.theme.colors.brandSecondary};
        position: absolute;
        display: none;
        content: "Clique aqui ou arraste um arquivo";
      }

      @media (max-width: 1124px) {
        width: 50%;

        .texto {
          display: none;
        }

        &::after {
          font-size: 2.3rem;
          display: block;
        }
      }

      @media (max-width: 1024px) {
        .texto {
          display: none;
        }

        &::after {
          content: "Clique aqui para carregar um arquivo";
          display: block;
        }
      }
    }
  }
`;

export { LogoInputContainerProdutoWrapper };
