import ContainerHomeSkeleton from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonHomeProps {}

const SkeletonHome: React.FC<SkeletonHomeProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <ContainerHomeSkeleton theme={theme}>
        {Array(8)
          .fill(0)
          .map((_, index) => {
            return (
              <div
                style={{ "--i": `${index}` } as React.CSSProperties}
                key={index}
                className="home-skeleton"
              />
            );
          })}
      </ContainerHomeSkeleton>
    </>
  );
};

export default SkeletonHome;
