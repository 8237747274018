import React from "react";
import Swal from "sweetalert2";
import { Button } from "../../../components/Button";
import { FooterPaginas } from "../../../components/FooterPaginas/FooterPaginas";
import Modal from "../../../components/Modal/Modal";
import { CroppingComponent } from "../../../components/Upload/CroppingComponent";
import { ModalCropContainer } from "../../../components/Upload/ImageUploadBanners/styles";
import { ImageUploadProdutosEmMassa } from "../../../components/Upload/ImageUploadProdutosEmMassa/ImageUploadProdutosEmMassa";
import { Image } from "../../../components/Upload/MultipleImageUpload";
import UploadStatusComponent from "../../../components/Upload/UploadStatusComponent/UploadStatusComponent";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { produtosApi } from "../../../hooks/produtosApi";
import { Semaphore } from "../../../services/Functions";
import "./styles";
import { ImportarImagensContainer } from "./styles";

interface ImportarImagensProps {
  modo: { id: string; titulo: string; descricao: string };
}

const ImportarImagens: React.FC<ImportarImagensProps> = ({ modo }) => {
  const { theme } = useTheme();
  const ProdutosApi = produtosApi();
  const [loading, setLoading] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const [toCropImage, setToCropImage] = React.useState<{
    base64: string;
    imagem: string;
    size: number;
  }>({
    base64: "",
    imagem: "",
    size: 0,
  });

  const [images, setImages] = React.useState<Image[]>([]);

  const onChangeImages = async (newImages: Image[]) => {
    let newImagesArray = [...images, ...newImages];
    const imagensProdutos = await ProdutosApi.getProdutosImportacaoPorModo(
      modo.id,
      newImages.map((image) => image.imagem),
    );
    newImages.forEach((newImage) => {
      newImage.produtos = imagensProdutos[newImage.imagem] || [];
    });
    setImages(newImagesArray);
  };

  const importarImagens = async (imagesToImport: Image[]) => {
    const semaphore = new Semaphore(1); // Semaforo pra controlar os requests simultaneos
    const uploadImage = async (image: Image, index: number) => {
      await semaphore.acquire();

      const newImage = {
        base64: image.base64,
        imagem: image.imagem,
        size: image.size,
      };

      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        image.progress = percentCompleted > 90 ? 90 : percentCompleted;
        setImages([...images]);
      };

      try {
        image.status = "carregando";
        setImages([...images]);
        await ProdutosApi.importarImagem(
          newImage,
          image.produtos || [],
          onUploadProgress,
        );
        image.progress = 100;
        image.uploadStatus = "sucesso";
        image.status = "sucesso";
      } catch (error) {
        image.progress = 0;
        image.status = "erro";
        image.error = "ERRO_IMPORTACAO";
      }

      setImages([...images]);

      semaphore.release();
    };

    const imagePromises = imagesToImport.map((image, index) =>
      uploadImage(image, index),
    );
    await Promise.allSettled(imagePromises);
  };

  const iniciarImportacao = async () => {
    const imagesToImport: Image[] = [
      ...images.filter(
        (image) =>
          (image.status !== "erro" &&
            image.uploadStatus !== "sucesso" &&
            image.produtos?.length) ||
          0 > 0,
      ),
    ];
    if (imagesToImport.length === 0) {
      Swal.fire({
        title: "Nenhuma imagem para importar!",
        text: "Não há nenhuma imagem válida para importação. As imagens precisam ter produtos atrelados e estar livre de erros.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    } else {
      Swal.fire({
        title: `Tem certeza que deseja importar ${imagesToImport.length} ${
          imagesToImport.length > 1 ? "imagens" : "imagem"
        }?`,
        text: "As imagens sem produtos atrelados ou com erros não serão importadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Importar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadingButton(true);
          await importarImagens(imagesToImport);
          Swal.fire({
            title: "Importação concluída!",
            icon: "success",
            text: "Verifique o status de cada imagem para mais detalhes.",
          });
          setLoadingButton(false);
        }
      });
    }
  };

  const handleLimpar = () => {
    Swal.fire({
      title: "Tem certeza que deseja limpar as imagens?",
      text: "Essa ação não pode ser desfeita.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Limpar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setImages([]);
      }
    });
  };

  const handleEditarImagem = (image: Image) => {
    setToCropImage({
      base64: image.base64,
      imagem: image.imagem,
      size: image.size,
    });
  };

  const handleRemoverImagem = (image: Image) => {
    const remover = () => {
      setImages(
        images.filter((imageToFilter) => imageToFilter.imagem !== image.imagem),
      );
    };

    if (image.progress === 100) {
      remover();
    } else {
      Swal.fire({
        title: "Tem certeza que deseja remover essa imagem?",
        text: "Essa ação não pode ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          remover();
        }
      });
    }
  };

  const modal = (
    cropping: JSX.Element,
    cancelar: JSX.Element,
    salvar: JSX.Element,
  ) => {
    return (
      <Modal
        isOpen={true}
        setModal={() => {}}
        width={"auto"}
        borderRadius="20px"
      >
        <ModalCropContainer theme={theme}>
          <div className="titulo">Editar imagem</div>
          {cropping}
          <div className="botoes">
            {cancelar}
            {salvar}
          </div>
        </ModalCropContainer>
      </Modal>
    );
  };

  const onImageCrop = (base64: string, imagem: string, size: number) => {
    setToCropImage({
      base64: "",
      imagem: "",
      size: 0,
    });
    const newImages = images.map((image) => {
      if (image.imagem === imagem) {
        image.base64 = base64;
        image.imagem = imagem;
        image.size = size;
        image.status = "sucesso";
        image.error = undefined;
      }
      return image;
    });
    setImages(newImages);
  };

  return (
    <>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <ImportarImagensContainer theme={theme}>
          <div className="subtitulo">
            Esse campo é APENAS para atualizar as imagens.
          </div>

          <div className="upload">
            <ImageUploadProdutosEmMassa
              onChange={onChangeImages}
              images={images}
            />
          </div>
          <div className="imagens">
            {images.map((image, index) => {
              return (
                <UploadStatusComponent
                  key={index}
                  image={image}
                  onEditar={handleEditarImagem}
                  onRemover={handleRemoverImagem}
                />
              );
            })}
          </div>
          {images.length > 0 && (
            <FooterPaginas>
              <div className="botoes-container">
                <>
                  <Button
                    title="Limpar"
                    disabled={images.some(
                      (image) => image.status === "carregando",
                    )}
                    className="cinza"
                    onClick={handleLimpar}
                  ></Button>
                  <Button
                    loading={loadingButton}
                    title="Importar"
                    onClick={iniciarImportacao}
                  ></Button>
                </>
              </div>
            </FooterPaginas>
          )}
        </ImportarImagensContainer>
      )}
      <CroppingComponent
        minWidth={800}
        minHeight={800}
        resize={{ width: 900, height: 900 }}
        aspectRatio={1 / 1}
        value={toCropImage}
        onChange={onImageCrop}
        onOpenCrop={modal}
        onLoading={(loading) => {}}
      />
    </>
  );
};

export default ImportarImagens;
