import styled from "styled-components";
import theme from "../../../Themes/light";

const RelatorioWrapper = styled.div`
  width: 100%;
  padding: 2rem 0 20rem 0;

  .relatorio-header {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;

    .variables-wrapper {
      display: flex;
      gap: 4rem;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .select-wrapper {
        width: 50rem;
      }

      .input-wrapper {
        min-width: 30rem;
      }
    }
  }

  .flex-footer-relatorios {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .botao {
      padding: 0.3rem 0;
    }

    .exportar {
      &::after {
        content: "Exportar relatório";
      }
    }

    .imprimir {
      background-color: ${theme.colors.placeholder};
      color: ${theme.colors.brandSecondary};
      &::after {
        content: "Imprimir relatório";
      }
    }

    > span {
      display: inline-flex;
      gap: 3rem;
    }

    .valor {
      color: ${theme.colors.brandSecondary};
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 1rem;
      align-items: center;
      flex-shrink: 1111;

      .valor-flex {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .texto {
          color: ${theme.colors.placeholder};
          white-space: normal;
        }
      }
    }
    .estoque {
      color: ${theme.colors.brandSecondary};
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 1rem;
      align-items: center;
      flex-shrink: 1111;

      .estoque-flex {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .texto {
          color: ${theme.colors.placeholder};
          white-space: normal;
        }
      }
    }
  }

  @media screen {
    .screen-table {
      display: block;
    }
  }

  @media print {
    .screen-table {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .relatorio-header {
      .variables-wrapper {
        .select-wrapper {
          flex-grow: 1;
        }
        .input-wrapper {
          flex-grow: 1;
        }
      }
    }

    .flex-footer-relatorios {
      flex-wrap: wrap;
      gap: 2rem;
      .botoes {
        flex-grow: 1;
        .exportar {
          width: 100%;
          &::after {
            content: "Exportar";
          }
        }
        .imprimir {
          width: 100%;
          &::after {
            content: "Imprimir";
          }
        }
      }
    }
  }
`;

export default RelatorioWrapper;
