import React, { useContext } from "react";
import Modal from "../../Modal";
import { ParabensContainer } from "./styles";
import { Button } from "../../../Button";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";

const Parabens: React.FC = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <ParabensContainer theme={theme}>
        <div className="ParabensContainer">
          <div className="tituloParabens">
            Queremos te desejar <br></br>
            <span>PARABÉNS!</span>
          </div>
          <div className="texto">
            Você acabou de dar um grande passo para vender online. Agora com o
            Conecta Venda você está cada vez mais próximo de digitalizar o seu
            negócio.<br></br>
            <br></br>E para você começar da melhor maneira possível, vamos te
            instruir para a criação do seu primeiro catálogo digital, aqui na
            área exclusiva para assinantes, onde você terá acesso a todas as
            funcionalidades da nossa plataforma.
            <br></br>
            <br></br>
            Se tiver qualquer dúvida, entre em contato com nosso suporte,
            estamos a disposição para te ajudar.
            <br></br>
            <br></br>
            Boas vendas!
          </div>
        </div>
      </ParabensContainer>
    </>
  );
};

export default Parabens;
