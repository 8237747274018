import styled, { keyframes } from "styled-components";
import theme from "../../Themes/light";

const opacityDropdown = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
`;

const grow = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const { colors } = theme;

export const FiltrosContainer = styled.div`
  width: 100%;
  font-size: 2.5rem;
  position: relative;

  > .filtrosChildren {
    color: ${(props) => props.theme.colors.brandSecondary};
    background-color: ${(props) => props.theme.colors.white};
    height: 6rem;
    border-radius: 50rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 1rem 4rem;
    svg {
      fill: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  > .fundoDropdown {
    cursor: default;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 209;
    opacity: 0.3;
    background-color: rgb(0 0 0 / 30%);
    width: 100%;
    height: 100%;
    animation: ${opacityDropdown} 0.5s linear;
  }

  > .containerDropdown {
    cursor: default;
    pointer-events: none;
    animation: grow 0.1s linear;

    > .dropdown {
      pointer-events: all;
      background: ${(props) => props.theme.colors.modal};
      box-shadow: rgb(0 0 0 / 25%) 0px 2px 2px 0px;
      padding: 4rem 5rem;
      border-radius: 24px;
      position: absolute;
      top: 120%;
      left: 0px;
      z-index: 210;
      width: 60rem;

      > .aplicarCancelar {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .cancelar {
          color: ${(props) => props.theme.colors.placeholder};
          cursor: pointer;
        }
      }
    }

    @media (max-width: 1024px) {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 209;

      .dropdown {
        max-width: 95%;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
`;
