import React from "react";
import ErrorPopover from "./styles";

interface PopoverFormProps {
  mensagem: string;
  ativo?: boolean;
  position?: string;
}

const PopoverForm: React.FC<PopoverFormProps> = ({
  mensagem,
  ativo,
  position,
}) => {
  const style = {
    top: position ? position : "105%",
  };

  return (
    <ErrorPopover
      className={`errorPopover ${ativo && "popoverAtivo"}`}
      style={style}
    >
      <div
        className="mensagem"
        dangerouslySetInnerHTML={{ __html: mensagem }}
      ></div>
    </ErrorPopover>
  );
};

export default PopoverForm;
