import { api } from "./useApi";

export const MelhorEnvioApi = () => ({
    gerarEtiqueta: async (pedido: any, nfe: string, id_agencia: string | number) => {
        const response = await api.post("melhor_envio/gerar_etiqueta", { pedido: pedido, nfe: nfe, id_agencia });
        return response.data;
    },
    verificarAutenticacao: async () => {
        const response = await api.post("/melhor_envio/verificar_autenticacao");
        return response.data;
    },
    consultarAgencias: async (dados_frete: any) => {
        dados_frete = JSON.parse(dados_frete);
        const response = await api.post("/melhor_envio/agencias", { dados_frete: dados_frete });
        return response.data;
    }
});