import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const InputFileButtonContainer = styled.div<{theme: ThemeType}>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export {
    InputFileButtonContainer
};

