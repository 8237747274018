import React, { memo } from "react";
import PedidoUnico from "../../types/PedidoUnico";
import Pagamento from "../../types/Pagamento";
import { ContainerPrintableSacola } from "./styles";
import {
  obterMiniaturaVideoYouTube,
  phoneMask,
} from "../../services/Functions";
import { cepMask, cnpjMask, cpfMask } from "../../services/Masks";
import moment from "moment";
import { TIPO_MIDIA } from "../../services/Consts";
import Fallback from "../../assets/images/empresas/fallback.jpg";
import { CestaAbandonada } from "../../types/CestaAbandonada";
import TableProdutos from "../TableProdutos/TableProdutos";

interface PrintableSacolaProps {
  sacola: CestaAbandonada | undefined;
}

const PrintableSacola: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PrintableSacolaProps
> = ({ sacola }, ref) => {
  const montarInformacoesCliente = () => {
    if (sacola && sacola.cliente) {
      const { cliente } = sacola;
      const informacoes = [
        cliente.telefone && phoneMask(cliente.telefone),
        cliente.email,
        cliente.data_nascimento &&
          `data de nascimento: ${moment(cliente.data_nascimento).format(
            "DD/MM/YYYY",
          )}`,
        cliente.cpf && `CPF: ${cpfMask(cliente.cpf)}`,
        cliente.cnpj && `CNPJ: ${cnpjMask(cliente.cnpj)}`,
        cliente.inscricao_estadual &&
          `IE: ${cpfMask(cliente.inscricao_estadual)}`,
        `${cliente.endereco || ""} ${cliente.numero || ""}`,
        cliente.complemento,
        cliente.bairro,
        cliente.cidade &&
          cliente.estado &&
          `${cliente.cidade} / ${cliente.estado}`,
        cliente.cep && cepMask(cliente.cep),
      ];
      return informacoes.filter(Boolean).join(", ");
    }
  };

  const montarInformacoesSacola = () => {
    if (sacola && sacola.valor_total) {
      const informacoes = [
        sacola.valor_total &&
          `Valor da sacola: ${Number.parseFloat(
            sacola.valor_total,
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}`,
      ];
      return informacoes.filter(Boolean).join("");
    }
  };

  return (
    <>
      {sacola && (
        <ContainerPrintableSacola ref={ref}>
          <div className="container">
            <div className="flex">
              <div className="titulo">Sacola #{sacola.id_cesta_abandonada}</div>
              <div>
                Data:{" "}
                {moment(sacola.data_modificacao).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
            <div className="flex">
              Catálogo: {sacola.catalogo!.descricao}, Vendedor:{" "}
              {sacola.vendedor}
            </div>
          </div>
          <div className="container-linha">
            <div className="linha"></div>
          </div>
          <div className="container">
            <div className="flex">
              <div>
                <span>{sacola.cliente!.nome} - </span>
                {montarInformacoesCliente()}
              </div>
            </div>
          </div>
          <div className="container-linha">
            <div className="linha"></div>
          </div>
          <div className="container">
            <div className="flex">
              <div>
                <span>
                  Valor total da sacola{" "}
                  {(
                    Number.parseFloat(sacola?.valor_total) -
                    (Number.parseFloat(sacola?.valor_total) || 0)
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>{" "}
                <br />
                {montarInformacoesSacola()} <br />
              </div>
            </div>
          </div>
          <div className="container">
            <TableProdutos
              produtos={sacola.produtos}
              template="impressao"
            />
          </div>
          {sacola.observacao && (
            <>
              <div className="container-linha" />
              <div className="container">
                <div className="flex">
                  <div>
                    <span>Observação do pedido: </span> <br />
                    {sacola.observacao}
                  </div>
                </div>
              </div>
            </>
          )}
        </ContainerPrintableSacola>
      )}
    </>
  );
};

export default memo(React.forwardRef(PrintableSacola));
