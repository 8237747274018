import styled, { DefaultTheme, keyframes } from "styled-components";
import theme from "../../Themes/light";

const pulsateAnimation = keyframes`
  0% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #ddd;
  }
`;

export const CatalogoListagemWrapper = styled.div<{
  theme: DefaultTheme;
  replicado: boolean;
}>`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  font-size: 2.5rem;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

  .catalogo-listagem-wrapper {
    height: 100%;
  }

  .catalogo-listagem-container {
    display: contents;
    cursor: ${({ replicado }) => (replicado ? "default" : "pointer")};
    .card-branco-catalogo {
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.white};
      padding-bottom: 2rem;
      transition: all 0.3s ease-in-out;

      .imagens-grid {
        display: grid;
        width: 100%;
        min-height: 36rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 2px;
        user-select: none;

        .imagem-catalogo {
          width: 100%;
          height: 100%;
          object-fit: cover;

          &:first-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.placeholder};
            border-radius: 0px 0 0px 16px;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &:last-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.placeholder};
            border-radius: 0px 0 16px 0px;
          }
        }

        .gray-loading {
          width: 100%;
          height: 100%;
          aspect-ratio: 1 / 1;
          min-height: 12rem;
          animation: ${pulsateAnimation} 1s linear infinite alternate;

          &:first-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.placeholder};
            border-radius: 0px 0 0px 16px;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &:nth-last-child(2) {
            border-bottom: 2px solid ${({ theme }) => theme.colors.placeholder};
            border-radius: 0px 0 16px 0px;
          }
        }

        .image-placeholder {
          width: 100%;
          height: 100%;
          background-color: ${({ theme }) => theme.colors.elements};

          &:first-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.placeholder};
            border-radius: 0px 0 16px 0px;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &:last-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.placeholder};
            border-radius: 0px 0 16px 0px;
          }
        }
      }

      .estrela-container {
        padding: 1rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
        fill: ${({ theme }) => theme.colors.placeholder};
        background: #ffffff;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        cursor: pointer;
      }

      .icone-svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      .informacoes {
        padding: 0.5rem 4rem;
        color: ${({ theme }) => theme.colors.placeholder};

        .nome-catalogo {
          color: ${({ theme }) => theme.colors.brandSecondary};
          font-weight: bold;
          font-size: 3.5rem;
          text-align: left;
          display: -webkit-box;
          word-break: break-all;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .flex-container {
          display: flex;
          color: ${({ theme }) => theme.colors.placeholder};
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .visualizacoes {
            display: flex;
            align-items: center;
            gap: 1rem;

            .olho {
              margin-top: 1px;
              fill: ${({ theme }) => theme.colors.placeholder};
            }
          }
        }

        .links-container {
          width: 100%;
          height: 5rem;
          display: flex;
          margin-top: 1rem;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          border-radius: 50px;
          overflow: hidden;
          position: relative;

          .botao-link {
            background-color: ${({ theme }) => theme.colors.elements};
            width: 64%;
            display: flex;
            height: 100%;
            color: ${({ theme }) => theme.colors.brandSecondary};
            align-items: center;
            justify-content: center;
            gap: 1rem;
            white-space: normal;
            padding: 0rem 3rem;
            border-radius: 50px;
            position: relative;
            cursor: pointer;

            .icone-link {
              width: 3rem;
              height: 2.5rem;
              fill: ${({ theme }) => theme.colors.brandSecondary};
            }

            .overlay-copiado {
              display: none;
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 16px;
              left: 0;
              top: 0;
              background-color: ${({ theme }) => theme.colors.elements};
              align-items: center;
              justify-content: center;
            }

            .overlay-ativo {
              display: flex;
              pointer-events: none;
              animation: fadeOut 2s ease-in-out;
            }

            @keyframes fadeOut {
              0% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
          }

          .botao-zap {
            height: 100%;
            width: 18%;
            border-radius: 50px;
            background-color: ${({ theme }) => theme.colors.brandPrimary};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icone-svg {
              width: 3rem;
              height: auto;
            }
          }

          .borda-reticencias {
            border-left: 3px solid ${({ theme }) => theme.colors.white};
            height: 100%;
            width: 18%;
            border-radius: 50px;
            position: relative;
            background-color: ${({ theme }) => theme.colors.elements};
            z-index: 10;

            .reticencias {
              width: 100%;
              height: 100%;
              border-radius: 50px;
              background-color: ${({ theme }) => theme.colors.elements};
              color: ${({ theme }) => theme.colors.brandSecondary};
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: 0.5s;
              transform: rotate(0deg);

              .options {
                fill: ${({ theme }) => theme.colors.placeholder};
                width: 3.6rem;
              }
            }

            .rodar-ativo {
              transform: rotate(315deg);
            }
          }

          .icones-compartilhar-container {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border-radius: 50px;
            width: 100%;
            height: calc(100%);
            background-color: ${({ theme }) => theme.colors.elements};
            position: absolute;
            right: 0;
            z-index: 9;
            transform: translateX(100%);
            transition: 0.5s;
            padding-right: 14%;

            .icone-svg {
              width: 3rem;
              height: auto;
              cursor: pointer;
              fill: ${({ theme }) => theme.colors.brandSecondary};
            }

            .popover-compartilhar {
              position: absolute;
              top: 100%;
              z-index: 12;
              background-color: ${({ theme }) => theme.colors.elements};
            }
          }

          .background-ativo {
            transform: translateX(0);
          }
        }
      }

      .expirado-glass {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
      }
    }

    .principal {
      .imagens-grid {
        .imagem-catalogo {
          &:first-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.titleColor};
          }
          &:last-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.titleColor};
          }
        }
        .gray-loading {
          &:first-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.titleColor};
          }
          &:nth-last-child(2) {
            border-bottom: 2px solid ${({ theme }) => theme.colors.titleColor};
          }
        }
        .image-placeholder {
          &:first-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.titleColor};
          }
          &:last-child {
            border-bottom: 2px solid ${({ theme }) => theme.colors.titleColor};
          }
        }
      }
      .estrela-container {
        fill: ${({ theme }) => theme.colors.titleColor};
      }
    }

    .expirado {
      .informacoes {
        .nome-catalogo {
          position: relative;
          z-index: 10;
        }
        .links-container {
          .botao-link {
            position: relative;
            z-index: 10;
            width: 100%;
          }
        }
      }
    }
  }
`;
