import { createContext } from "react";
import { Plano } from "../../types/Plano";

export type IntegracaoContextType = {
    chaveIntegracao: string;
    setChaveIntegracao: (chave: string) => void;
    activeChaveIntegracao: boolean;
    setActiveChaveIntegracao: (active: boolean) => void;
    onChangeIntegracao: (newValue: boolean) => void;
    onChangeIntegracaoTiny: (newValue: boolean) => void;
    onChangeIntegracaoBling: (newValue: boolean) => void;
    onChangeIntegracaoBlingV2: (newValue: boolean) => void;
};

export const IntegracaoContext = createContext<IntegracaoContextType>(null!);