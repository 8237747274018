import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProdutosPlanilhaContext } from '../../../contexts/ProdutosPlanilha/ProdutosPlanilhaContext';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { Campo, CampoValue, Dado } from '../../../types/ImportacaoPlanilha';
import { Button } from '../../Button';
import { FooterPaginas } from '../../FooterPaginas/FooterPaginas';
import Modal from '../../Modal/Modal';
import Paginacao from '../../Paginacao';
import { Select } from '../../Select';
import { Toggle } from '../../Toggle/Toggle';
import AjustarCampo from '../AjustarCampo/AjustarCampo';
import { TabelaEdicaoContainer } from './styles';

interface TabelaEdicaoProps {
}

type FiltrosTypes = 'todas' | 'validas' | 'invalidas';

const optionsFiltro: {name: string, value: FiltrosTypes}[] = [
    {name: 'Todos as linhas', value: 'todas'},
    {name: 'Linhas válidas', value: 'validas'},
    {name: 'Linhas inválidas', value: 'invalidas'}
]

const TabelaEdicao: React.FC<TabelaEdicaoProps> = ({}) => {

    const {theme} = useTheme();
    const {colunas, mappedCampos, dados, camposSelect, setColunas, setEtapa, setDados, dadosValidados, qtdCamposObrigatoriosFaltantes} = useContext(ProdutosPlanilhaContext)
    const [comCabecalho, setComCabecalho] = useState(true);
    const [modalAjustarCampo, setModalAjustarCampo] = useState(false);
    const campoEmEdicao = useRef<Campo | undefined>(undefined);
    const dadoEmEdicao = useRef<Dado | undefined>(undefined);
    const [filtro, setFiltro] = useState<FiltrosTypes>('todas');
    const navigate = useNavigate();

    //paginação
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 20;
    

    const onChangeComCabecalho = (newValue: boolean) => {
        setComCabecalho(newValue);
        //se desativar o cabeçalho, todas as colunas devem ser resetadas e a primeira linha deve ser considerada como dados
        if(!newValue) {
            let newColunas = new Array(colunas.length).fill('nenhum');
            let newDados = JSON.parse(JSON.stringify(dados));
            newDados.unshift(colunas);

            setColunas(newColunas);
            setDados(newDados);
        } else {
            //se ativar o cabeçalho, a primeira linha deve ser considerada como colunas e os dados devem ser os mesmos, exceto a primeira linha
            let newColunas = dados[0];
            let newDados = JSON.parse(JSON.stringify(dados));
            newDados.shift();

            setColunas(newColunas);
            setDados(newDados);
        }
    }

    const onChangeColuna = (index: number, newValue: string) => {
        //se ja tem uma coluna com esse valor, troca a coluna antiga por nenhuma
        let newColunas = [...colunas];
        if(newValue !== 'nenhum' && colunas.includes(newValue)) {
            newColunas = colunas.map(coluna => coluna === newValue ? 'nenhum' : coluna);
        }
        newColunas[index] = newValue;
        setColunas(newColunas);
    }

    const handlePageChange = (page: number) => {
        if(page !== currentPage){
            setCurrentPage(page);
        }
    };
    
    const onChangeCampo = (newValue: string) => {
        setModalAjustarCampo(false);
        let newDados = JSON.parse(JSON.stringify(dados));
        if(dadoEmEdicao.current) newDados[dadoEmEdicao.current.indexCol][dadoEmEdicao.current.indexRow] = newValue;
        setDados(newDados);
    }

    useEffect(() => {
        if(mappedCampos.filter(campo => campo !== undefined).length === 0){
            onChangeComCabecalho(false);
        }
    }, []);
    
    const onClickAjusteCampo = (coluna: Dado, campo: Campo | undefined) => {
        campoEmEdicao.current = campo;
        dadoEmEdicao.current = coluna;
        setModalAjustarCampo(true);
    }

    const dadosFiltrados = dadosValidados.filter(linha => {
        let temCampoInvalido = linha.filter(coluna => !coluna.valid).length > 0;
        if(filtro === 'validas' && temCampoInvalido) return false;
        if(filtro === 'invalidas' && !temCampoInvalido) return false;
        return true;
    });
    const totalItems = dadosFiltrados.length;
    const dadosPaginados = dadosFiltrados.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    

    return (
        <>
            <Modal
                isOpen={modalAjustarCampo}
                setModal={setModalAjustarCampo}
                width={'100rem'}
                maxWidth='95vw'
            >
                <AjustarCampo 
                    campo={campoEmEdicao.current}
                    dado={dadoEmEdicao.current}
                    onChange={onChangeCampo}
                />
            </Modal>
            <TabelaEdicaoContainer theme={theme}>
                <div className="filtros">
                    <div className="select-filtro-container">
                        <Select 
                            label='Filtrar'
                            placeholder='Filtras dados'
                            value={filtro}
                            options={optionsFiltro}
                            onChange={(newValue) => setFiltro(newValue as FiltrosTypes)}
                        />
                    </div>
                    <div className="filtros-container">
                        <label className="toggle-cabecalho">
                            Arquivo com cabeçalho
                            <Toggle
                                value={comCabecalho}
                                onChange={onChangeComCabecalho}
                            />
                        </label>
                    </div>
                </div>
                {dadosPaginados.length === 0 ? (
                    <>
                        <div className="mensagem">
                            <div className="texto-titulo">
                                <div>Nenhum resultado encontrado para sua busca.</div>
                            </div>
                            <div className="texto-subtitulo">
                                <div>Tente novamente com uma busca diferente!</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <table className='scrollBonitoBottom'>
                        <thead>
                            <tr>       
                                {mappedCampos.map((mappedCampo, index) => {
                                    //se a coluna atual não está em uso, mostrar nenhum
                                    //se a coluna atual nao esta em uso mas as colunas tem valor, deixar o valor invalido para mostrar o placeholder
                                    //se a coluna atual está em uso, mostrar o value normal
                                    const value = !mappedCampo && !colunas[index] ? 'nenhum' :
                                                    !mappedCampo && colunas[index] ? colunas[index] :
                                                        mappedCampo?.value
                                    
                                    return (
                                        <th key={index}>
                                            <Select
                                                className={`select-coluna ${qtdCamposObrigatoriosFaltantes > 0 && value === 'nenhum' ? 'invalid' : ''}`}
                                                //quando nao encontrar campo, vai cair no placeholder, que vai ficar em vermelho como se fosse erro
                                                placeholder={`${colunas[index]}`}
                                                //se a coluna atual não está em uso, mostrar nenhum
                                                value={value} 
                                                options={
                                                    camposSelect.filter(campo => {
                                                        //mostrar apenas os campos que não estão sendo usados sendo usados
                                                        //ou o nenhum
                                                        //ou ele mesmo
                                                        return (
                                                            mappedCampo?.value === campo.value ||
                                                            campo.value === 'nenhum' ||
                                                            !mappedCampos.filter(mCampo => mCampo).map(mCampo => mCampo?.value).includes(campo.value as CampoValue)
                                                        )
                                                    })
                                                }
                                                onChange={(newValue) => onChangeColuna(index, newValue)}>
                                            </Select>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dadosPaginados.map((linha, indexLinha) => {
                                return (
                                    <tr key={indexLinha}>
                                        {linha.map((coluna: Dado, indexColuna) => {
                                            return (
                                                <td 
                                                    key={indexColuna} 
                                                    className={`${coluna.disabled ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if(coluna.disabled) return;
                                                        onClickAjusteCampo(coluna, mappedCampos[indexColuna])
                                                    }}
                                                >
                                                    <div
                                                        className={`table-data ${!coluna.valid ? 'invalid' : ''}`}>
                                                        {coluna.value ? (
                                                            <>
                                                                {mappedCampos[indexColuna]?.element ? (
                                                                    mappedCampos[indexColuna]?.element(coluna)
                                                                ) : (
                                                                    coluna.value
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div className="placeholder">
                                                                {mappedCampos[indexColuna] ? mappedCampos[indexColuna].placeholder : '-'}
                                                            </div>
                                                        )}
                                                    
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
                <FooterPaginas>
                    <div className="footer">
                        <div className="paginacao">
                            <Paginacao
                                currentPage={currentPage}
                                totalPages={totalItems > 0 ? Math.ceil(totalItems / itemsPerPage) : 1}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        <div className="ajustar">
                            <Button
                                title='Ajustar produtos'
                                padding='5rem'
                                width='100%'
                                onClick={() => navigate('/painel/produtos/importar-produtos')}
                            />
                        </div>
                    </div>
                </FooterPaginas>
            </TabelaEdicaoContainer >
        </>
    )
}

export default TabelaEdicao;