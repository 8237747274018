import React, { CSSProperties } from "react";
import SkeletonPedidoCestaContainer from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonPedidoCestaProps {}

interface CSSPropertiesWithMultiValues extends CSSProperties {
  "--i"?: string;
}

export const SkeletonPedidoCesta: React.FC<SkeletonPedidoCestaProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <SkeletonPedidoCestaContainer
        className="SkeletonPedidoCesta"
        theme={theme}
      >
        <div className="flex-container-skeleton">
          <div className="text-skeleton"></div>
          <div className="text-skeleton"></div>
        </div>
        <div className="linha"></div>
        <div className="flex-container-skeleton">
          <div className="text-skeleton"></div>
          <div className="text-skeleton"></div>
        </div>

        <div className="container-skeleton"></div>

        <div className="container-skeleton"></div>

        <div className="container-skeleton"></div>

        <div className="container-skeleton"></div>
      </SkeletonPedidoCestaContainer>
    </>
  );
};
