import moment from "moment";
import React, { memo } from "react";
import {
  phoneMask
} from "../../services/Functions";
import { cepMask, cnpjMask, cpfMask } from "../../services/Masks";
import Pagamento from "../../types/Pagamento";
import PedidoUnico from "../../types/PedidoUnico";
import TableProdutos from "../TableProdutos/TableProdutos";
import { ContainerPrintablePedido } from "./styles";

interface PrintablePedidoProps {
  pedido: PedidoUnico | undefined;
  informacoesPagamento: Pagamento | undefined;
}

const PrintablePedido: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PrintablePedidoProps
> = ({ pedido, informacoesPagamento }, ref) => {
  const meiosDePagamento = {
    pix: "Pix",
    cartao: "Cartão",
    a_combinar: "A combinar",
  };

  const intermediadores = {
    pagseguro: "PagSeguro",
    pagarme: "Pagarme",
  };

  const obterInformacoesContato = (cliente) => {
    return [
      cliente.telefone && phoneMask(cliente.telefone),
      cliente.email,
      cliente.data_nascimento &&
        `data de nascimento: ${moment(cliente.data_nascimento).format("DD/MM/YYYY")}`,
    ].filter(Boolean).join(", ");
  };

  const obterInformacoesFiscais = (cliente) => {
    return [
      cliente.cpf && `CPF: ${cpfMask(cliente.cpf)}`,
      cliente.cnpj && `CNPJ: ${cnpjMask(cliente.cnpj)}`,
      cliente.inscricao_estadual && `IE: ${cpfMask(cliente.inscricao_estadual)}`,
    ].filter(Boolean).join(", ");
  };

  const obterEnderecoCliente = (cliente) => {
    return [
      `${cliente.endereco || ""} ${cliente.numero || ""}`.trim(),
      cliente.complemento,
      cliente.bairro,
      cliente.cidade && cliente.estado && `${cliente.cidade} / ${cliente.estado}`,
      cliente.cep && cepMask(cliente.cep),
    ].filter(Boolean).join(", ");
  };

  const montarInformacoesCliente = () => {
    if (pedido && pedido.cliente) {
      const { cliente } = pedido;
      const contato = obterInformacoesContato(cliente);
      const fiscais = obterInformacoesFiscais(cliente);
      const endereco = obterEnderecoCliente(cliente);

      return (
        <>
          {contato && <span>{contato}<br /></span>}
          {fiscais && <span>{fiscais}<br /></span>}
          {endereco && <span>{endereco}<br /></span>}
        </>
      );
    }
    return null;
  };

  const montarInformacoesPedido = () => {
    if (pedido && pedido.valor_total && informacoesPagamento) {
      const informacoes = [
        pedido.valor_total && 
        `Valor dos produtos: ${pedido.moeda} ${(
            Number.parseFloat(informacoesPagamento?.valor_cobranca)/100
          )
            .toFixed(2)
            .replace(".", ",")}`,
        Number.parseFloat(informacoesPagamento.desconto) > 0 &&
          ` - ${pedido?.moeda} ${(
            (Number.parseFloat(informacoesPagamento?.desconto_cupom || "0") / 100) * Number.parseFloat(pedido?.valor_total) || 0
          )
            .toFixed(2)
            .replace(".", ",")} de desconto do pix`,
            Number.parseFloat(informacoesPagamento.desconto_cupom) > 0 &&
            ` - ${pedido?.moeda} ${(
              (Number.parseFloat(informacoesPagamento?.desconto_cupom || "0") / 100) * Number.parseFloat(pedido?.valor_total) || 0
            )
              .toFixed(2)
              .replace(".", ",")} de desconto do cupom`,
        ,pedido.frete?.valor_frete &&
          Number.parseFloat(pedido.frete?.valor_frete) > 0 &&
          ` + Valor do frete: ${Number.parseFloat(
            pedido.frete.valor_frete
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}`,
      ];
      return informacoes.filter(Boolean).join("");
    }
  };

  const switchFormasDePagamento = () => {
    if (!informacoesPagamento) {
      return null;
    }

    const { tipo, intermediador, cartao, parcelas, id_transacao, pix_code } = informacoesPagamento;
  
    const informacoes = [
      intermediadores[intermediador],
      meiosDePagamento[tipo] && meiosDePagamento[tipo],
      cartao &&
        `${cartao[0].toUpperCase() + cartao.slice(1).toLowerCase()} x${
          parcelas || ""
        }`,
      id_transacao && id_transacao !== "0" ? id_transacao : "",
      pix_code &&
        `Chave pix: ${
          pix_code.length > 20 ? pix_code.slice(0, 20) + "..." : pix_code
        }`,
    ];
  
    return informacoes.filter(Boolean).join(", ");
  };
  
  const formatarTipoFrete = (frete: string | undefined) => {
    if (!frete) return "Não informado";
  
    const fretesLabels = {
      entrega_local: "Entrega local",
      frete_gratis: "Frete grátis",
      retirada_no_local: "Retirada no local",
      a_combinar: "A combinar",
    };
  
    frete.startsWith("especial_")
      ? (frete = `Especial - ${frete.replace("especial_", "")}`)
      : (frete = fretesLabels[frete] || frete.toUpperCase());
  
    return frete;
  };
  
  return (
    <>
      {pedido && informacoesPagamento && (
        <ContainerPrintablePedido ref={ref}>
          <div className="container">
            <div className="flex">
              <div className="titulo">Pedido #{pedido.id_pedido}</div>
              <div>
                Data: {moment(pedido.data).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
            <div className="flex">
              Catálogo: {pedido.catalogo!.descricao}, Vendedor: {pedido.nome}
            </div>
          </div>
          <div className="container-linha">
            <div className="linha"></div>
          </div>
          <div className="container">
            <div className="flex">
              <div>
                <span className="boldInicial">
                  {pedido.cliente!.nome
                    ? pedido.cliente!.nome
                    : pedido.cliente!.razao_social}{" "}
                  -{" "}
                </span>
                {montarInformacoesCliente()}
              </div>
            </div>
          </div>
          <div className="container-linha">
            <div className="linha"></div>
          </div>
          <div className="container">
            <div className="flex">
            <div className="single-line">
              <span className="boldInicial">Valor total do pedido</span>{" "}
              <span>
                {pedido.moeda} {(Number.parseFloat(informacoesPagamento?.valor_cobranca) / 100)
                  .toFixed(2)
                  .replace(".", ",")}
              </span>
              <br />
              <span>{montarInformacoesPedido()}</span>
              <br />
              <span>{switchFormasDePagamento()}</span>
              ,{" "}
              <span>
                {formatarTipoFrete(pedido.frete?.tipo_frete)}
                {pedido.frete?.prazo_frete && Number(pedido.frete?.prazo_frete) > 0
                  ? ` - ${pedido.frete?.prazo_frete} dia(s)`
                  : ""}
              </span>
            </div>
            </div>
          </div>
          <div className="container-linha">
            <div className="linha"></div>
          </div>
          <br />
          <div className="container">
            <TableProdutos produtos={pedido.produtos} template="impressao" sortVariacoes={true} />
          </div>
          {pedido.observacao_interna && (
            <>
              <div className="container-linha" />
              <div className="container">
                <div className="flex">
                  <div>
                    <span>Observação interna: </span> <br />
                    {pedido.observacao_interna}
                  </div>
                </div>
              </div>
            </>
          )}
          {pedido.cliente.observacao && (
            <>
              <div className="container-linha" />
              <div className="container">
                <div className="flex">
                  <div>
                    <span>Observação do pedido: </span> <br />
                    {pedido.cliente.observacao}
                  </div>
                </div>
              </div>
            </>
          )}
        </ContainerPrintablePedido>
      )}
    </>
  );  
};

export default memo(React.forwardRef(PrintablePedido));
