import "./styles.ts";
import { ReactComponent as GaleryIcon } from "../../../assets/newImages/icons/galery.svg";
import { ReactComponent as PlayIcon } from "../../../assets/newImages/icons/play.svg";
import { ReactComponent as Plus } from "../../../assets/newImages/icons/plus.svg";
import { ReactComponent as Loading } from "../../../assets/newImages/icons/loading.svg";
import { ImageUploadWithCrop } from "../ImageUploadWithCrop";
import { useEffect, useState } from "react";
import { LogoInputBannersWrapper, ModalCropContainer } from "./styles.ts";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import Modal from "../../Modal/Modal.tsx";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import LoadingSRC from "../../../assets/jsons/Loading.json";

interface ImageUploadBannersProps {
  value: string;
  onChange: (base64: string, imagem: string, size: number) => void;
  minWidth?: number;
  minHeight?: number;
  resize?: { width: number; height: number };
  aspectRatio: number;
}

export const ImageUploadBanners: React.FC<ImageUploadBannersProps> = ({
  onChange,
  value,
  aspectRatio,
  minWidth,
  minHeight,
  resize,
}) => {
  const { theme } = useTheme();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onDragIn = (e) => {
    setIsDragging(true);
  };

  const onDragOut = (e) => {
    setIsDragging(false);
  };

  const onDragDrop = (e) => {
    setIsDragging(false);
  };

  useEffect(() => {}, [value]);

  const modal = (
    cropping: JSX.Element,
    cancelar: JSX.Element,
    salvar: JSX.Element,
  ) => {
    return (
      <Modal
        isOpen={true}
        setModal={() => {}}
        width={"auto"}
        borderRadius="20px"
      >
        <ModalCropContainer theme={theme}>
          <div className="titulo">Editar imagem</div>
          {cropping}
          <div className="botoes">
            {cancelar}
            {salvar}
          </div>
        </ModalCropContainer>
      </Modal>
    );
  };

  return (
    <>
      <LogoInputBannersWrapper theme={theme}>
        <ImageUploadWithCrop
          onLoading={(loading) => setLoading(loading)}
          className={`logo-input-container-banners`}
          acceptFormats={".png, .jpeg, .jpg, .webp"}
          value={value}
          aspectRatio={aspectRatio}
          onChange={onChange}
          onDragDrop={onDragDrop}
          resize={resize}
          errorMargin={5}
          minWidth={minWidth}
          minHeight={minHeight}
          onOpenCrop={modal}
          style={{ aspectRatio: aspectRatio, width: "100%" }}
        >
          <div
            className={`wrapper ${!isDragging && "dragging"}`}
            onDragEnter={onDragIn}
            onDragLeave={onDragOut}
            onDragOver={onDragIn}
          >
            {loading ? (
              <>
                <Player
                  autoplay
                  loop
                  src={LoadingSRC}
                  style={{ height: "8rem", width: "8rem" }}
                >
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player>
              </>
            ) : (
              <>
                <div className="icones">
                  <GaleryIcon
                    className={`galery-icon ${
                      isDragging && "galery-ativo"
                    }`}
                  />
                  <PlayIcon
                    className={`play-icon ${isDragging && "play-ativo"}`}
                  />
                </div>
                <div className="texto-wrapper">
                  <div
                    className="texto"
                    style={{ opacity: !isDragging ? "1" : "0" }}
                  >
                    Clique aqui ou arraste um arquivo para carregar
                  </div>
                  <div
                    className="texto"
                    style={{ opacity: isDragging ? "1" : "0" }}
                  >
                    Solte o arquivo aqui
                  </div>
                </div>
              </>
            )}
          </div>
        </ImageUploadWithCrop>
      </LogoInputBannersWrapper>
    </>
  );
};
