import { useEffect, useRef, useState } from "react";
import Fallback from "../../assets/images/empresas/fallback.jpg";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ImagemCarregada, LoadingPadrao } from "./styles";

interface ImgWithLoadingProps {
  src: string;
  alt: string;
  fullSize?: boolean;
  loadingClass?: string;
  imageClass?: string;
  onLoad?: () => void;
  onClick?: () => void;
}

const ImgWithLoading: React.FC<ImgWithLoadingProps> = ({
  src,
  alt,
  fullSize,
  loadingClass,
  onLoad,
  onClick,
  imageClass,
}) => {
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(src);
  const placeholderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let newSrc = src;

    if(newSrc){
      const sizes = [900,680,413,80]
      let closest = sizes[0];

      if(!fullSize) {
        let width = placeholderRef.current?.offsetWidth || 400;
  
        closest = sizes.reduce((prev, curr) => {
            return (curr >= width && curr < prev) ? curr : prev;
        }, sizes[0]);
      }

      newSrc = newSrc.replace("{size}", closest.toString());
    } else {
      newSrc = Fallback;
    }

    setImgSrc(newSrc);
  }, [src]);

  return (
    <>
      {isLoading && (
        <LoadingPadrao
          ref={placeholderRef}
          className={`${loadingClass ? loadingClass : ''}`}
        ></LoadingPadrao>
      )}
      {imgSrc && (
        <ImagemCarregada
          className={`${imageClass ? imageClass : ''}`}
          src={imgSrc}
          alt={alt}
          theme={theme}
          onLoad={() => {
            setIsLoading(false)
            if(onLoad) onLoad()
          }}
          onError={() => {
            setIsLoading(false);
            setImgSrc(Fallback);
          }}
          style={{ display: isLoading ? "none" : "block" }}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default ImgWithLoading;
