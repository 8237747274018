import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/newImages/icons/angle-down.svg";
import { ScrollDirectionContext } from "../../contexts/ScrollDirection/ScrollDirectionContext";
import { ContainerFooterPaginas } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface FooterPaginasProps {
  className?: string;
  children: React.ReactNode;
  animation?: boolean;
}

export const FooterPaginas: React.FC<FooterPaginasProps> = ({
  children,
  animation = false,
  className,
}) => {
  const { theme } = useTheme();

  const scrollDir = useContext(ScrollDirectionContext).direction;
  const scrollPosition = useContext(ScrollDirectionContext).position;

  const [active, setActive] = useState(true);

  const handleLineClick = (e) => {
    e.preventDefault();
    setActive(!active);
  };

  useEffect(() => {
    if (scrollPosition === "bottom") {
      setActive(true);
    }
  }, [scrollPosition]);

  return (
    <ContainerFooterPaginas theme={theme} className={className}>
      <div
        className={`container-footer-paginas 
            ${scrollDir !== "up" && "container-footer-paginas-ativo"} 
            ${animation && "container-footer-paginas-animation"} 
            ${!active && "container-footer-paginas-escondido"} 
            ${
              !active && scrollDir !== "up"
                ? "container-footer-paginas-escondido-ativo"
                : ""
            }`}
      >
        <div className="linha-footer" onClick={handleLineClick}>
          <span className={`arrow ${active ? "active" : ""}`}>
            <span></span>
            <span></span>
          </span>
        </div>
        <div className="children-footer">{children}</div>
      </div>
    </ContainerFooterPaginas>
  );
};
