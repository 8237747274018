import styled, { css } from "styled-components";
import theme from "../../../Themes/light";

const { colors } = theme;

const commonStyles = css`
  display: contents;
  .containerFreteEspecialListagem {
    width: 100rem;
    max-width: 90vw;
    .fretesFlexContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .fretesContainer {
        display: inline-block;
        align-items: center;
        padding: 4rem 4rem;
        max-width: 100%;

        &::after,
        &::before {
          content: "";
          position: absolute;
          width: 40rem;
          height: 2px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          background-color: ${(props) => props.theme.colors.elements};
          border-radius: 50px;
        }

        &::after {
          bottom: 0;
        }

        &::before {
          top: 0;
        }

        .freteContainer {
          width: 100%;
          padding: 1rem 0;
          font-size: 2.5rem;
          gap: 1rem;
          align-items: center;
          justify-content: space-between;
          display: flex;

          .editarContainer {
            display: inline-flex;
            align-items: center;
            gap: 1rem;

            .editar {
              color: ${(props) => props.theme.colors.brandPrimary};
              cursor: pointer;
            }
          }

          .disabled {
            color: ${(props) => props.theme.colors.elements} !important;
            pointer-events: none;
          }

          .descricao {
            color: ${(props) => props.theme.colors.brandSecondary};
            max-width: 80%;
            text-align: left;
            min-width: 10rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .novo {
      margin-top: 4rem;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandPrimary};
      cursor: pointer;
    }
  }
`;

export const ContainerFreteEspecialListagem = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      .containerFreteEspecialListagem {
        width: 100%;
        .fretesFlexContainer {
          .fretesContainer {
            width: 100%;
            padding: 4rem 8rem;
            &::after,
            &::before {
              display: none;
            }
            .freteContainer {
            }
          }
        }
        .novo {
          margin-top: 0;
          text-align: left;
        }
      }
    `}
`;

// Add other styled components for the nested elements if necessary
