import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { Input } from "../../Input";
import { Select } from "../../Select";
import { ContainerIntegracaoTiny } from "./styles";
import { produtosApi } from "../../../hooks/produtosApi";
import Dropdown from "../../Dropdowns/Dropdown";
import Swal from "sweetalert2";

const IntegracaoTiny: React.FC = ({}) => {
  const produtosApiHook = produtosApi();

  const { theme } = useTheme();

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const [listasDePreco, setListasDePreco] = useState<any[]>([]);

  useEffect(() => {
    produtosApiHook
      .getTabelasDePreco()
      .then((response) => {
        setListasDePreco(response);
        if (!configuracoes.dados_tiny_v2.tabela_id) {
          setConfiguracoes({
            ...configuracoes,
            dados_tiny_v2: {
              ...configuracoes.dados_tiny_v2,
              tabela_id: response[0].id,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const callBackUrl =
    "https://conectavenda.com.br/postback.php?origem=tiny&chave=";

  return (
    <>
      <ContainerIntegracaoTiny theme={theme}>
        <div className="config">
          <Input
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  chave: newValue,
                },
              });
            }}
            type="text"
            value={configuracoes.dados_tiny_v2.chave}
            placeholder="Chave de integração"
            maxLength={64}
            showMaxLength={false}
            label="Chave de API"
            required={true}
            ignoreValidations={!configuracoes.dados_tiny_v2.ativo}
          />
        </div>
        <Dropdown active={configuracoes.dados_tiny_v2.chave ? true : false}>
          <div className="urlContainer">
            <span>URL de callback:</span>
            <div
              className="url"
              onClick={() => {
                navigator.clipboard
                  .writeText(callBackUrl + configuracoes.dados_tiny_v2.chave)
                  .then(
                    (res) => {
                      Swal.fire({
                        title: "URL copiada!",
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false,
                      });
                    },
                    (err) => {
                      Swal.fire({
                        title: "Erro ao copiar URL!",
                        text:
                          "Mas você pode copiar manualmente: " +
                          callBackUrl +
                          configuracoes.dados_tiny_v2.chave,
                        icon: "error",
                      });
                    }
                  );
              }}
            >
              <span>{callBackUrl}</span>
              <span>{configuracoes.dados_tiny_v2.chave}</span>
            </div>
          </div>
        </Dropdown>
        <label className="config">
          <ToggleConfigurar
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  upload_fotos_conecta: newValue,
                },
              });
            }}
            value={configuracoes.dados_tiny_v2.upload_fotos_conecta}
          />
          <div className="title">Upload de fotos pelo Conecta Venda</div>
        </label>

        <label className="config">
          <ToggleConfigurar
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  controle_estoque: newValue,
                },
              });
            }}
            value={configuracoes.dados_tiny_v2.controle_estoque}
          />
          <div className="title">Controle de estoque</div>
        </label>

        <label className="config">
          <ToggleConfigurar
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  nome_descricao: newValue,
                },
              });
            }}
            value={configuracoes.dados_tiny_v2.nome_descricao}
          />
          <div className="title">Utilizar nome do produto como descrição</div>
        </label>

        <div className="config">
          <Select
            placeholder="Selecione uma tabela de preço"
            label="Tabela de preço"
            sizeLabel="medium"
            required={configuracoes.dados_tiny_v2.ativo}
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  tabela_id: newValue,
                },
              });
            }}
            value={configuracoes.dados_tiny_v2.tabela_id}
            options={
              listasDePreco
                ? listasDePreco.map((lista) => {
                    return {
                      name: lista.descricao,
                      value: lista.id,
                    };
                  })
                : [
                    {
                      name: "Padrão",
                      value: 0,
                    },
                  ]
            }
          />
        </div>

        <div className="configs">
          <Input
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  identificador_integrador: newValue,
                },
              });
            }}
            type="text"
            value={configuracoes.dados_tiny_v2.identificador_integrador}
            placeholder="Identificador do integrador"
            showMaxLength={false}
            maxLength={64}
            label="Identificador do integrador"
            required
            ignoreValidations={!configuracoes.dados_tiny_v2.ativo}
          />
          <Input
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                dados_tiny_v2: {
                  ...configuracoes.dados_tiny_v2,
                  multiplicador: newValue,
                },
              });
            }}
            type="number"
            required
            step={"any"}
            value={configuracoes.dados_tiny_v2.multiplicador}
            placeholder="Multiplicador"
            ignoreValidations={!configuracoes.dados_tiny_v2.ativo}
            min={0.01}
            max={100}
            label="Multiplicador"
          />
        </div>
        <div className="obs">
          <br />
          <span>Observações:</span> <br />
          - Todo o processo de importação dos produtos, preços e estoques devem
          ser realizados pela plataforma da Tiny. <br />- Ao excluir um produto
          no Tiny, será necessário excluir manualmente no Conecta.
        </div>
      </ContainerIntegracaoTiny>
    </>
  );
};
export default IntegracaoTiny;
