import styled, { keyframes } from "styled-components";
import theme from "../../Themes/light";

const showAnimation = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

const ErrorPopover = styled.div`
  display: none;
  min-width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  position: absolute;
  font-size: 2.5rem;
  color: ${theme.colors.brandSecondary};
  transform: scale(1);
  z-index: 2;

  .mensagem {
    background-color: #eaeaea;
    padding: 10px;
    border-radius: 20px;
  }

  &.popoverAtivo {
    display: inline-flex;
    justify-content: center;
    top: 105%;
    animation: ${showAnimation} 0.7s cubic-bezier(0.08, 1.07, 0.49, 1);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
  }

  &:after {
    border-width: 0 9px 9px 9px;
    border-color: transparent transparent ${theme.colors.elements} transparent;
    bottom: 100%;
  }
`;

export default ErrorPopover;
