import { useEffect, useState } from "react";
import { EmpresaApi } from "../../hooks/empresaApi";
import { Empresa } from "../../types/Empresa";
import { EmpresaContext } from "./EmpresaContext";

export const EmpresaProvider = ({ children }: { children: JSX.Element }) => {
  const api = EmpresaApi();

  const [empresa, setEmpresa] = useState<Empresa | null>(null);
  const [erp_empresa, setErp_empresa] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const init = async () => {
      try {
        const [res, erp] = await Promise.all([
          api.get_empresa_usuario(),
          set_erp_empresa()
        ]);
  
        setErp_empresa(erp.erp || "");
        setEmpresa(res);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    init();
  }, []);

  const verificaVersaoCatalogo = async () => {
    // const res = await api.verificaVersaoCatalogo();
    // if (res == 1) {
    //     Swal.fire({
    //         title: 'Seu catálogo do Conecta Venda está na versão antiga 😕',
    //         text: 'Estamos programando um migração automática, no dia 3 de outubro, para a nova versão do Conecta Venda, com muito mais funcionalidades e recursos.',
    //         customClass: {
    //             popup: 'modalPersonalizado'
    //         },
    //         showCancelButton: true,
    //         confirmButtonText: "Quero migrar agora",
    //         cancelButtonText: 'Quero fazer a migração automática, no dia 03/10',
    //         }).then(swalRes => {
    //         if (swalRes){
    //             window.open(`https://api.whatsapp.com/send?phone=555496722005&text=Ol%C3%A1%2C%20quero%20fazer%20a%20migra%C3%A7%C3%A3o%20para%20a%20nova%20vers%C3%A3o%20do+ConectaVenda.+Meu+usu%C3%A1rio+%C3%A9:+${usuario}+da+empresa:+${empresa}.`);
    //         } else {
    //             return;
    //         }
    //     });
    // }
  };

  const plano_empresa = async () => {
    return await api.plano_empresa();
  };

  const set_erp_empresa = async () => {
    return await api.erp_empresa();
  };

  const get_erp_empresa = async () => {
    return await api.get_erp_empresa();
  };

  return (
    <EmpresaContext.Provider
      value={{
        empresa,
        setEmpresa,
        verificaVersaoCatalogo,
        erp_empresa,
        plano_empresa,
        get_erp_empresa,
        loading,
      }}
    >
      {children}
    </EmpresaContext.Provider>
  );
};
