import React, { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { Titulo, Texto, Planos, Botoes, ConteudoPlano } from "../box_styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface PlanBoxProps {
    planoAtual: string;
    setPlanBox: (newValue: boolean) => void;
}

export const PlanBox: React.FC<PlanBoxProps> = ({ planoAtual, setPlanBox }) => {
    const { theme } = useTheme();

    const handleChange = (): void => {
        window.open(
            `
        https://api.whatsapp.com/send?phone=555434434563&text=Olá,%20gostaria%20de%20mudar%20meu%20plano%20para%20${plano_selecionado_nome}
        `,
            "_blank",
        );
    };

    const [planos, setPlanos] = useState<any>([
        {
            ativo: true,
            nome: "Mensal",
            selecionado: false,
            html: (
                <div className="html">
                    <div className="texto-desconto">
                        <strong>R$ 120,00/mês</strong> <br />
                        Em até 3x 40,00
                    </div>
                    <div className="texto-final">
                        *Desconto válido para o plano mensal,
                        durate o semestre de contratação. <br />
                        *A renovação é automática.
                    </div>
                </div>
            ),
        },
        {
            nome: "Semestral",
            ativo: true,
            selecionado: false,
            html: (
                <div className="html">
                    <div className="descontos">
                        <div className="desconto">14% de desconto</div>
                        <div className="desconto2">Economize R$ 101,00</div>
                    </div>
                    <div className="texto-desconto">
                        Em até 6x sem juros de: <strong>R$ 103,16/mês</strong>{" "}                        
                    </div>
                    <div className="texto-final">
                        *A renovação é automática.
                    </div>
                </div>
            ),
        },
        {
            ativo: true,
            nome: "Anual",
            selecionado: false,
            html: (
                <div className="html">
                    <div className="descontos">
                        <div className="desconto">32% de desconto</div>
                        <div className="desconto2">Economize R$ 461,00</div>
                    </div>
                        <div className="texto-desconto">
                        Em até 12x sem juros de: <strong>R$ 81,58/mês</strong>{" "}
                    </div>
                    <div className="texto-final">
                        *A renovação é automática.
                    </div>
                </div>
            ),
        },
    ]);

    const [plano_selecionado_nome, setPlanoSelecionadoNome] =
        useState<string>("");

    useEffect(() => {
        let planos_filter = planos.filter((plano) => plano.nome != planoAtual);
        planos_filter[0].selecionado = true;
        setPlanoSelecionadoNome(planos_filter[0].nome);
        setPlanos([...planos_filter]);
    }, [planoAtual]);

    return (
        <div className="view-plan">
            <Titulo theme={theme}>Planos</Titulo>
            <Texto theme={theme}>
                Quer migrar entre os planos? Escolha abaixo qual deseja e
                solicite ao nosso atendimento.
            </Texto>
            <Planos theme={theme}>
                {planos.map((plano, index) => (
                    <div
                        key={index}
                        className={
                            plano.selecionado ? "plano selecionado" : "plano"
                        }
                        onClick={() => {
                            planos.map((plano) => {
                                plano.selecionado = false;
                            });
                            plano.selecionado = true;
                            setPlanoSelecionadoNome(plano.nome);

                            setPlanos([...planos]);
                        }}
                    >
                        <div className="titulo">{plano.nome}</div>
                        <div className="linha">
                            <div></div>
                        </div>
                    </div>
                ))}
            </Planos>
            <ConteudoPlano theme={theme}>
                {planos.map((plano, index) => {
                    if (plano.selecionado) {
                        return plano.html;
                    }
                    return null;
                })}
            </ConteudoPlano>
            <Botoes theme={theme}>
                <Button
                    title="Quero esse plano"
                    className="botao_alterar"
                    width="auto"
                    fontSize="2.5rem"
                    onClick={handleChange}
                />
                <div
                    className="cancelar"
                    onClick={() => {
                        setPlanBox(false);
                    }}
                >
                    Cancelar
                </div>
            </Botoes>
        </div>
    );
};
