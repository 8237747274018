import { Fragment, useEffect, useRef, useState } from "react";
import ComponentInfiniteScroll from "../ComponentInfiniteScroll/ComponentInfiniteScroll";
import { InfiniteContainer } from "./styles";

interface InfiniteScrollProps {
  children: (item: any, index: number) => React.ReactNode;
  className?: string;
  array: any[];
  itemsPerPage: number;
  scrollableDiv: React.RefObject<Element>;
  loadingItem?: React.ReactNode;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  className,
  array,
  itemsPerPage,
  scrollableDiv,
  loadingItem,
}) => {
  const [page, setPage] = useState(1);
  const loader = useRef<any>();

  useEffect(() => {
    const handleScroll = () => {
      //add page when in the end os scroll
      if (scrollableDiv.current) {
        const { scrollTop, clientHeight, scrollHeight } = scrollableDiv.current;
        if (scrollTop + clientHeight + 50 >= scrollHeight) {
          setPage((page) => page + 1);
        }
      }
    };

    if (scrollableDiv.current) {
      scrollableDiv.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableDiv.current) {
        scrollableDiv.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollableDiv]);

  function isSafari() {
    const userAgent = navigator.userAgent;
    const isChrome = userAgent.indexOf('Chrome') > -1;
    const isSafari = userAgent.indexOf('Safari') > -1;
  
    // Chrome has both 'Chrome' and 'Safari' in its userAgent string,
    // so we need to check for Safari and the absence of Chrome to confirm it's actually Safari.
    return isSafari && !isChrome;
  }
  

  const endIdx = page * itemsPerPage;

  return (
    <>
      <InfiniteContainer>
        <div className={`${className} infinite-container`}>
          {array.slice(0, endIdx).map((item, index) => (
            <Fragment key={index}>
              <ComponentInfiniteScroll
                className="component-infinite"
                root={scrollableDiv}
                rootMargin="0px 0px"
                unload={!isSafari()}
              >
                {children(item, index)}
              </ComponentInfiniteScroll>
            </Fragment>
          ))}
          <div ref={loader} style={{ height: "20px", margin: "0 auto" }}>
            {array.length > endIdx ? loadingItem : ""}
          </div>
        </div>
      </InfiniteContainer>
    </>
  );
};

export default InfiniteScroll;
