import { UilBell, UilMoon, UilQuestionCircle, UilRocket, UilSignOutAlt, UilSun } from "@iconscout/react-unicons";
import { useContext, useState } from "react";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/newImages/header/logotipo.svg";
import Ajuda from "../../components/Ajuda/Ajuda.tsx";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Modal from "../../components/Modal/Modal.tsx";
import Notificacoes from "../../components/Notificacoes/index.tsx";
import StepsCriacao from "../../components/StepsCriacao/StepsCriacao.tsx";
import FotoUsuario from "../../components/Uteis/FotoUsuario";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { HeaderContext } from "../../contexts/Header/HeaderContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { PERMISSOES } from "../../services/Consts.tsx";
import "./styles.ts";
import {
    MainHeader,
    ModalAjudaContainer,
    ModalCriacaoContainer,
    ModalNotificacoesContainer,
    ModalUsuarioContainer,
} from "./styles.ts";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import AvisoPagamentoAtrasado from "../../components/AvisoPagamentoAtrasado/AvisoPagamentoAtrasado.tsx";


function Header(props) {
    const { theme, toggleDarkTheme } = useTheme();
    const navigate = useNavigate();
    const [modalMenuUsuario, setModalMenuUsuario] = useState(false);
    const [modalAjuda, setModalAjuda] = useState(false);
    const [modalNotificacoes, setModalNotificacoes] = useState(false);
    
    const { modalCriacao, setModalCriacao, temNotificacaoNova, setTemNotificacaoNova } = useContext(HeaderContext);
    const {signout, permissoesUsuario, loading, user} = useContext(AuthContext);
    const {empresa} = useContext(EmpresaContext);


    const iconeToggleDarkMode = () => {
        return (
            <div className="icone" onClick={() => toggleDarkTheme()}>
                {theme.mode === "light" ? (
                    <>
                        <UilMoon className="botao rotate"></UilMoon>
                        <span className="hideDesktop">Modo Escuro</span>
                    </>
                ) : (
                    <>
                        <UilSun className="botao rotate"></UilSun>
                        <span className="hideDesktop">Modo Claro</span>
                    </>
                )}
            </div>
        );
    };

    const iconeSignOut = () => {
        return (
            <div className="icone" onClick={() => signout()}>
                <UilSignOutAlt className="botao"></UilSignOutAlt>
                <span className="hideDesktop">Sair da conta</span>
            </div>
        );
    };

    const iconePerfil = () => {
        return (
            <Link
                to={"/perfil/usuarios"}
                onClick={() => setModalMenuUsuario(false)}
            >
                <FotoUsuario size={4}></FotoUsuario>
                <span>Meu perfil</span>
            </Link>
        );
    };

    const logo = () => {
        return (
            <Link
                className="logoHorizontal hideDesktop"
                to={"/"}
                style={{ textDecoration: "none" }}
            >
                <Logo />
            </Link>
        );
    };

    const botaoGuia = () => {
        return (
            <button
                className="botaoGuia"
                onClick={() => setModalCriacao(!modalCriacao)}
            >
                <UilRocket className="rocket"></UilRocket>
                <div className="texto">Guia de criação</div>
            </button>
        );
    };

    const notificacao = () => {
        return (
            <div className={temNotificacaoNova ? "blink" : ""}>
                <UilBell
                    className={temNotificacaoNova ? "botao ringBell" : "botao"}
                    onClick={() => {
                        setModalNotificacoes(true)
                        setTemNotificacaoNova(false);
                    }}
                ></UilBell>
            </div>
        );
    };

    const duvidas = () => {
        return (
            <UilQuestionCircle
                onClick={() => setModalAjuda(!modalAjuda)}
                className="botao"
            ></UilQuestionCircle>
        );
    };

    const breadCrumbs = () => {
        return <Breadcrumbs></Breadcrumbs>;
    };

    return (
        <>

            {(permissoesUsuario.includes(PERMISSOES.USUARIOS_GERENTE) && empresa?.pagamento_em_atraso) && (
            <AvisoPagamentoAtrasado
                tipoPagamento={empresa?.forma_pagamento}
            />
            )}
            <Modal
                isOpen={modalMenuUsuario}
                setModal={setModalMenuUsuario}
                width={"auto"}
                hideDesktop={true}
                align={"flex-end"}
                justify="flex-start"
                animation="comeFromTop 600ms cubic-bezier(0.4, 0, 0.2, 1)"
                margin="0rem 2rem 0rem 0rem"
                borderRadius="30px"
            >
                <ModalUsuarioContainer theme={theme}>
                    <div className="modal-container">
                        <div className="icones-container">
                            <div className="icone-container">
                                <div className="icone">{iconePerfil()}</div>
                            </div>
                            <div className="icone-container">
                                {iconeToggleDarkMode()}
                            </div>
                            <div className="icone-container">
                                {iconeSignOut()}
                            </div>
                        </div>
                    </div>
                </ModalUsuarioContainer>
            </Modal>
            <Modal
                isOpen={modalCriacao}
                setModal={setModalCriacao}
                width={"auto"}
                align={"flex-end"}
                justify="flex-start"
                animation="comeFromTop 600ms cubic-bezier(0.4, 0, 0.2, 1)"
                margin="0rem 2rem 0rem 2rem"
                borderRadius="30px"
            >
                <ModalCriacaoContainer theme={theme}>
                    <div className="titulo">Rumo ao sucesso!</div>
                    <div className="subtitulo-criacao">
                        Vamos juntos configurar esses 5 passos?
                    </div>
                    <StepsCriacao
                        handleClose={(e) => {
                            setModalCriacao(false);
                        }}
                    />
                </ModalCriacaoContainer>
            </Modal>
            <Modal
                isOpen={modalAjuda}
                setModal={setModalAjuda}
                width={"auto"}
                align={"flex-end"}
                justify="flex-start"
                animation="comeFromTop 600ms cubic-bezier(0.4, 0, 0.2, 1)"
                margin="0rem 2rem 0rem 2rem"
                borderRadius="30px"
            >
                <ModalAjudaContainer theme={theme}>
                    <Ajuda />
                </ModalAjudaContainer>
            </Modal>
            <Modal
                isOpen={modalNotificacoes}
                setModal={setModalNotificacoes}
                width={"auto"}
                align={"flex-end"}
                justify="flex-start"
                animation="comeFromTop 600ms cubic-bezier(0.4, 0, 0.2, 1)"
                margin="0rem 2rem"
                borderRadius="30px"
            >
                <ModalNotificacoesContainer theme={theme}>
                    <Notificacoes onClose={() => setModalNotificacoes(false)}/>
                </ModalNotificacoesContainer>
            </Modal>
            <MainHeader theme={theme}>
                <div className="headerContainer">
                    {logo()}
                    <div className="paginacaoContainer hideMobile">
                        {breadCrumbs()}
                    </div>
                    <div className="botoesContainer">
                        {permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
                            <div className="icone">{botaoGuia()}</div>
                        )}
                        <div className="icone">{notificacao()}</div>
                        <div className="icone">{duvidas()}</div>
                        <div className="icone hideMobile">
                            {iconeToggleDarkMode()}
                        </div>
                        <div className="icone hideMobile">{iconeSignOut()}</div>
                        <div className="icone hideDesktop">
                            <FotoUsuario
                                onClick={() => {
                                    setModalMenuUsuario(true);
                                }}
                                className="foto"
                                size={4}
                            ></FotoUsuario>
                        </div>
                    </div>
                </div>
                <div className="paginacaoContainerMobile hideDesktop">
                    {breadCrumbs()}
                </div>
            </MainHeader>
        </>
    );
}

export default Header;
