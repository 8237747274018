import React, { useContext } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Pagamento from "../Pagamento/Pagamento";
import "./styles";
import { PagamentoMainContainer } from "./styles";

interface PagamentoMainProps {}

const PagamentoMain: React.FC<PagamentoMainProps> = ({}) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const [dropdown, setDropdown] = React.useState<boolean>(false);
  const [pagamentoDropdown, setPagamentoDropdown] =
    React.useState<boolean>(false);
  const { theme } = useTheme();

  return (
    <PagamentoMainContainer theme={theme}>
      <div>
        <Pagamento
          onChange={(intermediador) => {
            if(intermediador === 'pagseguro') {
              setPagamentoDropdown(!pagamentoDropdown);
            }
          }}
          configIn="configuracoes"
        ></Pagamento>
      </div>
    </PagamentoMainContainer>
  );
};

export default PagamentoMain;
