import { useContext, useEffect, useState } from "react";
import { CatalogoContext } from "../../../contexts/Catalogo/CatalogoContext";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { PagSeguroContext } from "../../../contexts/PagSeguro/PagSeguroContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { deepCopy } from "../../../services/Functions";
import { currencyMask, percentageMask } from "../../../services/Masks";
import { unmaskPercentage, unmaskToNumbers } from "../../../services/Unmasks";
import Configuracoes from "../../../types/Configuracoes";
import Dropdown from "../../Dropdowns/Dropdown";
import { Input } from "../../Input";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Select } from "../../Select";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ContainerIntermediadorContainer } from "./styles";

interface IntermediadoresProps {
  configIn: "catalogo" | "empresa" | "configuracoes";
  intermediador: "pagseguro" | "pagarme" | "mercadopago" | "pagarmehub";
}

const Intermediadores: React.FC<IntermediadoresProps> = ({
  configIn,
  intermediador,
}) => {

  const configsEmpresaContext = useContext(ConfiguracoesContext);
  const configsCatalogoContext = useContext(CatalogoContext);
  
  const pagSeguroApi = useContext(PagSeguroContext);

  const { theme } = useTheme();

  const configuracoes: any =
    configIn === "catalogo"
      ? configsCatalogoContext.catalogo
      : configsEmpresaContext.configuracoes
  const setConfiguracoes =
    configIn === "catalogo"
      ? configsCatalogoContext.setCatalogo
      : configsEmpresaContext.setConfiguracoes

  const ignoreValidation =
    configIn === "catalogo"
      ? false
      : (
        !(configuracoes as Configuracoes).pagamentoOnlineAtivo
      );


  const [configsCartao, setConfigsCartao] = useState(true);
  const [configsPix, setConfigsPix] = useState(true);

  const parcelas = [
    { value: 1, name: "1 parcela" },
    { value: 2, name: "2 parcelas" },
    { value: 3, name: "3 parcelas" },
    { value: 4, name: "4 parcelas" },
    { value: 5, name: "5 parcelas" },
    { value: 6, name: "6 parcelas" },
    { value: 7, name: "7 parcelas" },
    { value: 8, name: "8 parcelas" },
    { value: 9, name: "9 parcelas" },
    { value: 10, name: "10 parcelas" },
    { value: 11, name: "11 parcelas" },
    { value: 12, name: "12 parcelas" },
  ];

  useEffect(() => {
    if(configuracoes && configuracoes.intermediadores && !configuracoes.intermediadores[intermediador]){
      let newConfigs = {
        ...configuracoes,
        intermediadores: {
          ...configuracoes.intermediadores,
          [intermediador]: {
            cartao: {
              ativo: true,
              quantidade_maxima_parcelas: 1,
              valor_minimo_parcela: "0",
            },
            pix: {
              ativo: true,
              percentual_desconto: "0",
            },
          },
        },
      }
      configIn === "catalogo" ? setConfiguracoes(newConfigs) : setConfiguracoes(newConfigs)
    }
  }, [configuracoes])

  return (
    <>
      {configuracoes.intermediadores[intermediador] && (
        <ContainerIntermediadorContainer configIn={configIn} theme={theme}>
          <div className={`containerIntermediadores`}>
            {configIn === "empresa" && (
              <Titulo
                titulo={
                  intermediador.charAt(0).toUpperCase() + intermediador.slice(1)
                }
                subtitulo={
                  "Formas de pagamento solicitadas <br/> ao finalizar a compra."
                }
                flexDirection="column-reverse"
              ></Titulo>
            )}
            <div className="togglesContainer">
              <ToggleConfigurar
                ignoreValidations={ignoreValidation}
                nome={"Cartão de crédito"}
                value={
                  configuracoes.intermediadores[intermediador]?.cartao.ativo!
                }
                isValid={() => {
                  return (
                    configuracoes.intermediadores[intermediador]?.cartao.ativo! &&
                    !configsCartao &&
                    (!configuracoes.intermediadores[intermediador]?.cartao
                      .quantidade_maxima_parcelas! ||
                      !configuracoes.intermediadores[intermediador]?.cartao
                        .valor_minimo_parcela!)
                      ? false
                      : true
                  )
                }}
                invalidText="Preencha as configurações do cartão de crédito"
                onChange={(newValue) => {
                  if (
                    newValue &&
                    !configuracoes.intermediadores[intermediador]?.cartao
                      .quantidade_maxima_parcelas &&
                    !configuracoes.intermediadores[intermediador]?.cartao
                      .valor_minimo_parcela
                  ) {
                    setConfigsCartao(true);
                  }
                  if (!newValue) {
                    setConfigsCartao(false);
                  }
                  setConfiguracoes(
                    deepCopy(configuracoes, {
                      intermediadores: {
                        [intermediador]: { cartao: { ativo: newValue } },
                      },
                    }),
                  );
                }}
                onConfigurar={() => {
                  setConfigsCartao(!configsCartao);
                }}
              />
              <Dropdown active={configsCartao} className="dropdown">
                <div className="configsCartaoContainer">
                  <Select
                    sizeLabel="medium"
                    options={parcelas}
                    value={
                      configuracoes.intermediadores[intermediador]?.cartao
                        .quantidade_maxima_parcelas
                    }
                    placeholder="6 parcelas"
                    onChange={(newValue) => {
                      setConfiguracoes(
                        deepCopy(configuracoes, {
                          intermediadores: {
                            [intermediador]: {
                              cartao: { quantidade_maxima_parcelas: newValue },
                            },
                          },
                        }),
                      );
                    }}
                    label="Quantidade máxima de parcelas"
                    required={!ignoreValidation && configuracoes.intermediadores[intermediador]?.cartao.ativo}
                  />
                  <Input
                    type="text"
                    ignoreValidations={ignoreValidation || !configuracoes.intermediadores[intermediador]?.cartao.ativo}
                    sizeLabel="medium"
                    required={true}
                    value={
                      Number.parseFloat(
                        configuracoes.intermediadores[intermediador]?.cartao
                          .valor_minimo_parcela!,
                      ) > 0
                        ? Number.parseFloat(
                            configuracoes.intermediadores[intermediador]!.cartao
                              .valor_minimo_parcela!,
                          ).toFixed(0)
                        : ""
                    }
                    placeholder="R$ 10,00"
                    mask={{
                      mask: currencyMask,
                      unmask: unmaskToNumbers,
                    }}
                    onChange={(newValue) => {
                      setConfiguracoes(
                        deepCopy(configuracoes, {
                          intermediadores: {
                            [intermediador]: {
                              cartao: { valor_minimo_parcela: newValue },
                            },
                          },
                        }),
                      );
                    }}
                    label="Valor mínimo de cada parcela"
                  />
                </div>
              </Dropdown>

              <ToggleConfigurar
                ignoreValidations={ignoreValidation}
                nome={"Pix"}
                value={configuracoes.intermediadores[intermediador]!.pix.ativo}
                isValid={
                  configuracoes.intermediadores[intermediador]?.pix.ativo! &&
                  !configsPix &&
                  !configuracoes.intermediadores[intermediador]?.pix
                    .percentual_desconto!
                    ? false
                    : true
                }
                invalidText="Preencha as configurações do pix"
                onChange={(newValue) => {
                  if (
                    newValue &&
                    !configuracoes.intermediadores[intermediador]?.pix
                      .percentual_desconto
                  ) {
                    setConfigsPix(true);
                  }
                  if (!newValue) {
                    setConfigsPix(false);
                  }
                  setConfiguracoes(
                    deepCopy(configuracoes, {
                      intermediadores: {
                        [intermediador]: { pix: { ativo: newValue } },
                      },
                    }),
                  );
                }}
                onConfigurar={() => {
                  setConfigsPix(!configsPix);
                }}
              />

              <Dropdown active={configsPix} className="dropdown">
                <div className="configsPixContainer">
                  <Input
                    ignoreValidations={ignoreValidation || !configuracoes.intermediadores[intermediador]?.pix.ativo}
                    type="text"
                    sizeLabel="medium"
                    required={true}
                    value={
                      Number.parseFloat(
                        configuracoes.intermediadores[intermediador]!.pix
                          .percentual_desconto,
                      ) > 0
                        ? Number.parseFloat(
                            configuracoes.intermediadores[intermediador]!.pix
                              .percentual_desconto,
                          ).toFixed(0)
                        : "0"
                    }
                    placeholder="10%"
                    mask={{
                      mask: percentageMask,
                      unmask: unmaskPercentage,
                    }}
                    onChange={(newValue) => {
                      setConfiguracoes(
                        deepCopy(configuracoes, {
                          intermediadores: {
                            [intermediador]: {
                              pix: { percentual_desconto: newValue },
                            },
                          },
                        }),
                      );
                    }}
                    label="Porcentagem de desconto"
                  />
                </div>
              </Dropdown>
            </div>
          </div>
        </ContainerIntermediadorContainer>
      )}
    </>
  );
};
export default Intermediadores;
