import styled from "styled-components";
import theme from "../../../../Themes/light";

export const ContainerOpcoesDeFrete = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  padding: 0rem 12rem;
  max-width: 90vw;

  > .containerImagem {
    min-width: 60%;

    > .imagem {
      width: 100%;
    }
  }

  > .containerTexto {
    min-width: 40%;
    font-size: 3rem;
    text-align: left;

    > span {
      font-size: 4rem;
      color: ${(props) => props.theme.colors.titleColor};
    }
  }

  @media (max-width: 1024px) {
    padding: 0rem 6rem;
    flex-direction: column;

    .containerImagem {
      width: 100%;

      .imagem {
        max-width: 50rem;
      }
    }

    .containerTexto {
      text-align: center;
    }
  }
`;
