import styled from "styled-components";

const ContainerReplicarCatalogo = styled.div`
  padding: 8rem;
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.brandSecondary};
  position: relative;
  .tituloContainer{
    .titulo{
      margin-bottom: 3rem;
    }
  }
  .column-flex {
    display: inline-flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    .flex-label {
      display: flex;
      gap: 2rem;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem 0;
      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .row-flex {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    padding-top: 4rem;
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      > svg {
        padding-top: 0.3rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

const ContainerCatalogo = styled.div`
  width: 100%;
  padding: 5rem 10rem 18rem 10rem;
  .grid-container-catalogo {
    width: 100%;
    position: relative;
    font-size: 2.5rem;
    gap: 3vw;
    display: grid;
    grid-template-columns: calc(40% - 2px - 3vw) 2px calc(60% - 3vw); //paia isso
    .middle {
      width: 100%;
      height: calc(100vh - 70rem);
      padding-top: 12rem;
      background-color: ${(props) => props.theme.colors.elements};
      align-self: flex-start;
    }
    .left,
    .right {
      gap: 2rem;
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandSecondary};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .container-conteudo {
        opacity: 0;
        transition: 0.3s ease-in-out;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 10rem;
        left: 0;
        width: 100%;
        padding-bottom: 22rem;
        gap: 3rem;
        .tab-filtrar {
          white-space: nowrap;
        }
        .linha {
          width: 100%;
          height: 2px;
          background-color: ${(props) => props.theme.colors.elements};
          border-radius: 50px;
        }
      }
      .no-padding {
        top: 3rem;
      }
      .container-ativo {
        opacity: 1;
        pointer-events: all;
      }
      .bold {
        font-weight: bold;
        width: 100%;
        text-align: left;
        padding-left: 1rem;
      }
      .flex {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        > span {
          width: 100%;
          text-align: left;
          font-weight: bold;
        }
        > label {
          display: flex;
          width: 100%;
          gap: 1rem;
          flex-wrap: wrap;
          align-items: center;
        }
      }
      .no-wrap {
        flex-wrap: nowrap;
      }
      .nowrap {
        white-space: nowrap;
      }
      .space-between {
        justify-content: space-between;
      }
      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
        gap: 2rem;
      }
      .grid-mobile {
        display: flex;
        width: 100%;
      }
      .expiracao {
        display: block;
        transition: 0.3s ease-in-out;
        pointer-events: none;
        opacity: 0;
      }
      .ativo {
        pointer-events: inherit;
        opacity: 1;
      }
      .exemplo {
        color: ${(props) => props.theme.colors.placeholder};
        font-size: 2.2rem;
      }
      .half-width {
        width: 48%;
        min-width: 35rem;
        flex-grow: 1;
      }
      .filtrar-produtos-container {
        position: relative;
        width: 100%;
      }
    }
    .footer-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap-reverse;
      gap: 2rem;
      .green {
        color: ${(props) => props.theme.colors.brandPrimary};
        font-size: 2.5rem;
        cursor: pointer;
      }
      .right-flex {
        white-space: normal;
        display: flex;
        gap: 2rem;
        align-items: center;
        a {
          display: contents;
        }
        .cinza {
          color: ${(props) => props.theme.colors.brandSecondary};
          font-weight: normal;
          background-color: ${(props) => props.theme.colors.elements};
        }
      }
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      height: auto;
      .left,
      .right {
        .container-conteudo {
          top: 0;
          padding-bottom: 0;
          position: relative;
          display: none;
        }
        .container-ativo {
          display: flex;
        }
      }
      .footer-wrapper {
        .right-flex {
          display: contents;
        }
      }
    }
    @media (max-width: 680px) {
      .footer-wrapper {
        gap: 1rem;
        .button {
          width: 100% !important;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 5rem 3rem 20rem 3rem;
  }
`;

export { ContainerCatalogo, ContainerReplicarCatalogo };

