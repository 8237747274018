import { createContext } from "react";
import { FiltrosProdutos, Produto } from "../../types/Produto";
import { Categoria } from "../../types/Categoria";

export type ProdutosContextType = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  newProductModal: boolean;
  setNewProductModal: React.Dispatch<React.SetStateAction<boolean>>;
  produtos: Produto[];
  filtros: FiltrosProdutos;
  setFiltros: React.Dispatch<React.SetStateAction<FiltrosProdutos>>;
  filtroPesquisaAux: string;
  setFiltroPesquisaAux: React.Dispatch<React.SetStateAction<string>>;
  produtosFiltrados: Produto[];
  categorias: Categoria[];
  optionsAtivos: { name: string; value: string }[];
  optionsEstoque: { name: string; value: string }[];
  optionsVisualizacao: { name: string; value: string }[];
  optionsOrdenacao: { name: string; value: string }[];
  init: () => void;
};

export const ProdutosContext = createContext<ProdutosContextType>(null!);
