import { MutableRefObject, createContext } from "react";
import { ModaisConfiguracoesProps } from "../../types/ModaisConfiguracoesProps";

export type ConfigsPrimeiroAcessoContextType = {
  goTo: (localizador: string, params?: any) => void;
  modalAtivo: ModaisConfiguracoesProps;
  setModalAtivo: (modal: ModaisConfiguracoesProps) => void;
  modal: boolean;
  setModal: (modal: boolean) => void;
  modais: Array<ModaisConfiguracoesProps>;
  paramsHistory: MutableRefObject<any[]>;
};

export const ConfigsPrimeiroAcessoContext =
  createContext<ConfigsPrimeiroAcessoContextType>(null!);
