export function validatePhone(
  phone: string,
  inputElement?: HTMLInputElement,
): boolean {
  // Remove tudo que não for dígito do número de telefone
  const cleaned = phone.replace(/\D/g, "");

  // Verifica se o número de telefone tem 10 ou 11 dígitos
  const length = cleaned.length;
  if (length !== 10 && length !== 11) {
    if (inputElement) {
      inputElement.setCustomValidity("Preencha um telefone válido");
    }
    return false;
  }

  // Verifica se todos os caracteres são numéricos
  const numeric = /^\d+$/.test(cleaned);
  if (!numeric) {
    if (inputElement) {
      inputElement.setCustomValidity("Preencha um telefone válido");
    }
    return false;
  }

  return true;
}

export function validateCep(
  cep: string,
  inputElement?: HTMLInputElement,
): boolean {
  if (cep.length < 8) {
    if (inputElement) inputElement.setCustomValidity("Preencha um CEP válido");
    return false;
  }
  return true;
}

export function validateHex(
  hex: string,
  inputElement?: HTMLInputElement,
): boolean {
  if (hex.length < 7) {
    if (inputElement)
      inputElement.setCustomValidity("Preencha um código hexadecimal válido");
    return false;
  }
  return true;
}

export function validateEmail(email: string, inputElement?): boolean {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = regexEmail.test(email);
  if (!isValid) {
    if (inputElement) inputElement.setCustomValidity("Preencha um email válido");
    return false;
  }
  return true;
}
