import styled from "styled-components";
import theme from "../../Themes/light"; // substitua pelo caminho correto do arquivo de tema

export const ColorPickerContainer = styled.div``;

export const HexInputContainer = styled.div`
  color: ${theme.colors.placeholder};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  .input-hex-container {
    width: 50%;
  }
  .label {
    color: ${theme.colors.brandSecondary};
    font-weight: normal;
    font-size: 2rem;
  }
`;
