import React, { useEffect, useState } from "react";
import "./styles.ts";
import { Input } from "../../../../../components/Input/index.tsx";
import { Button } from "../../../../../components/Button/index.tsx";
import { ReactComponent as Voltar } from "../../../../../assets/newImages/icons/angle-down.svg";
import ModalSucesso from "../../../../../components/Modal/ModalSucesso/index.tsx";
import Modal from "../../../../../components/Modal/Modal.tsx";
import Titulo from "../../../../../components/Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo.tsx";
import { Toggle } from "../../../../../components/Toggle/Toggle.tsx";
import { Select } from "../../../../../components/Select/index.tsx";
import { listasDePrecoApi } from "../../../../../hooks/listasDePreco.ts";
import { useTheme } from "../../../../../contexts/Theme/ThemeContext.tsx";
import { formatCurrencyInput } from "../../../../../services/Masks.tsx";
import {
  CriarCategoriaContainer,
  ContainerEditarListas,
  GridListas,
  Header,
  Lista,
  TituloLista,
  Botoes,
} from "./styles.ts";
import {
  noSpecialCharacters,
  percentageMask,
} from "../../../../../services/Masks.tsx";
import { unmaskPercentage } from "../../../../../services/Unmasks.tsx";
import { copy } from "../../../../../services/Functions.tsx";
import Swal from "sweetalert2";
import Form from "../../../../../components/Form/index.tsx";
import { boolean } from "../../../../../services/Functions";

interface ListaDePrecoBoxProps {
  setListaDePrecoBox: (newValue: boolean) => void;
  setNovaListaDePreco: (newValue: number) => void;
  categorias: any[];
}

export const ListaDePrecoBox: React.FC<ListaDePrecoBoxProps> = ({
  setListaDePrecoBox,
  setNovaListaDePreco,
  categorias: categoriasParam,
}) => {
  const { theme } = useTheme();
  const [categorias, setCategorias] = useState<any[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [mensagemModal, setMensagemModal] = useState(
    "Salvando dados, aguarde..."
  );
  const [stateModal, setStateModal] = useState("loading");
  const [descricao, setDescricao] = React.useState<string>("");
  const [moeda, setMoeda] = React.useState<string>("");
  const [usarTabelaBase, setUsarTabelaBase] = React.useState<boolean>(false);
  const [aumentarDiminuir, setAumentarDiminuir] =
    React.useState<string>("aumentar");
  const [id_categoria, setIdCategoria] = React.useState<string>("");
  const [indice, setIndice] = React.useState<string>("0");
  const [listasDePreco, setListasDePreco] = React.useState<any[]>([]);
  const [listasDePrecoSelect, setListasDePrecoSelect] = React.useState<any[]>(
    []
  );
  const [reajustandoPrecos, setReajustandoPrecos] = React.useState<any>(false);
  const [id_lista_editando, setIdListaEditando] = React.useState<any>("0");
  const [tabelaSelecionada, setTabelaSelecionada] = React.useState<any>("0");
  const [editandoListasDePreco, setEditandoListasDePreco] =
    React.useState<any>(true);
  const [recarregarListasDePreco, setRecarregarListasDePreco] =
    React.useState<any>(false);

  const handleEditarListasDePreco = async () => {
    let listasEditar = copy(listasDePreco);

    listasEditar.forEach((lista) => {
      if (lista.ativo) {
        lista.ativo = "1";
      } else {
        lista.ativo = "0";
      }
    });

    listasDePrecoApi()
      .editarListaDePreco(listasEditar)
      .then((response) => {
        setEditandoListasDePreco(true);
        setRecarregarListasDePreco(!recarregarListasDePreco);
        Swal.fire({
          title: "Sucesso!",
          text: "Tabelas de preço editadas com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
          setListaDePrecoBox(false);
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Erro!",
          text: "Erro ao editar tabelas de preço",
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      });
  };

  const handleCriar = async () => {
    let indice_formatado = 0;
    let porcentagem_formatada = Number(indice);

    if (usarTabelaBase) {
      indice_formatado =
        aumentarDiminuir == "aumentar"
          ? 1 + porcentagem_formatada / 100
          : 1 - porcentagem_formatada / 100;
      if (indice_formatado < 0) indice_formatado = 0;
      setIndice(indice_formatado.toString());
    } else {
      setTabelaSelecionada("0");
      setIndice("0");
    }

    listasDePrecoApi()
      .criarListaDePreco(
        descricao,
        id_lista_editando,
        indice_formatado,
        moeda,
        tabelaSelecionada
      )
      .then((response) => {
        setEditandoListasDePreco(true);
        setDescricao("");
        setMoeda("");
        setUsarTabelaBase(false);
        setAumentarDiminuir("aumentar");
        setIndice("0");
        setTabelaSelecionada("0");
        Swal.fire({
          title: "Sucesso!",
          text: "Tabela de preço criada com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          setRecarregarListasDePreco(!recarregarListasDePreco);
          setEditandoListasDePreco(true);
          setNovaListaDePreco(1);
        });
      })
      .catch((error) => {
        console.log(error);
        setStateModal("error");
        let erro = error.response.data.error;
        if (erro == "TABELA_JA_EXISTE") {
          Swal.fire({
            title: "Erro!",
            text: "Já existe uma tabela de preço com esse nome",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const handleReajustarPrecos = async () => {
    let indice_formatado = 0;
    let porcentagem_formatada = Number(indice);

    indice_formatado =
      aumentarDiminuir == "aumentar"
        ? 1 + porcentagem_formatada / 100
        : 1 - porcentagem_formatada / 100;
    if (indice_formatado < 0) indice_formatado = 0;
    setIndice(indice_formatado.toString());

    listasDePrecoApi()
      .reajustarPrecos(
        id_categoria,
        indice_formatado,
        id_lista_editando,
        descricao,
        moeda,
        tabelaSelecionada
      )
      .then((response) => {
        setEditandoListasDePreco(true);
        setDescricao("");
        setMoeda("");
        setUsarTabelaBase(false);
        setAumentarDiminuir("aumentar");
        setIndice("0");
        setTabelaSelecionada("0");
        Swal.fire({
          title: "Sucesso!",
          text: "Tabela de preço editada com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          setRecarregarListasDePreco(!recarregarListasDePreco);
          setEditandoListasDePreco(true);
          setNovaListaDePreco(1);
        });
      })
      .catch((error) => {
        let erro = error.response.data.error;
        if (erro == "TABELA_JA_EXISTE") {
          Swal.fire({
            title: "Erro!",
            text: "Já existe uma tabela de preço com esse nome",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
        }
      });
  };

  useEffect(() => {
    const init = async () => {
      const listasDePreco = await listasDePrecoApi().getListas();
      setListasDePreco(listasDePreco);

      setListasDePrecoSelect(
        listasDePreco.map((lista) => {
          return {
            name: lista.descricao,
            value: lista.id,
          };
        })
      );

      setTabelaSelecionada(listasDePreco[0].id);
    };

    init();
  }, [recarregarListasDePreco]);

  useEffect(() => {
    setUsarTabelaBase(false);
    if (editandoListasDePreco) {
      setIdListaEditando("0");
      setDescricao("");
    }else{
      setTabelaSelecionada("0");
    }
  }, [editandoListasDePreco]);

  useEffect(() => {
    if (usarTabelaBase) {
      setTabelaSelecionada(listasDePreco[0].id);
    }else{
      setTabelaSelecionada("0");
    }
  }, [usarTabelaBase]);

  useEffect(() => {
    setCategorias([
      { name: "Todas as categorias", value: "0" },
      ...categoriasParam,
    ]);
    setIdCategoria(categoriasParam.length > 0 ? categoriasParam[0].value : "0");
  }, []);

  return (
    <>
      {editandoListasDePreco ? (
        <ContainerEditarListas theme={theme}>
          <Form
            className="form"
            id="form-listas"
            setFormValid={setFormValid}
            onSubmit={handleEditarListasDePreco}
          >
            <Titulo
              titulo="Tabelas de preço"
              subtitulo="Configure as tabelas de preço da sua conta."
              flexDirection="column-reverse"
            />
            <GridListas>
              {listasDePreco.map((listaDePreco, index) => {
                return (
                  <div key={listaDePreco.id || index}>
                    {index === 0 && (
                      <Header>
                        <TituloLista>Nome</TituloLista>
                        <TituloLista>Moeda</TituloLista>
                        <TituloLista>Situação</TituloLista>
                      </Header>
                    )}
                    <Lista>
                      <TituloLista>
                        <span
                          onClick={() => {
                            setIdListaEditando(listaDePreco.id);
                            setDescricao(listaDePreco.descricao);
                            setMoeda(listaDePreco.moeda);
                            setEditandoListasDePreco(false);
                          }}
                        >
                          {listaDePreco.descricao}
                        </span>
                      </TituloLista>
                      <TituloLista>
                        <span
                          onClick={() => {
                            setIdListaEditando(listaDePreco.id);
                            setDescricao(listaDePreco.descricao);
                            setMoeda(listaDePreco.moeda);
                            setEditandoListasDePreco(false);
                          }}
                        >
                          {listaDePreco.moeda}
                        </span>
                      </TituloLista>
                      <TituloLista>
                        <div className="center">
                          <Toggle
                            value={listaDePreco.ativo}
                            isValid={listasDePreco.some(
                              (lista) =>
                                lista.ativo === "1" ||
                                lista.ativo === 1 ||
                                boolean(lista.ativo) === true
                            )}
                            invalidText="Deve haver pelo menos </br> uma tabela de preço ativa."
                            onChange={async (newValue) => {
                              if (!newValue) {
                                const response = await Swal.fire({
                                  title: "Atenção!",
                                  text: "Inativar essa tabela de preço irá desativar todos os catálogos associados a ela. Tem certeza de que deseja continuar?",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "Sim, inativar",
                                  cancelButtonText: "Cancelar",
                                });
                                if (!response.isConfirmed) {
                                  return;
                                }
                              }
                              setListasDePreco(
                                listasDePreco.map((lista) => {
                                  if (lista.id === listaDePreco.id) {
                                    lista.ativo = newValue;
                                  }
                                  return lista;
                                })
                              );
                            }}
                          />
                        </div>
                      </TituloLista>
                    </Lista>
                  </div>
                );
              })}
            </GridListas>
            <div className="linha-pai">
              <div className="linha"></div>
            </div>
            <div className="nova-tabela">
              <span
                onClick={() => {
                  setEditandoListasDePreco(false);
                  setMoeda("");
                }}
              >
                Adicionar nova tabela
              </span>
            </div>
            <Botoes theme={theme}>
              <div
                className="cancelar"
                onClick={() => {
                  setListaDePrecoBox(false);
                }}
              >
                <Voltar /> Voltar
              </div>
              <Button
                title="Salvar"
                type="submit"
                width="50%"
                className={`botao_alterar ${!formValid && "disabled"}`}
              />
            </Botoes>
          </Form>
        </ContainerEditarListas>
      ) : (
        <CriarCategoriaContainer theme={theme}>
          <Titulo
            titulo={`${
              id_lista_editando == "0"
                ? "Nova tabela de preço"
                : `Editando tabela ${descricao}`
            }`}
            subtitulo={
              id_lista_editando == "0"
                ? "Configure as informações para criar uma nova tabela de preço."
                : ""
            }
            flexDirection="column-reverse"
          />

          <div className="inputs_nome_moeda">
            <Input
              label="Nome"
              sizeLabel="medium"
              type="text"
              name="nome"
              maxLength={24}
              mask={{ mask: noSpecialCharacters }}
              value={descricao}
              onChange={(newValue) => {
                setDescricao(newValue);
              }}
              placeholder="Nome da tabela de preço"
            />
            <Input
              label="Moeda"
              sizeLabel="medium"
              type="text"
              name="moeda"
              maxLength={9}
              mask={{ mask: formatCurrencyInput }}
              value={moeda}
              onChange={(newValue) => {
                setMoeda(newValue);
              }}
              placeholder="R$"
            />
          </div>

          {editandoListasDePreco && id_lista_editando != "0" ? (
            <div className="toggle">
              <Toggle
                value={reajustandoPrecos}
                onChange={(newValue) => {
                  if (categorias.length == 1) {
                    return Swal.fire({
                      title: "Erro!",
                      text: "Não há categorias cadastradas",
                      icon: "error",
                      showConfirmButton: true,
                      confirmButtonText: "Ok",
                    });
                  }
                  setReajustandoPrecos(newValue);
                }}
              />
              <div className="info">Reajustar preços</div>
            </div>
          ) : (
            <div className="toggle">
              <Toggle
                value={usarTabelaBase}
                onChange={(newValue) => {
                  setUsarTabelaBase(newValue);
                }}
              />
              <div className="info">Usar outra tabela de preço como base.</div>
            </div>
          )}

          {reajustandoPrecos && (
            <>
              <div className="tabela">
                <Select
                  label="Categoria"
                  sizeLabel="medium"
                  value={id_categoria}
                  name="tabela"
                  required
                  options={categorias}
                  onChange={(newValue) => {
                    setIdCategoria(newValue);
                  }}
                />
              </div>
              <div className="ajustes">
                <Select
                  label="Ajuste de preço"
                  sizeLabel="medium"
                  value={aumentarDiminuir}
                  name="tabela"
                  options={[
                    {
                      name: "Aumentar",
                      value: "aumentar",
                    },
                    {
                      name: "Diminuir",
                      value: "diminuir",
                    },
                  ]}
                  onChange={(newValue) => {
                    setAumentarDiminuir(newValue);
                  }}
                />

                <Input
                  label="Porcentagem"
                  sizeLabel="medium"
                  type="text"
                  name="nome"
                  mask={{
                    mask: percentageMask,
                    unmask: unmaskPercentage,
                  }}
                  value={indice}
                  onChange={(newValue) => {
                    let porcentagem = parseInt(newValue);

                    let valorPorcentagem = 0;
                    if (aumentarDiminuir == "aumentar") {
                      valorPorcentagem = porcentagem / 100;
                    } else {
                      valorPorcentagem = porcentagem / 100;
                    }
                    setIndice(valorPorcentagem.toFixed(2).replace(/^0/, ""));
                  }}
                  placeholder="0.0%"
                />
              </div>
            </>
          )}

          {usarTabelaBase && (
            <>
              <div className="tabela">
                <Select
                  label="Tabela de preço"
                  sizeLabel="medium"
                  value={tabelaSelecionada}
                  name="tabela"
                  options={listasDePrecoSelect}
                  onChange={(newValue) => {
                    setTabelaSelecionada(newValue);
                  }}
                />
              </div>
              <div className="ajustes">
                <Select
                  label="Ajuste de preço"
                  sizeLabel="medium"
                  value={aumentarDiminuir}
                  name="tabela"
                  options={[
                    {
                      name: "Aumentar",
                      value: "aumentar",
                    },
                    {
                      name: "Diminuir",
                      value: "diminuir",
                    },
                  ]}
                  onChange={(newValue) => {
                    setAumentarDiminuir(newValue);
                  }}
                />

                <Input
                  label="Porcentagem"
                  sizeLabel="medium"
                  type="text"
                  name="nome"
                  mask={{
                    mask: percentageMask,
                    unmask: unmaskPercentage,
                  }}
                  value={indice}
                  onChange={(newValue) => {
                    let porcentagem = parseInt(newValue);

                    let valorPorcentagem = 0;
                    if (aumentarDiminuir == "aumentar") {
                      valorPorcentagem = porcentagem / 100;
                    } else {
                      valorPorcentagem = porcentagem / 100;
                    }
                    setIndice(valorPorcentagem.toFixed(2).replace(/^0/, ""));
                  }}
                  placeholder="0.0%"
                />
              </div>
            </>
          )}
          <Botoes theme={theme}>
            <div
              className="cancelar"
              onClick={() => {
                setEditandoListasDePreco(true);
              }}
            >
              <Voltar /> Voltar
            </div>
            <Button
              title="Salvar"
              className="botao_alterar"
              width="50%"
              onClick={
                id_lista_editando != "0" && reajustandoPrecos
                  ? handleReajustarPrecos
                  : handleCriar
              }
              disabled={
                descricao.length == 0 ||
                (reajustandoPrecos && !indice && usarTabelaBase && !indice) ||
                (reajustandoPrecos && !id_categoria)
              }
            />
          </Botoes>
        </CriarCategoriaContainer>
      )}
    </>
  );
};
