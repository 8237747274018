import { api } from "./useApi";

export const produtoApi = () => ({
  getProduto: async (id: any) => {
    let response = await api.post("produto/unico", { id_produto: id });
    return response.data;
  },
  produtoCriar: async (produto: any) => {
    let response = await api.post("produto/criar", { produto });
    return response.data;
  },
  produtoInativar: async (id: any) => {
    let response = await api.post("produto/remover", { id_produto: id });
    return response.data;
  },
});
