const theme = {
  colors: {
    white: "#fff",
    black: "#000",
    brandSuccess: "#a6c733",
    brandInfo: "#57bdda",
    brandWarning: "#e5c649",
    brandDanger: "#ed7878",
    brandPrimary: "#0D5229",
    brandSecondary: "#636466",
    titleColor: "#FF6E28",
    placeholder: "#C7C7C7",
    elements: "#E0E0E0",
    modal: "#FAFAFA",
    background1: "#F9F9F9",
    background2: "#FFFFFF",
    mockup: "#D9D9D9",
    description: "#808080",
  },
};

export default theme;
