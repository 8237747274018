import Swal from "sweetalert2";
import "./styles.scss";
import { useRef, useState } from "react";
import { useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { Button } from "../Button";
import React from "react";
import { PromiseFileReader, getCroppedImg } from "../../services/Functions";
import PopoverForm from "../PopoverForm/PopoverForm";
interface ImageUploadWithCropProps {
  acceptFormats: string;
  aspectRatio: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  resize?: {
    width: number;
    height: number;
  };
  value: string;
  circularCrop?: boolean;
  onChange: (base64: string, imagem: string, size: number) => void;
  className?: string;
  style?: React.CSSProperties;
  onDragIn?: (e) => void;
  onDragOut?: (e) => void;
  onDragOver?: (e) => void;
  onDragDrop?: (e) => void;
  maxBytes?: number;
  children?: React.ReactNode;
  onLoading: (loading: boolean) => void;
  errorMargin?: number; //margem para ja mandar a imagem sem cropar caso esteja no tamanho ideal
  onOpenCrop?: (
    cropping: JSX.Element,
    cancelar: JSX.Element,
    salvar: JSX.Element
  ) => JSX.Element;
}

export const ImageUploadWithCrop: React.FC<ImageUploadWithCropProps> = ({
  acceptFormats,
  onChange,
  aspectRatio,
  value,
  minWidth,
  minHeight,
  maxWidth,
  maxBytes,
  maxHeight,
  resize,
  circularCrop = false,
  onDragIn,
  onDragOut,
  onDragOver,
  onDragDrop,
  className,
  errorMargin = 0,
  style,
  children,
  onLoading,
  onOpenCrop,
}) => {
  const [base64, setBase64] = useState<string>("");
  const [imagem, setImagem] = useState<string>("");
  const [imageExtension, setImageExtension] = useState<string>("");
  const [size, setSize] = useState<number>(0);

  const imageRef = useRef<HTMLImageElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [crop, setCrop] = useState<Crop>();

  const [modal, setModal] = useState<boolean>(false);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLLabelElement>
  ): void => {
    onLoading(true);

    setBase64("");
    setImagem("");
    setSize(0);

    let files: FileList | null = null;

    if ((event as React.DragEvent<HTMLLabelElement>).dataTransfer) {
      files = (event as React.DragEvent<HTMLLabelElement>).dataTransfer.files;
    } else if ((event as React.ChangeEvent<HTMLInputElement>).target) {
      files = (event as React.ChangeEvent<HTMLInputElement>).target.files;
    }

    if (files) {
      const filesArrayAux = Array.from(files);
      const filesArray = [...filesArrayAux];

      if (inputFileRef.current) {
        inputFileRef.current.files = null;
        inputFileRef.current.value = "";
      }

      if (!isValid(filesArray)) {
        onLoading(false);
        return;
      }

      PromiseFileReader(filesArray[0], {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        minWidth: minWidth,
        minHeight: minHeight,
        errorMargin: errorMargin,
      }).then(
        (result) => {
          if (result.consideration === "TAMANHO_IDEAL") {
            onChange(result.base64, result.imagem, result.size);
          } else if (result.consideration === "PODE_CROPAR") {
            setBase64(result.base64);
            setImagem(result.imagem);
            setSize(result.size);
          } else if (result.consideration === "TAMANHO_INVALIDO") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `A imagem deve ter ${
                minWidth && minHeight
                  ? `no mínimo ${minWidth}x${minHeight} pixels`
                  : ""
              }
                     ${
                       maxWidth && maxHeight
                         ? `no máximo ${maxWidth}x${maxHeight} pixels`
                         : ""
                     }!`,
            });
          } else if (result.consideration === "PROPORCAO_INVALIDA") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `A imagem deve ter a proporção ${aspectRatio}!`,
            });
          }
          onLoading(false);
        },
        (err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Ocorreu um erro ao tentar upar esta imagem!`,
          });
          onLoading(false);
        }
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Ocorreu um erro ao upar as imagens!`,
      });
      onLoading(false);
    }
  };

  const isValid = (files: File[]) => {
    const formats = acceptFormats.split(",").map((format) => format.trim());
    const extension: string =
      (files && files[0]!.name.split(".").pop())?.toLocaleLowerCase() || "";

    files && setImageExtension(extension === "jpg" ? "jpeg" : extension);

    if (files && !formats.includes("." + extension)) {
      Swal.fire({
        icon: "error",
        text: `Formato de imagem inválido! Os formatos aceitos são: ${formats.join(
          ", "
        )}`,
      });
      return false;
    } else if (files && maxBytes && files[0]!.size > maxBytes) {
      Swal.fire({
        icon: "error",
        text: `Imagem muito grande!`,
      });
      return false;
    }
    return true;
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectRatio) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspectRatio));
    }
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const handleOnSaveCrop = (crop: Crop) => {
    onLoading(true);
    getCroppedImg(imageRef.current!, crop, imageExtension, resize).then(
      (croppedImageBlob) => {
        const file = new File([croppedImageBlob], imagem, {
          type: `image/${imageExtension}`,
        });
        PromiseFileReader(file).then(
          (result) => {
            // result.base64 is the base64 of cropped image
            // pass this to the onChange function
            onChange(result.base64, result.imagem, result.size);
            setModal(false);
            onLoading(false);
          },
          (err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              text: `Ocorreu um erro ao tentar recortar esta imagem!`,
            });
            onLoading(false);
          }
        );
      }
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDragOver && onDragOver(e);
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDragIn && onDragIn(e);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDragOut && onDragOut(e);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onDragDrop && onDragDrop(e);

    handleChange(e);
  };

  useEffect(() => {
    if (base64 !== "") {
      setModal(true);
    }
  }, [base64]);

  useEffect(() => {
    if (value === "") {
      setBase64("");
      setImagem("");
      setSize(0);
    }
  }, [value]);

  return (
    <>
      <label
        className={className}
        style={{ ...style, position: "relative" }}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {children}
        <input
          ref={inputFileRef}
          style={{ display: "none" }}
          type="file"
          onChange={handleChange}
          accept={acceptFormats}
        ></input>
      </label>
      {modal && (
        <>
          {onOpenCrop &&
            onOpenCrop(
              <>
                <div className="imagem-container">
                  <div className="imagem">
                    <ReactCrop
                      className="crop-custom-class"
                      aspect={aspectRatio}
                      minWidth={
                        minWidth
                          ? minWidth! /
                            (imageRef.current?.naturalWidth! /
                              imageRef.current?.width!)
                          : 0
                      } // para cropar conforme a escala
                      minHeight={
                        minHeight
                          ? minHeight! /
                            (imageRef.current?.naturalHeight! /
                              imageRef.current?.height!)
                          : 0
                      }
                      keepSelection={true}
                      circularCrop={circularCrop}
                      crop={crop}
                      onChange={(crop, percentCrop) => setCrop(percentCrop)}
                    >
                      <img ref={imageRef} src={base64} onLoad={onImageLoad} />
                    </ReactCrop>
                  </div>
                </div>
              </>,
              <>
                <div className="cancelar" onClick={() => setModal(false)}>
                  Cancelar
                </div>
              </>,
              <>
                <Button
                  title="Salvar"
                  className="salvar"
                  onClick={() => handleOnSaveCrop(crop!)}
                />
              </>
            )}
        </>
      )}
    </>
  );
};
