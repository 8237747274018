import ContainerProdutosListagemSkeleton from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonProdutoProps {
  style?: React.CSSProperties;
}

const SkeletonProduto: React.FC<SkeletonProdutoProps> = ({ style }) => {
  const { theme } = useTheme();

  return <ContainerProdutosListagemSkeleton style={style} theme={theme} />;
};

export default SkeletonProduto;
