import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "1s";
const ContainerHomeSkeleton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 20rem 20rem 40rem 20rem;
  width: 100%;
  gap: 8rem;

  .home-skeleton {
    width: 100%;
    border-radius: 20px;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.05s);

    @keyframes pulsate {
      0% {
        background-color: ${(props) => props.theme.colors.skeleton1};
      }

      100% {
        background-color: ${(props) => props.theme.colors.skeleton2};
      }
    }
  }

  :nth-child(7) {
    grid-column: 1 / -1;
  }

  :nth-child(8) {
    grid-column: 1 / -1;
  }

  @media (max-width: 775px) {
    grid-template-columns: 1fr;
  }
`;

export default ContainerHomeSkeleton;
