import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AngleDown } from "../../assets/newImages/icons/angle-down.svg";
import { ReactComponent as InfoSVG } from "../../assets/newImages/icons/info.svg";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import PopoverForm from "../PopoverForm/PopoverForm";
import "./styles";
import { ViewSelect } from "./styles";

interface SelectProps {
    options: Array<{ name: string; value: any }> | any;
    value: any;
    onChange: (newValue: string, name?: string) => void;
    name?: string;
    placeholder?: string;
    className?: string;
    label?: string;
    alignLabel?: "left" | "center" | "right";
    sizeLabel?: "small" | "medium" | "large";
    required?: boolean;
    infoModal?: (e: any) => void;
    disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
    options,
    value,
    placeholder,
    onChange,
    name,
    label,
    sizeLabel,
    alignLabel,
    className,
    required = false,
    infoModal,
    disabled,
}) => {
    let fontLabel = sizesLabel();

    const { theme } = useTheme();

    function sizesLabel() {
        switch (sizeLabel) {
            case "small":
                return "1.5rem";
            case "medium":
                return "2.5rem";
            case "large":
                return "3rem";
            default:
                return "2.5rem";
        }
    }

    const [ativo, setAtivo] = useState(true);
    const [selecionado, setSelecionado] = useState<any>();
    const [focused, setFocused] = useState<null | number>(null);
    const [hoverEffect, setHoverEffect] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectedOptionRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const filteredOptions = options.filter((option: any) => 
        (searchTerm && option.name) ? option.name.toLowerCase().includes(searchTerm.toString().toLowerCase()) : true
    );

    const optionsHeight = filteredOptions.length > 5 ? 200 : filteredOptions.length * 40;

    useEffect(() => {
        if (options.length === 0) {
            return;
        }
    }, [options, value]);

    useEffect(() => {
        let option = options.find((option: any) => option.value === value);
        setSelecionado(option ? option.name : null);
    }, [options, value]);

    const handleSelectChange = (
        event:
            | React.MouseEvent<HTMLDivElement>
            | React.KeyboardEvent<HTMLDivElement>,
        newValue: string,
        selecionado: any,
    ) => {
        if (disabled) return;
        setSelecionado(selecionado);
        setAtivo(true);
        setSearchTerm('');
        name ? onChange(newValue, name) : onChange(newValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (!ativo && focused !== null && filteredOptions[focused]) {
                handleSelectChange(
                    event,
                    filteredOptions[focused].value,
                    filteredOptions[focused].name,
                );
            }
        } else if (event.key === "Escape") {
            event.preventDefault();
            setAtivo(true);
        }
    };

    const handleCloseDropdown = (e) => {
        if (
            selectedOptionRef.current &&
            !selectedOptionRef.current.contains(e.target)
        ) {
            setAtivo(true);
            document.removeEventListener("click", handleCloseDropdown);
        }
    };

    useEffect(() => {
        if (ativo) {
            setSearchTerm(''); // Resetar o termo de pesquisa quando fechar
            document.removeEventListener("click", handleCloseDropdown);
        } else {
            if (filteredOptions.length > 0) {
                setFocused(0);
            } else {
                setFocused(null);
            }
            document.addEventListener("click", handleCloseDropdown);
            inputRef.current?.focus();
        }
        return () => {
            document.removeEventListener("click", handleCloseDropdown);
        };
    }, [ativo]);

    // Remova este useEffect
    // useEffect(() => {
    //     if (filteredOptions.length > 0) {
    //         setFocused(0);
    //     } else {
    //         setFocused(null);
    //     }
    // }, [filteredOptions]);

    useEffect(() => {
        setSelecionado(
            options.find((option: any) => option.value === value)?.name,
        );
    }, [value, options]);

    return (
        <>
            <ViewSelect
                theme={theme}
                className={`view-select ${className ? className : ""}`}
                onMouseEnter={(event) => {
                    setHoverEffect(true);
                }}
                onMouseMove={(event) => {
                    setHoverEffect(true);
                }}
            >
                <PopoverForm mensagem={"Selecione um item da lista"} />
                <select
                    required={required}
                    className="selectAuxiliar"
                    tabIndex={-1}
                    disabled={disabled}
                    value={options.find((option: any) => option.value === value)?.name || ""}
                    onChange={(event) => {
                        name
                            ? onChange(event.target.value, name)
                            : onChange(event.target.value);
                        setSelecionado(event.target.value);
                    }}
                >
                    <option value="" disabled hidden>
                        {placeholder}
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value} disabled={disabled}>
                            {option.name}
                        </option>
                    ))}
                </select>
                {label && (
                    <div
                        className={`label-select ${alignLabel && alignLabel}`}
                        style={{ fontSize: fontLabel }}
                    >
                        {label}
                        {infoModal && (
                            <div className="infoModal" onClick={infoModal}>
                                <div className="infoModalIcon">
                                    <InfoSVG
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            infoModal(e);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div
                    tabIndex={0}
                    className={`${ativo ? "select" : "select ativo"}`}
                    ref={selectedOptionRef}
                    onClick={(e) => {
                        if (disabled) return;
                        setAtivo(!ativo);
                        if (!ativo && inputRef.current) {
                            inputRef.current.focus();
                        }
                    }}
                    onKeyDown={handleKeyDown}
                    style={
                        disabled
                            ? {
                                  cursor: "not-allowed",
                                  opacity: "0.5",
                              }
                            : {}
                    }
                >
                    {ativo ? (
                        <div
                            className={`${
                                selecionado ? "selecionado" : "placeholder"
                            }`}
                            onClick={(e) => {
                                if (disabled) return;
                                setAtivo(!ativo);
                                if (!ativo && inputRef.current) {
                                    inputRef.current.focus();
                                }

                                e.stopPropagation();
                            }}
                        >
                            {selecionado ? selecionado : placeholder}
                        </div>
                    ) : (
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value.length <= 60) {
                                    setSearchTerm(value);
                                }
                            }}
                            className="search-input"
                            placeholder={placeholder}
                            ref={inputRef}
                            maxLength={60} // Limitar a 60 caracteres
                            onKeyDown={(e) => {
                                if (["Enter", "Escape"].includes(e.key)) {
                                    handleKeyDown(e);
                                } else {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    )}
                    <div
                        className="icon"
                        onClick={(e) => {
                            if (disabled) return;
                            setAtivo(!ativo);
                            if (!ativo && inputRef.current) {
                                inputRef.current.focus();
                            }
                        }}
                    >
                        <AngleDown />
                    </div>
                </div>
                {!ativo && (
                    <div
                        className={`option ativo scrollBonito ${hoverEffect && "hoverEffect"}`}
                        style={{ maxHeight: `${optionsHeight}px` }}
                    >
                        {filteredOptions.map((option, index) => {
                            return (
                                <div
                                    key={index}
                                    onMouseOver={() => {
                                        setFocused(index);
                                    }}
                                    className={`optionsContainer ${
                                        index === focused ? "focused" : ""
                                    }`}
                                    onClick={(e) =>
                                        handleSelectChange(
                                            e,
                                            option.value,
                                            option.name,
                                        )
                                    }
                                >
                                    <div className="option-text">{option.name}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </ViewSelect>
        </>
    );
};
