import { useContext, useEffect } from "react";
import OneSignal from "react-onesignal";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import GlobalFonts from "./app_fonts";
import GlobalStyles from "./app_styles";
import BlipChatComponent from "./components/BlipChat/BlipChat";
import ImportacaoMain from "./components/ProdutosPlanilha/ImportacaoMain/ImportacaoMain";
import TabelaEdicao from "./components/ProdutosPlanilha/TabelaEdicao/TabelaEdicao";
import { AuthContext } from "./contexts/Auth/AuthContext";
import { CatalogoProvider } from "./contexts/Catalogo/CatalogoProvider";
import { ConfiguracoesProvider } from "./contexts/Configuracoes/ConfiguracoesProvider";
import { IntegracaoProvider } from "./contexts/Integracao/IntegracaoProvider";
import { ListasDePrecoProvider } from "./contexts/Listas/ListasDePrecoProvider";
import { PerfilProvider } from "./contexts/Perfil/PerfilProvider";
import { ProdutosProvider } from "./contexts/Produtos/ProdutosProvider";
import { RelatoriosProvider } from "./contexts/Relatorios/RelatoriosProvider";
import { useTheme } from "./contexts/Theme/ThemeContext";
import Catalogo from "./pages/Catalogos/Catalogo/Catalogo";
import Catalogos from "./pages/Catalogos/Catalogos";
import Cesta from "./pages/CestasAbandonadas/Cesta/Cesta";
import CestasAbandonadas from "./pages/CestasAbandonadas/CestasAbandonadas";
import Compartilhar from "./pages/Compartilhar/Compartilhar";
import Configuracoes from "./pages/Configuracoes/Configuracoes";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Novidades from "./pages/Novidades/Novidades";
import { PagSeguroAutorizado } from "./pages/PagSeguroAutorizado/PagSeguroAutorizado";
import Pedido from "./pages/Pedidos/Pedido/Pedido";
import Pedidos from "./pages/Pedidos/Pedidos";
import { Perfil } from "./pages/Perfil/Perfil";
import ImportarProdutos from "./pages/Produtos/ImportarProdutos/ImportarProdutos";
import InformacoesERP from "./pages/Produtos/InformacoesERP/InformacoesERP";
import ModosImportacao from "./pages/Produtos/ModosImportacao.tsx/ModosImportacao";
import Produto from "./pages/Produtos/Produto/Produto";
import Produtos from "./pages/Produtos/Produtos";
import Relatorios from "./pages/Relatorios/Relatorios";
import { PERMISSOES } from "./services/Consts";

function App() {
  const { user, permissoesUsuario, loading } = useContext(AuthContext);
  const location = useLocation();
  const { theme } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const isLocal = window.location.href.includes("localhost") || window.location.href.includes("dev");
    
    try {
      OneSignal.init({
        appId: isLocal ? "d784bf9a-1656-4987-a339-bcbb23b83e54" : "b5c71943-0f1c-45bd-8482-5c80451710d6",
        allowLocalhostAsSecureOrigin: isLocal,
        welcomeNotification: {
          disable: true
        },
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: 'push',
                autoPrompt: false,
                text: {
                  actionMessage: "Ativar notificações neste navegador?",
                  acceptButton: "Ativar",
                  cancelButton: "Não",
                }
              }
            ]
          }
        }
      });
    } catch (error) {
      console.log("Erro ao inicializar OneSignal", error);
    }
  }, []);

  return (
    <>
      <GlobalStyles theme={theme} />
      <GlobalFonts />
      <div className="App" id="App">
        {user !== null && user !== false && (
          <>
            <Routes>
              <Route path="/" element={<Dashboard />}>
                <Route
                  index
                  element={
                    <>
                      <Home />
                      <BlipChatComponent />
                    </>
                  }
                ></Route>
                <Route path="/compartilhar" element={<Compartilhar />}></Route>
                <Route path="/novidades" element={<Novidades />}></Route>
                <Route path="/painel/pedidos" element={<Pedidos />}></Route>
                <Route
                  path="/painel/pedidos/pedido/:id"
                  element={<Pedido />}
                ></Route>
                <Route
                  path="/painel/sacolas-abandonadas"
                  element={<CestasAbandonadas />}
                ></Route>
                <Route
                  path="/painel/sacolas-abandonadas/sacola-abandonada/:id/:name"
                  element={<Cesta />}
                ></Route>
                {(permissoesUsuario.includes(PERMISSOES.USUARIOS_GERENTE) ||
                  loading) && (
                  <Route
                    path="/perfil/usuarios"
                    element={
                      <PerfilProvider>
                        <Perfil />
                      </PerfilProvider>
                    }
                  ></Route>
                )}
                {(permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) ||
                  loading) && (
                  <Route
                    path="/painel/configuracoes/:anchor?"
                    element={
                      <ConfiguracoesProvider>
                        <IntegracaoProvider>
                          <Configuracoes />
                        </IntegracaoProvider>
                      </ConfiguracoesProvider>
                    }
                  ></Route>
                )}
                {(permissoesUsuario.includes(PERMISSOES.CATALOGOS) ||
                  loading) && (
                  <>
                    <Route
                      path="/painel/catalogos"
                      element={<Catalogos />}
                    ></Route>
                    <Route
                      path="/painel/catalogos/catalogo/:id/:name/:duplicated?"
                      element={
                        <ConfiguracoesProvider>
                          <CatalogoProvider>
                            <ProdutosProvider tipoFiltro="listagem-catalogo">
                              <Catalogo />
                            </ProdutosProvider>
                          </CatalogoProvider>
                        </ConfiguracoesProvider>
                      }
                    ></Route>
                  </>
                )}
                {(permissoesUsuario.includes(PERMISSOES.PRODUTOS) ||
                  loading) && (
                  <>
                    <Route
                      path="/painel/produtos/importar-imagens/:id?"
                      element={<ModosImportacao />}
                    />
                    <Route path="/painel/produtos/importar-produtos" element={<ImportarProdutos />}>
                      <Route 
                        index
                        element={<ImportacaoMain />}
                      />
                      <Route
                        path="ajustar"
                        element={<TabelaEdicao />}
                      />
                    </Route>
                    <Route
                      path="/painel/produtos"
                      element={
                        <ProdutosProvider tipoFiltro="listagem-padrao">
                          <ConfiguracoesProvider>
                            <Produtos />
                          </ConfiguracoesProvider>
                        </ProdutosProvider>
                      }
                    ></Route>
                    <Route
                      path="/painel/produtos/informacoes-erp"
                      element={
                        <InformacoesERP />
                      }
                    ></Route>
                    <Route
                      path="/painel/produtos/produto/:id"
                      element={
                        <ListasDePrecoProvider>
                          <ConfiguracoesProvider>
                            <Produto />
                          </ConfiguracoesProvider>
                        </ListasDePrecoProvider>
                      }
                    ></Route>
                  </>
                )}
                {(permissoesUsuario.includes(PERMISSOES.RELATORIOS) ||
                  loading) && (
                  <Route
                    path="/painel/relatorios/:id?"
                    element={
                      <RelatoriosProvider>
                        <Relatorios />
                      </RelatoriosProvider>
                    }
                  ></Route>
                )}
                <Route path="*" element={<Navigate to="/" />}></Route>{" "}
                {/* navegar pra home quando a rota nao for encontrada */}
              </Route>
              {/* -------------------------------------------------------------------------- */}
              <Route
                path="/pagseguro-autorizado"
                element={<PagSeguroAutorizado />}
              ></Route>
            </Routes>
          </>
        )}
        <>
          {user === false && (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />}></Route>
            </Routes>
          )}
        </>
      </div>
    </>
  );
}

export default App;
