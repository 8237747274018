import { createContext } from "react";

export type ScrollDirectionContextType = {
  direction: "up" | "down";
  position: "top" | "other" | "bottom";
};

export const ScrollDirectionContext = createContext<ScrollDirectionContextType>(
  null!,
);
