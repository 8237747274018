import { max } from "moment";
import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "1s";
const ContainerCatalogoUnicoSkeleton = styled.div`
  width: 100%;
  display: grid;
  gap: 10rem;
  grid-template-columns: 4fr 6fr;

  .grid1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 7rem 14rem 7rem 7rem 7rem 24rem;
    row-gap: 8rem;
    column-gap: 5rem;
    width: 100%;
    grid-template-areas:
      "zika0 zika0 zika1 zika1"
      "zika2 zika2 zika2 zika2"
      "zika3 zika3 zika4 zika4"
      "zika5 zika5 zika5 zika5"
      "zika6 zika6 zika7 zika7"
      "zika8 zika8 zika8 zika8";
  }

  .grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 7rem 7rem 7rem 7rem 7rem 24rem;
    row-gap: 8rem;
    column-gap: 5rem;
    width: 100%;
    grid-template-areas:
      "zika0 zika0 zika zika"
      "zika1 zika1 zika1 zika1"
      "zika2 zika2 zik zik"
      "zika3 zika3 zika4 zika4"
      "zika5 zika5 zika5 zika5";
  }

  .item-skeleton {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 16px;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.1s);
    @keyframes pulsate {
      0% {
        background-color: ${(props) => props.theme.colors.skeleton1};
      }

      100% {
        background-color: ${(props) => props.theme.colors.skeleton2};
      }
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export default ContainerCatalogoUnicoSkeleton;
