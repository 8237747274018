import styled, { keyframes } from "styled-components";
import theme from "../../Themes/light";

// Define the keyframes
const comeFromRightAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const comeFromLeftAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const opacityShowAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const opacityHideAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// Main Container
export const AsideMainContainer = styled.div`
  .aside-container {
    position: fixed;
    display: grid;
    grid-template-rows: 3% 6% 91%;
    flex-direction: column;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: 40px 0rem 0rem 40px;
    background: ${(props) => props.theme.colors.white};
    transition: all 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 211;
    text-align: left;
    padding: 6rem 8rem 14rem;
    font-size: 2.5rem;

    .titulo {
      color: ${(props) => props.theme.colors.brandSecondary};
      font-size: 3rem;
    }

    .subtitulo {
      font-size: 3.5rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.titleColor};
    }

    .children {
      height: 100%;
      position: relative;
    }

    .footer {
      position: absolute;
      bottom: 4rem;
      left: 8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3rem;
      pointer-events: all;
      flex-wrap: wrap;

      .cancelar {
        color: ${(props) => props.theme.colors.brandPrimary};
        cursor: pointer;
      }
    }
  }

  .aside-ativo {
    animation: ${comeFromRightAnimation} 0.3s ease-in-out;
    transform: translateX(0%);
  }

  .fundo {
    visibility: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 210;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: ${opacityHideAnimation} 0.3s ease-in-out;
  }

  .fundo-ativo {
    visibility: visible;
    animation: ${opacityShowAnimation} 0.3s ease-in-out;
    opacity: 1;
  }

  @media (max-width: 675px) {
    .aside-container {
      padding: 4rem 4rem 14rem;
      .footer {
        left: 4rem;
      }
    }
  }
`;
