import styled from "styled-components";
import theme from "../../../Themes/light";

export const MockupCatalogoContainer = styled.div`
  display: contents;
  .mockupCatalogo {
    position: relative;
    width: 100%;
    max-width: 60rem;
    min-width: 37rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    z-index: 1;
    overflow: hidden;
    border-radius: 50px 50px 0px 0px;

    .menuFundo {
      position: absolute;
      width: 100%;
      z-index: 200;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);

      .menu {
        height: 100%;
        width: 70%;
        border-radius: 0px 30px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 8rem 4rem;

        .logoContainer {
          background-color: ${theme.colors.mockup};
          width: 8rem;
          height: 8rem;
          border-radius: 50%;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .nomeDaMarca {
          width: 100%;
          text-align: center;
          margin-top: 2rem;
          font-weight: bold;
          font-size: 2rem;
          white-space: pre-wrap;
          word-wrap: break-word;
        }

        .descricao {
          width: 100%;
          text-align: justify;
          font-weight: 400;
          margin-top: 1.5rem;
          font-size: 1.5rem;
          white-space: pre-wrap;
          word-wrap: break-word;
        }

        .whatsInstaContainer {
          font-weight: 400;
          margin-top: 2rem;
          display: flex;
          font-size: 1.5rem;
          width: 100%;
          gap: 0.5rem;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          > span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
          }
        }

        .endereco {
          width: 100%;
          word-wrap: break-word;
          text-align: justify;
          font-weight: 400;
          margin-top: 2rem;
          font-size: 1.5rem;
          width: 100%;
        }
      }
    }

    .mockupContainer {
      width: 100%;
      max-height: 72rem;
      background-color: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: scroll;
      margin-top: 4rem;

      &::-webkit-scrollbar {
        width: 5px;
        display: block;
        background-color: none;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background-color: ${theme.colors.elements};
      }

      .cabecalho {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4rem;
        border-radius: 50px;
        z-index: 1;
      }

      .banner {
        width: 90%;
        min-height: 4rem;
        border-radius: 50px;
        margin-top: 2rem;
        background-color: ${theme.colors.mockup};
        opacity: 0.5;
      }

      .filtroVariacoes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 4px;
        margin-top: 1rem;

        .variacoes {
          display: flex;
          padding-left: 6rem;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 2rem;
          overflow: hidden;
          gap: 0.5rem;
          width: 100%;

          .porCategoria,
          .porVariacao {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            width: 100%;

            .categoria,
            .variacao {
              border-radius: 50px;
              min-width: 15rem;
              height: 1.5rem;
              background-color: ${theme.colors.mockup};
              opacity: 0.5;
            }

            .categoria:nth-child(2) {
              opacity: 1;
            }

            .variacao:nth-child(1) {
              opacity: 1;
            }
          }
        
        }
      }

      > .categorias {
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        height: 100%;
        width: 100%;
        left: 0;
        padding: 3rem 2rem 1rem 2rem;
        gap: 1rem;
        background: ${theme.colors.white};
        z-index: 10;

        .categoria {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 15px;

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(7) {
            grid-column-start: span 2;
          }

          .botaoCompartilhar {
            .botao {
              position: absolute;
              right: 1rem;
              top: 1rem;
              width: 3rem;
              height: 2rem;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: ${theme.colors.mockup};
            }
          }
        }
      }

      > .produtos {
        width: 100%;
        display: grid;
        gap: 1rem;

        > .produto {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;

          > .imagem {
            cursor: default;
            position: relative;
            width: 100%;
            min-height: 15rem; // min height in case the browser doesn't support aspect-ratio
            aspect-ratio: 1/1;

            > .fundoImagem {
              border-radius: 15px;
              width: 100%;
              height: 100%;
              opacity: 0.5;
              background-color: ${theme.colors.mockup};
            }

            .botaozinho {
              position: absolute;
              height: 2rem;
              width: 3rem;
              right: 8px;
              top: 8px;
              border-radius: 8px;
            }
          }

          .variacoes {
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: 100%;

            .variacao {
              position: relative;
              width: 100%;
              height: 2rem;
              display: flex;
              align-items: center;
              justify-content: center;

              .fundoVariacao {
                background-color: ${theme.colors.mockup};
                width: 100%;
                height: 100%;
                opacity: 0.5;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 6rem 0 1rem;

                .variacaoEstoque {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 100%;

                  .nomeVariacao {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.9rem;
                    font-weight: bold;
                    flex-grow: 1;
                    min-height: 50%;
                  }

                  .estoque {
                    color: ${theme.colors.brandSecondary};
                    min-height: 50%;
                    font-size: 1.9rem;
                  }
                }
              }

              .bolinha {
                position: absolute;
                min-height: 2rem;
                width: 2rem;
                align-self: center;
                top: 0;
                right: 0;

                .fundoBolinha {
                  border-radius: 50%;
                  width: 100%;
                  height: 100%;
                }
              }
            }
              .expandir{
            text-align: center;
            color: #afafaf;
            background-color: #f6f6f6;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            margin-top: 3px;
            font-size: 2rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: solid 2px;
          }
          }

          .botaoCompartilhar {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .botao {
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    @media (max-width: 520px) {
      height: 70rem;
    }
  }
`;
