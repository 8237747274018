import styled from "styled-components";
import theme from "../../../Themes/light";

const ContainerPrintableRelatorio = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    @page {
      margin: 35px 40px;
    }
    width: 100%;
    page-break-after: always;
    .flex-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0px 20px;
      margin-bottom: 20px;
      .print-logo {
        fill: ${theme.colors.brandSecondary} !important;
      }
      .data {
        font-size: 12px;
        color: ${theme.colors.brandSecondary} !important;
      }
    }
    .flex-center-align {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 20px 0;
      margin-bottom: 20px;
      gap: 10px;
      position: relative;
      .titulo {
        width: 100%;
        font-size: 20px;
        color: ${theme.colors.brandSecondary} !important;
        font-weight: bold;
        text-align: center;
      }
      .descricao {
        width: 100%;
        font-size: 12px;
        padding: 0 10%;
        color: ${theme.colors.brandSecondary} !important;
        text-align: center;
      }
      &::after {
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        background-color: ${theme.colors.placeholder} !important;
        border-radius: 50px;
        bottom: 0;
      }
    }
    table {
      width: 100%;
      font-size: 9px;
      thead {
        th {
          color: ${theme.colors.brandSecondary} !important;
          text-align: center;
          &:first-of-type {
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          position: relative;
          td {
            color: ${theme.colors.brandSecondary} !important;
            text-align: center;
            padding: 4px 0;
            .imagem {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              object-fit: contain;
            }
            &:first-of-type {
              text-align: left;
            }
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: ${theme.colors.placeholder} !important;
            border-radius: 50px;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
`;

export { ContainerPrintableRelatorio };
