import React, { useEffect, useState } from 'react';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { currencyMask } from '../../../services/Masks';
import { unmaskCurrency } from '../../../services/Unmasks';
import { Subtitulo, Titulo } from '../../../styled/Styled';
import { Campo, Dado } from '../../../types/ImportacaoPlanilha';
import { Button } from '../../Button';
import Form from '../../Form';
import { Input } from '../../Input';
import Quill from '../../Quill/Quill';
import { AjustarCampoContainer } from './styles';

interface AjustarCampoProps {
    campo: Campo | undefined;
    dado: Dado | undefined;
    onChange: (newValue: string) => void;
}

const toLocale = (value: string) => {
    return Number.parseFloat(value.replace(",", ".")).toLocaleString(
        "pt-br",
        {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    ).replaceAll('.', '');
}

const AjustarCampo: React.FC<AjustarCampoProps> = ({
    campo,
    dado,
    onChange
}) => {
    const {theme} = useTheme();
    const [value, setValue] = useState(dado?.value || '');
    const [formValid, setFormValid] = useState(false);
    
    
    useEffect(() => {
        let newValue = dado?.value || '';

        // Se o campo for preço ou número e o dado for inválido, significa que o valor é um texto, entao deve ser limpo pro input
        if((['price', 'decimal', 'integer'].includes(campo?.type || '')) && dado?.valid === false) newValue = '';

        // Se o campo for preço e o dado for válido, formata o valor para locale assim como na tabela de edição
        if(campo?.type === 'price' && dado?.valid) newValue = toLocale(newValue);

        setValue(newValue);
    }, [dado]);

    const onChangeInput = (newValue: string) => {
        setValue(newValue);
    }
    
    if(!campo || !dado) {
        return null;
    }

    return (
        <AjustarCampoContainer theme={theme}>
            <Form
                id='ajustar-campo-form'
                className='ajustar-campo-form'
                setFormValid={setFormValid}
                onSubmit={() => {
                    onChange(value)
                }}
            >    
                <div>
                    <div className='titulos'>
                        <Subtitulo theme={theme}>
                            Meus produtos / Adicionar produtos em massa
                        </Subtitulo>
                        <Titulo theme={theme} textAlign='left'>
                            Ajustar campo
                        </Titulo>
                    </div>
                </div>
                {campo.type === 'quill' ? (
                    <>
                        <Quill 
                            className="quill-editor-2"
                            formats={["bold", "italic", "underline", "list"]}
                            placeholder={campo.placeholder || ''}
                            required={false}
                            maxLength={200}
                            value={value}
                            onChange={onChangeInput}
                        />
                    </>
                ) : (
                    <Input 
                        onChange={onChangeInput}
                        mask={campo.type === 'price' ? {
                            mask: currencyMask,
                            unmask: unmaskCurrency
                        } : undefined}
                        maxLength={campo.validacao?.maxLength || undefined}
                        required={campo.validacao?.obrigatorio || false}
                        showMaxLength={campo.validacao?.maxLength ? true : false}
                        value={value}
                        type={'text'}
                        placeholder={campo.placeholder || ''}
                    />
                )}
                <div className="botao-container">
                    <Button
                        title='Salvar e continuar'
                        type='submit'
                        className={`${!formValid && "disabled"}`}
                    />
                </div>
            </Form>
        </AjustarCampoContainer >
    )
}

export default AjustarCampo;