import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useState } from "react";
import LoadingSRC from "../../../assets/jsons/Loading.json";
import { ReactComponent as GaleryIcon } from "../../../assets/newImages/icons/galery.svg";
import { ReactComponent as PlayIcon } from "../../../assets/newImages/icons/play.svg";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import { Image, MultipleImageUpload } from "../MultipleImageUpload.tsx";
import { LogoInputContainerProdutoWrapper } from "./styles.ts";

interface ImageUploadProdutosEmMassaProps {
  onChange: (newImage: Image[]) => void;
  images: Image[];
}

export const ImageUploadProdutosEmMassa: React.FC<
  ImageUploadProdutosEmMassaProps
> = ({ onChange, images }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { theme } = useTheme();

  const onDragIn = (e) => {
    setIsDragging(true);
  };

  const onDragOut = (e) => {
    setIsDragging(false);
  };

  const onDragDrop = (e) => {
    setIsDragging(false);
  };

  return (
    <>
      <LogoInputContainerProdutoWrapper theme={theme}>
        <MultipleImageUpload
          images={images}
          onLoading={(loading) => setLoading(loading)}
          className={`logo-input-container-produto`}
          acceptFormats={".png, .jpeg, .jpg, .webp"}
          onChange={onChange}
          onDragDrop={onDragDrop}
          errorMargin={50}
          aspectRatio={1 / 1}
          minWidth={800}
          minHeight={800}
        >
          <div
            className={`wrapper ${!isDragging && "dragging"}`}
            onDragEnter={onDragIn}
            onDragLeave={onDragOut}
            onDragOver={onDragIn}
          >
            <>
              {loading ? (
                <>
                  <Player
                    autoplay
                    loop
                    src={LoadingSRC}
                    style={{ height: "8rem", width: "8rem" }}
                  >
                    <Controls
                      visible={false}
                      buttons={["play", "repeat", "frame", "debug"]}
                    />
                  </Player>
                </>
              ) : (
                <>
                  <div className="icones">
                    <GaleryIcon
                      className={`galery-icon ${isDragging && "galery-ativo"}`}
                    />
                    <PlayIcon
                      className={`play-icon ${isDragging && "play-ativo"}`}
                    />
                  </div>
                  <div className="texto-wrapper">
                    <div
                      className="texto"
                      style={{ opacity: !isDragging ? "1" : "0" }}
                    >
                      Clique aqui ou arraste um arquivo para carregar
                    </div>
                    <div
                      className="texto"
                      style={{ opacity: isDragging ? "1" : "0" }}
                    >
                      Solte o arquivo aqui
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </MultipleImageUpload>
        <div className="linha_">
          <div></div>
        </div>
      </LogoInputContainerProdutoWrapper>
    </>
  );
};
