import React, { useState, useEffect } from "react";
import "./styles.ts";
import ContainerTabs from "./styles.ts";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";

interface TabsProps {
  tabs: { name: string; value: string }[];
  tabsNumber?: number;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  disabled?: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  tabsNumber = 3,
  value,
  onChange,
  className,
  disabled,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const { theme } = useTheme();

  useEffect(() => {
    setActiveTab(value);
  }, [value]);

  return (
    <>
      <ContainerTabs className={` ${className ? className : ""}`} theme={theme}>
        <div
          className="tabs"
          style={{ gridTemplateColumns: `repeat(${tabsNumber}, 1fr)` }}
        >
          {tabs.map((tab, index) => {
            return (
              <div
                id={tab.value}
                className={`tab ${tab.value === activeTab && "active-tab"}`}
                onClick={() => onChange(tab.value)}
              >
                <div className={`tab-name`}>{tab.name}</div>
              </div>
            );
          })}
        </div>
      </ContainerTabs>
    </>
  );
};

export default Tabs;
