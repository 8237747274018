import React from "react";
import { CustomPicker } from "react-color";
import {
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";
import { ColorPickerContainer, HexInputContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { hexadecimalMask } from "../../services/Masks";
import { Input } from "../Input";

export const MyPicker = ({ hex, hsl, hsv, onChange, color, onChangeInput }) => {
  const { theme } = useTheme();

  const onChangeEditableInput = (newValue: string) => {
    onChangeInput(newValue);
  };

  return (
    <>
      <ColorPickerContainer>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "2rem",
          }}
        >
          <div
            style={{
              width: 150,
              height: 150,
              position: "relative",
              MozWindowDragging: "no-drag",
              cursor: "crosshair",
            }}
          >
            <Saturation
              hsl={hsl}
              hsv={hsv}
              onChange={onChange}
              pointer={() => {
                return (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      border: `2px solid white`,
                      outline: "1px solid black",
                      backgroundColor: "trasparent",
                      position: "absolute",
                      top: "-5px",
                      left: "-5px",
                      cursor: "crosshair",
                      MozWindowDragging: "no-drag",
                    }}
                  ></div>
                );
              }}
            />
          </div>
          <div
            style={{
              height: 150,
              width: 20,
              position: "relative",
            }}
          >
            <Hue
              direction={"vertical"}
              hsl={hsl}
              onChange={onChange}
              pointer={() => {
                return (
                  <div
                    style={{
                      width: 20,
                      height: 5,
                      backgroundColor: "#FFFFFF",
                      border: `1px solid black`,
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                    }}
                  ></div>
                );
              }}
            />
          </div>
        </div>
        <HexInputContainer>
          <div className="label">Hex</div>
          <div className="input-hex-container">
            <Input
              value={hexadecimalMask(color)}
              type="text"
              onChange={onChangeEditableInput}
              mask={{
                mask: hexadecimalMask,
              }}
              placeholder="#000000"
            />
          </div>
        </HexInputContainer>
      </ColorPickerContainer>
    </>
  );
};

export default CustomPicker(MyPicker);
