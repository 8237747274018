import styled, { keyframes } from 'styled-components';
import { ThemeType } from '../../types/Theme';

const rotate45deg = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(45deg);
    }
`;

const BlipChatContainer = styled.div<{theme: ThemeType}>`
    position: fixed;
    bottom: 10rem;
    right: 3rem;
    .relative-container{
        position: relative;
        width: 100%;
        height: 100%;
        .floating-button{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 8rem;
            height: 8rem;
            z-index: 50;
            transition: all 0.3s ease;
            border-radius: 50%;
            background-color: ${({theme}) => theme.colors.brandPrimary};
            box-shadow:
            rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
            rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
            transform: translateY(0%) translateX(0%) scale(1);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:active{
                transform: scale(0.9)
            }
            .blip-icon-container{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .blip-icon{
                    width: 75%;
                    height: 75%;
                    fill: ${({theme}) => theme.colors.white};
                }
                .plus-icon{
                    width: 50%;
                    height: 50%;
                    fill: ${({theme}) => theme.colors.white};
                    animation: ${rotate45deg} 0.3s ease forwards;
                }
            }
            .chat-container{
                transform: translateY(10%);
                opacity: 0;
                pointer-events: none;
                transition: all 0.3s ease;
                position: absolute;
                bottom: 10rem;
                right: 0;
                width: 70rem;
                max-width: calc(90vw - 3rem);
                height: 100rem;
                max-height: calc(100vh - 30rem);
                user-select: none;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
                border-radius: 3rem;
                overflow: hidden;
            }
            .active{
                transform: translateY(0);
                opacity: 1;
                pointer-events: all;
            }
        }
        .hidden-bottom{
            transform: translateY(100%) translateX(25%) scale(0);
        }
    }
    @media (min-width: 1024px) {
        bottom: 5rem;
        right: 5rem;
        .relative-container{
            .floating-button{
                width: 8rem;
                height: 8rem;
                .chat-container{
                    bottom: 12rem;
                }
            }
        }
    }
`;

export {
    BlipChatContainer
};
