import React, { useContext, useEffect, useState, useRef } from "react";
import { Center, Grids, ContainerMelhorEnvio } from "./styles";
import { ModalContainer } from "../IntegracaoBlingV2/styles";
import { Button } from "../../Button";
import LoadingComFundo from "../../Loading/LoadingComFundo/LoadingComFundo";
import SkeletonBling from "../../Skeletons/SkeletonBling/SkeletonBling";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { Input } from "../../Input";
import { MelhorEnvioApi } from "../../../hooks/melhorEnvio";
import Swal from "sweetalert2";
import Modal from "../../Modal/Modal";
import { hostname, baseURL } from "../../../hooks/useApi";

const MelhorEnvio: React.FC = () => {
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const { theme } = useTheme();
  const api = MelhorEnvioApi();
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const [loading, setLoading] = useState(true);
  const [modalAutorizacao, setModalAutorizacao] = useState(true);
  const nomeUsuario = empresa?.usuario;
  const [autenticado, setAutenticado] = useState(false);

  const [urlBase, setUrlBase] = useState("");
  const [urlRedirecionamento, setUrlRedirecionamento] = useState(
    "https://painel-api.conectavenda.com.br/melhor_envio/autenticacao"
  );
  const [urlMelhorEnvio, setUrlMelhorEnvio] = useState("");
  const [clientId, setClientId] = useState(14208);
  const [secret, setSecret] = useState(
    "0387SJjIYo7uNzds8Szwb22GMxMRpCzOS48gYauw"
  );

  const cancelar = () => {
    setModalAutorizacao(false);

    setConfiguracoes({
      ...configuracoes,
      dados_bling_v2: {
        ...configuracoes.dados_bling_v2!,
        ativo: false,
      },
    });
  };

  const verificarAutenticacao = async () => {
    const res = await api.verificarAutenticacao();

    if (res.ativo) {
      setAutenticado(true);
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChangeRef.current
      );
    } else {
      if (res.error) {
        if (res.url) {
          setAutenticado(false);
        } else {
          Swal.fire({
            title: "Erro inesperado ao verificar autenticação!",
            text: res.error,
            icon: "error",
          });
        }
      }
    }
    setLoading(false);
  };

  const handleVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      await verificarAutenticacao();
    }
  };

  const handleVisibilityChangeRef = useRef(() => {});

  useEffect(() => {
    setUrlRedirecionamento(`${urlBase}melhor_envio/autenticacao`);
  }, [urlBase]);

  useEffect(() => {
    handleVisibilityChangeRef.current = handleVisibilityChange;

    const init = async () => {
      handleVisibilityChangeRef.current();
    };

    init();

    if (
      hostname == "localhost" ||
      hostname == "painel-api-dev.conectavenda.com.br"
    ) {
      setUrlBase("https://painel-api-dev.conectavenda.com.br/");
      setUrlMelhorEnvio("https://sandbox.melhorenvio.com.br/");
      setClientId(4516);
      setSecret("ooGhzgKGJroF31JyUyRxP4Jiqj6aq3H79YEPHXXv");
    } else {
      setUrlBase(baseURL);
      setUrlMelhorEnvio("https://melhorenvio.com.br/");
    }

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChangeRef.current
    );

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChangeRef.current
      );
    };
  }, []);

  return (
    <ContainerMelhorEnvio theme={theme}>
      {!autenticado && !loading && (
        <>
          <Modal
            width={"80rem"}
            maxWidth="98vw"
            isOpen={modalAutorizacao}
            backdrop={"static"}
            setModal={setModalAutorizacao}
          >
            <ModalContainer theme={theme}>
              {loading ? (
                <LoadingComFundo />
              ) : (
                <>
                  <div className="titulo">Autorização Melhor Envio</div>
                  <div className="texto">
                    Para continuar, é necessário autorizar a integração com o
                    Melhor Envio. Clique no botão abaixo para ser redirecionado
                    para a página de autorização.
                  </div>
                  <div className="botoes">
                    <div className="cancelar" onClick={cancelar}>
                      Cancelar
                    </div>
                    <a
                      href={`${urlMelhorEnvio}oauth/authorize?client_id=${clientId}&redirect_uri=${urlRedirecionamento}&response_type=code&state=${nomeUsuario}-conecta-${clientId}-conecta-${secret}&scope=cart-read cart-write companies-read companies-write coupons-read coupons-write notifications-read orders-read products-read products-write purchases-read shipping-calculate shipping-cancel shipping-checkout shipping-companies shipping-generate shipping-preview shipping-print shipping-share shipping-tracking ecommerce-shipping transactions-read users-read users-write`}
                      target="_blank"
                    >
                      <Button title="Autorizar" />
                    </a>
                  </div>
                </>
              )}
            </ModalContainer>
          </Modal>
        </>
      )}
      {loading || !autenticado ? (
        <SkeletonBling />
      ) : (
        <>
          <Grids>
            <div className="containerInput">
              <Input
                maxLength={5}
                showMaxLength={false}
                name="Altura"
                type="number"
                value={configuracoes.frete.melhorEnvio.altura}
                required={true}
                placeholder={"0"}
                label="Altura"
                alignLabel="left"
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      melhorEnvio: {
                        ...configuracoes.frete.melhorEnvio,
                        altura: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
            <div className="containerInput">
              <Input
                maxLength={5}
                type="number"
                showMaxLength={false}
                name="Largura"
                label="Largura"
                alignLabel="left"
                value={configuracoes.frete.melhorEnvio.largura}
                required={true}
                placeholder={"0"}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      melhorEnvio: {
                        ...configuracoes.frete.melhorEnvio,
                        largura: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
            <div className="containerInput">
              <Input
                maxLength={5}
                showMaxLength={false}
                required={true}
                name="comprimento"
                type="number"
                label="Comprimento"
                alignLabel="left"
                value={configuracoes.frete.melhorEnvio.comprimento}
                placeholder={"0"}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      melhorEnvio: {
                        ...configuracoes.frete.melhorEnvio,
                        comprimento: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
          </Grids>
        </>
      )}
    </ContainerMelhorEnvio>
  );
};

export default MelhorEnvio;
