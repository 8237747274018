import { useContext } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Textarea } from "../../Textarea";
import { ContainerCompartilhamento } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface CompartilhamentoProps {
  configIn: "empresa" | "configuracoes";
}

export const Compartilhamento: React.FC<CompartilhamentoProps> = ({
  configIn,
}) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const { theme } = useTheme();

  return (
    <>
      <ContainerCompartilhamento configIn={configIn} theme={theme}>
        {configIn === "empresa" && (
          <Titulo
            titulo="Compartilhamento"
            subtitulo={"Configure seu catálogo"}
          />
        )}
        <div className="informacoes">
          <div className="descricao">
            Personalize a mensagem que é enviada junto{" "}
            {configIn === "empresa" && <br />}
            ao link de compartilhamento do seu catálogo.
          </div>
          <div className="mensagemLink">
            <div className="tituloMensagem">Mensagem do link</div>

            <Textarea
              sizeLabel="medium"
              type="text"
              maxLength={250}
              name="descricao"
              value={configuracoes.mensagem_whats}
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  mensagem_whats: newValue,
                });
              }}
              placeholder="Acesse a lista de produtos selecionados que fizemos para você:"
              error=""
            />
          </div>
        </div>
      </ContainerCompartilhamento>
    </>
  );
};
