import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "1s";
const SkeletonTableContainer = styled.div`
  width: 100%;
  margin-top: 10rem;

  .mockupSituacoes {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
    justify-content: space-between;
    gap: 1rem;

    > div {
      width: 100%;
      height: 6rem;
      margin-bottom: 1rem;
      background-color: ${(props) => props.theme.colors.background1};
      border-radius: 10px;
      animation: pulsate ${transitionTime} ease-out infinite alternate;
      animation-delay: calc(var(--i) * 0.1s);
    }
  }

  > table {
    width: 100%;
    margin-top: 10rem;

    > tbody {
      width: 100%;

      > tr {
        width: 100%;
        height: 6rem;
        color: transparent;

        > td {
          background: inherit;
          border-radius: 10px;
        }

        > :first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        > :last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        .buttonTable {
          background-color: ${(props) => props.theme.colors.elements};
          font-weight: normal;
          color: ${(props) => props.theme.colors.brandSecondary};
        }
      }

      > :nth-child(2n + 1) {
        background-color: ${(props) => props.theme.colors.white};
        animation: pulsate ${transitionTime} ease-out infinite alternate;
        animation-delay: calc(var(--i) * 0.2s);
      }

      > :nth-child(2n) {
        background-color: ${(props) => props.theme.colors.background1};
      }

      .selected {
        background-color: rgba(164, 164, 164, 0.2);
      }
    }
  }
`;

export default SkeletonTableContainer;
