import styled from "styled-components";

export const InfiniteContainer = styled.div`
  display: contents !important;
  width: 100%;
  height: 100%;

  .component-infinite {
    width: 100%;
    height: 100%;
  }
`;
