const darkTheme = {
  mode: "dark",
  colors: {
    white: "#303030",
    black: "#222222",
    brandSuccess: "#a6c733",
    brandInfo: "#57bdda",
    brandWarning: "#e5c649",
    brandDanger: "#ed7878",
    brandPrimary: "#36945C",
    brandSecondary: "#E1E1E1",
    titleColor: "#FF6E28",
    placeholder: "#939090",
    elements: "#434343",
    modal: "#242526",
    background1: "#242526",
    background2: "#303030",
    mockup: "#D9D9D9",
    skeleton1: "#242526",
    skeleton2: "#303030",
  },
};

export default darkTheme;
