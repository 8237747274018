import { useMemo, useState } from "react";
import { Arquivo, Campo, CampoType, EtapaImportacao, OptionsValidarCampo, SeparatorTypes } from "../../types/ImportacaoPlanilha";
import { ProdutosPlanilhaContext } from "./ProdutosPlanilhaContext";

interface ProdutosPlanilhaProviderProps {
  children: JSX.Element;
}

const campos: Campo[] = [
  {
    value: "nome",
    label: "Nome",
    obrigatorio: true,
    type: "text",
    placeholder: "Preencher",
    validacao: {
      obrigatorio: true,
      maxLength: 64,
    }
  },
  {
    value: "sku",
    label: "SKU",
    type: "text",
    obrigatorio: true,
    placeholder: "Preencher",
    validacao: {
      obrigatorio: true,
      maxLength: 64,
    }
  },
  {
    value: "variacao",
    label: "Variação",
    type: "text",
    placeholder: "-",
    obrigatorio: false,
    validacao: {
      maxLength: 64,
    }
  },
  {
    value: "preco",
    label: "Preço",
    type: "price",
    element: (dado) => {
      return (
        <>
          {dado.valid
            ? Number.parseFloat(dado.value.replace(",", ".")).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )
            : dado.value}
        </>
      );},
    placeholder: "Sob consulta",
    obrigatorio: false,
  },
  {
    value: "peso_g",
    label: "Peso (g)",
    type: "decimal",
    placeholder: "Preencher",
    obrigatorio: false,
    validacao: {
      obrigatorio: true,
    }
  },
  {
    value: "categoria",
    label: "Categoria",
    type: "text",
    obrigatorio: true,
    placeholder: "Preencher",
    validacao: {
      obrigatorio: true,
      maxLength: 64,
    }
  },
  {
    value: "descricao",
    label: "Descrição",
    type: "quill",
    element: (dado) => {
      return(
        <div className="descricao" dangerouslySetInnerHTML={{__html: dado.value}}></div>
      )
    },
    placeholder: "-",
    obrigatorio: false,
  },
  {
    value: "estoque",
    label: "Estoque",
    type: "integer",
    placeholder: "Sem controle de estoque",
    obrigatorio: false,
  },
  {
    value: "multiplicador",
    label: "Multiplicador",
    type: "integer",
    placeholder: "0",
    obrigatorio: false,
  },
  {
    value: "quantidade_minima",
    label: "Quantidade mínima",
    type: "integer",
    placeholder: "0",
    obrigatorio: false,
  },
];

const limparCampo = (campo: string) => {
  //remover ç, acentos, troca espaços por underline, remove paranteses e coloca tudo em minusculo, pra comparar com o value do select
  if(!campo) return '';
  return campo.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '_').replace(/[()]/g, '').toLowerCase();
}

export const ProdutosPlanilhaProvider: React.FC<ProdutosPlanilhaProviderProps> = ({
  children
}) => {

  const [etapa, setEtapa] = useState<EtapaImportacao>("importar-arquivo");
  const [arquivo, setArquivo] = useState<Arquivo>({ name: "", content: "" });
  const [colunas, setColunas] = useState<string[]>([]);
  const [dados, setDados] = useState<string[][]>([]);
  const [separator, setSeparator] = useState<SeparatorTypes | "">("");
  const [tabelaSelecionada, setTabelaSelecionada] = useState<string>("");
  const [removerProdutos, setRemoverProdutos] = useState<boolean>(false);
  const [slidingActive, setSlidingActive] = useState<boolean>(false);


  const onLoadArquivo = async () => {
    //o replace é para remover as aspas do csv, que são adicionadas automaticamente
    //quando o campo tem caracter especial
    //tambem remove o \r, que é adicionado no final de cada linha em windows
    let rows: string[][] = 
      arquivo.content
        .split('\n')
        .map(row => row.split(separator || ';').map(value => value.replace(/^\"|\"$/g, '').replace(/\r$/, '')));
    
    //quando o result acaba com um \n, a última linha se torna um array com um elemento vazio
    //então é preciso remover essa linha
    if(rows[rows.length - 1].length === 1 && rows[rows.length - 1][0].trim() === '') {
      rows.pop();
    }

    let biggestRow = rows.reduce((biggest, row) => row.length > biggest ? row.length : biggest, 0);
    //preenche as linhas com valores vazios, para que todas tenham o mesmo tamanho
    rows.forEach(row => {
      while(row.length < biggestRow || row.length < campos.length) {
        row.push('');
      }
    });

    setColunas(rows[0]);
    setDados(rows.slice(1));
  };

  const validarCampo = (value: string, options: OptionsValidarCampo | undefined, type: CampoType) => {
    if (options && options.obrigatorio && value.trim() === "") {
      return false;
    }

    if ((['price', 'decimal', 'integer'].includes(type)) && isNaN(Number(value.replace(",", ".")))) {
      return false;
    }

    if(type === 'integer' && !Number.isInteger(Number(value.replace(",", ".")))) {
      return false;
    }

    if(options) {
  
      if (options.maxLength && value.length > options.maxLength) {
        return false;
      }
  
      if (options.minLength && value.length < options.minLength) {
        return false;
      }
  
      if (options.max && Number(value.replace(",", ".")) > options.max) {
        return false;
      }
  
      if (options.min && Number(value.replace(",", ".")) < options.min) {
        return false;
      }
    } 

    return true;
  }

  //montar os campos mapeados conforme os indexes, e garantir que nao haverá duplicidade
  const mappedCampos = useMemo(() => colunas.map(limparCampo).map((cleanColuna, index, self) => {
    // se o campo não for encontrado, ou se for encontrado e for o PRIMEIRO campo com esse valor
    // retorna o campo, do contrario, o campo fica undefined
    return campos.find((campo) => (campo.value === cleanColuna && self.indexOf(cleanColuna) === index));
  }), [colunas, campos]);

  //campos que podem ser selecionados no select
  //adicionar um campo "Nenhum" no final
  const camposSelect = useMemo(() => [
    ...campos.map(campo => ({
      name: campo.label + (campo.obrigatorio ? " *" : ""),
      value: campo.value
    })),
    {
      name: "Nenhum",
      value: "nenhum"
    }
  ], [campos]);

  const dadosValidados = useMemo(() => dados.map((row, indexCol) => {
    return row.map((value, indexRow) => {
      const disabled = mappedCampos[indexRow] === undefined || mappedCampos[indexRow]?.value === 'nenhum';
      if (disabled) {
        return { value, valid: true, disabled: true, indexRow: indexRow, indexCol: indexCol};
      }
      const valid = validarCampo(value, mappedCampos[indexRow]!.validacao, mappedCampos[indexRow]!.type);

      return { value, disabled: false, valid, indexRow: indexRow, indexCol: indexCol};
    });
  }), [dados, mappedCampos]);

  // checar se todos os campos obrigatorios foram selecionados, se nao, os campos com 'Nenhum' devem ser marcados como invalidos (vermelho)
  const qtdCamposObrigatoriosFaltantes = useMemo(() => campos
  .filter(campo => campo.obrigatorio)
  .map(campo => campo.value)
  .filter(campo => !mappedCampos.map(mappedCampo => mappedCampo?.value).includes(campo)).length, [campos, mappedCampos]);

  const qtdDadosInvalidos = useMemo(() => dadosValidados.filter(linha => linha.some(dado => !dado.valid)).length, [dadosValidados]);

  const qtdDadosValidos = useMemo(() => qtdCamposObrigatoriosFaltantes > 0 ? 0 : dadosValidados.filter(linha => linha.every(dado => dado.valid)).length, [qtdCamposObrigatoriosFaltantes, dadosValidados]);

  const qtdColunasInvalidas = useMemo(() => qtdCamposObrigatoriosFaltantes > 0 ?
    mappedCampos.filter(campo => campo === undefined || campo.value === 'nenhum').length :
    mappedCampos.filter((campo, index) => campo === undefined && colunas[index] !== '' && colunas[index] !== 'nenhum').length, [qtdCamposObrigatoriosFaltantes, mappedCampos, colunas]);

  return (
    <ProdutosPlanilhaContext.Provider
      value={{
        etapa,
        setEtapa,
        mappedCampos,
        camposSelect,
        dadosValidados,
        qtdCamposObrigatoriosFaltantes,
        qtdDadosInvalidos,
        qtdDadosValidos,
        qtdColunasInvalidas,
        onLoadArquivo,
        colunas,
        arquivo,
        setArquivo,
        setColunas,
        separator,
        setSeparator,
        dados,
        setDados,
        campos,
        tabelaSelecionada,
        setTabelaSelecionada,
        removerProdutos,
        setRemoverProdutos,
        slidingActive,
        setSlidingActive
      }}
    >
      {children}
    </ProdutosPlanilhaContext.Provider>
  );
};
