import styled from "styled-components";

const ViewPerfil = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vh - 12rem);
  padding: 5rem 5rem 5rem 10rem;
  font-size: 2.5rem;
  gap: 10rem;
  display: grid;
  grid-template-columns: calc(40% - 2px - 4rem) 2px calc(50% - 4rem);

  @media (max-width: 1024px) {
    padding: 5rem 5rem 18rem 5rem;
    grid-template-columns: 100%;
    gap: 0;
  }
`;

const ViewModalPermissoes = styled.div`
padding: 0 5%;
.titulo{
  font-size: 2.5rem;
  text-align: left;
  font-weight: bold;
}
.texto{
  text-align: left;
  margin-bottom: 3rem;
}
`;

const Middle = styled.div`
  width: 100%;
  height: 80%;
  background-color: ${(props) => props.theme.colors.elements};
  align-self: center;
`;

const LeftRight = styled.div`
  gap: 2rem;
  position: relative;
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.brandSecondary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .edicao-usuario {
    > .titulo {
      text-align: left;
      font-size: 3rem;
      color: ${(props) => props.theme.colors.titleColor};
      font-weight: bold;
    }

    .titulo_input {
      text-align: left;
      margin: 2rem 0 2rem 0;
      color: ${(props) => props.theme.colors.brandSecondary};
    }
    .campos {
      .erro {
        text-align: left;
        color: ${(props) => props.theme.colors.brandDanger};
      }

      .alterar {
        display: grid;
        grid-template-columns: 90% 10%;
        align-items: center;

        &.novo {
          grid-template-columns: 100%;
        }

        .botao_alterar {
          text-align: right;
          color: ${(props) => props.theme.colors.brandPrimary};
          cursor: pointer;
        }
        @media (max-width: 385px) {
          grid-template-columns: 80% 20%;
        }
      }

      .select {
        margin-top: 2rem;
      }
    }

    .campos > * {
      margin-top: 20px;
    }

    .botoes_ {
      display: flex;
      margin-top: 8rem;
      font-size: 2.5rem;
      justify-content: space-between;
      align-items: center;

      .novo_usuario {
        color: ${(props) => props.theme.colors.brandPrimary};
        cursor: pointer;
      }
    }

    .linha_abaixo {
      margin-top: 10rem;
      width: 100%;
      height: 2px;
      background-color: ${(props) => props.theme.colors.elements};
    }

    .titulo_assinatura {
      margin-top: 10rem;
      text-align: left;
      font-size: 3rem;
      color: ${(props) => props.theme.colors.titleColor};
      font-weight: bold;
    }

    .alterar {
      display: grid;
      grid-template-columns: 90% 10%;
      align-items: center;

      .botao_alterar {
        text-align: right;
        color: ${(props) => props.theme.colors.brandPrimary};
        cursor: pointer;
        margin-left: 10px;
        margin-top: 20px;
      }

      .botao_excluir {
        text-align: right;
        color: ${(props) => props.theme.colors.brandPrimary};
        cursor: pointer;
        margin-left: 10px;
        margin-top: 29px;
      }
    }
  }

  .linha {
    display: flex;
    justify-content: center;

    div {
      width: 2px;
      height: 100%;
      border-radius: 10px;
      background-color: ${(props) => props.theme.colors.elements};
      box-shadow: 0 0 10px ${(props) => props.theme.colors.elements};
    }
  }
`;

export { ViewPerfil, Middle, LeftRight, ViewModalPermissoes };
