import React, { useContext } from "react";
import Stepper from "./Stepper/Stepper";
import Modal from "../Modal";
import { Button } from "../../Button";
import { ContentContainer } from "./styles";

import { ConfigsPrimeiroAcessoContext } from "../../../contexts/ConfigsPrimeiroAcesso/ConfigsPrimeiroAcessoContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { rgba } from "../../../services/Functions";

const ModaisConfigsPrimeiroAcesso: React.FC<any> = () => {
  const { modal, setModal, modalAtivo, setModalAtivo, modais } = useContext(
    ConfigsPrimeiroAcessoContext,
  );

  const { theme } = useTheme();

  return (
    <>
      <ContentContainer>
        <Modal
          isOpen={modal}
          setModal={setModal}
          width={modalAtivo.width}
          align={modalAtivo.align}
          justify={modalAtivo.justify}
          animation={modalAtivo.animation}
          margin={modalAtivo.margin}
          backdrop={"static"}
          background={{
            backgroundColor: `rgba(46, 46, 46, 0.2)`,
            backdropFilter: "blur(8px)",
          }}
        >
          {modalAtivo.localizador === "parabens" && (
            <ContentContainer className="contentContainer">
              {React.createElement(modalAtivo.component)}
              <div className="buttonContainer">
                <Button
                  onClick={modalAtivo.onAvancar}
                  type="button"
                  title="Bora começar a vender?"
                ></Button>
              </div>
            </ContentContainer>
          )}
          {modalAtivo.localizador !== "parabens" && (
            <Stepper
              array={modais}
              modalAtivo={modalAtivo}
              simNao={modalAtivo.simNao}
              salvarCancelar={modalAtivo.salvarCancelar}
              avancarText={modalAtivo.avancarText}
              onAvancar={modalAtivo.onAvancar}
              voltarText={modalAtivo.voltarText}
              onVoltar={modalAtivo.onVoltar}
              pularText={modalAtivo.pularText}
              onPular={modalAtivo.onPular}
              cancelarText={modalAtivo.cancelarText}
              onCancelar={modalAtivo.onCancelar}
              salvarText={modalAtivo.salvarText}
              onSalvar={modalAtivo.onSalvar}
            >
              <div className="contentContainer">
                {React.createElement(modalAtivo.component)}
              </div>
            </Stepper>
          )}
        </Modal>
      </ContentContainer>
    </>
  );
};

export default ModaisConfigsPrimeiroAcesso;
