import styled from "styled-components";


export const ViewContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: 100rem;
  padding: 5rem 10rem 30rem 10rem;
  font-size: 2.5rem;
  gap: 4rem;
  display: grid;
  grid-template-columns: calc(50% - 2px - 4rem) 2px calc(50% - 4rem);
  grid-template-rows: 100%;
  @media (max-width: 1025px) {
    display: inline;
    padding: 5rem 1rem 30rem 1rem;
  }

  .middle {
    width: 100%;
    height: 80%;
    background-color: ${(props) => props.theme.colors.elements};
    align-self: center;
  }

  .left,
  .right {
    gap: 2rem;
    position: relative;
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 1024px) {
      padding: 0 3rem;
      &.ativo{
        padding: 0;
      }
    }

    .botoes {
      padding: 0;
      display: flex;
      align-items: flex-end;
      height: 100%;
      justify-content: space-between;
      .salvar {
        .botao_avancado {
          cursor: pointer;
          margin: 15px 0;
          color: ${(props) => props.theme.colors.brandPrimary};
        }
      }
      .voltar {
        cursor: pointer;
      }
    }

    .midias {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      grid-gap: 20px;

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        width: 100%;
        max-width: 100%;
        height: 100%;

        .adicionar_produto {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: ${(props) => props.theme.colors.brandPrimary};
          border-radius: 15px;

          .plus {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }

      .imagem {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          border-radius: 15px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .icone-apagar {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 2px;
          background-color: #303030;
          right: 1rem;
          top: 1rem;
          &.visivel {
            display: flex;
          }

          &.invisivel {
            display: none;
          }

          cursor: pointer;
          svg {
            height: 3rem;
            width: auto;
            fill: #e1e1e1;
            transform: rotate(45deg);
          }
        }

        .icone-esquerda {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 2px;
          left: 1rem;
          top: calc(50% - 3px);
          cursor: pointer;

          &.visivel {
            display: flex;
          }

          &.invisivel {
            display: none;
          }
          svg {
            height: 3rem;
            width: auto;
            fill: #e1e1e1;
          }
        }

        .icone-direita {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 2px;
          right: 1rem;
          top: calc(50% - 3px);
          cursor: pointer;

          &.visivel {
            display: flex;
          }

          &.invisivel {
            display: none;
          }
          svg {
            height: 3rem;
            transform: rotate(180deg);
            width: auto;
            fill: #e1e1e1;
          }
        }
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .cadastro {
      width: 100%;

      .tem-variacao {
        width: 100%;
        border-radius: 15px;
        border: solid 1px
          ${(props) => (props.theme.mode == "light" ? "#dbdbdb" : "#434343")};
        padding: 10px 5px;
        margin-bottom: 15px;

        .topo {
          display: flex;
          padding-left: 15px;
          align-items: center;
          color: ${(props) =>
    props.theme.mode == "light"
      ? "#434343"
      : props.theme.colors.brandSecondary};

          div {
            margin-right: 10px;
          }
        }

        .texto-variacao {
          margin-top: 10px;
          text-align: left;
          font-size: 13px;
          padding-left: 15px;
        }
      }

      .textarea {
        .label{
          font-size: 2.5rem;
          width: 100%;
    font-weight: normal;
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    align-items: center;
    text-align: justify;
    white-space: normal;
        }
        margin-top: 10px;
      }

      .sku-peso {
        display: flex;
        justify-content: space-between;
        grid-gap: 2rem;
        margin-top: 5rem;

        .sku {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          width: 95%;

          .inputComponentContainer {
            width: 90%;
          }

          .gerar {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${(props) => props.theme.colors.elements};
            border-radius: 10rem;
            width: 60px;
            height: 6rem;
            margin-left: -10rem;
            z-index: 2;
            cursor: pointer;

            .gerar-texto {
              color: ${(props) => props.theme.colors.brandSecondary};
              text-align: center;
            }
          }
        }

        .peso {
          width: 95%;
        }

        @media (max-width: 390px) {
          flex-wrap: wrap;
          .peso {
            width: 100%;
          }
          .sku {
            width: 100%;
          }
        }
      }

      .categoria {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 15px;
        gap: 1rem;

        .view-select {
          width: 100%;
        }

        .mais {
          background-color: ${(props) => props.theme.colors.brandPrimary};
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          min-width: 5rem;
          min-height: 5rem;
          width: 5rem;
          height: 5rem;
          cursor: pointer;

          .plus {
            width: 17px;
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }

      .precos-lista {
        .preco {
          display: flex;
          gap: 1rem;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 15px;
          margin-right: 5rem;
          .preco-input{
            width: 100%;
            display: flex;
            align-items: flex-end;
            gap: 1rem;
            .lista {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ${(props) => props.theme.colors.elements};
              border-radius: 10rem;
              width: 25rem;
              height: 6rem;
              z-index: 2;
              cursor: pointer;
            }
            @media (max-width: 1024px) {
              display: grid;
              grid-template-columns: 70% 30%;
              justify-content: end;
              align-items: end;
              justify-items: end;
            }
          }


          .mais {
            background-color: ${(props) => props.theme.colors.brandPrimary};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            min-width: 5rem;
            min-height: 5rem;
            width: 5rem;
            height: 5rem;
            cursor: pointer;
            margin-bottom: 5px;

            .plus {
              width: 17px;
              fill: ${(props) => props.theme.colors.white};
            }
          }
        }

        .primeiro{
          margin-right: 0;
        }
      }

      .tem-estoque {
        display: flex;
        align-items: center;
        margin: 3rem 0;
        label{
          display: flex;
          align-items: center;
        }
        div {
          margin-right: 1rem;
        }
      }

      .estoque-unico {
        margin-top: 3rem;
      }
    }

    .avancado {
      margin-top: 4rem;

      .titulo-avancado {
        text-align: left;
      }

      .inputs-avancado {
        margin-top: 4rem;

        .bloco-input {
          margin-top: 4rem;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(45rem, 1fr));
          grid-gap: 2rem;
          align-items: flex-end;
        }
      }
      .linha {
          margin-top: 3rem;
          width: 100%;
          height: 2px;
          background-color: ${(props) => props.theme.colors.elements};
          border-radius: 50px;
        }
        .catalogos{
          text-align: left;
          .titulo-catalogos{
          font-weight: bold;
            margin: 2rem 0;
          }
         .nome{
          margin: 1rem 0;
         }
        }
    }

    .linha {
      display: flex;
      justify-content: center;
      div {
        width: 2px;
        height: 100%;
        background-color: #dbdbdb;
        box-shadow: 0 0 10px #dbdbdb;
      }
    }
  }
`;

export const FooterWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    .excluir_produto {
      cursor: pointer;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  .final{
    display: flex;
    .salvar {
      margin-left: 3rem;
      button {
        margin-bottom: 1rem;
      }
    }
    .avancado {
      button {
        background-color: ${(props) => props.theme.colors.elements};
        font-weight: normal;
        color: ${(props) => props.theme.colors.brandSecondary};
      }
    }
  }
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      .final{
        display: contents;
        .salvar, .avancado{
          width: 100%;
          margin: 0;
          button{
            width: 100%;
            margin: 0;
          }
        }
      }
    }
`;