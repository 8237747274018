import { createContext } from "react";
import { Plano } from "../../types/Plano";
import { Empresa } from "../../types/Empresa";

export type EmpresaContextType = {
  empresa: Empresa | null;
  setEmpresa: (empresa: Empresa) => void;
  verificaVersaoCatalogo: () => Promise<void>;
  erp_empresa: string;
  plano_empresa: () => Promise<Plano>;
  get_erp_empresa: () => Promise<void>;
  loading: boolean;
};

export const EmpresaContext = createContext<EmpresaContextType>(null!);