import { Controls, Player } from "@lottiefiles/react-lottie-player";
import React, { useContext, useEffect, useRef, useState } from "react";
import src from "../../../assets/jsons/Loading.json";
import { ReactComponent as AngleDownSVG } from "../../../assets/newImages/icons/angle-down.svg";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import PopoverForm from "../../PopoverForm/PopoverForm";
import ViewSelectPedidos from "./styles";

interface SelectPedidosProps {
  options:
    | Array<{ name: string; value: any; quantidade: string | number }>
    | any;
  value: any;
  onChange: (newValue: string, name?: string) => void;
  name?: string;
  placeholder?: string;
  label?: string;
  alignLabel?: "left" | "center" | "right";
  sizeLabel?: "small" | "medium" | "large";
  cores: Record<number, string>;
  required?: boolean;
  mostrarQuantidade?: boolean;
  color?: string;
  backgroundColor?: string;
  openUp?: boolean;
  loading?: boolean;
}

const Option = ({ option, focused, handleSelectPedidosChange, index, setFocused, cores, mostrarQuantidade }) => {
  return (
    <div
      key={index}
      className={`optionsContainer ${
        index === focused && "focused"
      }`}
      onMouseOver={() => {
        setFocused(index);
      }}
      onClick={(e) =>
        handleSelectPedidosChange(e, option.value, option)
      }
    >
      <div className="option-text">
        <div
          className="bolinha"
          style={{
            backgroundColor: cores[Number.parseInt(option.value)],
          }}
        />
        {option.name}
        {mostrarQuantidade && (
          <div className="quantidade">{option.quantidade}</div>
        )}
      </div>
    </div>
  )
}

export const SelectPedidos: React.FC<SelectPedidosProps> = ({
  options,
  value,
  placeholder,
  onChange,
  name,
  label,
  sizeLabel,
  alignLabel,
  required = false,
  cores,
  mostrarQuantidade = true,
  openUp = false,
  loading,
}) => {
  let fontLabel = sizesLabel();

  const { theme } = useTheme();

  function sizesLabel() {
    switch (sizeLabel) {
      case "small":
        return "1.5rem";
      case "medium":
        return "2.5rem";
      case "large":
        return "3rem";
      default:
        return "2.5rem";
    }
  }

  const [ativo, setAtivo] = useState(true);
  const [selecionado, setSelecionado] = useState<any>();
  const [focused, setFocused] = useState<null | number>(0);
  const [hoverEffect, setHoverEffect] = useState(false);
  const selectedOptionRef = useRef<HTMLDivElement>(null);
  const empresaContext = useContext(EmpresaContext);

  const optionsHeight = options.length > 5 ? 200 : options.length * 40;

  useEffect(() => {
    if (options.length === 0) {
      return;
    }
  }, [options, value]);

  useEffect(() => {
    let option = options.find((option: any) => option.value === value);
    setSelecionado(option ? option.name : null);
  }, [options]);

  const handleSelectPedidosChange = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    newValue: string,
    selecionado: any,
  ) => {
    setSelecionado(selecionado);
    setAtivo(!ativo);
    name ? onChange(newValue, name) : onChange(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    setHoverEffect(false);
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (!ativo) {
        focused !== null
          ? setFocused((focused + 1) % options.length)
          : setFocused(0);
      } else {
        setAtivo(!ativo);
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      if (!ativo) {
        focused !== null
          ? setFocused((focused - 1 + options.length) % options.length)
          : setFocused(options.length - 1);
      } else {
        setAtivo(!ativo);
      }
    } else if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (!ativo) {
        focused !== null
          ? handleSelectPedidosChange(
              event,
              options[focused].value,
              options[focused].name,
            )
          : setAtivo(!ativo);
      } else {
        setAtivo(!ativo);
      }
    } else if (event.key === "Escape") {
      event.preventDefault();
      setAtivo(true);
    }
  };

  const optionsRef = useRef<HTMLDivElement>(null);
  const scrollToHighlightedOption = () => {
    if (!optionsRef.current || !focused || focused === -1) return;
    const container = optionsRef.current;
    const highlightedOption = container!.children![focused!] as HTMLElement;
    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.offsetHeight;
    const optionTop = highlightedOption.offsetTop;
    const optionBottom = optionTop + highlightedOption.offsetHeight;

    if (optionTop < containerTop) {
      container.scrollTop = optionTop;
    } else if (optionBottom > containerBottom) {
      container.scrollTop = optionBottom - container.offsetHeight;
    }
  };

  const handleCloseDropdown = (e) => {
    if (
      selectedOptionRef.current &&
      !selectedOptionRef.current.contains(e.target)
    ) {
      setAtivo(true);
      document.removeEventListener("click", handleCloseDropdown);
    }
  };

  useEffect(() => {
    scrollToHighlightedOption();
  }, [focused]);

  useEffect(() => {
    if (ativo) {
      setFocused(null);
      document.removeEventListener("click", handleCloseDropdown);
    } else {
      setFocused(options.findIndex((option: any) => option.value === value));
      document.addEventListener("click", handleCloseDropdown);
    }
    return () => {
      document.removeEventListener("click", handleCloseDropdown);
    };
  }, [ativo]);

  useEffect(() => {
    setSelecionado(options.find((option: any) => option.value === value)?.name);
  }, [value]);

  return (
    <>
      <ViewSelectPedidos
        onKeyDown={handleKeyDown}
        onMouseEnter={(event) => {
          setHoverEffect(true);
        }}
        onMouseMove={(event) => {
          setHoverEffect(true);
        }}
        theme={theme}
      >
        <PopoverForm mensagem={"Selecione um item da lista"} />
        <select
          required={required}
          className="selectAuxiliar"
          tabIndex={-1}
          value={value}
          onChange={(event) => {
            name
              ? onChange(event.target.value, name)
              : onChange(event.target.value);
            onChange(event.target.value);
          }}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        {label && (
          <div
            className={`label ${alignLabel && alignLabel}`}
            style={{ fontSize: fontLabel }}
          >
            {label}
          </div>
        )}
        <div
          tabIndex={0}
          className={`${ativo ? "select" : "select ativo"}`}
          onClick={(e) => {
            setAtivo(!ativo);
            e.stopPropagation();
            if (ativo) {
              document.addEventListener("click", (e) => {
                setAtivo(true);
              });
            }
          }}
        >
          <div className={`${value ? "value" : "placeholder"}`}>
            <div
              className="bolinha"
              style={{ backgroundColor: cores[Number.parseInt(value)] }}
            />
            {options.find((option: any) => option.value === value)?.name ||
              placeholder}
            {mostrarQuantidade && (
              <div className="quantidade">
                {options.find((option: any) => option.value === value)
                  ?.quantidade || ""}
              </div>
            )}
          </div>
          <div className="icon">
            {loading ? (
              <Player
                autoplay
                loop
                src={src}
                style={{ height: "3rem", width: "3rem" }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
            ) : (
              <AngleDownSVG className="arrow" />
            )}
          </div>
        </div>
        <div
          ref={optionsRef}
          className={`
            ${openUp && "openUpwards"}
            ${ativo ? "options" : "option ativo scrollBonito"} 
            ${hoverEffect && "hoverEffect"} 
          `}
          style={{ maxHeight: `${optionsHeight}px` }}
        >
          {options
            .map((option, index) => {
              return (
                <Option
                  option={option}
                  focused={focused}
                  handleSelectPedidosChange={handleSelectPedidosChange}
                  index={index}
                  setFocused={setFocused}
                  cores={cores}
                  mostrarQuantidade={mostrarQuantidade}
                />
              );
            })}
          {empresaContext.empresa?.demo && (
            <Option
              option={{
                name: "Excluído",
                value: "9",
                quantidade: "0",
              }}
              focused={focused}
              handleSelectPedidosChange={handleSelectPedidosChange}
              index={options.filter((option) => option.name != "Excluído").length}
              setFocused={setFocused}
              cores={cores}
              mostrarQuantidade={mostrarQuantidade}
            />
          )}
        </div>
      </ViewSelectPedidos>
    </>
  );
};
