import moment from "moment";
import React, { memo } from "react";
import { ReactComponent as Logo } from "../../../assets/newImages/header/logotipo.svg";
import ImgWithLoading from "../../../components/ImgWithLoading/ImgWithLoading.tsx";
import { Relatorio } from "../../../types/Relatorios.ts";
import "./styles.ts";
import { ContainerPrintableRelatorio } from "./styles.ts";

interface PrintableRelatorioProps {
  tableColumns: Array<{ key: string; label: string }>;
  tableData: Array<any>;
  relatorio: Relatorio;
}

const PrintableRelatorio: React.ForwardRefRenderFunction<
  HTMLTableElement,
  PrintableRelatorioProps
> = ({ tableColumns, tableData, relatorio }, ref) => {
  return (
    <ContainerPrintableRelatorio ref={ref}>
      <div className="flex-container">
        <Logo className="print-logo" />
        <div className="data">
          Data: {moment().format("DD/MM/YYYY HH:mm:ss")}
        </div>
      </div>
      <div className="flex-center-align">
        <div className="titulo">{relatorio.nome}</div>
        <div className="descricao">{relatorio.descricao}</div>
      </div>
      <table>
        <thead>
          <tr>
            {tableColumns.map((column) => (
              <th key={column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              {tableColumns.map((column) =>
                /^foto?$/i.test(column.key) ? (
                  <td key={column.key}>
                    <ImgWithLoading
                      imageClass="imagem"
                      src={row[column.key]}
                      alt="Imagem"
                    />
                  </td>
                ) : (
                  <td key={column.key}>{row[column.key]}</td>
                ),
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
            <tr>
              {tableColumns.map((column, index) => {
                let total: any = 0;
                const keyMapping = [
                  { key: "Estoque", isMonetary: false, decimalPlaces: 0 },
                  { key: "Peso", isMonetary: false, decimalPlaces: 2 },
                  { key: "Peso (g)", isMonetary: false, decimalPlaces: 2 },
                  { key: "Preço:", isMonetary: true, decimalPlaces: 2 },
                  { key: "Preços", isMonetary: true, decimalPlaces: 2 },
                  { key: "Quantidade de Pedidos", isMonetary: false, decimalPlaces: 0 },
                  { key: "Quantidade Vendida de Produtos", isMonetary: false, decimalPlaces: 0 },
                  { key: "Quantidade vendida", isMonetary: false, decimalPlaces: 0 },
                  { key: "Quantidade_de_Pedidos", isMonetary: false, decimalPlaces: 0 },
                  { key: "Total", isMonetary: true, decimalPlaces: 2 },
                  { key: "Total em Vendas", isMonetary: true, decimalPlaces: 2 },
                  { key: "Valor Total", isMonetary: true, decimalPlaces: 2 },
                  { key: "Valor_total", isMonetary: true, decimalPlaces: 2 },
                  { key: "valor_total", isMonetary: true, decimalPlaces: 2 }
                ];

                const matchedKey = keyMapping.find(km => column.key.includes(km.key));
                if (matchedKey) {
                  total = tableData.reduce((sum, data) => sum + (Number.parseFloat((data[column.key] ? data[column.key] : 0).toString().replace(",", ".")) || 0), 0);
                }
                const formattedTotal = total.toFixed(matchedKey ? matchedKey.decimalPlaces : 0).replace(".", ",");
                
                if (index === 0) {
                  return (
                    <td key={column.key}>
                      Total geral: 
                    </td>
                  );
                } else if (matchedKey) {
                  if (matchedKey.isMonetary) {
                    return (
                      <td key={column.key}>
                        {formattedTotal}
                      </td>
                    );
                  } else {
                    return (
                      <td key={column.key}>
                        {formattedTotal}
                      </td>
                    );
                  }
                }
                return <td key={column.key}></td>;
              })}
            </tr>
        </tfoot>
      </table>
    </ContainerPrintableRelatorio>
  );
};

export default memo(React.forwardRef(PrintableRelatorio));
