import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const TabelaEdicaoContainer = styled.div<{theme: ThemeType}>`
    width: 100%;
    color: ${({theme}) => theme.colors.brandSecondary};
    padding: 6rem 10rem 20rem 10rem;
    .filtros{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        .filtros-container{
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: center;
        }
        .select-filtro-container{
            width: 50rem;
        }
        .toggle-cabecalho{
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 2.5rem;
            padding-top: 3.5rem;
            color: ${({theme}) => theme.colors.brandSecondary};
        }
    }
    .mensagem {
        margin: 10rem 0;
        width: 100%;
        .texto-titulo {
            text-align: center;
            color: ${(props) => props.theme.colors.brandSecondary};
            font-size: 3.5rem;
        }

        .texto-subtitulo {
            text-align: center;
            color: ${(props) => props.theme.colors.brandSecondary};
            font-size: 2.5rem;
        }
    }
    table{
        display: block;
        min-height: 50rem;
        thead{
            tr{
                th{
                    padding: 1rem;
                    font-size: 2.5rem;
                    .select-coluna{
                        width: 30rem;
                        .select{
                            background-color: transparent;
                            padding: 0;
                            border-color: transparent !important;
                            .placeholder{
                                color: ${({theme}) => theme.colors.brandDanger};
                            }
                        }
                    }
                    .invalid{
                        .select{
                            .selecionado{
                                color: ${({theme}) => theme.colors.brandDanger};
                            }
                        }
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 2.5rem;
                    text-align: left;
                    padding: 1rem;
                    cursor: pointer;
                    .table-data{
                        width: 30rem;
                        /* display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical; */
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        .descricao{
                            width: 100%;
                            height: 3rem;
                        }
                    }
                    .invalid{
                        color: ${({theme}) => theme.colors.brandDanger};
                        .placeholder{
                            color: ${({theme}) => theme.colors.brandDanger};
                        }
                    }
                    .placeholder{
                        color: ${({theme}) => theme.colors.placeholder};
                    }
                    
                }
                .disabled{
                    cursor: not-allowed !important;
                    opacity: 0.4 !important;
                }
                > :first-child {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }

                > :last-child {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
            > :nth-child(2n) {
                background-color: ${({theme}) => theme.colors.white};
            }

            > :nth-child(2n + 1) {
                background-color: ${({theme}) => theme.colors.background1};
            }

            
        }
    }
    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        .ajustar{
            width: 30rem;
        }
    }
    @media (max-width: 1024px) {
        padding: 3rem 5rem 30rem 5rem;
        .footer{
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .ajustar{
                width: 100%;
            }
        }
    }
`;

export {
    TabelaEdicaoContainer
};

