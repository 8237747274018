import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const BackendInfiniteScrollContainer = styled.div<{theme: ThemeType}>`
    display: contents;
`;

export {
    BackendInfiniteScrollContainer
};

