import moment from "moment";
import React, { isValidElement, useEffect, useState } from "react";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { Button } from "../Button/index.tsx";
import ImgWithLoading from "../ImgWithLoading/ImgWithLoading";
import TableMainContainer from "./styles.ts";

interface Column {
  key: string;
  label: string | (() => JSX.Element);
  mask?: (value: string) => string | JSX.Element; 
  allowHTML?: boolean;
}

interface SortConfig {
  keys: string;
  direction: "ascending" | "descending";
}

interface TableProps {
  data: { [key: string]: any }[];
  columns: Column[];
  sortable: boolean;
  selectable: boolean;
  button: boolean;
  buttonText?: string;
  mostrarTotais?: boolean;
  statusColor?: string;
  onDoubleClicked?: (item: any, event: React.MouseEvent) => void;
  onButtonClicked?: (item: any, event: React.MouseEvent) => void;
  onItemSelected?: (selectedItens: any[]) => void;
  page: number;
  itensPorPagina: number;
  className?: string;
}

const Table: React.FC<TableProps> = ({
  data,
  columns,
  sortable,
  selectable,
  button,
  mostrarTotais,
  buttonText,
  statusColor,
  onItemSelected,
  page,
  itensPorPagina,
  onButtonClicked,
  onDoubleClicked,
  className,
}) => {  
  const { theme } = useTheme();

  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [sortedData, setSortedData] = useState(
    data.slice(
      (page - 1) * itensPorPagina,
      itensPorPagina === -1 ? data.length + 1 : page * itensPorPagina,
    ),
  );

  const requestSort = (keys: string, direc?: "ascending" | "descending") => {    
    let direction: "ascending" | "descending" = direc || "ascending";
    if (
      sortConfig &&
      sortConfig.keys === keys &&
      sortConfig.direction === "ascending" &&
      !direc
    ) {
      direction = "descending";
    }
    setSortConfig({ keys, direction });

    if (sortable) {
      const newSortedData = [...data].sort((a, b) => {
        for (const key of keys.split(",")) {
          const trimmedKey = key.trim();

          // Check if both values are null or empty, if so, continue to next iteration
          if (
            (a[trimmedKey] === null || a[trimmedKey] === "") &&
            (b[trimmedKey] === null || b[trimmedKey] === "")
          ) {
            continue;
          }

          // If a[trimmedKey] is null or empty, b[trimmedKey] should be considered greater
          if (a[trimmedKey] === null || a[trimmedKey] === "") {
            return direction === "ascending" ? -1 : 1;
          }

          // If b[trimmedKey] is null or empty, a[trimmedKey] should be considered greater
          if (b[trimmedKey] === null || b[trimmedKey] === "") {
            return direction === "ascending" ? 1 : -1;
          }

          if (a[trimmedKey] !== b[trimmedKey]) {
            // Try to parse the fields as dates
            const aDate = moment(a[trimmedKey], moment.ISO_8601, true);
            const bDate = moment(b[trimmedKey], moment.ISO_8601, true);

            if (aDate.isValid() && bDate.isValid()) {
              // The fields are valid dates
              return direction === "ascending"
                ? aDate.diff(bDate)
                : bDate.diff(aDate);
            } else if (!isNaN(a[trimmedKey]) && !isNaN(b[trimmedKey])) {
              // The fields are numeric
              return direction === "ascending"
                ? Number(a[trimmedKey]) - Number(b[trimmedKey])
                : Number(b[trimmedKey]) - Number(a[trimmedKey]);
            } else {
              // The fields are strings
              const comp = a[trimmedKey]
                .toString()
                .localeCompare(b[trimmedKey].toString(), undefined, {
                  numeric: true,
                  sensitivity: "base",
                });
              return direction === "ascending" ? comp : -comp;
            }
          }
        }
        return 0;
      });

      setSortedData(
        newSortedData.slice(
          (page - 1) * itensPorPagina,
          itensPorPagina === -1 ? sortedData.length + 1 : page * itensPorPagina,
        ),
      );
    }
  };

  const selectAll = (selected: boolean) => {
    if (!selectable) return;
    setSelectedRows(
      selected ? new Set(sortedData.map((item, index) => index)) : new Set(),
    );
  };

  const selectRow = (
    index: number,
    selected: boolean,
    shiftKey: boolean,
    event: React.MouseEvent,
  ) => {
    event.stopPropagation();
    if (!selectable) return;
    const newSelectedRows = new Set(selectedRows);

    if (shiftKey) {
      const min = Math.min(...Array.from(selectedRows));
      const max = Math.max(...Array.from(selectedRows));

      if (index < min) {
        for (let i = index; i < min; i++) {
          newSelectedRows.add(i);
        }
      } else if (index > max) {
        for (let i = index; i > max; i--) {
          newSelectedRows.add(i);
        }
      }
    } else {
      if (selected) {
        newSelectedRows.add(index);
      } else {
        newSelectedRows.delete(index);
      }
    }

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedRows(new Set()); // reset selected rows whenever data changes
      sortable && columns[0] && requestSort(columns[0].key, "ascending");
    } else {
      setSortedData([]);
    }
  }, [data]);

  useEffect(() => {
    setSelectedRows(new Set()); // reset selected rows whenever data changes
    setSortedData(
      data.slice(
        (page - 1) * itensPorPagina,
        itensPorPagina === -1 ? sortedData.length + 1 : page * itensPorPagina,
      ),
    );
  }, [page]);

  useEffect(() => {
    if (sortedData.length > 0) {
      onItemSelected &&
        onItemSelected(
          sortedData.filter((item, index) => selectedRows.has(index)),
        );
    }
  }, [selectedRows, sortedData]);

  return (
    <>
      <TableMainContainer
        className={`${className ? className : ""}`}
        theme={theme}
      >
        {sortedData.length > 0 ? (
          <div className="scrollBonitoBottom">
            <div className="tableContainer">
              <table>
                <thead>
                  <tr>
                    {selectable && (
                      <th>
                        <label className="checkboxBonito checkboxTable">
                          <input
                            type="checkbox"
                            checked={
                              sortedData.length > 0 &&
                              selectedRows.size === sortedData.length
                            }
                            onChange={(e) => selectAll(e.target.checked)}
                          />
                        </label>
                      </th>
                    )}
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        onClick={() => sortable && requestSort(column.key)}
                      >
                        <div className="th-wrapper">
                          {typeof column.label === 'function' ? column.label() : column.label}
                          {typeof column.label !== 'function' && (
                            <svg
                              className={`arrow ${
                                sortConfig?.keys === column.key
                                  ? sortConfig?.direction === "ascending"
                                    ? "down"
                                    : "up"
                                  : ""
                              }`}
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.77552 0.220682C7.63333 0.0793364 7.44099 0 7.2405 0C7.04001 0 6.84766 0.0793364 6.70547 0.220682L3.98103 2.90718L1.29453 0.220682C1.15234 0.0793364 0.959994 0 0.759504 0C0.559014 0 0.36667 0.0793364 0.224481 0.220682C0.15335 0.291231 0.0968926 0.375166 0.0583643 0.467645C0.0198361 0.560124 0 0.659316 0 0.7595C0 0.859683 0.0198361 0.958876 0.0583643 1.05135C0.0968926 1.14383 0.15335 1.22777 0.224481 1.29832L3.44221 4.51605C3.51276 4.58718 3.59669 4.64363 3.68917 4.68216C3.78165 4.72069 3.88084 4.74053 3.98103 4.74053C4.08121 4.74053 4.1804 4.72069 4.27288 4.68216C4.36536 4.64363 4.4493 4.58718 4.51985 4.51605L7.77552 1.29832C7.84665 1.22777 7.90311 1.14383 7.94163 1.05135C7.98016 0.958876 8 0.859683 8 0.7595C8 0.659316 7.98016 0.560124 7.94163 0.467645C7.90311 0.375166 7.84665 0.291231 7.77552 0.220682Z"
                                fill="#C7C7C7"
                              />
                            </svg>
                          )}
                        </div>
                      </th>
                    ))}
                    {statusColor && <th className="buttonTH">Status</th>}
                    {button && (
                      <th className="buttonTH">
                        {buttonText ? buttonText : "Detalhes"}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedData &&
                    sortedData.map((item, index) => (
                      <tr
                        key={(page - 1) * itensPorPagina + index}
                        onClick={(e) =>
                          selectRow(
                            index,
                            !selectedRows.has(index),
                            e.shiftKey,
                            e,
                          )
                        }
                        onDoubleClick={(e) =>
                          onDoubleClicked && onDoubleClicked(item, e)
                        }
                        className={`${
                          selectedRows.has(index) && selectable && "selected"
                        }`}
                      >
                        {selectable && (
                          <td>
                            <label className="checkboxBonito">
                              <input
                                type="checkbox"
                                checked={selectedRows.has(index)}
                                onChange={(e) => e.preventDefault()}
                                onClick={(e) =>
                                  selectRow(
                                    index,
                                    e.currentTarget.checked,
                                    e.shiftKey,
                                    e,
                                  )
                                }
                              />
                            </label>
                          </td>
                        )}
                        {columns.map((column, colIndex) => {
                          if (/^(foto|imagem|image)$/i.test(column.key)) {
                            return (
                              <td
                                key={
                                  (page - 1) * itensPorPagina + index + colIndex
                                }
                              >
                                <ImgWithLoading
                                  imageClass="imagem"
                                  loadingClass="imagem"
                                  src={item[column.key]}
                                  alt={item[column.key]}
                                />
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={(page - 1) * itensPorPagina + index + colIndex}
                              >
                                {(() => {
                                  let key: string | JSX.Element = column.key
                                    .split(",")
                                    .map((key) => item[key.trim()] || "")
                                    .filter((val) => val !== "")
                                    .join(", ");
                            
                                  if (column.mask) {
                                    key = column.mask(key);
                                  }
                                  if(isValidElement(key)) return key;

                                  return column.allowHTML ? <span dangerouslySetInnerHTML={{ __html: key as string }} /> : key;
                                })()}
                              </td>
                            );
                          }
                        })}
                        {statusColor && (
                          <td className="stickyTD">
                            <div
                              className="bolinha"
                              style={{ backgroundColor: statusColor }}
                            ></div>
                          </td>
                        )}
                        {button && (
                          <td className="stickyTD">
                            <Button
                              onClick={(e) =>
                                onButtonClicked && onButtonClicked(item, e)
                              }
                              className="buttonTable"
                              padding="5rem"
                              title={buttonText ? buttonText : "Detalhes"}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
                {(mostrarTotais) && (
                  <tfoot>
                    <tr>
                      {columns.map((column, index) => {
                        let total = 0;
                        const keyMapping = [
                          { key: "Estoque", isMonetary: false, decimalPlaces: 0 },
                          { key: "Peso", isMonetary: false, decimalPlaces: 2 },
                          { key: "Peso (g)", isMonetary: false, decimalPlaces: 2 },
                          { key: "Preço:", isMonetary: true, decimalPlaces: 2 },
                          { key: "Preços", isMonetary: true, decimalPlaces: 2 },
                          { key: "Quantidade de Pedidos", isMonetary: false, decimalPlaces: 0 },
                          { key: "Quantidade Vendida de Produtos", isMonetary: false, decimalPlaces: 0 },
                          { key: "Quantidade vendida", isMonetary: false, decimalPlaces: 0 },
                          { key: "Quantidade_de_Pedidos", isMonetary: false, decimalPlaces: 0 },
                          { key: "Total", isMonetary: true, decimalPlaces: 2 },
                          { key: "Total em Vendas", isMonetary: true, decimalPlaces: 2 },
                          { key: "Valor Total", isMonetary: true, decimalPlaces: 2 },
                          { key: "Valor_total", isMonetary: true, decimalPlaces: 2 },
                          { key: "valor_total", isMonetary: true, decimalPlaces: 2 },
                          { key: "estoque_atual", isMonetary: true, decimalPlaces: 0 },
                          { key: "quantidade_vendida", isMonetary: true, decimalPlaces: 0 },
                        ];

                        const matchedKey = keyMapping.find(km => column.key.includes(km.key));
                        if (matchedKey) {
                          total = data.reduce((sum, data) => sum + (Number.parseFloat((data[column.key] ? data[column.key] : 0).toString().replace(",", ".")) || 0), 0);
                        }
                        const formattedTotal = total.toFixed(matchedKey ? matchedKey.decimalPlaces : 0).replace(".", ",");
                        
                        if (index === 0) {
                          return (
                            <td key={column.key}>
                              Total geral: 
                            </td>
                          );
                        } else if (matchedKey) {
                          if (matchedKey.isMonetary) {
                            return (
                              <td key={column.key}>
                                {formattedTotal}
                              </td>
                            );
                          } else {
                            return (
                              <td key={column.key}>
                                {formattedTotal}
                              </td>
                            );
                          }
                        }
                        return <td key={column.key}></td>;
                      })}
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        ) : (
          <>
            <div className="mensagem">
              <div className="texto-titulo">
                <div>Nenhum resultado encontrado para sua busca.</div>
              </div>
              <div className="texto-subtitulo">
                <div>Tente novamente com uma busca diferente!</div>
              </div>
            </div>
          </>
        )}
      </TableMainContainer>
    </>
  );
};

export default Table;
