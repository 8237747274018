export function unmaskToNumbers(value: string) {
  if (value == null || value === "") {
    return "";
  }
  //remove caracteres não numéricos
  return value.replace(/\D/g, "");
}

export function unmaskToInt(value: string) {
  if (value == null || value === "") {
    return "";
  }

  return value.replace(/[^0-9]/g, "");
}

export function unmaskCurrency(value: string) {
  if (value == null || value === "") {
    return "";
  }
  //remove caracteres não numéricos
  value = value.replace(/\D/g, "");
  //separa centavos
  value = value.replace(/(\d{2})$/, ".$1");
  //separa milhares
  value = value.replace(/(\d+)(\d{3},\d{2})$/g, "$1.$2");
  return value;
}

export function unmaskPercentage(
  value: string,
  event: React.ChangeEvent<HTMLInputElement>,
) {
  // remove caracteres não numéricos e zeros à esquerda
  value = value.replace(/\D/g, "").replace(/^0+/, "");
  if ((event.nativeEvent as InputEvent).data === null) {
    value = value.slice(0, -1);
  }
  return value;
}

export function unmaskInstagram(value: string) {
  return value.replace(/[^a-zA-Z0-9_.-]/g, "");
}
