import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfigsPrimeiroAcessoContext } from "../../../contexts/ConfigsPrimeiroAcesso/ConfigsPrimeiroAcessoContext";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { BotoesContainer, Titulo } from "../../../styled/Styled";
import { Button } from "../../Button";
import Form from "../../Form";
import Modal from "../../Modal/Modal";
import FreteEspecial from "../FreteEspecial/FreteEspecial";
import FreteEspecialListagem from "../FreteEspecialListagem/FreteEspecialListagem";
import "./styles";
import { FreteEspecialMainContainer } from "./styles";

interface FreteEspecialMainProps {
  configIn: "empresa" | "configuracoes";
}

const FreteEspecialMain: React.FC<FreteEspecialMainProps> = ({ configIn }) => {
  const {
    fretes_especiais,
    setFretes_especiais,
    configuracoes,
    setConfiguracoes,
  } = useContext(ConfiguracoesContext);
  const configsPrimeiroAcessoContext = useContext(ConfigsPrimeiroAcessoContext);

  const { theme } = useTheme();
  const [fretes_auxiliar, setFretes_auxiliar] = useState<any>([]);

  const [modalFrete, setModalFrete] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const indexFrete = useRef<number>(-1);

  const handleAdicionarFrete = () => {
    indexFrete.current = -1;
    setModalFrete(true);
  };

  const cancelarEdicaoFrete = () => {
    setFretes_especiais(fretes_auxiliar);
    setModalFrete(false);
    if(fretes_auxiliar.length === 0) {
      setConfiguracoes({
        ...configuracoes,
        frete: {
            ...configuracoes.frete,
            freteEspecialAtivo: false,
        },
    });
    }
  };

  const edicaoFrete = (index: number) => {
    indexFrete.current = index;
    setModalFrete(true);
  };

  const onExclusaoFrete = (newLenght) => {
    setModalFrete(false);
    if(newLenght === 0) {
      setConfiguracoes({
        ...configuracoes,
        frete: {
            ...configuracoes.frete,
            freteEspecialAtivo: false,
        },
    });
    }
  }

  useEffect(() => {
    if(fretes_especiais.length === 0) {
      indexFrete.current = -1;
      setModalFrete(true);
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={modalFrete}
        setModal={setModalFrete}
        width={'100rem'}
        maxWidth="98vw"
        backdrop={'static'}
        padding="3rem 4rem"
        onUseEffect={() => {
          setFretes_auxiliar(JSON.parse(JSON.stringify(fretes_especiais)));
        }}
      >
        <Form 
          id="formFreteEspecial" 
          onSubmit={() => {
            setModalFrete(false)
          }} 
          setFormValid={setFormValid}
        >
          <Titulo theme={theme}>
            {indexFrete.current === -1 ? "Adicionar" : "Editar"} frete especial
          </Titulo>
          <FreteEspecial
            configIn="configuracoes"
            onExclusaoFreteEspecial={onExclusaoFrete}
            index={indexFrete.current}
          />
          <BotoesContainer theme={theme}>
            <div 
              className="cancelar" 
              onClick={() => {
                cancelarEdicaoFrete();
              }}>
              Cancelar
            </div>
            <Button
              title="Salvar"
              type="submit"
              className={`${!formValid && "disabled"}`}
            />
          </BotoesContainer>
        </Form>
      </Modal>
      <FreteEspecialMainContainer theme={theme}>
        {configIn === "configuracoes" && (
          <div className="dropdown">
            {fretes_especiais.length > 0 && (
              <FreteEspecialListagem
                configIn="configuracoes"
                onEditarFrete={edicaoFrete}
              />
            )}
            <div className="novo" onClick={handleAdicionarFrete}>
              Adicionar frete especial
            </div>
          </div>
        )}
      </FreteEspecialMainContainer>
    </>
  );
};

export default FreteEspecialMain;
