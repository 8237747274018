import styled, { css } from "styled-components";

const commonStyles = css`
  display: contents;
  .Endereco {
    padding: 5rem 10rem;
    width: 200rem;
    max-width: 90vw;
    display: grid;
    grid-template-columns: calc(60% - 6rem) calc(40% - 6rem);
    gap: 12rem;

    .informacoes {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5rem;
      min-width: none;

      .titulo {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 5rem;

        .subtitulo {
          font-size: 2.5rem;
          color: ${(props) => props.theme.colors.brandSecondary};
        }

        .barra {
          font-size: 2.5rem;
          color: ${(props) => props.theme.colors.brandSecondary};
        }

        .laranja {
          display: flex;
          font-weight: bold;
          align-items: center;
          justify-content: flex-end;
          color: ${(props) => props.theme.colors.titleColor};
          font-size: 3rem;
        }
      }

      .inputs-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 2rem;

        .input-container {
          width: 100%;
          font-size: 2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: 2rem;
          row-gap: 2rem;

          .cep,
          .bairro {
            flex-grow: 1;
            min-width: 60%;
          }

          .estado,
          .numero {
            min-width: 1rem;
            flex-grow: 1;
          }
        }

        .checkbox-container {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          padding-left: 2rem;
          gap: 1rem;

          > label {
            font-weight: normal;
            color: ${(props) => props.theme.colors.brandSecondary};
            font-size: 2rem;
            margin: 0;
          }

          > input {
            margin: 0;
            accent-color: ${(props) => props.theme.colors.brandPrimary};
          }
        }
      }
    }

    .mockup-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 1024px) {
      grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
      gap: 2rem;
      padding: 3rem 2rem;

      .informacoes {
        > .titulo {
          margin-bottom: 1rem;
        }
      }
    }

    @media (max-width: 750px) {
      padding: 3rem 2rem;
      grid-template-columns: repeat(auto-fit, 100%);
      gap: 5rem;
      .informacoes {
        gap: 0;
      }
    }
  }
`;

export const EnderecoContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      .Endereco {
        padding: 0;
        max-width: 100%;
        width: 100%;
        display: flex;
        gap: 0;
        justify-content: space-evenly;
        .informacoes {
          gap: 0rem;
          padding: 0 3rem;
        }
        .mockup-container {
          max-width: 55rem;
        }
        @media (max-width: 1024px) {
          .mockup-container {
            display: none;
          }
          .informacoes {
            padding: 0;
            .tituloContainer {
              .titulo {
                margin-bottom: 0;
                padding: 0 0 3rem 0;
                .subtitulo {
                  display: none;
                }
                .barra {
                  display: none;
                }
              }
            }
          }
        }
      }
    `};
`;

// Add other styled components for the nested elements if necessary
