import styled from "styled-components";
import theme from "../../../../Themes/light";

export const StepperMainContainer = styled.div`
  .stepperContainer {
    padding: 4rem 10rem 3rem 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .sombra {
      position: relative;
      border-radius: 30px;
      width: 350px;
      background: ${(props) => props.theme.colors.white};
      height: 1rem;

      .preenchimento {
        border-radius: 30px;
        display: block;
        height: 100%;
        position: relative;
        background: ${(props) => props.theme.colors.brandPrimary};
      }
    }
  }

  .navigationContainer {
    padding: 0 7rem 0rem 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .voltar,
    > .nao {
      padding: 3rem 3rem;
      font-size: 2.5rem;
      display: flex;
      cursor: pointer;
      align-content: center;
      justify-content: center;
      gap: 8px;
    }

    .avancar,
    > .sim {
      display: flex;
      font-size: 2.5rem;
      gap: 1rem;
      padding: 3rem 3rem;
      margin-left: auto;
      align-items: center;

      .pular {
        cursor: pointer;
      }

      > .disabled {
        opacity: 0.7 !important;
      }
    }

    @media (max-width: 450px) {
      padding: 0 1rem 0rem 1rem;

      .voltar {
        padding: 0rem 0rem 5rem 2rem;
      }

      .avancar {
        padding: 0rem 2rem 5rem 0rem;
      }
    }
  }

  .navigationContainerSalvarCancelar {
    padding: 5rem 7rem 3rem 7rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .salvar {
      > .disabled {
        opacity: 0.7 !important;
      }
    }

    .cancelar {
      font-size: 2rem;
      color: ${(props) => props.theme.colors.brandPrimary};
      cursor: pointer;
    }
  }

  > .childrenContainer {
    margin-top: 5rem;
  }
`;
