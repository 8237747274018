import { ListasDePrecoContext } from "./ListasDePrecoContext";
import { useState, useEffect } from "react";
import { listasDePrecoApi } from "../../hooks/listasDePreco";

export const ListasDePrecoProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const api = listasDePrecoApi();
  const [ListasDePreco, setListasDePreco] = useState<[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let listas = await api.getListas();
    setListasDePreco(listas);
  };

  return (
    <ListasDePrecoContext.Provider value={{ ListasDePreco, init }}>
      {children}
    </ListasDePrecoContext.Provider>
  );
};
