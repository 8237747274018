import React, { useContext, useEffect } from "react";
import { ContainerVisualColunasContainer } from "./styles";
import { Input } from "../../Input";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { MockupCatalogo } from "../../Mockups/MockupCatalogo/MockupCatalogo";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { copy } from "../../../services/Functions";
import { Select } from "../../Select";
import { Toggle } from "../../Toggle/Toggle";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface VisualColunasProps {
  configIn: "empresa" | "configuracoes";
}

const VisualColunas: React.FC<VisualColunasProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const qtdColunasMobile = [
    { name: "1 coluna", value: "1" },
    { name: "2 colunas", value: "2" },
  ];

  const qtdColunasDesktop = [
    { name: "3 colunas", value: "3" },
    { name: "4 colunas", value: "4" },
    { name: "5 colunas", value: "5" },
  ];

  const qtdVariacoes = [
    { name: "4 variações", value: "4" },
    { name: "5 variações", value: "5" },
    { name: "6 variações", value: "6" },
    { name: "7 variações", value: "7" },
    { name: "8 variações", value: "8" },
    { name: "9 variações", value: "9" },
    { name: "10 variações", value: "10" },
    { name: "Todas", value: "999" },
  ];

  const { theme } = useTheme();

  return (
    <>
      <ContainerVisualColunasContainer configIn={configIn} theme={theme}>
        <div className="informacoes">
          {configIn === "empresa" && (
            <Titulo
              titulo="Visual"
              subtitulo="Configure seu catálogo"
              justifyContent="flex-start"
            />
          )}
          <div className="subtitulo">Quantidade de produtos por colunas</div>
          <Select
            label="Em celulares e tablets"
            sizeLabel="medium"
            options={qtdColunasMobile}
            value={configuracoes.catalogo.quantidade_colunas_padrao.mobile.toString()}
            placeholder="1 coluna"
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                catalogo: {
                  ...configuracoes.catalogo,
                  quantidade_colunas_padrao: {
                    ...configuracoes.catalogo.quantidade_colunas_padrao,
                    mobile: newValue,
                  },
                },
              });
            }}
          ></Select>
          <Select
            label="Em computadores"
            sizeLabel="medium"
            options={qtdColunasDesktop}
            value={configuracoes.catalogo.quantidade_colunas_padrao.desktop.toString()}
            placeholder="1 coluna"
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                catalogo: {
                  ...configuracoes.catalogo,
                  quantidade_colunas_padrao: {
                    ...configuracoes.catalogo.quantidade_colunas_padrao,
                    desktop: newValue,
                  },
                },
              });
            }}
          ></Select>
            
          <label>
            <div className="toggleContainer">
              <Toggle
                value={
                  configuracoes.catalogo.mostrar_botao_compartilhamento_produto
                }
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    catalogo: {
                      ...configuracoes.catalogo,
                      mostrar_botao_compartilhamento_produto: newValue,
                    },
                  });
                }}
              ></Toggle>
              Compartilhamento de produto
            </div>
          </label>
        
        </div>
        <div className="mockup-container">
          <MockupCatalogo
            menu={false}
            banner={false}
            configuracoes={configuracoes}
          />
        </div>
      </ContainerVisualColunasContainer>
    </>
  );
};

export default VisualColunas;
