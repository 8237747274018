import styled from "styled-components";
import theme from "../../../Themes/light";
import { ThemeType } from "../../../types/Theme";

export const ContainerGoogle = styled.div<{theme: ThemeType}>`
  width: 100%;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 4rem;
  .grid{
    display: grid;
    width: 100%;
    justify-content: flex-end;
    grid-gap: 15px;
    grid-template-columns: 50% 50%;
    width: 100%;
    align-items: start;
    .input{
        width: 100%;
    }
    .botao{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
  }
  .texto-input{
    margin-top: 1.5rem;
    width: 100%;
    text-align: left;
    font-size: 2rem;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
`;