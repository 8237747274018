import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

const AjudaContainer = styled.div<{ theme: ThemeType }>`
  padding: 10rem 10rem;
  .titulo {
    font-size: 3rem;
    color: ${(props) => props.theme.colors.titleColor};
    font-weight: bold;
  }
  .texto {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
  .botoes {
    padding: 3rem 0 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: ${(props) => props.theme.colors.elements};
      top: 100%;
    }
    > a {
      width: 100%;
      text-decoration: none;
    }
  }
  a {
    width: 100%;
    text-decoration: none;
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    &:focus {
      outline: none;
    }
  }
  .suporte{
    padding: 5rem 0;
    display: flex;
    font-size: 2.5rem;
    gap: 1rem;
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
 justify-content: center;
  
    &::after {
      content: "";
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: ${(props) => props.theme.colors.elements};
      top: 100%;
    }
    div{
      text-align: left;
      span{
        font-size: 2rem;
      }
    }
    .toggle{
      margin-top: 4px;
    }
  }
  @media (max-width: 768px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 345px) {
    padding: 5rem 3rem;
  }
`;

export { AjudaContainer };
