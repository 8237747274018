import styled from "styled-components";
import theme from "../../../Themes/light";
import { ThemeType } from "../../../types/Theme";

export const ContainerFacebookPixel = styled.div<{ theme: ThemeType }>`
  width: 100%;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 4rem;
  .box{
    width: 100%;
    .input{
        width: 100%;
        margin-bottom: 2rem;
    }
    .texto-input{
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    width: 100%;
    text-align: left;
    font-size: 2rem;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
    .botao{
        display: flex;
        justify-content: flex-end;
        width: 100%;
  
    }
  }
  .texto-input{
    margin-top: 1.5rem;
    width: 100%;
    text-align: left;
    font-size: 2rem;
    color: ${(props) => props.theme.colors.brandSecondary};
  }
`;