import React, { useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import './styles';
import { BackendInfiniteScrollContainer } from './styles';

interface BackendInfiniteScrollProps {
    next: () => void;
    hasMore: boolean;
    loader: React.ReactNode;
    children: React.ReactNode;
    debounceTime?: number;
}

const BackendInfiniteScroll: React.FC<BackendInfiniteScrollProps> = ({
    next,
    hasMore,
    loader,
    children,
    debounceTime = 100,
}) => {
    const { theme } = useTheme();
    const loaderRef = useRef<HTMLDivElement | null>(null);
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting && hasMore) {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
            debounceTimeoutRef.current = setTimeout(() => {
                next();
            }, debounceTime);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver);
        const loaderElement = loaderRef.current;

        if (loaderElement) {
            observer.observe(loaderElement);
        }

        return () => {
            if (loaderElement) {
                observer.unobserve(loaderElement);
            }
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, [hasMore, debounceTime, next]);

    return (
        <BackendInfiniteScrollContainer theme={theme}>
            {children}
            {hasMore && (
                <div ref={loaderRef}>
                    {loader}
                </div>
            )}
        </BackendInfiniteScrollContainer>
    );
};

export default BackendInfiniteScroll;
