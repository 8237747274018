import React from "react";
import { useEffect } from "react";
import { Button } from "../Button";
import { FiltrosContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ReactComponent as FilterSVG } from "../../assets/newImages/icons/filter.svg";

interface filtrosProps {
  children: React.ReactNode;
  onAplicar: () => void;
  onCancelar: () => void;
}
export const Filtros: React.FC<filtrosProps> = ({
  children,
  onAplicar,
  onCancelar,
}) => {
  const { theme } = useTheme();

  const [dropdown, setDropdown] = React.useState(false);

  const handleOnAplicar = () => {
    onAplicar();
    setDropdown(false);
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setDropdown(false);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <FiltrosContainer theme={theme}>
        <div className="filtrosChildren" onClick={() => setDropdown(!dropdown)}>
          <FilterSVG />
          <div>Filtros</div>
        </div>
        {dropdown && (
          <>
            <div
              className="fundoDropdown"
              onClick={() => setDropdown(!dropdown)}
            ></div>
            <div className="containerDropdown">
              <div className="dropdown">
                {children}
                <div className="aplicarCancelar">
                  <Button onClick={() => handleOnAplicar()} title={"Aplicar"} />
                  <div
                    className="cancelar"
                    onClick={() => {
                      setDropdown(false);
                      onCancelar();
                    }}
                  >
                    Cancelar
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </FiltrosContainer>
    </>
  );
};
