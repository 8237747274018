import styled, { keyframes } from "styled-components";
import { ThemeType } from "../../types/Theme";

const shimmerAnimation = keyframes<ThemeType>`
  0%, 100% {
    background-color: var(--skeleton-start);
  }
  50% {
    background-color: var(--skeleton-middle);
  }
`;

const ProdutoWrapper = styled.div<{ theme: ThemeType }>`
  --skeleton-start: ${({ theme }) => theme.colors.skeleton2};
  --skeleton-middle: ${({ theme }) => theme.colors.skeleton1};

  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px;
  transition: all 0.3s ease-in-out;

  .skeleton-produto {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 20rem;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.skeleton1};
    transition: all 0.3s ease-in-out;
  }

  .produto {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    background-color: ${(props) => props.theme.colors.white};
    font-size: 2.5rem;
    padding: 0 0 1rem 0;

    .imagem {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      position: relative;
      border-radius: 16px;
      min-height: 15rem;
      width: 100%;
      aspect-ratio: 1 / 1;
      border-bottom: 2px solid ${(props) => props.theme.colors.placeholder};
      border-bottom: ${(props) =>
        props.theme.mode == "light"
          ? `2px solid ${props.theme.colors.placeholder}`
          : "none"};
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
      }

      .skeleton {
        position: absolute;
        top: 0;
        left: 0;
        aspect-ratio: 1 / 1;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: ${({theme}) => theme.colors.skeleton1};
        transition: all 0.3s ease-in-out;
        animation: ${shimmerAnimation} 2s infinite;
      }
    }

    .conteudo-container {
      padding: 0 3rem 0 3rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .texto-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        text-align: left;
        padding: 1rem 0rem 0rem 0rem;
        align-content: flex-start;

        .texto {
          color: ${(props) => props.theme.colors.placeholder};
          font-size: 2.5rem;
          span {
            color: ${(props) => props.theme.colors.brandSecondary};
            font-size: 3rem;
            font-weight: bold;
          }
          line-height: 1;
        }

        .texto-estoque {
          font-size: 2rem;
        }

        .referencia {
          display: flex;
          width: 100%;
          flex-direction: column;
          line-height: 1;

          &::after {
            content: "";
            width: 5rem;
            height: 2px;
            border-radius: 50px;
            background-color: ${(props) => props.theme.colors.placeholder};
            margin: 0.5rem 0;
          }
        }
      }
    }
  }
`;

export default ProdutoWrapper;
