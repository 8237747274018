import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

export const HeaderTermosContainer = styled.div<{ theme: ThemeType }>`
  display: contents;
  .titulo {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.brandSecondary};
    padding: 4rem 0 0 0;
  }
  .table-termos {
    width: 100%;
    padding: 0 4rem;
    overflow: hidden;
    .termos-header {
      border-radius: 1rem 1rem 0 0;
      width: 100%;
      text-align: center;
      color: ${({ theme }) => theme.colors.brandSecondary};
      font-size: 2.5rem;
      background-color: ${({ theme }) => theme.colors.background2};
      padding: 2rem 0;
      margin-top: 2rem;
    }
    .table-wrapper {
      width: 100%;
      border-radius: 0 0 1rem 1rem;
      table {
        width: 100%;
        thead {
          tr {
            th {
              padding: 2rem;
              border: 1px solid ${({ theme }) => theme.colors.brandSecondary};
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 2rem;
              border: 1px solid ${({ theme }) => theme.colors.brandSecondary};
            }
            &:first-child {
              border-bottom-left-radius: 4rem;
            }
            &:last-child {
              border-bottom-right-radius: 4rem;
            }
          }
        }
      }
    }
  }
`;

export const ModalTermosUsoWrapper = styled.div<{ theme: ThemeType }>`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ModalTermosUsoContainer = styled.div<{ theme: ThemeType }>`
  width: 100%;
  height: 100%;
  padding: 0 6rem 8rem 6rem;
  .termosUso {
    background-color: ${({ theme }) => theme.colors.background2};
    padding: 3rem;
    border-radius: 2rem;
    .texto {
      max-height: 50rem;
      text-align: justify;
    }

    .rodape {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      font-size: 2.5rem;
      gap: 2rem;
      padding-top: 2rem;
      .concordar {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        .textoConcordo {
          line-height: 1;
        }
      }

      .confirmacao {
        margin-right: 15px;

        .aceitar {
          font-size: 14px;
          padding: 0px 30px;
          border-radius: 10px;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0 2rem 8rem 2rem;
  }
`;
