import styled, { css } from "styled-components";
import theme from "../../Themes/light";

const DropdownContainer = styled.div<{ duration: number; height: string }>`
  ${({ duration, height }) => css`
    position: relative;
    width: 100%;
    transition: height ${duration}ms ease-in-out;
    height: ${height};
    overflow: ${height === "auto" ? "visible" : "hidden"};
  `}
`;

export { DropdownContainer };
