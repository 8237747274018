import { useContext, useEffect, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { ContainerPoliticaDePrivacidadeContainer } from "./styles";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import ReactQuill from "react-quill";
import { Input } from "../../Input";

interface PoliticaDePrivacidadeProps {
  configIn: "empresa" | "configuracoes";
}

export const PoliticaDePrivacidade: React.FC<PoliticaDePrivacidadeProps> = ({
  configIn,
}) => {
  const {
    politica_de_privacidade,
    setPolitica_de_privacidade,
    configuracoes,
    setConfiguracoes,
  } = useContext(ConfiguracoesContext);
  const [inputAux, setInputAux] = useState(
    (politica_de_privacidade || "").replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, "")
  );

  const { theme } = useTheme();

  return (
    <>
      <ContainerPoliticaDePrivacidadeContainer
        configIn={configIn}
        theme={theme}
      >
        {configIn === "empresa" && (
          <Titulo
            titulo="Política De Privacidade"
            subtitulo={"Configure seu catálogo"}
          />
        )}
        <div className="texto">
          Deixe claro para seu cliente sua política de privacidade. Essa
          política dá segurança para seu cliente na hora da compra, passando
          maior credibilidade de sua marca.
        </div>
        <div className="containerQuill">
          <ReactQuill
            className="quill-editor"
            formats={["bold", "italic", "underline", "list"]}
            value={politica_de_privacidade}
            onChange={(newValue) => {
              setPolitica_de_privacidade(newValue);
              setInputAux(
                newValue.replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, ""),
              );
            }}
          ></ReactQuill>
          <div className="hidden-input-wrapper">
            <Input
              className="hidden-input"
              invalidText="Preencha este campo"
              isValid={{
                isValidFunction: (value) => {
                  return configuracoes.lgpd.obrigatorio && !value.length
                    ? false
                    : true;
                },
                dependencies: [configuracoes.lgpd.obrigatorio, inputAux],
              }}
              placeholder=""
              onChange={(newValue) => {
                setInputAux(newValue);
              }}
              type="text"
              value={inputAux}
            />
          </div>
        </div>
        <div className="texto-obrigatorio">
          Gostaria que seu cliente concorde com sua política de privacidade ao
          finalizar a compra? Se sim, selecione o opção abaixo.
        </div>
        <label className="obrigatorio">
          <input
            checked={configuracoes.lgpd.obrigatorio}
            type="checkbox"
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                lgpd: {
                  ...configuracoes.lgpd,
                  obrigatorio: newValue.target.checked,
                  opcao: newValue.target.checked ? 2 : 1,
                },
              });
            }}
          ></input>
          É obrigatório meu cliente marcar que concorda com a política de
          privacidade.
        </label>
      </ContainerPoliticaDePrivacidadeContainer>
    </>
  );
};
