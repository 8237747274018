import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProdutosPlanilhaContext } from '../../../contexts/ProdutosPlanilha/ProdutosPlanilhaContext';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { Button } from '../../Button';
import { InformacoesArquivoContainer } from './styles';

interface InformacoesArquivoProps {
}

const InformacoesArquivo: React.FC<InformacoesArquivoProps> = ({}) => {

    const {theme} = useTheme();
    const {dadosValidados, qtdColunasInvalidas, qtdDadosInvalidos, qtdDadosValidos, setEtapa} = useContext(ProdutosPlanilhaContext);
    const navigate = useNavigate();
    
    const pluralize = (count: number, singular: string, plural: string) => 
        `${count} ${count === 1 ? singular : plural}`;

    return (
        <InformacoesArquivoContainer theme={theme}>
            <div className="informacao">
                <span className='accept'>✓</span> {pluralize(qtdDadosValidos, 'produto a ser importado;', 'produtos a serem importados;')}
            </div>
            <div className="informacao">
                <span>x</span> {pluralize(qtdColunasInvalidas, 'coluna inválida;', 'colunas inválidas;')}
            </div>
            <div className="informacao">
                <span>x</span> {pluralize(qtdDadosInvalidos, 'produto inválido;', 'produtos inválidos;')}                
            </div>
            <Button 
                title='Ajustar produtos'
                className='ajustar-produtos'
                onClick={() => navigate('ajustar')}
                width='100%'
            />
        </InformacoesArquivoContainer >
    )
}

export default InformacoesArquivo;