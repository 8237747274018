import styled from "styled-components";
import theme from "../../Themes/light";

const transitionTime = "0.3s";
const TableMainContainer = styled.div`
  .tableContainer {
    width: 100%;
    font-size: 2.3rem;
    color: ${(props) =>
      props.theme.mode == "light"
        ? "unset"
        : props.theme.colors.brandSecondary};
    > table {
      width: 100%;

      > thead {
        width: 100%;

        > tr {
          > th {
            font-weight: normal;
            .th-wrapper{
              cursor: pointer;
              font-weight: normal;
              text-align: left;
              padding: 5rem 2rem;
              word-break: keep-all;
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              > .arrow {
                visibility: hidden;
                margin-left: 1rem;
                font-size: 1.5rem;
                transition: transform ${transitionTime};
              }
  
              > .up {
                visibility: visible;
                transform: rotate(180deg);
              }
  
              > .down {
                visibility: visible;
              }
            }
          }
          .buttonTH {
            text-align: center;
            width: 10rem;
            cursor: inherit;
            background-color: ${(props) => props.theme.colors.background1};
          }

          @media (min-width: 1024px) {
            .buttonTH {
              position: sticky;
              right: 0;
            }
          }
        }

        .checkboxTable {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      > tfoot {
        font-weight: bold;
        width: 100%;
        position: relative;
        padding-top: 1rem;
        > tr {
          text-align: justify;
          > td {
            padding: 1.8rem 2rem;
          }
        }
        &::after{
          content: '';
          top: 1rem;
          left: 0;
          width: 100%;
          height: 1px;
          border-radius: 10px;
          background-color: ${(props) => props.theme.colors.elements};
          position: absolute;
        }
      }

      > tbody {
        width: 100%;
        > tr {
          transition: ${transitionTime};
          > td {
            padding: 0.8rem 2rem;
            word-break: keep-all;
            white-space: nowrap;
            text-align: left;
            > .bolinha {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              margin-left: auto;
              margin-right: auto;
            }
            > .imagem {
              border-radius: 15px;
              width: 10rem;
              height: 10rem;
              min-height: 10rem;
              object-fit: cover;
            }
          }

          > :first-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }

          > :last-child {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          &:hover {
            background-color: rgba(164, 164, 164, 0.2);
            .stickyTD {
              background-color: transparent;
            }
          }

          .stickyTD {
            transition: ${transitionTime};
            .buttonTable {
              background-color: ${(props) => props.theme.colors.elements};
              font-weight: normal;
              color: ${(props) => props.theme.colors.brandSecondary};
              right: 0;
            }
          }

          @media (min-width: 1024px) {
            .stickyTD {
              position: sticky;
              background-color: inherit;
              right: 0;
              transition: ${transitionTime};
              &:first-child {
                right: 10rem;
              }
              .buttonTable {
                background-color: ${(props) => props.theme.colors.elements};
                font-weight: normal;
                color: ${(props) => props.theme.colors.brandSecondary};
                right: 0;
              }
            }
          }
        }
        > :nth-child(2n + 1) {
          background-color: ${(props) => props.theme.colors.white};
        }

        > :nth-child(2n) {
          background-color: ${(props) => props.theme.colors.background1};
        }
        .selected {
          background-color: rgba(164, 164, 164, 0.2);
          .stickyTD {
            background-color: transparent;
          }
        }
      }
    }
  }
  .mensagem {
    margin-top: 10rem;

    .texto-titulo {
      color: ${(props) => props.theme.colors.brandSecondary};
      font-size: 3.5rem;
    }

    .texto-subtitulo {
      color: ${(props) => props.theme.colors.brandSecondary};
      font-size: 2.5rem;
    }
  }
`;

export default TableMainContainer;
