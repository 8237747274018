import React, { useRef } from 'react';
import Swal from 'sweetalert2';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import { Button } from '../Button';
import PopoverForm from '../PopoverForm/PopoverForm';
import './styles';
import { InputFileButtonContainer } from './styles';

export interface FileType {
    content: string;
    name: string;
    size: number;
    type: string;
}

interface InputFileButtonProps {
    onChange(files: FileType[]): void;
    accept: string[];
    title: string;
    className?: string;
    multiple?: boolean;
    required?: boolean;
}

const InputFileButton: React.FC<InputFileButtonProps> = ({
    onChange,
    accept,
    title,
    className,
    multiple,
    required=false
}) => {
    const fileInput = useRef<HTMLInputElement>(null)

    const {theme} = useTheme();

    const onChangeFile = (eChange: React.ChangeEvent<HTMLInputElement>) => {
        if (!eChange.target.files) return;
        const files = eChange.target.files;
    
        // Use Promise.all to handle multiple file reads
        Promise.all(Array.from(files).map(file => {
            return new Promise<FileType>((resolve, reject) => {
                console.log(file.type, accept)
                if (!accept.map(value => value.replace('.', '')).includes(file.type.split('/').pop() || '')) {
                    eChange.target.value = '';
                    reject(new Error('TIPO_INVALIDO'));
                    return;
                }
    
                const reader = new FileReader();
                reader.onload = () => {
                    const content = reader.result as string;
                    resolve({
                        content,
                        name: file.name,
                        size: file.size,
                        type: file.type
                    });
                };
                reader.onerror = () => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao ler o arquivo',
                    });
                    eChange.target.value = '';
                    reject(new Error('ERRO_LEITURA_ARQUIVO'));
                };
                // Read the file with ISO-8859-1 encoding
                reader.readAsText(file, 'ISO-8859-1');
            });
        }))
        .then(returnFiles => {
            onChange(returnFiles);
        })
        .catch(error => {
            if(error.message === 'TIPO_INVALIDO'){
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Tipo de arquivo inválido',
                });
                return;
            }
            if(error.message === 'ERRO_LEITURA_ARQUIVO'){
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao ler o arquivo',
                });
                return;
            }
            console.error(error);
        });
    };
    

    const openFileInput = (e) => {
        e.preventDefault();
        fileInput.current!.click();
    };

    return (
        <InputFileButtonContainer theme={theme}>
            <input
              type="file"
              accept=".csv"
              id="csv-file"
              ref={fileInput}
              onChange={onChangeFile}
              style={{ display: "contents" }}
              required={required}
              multiple={multiple}
            />
            <PopoverForm
                mensagem={`Adicione um arquivo (${accept.join(", ")}) para continuar`}
            />
            <Button
                className={className}
                width='100%'
                title={title || 'Escolher arquivo'}
                onClick={openFileInput}
            ></Button>                
        </InputFileButtonContainer >
    )
}

export default InputFileButton;