import { api } from "./useApi";

export const NotificacoesApi = () => ({
    getNotificacoes: async () => {
        let response = await api.post("/notificacoes/listar");
        return response.data;
    },
    isHabilitado: async (id_onesignal: string) => {
        let response = await api.post("/notificacoes/isHabilitado", { id_onesignal });
        return response.data;
    },
    habilitarDesabilitar: async (id_onesignal: string, habilitar: boolean) => {
        let response = await api.post("/notificacoes/habilitar-desabilitar", { id_onesignal, habilitar });
    },
    temNotificacaoNova: async () => {
        let response = await api.post("/notificacoes/tem-notificacao-nova");
        return response.data;
    }
});

