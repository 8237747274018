import React, { useContext, useState } from "react";
import "./styles.ts";
import { Link, useLocation } from "react-router-dom";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { useEffect } from "react";
import FotoUsuario from "../../components/Uteis/FotoUsuario.tsx";
import { ScrollDirectionContext } from "../../contexts/ScrollDirection/ScrollDirectionContext.tsx";
import { ViewMenuLateral } from "./styles.ts";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { ReactComponent as LogoSVG } from "../../assets/newImages/menuLateral/Logo.svg";
import { ReactComponent as IconeMeuPainelSVG } from "../../assets/newImages/menuLateral/painel.svg";
import { ReactComponent as IconeCompartilharSVG } from "../../assets/newImages/menuLateral/share.svg";
import { ReactComponent as IconeConfiguracoesSVG } from "../../assets/newImages/menuLateral/config.svg";
import { AuthContext } from "../../contexts/Auth/AuthContext.tsx";
import { PERMISSOES } from "../../services/Consts.tsx";

const MenuLateral: React.FC = (props) => {
  const { theme } = useTheme();
  const { permissoesUsuario } = useContext(AuthContext);
  const [menuAtivo, setMenuAtivo] = useState("meuPainel");
  const { empresa, setEmpresa } = useContext(EmpresaContext);

  const scrollDir = useContext(ScrollDirectionContext).direction;
  const location = useLocation();

  const isIos = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  let ios = isIos();

  useEffect(() => {
    setMenuAtivo(
      location.pathname.includes("configuracoes")
        ? "configuracao"
        : location.pathname.includes("compartilhar")
        ? "compartilhar"
        : location.pathname.includes("perfil")
        ? "perfil"
        : "meuPainel"
    );
  }, [location.pathname]);

  return (
    <>
      <ViewMenuLateral theme={theme}>
        <div
          className={`view-menu-lateral ${
            scrollDir === "up" && "bottomAtivo"
          } ${ios && "ios"}`}
        >
          <div className="fixedContainer">
            <Link
              className="hideMobile"
              to={"/"}
              style={{ textDecoration: "none" }}
            >
              <div className="logoConecta">
                <LogoSVG />
              </div>
            </Link>
            <div className="menus">
              <Link
                to={"/empresa-catalogos"}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={`menuContainer ${
                    menuAtivo == "meuPainel" ? "ativo" : ""
                  }`}
                >
                  <div className="menu">
                    <IconeMeuPainelSVG className="icone-svg" />
                  </div>
                  <div className="nomeMenu">Meu painel</div>
                </div>
              </Link>
              <Link to={"/compartilhar"} style={{ textDecoration: "none" }}>
                <div
                  className={`menuContainer ${
                    menuAtivo == "compartilhar" ? "ativo" : ""
                  }`}
                >
                  <div className="menu">
                    <IconeCompartilharSVG className="icone-svg" />
                  </div>
                  <div className="nomeMenu">Compartilhar</div>
                </div>
              </Link>
              {permissoesUsuario?.includes(PERMISSOES.CONFIGURACOES) && (
                <Link
                  to={"/painel/configuracoes"}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={`menuContainer ${
                      menuAtivo == "configuracao" ? "ativo" : ""
                    }`}
                  >
                    <div className="menu">
                      <IconeConfiguracoesSVG className="icone-svg" />
                    </div>
                    <div className="nomeMenu">Configuração</div>
                  </div>
                </Link>
              )}
            </div>
            <div className="logoPerfil hideMobile">
              <Link
                to={
                  permissoesUsuario?.includes(PERMISSOES.USUARIOS_GERENTE)
                    ? "/perfil/usuarios"
                    : "/"
                }
                style={{ textDecoration: "none" }}
              >
                <div
                  className={`menuContainer ${
                    menuAtivo == "perfil" ? "ativo" : ""
                  }`}
                >
                  <FotoUsuario
                    size={5}
                    ativo={menuAtivo == "perfil"}
                  ></FotoUsuario>
                  <div className="nomeMenu">
                    {permissoesUsuario?.includes(PERMISSOES.USUARIOS_GERENTE)
                      ? "Meu perfil"
                      : empresa?.usuario}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </ViewMenuLateral>
    </>
  );
};

export default MenuLateral;
