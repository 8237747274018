import Configuracoes from "../../types/Configuracoes";

export const ConfiguracoesJson: Configuracoes = {
  primeiro_acesso: true,
  campos_pedido_pessoa_fisica: [
    { nome: "Nome completo", campo: "nome", ativo: true, obrigatorio: true },
    { nome: "CPF", campo: "cpf", ativo: false, obrigatorio: false },
    {
      nome: "Data de nascimento",
      campo: "data_nascimento",
      ativo: false,
      obrigatorio: false,
    },
  ],
  campos_pedido_pessoa_juridica: [
    {
      nome: "Razão Social",
      campo: "razao_social",
      ativo: true,
      obrigatorio: true,
    },
    { nome: "CNPJ", campo: "cnpj", ativo: false, obrigatorio: false },
    {
      nome: "Inscrição Estadual",
      campo: "inscricao_estadual",
      ativo: false,
      obrigatorio: false,
    },
  ],
  campos_pedido_geral: [
    { nome: "E-mail", campo: "email", ativo: false, obrigatorio: false },
    {
      nome: "Telefone (WhatsApp)",
      campo: "telefone",
      ativo: true,
      obrigatorio: true,
    },
    {
      nome: "Observação",
      campo: "observacao",
      ativo: false,
      obrigatorio: false,
    },
    { nome: "CEP", campo: "cep", ativo: false, obrigatorio: false },
    { nome: "Estado", campo: "estado", ativo: false, obrigatorio: false },
    { nome: "Cidade", campo: "cidade", ativo: false, obrigatorio: false },
    { nome: "Endereço", campo: "endereco", ativo: false, obrigatorio: false },
    { nome: "Número", campo: "numero", ativo: false, obrigatorio: false },
    {
      nome: "Complemento",
      campo: "complemento",
      ativo: false,
      obrigatorio: false,
    },
    { nome: "Bairro", campo: "bairro", ativo: false, obrigatorio: false },
  ],
  lgpd: {
    nome: "Política de Privacidade",
    campo: "lgpd",
    obrigatorio: false,
    opcao: 2,
  },
  mensagem_whats: "",
  nome_empresa_pedido: "",
  dados_pagseguro: {
    email: "",
    token: "",
    mensagem: "",
  },
  dados_bling: {
    ativo: false,
    chave: "",
    upload_fotos_conecta: false,
    controle_estoque: false,
    apenas_deposito_geral: false,
    multiplicador: "1",
    estoque_ilimitado: false,
    status_pedido_atendido: false,
    codigo_loja_bling: "",
    descricao_curta: false,
  },
  dados_mercadoPago: {
    ativo: false,
    scope: "",
    secret: "",
    sandbox: false,
    user_id: 0,
    client_id: "",
    expires_in: 0,
    public_key: "",
    token_type: "",
    access_token: "",
    refresh_token: "",
    dataAtualizacao: "",
  },
  dados_bling_v2: {
    ativo: false,
    chave: "",
    upload_fotos_conecta: false,
    controle_estoque: false,
    apenas_deposito_geral: false,
    multiplicador: "",
    estoque_ilimitado: false,
    status_pedido_atendido: false,
    codigo_loja_bling: "",
    descricao_curta: false,
    buscar_de_depositos: [],
    autenticado: false,
    configurar_observacao_conecta: false,
  },
  dados_tiny: {
    ativo: false,
    chave: "",
    identificador_integrador: "",
    tabela_descricao: "",
    nome_descricao: false,
    tabela_id: "",
    upload_fotos_conecta: false,
    controle_estoque: false,
  },
  dados_tiny_v2: {
    ativo: false,
    chave: "",
    identificador_integrador: "",
    tabela_descricao: "",
    nome_descricao: false,
    tabela_id: "",
    multiplicador: "1",
    upload_fotos_conecta: false,
    controle_estoque: false,
  },
  dominio: "",
  subdominio: "",
  dados_empresa: {
    informacoes_gerais: {
      ramo_atividade: "",
      logotipo: "",
      nome: "",
      descricao: "",
    },
    redes_sociais: {
      whatsapp: "",
      instagram: "",
    },
    endereco: {
      cep: "",
      estado: "",
      cidade: "",
      endereco: "",
      numero: "",
      bairro: "",
      complemento: "",
      ocultar_endereco_catalogo: false,
    },
  },
  catalogo: {
    cor_primaria: "#0E5129",
    cor_secundaria: "#FFFFFF",
    mostrar_estoque: false,
    mostrar_botao_compartilhamento_produto: false,
    mostrar_botao_compartilhamento_categoria: false,
    quantidade_colunas_padrao: {
      mobile: "1",
      desktop: "3",
    },
    limite_variacoes: "10",
  },

  instashop: {
    ativo: false,
    categoria: {
      id: "",
      descricao: "",
    },
  },
  pagamentoCombinarAtivo: false,
  pagamentoOnlineAtivo: false,
  intermediadores: {
    a_combinar: {
      ativo: false,
      prazo_contato: "1",
    },
  },

  download_fotos: true,
  mostrar_grupos: false,
  mostrar_variacoes: false,
  moeda: "R$",
  ordenacao_catalogo: {
    nome: "Nome A-Z",
    chave: "produto_referencia",
    inverso: false,
  },

  ordenacao_catalogo_variacoes: {
    nome: "Variação A-Z",
    chave: "variacao_descricao",
    inverso: false,
  },

  integracao: {
    cadastrarImagens: false,
    cadastrarProdutos: false,
  },

  frete: {
    opcoes: [
      { ativo: false, tipo: "pac", descricao: "PAC", codigo: "04510" },
      { ativo: false, tipo: "sedex", descricao: "Sedex", codigo: "04014" },
      {
        ativo: false,
        tipo: "sedex_10",
        descricao: "Sedex 10",
        codigo: "40215",
      },
      {
        ativo: false,
        tipo: "mini_envios",
        descricao: "Mini Envios",
        codigo: "04235",
      },
    ],
    configuracoes: {
      codigo_administrativo: "",
      contrato: "",
      cartao: "",
      usuario: "",
      senha: "",
      cep_origem: "",
      seguro: false,
      diasEntregaCorreios: 0,
    },
    dimensoes: {
      comprimento: "16",
      largura: "11",
      altura: "2",
      peso: "0.0",
    },
    correiosAtivo: false,
    freteEspecialAtivo: false,
    freteGratisAtivo: false,
    entregaLocal: {
      diasEntregaEntregaLocal: "0",
      ativo: false,
      cepDoLocal: "",
      semTaxa: false,
      comTaxa: {
        ativo: false,
        valor: "0",
      },
    },
    retirada_no_local: {
      ativo: false,
      intervalos: [
        {
          dias: [],
          horarios: [
            {
              abre: "",
              fecha: "",
            },
          ],
        },
      ],
    },
    melhorEnvio: {
      ativo: false,
      token: "",
      altura: "",
      largura: "",
      comprimento: "",
      peso: "",
    },
  },
  dados_facebook_pixel: {
    ativo: false,
    id: "",
    token:""
  },
  verificacoes_de_dominio: {
    ativo: false,
    google: "",
    facebook: "",
    pinterest: "",
  },
  dados_google: {
    ativo : false,
    analytics: "",
    ads: "",
    tagmanager: "",
  },
};

export const politica_default = `
  <p>Política de privacidade</a>

    <br />

    <p>Nós valorizamos profundamente a sua privacidade e nos comprometemos a proteger as suas informações pessoais. Esta Política de Privacidade foi elaborada para que você saiba como nós gerenciamos, coletamos, armazenamos e utilizamos as informações que você compartilha conosco ao utilizar nossos serviços online.</p>

    <br />

    <h2 id="privacy-policy">Como coletamos suas informações</h2>

    <p>Nós coletamos informações pessoais que você decide compartilhar conosco ao se registrar em nosso site, fazer compras ou participar de promoções e pesquisas. Estas informações incluem, mas não se limitam a seu nome, endereço de e-mail, endereço residencial e número de telefone.</p>

    <br />

    <h2>Informações coletadas automaticamente</h2>

    <p>Além disso, nós coletamos automaticamente algumas informações sempre que você utiliza nossos serviços. Estas informações podem incluir detalhes sobre o dispositivo que você está usando, seu sistema operacional e dados sobre a sua interação com nossos serviços.</p>

    <br />

    <h2>Como utilizamos suas informações</h2>

    <p>Fornecer, manter e aprimorar constantemente a qualidade de nossos serviços.</p>

    <p>Processar suas transações e enviar notificações relacionadas a elas.</p>

    <p>Responder a suas perguntas, comentários ou reclamações.</p>

    <br />

    <h2>Compartilhamento de informações</h2>

    <p>Nós nunca venderemos suas informações pessoais para terceiros. No entanto, nós poderemos compartilhar suas informações em situações específicas, como:</p>

    <ul>
        <li>Para cumprir com leis ou responder a processos legais.</li>
        <li>Para proteger nossos direitos, a segurança dos nossos serviços e a segurança dos nossos usuários.</li>
    </ul>

    <br />

    <h2>Segurança das suas informações</h2>

    <p>Nós empregamos diversas medidas de segurança para proteger suas informações contra acessos não autorizados, perda, alteração ou destruição.</p>

    <br />

    <h2>Cookies e tecnologias similares</h2>

    <p>Nós utilizamos cookies e outras tecnologias similares para oferecer e suportar nossos serviços, ajudando a proporcionar uma experiência melhorada para você.</p>

    <br />

    <p>Agradecemos por confiar na nossa marca. Estamos sempre aqui para responder a quaisquer perguntas ou preocupações que você possa ter em relação à sua privacidade. Sinta-se à vontade para entrar em contato conosco através do nosso WhatsApp, no menu lateral esquerdo.</p>
`;

export const politicaTrocaeDevolucao_default = `
  <p>Política de troca e devolução</a>

    <h2 id="privacy-policy">Condições gerais</h2>

    <p>Todas as ocorrências que envolvam troca ou devolução devem ser feitas no prazo de até 7 (sete) dias corridos, a contar da data de entrega, e devem ser comunicadas através de nosso e-mail ou telefone.</p>

    <br />

    <h2>Quando recusar o produto</h2>

    <p>Os produtos são enviados ao cliente em perfeitas condições. Se ocorrer qualquer das hipóteses abaixo, recuse o recebimento e escreva o motivo no verso do DANFE: <br>

    embalagem aberta ou avariada; <br>
    
    produto avariado; <br>
    
    produto em desacordo com o pedido; <br>
    
    falta de acessórios. <br>
    
    Se, ainda assim, você aceitar o produto, por favor, entre em contato conosco em até 72 horas.</p>

    <br />

    <h2>Troca ou cancelamento da compra</h2>

    <p>A devolução de qualquer produto só pode ser feita no prazo de até 7 (sete) dias, a contar da data de entrega. Nesse período, se o produto apresentar defeito, ou se você não estiver satisfeito(a) com a compra, nos comunique através de e-mail ou telefone e solicite a troca.</p>

    <br />

    <h2>Prazo para resolução da troca</h2>

    <p>Nosso prazo para a resolução da troca é de até 30 (trinta) dias corridos, contados a partir da data do recebimento do produto em nossa empresa, para verificar se a solicitação preenche os requisitos da Política de Trocas e Devoluções. Em caso afirmativo, enviará outro produto ao cliente</p>

    <br />

    <h2>Restituição do valor pago</h2>

    <p>Iremos fazer a restituição dos valores pagos utilizando a mesma forma de pagamento escolhida no processo de compras ou através de depósito bancário. A restituição dos valores será processada somente após o recebimento e análise do(s) produto(s) em nossa empresa.</p>
`;
