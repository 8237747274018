import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const AjustarCampoContainer = styled.div<{theme: ThemeType}>`
    display: flex;
    flex-direction: column;
    padding: 5rem 10rem;
    .ajustar-campo-form{
        display: flex;
        flex-direction: column;
        gap: 5rem;
        .titulos{
            display: inline-flex;
            flex-direction: column;
            width: auto;
        }
        .botao-container{
            display: flex;
            justify-content: center;
        }
    }
`;

export {
    AjustarCampoContainer
};

