import React, { useState } from "react";
import { ContainerCupom } from "./styles";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Input } from "../../Input";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { percentageMask } from "../../../services/Masks";
import { unmaskPercentage } from "../../../services/Unmasks";
import { cupomMask } from "../../../services/Masks";

interface CupomType {
  cupom: string;
  desconto: number; // Porcentagem de desconto
}

interface CupomProps {
  setCupom: (cupom: CupomType) => void;
  cupom: CupomType;
  excluirCupom?: () => void;
}

const Cupom: React.FC<CupomProps> = ({ setCupom, cupom, excluirCupom }) => {
  const { theme } = useTheme();

  return (
    <ContainerCupom configIn={"configuracoes"} theme={theme}>
      {
        <div className="containerCupom">
          <div className="inputContainer">
            <Input
              type="text"
              value={cupom.cupom}
              placeholder="Nome do Cupom"
              required={true}
              mask={{
                mask: cupomMask,
              }}
              maxLength={12}
              onChange={(newValue) => {
                setCupom({ ...cupom, cupom: newValue });
              }}
              label="Nome do Cupom"
            />
            <Input
              type="text"
              value={cupom.desconto.toString()}
              mask={{
                mask: percentageMask,
                unmask: unmaskPercentage,
              }}
              placeholder="Porcentagem de Desconto"
              required={true}
              onChange={(newValue) => {
                setCupom({
                  ...cupom,
                  desconto: parseFloat(newValue),
                });
              }}
              label="Desconto (%)"
            />
          </div>
          <div className="exclusao">
            <span
              onClick={() => {
                if (excluirCupom) {
                  excluirCupom();
                }
              }}
            >
              Excluir Cupom
            </span>
          </div>
        </div>
      }
    </ContainerCupom>
  );
};

export default Cupom;
