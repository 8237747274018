import { IntegracaoContext } from "./IntegracaoContext";
import {
    useState,
    useEffect,
    useDebugValue,
    StrictMode,
    useContext,
} from "react";
import { EmpresaApi } from "../../hooks/empresaApi";
import Swal from "sweetalert2";
import { ConfiguracoesContext } from "../Configuracoes/ConfiguracoesContext";

export const IntegracaoProvider = ({ children }: { children: JSX.Element }) => {
    const api = EmpresaApi();
    const [chaveIntegracao, setChaveIntegracao] = useState<string>("");
    const { configuracoes, setConfiguracoes } =
        useContext(ConfiguracoesContext);

    const [activeChaveIntegracao, setActiveChaveIntegracao] =
        useState<boolean>(false);

    useEffect(() => {
        async function getChaveApi() {
            const response = await api.get_chave_api();
            if (response) {
                setChaveIntegracao(response);
                setActiveChaveIntegracao(true);
            }
        }
        getChaveApi();
    }, []);

    const onChangeIntegracao = (newValue: boolean) => {
        if (!newValue) {
            if (chaveIntegracao) {
                //para cancelar entrar em contato com o suporte
                Swal.fire({
                    text: "Para cancelar a integração entre em contato com o suporte.",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Entrar em contato",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.open(
                            "https://api.whatsapp.com/send?phone=555434434563&text=Olá, gostaria de cancelar minha integração com o Conecta Venda.",
                            "_blank",
                        );
                    }
                });
            } else {
                setActiveChaveIntegracao(false);
            }
        } else {
            setActiveChaveIntegracao(true);
        }
    };

    const onChangeIntegracaoTiny = (newValue: boolean) => {
        if (newValue && configuracoes.dados_bling.ativo) {
            Swal.fire({
                text: "Você já possui uma integração com o Bling. Para ativar a integração com a Tiny você deve desativar a integração com o Bling.",
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
            });
            return false;
        }
        setConfiguracoes({
            ...configuracoes,
            dados_tiny_v2: {
                ...configuracoes.dados_tiny_v2,
                ativo: newValue,
            },
        });
    };

    const onChangeIntegracaoBling = (newValue: boolean) => {
        if (newValue && configuracoes.dados_tiny_v2.ativo) {
            Swal.fire({
                text: "Você já possui uma integração com a Tiny. Para ativar a integração com o Bling você deve desativar a integração com a Tiny.",
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
            });
            return false;
        }
        setConfiguracoes({
            ...configuracoes,
            dados_bling: {
                ...configuracoes.dados_bling,
                ativo: newValue,
            },
        });
    };

    const onChangeIntegracaoBlingV2 = (newValue: boolean) => {
        if (newValue && configuracoes.dados_tiny_v2.ativo) {
            Swal.fire({
                text: "Você já possui uma integração com a Tiny. Para ativar a integração com o Bling você deve desativar a integração com a Tiny.",
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
            });
            return false;
        }
        if(configuracoes.dados_bling_v2 !== undefined){
            setConfiguracoes({
                ...configuracoes,
                dados_bling_v2: {
                    ...configuracoes.dados_bling_v2,
                    ativo: newValue,
                },
            });
        }
    };

    return (
        <IntegracaoContext.Provider
            value={{
                chaveIntegracao,
                activeChaveIntegracao,
                setActiveChaveIntegracao,
                setChaveIntegracao,
                onChangeIntegracao,
                onChangeIntegracaoBling,
                onChangeIntegracaoTiny,
                onChangeIntegracaoBlingV2,
            }}
        >
            {children}
        </IntegracaoContext.Provider>
    );
};
