import React from "react";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { formatPhone, swallDadosSalvos } from "../../../services/Functions";
import {
    Titulo,
    Texto,
    Erro,
    NumeroAtual,
    InputsContainer,
    Botoes,
} from "../box_styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Form from "../../../components/Form";
import { phoneMask } from "../../../services/Masks";
import { unmaskToNumbers } from "../../../services/Unmasks";
import { validatePhone } from "../../../services/MasksValidations";

interface PhoneBoxProps {
    onChange: (newValue: string) => void;
    setPhoneBox: (newValue: boolean) => void;
    actualPhone: string;
}

export const PhoneBox: React.FC<PhoneBoxProps> = ({
    onChange,
    setPhoneBox,
    actualPhone,
}) => {
    const { theme } = useTheme();

    const [telefone, setTelefone] = React.useState<string>("");
    const [formValid, setFormValid] = React.useState<boolean>(false);

    const handleChange = (): void => {
        onChange(telefone);
        setPhoneBox(false);
        swallDadosSalvos();
    };

    return (
        <Form className="view-PhoneBox" id="view-PhoneBoxForm" onSubmit={handleChange} setFormValid={setFormValid}>
            <Titulo theme={theme}>Alteração de Telefone</Titulo>
            <NumeroAtual theme={theme}>
                Número atual: {actualPhone} <br />
                <br />
                Informe novo número
            </NumeroAtual>
            <InputsContainer theme={theme}>
                <Input
                    type="text"
                    value={telefone}
                    mask={{
                        mask: phoneMask,
                        unmask: unmaskToNumbers,
                        maskValidation: validatePhone,
                    }}
                    onChange={(newValue) => {
                        setTelefone(newValue);
                    }}
                    placeholder="(00) 000000000"
                />
                <Botoes theme={theme}>
                    <Button
                        title="Alterar"
                        className={`botao_alterar ${formValid ? "" : "disabled"}`}
                        width="50%"
                        fontSize="2.5rem"
                        type="submit"
                    />
                    <div
                        className="cancelar"
                        onClick={() => {
                            setPhoneBox(false);
                        }}
                    >
                        Cancelar
                    </div>
                </Botoes>
            </InputsContainer>
        </Form>
    );
};
