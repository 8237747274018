import styled, { css } from "styled-components";
import { ThemeType } from "../../../types/Theme";

export const ContainerIntegracaoBlingV2 = styled.div`
  margin: 5rem 0;
  min-height: 30rem;
  border-radius: 6rem;
  color: ${({theme}) => theme.colors.brandSecondary};
  .config {
    display: flex;
    margin: 3rem 0;
    .title {
      margin-left: 2rem;
      text-align: left;
    }
  }
  .configs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin-top: 3rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .obs{
    font-size: 2rem;
    text-align: justify;
  }
  .urlContainer{
    text-align: left;
    .url{
      cursor: pointer;
      color: ${({theme}) => theme.colors.brandPrimary};
      text-align: left;
      word-break: break-all;
      &:hover{
        text-decoration: underline;
      }
    }
  }
`;

export const ModalContainer = styled.div<{theme: ThemeType}>`
  padding: 2rem 5rem;
  .titulo{
    font-size: 3rem;
    text-align: center;
    font-weight: bold;
    color: ${({theme}) => theme.colors.titleColor};
    margin-bottom: 2rem;
  }
  
  .texto{
    font-size: 2.5rem;
    text-align: justify;
    margin-bottom: 2rem;
  }
  .botoes{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancelar{
      font-size: 2.5rem;
      cursor: pointer; 
    }
  }
`
