import React, { useContext, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { copy } from "../../../services/Functions";
import { ContainerEstadosFreteGratis } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface EstadosFreteGratisProps {
  index?: number;
}

const EstadosFreteGratis: React.FC<EstadosFreteGratisProps> = ({ index }) => {
  const { fretes_gratis, setFretes_gratis } = useContext(ConfiguracoesContext);

  const { theme } = useTheme();

  const estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const handleStateClick = (estado: string) => {
    if (index !== undefined) {
      const copia = copy(fretes_gratis);

      fretes_gratis[index].por_estado.estados.includes(estado)
        ? (copia[index].por_estado.estados = copia[
            index
          ].por_estado.estados.filter((estadoAtual) => estadoAtual !== estado))
        : copia[index].por_estado.estados.push(estado);

      setFretes_gratis(copia);
    }
  };

  return (
    <>
      <ContainerEstadosFreteGratis theme={theme}>
        {index !== undefined && fretes_gratis[index] && (
          <>
            <Titulo
              titulo="Frete Grátis"
              subtitulo={"Selecione os estados para habilitar o frete grátis"}
              flexDirection="column-reverse"
            />
            <div className="flexContainer">
              {estados.map((estado, indexEstado) => {
                const isActive =
                  fretes_gratis[index].por_estado.estados.includes(estado);
                return (
                  <div
                    className={`containerEstado ${isActive && "ativo"}`}
                    key={indexEstado}
                    onClick={() => handleStateClick(estado)}
                  >
                    {estado}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </ContainerEstadosFreteGratis>
    </>
  );
};

export default EstadosFreteGratis;
