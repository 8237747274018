import styled, { css } from "styled-components";

const commonStyles = css`
  display: flex;
  flex-direction: column;
  width: 100rem;
  max-width: 90vw;
  .dropdown {
    display: contents;
    .intervalos {
      position: relative;
      padding-bottom: 5rem;
      margin-bottom: 5rem;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 40rem;
        height: 2px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        background-color: ${(props) => props.theme.colors.elements};
        border-radius: 50px;
      }

      .diasContainer {
        display: contents;
        > label {
          display: none;
        }
        > .flexDias {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          > .dia {
            width: 5rem;
            height: 5rem;
            display: flex;
            align-items: center;
            border-radius: 50%;
            color: ${(props) => props.theme.colors.white};
            font-size: 2.5rem;
            justify-content: center;
            background-color: ${(props) => props.theme.colors.elements};
            cursor: pointer;
          }

          > .ativo {
            color: ${(props) => props.theme.colors.brandPrimary};
            border: 1px solid ${(props) => props.theme.colors.brandPrimary};
          }

          > .disabled {
            opacity: 0.5;
            color: ${(props) => props.theme.colors.elements};
            background-color: ${(props) => props.theme.colors.brandSecondary};
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
      .horariosContainer {
        display: contents;
        > .horariosFlex {
          display: flex;
          flex-direction: column;
          gap: 5rem;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding: 2rem 4rem;

          > .horarios {
            gap: 3rem;
            width: 45rem;
            display: flex;
            align-items: center;
            position: relative;

            > .x {
              margin-top: 4rem;
              right: -3rem;
              position: absolute;
              cursor: pointer;
              font-size: 2rem;
              color: ${(props) => props.theme.colors.brandSecondary};
            }
          }
        }
      }
    }

    .adicionar {
      cursor: pointer;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`;

export const ContainerRetiradaNoLocalContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      max-width: none;
      .dropdown {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        width: 100%;
        padding: 4rem 0 0 0;
        .adicionar {
          text-align: left;
        }
        .intervalos {
          margin: 0;
          padding: 0;
          row-gap: 2rem;
          column-gap: 2rem;
          width: 100%;
          display: flex;
          @media (max-width: 604px) {
            flex-wrap: wrap;
          }
          .diasContainer {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 2rem;
            flex-direction: column;
            > label {
              display: block;
              font-size: 2.5rem;
              color: ${(props) => props.theme.colors.brandSecondary};
            }
          }
          .adicionar {
            align-self: center;
            padding-top: 4rem;
            justify-self: center;
            white-space: nowrap;
          }
          .horariosContainer {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-end;
            column-gap: 4rem;
            row-gap: 1rem;
            .horariosFlex {
              width: calc(50% - 4rem);
              min-width: 40rem;
              padding: 0;
              .horarios {
                gap: 1rem;
                width: 100%;
                .x {
                  position: static;
                }
              }
            }
            .adicionar {
              width: calc(50% - 4rem);
              min-width: 40rem;
              text-align: center;
            }
            @media (max-width: 580px) {
              justify-content: flex-start;
            }
          }
          &::after {
            display: none;
          }
        }
      }
    `}
`;
