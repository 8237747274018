import React, { useEffect, useState, useRef } from "react";
import { Input } from "../Input";
import { ReactComponent as Plus } from "../../assets/newImages/icons/plus.svg";
import { ReactComponent as Copy } from "../../assets/newImages/icons/copy.svg";
import { ListasPreco, ViewVariacoes } from "./styles.ts";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { currencyMask, stringNumber } from "../../services/Masks.tsx";
import {
  unmaskCurrency,
  unmaskToInt,
  unmaskToNumbers,
} from "../../services/Unmasks";
import Swal from "sweetalert2";

interface VariacoesInputProps {
  listasDePreco: any[];
  nome: string;
  variacoesPrecos: any[];
  index: number;
  estoque: string;
  onChangePreco: (newValue: any) => void;
  onChangeEstoque: (newValue: any) => void;
  onChangeNomeVariacao: (newValue: any) => void;
  temEstoque: boolean;
  quantidade_variacoes: number;
  deletaVariacao: (index: number) => void;
  novaListaDePreco: () => void;
  replicaEstoques: (estoque) => void;
  replicaNomeVariacoes: (nome) => void;
  replicaPrecoTabela: (preco, index) => void;
  permitir_edicao: boolean;
}

export const VariacoesInput: React.FC<VariacoesInputProps> = ({
  temEstoque,
  variacoesPrecos,
  permitir_edicao,
  ...props
}) => {
  const { theme } = useTheme();

  const [index_variacao, setIndexVariacao] = useState(props.index);
  const [nomeVariacao, setNomeVariacao] = useState(props.nome);
  const [listasDePrecoVariacao, setListasDePrecoVariacao] = useState(
    props.listasDePreco
  );

  const replicarRefs = useRef<(HTMLDivElement | null)[]>(
    variacoesPrecos.map(() => null)
  );

  const handleFocus = (index: number) => {
    const currentRef = replicarRefs.current[index];
    if (currentRef) {
      currentRef.classList.add("visible");
    }
  };

  const handleBlur = (index: number) => {
    const currentRef = replicarRefs.current[index];
    if (currentRef) {
      currentRef.classList.remove("visible");
    }
  };

  const replicarRefNome = useRef<HTMLDivElement>(null);
  const replicarRefEstoque = useRef<HTMLDivElement>(null);

  const [quantidade_variacoes, setQuantidadeVariacoes] = useState(
    props.quantidade_variacoes
  );
  const [estoque, setEstoque] = useState(props.estoque || "0");

  function onChangePreco(newValue: any) {
    props.onChangePreco(newValue);
  }

  function onChangeEstoque(newValue: any) {
    props.onChangeEstoque(newValue);
  }

  function onChangeNomeVariacao(newValue: any) {
    props.onChangeNomeVariacao(newValue);
  }

  useEffect(() => {
    setNomeVariacao(props.nome);
    setListasDePrecoVariacao(props.listasDePreco);
    setEstoque(props.estoque);
  }, [props]);

  return (
    <>
      {(index_variacao === 0 || quantidade_variacoes === 1) && (
        <ListasPreco theme={theme}>
          <div className="nomes_lista">
            <div className="nome">Nome da variação</div>
          </div>
          {temEstoque && (
            <div className="nomes_lista">
              <div className="nome">Estoque</div>
            </div>
          )}
          {listasDePrecoVariacao.map((lista, index) => {
            return (
              <div className={`nomes_lista`}>
                <div className="nome">Preço {lista.descricao}</div>
              </div>
            );
          })}
          <div className="nova_lista">
            <div
              className="mais"
              onClick={() => {
                if(!permitir_edicao) return;
                props.novaListaDePreco();
              }}
              style={
                !permitir_edicao
                  ? { cursor: "not-allowed", opacity: "0.5" }
                  : {}
              }
            >
              <Plus className="plus" />
            </div>
          </div>
        </ListasPreco>
      )}
      <ViewVariacoes theme={theme}>
        <div
          className="nome_variacao"
          style={{ opacity: nomeVariacao ? 1 : 0.5 }}
        >
          <Input
            sizeLabel="medium"
            type="text"
            name="nome"
            disabled={!permitir_edicao}
            value={nomeVariacao}
            onChange={(newValue) => {
              setNomeVariacao(newValue);
              onChangeNomeVariacao({
                index_variacao: index_variacao,
                nome: newValue,
              });
            }}
            onFocus={() => {
              if (replicarRefNome.current) {
                replicarRefNome.current.classList.add("visible");
              }
            }}
            onBlur={() => {
              if (replicarRefNome.current) {
                replicarRefNome.current.classList.remove("visible");
              }
            }}
            placeholder="Nome da variação"
          />
          <div
            className="replicar"
            ref={replicarRefNome}
            onClick={() => {
              props.replicaNomeVariacoes(nomeVariacao);
            }}
          >
            <Copy /> Aplicar em todas
          </div>
        </div>

        {temEstoque ? (
          <div className="nome_variacao">
            <Input
              sizeLabel="medium"
              type="text"
              name="nome"
              value={estoque}
              disabled={!permitir_edicao}
              mask={{ mask: stringNumber }}
              onFocus={() => {
                if (replicarRefEstoque.current) {
                  replicarRefEstoque.current.classList.add("visible");
                }
              }}
              onBlur={() => {
                if (replicarRefEstoque.current) {
                  replicarRefEstoque.current.classList.remove("visible");
                }
              }}
              onChange={(newValue) => {
                if (newValue == "") newValue = "0";
                setEstoque(newValue);
                onChangeEstoque({
                  index_variacao: index_variacao,
                  estoque: newValue,
                });
              }}
              placeholder="Estoque"
            />
            <div
              className="replicar"
              ref={replicarRefEstoque}
              onClick={() => {
                props.replicaEstoques(estoque);
              }}
            >
              <Copy /> Aplicar em todas
            </div>
          </div>
        ) : (
          <></>
        )}
        {variacoesPrecos.map((preco, index) => {
          return (
            <>
              <div className="preco_variacao" key={index}>
                <Input
                  sizeLabel="medium"
                  type="text"
                  name="nome"
                  disabled={!permitir_edicao}
                  mask={{
                    mask: currencyMask,
                    unmask: unmaskToInt,
                    param:
                      listasDePrecoVariacao[index] &&
                      listasDePrecoVariacao[index].moeda
                        ? listasDePrecoVariacao[index].moeda
                        : "R$",
                  }}
                  value={preco.preco}
                  onChange={(newValue) => {
                    preco.preco = newValue;
                    onChangePreco({
                      preco: newValue,
                      index_variacao: index_variacao,
                      index_preco: index,
                    });
                  }}
                  onFocus={() => handleFocus(index)}
                  onBlur={() => handleBlur(index)}
                  placeholder="R$ 00,00"
                />
                <div
                  className="replicar"
                  ref={(el) => (replicarRefs.current[index] = el)}
                  onClick={() => {
                    props.replicaPrecoTabela(preco.preco, index);
                  }}
                >
                  <Copy /> Aplicar em todas
                </div>
              </div>
            </>
          );
        })}
        {permitir_edicao && (
          <div
            className="deleta_variacao"
            onClick={() => {
              Swal.fire({
                title: "Tem certeza que deseja excluir essa variação?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Excluir",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  props.deletaVariacao(index_variacao);
                }
              });
            }}
          >
            <span></span>
          </div>
        )}
      </ViewVariacoes>
    </>
  );
};
