import { Node } from "../../types/Node";

const nodes: Node[] = [
  {
    id: "suaempresa",
    name: "Sua Empresa",
    children: [
      {
        id: "informacoesdamarca",
        name: "Informações da Marca",
      },
      {
        id: "endereco",
        name: "Endereço",
      },
    ],
  },
  {
    id: "entregaeretirada",
    name: "Entrega e Retirada",
    children: [
      {
        id: "correios",
        name: "Correios",
      },
      {
        id: "melhorenvio",
        name: "Melhor Envio",
      },
      {
        id: "entregalocal",
        name: "Entrega Local",
      },
      {
        id: "entregaespecial",
        name: "Entrega Especial",
      },
      {
        id: "fretegratis",
        name: "Frete Grátis",
      },
      {
        id: "retiradanolocal",
        name: "Retirada no Local",
      },
    ],
  },
  {
    id: "pagamento",
    name: "Pagamento",
    children: [
      {
        id: "pagamentoonline",
        name: "Pagamento Online",
      },
      {
        id: "pagamentoacombinar",
        name: "Pagamento a Combinar",
      },
    ],
  },
  {
    id: "configureseucatalogo",
    name: "Configure seu Catálogo",
    children: [
      {
        id: "pedido",
        name: "Pedido",
      },
      {
        id: "cupons",
        name: "Cupons",
      },
      {
        id: "politicadeprivacidade",
        name: "Política de Privacidade",
      },
      {
        id: "politicadetrocaedevolucao",
        name: "Política de Troca e Devolução",
      },
      {
        id: "compartilhamento",
        name: "Compartilhamento",
      },
      {
        id: "visual",
        name: "Visual",
      },
    ],
  },
  {
    id: "integracoes",
    name: "Integrações",
    children: [
      {
        id: "chaveintegracao",
        name: "Chave de API",
      },
      {
        id: "integracaodobling",
        name: "Integração do Bling",
      },
      {
        id: "integracaodatiny",
        name: "Integração da Tiny",
      },
      {
        id: "verificacoesdedominio",
        name: "Verificações de Domínio",
      },
      {
        id: "facebookpixel",
        name: "Integração Meta",
      },
      {
        id: "google",
        name: "Integração Google",
      },
    ],
  },
];

export default nodes;
