import { UsuarioContext } from "./UsuarioContext";
import { useState, useEffect } from "react";
import { authApi } from "../../hooks/authApi";

export const UsuarioProvider = ({ children }: { children: JSX.Element }) => {
  const [nome, setNome] = useState<String>("");

  return (
    <UsuarioContext.Provider value={{ nome }}>
      {children}
    </UsuarioContext.Provider>
  );
};
