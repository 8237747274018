import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { obterMiniaturaVideoYouTube } from "../../services/Functions";
import { Produto as ProdutoType } from "../../types/Produto";
import ImgWithLoading from "../ImgWithLoading/ImgWithLoading";
import ProdutoWrapper from "./styles";
interface ProdutoProps {
  produto: ProdutoType;
  children?: React.ReactNode;
  moeda?: string;
  onClick?: (e) => void;
  openImage?: boolean;
}

const Produto: React.FC<ProdutoProps> = ({ produto, moeda="R$",children, onClick, openImage }) => {
  const { theme } = useTheme();

  const [loaded, setLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (produto.imagens[0] && produto.imagens[0].includes("youtube.com")) {
      setImgSrc(obterMiniaturaVideoYouTube(produto.imagens[0]));
    } else {
      setImgSrc(produto.imagens[0]);
    }
  }, [produto]);

  const MySwal = withReactContent(Swal);
  
  const handleImageClick = () => {
    if (!imgSrc || !openImage) return;
    MySwal.fire({
      title: produto.nome,
      html: `<img src=${imgSrc.replace("{size}", "900")} style="width: 100%; height: 100%; object-fit: contain; border-radius: 4rem"/>`,
    });
  };

  return (
    <ProdutoWrapper className="produto-wrapper" onClick={onClick} theme={theme}>
      <div className="produto">
        <div className="imagem">
          <ImgWithLoading
            onClick={handleImageClick}
            src={imgSrc}
            alt="Imagem do produto"
            loadingClass="skeleton"
          />
        </div>
        <div className="conteudo-container">
          <div className="texto-container">
            <div className="texto">{produto.associacao}</div>
            <div className="texto referencia">
              <span>
                {produto.referencia} 
                {produto.preco && (
                  <>
                    <br /> {`${Number(produto.preco.replace(",", ".")) > 0 ? `${moeda}${produto.preco}` : "A combinar"}`}
                  </>
                )}
              </span>
            </div>
            <div className="texto texto-estoque">
              {Number.parseInt(produto.estoque) >= 0 ? (
                <>Estoque: {produto.estoque}</>
              ) : (
                <>Sem controle de estoque</>
              )}
            </div>
          </div>
          {children && children}
        </div>
      </div>
    </ProdutoWrapper>
  );
};

export default Produto;
