import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';
import { rgba } from "../../services/Functions";
import theme from '../../Themes/light';

const NumeroInternacionalContainer = styled.div<{ theme: ThemeType }>`
  .input-container-nacional {
    background-color: red;
    width: 100% !important;
  }

  .iti {
    display: block;
  }

  .iti__selected-country {
    background-color: ${({theme}) => theme.primaryColor};
    color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};

  }

  .iti__tel-input {
    display: block;
    font-size: 2.5rem;
    width: 100%;
    border-radius: 10rem;
    border: 1px solid transparent;
    color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};
    background-color: ${(props) => props.theme.colors.white};
    padding: 1rem 4rem;
    padding-left: 17rem !important;
    height: 6rem;

    &::placeholder {
      color: ${(props) => props.theme.colors.placeholder};
      outline: none;
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.brandPrimary};
      color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};
    }
    &:disabled {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.placeholder};
    }
  }
  .iti__tel-input:focus {
    outline: none;
    border-bottom: 1px solid #36945C;
    color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};
  }
  .iti__tel-input:focus-visible {
    outline: none;
    box-shadow: none;
  }

  .iti__country-container {
    padding-left: 20px;
  }
  .iti__dropdown-content {
    overflow-x: hidden;
    color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 3rem;
    width: 775px !important;
    z-index: 205;
    padding: 0rem 0rem;
    margin-top: 0px !important;
    position: absolute; 
    border: none;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    border-top: 1px solid ${(props) => props.theme.colors.brandPrimary};


    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.theme.colors.brandPrimary};
      color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};
    }
  }
  .iti__country-list {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: ${({theme}) => theme.colors.elements} transparent;

    &::-webkit-scrollbar {
        width: 25px;
        display: block;
    }
    &::-webkit-scrollbar-button {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        border: 10px solid rgba(0, 0, 0, 0) !important;
        background-clip: padding-box;

        border-radius: 25px;
        background-color: ${({theme}) => theme.colors.elements};
    }
    .iti__highlight {
      background-color: ${rgba(theme.colors.titleColor, 0.3)} !important;
      
    }
  }
  .iti__search-input:focus-visible {
    outline: none;
    box-shadow: none;
  }
  .iti__search-input {
    color: ${({theme}) => theme.mode == "light" ? "unset" : theme.colors.brandSecondary};
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export default NumeroInternacionalContainer;
