import styled from "styled-components";

export const ContainerPrincipal = styled.div`
  .containerPaginas {
    padding-left: clamp(120px, 7%, 9999px);
    position: relative;
    right: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
      padding-left: 0;
      width: 100%;
    }
  }
`;
