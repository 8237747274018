import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import { Button } from "../../../components/Button";
import { FooterPaginas } from "../../../components/FooterPaginas/FooterPaginas";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import CodigoRastreio from "../../../components/Pedido/CodigoRastreio/CodigoRastreio.tsx";
import NotaFiscal from "../../../components/Pedido/NotaFiscal/NotaFiscal.tsx";
import PrintablePedido from "../../../components/PrintablePedido/PrintablePedido.tsx";
import { SelectPedidos } from "../../../components/Select/SelectPedidos";
import { SkeletonPedidoCesta } from "../../../components/Skeletons/SkeletonPedidoCesta/SkeletonPedidoCesta";
import TableProdutos from "../../../components/TableProdutos/TableProdutos.tsx";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import { MelhorEnvioApi } from "../../../hooks/melhorEnvio.ts";
import { PedidosApi } from "../../../hooks/pedidosApi";
import { cepMask, cnpjMask, cpfMask, phoneMask } from "../../../services/Masks";
import Pagamento from "../../../types/Pagamento";
import PedidoUnico from "../../../types/PedidoUnico";
import { ContainerPedido } from "./styles.ts";

interface PedidoProps {}

const Pedido: React.FC<PedidoProps> = ({}) => {
  const { theme } = useTheme();
  const pedidosApi = PedidosApi();
  const melhorEnvioApi = MelhorEnvioApi();
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const { id } = useParams<{ id: string }>();
  const [observacaoInterna, setObservacaoInterna] = useState("");
  const [codigoRastreio, setCodigoRastreio] = useState("");
  const [loadingPrinting, setLoadingPrinting] = useState(false);
  const printableRef = useRef<HTMLDivElement>(null);
  const meiosDePagamento = {
    pix: "Pix",
    cartao: "Cartão",
    a_combinar: "A combinar",
  };
  const intermediadores = {
    pagseguro: "PagSeguro",
    pagarme: "Pagarme",
  };

  const [pedido, setPedido] = useState<PedidoUnico>();
  const [informacoesPagamento, setInformacoesPagamento] = useState<
    Pagamento | undefined
  >();
  const [situacoes, setSituacoes] = useState<
    Array<{ value: number; name: string; quantidade?: number }>
  >([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingObs, setLoadingObs] = useState(false);

  const [formValidObs, setFormValidObs] = useState(false);

  const cores = {
    // cores conforme o id da situação no banco
    1: "#FFBE9D",
    7: "#E7BE00",
    8: "#4DE889",
    4: "#00A2BB",
    5: "#0093FB",
    6: "#FF6E28",
    2: "#0D5229",
    3: "#9BA9FB",
    9: "#FF0000",
  };

  const init = async () => {
    if (!id) return;
    const [pedido, situacoes, informacoesPagamento] = await Promise.all([
      pedidosApi.getPedido(id),
      pedidosApi.getSituacoes(),
      pedidosApi.getInformacoesPagamento(id),
    ]);
    setPedido(pedido);

    setSituacoes(
      situacoes.map((situacao: any) => ({
        name: situacao.descricao,
        value: situacao.id.toString(),
      }))
    );
    setInformacoesPagamento(informacoesPagamento);

    setObservacaoInterna(pedido.observacao_interna);
    setCodigoRastreio(pedido.frete.codigo_rastreio || "");

    setLoadingSkeleton(false);
  };


  const handleSituacaoChange = async (situacao: string) => {
    if (!id || pedido?.situacao === situacao) return;
    if (situacao === "3") {
      handleCancelarPedido();
    } else {
      setLoading(true);
      await pedidosApi.alterarSituacao([id], Number.parseInt(situacao)).then(
        (res) => {
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            title: "Situação do pedido alterada com sucesso!",
          });
          setPedido({ ...pedido!, situacao: situacao });
          setLoading(false);
        },
        (err) => {
          Swal.fire({
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
            title: "Erro ao alterar situação do pedido!",
          });
          setLoading(false);
        }
      );
    }
  };

  const handleOnSubmitObservacaoInterna = async (observacao: string) => {
    setLoadingObs(true);
    id &&
      pedidosApi.atualizarObservacaoInternaPedido(id, observacao).then(
        (res) => {
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            title: "Observação interna atualizada com sucesso!",
          });
          setLoadingObs(false);
        },
        (err) => {
          Swal.fire({
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
            title: "Erro ao atualizar observação interna!",
          });
          setLoadingObs(false);
        }
      );
  };

  const handleExportarPedido = async () => {
    if (!id) return;
    pedidosApi
      .exportarPedidos([id])
      .then((res) => {
        window.open(res[0], "_blank");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          title: "Erro ao exportar pedido!",
        });
      });
  };

  const handleCancelarPedido = async () => {
    if (!id) return;
    // Cancelado
    Swal.fire({
      title: "Tem certeza que deseja cancelar este pedido?",
      text: "Esta ação não poderá ser desfeita e irá repor os estoques dos produtos caso você utilize.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Cancelar pedido",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      setLoading(true);
      if (result.isConfirmed) {
        pedidosApi.alterarSituacao([id], Number.parseInt("3")).then(
          (res) => {
            setPedido({
              ...pedido!,
              situacao: "3",
              situacao_descricao: "Cancelado",
            });
            Swal.fire({
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              title: "Pedido cancelado com sucesso!",
            });
            setLoading(false);
          },
          (err) => {
            Swal.fire({
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              title: "Erro ao cancelar pedido!",
            });
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
    });
  };

  const changeFretes = (codigo: string, url_etiqueta) => {
    setPedido({
      ...pedido!,
      frete: { ...pedido?.frete, codigo_rastreio: codigo, url_etiqueta } as any,
    });
  };

  const memoizedPedido = useMemo(() => pedido, [pedido, observacaoInterna]);
  const memoizedInformacoesPagamento = useMemo(
    () => informacoesPagamento,
    [informacoesPagamento]
  );

  useEffect(() => {
    init();
  }, []);

  const switchFormasDePagamento = () => {
    switch (informacoesPagamento?.tipo) {
      case "cartao":
        return (
          <>
            {informacoesPagamento?.intermediador ||
            informacoesPagamento?.tipo ? (
              <div className="column-container">
                <div className="titulo-pedido">Forma de pagamento</div>
                <div className="subtitulo-pedido">
                  {intermediadores[informacoesPagamento.intermediador] || ""},{" "}
                  {meiosDePagamento[informacoesPagamento.tipo] || ""}
                </div>
              </div>
            ) : null}
            {informacoesPagamento?.cartao && informacoesPagamento?.parcelas ? (
              <div className="column-container">
                <div className="titulo-pedido">Parcelas</div>
                <div className="subtitulo-pedido">
                  {`${
                    informacoesPagamento.cartao[0].toUpperCase() +
                    informacoesPagamento.cartao.slice(1).toLowerCase()
                  } x${informacoesPagamento.parcelas}`}
                </div>
              </div>
            ) : null}
            {informacoesPagamento?.id_transacao ? (
              <div className="column-container">
                <div className="titulo-pedido">ID da transação</div>
                <div className="subtitulo-pedido">
                  {informacoesPagamento.id_transacao}
                </div>
              </div>
            ) : null}
          </>
        );
      case "pix":
        return (
          <>
            {informacoesPagamento?.intermediador ||
            informacoesPagamento?.tipo ? (
              <div className="column-container">
                <div className="titulo-pedido">Forma de pagamento</div>
                <div className="subtitulo-pedido">
                  {intermediadores[informacoesPagamento.intermediador] || ""},{" "}
                  {meiosDePagamento[informacoesPagamento.tipo] || ""}
                </div>
              </div>
            ) : null}
            {informacoesPagamento?.id_transacao ? (
              <div className="column-container">
                <div className="titulo-pedido">ID da transação</div>
                <div className="subtitulo-pedido">
                  {informacoesPagamento.id_transacao}
                </div>
              </div>
            ) : null}
            {informacoesPagamento?.pix_code ? (
              <div className="column-container">
                <div className="titulo-pedido">Enviar chave pix</div>
                <div className="pix-div">
                  <Button
                    className="botao"
                    padding="5rem"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        informacoesPagamento.pix_code
                      )
                    }
                    title={"Copiar chave"}
                  />
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=55${pedido?.cliente.telefone}&text=${informacoesPagamento.pix_code}`}
                  >
                    <img
                      className="icone"
                      src={
                        require("../../../assets/newImages/icons/whatsapp.svg")
                          .default
                      }
                      alt=""
                    />
                  </a>
                  <a
                    target="_blank"
                    href={`https://telegram.me/share/url?url=ht&text=${informacoesPagamento.pix_code}`}
                  >
                    <img
                      className="icone"
                      src={
                        require("../../../assets/newImages/icons/telegram-alt.svg")
                          .default
                      }
                      alt=""
                    />
                  </a>
                </div>
              </div>
            ) : null}
          </>
        );
        break;
      case "a_combinar":
        return (
          <>
            {informacoesPagamento?.tipo ? (
              <div className="column-container">
                <div className="titulo-pedido">Forma de pagamento</div>
                <div className="subtitulo-pedido">
                  {meiosDePagamento[informacoesPagamento.tipo] || ""}
                </div>
              </div>
            ) : null}
          </>
        );

      default:
        return <></>;
        break;
    }
  };

  const formatarTipoFrete = (frete: string | undefined) => {
    if (!frete) return "Não informado";

    const fretesLabels = {
      entrega_local: "Entrega local",
      frete_gratis: "Frete grátis",
      retirada_no_local: "Retirada no local",
      a_combinar: "A combinar",
    };

    frete.startsWith("especial_")
      ? (frete = `Especial - ${frete.replace("especial_", "")}`)
      : (frete = fretesLabels[frete]
          ? fretesLabels[frete]
          : frete.toUpperCase());

    return frete;
  };

  return (
    <>
      {loadingSkeleton ? (
        <SkeletonPedidoCesta />
      ) : (
        <ContainerPedido theme={theme}>
          <div className="flex-container flex-titulo">
            <div className="subtitulo-pedido">
              <span>Pedido #{pedido?.id_pedido || "????"}</span>
            </div>
            {pedido?.situacao === "3" ? (
              <div className="subtitulo-pedido">
                <div
                  className="bolinha"
                  style={{
                    backgroundColor:
                      cores[Number.parseInt(pedido?.situacao!) || 0],
                  }}
                />
                {pedido?.situacao_descricao || ""}
              </div>
            ) : (
              <span className="select-pedido-unico">
                <SelectPedidos
                  options={
                    pedido?.situacao ===
                    situacoes
                      .find((situacao) => situacao.name === "Excluído")
                      ?.value.toString()
                      ? situacoes
                      : situacoes.filter(
                          (situacao) => situacao.name !== "Excluído"
                        )
                  }
                  loading={loading}
                  value={pedido?.situacao?.toString()}
                  onChange={handleSituacaoChange}
                  cores={cores}
                />
              </span>
            )}
          </div>
          <div className="linha"></div>
          <div className="flex-container flex-titulo">
            <div className="titulo-pedido">
              Data: {moment(pedido?.data).format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <div className="titulo-pedido">Tabela: {pedido?.tabela || ""}</div>
          </div>
          <div className="container-branco">
            <div className="flex-container">
              {pedido?.cliente.nome || pedido?.cliente.razao_social ? (
                <div className="column-container">
                  <div className="titulo-pedido">Cliente</div>
                  <div className="subtitulo-pedido">
                    <span>
                      {pedido?.cliente.nome || pedido?.cliente.razao_social}
                    </span>
                  </div>
                </div>
              ) : null}

              {pedido?.cliente.telefone ? (
                <div className="column-container">
                  <div className="titulo-pedido">Telefone</div>
                  <div className="subtitulo-pedido">
                    <a
                      target="blank"
                      href={`https://api.whatsapp.com/send?phone=55${pedido?.cliente.telefone}`}
                    >
                      {phoneMask(pedido?.cliente.telefone)}
                    </a>
                  </div>
                </div>
              ) : null}

              {pedido?.cliente.email ? (
                <div className="column-container">
                  <div className="titulo-pedido">Email</div>
                  <div className="subtitulo-pedido">
                    <a href={`mailto:${pedido?.cliente.email}`} target="_blank">
                      {pedido?.cliente.email}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="flex-container">
              {pedido?.cliente.cpf || pedido?.cliente.cnpj ? (
                <div className="column-container">
                  <div className="titulo-pedido">CPF/CNPJ</div>
                  <div className="subtitulo-pedido">
                    {cpfMask(pedido?.cliente.cpf || "") ||
                      cnpjMask(pedido?.cliente.cnpj || "")}
                  </div>
                </div>
              ) : null}

              {pedido?.cliente.inscricao_estadual ? (
                <div className="column-container">
                  <div className="titulo-pedido">Inscrição Estadual</div>
                  <div className="subtitulo-pedido">
                    {pedido?.cliente.inscricao_estadual}
                  </div>
                </div>
              ) : null}

              {pedido?.cliente.data_nascimento ? (
                <div className="column-container">
                  <div className="titulo-pedido">Data de nascimento</div>
                  <div className="subtitulo-pedido">
                    {moment(pedido?.cliente.data_nascimento).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="linha-menor"></div>

            <div className="flex-container">
              {pedido?.cliente.cep ? (
                <div className="column-container">
                  <div className="titulo-pedido">CEP</div>
                  <div className="subtitulo-pedido">
                    {cepMask(pedido?.cliente.cep)}
                  </div>
                </div>
              ) : null}
              {pedido?.cliente.cidade && pedido?.cliente.estado ? (
                <div className="column-container">
                  <div className="titulo-pedido">Cidade</div>
                  <div className="subtitulo-pedido">
                    {pedido?.cliente.cidade} / {pedido?.cliente.estado}
                  </div>
                </div>
              ) : null}
              {pedido?.cliente.bairro ? (
                <div className="column-container">
                  <div className="titulo-pedido">Bairro</div>
                  <div className="subtitulo-pedido">
                    {pedido?.cliente.bairro}
                  </div>
                </div>
              ) : null}

              {pedido?.cliente.endereco || pedido?.cliente.numero ? (
                <div className="column-container">
                  <div className="titulo-pedido">Endereço</div>
                  <div className="subtitulo-pedido">
                    {pedido?.cliente.endereco}, {pedido?.cliente.numero}
                    {pedido?.cliente.complemento
                      ? `, ${pedido?.cliente.complemento}`
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="container-branco">
            <div className="flex-container">
              {pedido?.catalogo?.descricao ? (
                <div className="column-container">
                  <div className="titulo-pedido">Catálogo</div>
                  <div className="subtitulo-pedido">
                    {pedido?.catalogo.descricao}
                  </div>
                </div>
              ) : null}
              {pedido?.nome ? (
                <div className="column-container">
                  <div className="titulo-pedido">Vendedor</div>
                  <div className="subtitulo-pedido">{pedido?.nome}</div>
                </div>
              ) : null}
              {pedido?.catalogo?.identificacao ? (
                <div className="column-container">
                  <div className="titulo-pedido">Código do catálogo</div>
                  <a
                    href={`${empresa?.dominio_https}${pedido?.catalogo.identificacao}`}
                    target="_blank"
                    className="subtitulo-pedido"
                  >
                    {pedido?.catalogo.identificacao}
                  </a>
                </div>
              ) : null}
            </div>
          </div>

          {(pedido?.erp_info && (
            <div className="container-branco">
              <div className="flex-container">
                {pedido?.erp_info ? (
                  <div className="column-container">
                    <div className="titulo-pedido">
                      Status sincronização ERP
                    </div>
                    <div className="subtitulo-pedido">{pedido?.erp_info}</div>
                  </div>
                ) : null}
              </div>
            </div>
          )) ||
            null}

          <div className="container-branco">
            <div className="flex-container">
              {pedido?.valor_total ? (
                <div className="column-container">
                  <div className="titulo-pedido">Valor final</div>
                  <div className="subtitulo-pedido">
                    <span>
                      {`${pedido.moeda} ${Number.parseFloat(
                        informacoesPagamento?.valor_cobranca / 100
                      )
                        .toFixed(2)
                        .replace(".", ",")}`}
                    </span>
                  </div>
                </div>
              ) : null}
              {pedido?.valor_total ? (
                <div className="column-container">
                  <div className="titulo-pedido">Valor dos produtos</div>
                  <div className="subtitulo-pedido">
                    {`${pedido.moeda} ${Number.parseFloat(pedido?.valor_total)
                      .toFixed(2)
                      .replace(".", ",")}`}
                  </div>
                </div>
              ) : null}
              {pedido?.frete?.valor_frete ? (
                <div className="column-container">
                  <div className="titulo-pedido">Valor do Frete</div>
                  <div className="subtitulo-pedido">
                    {`${pedido.moeda} ${Number.parseFloat(
                      pedido?.frete.valor_frete
                    )
                      .toFixed(2)
                      .replace(".", ",")}`}
                  </div>
                </div>
              ) : null}
              {informacoesPagamento?.desconto_cupom > 0 ? (
                <div className="column-container">
                  <div className="titulo-pedido">Desconto Cupom</div>
                  <div className="subtitulo-pedido">
                    {`${pedido?.moeda} ${(
                      (Number.parseFloat(
                        informacoesPagamento?.desconto_cupom || "0"
                      ) /
                        100) *
                        Number.parseFloat(pedido?.valor_total) || 0
                    )
                      .toFixed(2)
                      .replace(".", ",")}`}
                  </div>
                </div>
              ) : null}
              {informacoesPagamento?.desconto > 0 ? (
                <div className="column-container">
                  <div className="titulo-pedido">Desconto do pix</div>
                  <div className="subtitulo-pedido">
                    {`${pedido?.moeda} ${(
                      (Number.parseFloat(
                        informacoesPagamento?.desconto || "0"
                      ) /
                        100) *
                        Number.parseFloat(pedido?.valor_total) || 0
                    )
                      .toFixed(2)
                      .replace(".", ",")}`}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="linha-menor"></div>
            <div className="flex-container">{switchFormasDePagamento()}</div>
            <div className="linha-menor"></div>
            <div className="flex-container">
              {pedido?.frete ? (
                <>
                  <div className="column-container">
                    <div className="titulo-pedido">Tipo de frete</div>
                    <div className="subtitulo-pedido">
                      {formatarTipoFrete(pedido?.frete.tipo_frete)}
                    </div>
                  </div>
                  {pedido?.frete.id_frete_tipo == "5" && (
                    <div className="column-container center">
                      <div className="titulo-pedido"></div>
                      <div className="subtitulo-pedido">
                        {pedido?.frete.url_etiqueta ? (
                          <a
                            href={pedido?.frete.url_etiqueta}
                            target="_blank"
                            className="subtitulo-pedido"
                          >
                            <Button
                              title="Imprimir etiqueta"
                              className="botao"
                              fontSize="2.5rem"
                            />
                          </a>
                        ) : (
                          <NotaFiscal
                            pedido={pedido}
                            empresa={empresa!.tipo_pessoa}
                            changeFretes={changeFretes}
                            frete={formatarTipoFrete(pedido?.frete.tipo_frete)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {pedido?.frete.tipo_frete !== "a_combinar" &&
                    pedido?.frete.tipo_frete !== "retirada_no_local" && (
                      <div className="column-container">
                        <div className="titulo-pedido">Código de rastreio</div>
                        <div className="subtitulo-pedido">
                          <CodigoRastreio
                            codigo={pedido?.frete.codigo_rastreio}
                            disabled={pedido?.frete.url_etiqueta != ""}
                            id_pedido={pedido.id_pedido || ""}
                          />
                        </div>
                      </div>
                    )}
                  <div className="column-container">
                    <div className="titulo-pedido">Prazo de entrega</div>
                    <div className="subtitulo-pedido">
                      {pedido?.frete.prazo_frete && Number(pedido?.frete.prazo_frete) > 0 ? (
                        <>
                          {Number(pedido?.frete.prazo_frete) + Number(pedido?.frete.dias_entrega_adicionais)} dia(s)
                        </>
                      ) : (
                        "Não informado"
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="container-branco">
            <TableProdutos
              produtos={pedido?.produtos || []}
              moeda={pedido?.moeda || "R$"}
              template="principal"
            />
          </div>

          <div className="container-branco">
            {pedido?.cliente.observacao && (
              <>
                <div className="flex-container">
                  <div className="column-container">
                    <div className="titulo-pedido">Observação do pedido</div>
                    <div className="subtitulo-pedido">
                      {pedido?.cliente.observacao}
                    </div>
                  </div>
                </div>
                <div className="linha-menor"></div>
              </>
            )}
            <div className="flex-container">
              <div className="titulo-pedido">Observação interna</div>
            </div>
            <Form
              id={"form-gravar-obs"}
              setFormValid={setFormValidObs}
              onSubmit={() =>
                handleOnSubmitObservacaoInterna(observacaoInterna)
              }
            >
              <div className="obs-gravar">
                <div className="input-div">
                  <Input
                    placeholder="Insira aqui a observação sobre esse pedido. Essa observação não fica visível para o cliente."
                    onChange={(newValue) => setObservacaoInterna(newValue)}
                    value={observacaoInterna}
                    type={"text"}
                  />
                </div>
                <Button type="submit" title="Gravar" loading={loadingObs} />
              </div>
            </Form>
          </div>
          <FooterPaginas>
            <div className="flex-botoes-pedido">
              <div className="flex-esquerda">
                <ReactToPrint
                  onAfterPrint={() => setLoadingPrinting(false)}
                  onBeforeGetContent={() => setLoadingPrinting(true)}
                  onPrintError={() => {
                    Swal.fire({
                      icon: "error",
                      showConfirmButton: false,
                      timer: 1500,
                      title: "Erro ao imprimir pedido!",
                    });
                    setLoadingPrinting(false);
                  }}
                  trigger={() => (
                    <Button
                      loading={loadingPrinting}
                      className="botao-bottom"
                      padding="5rem"
                      title="Imprimir pedido"
                    />
                  )}
                  removeAfterPrint={false}
                  content={() => printableRef.current}
                />
                <Button
                  className="botao-bottom"
                  padding="5rem"
                  title="Salvar em CSV"
                  onClick={handleExportarPedido}
                />

                {pedido?.hash && (
                  <Button
                    className="botao-bottom"
                    padding="5rem"
                    title="Link do pedido"
                    onClick={() =>
                      window.open(
                        `${
                          empresa?.dominio_https !=
                          "http://localhost/conecta-static/dist/#!/"
                            ? "https://app.conectavenda.com.br/"
                            : empresa?.dominio_https
                        }detalhes-pedido/${pedido!.hash}`,
                        "_blank"
                      )
                    }
                  />
                )}
                {pedido?.hash && pedido?.cliente.telefone && (
                  <Button
                    className="botao-bottom"
                    padding="5rem"
                    title="Compartilhar pedido"
                    onClick={() => {
                      const telefone = `55${pedido.cliente.telefone}`;
                      const mensagem = encodeURIComponent(
                        `Confira os detalhes do seu pedido aqui: ${empresa?.dominio_https}detalhes-pedido/${
                          pedido!.hash
                        }`
                      );
                      const whatsappUrl = `https://api.whatsapp.com/send?phone=${telefone}&text=${mensagem}`;

                      window.open(whatsappUrl, "_blank");
                    }}
                  />
                )}
              </div>
              {pedido?.situacao !== "3" && (
                <Button
                  className="botao-bottom"
                  padding="5rem"
                  title="Cancelar pedido"
                  onClick={handleCancelarPedido}
                />
              )}
            </div>
          </FooterPaginas>
        </ContainerPedido>
      )}
      <PrintablePedido
        pedido={memoizedPedido}
        informacoesPagamento={memoizedInformacoesPagamento}
        ref={printableRef}
      />
    </>
  );
};

export default Pedido;
