import { RelatoriosContext } from "./RelatoriosContext";
import { useState, useEffect, useContext } from "react";
import { relatoriosApi } from "../../hooks/relatoriosApi";

export const RelatoriosProvider = ({ children }: { children: JSX.Element }) => {
  const api = relatoriosApi();

  const [relatorios, setRelatorios] = useState([]);

  const [tipos, setTipos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      const res = await api.relatorios();
      setRelatorios(res.relatorios!);

      setTipos(
        res.tipos!.filter((tipo) =>
          res.relatorios!.some((relatorio) => relatorio.tipo === tipo.id),
        ),
      );

      setLoading(false);
    };
    init();
  }, []);

  const gerarRelatorio = async (id_relatorio: string, variaveis: {}) => {
    return await api.gerarRelatorio(id_relatorio, variaveis);
  };

  const exportarRelatorio = async (id_relatorio: string, variaveis: {}) => {
    return await api.exportarRelatorio(id_relatorio, variaveis);
  };

  return (
    <RelatoriosContext.Provider
      value={{ gerarRelatorio, exportarRelatorio, relatorios, tipos, loading }}
    >
      {children}
    </RelatoriosContext.Provider>
  );
};
