import styled from "styled-components";
import theme from "../../../../Themes/light";

const transitionTime = "0.3s";

const ProdutosListagem = styled.div`
  width: 100%;
  padding: 5rem 3rem 30rem 3rem;
  font-size: 2.5rem;
  overflow: hidden;
  min-height: 35rem;
  .button-filtrar {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding: 0.5rem;
  }

  .grid-header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 1rem;
    padding-bottom: 4rem;
    .view-select {
      white-space: normal;
    }

    .input {
      min-width: 20%;
      flex-grow: 1;
    }

    .select {
      min-width: 20%;
      flex-grow: 1;
    }
  }

  .produtos-wrapper {
    width: 100%;
    display: grid;
    padding: 0 0 0 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 8fr;
    height: 80vh;
    min-height: 200rem;
    overflow: hidden;
    transition: 0.3s ease-in-out;

    .informacoes {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 1rem 0;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 50px;
        background-color: ${theme.colors.elements};
        top: 0;
        left: 0;
      }

      .flex-container-produtos {
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        padding: 1rem 0;
        column-gap: 2rem;
        row-gap: 1rem;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        color: ${theme.colors.brandSecondary};
        &:nth-of-type(2n){
          padding-top: 4rem;
        }
        .container-half {
          width: calc(33% - 1rem);
          min-width: 40rem;
          flex-grow: 1;
          .botao {
            padding: 0.5rem 0;
          }
          .cinza {
            background-color: ${theme.colors.elements};
            font-weight: normal;
            color: ${theme.colors.brandSecondary};
          }
        }

        span {
          color: ${theme.colors.elements};
        }
      }
    }

    .produtos {
      width: 100%;
      max-height: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
      gap: 4rem;
      align-items: center;
      justify-content: space-between;
      justify-items: center;
      .mensagem {
        padding-top: 10rem;
        width: 100%;
        grid-column: 1 / -1;
        grid-row: 1;
        align-self: flex-start;

        .texto-titulo {
          color: ${theme.colors.brandSecondary};
          font-size: 3.5rem;
        }

        .texto-subtitulo {
          color: ${theme.colors.brandSecondary};
          font-size: 2.5rem;
        }
      }
      .produtos-wrapper {
        display: contents;

        .produto-container {
          width: 100%;
          height: 100%;
          max-height: 55rem;
          align-self: first baseline;
          cursor: pointer;

          a {
            width: 100%;
            height: 100%;
          }

          .button-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            font-size: 2.2rem !important;
            white-space: nowrap;
            flex-grow: 1;
            padding: 1rem 0;

            .botao-remover {
              background-color: ${theme.colors.elements};
              color: ${theme.colors.brandSecondary};
              padding: 0.5rem !important;
              width: 100% !important;

              &::after {
                content: "Remover do catálogo";
                display: block;
              }
            }

            .botao-adicionar {
              padding: 0.5rem !important;
              width: 100% !important;

              &::after {
                content: "Adicionar ao catálogo";
                display: block;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0 0 0rem 0;
    .produtos-wrapper {
      height: 100vh;
      .produtos {
        grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
        align-items: center;
        justify-content: center;
        justify-items: center;
        .produtos-wrapper {
          .produto-container {
            .button-wrapper {
              .botao-adicionar {
                &::after {
                  content: "Adicionar";
                }
              }

              .botao-remover {
                &::after {
                  content: "Remover";
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProdutosListagem;
