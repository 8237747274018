import styled from "styled-components";

const Titulo = styled.div`
  text-align: left;
  font-size: 3rem;
  color: ${(props) => props.theme.colors.titleColor};
  font-weight: bold;
  margin-bottom: 10px;
`;

const Erro = styled.div`
  text-align: left;
  color: ${(props) => props.theme.colors.brandDanger};
`;

const TituloInput = styled.div`
  text-align: left;
  margin: 4rem 0 2.5rem 0;
`;

const Texto = styled.div`
  text-align: left;
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.brandSecondary};
  text-align: justify;
  margin-bottom: 5rem;
`;

const NumeroAtual = styled.div`
  margin-top: 10px;
  text-align: left;
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.brandSecondary};
  text-align: justify;
  margin-bottom: 2.5rem;
`;

const Botoes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .cancelar {
    color: ${(props) => props.theme.colors.brandPrimary};
    cursor: pointer;
    font-size: 2.5rem;
  }
`;

const Planos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  .plano {
    width: 100%;

    .titulo {
      color: ${(props) => props.theme.colors.brandSecondary};
      text-align: center;
      cursor: pointer;
      font-size: 2.5rem;
    }

    .linha {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5px;

      div {
        height: 2.5px;
        background-color: ${(props) => props.theme.colors.elements};
        width: 100%;
      }
    }

    &.selecionado {
      .titulo {
        font-weight: bold;
        color: ${(props) => props.theme.colors.brandPrimary};
      }

      .linha {
        div {
          height: 2.5px;
          background-color: ${(props) => props.theme.colors.brandPrimary};
        }
      }
    }
  }
`;

const InputsContainer = styled.div`
  > * {
    margin-top: 20px;
  }
`;

const ConteudoPlano = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .html {
    width: 100%;
    .descontos {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .desconto {
      width: 50%;
      font-size: 16px;
      padding: 7px;
      margin-top: 20px;
      text-align: center;
      color: ${(props) => props.theme.colors.brandSecondary};
      font-weight: bold;
      background-color: ${(props) => props.theme.colors.white};
      border-radius: 5rem;
    }
    .desconto2 {
      width: 50%;
      font-size: 16px;
      padding: 7px;
      margin-top: 20px;
      text-align: center;
      color: white;
      font-weight: bold;
      background-color: #FF6E28;
      border-radius: 5rem;
    }

    .texto-desconto {
      margin-top: 15px;
      text-align: center;
      font-size: 15px;
      color: ${(props) => props.theme.colors.brandSecondary};

      strong {
        font-size: 23px;
      }
    }

    .texto-final {
      text-align: justify;
      margin-top: 15px;
    }
  }
`;

export {
  Titulo,
  Erro,
  TituloInput,
  Texto,
  NumeroAtual,
  Botoes,
  Planos,
  InputsContainer,
  ConteudoPlano,
};
