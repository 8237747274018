import React, { useEffect, useState } from "react";
import { VariacoesInput } from "../../../../components/VariacoesInput/index.tsx";
import Tabs from "../../../../components/Tabs/Tabs.tsx";
import "./styles.ts";
import VariacoesBoxContainer from "./styles.ts";
import { useTheme } from "../../../../contexts/Theme/ThemeContext.tsx";

interface VariacoesBoxProps {
  onChangePreco: (newValue: {
    index_variacao: number;
    index_preco: number;
    preco: string;
  }) => void;
  onChangeEstoque: (newValue: {
    index_variacao: number;
    estoque: string;
  }) => void;
  setProdutoVariacoes: (newValue: boolean) => void;
  novaVariacao: () => void;
  onChangeNomeVariacao: (newValue: {
    index_variacao: number;
    nome: string;
  }) => void;
  variacoes: any[];
  variacoesPrecos: any[];
  listasDePreco: any[];
  temEstoque: boolean;
  novaListaDePreco: () => void;
  deletaVariacao: (index: number) => void;
  replicaEstoques: (estoque) => void;
  replicaNomeVariacoes: (nome) => void;
  replicaPrecoTabela: (preco, index) => void;
  permitir_edicao: boolean;
}

export const VariacoesBox: React.FC<VariacoesBoxProps> = ({
  onChangePreco,
  deletaVariacao,
  novaListaDePreco,
  onChangeNomeVariacao,
  onChangeEstoque,
  temEstoque,
  novaVariacao,
  setProdutoVariacoes,
  variacoes,
  variacoesPrecos,
  listasDePreco,
  replicaEstoques,
  replicaNomeVariacoes,
  replicaPrecoTabela,
  permitir_edicao,
}) => {
  const { theme } = useTheme();

  function onChangePrecos(newValue: any) {
    onChangePreco(newValue);
  }

  function onChangeEstoques(newValue: any) {
    onChangeEstoque(newValue);
  }

  function onChangeNomeVariacoes(newValue: any) {
    onChangeNomeVariacao(newValue);
  }

  return (
    <VariacoesBoxContainer theme={theme}>
      <Tabs
        tabs={[{ name: "Variações", value: "variacoes" }]}
        value={"variacoes"}
        onChange={() => {}}
      />
      <div className="texto">
        Utilize o campo de variação para diferenciar seu produto pelas
        caracteristicas dele. No nome da variação coloque o tamanho, a cor e
        etc.
      </div>
      <div className="variacoes scrollBonitoBottom">
        {variacoes.map((variacao, index) => {
          if (variacao.ativo == "1") {
            return (
              <VariacoesInput
                key={index}
                quantidade_variacoes={
                  variacoes.filter((variacao) => {
                    return variacao.ativo == "1";
                  }).length
                }
                deletaVariacao={deletaVariacao}
                temEstoque={temEstoque}
                index={index}
                permitir_edicao={permitir_edicao}
                estoque={variacao.estoque}
                listasDePreco={listasDePreco}
                nome={variacao.variacao}
                variacoesPrecos={variacao.precos}
                onChangePreco={(newValue: any) => {
                  onChangePrecos(newValue);
                }}
                replicaEstoques={(estoque) => {
                  replicaEstoques(estoque);
                }}
                replicaNomeVariacoes={(nome) => {
                  replicaNomeVariacoes(nome);
                }}
                replicaPrecoTabela={(preco, index) => {
                  replicaPrecoTabela(preco, index);
                }}
                onChangeEstoque={(newValue: any) => {
                  onChangeEstoques(newValue);
                }}
                onChangeNomeVariacao={(newValue: any) => {
                  onChangeNomeVariacoes(newValue);
                }}
                novaListaDePreco={() => {
                  novaListaDePreco();
                }}
              />
            );
          }
        })}
        {permitir_edicao && (
          <div className="nova_variacao">
            <div className="adicionar" onClick={() => novaVariacao()}>
              Adicionar variação
            </div>
          </div>
        )}
      </div>
    </VariacoesBoxContainer>
  );
};
