import React, { useState } from "react";
import styled from "styled-components";
import ScrollContainerX from "../../components/ScrollContainer/ScrolContainerX";

export const CarouselWrapper = styled.div`
  display: flex;
  width: calc(100% + 20px); // espaço para o preview
  overflow: hidden;
`;

export const CarouselContainer = styled.div`
  display: flex;
  width: calc(100% + 20px); // espaço para o preview
  scroll-snap-type: x mandatory;
`;

export const CarouselItem = styled.div`
  flex: 0 0 40rem;
  padding: 10px;
  scroll-snap-align: start;
  box-sizing: border-box;
  // Adicione mais estilos para os itens aqui
`;

export const PreviewItem = styled(CarouselItem)`
  width: 20px; // largura do preview
`;

interface CarouselProps {
  items: JSX.Element[];
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  return (
    <ScrollContainerX speed={1}>
      <CarouselContainer>
        {items.slice(0, -1).map((item, index) => (
          <CarouselItem key={index}>{item}</CarouselItem>
        ))}
        <PreviewItem>{items[items.length - 1]}</PreviewItem>
      </CarouselContainer>
    </ScrollContainerX>
  );
};

export default Carousel;
