import styled from "styled-components";

export const ModalSucessoContainer = styled.div`
  padding: 30px 0;
  font-size: 20px;

  .mensagem {
    margin-bottom: 30px;
    font-family: "D-DIN";
    color: ${(props) => props.theme.colors.brandSecondary};
    font-weight: bold;
  }
`;
