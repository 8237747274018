import React, { useEffect, useState } from "react";
import { ContainerModal } from "./styles";
import ReactDOM from "react-dom";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface ModalProps {
  isOpen: boolean;
  setModal: (value: boolean) => void;
  width: number | String | undefined;
  maxWidth?: string;
  children?: React.ReactNode;
  backdrop?: String | "static";
  hideDesktop?: boolean;
  align?: string;
  justify?: string;
  margin?: string;
  animation?: string;
  className?: string;
  padding?: string;
  borderRadius?: string;
  background?: { backgroundColor?: string; backdropFilter?: string };
  onUseEffect?: () => void;
  onBackdropClose?: () => void;
  overflow?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  setModal,
  width,
  children,
  backdrop,
  hideDesktop,
  align,
  margin,
  animation,
  background,
  padding,
  justify,
  borderRadius,
  maxWidth,
  onBackdropClose,
  onUseEffect,
  overflow,
  className
}) => {
  width === "lg" ? (width = "clamp(100px, 90vw, 646px)") : (width = width);

  const { theme } = useTheme();

  background = background
    ? background
    : { backgroundColor: "rgba(0, 0, 0, 0.5)" };

  const hide = hideDesktop == true ? "hideDesktop" : "";

  const fundoBrancoContainerStyle = {
    alignItems: align,
    justifyContent: justify,
  };
  const fundoBrancoStyle = {
    margin: margin,
    width: typeof width === "number" ? `${width}px` : `${width}`,
    maxWidth: maxWidth ? maxWidth : "",
    animation: animation,
  };
  const childrenStyle = {
    animation: animation,
    borderRadius: borderRadius ? borderRadius : "",
    padding: padding ? padding : "",
    overflow: overflow ? overflow : "",
  };

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    if (isOpen) {
      onUseEffect && onUseEffect();
      document.body.classList.add("modal-shown"); // classes pra prevenir scroll
      document.body.classList.add("modal-height-auto");
      var root = document.getElementsByTagName("html")[0];
      root.classList.add("modal-shown");
      root.classList.add("modal-height-auto");
    } else {
      document.body.classList.remove("modal-shown");
      document.body.classList.remove("modal-height-auto");
      var root = document.getElementsByTagName("html")[0];
      root.classList.remove("modal-shown");
      root.classList.remove("modal-height-auto");
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-shown");
      document.body.classList.remove("modal-height-auto");
      var root = document.getElementsByTagName("html")[0];
      root.classList.remove("modal-shown");
      root.classList.remove("modal-height-auto");

      onBackdropClose && onBackdropClose();
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <>
          <div className="containerModal">
            <ContainerModal theme={theme} className={className}>
              <div
                style={background}
                onClick={() => {
                  if (backdrop !== "static") handleClose();
                }}
                className={`fundo ${hide}`}
              ></div>
              <div className="divAuxiliar">
                <div
                  style={fundoBrancoContainerStyle}
                  className={`fundo-branco-container ${hide}`}
                >
                  <div
                    style={fundoBrancoStyle}
                    className={`fundo-branco ${hide}`}
                  >
                    <div style={childrenStyle} className={`children ${hide}`}>
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </ContainerModal>
          </div>
        </>
      )}
    </>,
    document.getElementById("App")!,
  );
};

export default Modal;
