import React from 'react';
import './styles';
import { SkeletonBlingContainer } from './styles';
import { useTheme } from '../../../contexts/Theme/ThemeContext';

interface SkeletonBlingProps {
}

const SkeletonBling: React.FC<SkeletonBlingProps> = ({}) => {

   const {theme} = useTheme();

    return (
        <SkeletonBlingContainer  theme={theme}>
            {Array(15)
            .fill(0)
            .map((_, index) => {
                return (
                    <div
                    style={{ "--i": `${index}`, gridArea: `sk${index}` } as React.CSSProperties}
                    key={index}
                    />
                );
            })}
        </SkeletonBlingContainer >
    )
}

export default SkeletonBling;