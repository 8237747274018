import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const AvisoPagamentoAtrasadoContainer = styled.div<{theme: ThemeType}>`
    width: 100%;    
    .faixa {
        width: 100%;
        background-color: #fe0000;
        display: flex;
        gap: 2px;
        align-items: center;
        height: 5.0rem;
        justify-content: center;
        border-bottom-right-radius: 4rem;
        position: relative;
        @media (max-width: 1024px) {
            border-bottom-right-radius: 4rem;
            border-bottom-left-radius: 4rem;
            height: 6.0rem;
        }
        @media (max-width: 425px) {
            display: flex;
            flex-direction: row;
            border-bottom-right-radius: 4rem;
            border-bottom-left-radius: 4rem;
            height: 8.0rem;
            gap: 2px;
            .sino {
                width: 40%;
            }
            .texto_caps {
                text-align: center;
                font-size: 2.0rem !important;
                line-height: 12px;
                padding-left: 30px;
            }
            .barra {
                color: red;
            }
            .texto {
                text-align: left;
                font-size: 2.0rem !important;
                line-height: 12px;
                padding-right: 30px;
            }
        }      
        &::before {
            content: '';
            position: absolute;
            width: 20%;
            right: 100%;
            height: 5.0rem;
            background-color: #fe0000; 
        }
        .texto_caps {
            display: flex;
            color: white;
            font-size: 2.5rem;
            justify-content: center;
            align-items: center;
            font-weight: bold;
        }
        .texto {
            color: white;
            font-size: 2.2rem;
        }
        .sino {
            color: white;
        }
        .barra span {
            display: inline-block;
            color: white;
            content: "|";
        }        
        @media (max-width: 425px) {
            .barra span {
                display: none;
            }
        }
    }
`;

export { 
    AvisoPagamentoAtrasadoContainer,
};