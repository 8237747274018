export const PERMISSOES = {
  PRODUTOS: 1, //pagina
  CATALOGOS: 2, //pagina
  CONFIGURACOES: 3, //pagina
  VISUALIZAR_PEDIDOS_GERENTE: 4, // visualizar pedidos de todos os vendedores
  VISUALIZAR_PEDIDOS_NAO_GERENTE: 5, //pedidos so dele
  VISUALIZAR_PEDIDOS_PREPOSTO: 6, //nao existe mais
  USUARIOS_GERENTE: 7, // pagina
  USUARIOS_PREPOSTO: 8, // nao existe mais
  RELATORIOS: 9, // pagina
  INDIQUE: 10, //nao existe mais
  PEDIDOS_EDICAO_TOTAL: 11, // edicao de pedidos de todos os vendedores
  PEDIDOS_EDICAO_RESTRITA: 12, // edicao de pedidos so dele (verificar, talvez só de pra alterar a situacao ate uma especifica)
  BOTAO_PEDIDOS: 13,
  BOTAO_CATALOGOS: 14,
  BOTAO_RELATORIOS: 15,
  BOTAO_PRODUTOS: 16,
  BOTAO_VENDEDORES: 17,
  BOTAO_INDIQUE: 18,
  REPLICAR_CATALOGOS_TODOS: 19,
  REPLICAR_CATALOGOS_PREPOSTO: 20,
  BOTAO_CESTAS_ABANDONADAS: 21,
};

export const TIPO_MIDIA = {
  IMAGEM: 1,
  YOUTUBE: 2,
};
