import styled, { css, keyframes } from "styled-components";
import { ThemeType } from "../../types/Theme";

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const comeFromTopAnimation = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const FundoRelativo = styled.div<{
  theme: ThemeType;
  normalModal: boolean;
  background?: string;
  zIndex: number;
}>`
  display: ${({ normalModal }) => (normalModal ? "flex" : "contents")};
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => (zIndex+100)};
  background-color: ${({ background }) =>
    background ? background : "rgba(0, 0, 0, 0.15)"};
  .normal-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: ${({ normalModal }) => (normalModal ? "100%" : "99vw")};
    top: 0;
    left: 0;
    background-color: ${({ background }) =>
      background ? background : "rgba(0, 0, 0, 0.15)"};
    animation: ${opacityAnimation} 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: ${({ normalModal }) => (normalModal ? "0" : "40px")};
    z-index: ${({ zIndex }) => zIndex};

    .fundoBrancoRelativo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: ${({ normalModal }) => (normalModal ? "100vh" : "100%")};
      opacity: 1;
      z-index: ${({ zIndex }) => zIndex};

      .childrenRelativo {
        opacity: 1;
        box-shadow: rgb(0 0 0 / 25%) 0px 2px 2px 0px;
        border-radius: 40px;
        background-color: ${({ theme }) => theme.colors.modal};
        overflow: hidden;
        width: 100%;
        padding: 3rem 0px;
        animation: ${comeFromTopAnimation} 0.7s cubic-bezier(0.4, 0, 0.2, 1);
        display: flex;
        flex-direction: column;
        position: absolute;

        .containerChildrenRelativo {
          padding: 3rem 0px;
        }

        > .titulo {
          font-size: 3rem;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.titleColor};
        }

        .subtitulo {
          font-size: 2.5rem;
          color: ${({ theme }) => theme.colors.brandSecondary};
        }

        .containerBotaoConfirmar {
          width: 100%;
          display: flex;
          justify-content: center;

          .botaoConfirmar {
            display: flex;
            min-width: 25rem;
            align-items: center;
            justify-content: center;
            padding: 1rem 8rem;
            margin: 0px 8rem;
            border-radius: 50px;
            background-color: ${({ theme }) => theme.colors.brandPrimary};
            color: ${({ theme }) => theme.colors.white};
            cursor: pointer;
          }
        }

        .cancelar {
          cursor: pointer;
          margin-top: 2rem;
          color: ${({ theme }) => theme.colors.brandPrimary};
        }

        .mensagemDisabled {
          font-size: 1.5rem;
          color: red;
          margin-top: 1rem;
          margin-bottom: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    @media (max-width: 1024px) {
      border-radius: ${({ normalModal }) =>
        normalModal ? "0px" : "30px"} !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      .fundoBrancoRelativo {
        justify-items: center;
        .childrenRelativo {
          position: relative;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
        }
      }
    }
  }
`;

export default FundoRelativo;
