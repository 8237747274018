import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ContainerPagSeguroAutorizado } from "./styles";

interface PagSeguroAutorizadoProps {}

export const PagSeguroAutorizado: React.FC<PagSeguroAutorizadoProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <ContainerPagSeguroAutorizado theme={theme}>
        <span>PagBank Autorizado! </span>
        Volte ao conecta venda para continuar.
      </ContainerPagSeguroAutorizado>
    </>
  );
};
