import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const BannersCanvaContainer = styled.div<{theme: ThemeType}>`
    width: 100%;
    border-radius: 2rem;
    padding: 1rem 2rem;
    border: 1px solid ${({theme}) => theme.colors.elements};
    text-align: justify;
    .titulo{
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    .texto{
        font-size: 2.4rem;
        margin-bottom: 1rem;
    }
    .links{
        font-size: 2.4rem;
        text-align: left;
        a{
            margin: 0 1rem;
            text-decoration: underline;
        }
    }
`;

export { 
    BannersCanvaContainer,
};