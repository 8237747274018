import React from "react";
import svgImagePlaceholder from "../../../../assets/newImages/modais/cobrancaPlaceholder.svg";
import svgImage from "../../../../assets/newImages/modais/cobranca.svg";
import LoadingComFundo from "../../../Loading/LoadingComFundo/LoadingComFundo";
import { ContainerCobrancaContainer } from "./styles";

const Cobranca: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      <ContainerCobrancaContainer>
        <div className="containerImagem">
          {loading && <img className="imagem" src={svgImagePlaceholder}></img>}
          <img
            className="imagem"
            src={svgImage}
            onLoad={() => setLoading(false)}
          ></img>
        </div>
        <div className="containerTexto">
          E sobre o <span>pagamento</span>
          <br />
          quer fazer a cobrança <br className="hideMobile" />
          na finalização do pedido?
        </div>
      </ContainerCobrancaContainer>
    </>
  );
};
export default Cobranca;
