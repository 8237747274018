import React, { useEffect } from "react";
import "./styles";
import { UploadStatusComponentContainer } from "./styles";
import { Image } from "../MultipleImageUpload";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ReactComponent as ImageIcon } from "../../../assets/newImages/icons/galery.svg";
import { ReactComponent as Error } from "../../../assets/newImages/icons/plus.svg";
import ProgressBar from "../../ProgressBar/ProgressBar";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

interface UploadStatusComponentProps {
  image: Image;
  onRemover: (image: Image) => void;
  onEditar: (image: Image) => void;
}

const UploadStatusComponent: React.FC<UploadStatusComponentProps> = ({
  image,
  onRemover,
  onEditar,
}) => {
  const { theme } = useTheme();
  const MySwal = withReactContent(Swal);

  const [currentIcon, setCurrentIcon] = React.useState("!");
  useEffect(() => {
    let currentIcon = "";

    switch (image.status) {
      case "advertencia":
        currentIcon = "i";
        break;
      case "erro":
        currentIcon = "x";
        break;
      case "carregando":
        currentIcon = "...";
        break;
      default:
        currentIcon = "✓";
        break;
    }

    setCurrentIcon(currentIcon);
  }, [image.status]);

  const openImage = () => {
    MySwal.fire({
      title: image.imagem,
      html: `<img src=${image.base64} style="width: 100%; height: 100%; object-fit: contain; border-radius: 4rem"/>`,
    });
  };

  return (
    <UploadStatusComponentContainer theme={theme} image={image}>
      <div className="main-container">
        <div className="icone-wrapper">
          <ImageIcon
            className="icone"
            onClick={() => image.status !== "erro" && openImage()}
          />
        </div>
        <div className="name-progress-wrapper">
          <div className="name">{image.imagem}</div>
          <ProgressBar progress={image.progress || 0} />
        </div>
        <div className="status">
          <div className="icon">{currentIcon}</div>
        </div>
      </div>
      <div className="acoes">
        <div className="produtos">Produtos: {image.produtos?.length || 0}</div>
        <div className="botoes">
          {image.status === "advertencia" && (
            <div className="editar" onClick={(e) => onEditar(image)}>
              Editar
            </div>
          )}
          <div className="remover" onClick={(e) => onRemover(image)}>
            Remover
          </div>
        </div>
      </div>
    </UploadStatusComponentContainer>
  );
};

export default UploadStatusComponent;
