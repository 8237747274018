import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const ImportarPorErpContainer = styled.div<{theme: ThemeType}>`
    font-size: 2.5rem;
    padding: 4rem 8rem;
    width: 70rem;
    min-height: 60rem;
    max-width: 100%;
    border-radius: 4rem;
    overflow: hidden;
    position: relative;
    .texto{
        padding: 5rem 0;
        text-align: justify;
    }
    ul{
        li{
            text-align: left;
            a{
                color: ${props => props.theme.colors.brandPrimary};
            }
        }
    }
    .toggles{
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        justify-content: center;
    }
    .botoes{
        padding: 4rem 0 2rem 0;
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: space-between;
        .arrow{
            display: flex;
            cursor: pointer;
            font-size: 2.5rem;
            align-items: center;
            gap: 1rem;
            svg{
                margin-top: 2px;
                stroke: ${props => props.theme.colors.brandSecondary};
            }
        }
    }
`;

export { 
    ImportarPorErpContainer
};