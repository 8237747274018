import React from "react";
import "./styles";
import { TituloContainer } from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface TituloProps {
  title: string;
  subtitle?: string;
}

const Titulo: React.FC<TituloProps> = ({ title, subtitle }) => {
  const { theme } = useTheme();

  return (
    <TituloContainer theme={theme}>
      <div className="titulo">{title}</div>
      <div className="subtitulo">{subtitle}</div>
    </TituloContainer>
  );
};

export default Titulo;
