import React from "react";
import svgImagePlaceholder from "../../../../assets/newImages/modais/criarProdutoPlaceholder.svg";
import svgImage from "../../../../assets/newImages/modais/criarProduto.svg";
import LoadingComFundo from "../../../Loading/LoadingComFundo/LoadingComFundo";
import { ContainerCriarProduto } from "./styles";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";

const CriarProduto: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  const { theme } = useTheme();

  return (
    <>
      <ContainerCriarProduto theme={theme}>
        <div className="containerImagem">
          {loading && <img className="imagem" src={svgImagePlaceholder}></img>}
          <img
            className="imagem"
            src={svgImage}
            onLoad={() => setLoading(false)}
          ></img>
        </div>
        <div className="containerTexto">
          <span>Vamos adicionar seu primeiro produto?</span>
          <br />
          Clique no botão abaixo para criar um produto, a partir dele, você
          criará seu primeiro catálogo.
        </div>
      </ContainerCriarProduto>
    </>
  );
};
export default CriarProduto;
