import React from 'react';
import './styles';
import { BannersCanvaContainer } from './styles';
import { useTheme } from '../../contexts/Theme/ThemeContext';

interface BannersCanvaProps {
}

const BannersCanva: React.FC<BannersCanvaProps> = ({}) => {

   const {theme} = useTheme();

    return (
        <BannersCanvaContainer theme={theme}>
            <div className="titulo">
                Não tem um banner?
            </div>
            <div className="texto">
                Disponibilizamos um banner editável no Canva, basta usar o modelo, editar, salvar e usar nos seus catálogos.
            </div>
            <div className="links">
                Acesse aqui: 
                <a 
                    href="https://www.canva.com/design/DAF3EijSrfU/J7vDY-R3CgMQzT2hsjYP2g/view?utm_content=DAF3EijSrfU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" 
                    target="_blank" 
                    rel="noreferrer"
                >
                    Banner para celular 
                </a>
                |
                <a 
                    href="https://www.canva.com/design/DAF3EuVky2U/igW2Vev2nhpHceSNMpSA9g/view?utm_content=DAF3EuVky2U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                    target="_blank"
                    rel="noreferrer"
                >
                    Banner para computador
                </a>
            </div>
        </BannersCanvaContainer >
    )
}

export default BannersCanva;