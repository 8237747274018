import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { authApi } from "../../hooks/authApi";
import { User } from "../../types/User";
import { AuthContext } from "./AuthContext";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null | false>(null);
  const [loading, setLoading] = useState(false);
  const [permissoesUsuario, setPermissoesUsuario] = useState<number[]>([]);
  const location = useLocation();
  const api = authApi();

  const getPermissoesUsuario = async () => {
    const data = await api.getPermissoes();
    setPermissoesUsuario(data);
  };

  const validateToken = async () => {
    const data = await api.validateToken();
    if (data === "erro") {
      setUser(false);
      return false;
    } else {
      setUser(data);
      return true;
    }
  };

  const signin = async (email: string, password: string) => {
    const data = await api.signin(email, password);
    if (data === "LOGADO") {
      return "LOGADO";
    } else return data.response.data.error
  };

  const signout = async () => {
    const data = await api.logout();
    if (data == "DESLOGADO") {
      setUser(false);
      setToken("");
    }

    return false;
  };

  const setToken = (token: string) => {
    localStorage.setItem("token", token);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      //tem q ser sincrono
      await validateToken();
      await getPermissoesUsuario();
      setLoading(false);
    };
    init();
  }, [location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        user,
        signin,
        signout,
        validateToken,
        permissoesUsuario,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
