import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

const transitionTime = "0.3s";

const ContainerCatalogosListagem = styled.div<{ theme: ThemeType, semProdutos: boolean }>`
  width: 100%;
  padding: 5rem 10rem 13rem 10rem;
  font-size: 2.5rem;

  .flex-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .input {
      min-width: 65rem;
    }

    .flex-filtros {
      display: flex;
      column-gap: 4rem;

      > .select {
        min-width: 35rem;
      }
    }
  }

  .catalogos {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
    gap: 4rem;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    padding: 5rem 0;

    .mensagem {
      margin-top: 10rem;
      grid-column: ${({ semProdutos }) => (semProdutos ? "1 / -1" : "2 / -1")};	

      .texto-titulo {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-size: 3.5rem;
      }

      .texto-subtitulo {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-size: 2.5rem;
        .clique-aqui {
          font-size: 3rem;
          color: ${(props) => props.theme.colors.titleColor};
        }
      }
      @media (max-width: 1024px) {
        grid-column: 1 / -1;
      }
    }

    .catalogo-wrapper {
      width: 100%;
      height: 100%;
    }

    .center {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .adicionar_catalogo {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 60rem;
        background-color: ${(props) => props.theme.colors.brandPrimary};
        border-radius: 20px;
        box-shadow: rgb(0 0 0 / 5%) 0px 2px 2px 0px;

        .plus {
          fill: ${(props) => props.theme.colors.white};
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 3rem 2rem 13rem 2rem;

    .flex-header {
      flex-wrap: wrap;
      row-gap: 2rem;
      width: 100%;

      > .input {
        width: 100%;
        min-width: 0;
      }

      .flex-filtros {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 2rem;

        > .select {
          flex-grow: 1;
          min-width: 48%;
          max-width: 100%;
        }
      }
    }

    .catalogos {
      justify-content: center;
    }
  }
`;

export default ContainerCatalogosListagem;
