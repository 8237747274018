import styled, { css } from "styled-components";

export const commonStyles = css`
  .containerCupomListagem {
    width: 100%;
    padding: 4rem 0rem;
    .cuponsContainer {
      width: 100%;
      color: ${(props) => props.theme.colors.brandSecondary};
      position: relative;
      display: inline-block;
      align-items: center;
      padding: 4rem 4rem;
      max-width: 100%;

    

      .cupomContainer {
        width: 100%;
        padding: 1rem 0;
        font-size: 2.5rem;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        display: flex;

        .nome,
        .desconto {
          white-space: nowrap;
        }

        .editarContainer {
          display: inline-flex;
          align-items: center;
          gap: 1rem;

          .editar {
            color: ${(props) => props.theme.colors.brandPrimary};
            cursor: pointer;
          }
        }

        .disabled {
          color: ${(props) => props.theme.colors.elements} !important;
          pointer-events: none;
        }
      }
    }

    > .novo {
      margin-top: 4rem;
      padding-left: 2rem;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandPrimary};
      cursor: pointer;
    }
  }
`;

export const ContainerCupomListagem = styled.div<{
    configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
        configIn === "configuracoes" &&
        css`
      .containerCupomListagem {
        width: 100%;
          .cuponsContainer {
            width: 100%;
            color: ${(props) => props.theme.colors.brandSecondary};
            padding: 4rem 8rem;
            &::after,
            &::before {
              display: none;
            }
            .cupomContainer {
              color: ${(props) => props.theme.colors.brandSecondary};
            }
          }
        .novo {
          margin-top: 0;
          text-align: left;
        }
      }
    `}
`;

// Add other styled components for the nested elements if necessary
