import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles";
import { FreteGratisMainContainer } from "./styles";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import FreteGratis from "../FreteGratis/FreteGratis";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import EstadosFreteGratis from "../EstadosFreteGratis/EstadosFreteGratis";
import { FreteGratis as FreteGratisType } from "../../../types/FreteGratis";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Form from "../../Form";
import { BotoesContainer, Titulo } from "../../../styled/Styled";
import { Button } from "../../Button";
import Modal from "../../Modal/Modal";
import FreteGratisListagem from "../FreteGratisListagem/FreteGratisListagem";
import CuponsListagem from "../CuponsListagem/CuponsListagem";
import Cupom from "../Cupom/Cupom";
import Swal from "sweetalert2";

interface FreteGratisMainProps {}

const CuponsMain: React.FC<FreteGratisMainProps> = ({}) => {
  const { cupons, setCupons } = useContext(ConfiguracoesContext);
  const [modalEstado, setModalEstado] = useState<boolean>(false);
  const [cupom, setCupom] = useState<any>({
    cupom: "",
    desconto: 0,
    ativo: true,
  });

  const { theme } = useTheme();

  const [modalCupom, setModalCupom] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const indexCupom = useRef<number>(-1);

  const handleAdicionarCupom = () => {
    indexCupom.current = -1;
    setModalCupom(true);
  };

  const cancelarEdicaoCupom = () => {
    setModalCupom(false);
  };

  const excluirCupom = () => {
    const copia = [...cupons];
    copia.splice(indexCupom.current, 1);
    setCupons(copia);
    setModalEstado(false);
    setModalCupom(false);
  };

  const edicaoCupom = (index: number) => {
    indexCupom.current = index;
    setCupom(cupons[index]);
    setModalCupom(true);
  };

  return (
    <>
      <Modal
        isOpen={modalCupom}
        setModal={setModalCupom}
        width={"100rem"}
        maxWidth="98vw"
        backdrop={"static"}
        padding="3rem 4rem"
        onUseEffect={() => {
          setFormValid(false);
        }}
      >
        <Form
          id="cupom"
          onSubmit={(e) => {
            if (indexCupom.current === -1) {
              if (cupons.find((c) => c.cupom === cupom.cupom)) {
                Swal.fire({
                  icon: "error",
                  title: "Erro",
                  text: "Cupom já cadastrado",
                });
                return;
              }
              setCupons([...cupons, cupom]);
            } else {
              const copia = [...cupons];
              copia[indexCupom.current] = cupom;
              setCupons(copia);
            }
            setModalCupom(false);
          }}
          setFormValid={setFormValid}
        >
          <Titulo theme={theme}>
            {indexCupom.current === -1 ? "Adicionar" : "Editar"} Cupom
          </Titulo>
          <Cupom
            setCupom={setCupom}
            cupom={cupom}
            excluirCupom={excluirCupom}
          />
          <BotoesContainer theme={theme}>
            <div
              className="cancelar"
              onClick={() => {
                cancelarEdicaoCupom();
              }}
            >
              Cancelar
            </div>
            <Button
              title="Adicionar"
              type="submit"
              className={`${!formValid && "disabled"}`}
            />
          </BotoesContainer>
        </Form>
      </Modal>
      <FreteGratisMainContainer theme={theme}>
        <div className="dropdown">
          <CuponsListagem
            configIn="empresa"
            onEditarCupom={edicaoCupom}
            onNovoCupom={handleAdicionarCupom}
          />
        </div>
        <ModalRelativo
          normalModal={true}
          zIndex={9999}
          width="auto"
          background="none"
          isOpen={modalEstado}
          setIsOpen={setModalEstado}
          titulo={""}
          subtitulo={""}
          center={true}
          botaoCancelar="Cancelar"
          cancelar={true}
          onUseEffect={() => {}}
          onCancel={() => {
            setModalEstado(false);
          }}
        >
          <Cupom
            setCupom={setCupom}
            cupom={cupom}
            excluirCupom={excluirCupom}
          />
        </ModalRelativo>
      </FreteGratisMainContainer>
    </>
  );
};

export default CuponsMain;
