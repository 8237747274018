import styled, { keyframes } from "styled-components";
import theme from "../../../Themes/light";

const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ImageUploadLogoContainer = styled.div`
  width: 100%;
  height: 6rem;
  .logo-input-container-empresa {
    width: 100%;
    height: 100%;
    .logo-wrapper {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0rem 4rem;
      gap: 2rem;
      border-radius: 25rem;
      background-color: ${(props) => props.theme.colors.white};
      .logo {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .loading {
          display: flex;
          align-items: center;
          svg {
            fill: transparent;
            stroke: ${(props) => props.theme.colors.elements};
            animation:
              ${dashAnimation} 2s ease infinite,
              ${rotateAnimation} 2s linear infinite;
            stroke-width: 1;
          }
        }
        .plus {
          border-radius: 50%;
          padding: 0.2rem;
          border: 1px solid ${(props) => props.theme.colors.brandSecondary};
          display: flex;
          align-items: center;
          svg {
            fill: ${(props) => props.theme.colors.brandSecondary};
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .texto {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-size: 2.5rem;
      }
    }
  }
`;

const ModalCroppingContainer = styled.div`
  width: 65rem;
  max-width: 100%;
  padding: 0 2rem;
  .titulo {
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 3rem;
    text-align: left;
    padding: 2rem 0 0 2rem;
    font-weight: bold;
  }
  .botoes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 0 2rem;
    .cancelar {
      cursor: pointer;
      font-size: 2.5rem;
      margin-top: 0 !important;
      color: ${(props) => props.theme.colors.brandSecondary};
    }
  }
`;

export { ImageUploadLogoContainer, ModalCroppingContainer };
