import styled, { css } from "styled-components";
import theme from "../../../Themes/light";
import { HtmlHTMLAttributes } from "react";

const { colors } = theme;

const commonStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  > .titulo {
    color: ${(props) => props.theme.colors.brandPrimary};
    font-size: 2.5rem;
    font-weight: bold;
  }
  .containerToggleConfigurar {
    position: relative;
    justify-content: center;
    padding: 2rem 0 2rem 0;
  }
`;

const PagamentoCombinarContainer = styled.div<{
  configIn: "empresa" | "configuracoes" | "catalogo";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    (configIn === "configuracoes" || configIn === "catalogo") &&
    css`
      padding: 3rem 0 0 0;
      color: ${(props) => props.theme.colors.brandSecondary};
      .titulo {
        display: none;
      }
      .descricao {
        width: 100%;
        padding: 2rem 0 0 0;
        font-size: 2.5rem;
        color: ${(props) => props.theme.colors.brandSecondary};
        text-align: left;
      }
      .containerToggleConfigurar {
        padding: 0;
        width: 100%;
        .nome {
          width: 100%;
          font-weight: bold;
        }
      }
    `}
`;

export { PagamentoCombinarContainer };
