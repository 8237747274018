import React, { useState } from "react";
import { useEffect } from "react";
import FundoRelativo from "./styles";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface ModalRelativoProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children?: React.ReactNode;
  width?: string;
  backdrop?: 'static';
  botaoConfirmar?: string;
  background?: string;
  botaoCancelar?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  titulo: string;
  subtitulo: string;
  center?: boolean;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  cancelar?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  maxWidth?: string;
  padding?: string;
  onUseEffect?: () => void;
  removeConfirm?: boolean;
  normalModal?: boolean;
  zIndex?: number;
}

const ModalRelativo: React.FC<ModalRelativoProps> = ({
  isOpen,
  children,
  width,
  setIsOpen,
  onConfirm,
  botaoConfirmar,
  titulo,
  subtitulo,
  center,
  top,
  bottom,
  right,
  left,
  cancelar,
  disabled,
  disabledMessage,
  maxWidth,
  padding,
  onCancel,
  onUseEffect,
  botaoCancelar,
  background,
  removeConfirm,
  backdrop,
  normalModal = false,
  zIndex=100,
}) => {
  const childrenRef = React.useRef<HTMLDivElement>(null);

  const { theme } = useTheme();

  const [activateDisabledMessage, setActivateDisabledMessage] = useState(false);

  const childrenRelativoStyle = {
    width: width,
    top: normalModal ? "" : top,
    left: normalModal ? "" : left,
    right: normalModal ? "" : right,
    bottom: normalModal ? "" : bottom,
    maxWidth: maxWidth,
    padding: padding,
  };

  const handleOnConfirm = () => {
    if (!disabled) {
      setIsOpen(false);
      onConfirm && onConfirm();
    } else {
      setActivateDisabledMessage(true);
    }
  };

  const handleOnCancel = () => {
    setIsOpen(false);
    onCancel && onCancel();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      handleOnCancel();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      childrenRef.current &&
      !childrenRef.current.contains(event.target as Node)
    ) {
      handleOnCancel();
    }
  };

  useEffect(() => {
    const init = async () => {
      if(backdrop !== 'static') {
        setTimeout(() => {
          document.addEventListener("keydown", handleKeyDown, false);
          document.addEventListener("click", handleClickOutside, false);
        }, 500);
      }
    };

    init();

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
      document.removeEventListener("click", handleClickOutside, false);
      document.body.classList.remove("modal-shown");
      document.body.classList.remove("modal-height-auto");
      var root = document.getElementsByTagName("html")[0];
      root.classList.remove("modal-shown");
      root.classList.remove("modal-height-auto");
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      onUseEffect && onUseEffect();
      if (normalModal) {
        document.body.classList.add("modal-shown"); // classes pra prevenir scroll
        document.body.classList.add("modal-height-auto");
        var root = document.getElementsByTagName("html")[0];
        root.classList.add("modal-shown");
        root.classList.add("modal-height-auto");
      }
    } else if (normalModal) {
      document.body.classList.remove("modal-shown");
      document.body.classList.remove("modal-height-auto");
      var root = document.getElementsByTagName("html")[0];
      root.classList.remove("modal-shown");
      root.classList.remove("modal-height-auto");
    }
  }, [isOpen, normalModal]);

  return (
    <>
      {isOpen && (
        <FundoRelativo
          theme={theme}
          background={background}
          normalModal={normalModal}
          zIndex={zIndex}
        >
          <div className="normal-wrapper">
            <div className={`fundoBrancoRelativo ${center ? "center" : ""}`}>
              <div
                ref={childrenRef}
                className="childrenRelativo"
                style={childrenRelativoStyle}
              >
                <div className="titulo">{titulo}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: subtitulo }}
                  className="subtitulo"
                ></div>
                <div className="containerChildrenRelativo">{children}</div>
                {activateDisabledMessage && disabled && disabledMessage && (
                  <div className="mensagemDisabled">
                    {disabled ? disabledMessage : ""}
                  </div>
                )}
                {!removeConfirm && (
                  <div className="containerBotaoConfirmar">
                    <div
                      className="botaoConfirmar"
                      style={{ opacity: disabled ? "0.7" : "1" }}
                      onClick={handleOnConfirm}
                    >
                      {botaoConfirmar ? botaoConfirmar : "Salvar"}
                    </div>
                  </div>
                )}
                {cancelar && (
                  <div className="cancelar" onClick={handleOnCancel}>
                    {botaoCancelar ? botaoCancelar : "Cancelar"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </FundoRelativo>
      )}
    </>
  );
};

export default ModalRelativo;
