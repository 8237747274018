import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const BannersShowcaseContainer = styled.div<{theme: ThemeType}>`
    .banners{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        .banner{
            width: 100%;
            position: relative;
            border-radius: 20px;
            background-color: ${({theme}) => theme.colors.white};
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img{
                width: 100%;
                height: 15rem;
                object-fit: scale-down;
            }
            .left, .right, .remove{
                display: none;
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                cursor: pointer;
                svg{
                    width: 100%;
                    height: 100%;
                    fill: ${({theme}) => theme.colors.brandSecondary};
                }
            }
            .left{
                left: 0;
                bottom: 1rem;
            }
            .right {
                right: 0;
                bottom: 1rem;
                transform: rotate(180deg);
            }
            .remove{
                position: absolute;
                border-radius: 50%;
                padding: 2px;
                right: 1rem;
                top: 1rem;
                transform: rotate(45deg);
                background-color: ${({theme}) => theme.colors.brandSecondary};
                svg{
                    fill: ${({theme}) => theme.colors.white};
                }
            }

            &:hover{
                .left, .right, .remove{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            } 
        }
        .emptyBanner{
            width: 100%;
            height: 100%;
            background-color: ${({theme}) => theme.colors.white};
            border-radius: 20px;
        }
    }
`;

export { 
    BannersShowcaseContainer,
};