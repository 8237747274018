import styled, { keyframes } from "styled-components";
import theme from "../../../Themes/light";
import { rgba } from "../../../services/Functions";

const transitionTime = "0.3s";
const dashAnimation = keyframes`
    0% {
        stroke-dasharray: 1, 95;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 85, 95;
        stroke-dashoffset: -25;
    }
    100% {
        stroke-dasharray: 85, 95;
        stroke-dashoffset: -93;
    }
    `;
const ViewSelectPedidos = styled.div`
  position: relative;
  width: 100%;
  font-size: 2.5rem;
  .selectAuxiliar {
    position: absolute;
    bottom: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
    &:focus + .select {
      border-color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
  .left {
    margin-left: 2rem;
    justify-content: flex-start;
  }
  .center {
    justify-content: center;
  }
  .right {
    justify-content: flex-end;
    margin-right: 2rem;
  }
  .label {
    font-size: 2.5rem;
    font-weight: 400;
    width: 100%;
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    text-align: left;
    white-space: normal;
  }
  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4rem;
    border: 1px solid transparent;
    border-radius: 10rem;
    cursor: pointer;
    transition: border-color ${transitionTime};
    background-color: ${(props) => props.theme.colors.white};
    height: 6rem;
    .placeholder {
      font-size: 2.5rem;
      color: $placeholder;
    }
  }

  .select:hover,
  .select:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.brandPrimary};
  }

  .icon svg {
    width: 100%;
    height: 100%;
  }
  .icon {
    width: 3rem;
    height: 3rem;
    .arrow {
      fill: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  .options {
    font-size: 2.5rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.colors.brandSecondary};
    border-top: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition:
      opacity ${transitionTime},
      transform ${transitionTime};
  }

  .focused {
    background-color: ${rgba(theme.colors.titleColor, 0.3)} !important;
  }
  .option,
  .optionsContainer {
    font-size: 2.5rem;
    width: inherit;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: background-color ${transitionTime};
    text-align: left;
    overflow: hidden;

    &.ativo {
      width: 100%;
      border-radius: 3rem;
      z-index: 205;
      padding: 0rem 0rem;
      position: absolute;
      max-height: 200px; /* altura máxima */
      overflow-y: scroll; /* adiciona scroll */
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }
    .option-text {
      padding: 0.5rem 4rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      position: relative;
      > .bolinha {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        z-index: 1;
      }
      > .quantidade {
        position: absolute;
        right: 1rem;
      }
    }
    @media (max-width: 1024px) {
      .option-text {
        padding: 1rem 1rem 1rem 3rem;
      }
    }
  }
  .hoverEffect {
    :hover {
      background-color: rgba(${(props) => props.theme.colors.titleColor}, 0.2);
    }
  }
  .openUpwards {
    background-color: ${(props) => props.theme.colors.white};
    top: auto;
    bottom: 100%;
  }

  // Define animation styles for the dropdown
  .select {
    padding: 1rem 1rem 1rem 3rem;
    .icon {
      transition: transform ${transitionTime};
    }
    .selecionado {
      color: ${(props) => props.theme.colors.brandSecondary};
    }

    &.ativo {
      .icon {
        transform: rotate(180deg);
      }
    }
    .value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      color: ${(props) =>
        props.theme.mode == "light"
          ? "unset"
          : props.theme.colors.brandSecondary};
      > .bolinha {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        z-index: 1;
      }
      > .quantidade {
        position: absolute;
        right: 6rem;
      }
    }
  }

  .options {
    display: none;
    &.ativo {
      display: block;
      opacity: 1;

      transform: translateY(0);
    }
  }
`;

export default ViewSelectPedidos;
