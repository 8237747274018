import styled from "styled-components";
import theme from "../../../Themes/light";
import { ThemeType } from "../../../types/Theme";

const { colors } = theme;

export const ContainerEstadosFreteGratis = styled.div<{theme: ThemeType}>`
  width: 100rem;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .flexContainer {
    width: 100%;
    padding: 0 10%;
    column-gap: 2rem;
    row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

    .containerEstado {
      cursor: pointer;
      font-size: 2.5rem;
      color: ${({theme}) => theme.mode === 'light' ? theme.colors.white : theme.colors.background1};
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 10rem;
      width: 10%;
      height: 5rem;
      background-color: ${(props) => props.theme.colors.elements};
      border-radius: 10px;
    }

    .ativo {
      border: 1px solid ${(props) => props.theme.colors.brandPrimary};
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`;
