import React, { memo } from "react";
import PrintablePedido from "../../../components/PrintablePedido/PrintablePedido";
import "./styles";
import PedidosImpressaoContainer from "./styles";

interface PedidosImpressaoProps {
  pedidos: any;
}

const PedidosImpressao: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PedidosImpressaoProps
> = ({ pedidos }, ref) => {
  return (
    <PedidosImpressaoContainer ref={ref}>
      {pedidos.map((pedido, index) => (
        <PrintablePedido
          key={index}
          pedido={pedido}
          informacoesPagamento={pedido.informacoesPagamento}
        />
      ))}
    </PedidosImpressaoContainer>
  );
};

export default memo(React.forwardRef(PedidosImpressao));
