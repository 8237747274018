import React, { useContext } from "react";
import "./styles";
import { ImageUploadLogoContainer, ModalCroppingContainer } from "./styles";
import { ImageUploadWithCrop } from "../ImageUploadWithCrop";
import { ReactComponent as Plus } from "../../../assets/newImages/icons/plus.svg";
import { ReactComponent as Loading } from "../../../assets/newImages/icons/rounded.svg";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";

interface ImageUploadLogoProps {
  value: string;
  onChange: (base64: string, imagem: string, size: number) => void;
  onLoading?: (loading: boolean) => void;
  configIn: "empresa" | "configuracoes";
}

const ImageUploadLogo: React.FC<ImageUploadLogoProps> = ({
  onChange,
  value,
  configIn,
}) => {
  const { theme } = useTheme();

  const [loading, setLoading] = React.useState(false);
  const {
    configuracoes,
    processarLogo,
    loading: loadingConfigs,
  } = useContext(ConfiguracoesContext);

  return (
    <ImageUploadLogoContainer theme={theme}>
      <ImageUploadWithCrop
        onLoading={(loading) => {
          setLoading(loading);
        }}
        className={`logo-input-container-empresa`}
        acceptFormats={".png, .jpeg, .jpg, .webp"}
        value={value}
        aspectRatio={1 / 1}
        onChange={(base64, imagem, size) => processarLogo(base64)}
        circularCrop={true}
        resize={{ width: 500, height: 500 }}
        minWidth={500}
        minHeight={500}
        errorMargin={50}
        onOpenCrop={(cropping, cancelar, salvar) => (
          <ModalRelativo
            removeConfirm={true}
            isOpen={true}
            setIsOpen={() => {}}
            normalModal={configIn === "configuracoes" ? true : false}
            titulo={""}
            subtitulo={""}
            width="auto"
            background="none"
            center={true}
            right="40%"
          >
            <ModalCroppingContainer theme={theme}>
              <div className="titulo">Editar imagem</div>
              {cropping}
              <div className="botoes">
                {cancelar}
                {salvar}
              </div>
            </ModalCroppingContainer>
          </ModalRelativo>
        )}
      >
        <div className="logo-wrapper">
          <div className="logo">
            {loading || loadingConfigs ? (
              <div className="loading">
                <Loading />
              </div>
            ) : (
              <div className="plus">
                <Plus />
              </div>
            )}
          </div>
          <div className="texto">Logotipo</div>
        </div>
      </ImageUploadWithCrop>
    </ImageUploadLogoContainer>
  );
};

export default ImageUploadLogo;
