import React from "react";
import "./styles";
import { VisualMainContainer } from "./styles";
import { Visual } from "../Visual/Visual";
import VisualColunas from "../VisualColunas/VisualColunas";
import VisualVariacoes from "../VisualVariacoes/VisualVariacoes";
import VisualFiltros from "../VisualFiltros/VisualFiltros";

interface VisualMainProps {}

const VisualMain: React.FC<VisualMainProps> = ({}) => {
  return (
    <VisualMainContainer>
      <Visual configIn="configuracoes" />
      <VisualColunas configIn="configuracoes" />
      <VisualVariacoes configIn="configuracoes" />
      <VisualFiltros configIn="configuracoes" />
    </VisualMainContainer>
  );
};

export default VisualMain;
