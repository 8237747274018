import styled from "styled-components";
import theme from "../../Themes/light";

const PaginacaoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  button {
    border: none;
    color: ${theme.colors.elements};
    font-size: 2.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0;
    padding: 0;
    background-color: transparent;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      color: ${theme.colors.brandSecondary};
      transform: scale(1.2);
      font-weight: bold;
    }
  }

  .paginas {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 25rem;
  }

  .elipsis {
    font-size: 2.5rem;
    color: ${theme.colors.elements};
  }
`;

export default PaginacaoContainer;
