import React, { useContext, useState } from "react";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { ContainerGoogle } from "./styles";
import { Input } from "../../Input";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";

export const Google: React.FC = () => {
  const { theme } = useTheme();
  let contentValueGoogle = "8AYG4Rel1rJ7tVOzDunGmcKP5AACRu9eekr5a6pMc9I";
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  return (
    <ContainerGoogle theme={theme}>
      <div className="grid">
        <div className="box">
          <div className="input">
            <Input
              type="text"
              label="Google"
              value={configuracoes.verificacoes_de_dominio.google}
              maxLength={43}
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  verificacoes_de_dominio: {
                    ...configuracoes.verificacoes_de_dominio,
                    google: newValue,
                  },
                });
              }}
              placeholder="8AYG4Rel1rJ7tVOzDunGmcKP5AACRu9eekr5a6pMc9I"
            />
          </div>
          <div className="texto-input">
            Insira o content do código conforme destaque no exemplo:
            <br />
            {`<meta name="google-site-verification" content="`}
            <strong>{contentValueGoogle}</strong>
            {`" />`}
          </div>
        </div>
        <Input
          type="text"
          label="Google Analytics"
          value={configuracoes.dados_google.analytics}
          maxLength={15}
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_google: {
                ...configuracoes.dados_google,
                analytics: newValue,
              },
            });
          }}
          placeholder="Insira o código do Google Analytics"
        />

        <Input
          type="text"
          label="Google Tag Manager"
          value={configuracoes.dados_google.tag_manager}
          maxLength={11}
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_google: {
                ...configuracoes.dados_google,
                tag_manager: newValue,
              },
            });
          }}
          placeholder="Insira o código do Google Tag Manager"
        />

        <Input
          type="text"
          label="Google ADS"
          value={configuracoes.dados_google.ads}
          maxLength={10}
          onChange={(newValue) => {
            setConfiguracoes({
              ...configuracoes,
              dados_google: {
                ...configuracoes.dados_google,
                ads: newValue,
              },
            });
          }}
          placeholder="Insira o código do Google ADS"
        />
      </div>
    </ContainerGoogle>
  );
};
