import styled, { css } from "styled-components";

const commonStyles = css`
  display: contents;
  .containerIntermediadores {
    width: 100rem;
    max-width: 90vw;
    > .togglesContainer {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 3rem;
      .dropdown {
        .configsCartaoContainer {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 40rem;
          align-items: center;
          gap: 3rem;
          padding-bottom: 3rem;

          &::after {
            content: "";
            position: absolute;
            width: 35rem;
            height: 2px;
            border-radius: 50px;
            background-color: ${(props) => props.theme.colors.elements};
            bottom: 0;
          }
        }

        .configsPixContainer {
          width: 40rem;
        }
      }
    }
  }
`;

export const ContainerIntermediadorContainer = styled.div<{
  configIn: "configuracoes" | "empresa" | "catalogo";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "empresa" &&
    css`
      .containerIntermediadores {
        .togglesContainer {
          gap: 1rem;
          .dropdown {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    `};
  ${({ configIn }) =>
    configIn === "catalogo" &&
    css`
      .containerIntermediadores {
        width: 100%;
        max-width: 100%;
        .togglesContainer {
          gap: 0rem;
          width: 100%;
          .containerToggleConfigurar {
            width: 100%;
            .nome {
              color: ${(props) => props.theme.colors.brandSecondary};
              width: 100%;
            }
          }
          .dropdown {
            width: 100%;
            .configsCartaoContainer {
              padding: 0 0 3rem 0;
              width: 100%;
              gap: 1rem;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
              &::after {
                display: none;
              }
            }
            .configsPixContainer {
              width: 100%;
            }
          }
        }
      }
    `}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      .containerIntermediadores {
        width: 100%;
        max-width: 100%;
        padding: 0 2rem;
        .togglesContainer {
          gap: 0rem;
          .containerToggleConfigurar {
            width: 100%;
            .nome {
              color: ${(props) => props.theme.colors.brandSecondary};
              font-weight: bold;
              width: 100%;
            }
          }
          .dropdown {
            width: 100%;
            .configsCartaoContainer {
              padding: 0 0 3rem 0;
              width: 100%;
              gap: 1rem;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
              &::after {
                display: none;
              }
            }
            .configsPixContainer {
              width: 100%;
            }
          }
        }
      }
    `}
`;

// Add other styled components for the nested elements if necessary
