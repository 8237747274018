import React, { CSSProperties } from "react";
import SkeletonTableContainer from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonTableProps {}

interface CSSPropertiesWithMultiValues extends CSSProperties {
  "--i"?: string;
}

export const SkeletonTable: React.FC<SkeletonTableProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <SkeletonTableContainer theme={theme}>
        <div className="mockupSituacoes hideMobile">
          {Array(8)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  style={{ "--i": `${index}` } as React.CSSProperties}
                  key={index}
                ></div>
              );
            })}
        </div>
        <table className="loadingState">
          <tbody>
            {Array(25)
              .fill(0)
              .map((_, index) => {
                return (
                  <tr
                    style={{ "--i": `${index}` } as React.CSSProperties}
                    key={index}
                  >
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </SkeletonTableContainer>
    </>
  );
};
