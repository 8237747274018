import styled from "styled-components";

const StyledInputComponents = styled.div`
  width: 100%;
  .disabledInput {
    cursor: not-allowed !important;
  }

  .inputComponentContainer {
    width: 100%;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    position: relative;
    .left {
      justify-content: flex-start;
    }
    .center {
      justify-content: center;
    }
    .right {
      justify-content: flex-end;
    }

    .label {
      font-size: 2.5rem;
      width: 100%;
      font-weight: normal;
      color: ${(props) => props.theme.colors.brandSecondary};
      display: flex;
      align-items: center;
      text-align: justify;
      white-space: normal;
      padding: 5px 0;

      > input {
        margin-right: 1rem;
      }

      .info-icon{
        margin-left: 2rem;
      }
    }

    .input {
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: ${(props) =>
    props.theme.mode == "light"
      ? "unset"
      : props.theme.colors.brandSecondary};

      label {
        color: ${(props) => props.theme.colors.brandSecondary};
        font-weight: normal;
      }

      input {
        font-size: 2.5rem;
        width: 100%;
        border-radius: 10rem;
        border: 1px solid transparent;
        padding: 1rem 4rem;
        height: 6rem;
        background-color: ${(props) => props.theme.colors.white};

        &::placeholder {
          outline: none;
          color: ${(props) => props.theme.colors.placeholder};
        }

        &:focus {
          outline: none;
          border-bottom: 1px solid ${(props) => props.theme.colors.brandPrimary};
          color: ${(props) =>
    props.theme.mode == "light"
      ? "unset"
      : props.theme.colors.brandSecondary};
        }

        //disabled background
        &:disabled {
          background-color: ${(props) => props.theme.colors.white};
          color: ${(props) => props.theme.colors.placeholder};
        }
      }

      .maxLenght {
        color: #c7c7c7;
        position: absolute;
        right: 10px;
      }
    }

    .comIcone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .iconeContainer {
        cursor: pointer;
        position: absolute;
        right: 2rem;
        width: 3rem;
        height: 80%;

        .icone {
          object-fit: contain;
          width: 100%;
          height: 100%;
          fill: ${(props) => props.theme.colors.brandPrimary};
        }
      }
    }
  }
`;

export default StyledInputComponents;
