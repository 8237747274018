import styled from "styled-components";
import theme from "../../Themes/light";

const RelatoriosContainer = styled.div`
  padding: 5rem 10rem;

  .relatorios-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .tipo {
      position: absolute;
      padding: 3rem 3rem;
      top: 0;
      pointer-events: none;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .relatorio-container {
        transition: 0.3s ease-in-out;
        text-decoration: none;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
        width: 100%;
        position: relative;
        padding: 3rem 0 3rem 0;
        color: ${(props) => props.theme.colors.brandSecondary};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        .titulo {
          width: 100%;
          text-align: left;
          font-size: 3rem;
          font-weight: bold;
        }

        .descricao {
          width: 100%;
          align-self: flex-start;
          text-align: justify;
          font-size: 2.5rem;
          max-width: 50%;
        }

        &:first-of-type::after,
        &:not(:last-of-type)::after {
          content: "";
          position: absolute;
          top: 100%;
          width: calc(100% + 6rem);
          height: 1px;
          border-radius: 50px;
          background-color: ${(props) => props.theme.colors.placeholder};
        }
      }

      & > .ativo {
        opacity: 1;
        pointer-events: all;
      }
    }

    & > .ativo {
      pointer-events: all;
      padding-bottom: 10rem;
    }
  }

  @media (max-width: 1024px) {
    padding: 5rem 1rem;

    .relatorios-container {
      .tipo {
        .relatorio-container {
          .descricao {
            max-width: 70%;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .relatorios-container {
      .tipo {
        .relatorio-container {
          .descricao {
            max-width: 100%;
          }
        }
      }
    }
  }
`;

export default RelatoriosContainer;
