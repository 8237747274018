import { createContext } from "react";
import { User } from "../../types/User";

export type AuthContextType = {
  user: User | null | false;
  signin: (email: string, password: string) => Promise<boolean | string>;
  signout: () => void;
  validateToken: () => Promise<boolean>;
  permissoesUsuario: number[];
  loading: boolean;
};

export const AuthContext = createContext<AuthContextType>(null!);
