import { max } from "moment";
import styled from "styled-components";
import theme from "../../Themes/light";

export const ContainerConfiguracoes = styled.div`
  width: 100%;
  padding: 5rem 10rem 20rem 10rem;

  .grid-conteudo {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;

    .container-conteudo-configs {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      #form-configs {
        display: contents;
      }

      .configuracoes-container {
        position: relative;
        width: 100%;
        height: 100%;

        .container-scroll-configs {
          position: absolute;
          padding-bottom: 0 !important;
          width: 100%;
          height: 100%;
          .node-mobile {
            display: contents;
            &:first-of-type {
              .nodes-dropdown-mobile {
                .children-wrapper {
                  &:first-of-type {
                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }
            .nodes-dropdown-mobile {
              display: contents;
              .children-wrapper {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 4rem 4rem;
                &::after {
                  content: "";
                  position: absolute;
                  border-radius: 50px;
                  width: 95%;
                  height: 2px;
                  top: 0;
                  background-color: ${(props) => props.theme.colors.elements};
                }
              }
            }
          }
        }
      }

      .footer-configs {
        width: 100%;
        height: 100%;
        gap: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .button-salvar {
          width: 30rem;
        }
      }
      .visualizacao-ativa {
        display: none;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 5rem 5rem 13rem 5rem;
    .grid-conteudo {
      display: block;
      .container-conteudo-configs {
        display: flex;
        flex-direction: column;
        .configuracoes-container {
          .container-scroll-configs {
            position: relative;
            .node-mobile {
              display: block;
              &:first-of-type {
                .node-mobile-title {
                  border-radius: 4rem 4rem 0px 0px;
                }
              }
              &:last-of-type {
                .node-mobile-title {
                  border-radius: 0px 0px 4rem 4rem;
                }
                .node-mobile-title-active {
                  border-radius: 0px;
                }
              }
              &:not(:last-of-type) {
                .node-mobile-title {
                  &::after {
                    transition: all 0.3s linear;
                    content: "";
                    position: absolute;
                    top: calc(100% - 1px);
                    width: 98%;
                    height: 1px;
                    border-radius: 50px;
                    background-color: ${(props) => props.theme.colors.elements};
                  }
                }
              }
              .nodes-dropdown-mobile {
                display: block;
                padding: 0;
                .children-wrapper {
                  padding: 4rem 2rem;
                  background-color: ${(props) =>
                    props.theme.colors.background1};
                  &:first-of-type {
                    &::after {
                      display: none;
                    }
                  }
                  .flexTituloConfigs {
                    .tituloContainer {
                      .titulo {
                        .subtitulo {
                          display: none;
                        }
                        .barra {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }

              .node-mobile-title {
                justify-content: center;
                display: flex;
                padding: 4rem 2rem;
                background-color: ${(props) => props.theme.colors.white};
                transition: all 0.3s ease-in-out;
                position: relative;
                .node-mobile-title-text {
                  width: 100%;
                  text-align: left;
                  transition: all 0.3s linear;
                  color: ${(props) => props.theme.colors.brandSecondary};
                  font-size: 3rem;
                }
                .angle {
                  fill: ${(props) => props.theme.colors.elements};
                  transition: all 0.2s ease-in-out;
                  transform: rotate(270deg);
                }
                .up {
                  transform: rotate(360deg);
                }
              }
              .node-mobile-title-active {
                overflow: hidden;
                &::before {
                  content: "";
                  position: absolute;
                  top: calc(100% - 1px);
                  width: 98%;
                  height: 1px;
                  border-radius: 50px;
                  z-index: 1;
                  background-color: ${(props) => props.theme.colors.titleColor};
                  animation: comeFromLeft 0.2s ease-in-out;
                }
                @keyframes comeFromLeft {
                  0% {
                    transform: translateX(-100%);
                  }
                  100% {
                    transform: translateX(0%);
                  }
                }
                .node-mobile-title-text {
                }
                .angle {
                  fill: ${(props) => props.theme.colors.titleColor};
                }
              }
            }
          }
          .scrollBonito {
            overflow-y: visible;
          }
        }
        .footer-configs {
          justify-content: space-between;
          .visualizacao-ativa {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          .button-visualizar {
            background-color: ${(props) => props.theme.colors.elements};
            color: ${(props) => props.theme.colors.brandSecondary};
            width: 50%;
          }
          .button-salvar {
            width: 50%;
          }
        }
        .visualizacao-ativa {
          display: block;
          width: 100%;
          .dropdown-visualizacao {
            .div-visualizacao {
              display: flex;
              justify-content: center;
              padding: 2rem 0 0 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 380px) {
    padding: 5rem 1rem 13rem 1rem;
  }
`;
