import React from 'react';
import './styles';
import { UilBell } from "@iconscout/react-unicons";
import { AvisoPagamentoAtrasadoContainer } from './styles';
import { useTheme } from '../../contexts/Theme/ThemeContext';

interface AvisoPagamentoAtrasadoProps {
    tipoPagamento: "1" | "2";
}

const tipoPagamentoMap = {
    "1": "boleto",
    "2": "cartao"
};
const links = {
    "boleto": "https://api.whatsapp.com/send?phone=555434434563&text=Estou%20no%20painel%20do%20Conecta%20Venda%20e%20estou%20com%20pagamento%20em%20atraso.%20Gostaria%20da%20segunda%20via%20do%20boleto.%20",
    "cartao": "https://api.whatsapp.com/send?phone=555434434563&text=Estou%20no%20painel%20do%20Conecta%20Venda%20e%20estou%20com%20erro%20no%20cart%C3%A3o.%20Gostaria%20de%20regularizar%20o%20pagamento.%20"
};

const AvisoPagamentoAtrasado: React.FC<AvisoPagamentoAtrasadoProps> = ({tipoPagamento}) => {

   const {theme} = useTheme();
   const tipoPagamentoTexto = tipoPagamentoMap[tipoPagamento] || "desconhecido";
   const link = links[tipoPagamentoTexto];

   const mensagem = tipoPagamentoTexto  === 'cartao'
   ? `Regularize o pagamento com nosso time financeiro para evitar o congelamento de sua conta.`
   : `Regularize o pagamento com nosso time financeiro para evitar o congelamento de sua conta.`;

   const pagamento = tipoPagamentoTexto  === 'cartao'
   ? "PAGAMENTO EM ATRASO"
   : "ERRO NO CARTÃO";

    return (
        <a href={link} style={{ textDecoration: 'none' }}>
            <AvisoPagamentoAtrasadoContainer theme={theme}>
                <div className="faixa">
                    <div className="texto_caps">
                        <UilBell className="sino" />{pagamento}<span className='barra'>&nbsp;|</span>
                    </div>
                    <div className="texto">
                        {mensagem}
                    </div>
                </div>
            </AvisoPagamentoAtrasadoContainer>
        </a>
    )
}

export default AvisoPagamentoAtrasado;