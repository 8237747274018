import React from "react";
import PaginacaoContainer from "./styles";

interface PaginacaoProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Paginacao: React.FC<PaginacaoProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getPaginationArray = (current: number, total: number) => {
    const delta = 1;
    const range: any = [];
    const rangeWithDots: any = [];
    let l;

    range.push(1);
    if (total <= 1) {
      return range;
    }

    for (
      let i = Math.max(2, current - delta);
      i <= Math.min(total - 1, current + delta);
      i++
    ) {
      range.push(i);
    }

    range.push(total);

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const pages = getPaginationArray(currentPage, totalPages);

  return (
    <PaginacaoContainer
      style={{ visibility: totalPages <= 1 ? "hidden" : "visible" }}
    >
      <button
        onClick={() =>
          onPageChange(currentPage - 1 >= 1 ? currentPage - 1 : currentPage)
        }
        style={{
          visibility:
            currentPage === 1 || totalPages <= 1 ? "hidden" : "visible",
        }}
      >
        <svg
          width="7"
          height="11"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.21384 10.2499C6.39528 10.0579 6.49335 9.80183 6.48665 9.53773C6.47995 9.27363 6.36901 9.02291 6.17807 8.84033L2.54814 5.34129L5.9972 1.71263C6.17864 1.5206 6.27672 1.26458 6.27002 1.00048C6.26331 0.736375 6.15238 0.485657 5.96143 0.30308C5.86612 0.21174 5.75367 0.140175 5.63056 0.0925137C5.50745 0.0448526 5.37613 0.0220386 5.24416 0.0253874C5.11219 0.0287362 4.98219 0.0581814 4.86166 0.112025C4.74112 0.165869 4.63245 0.243045 4.54189 0.339101L0.410813 4.68529C0.319473 4.7806 0.247908 4.89306 0.200247 5.01617C0.152586 5.13927 0.129771 5.2706 0.13312 5.40257C0.136469 5.53454 0.165915 5.66454 0.219758 5.78507C0.273602 5.9056 0.350778 6.01428 0.446834 6.10484L4.7943 10.2859C4.88961 10.3772 5.00206 10.4488 5.12517 10.4965C5.24827 10.5441 5.3796 10.5669 5.51157 10.5636C5.64354 10.5602 5.77354 10.5308 5.89407 10.477C6.0146 10.4231 6.12328 10.3459 6.21384 10.2499Z"
            fill="#C7C7C7"
          />
        </svg>
      </button>
      <div className="paginas">
        {pages.map((page, idx) =>
          typeof page === "number" ? (
            <button
              key={idx}
              onClick={() => onPageChange(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page.toString().padStart(2, "0")}
            </button>
          ) : (
            <span className="elipsis" key={idx}>
              &hellip;
            </span>
          ),
        )}
      </div>
      <button
        onClick={() =>
          onPageChange(
            currentPage + 1 <= totalPages ? currentPage + 1 : currentPage,
          )
        }
        style={{
          visibility:
            currentPage === totalPages || totalPages <= 1
              ? "hidden"
              : "visible",
        }}
      >
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.290794 1.2542C0.104543 1.44156 1.28577e-06 1.69502 1.25112e-06 1.9592C1.21648e-06 2.22339 0.104543 2.47684 0.290794 2.6642L3.83079 6.2542L0.290793 9.7942C0.104542 9.98156 1.65883e-07 10.235 1.31239e-07 10.4992C9.65951e-08 10.7634 0.104542 11.0168 0.290793 11.2042C0.383755 11.2979 0.494356 11.3723 0.616216 11.4231C0.738075 11.4739 0.868781 11.5 1.00079 11.5C1.1328 11.5 1.26351 11.4739 1.38537 11.4231C1.50723 11.3723 1.61783 11.2979 1.71079 11.2042L5.95079 6.9642C6.04452 6.87124 6.11892 6.76064 6.16968 6.63878C6.22045 6.51692 6.24659 6.38621 6.24659 6.2542C6.24659 6.12219 6.22045 5.99148 6.16968 5.86963C6.11892 5.74777 6.04452 5.63717 5.95079 5.5442L1.71079 1.2542C1.61783 1.16047 1.50723 1.08608 1.38537 1.03531C1.26351 0.984542 1.13281 0.958403 1.00079 0.958403C0.868782 0.958403 0.738076 0.984542 0.616217 1.03531C0.494358 1.08608 0.383757 1.16047 0.290794 1.2542Z"
            fill="#C7C7C7"
          />
        </svg>
      </button>
    </PaginacaoContainer>
  );
};

export default Paginacao;
