import React, { useContext, useEffect } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ContainerEntregaRetirada } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface EntregaRetiradaProps {
  onConfigurarCorreios: () => void;
  onConfigurarEntregaLocal: () => void;
  onConfigurarFreteEspecial: (length: number) => void;
  onConfigurarFreteGratis: (length: number) => void;
  onConfigurarRetiradaNoLocal: () => void;
}

const EntregaRetirada: React.FC<EntregaRetiradaProps> = ({
  onConfigurarCorreios,
  onConfigurarFreteEspecial,
  onConfigurarEntregaLocal,
  onConfigurarFreteGratis,
  onConfigurarRetiradaNoLocal,
}) => {
  const { configuracoes, setConfiguracoes, fretes_gratis, fretes_especiais } =
    useContext(ConfiguracoesContext);

  const { theme } = useTheme();

  return (
    <>
      <ContainerEntregaRetirada theme={theme}>
        <div className="containerEntregaRetirada">
          <Titulo
            justifyContent="center"
            flexDirection="column-reverse"
            titulo="Entrega e Retirada"
            subtitulo={`Selecione o tipo de frete a ser <br/>calculado no fechamento do pedido.`}
          />
          <div className="entrega">
            <div className="titulo">Entrega</div>
            <ToggleConfigurar
              nome="Correios"
              value={configuracoes.frete.correiosAtivo}
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  frete: {
                    ...configuracoes.frete,
                    ["correiosAtivo"]: newValue,
                  },
                });
              }}
              onConfigurar={onConfigurarCorreios}
            />

            <ToggleConfigurar
              nome="Entrega local"
              value={configuracoes.frete.entregaLocal.ativo}
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  frete: {
                    ...configuracoes.frete,
                    ["entregaLocal"]: {
                      ...configuracoes.frete["entregaLocal"],
                      ativo: newValue,
                    },
                  },
                });
              }}
              onConfigurar={onConfigurarEntregaLocal}
            />

            <ToggleConfigurar
              nome="Frete especial"
              value={configuracoes.frete.freteEspecialAtivo}
              isValid={(newValue) =>
                (fretes_especiais.length === 0 ||
                  !fretes_especiais.some((frete) => frete.ativo)) &&
                configuracoes.frete.freteEspecialAtivo
                  ? false
                  : true
              }
              invalidText="Você precisa configurar um frete especial <br/> para ter essa opção ativa."
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  frete: {
                    ...configuracoes.frete,
                    ["freteEspecialAtivo"]: newValue,
                  },
                });
              }}
              onConfigurar={() =>
                onConfigurarFreteEspecial(fretes_especiais.length)
              }
            />

            <ToggleConfigurar
              nome="Frete grátis"
              value={configuracoes.frete.freteGratisAtivo}
              isValid={(newValue) =>
                (fretes_gratis.length === 0 ||
                  !fretes_gratis.some((frete) => frete.ativo)) &&
                configuracoes.frete.freteGratisAtivo
                  ? false
                  : true
              }
              invalidText="Você precisa configurar um frete grátis <br/> para ter essa opção ativa."
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  frete: {
                    ...configuracoes.frete,
                    ["freteGratisAtivo"]: newValue,
                  },
                });
              }}
              onConfigurar={() => onConfigurarFreteGratis(fretes_gratis.length)}
            />
          </div>
          <div className="retirada">
            <div className="titulo">Retirada</div>
            <ToggleConfigurar
              nome="No local"
              value={configuracoes.frete.retirada_no_local.ativo}
              isValid={(newValue) =>
                configuracoes.frete.retirada_no_local.ativo &&
                !configuracoes.frete.retirada_no_local.intervalos.some(
                  (intervalo) => intervalo.dias.length,
                )
                  ? false
                  : true
              }
              invalidText="Você precisa configurar pelo menos um dia <br/> para ter essa opção ativa."
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  frete: {
                    ...configuracoes.frete,
                    ["retirada_no_local"]: {
                      ...configuracoes.frete["retirada_no_local"],
                      ativo: newValue,
                    },
                  },
                });
              }}
              onConfigurar={onConfigurarRetiradaNoLocal}
            />
          </div>
        </div>
      </ContainerEntregaRetirada>
    </>
  );
};
export default EntregaRetirada;
