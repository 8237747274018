import styled from 'styled-components';
import { ThemeType } from '../../../../types/Theme';

const DepositosContainer = styled.div<{theme: ThemeType, loading: boolean}>`
    border-radius: 6rem;
    position: relative;
    overflow: hidden;
    padding: 2rem 5rem;
    min-height: ${({loading}) => loading ? '40rem' : 'auto'};
    .titulo{
        font-size: 3rem;
        text-align: center;
        font-weight: bold;
        color: ${({theme}) => theme.colors.titleColor};
        margin-bottom: 2rem;
    }
    .depositos{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        .deposito{

        }
    }
    .texto{
        font-size: 2.5rem;
        text-align: justify;
        margin-bottom: 5rem;
    }
    .botoes{
        margin-top:5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cancelar{
        font-size: 2.5rem;
        cursor: pointer; 
        }
    }
`;

export { 
    DepositosContainer,
};