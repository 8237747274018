import styled from 'styled-components';
import { ThemeType } from '../../../../types/Theme';

const ModaisConfigContainer = styled.div<{theme: ThemeType}>`
    .botoes{
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: space-between;
        .arrow{
            display: flex;
            cursor: pointer;
            font-size: 2.5rem;
            align-items: center;
            gap: 1rem;
            svg{
                margin-top: 2px;
                stroke: ${props => props.theme.colors.brandSecondary};
            }
        }
    }
`;

export { 
    ModaisConfigContainer,
};