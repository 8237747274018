import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlidersSVG } from "../../assets/newImages/icons/sliders-v.svg";
import AsideMenu from "../../components/AsideMenu/AsideMenu";
import { Filtros } from "../../components/Filtros/Filtros";
import { FiltrosData } from "../../components/FiltrosData/FiltrosData";
import { FooterPaginas } from "../../components/FooterPaginas/FooterPaginas";
import { Input } from "../../components/Input";
import Paginacao from "../../components/Paginacao";
import { Select } from "../../components/Select";
import { SkeletonTableCestas } from "../../components/Skeletons/SkeletonTableCestas/SkeletonTableCestas";
import Table from "../../components/Table/Table";
import { Toggle } from "../../components/Toggle/Toggle";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { CatalogosApi } from "../../hooks/catalogos";
import { CestasAbandonadasApi } from "../../hooks/cestasAbandonadasApi";
import { PerfilApi } from "../../hooks/perfilApi";
import { copy } from "../../services/Functions";
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  phoneMask
} from "../../services/Masks";
import "./styles.ts";
import { ContainerCestasAbandonadas } from "./styles.ts";

type camposListagem = 
"id_cesta_abandonada"
| "descricao"
| "data_atualizacao"
| "observacao"
| "moeda"
| "valor_total"
| "nome_razao_social"
| "cpf_cnpj"
| "tabela_preco"
| "inscricao_estadual"
| "email"
| "telefone"
| "data_nascimento"
| "usuario"
| "estado"
| "cep"
| "cidade"
| "bairro"
| "endereco_completo";

const camposPersonalizados: 
  Array<{
    nome: string;
    campo: camposListagem;
    mask?: (value: string) => string;
  }>
  = [
  {
    nome: "Catálogo",
    campo: "descricao",
  },
  {
    nome: "Data de criação",
    campo: "data_atualizacao",
    mask: (value) => dateMask(value),
  },
  {
    nome: "Observação",
    campo: "observacao",
  },
  {
    nome: "Cifrão",
    campo: "moeda",
  },
  {
    nome: "Valor",
    campo: "valor_total",
    mask: (value) => {
      return Number(value).toFixed(2).replace(".", ",");
    },
  },
  {
    nome: "Cliente",
    campo: "nome_razao_social",
  },
  {
    nome: "CPF/CNPJ",
    campo: "cpf_cnpj",
    mask: (value: string) => {
      return value.length <= 11 ? cpfMask(value) : cnpjMask(value);
    },
  },
  { nome: "Tabela de preço", campo: "tabela_preco" },
  {
    nome: "Inscrição Estadual",
    campo: "inscricao_estadual",
  },
  { nome: "E-mail", campo: "email" },
  {
    nome: "Telefone",
    campo: "telefone",
    mask: phoneMask,
  },
  {
    nome: "Data de nascimento",
    campo: "data_nascimento",
    mask: (value) => dateMask(value),
  },
  { nome: "Vendedor", campo: "usuario" },
  { nome: "UF", campo: "estado" },
  { nome: "CEP", campo: "cep", mask: cepMask },
  { nome: "Cidade", campo: "cidade" },
  { nome: "Bairro", campo: "bairro" },
  {
    nome: "Endereço, nº e complemento",
    campo: "endereco_completo",
  },
];

interface CestasAbandonadasProps {}

const CestasAbandonadas: React.FC<CestasAbandonadasProps> = ({}) => {
  const navigate = useNavigate();
  const [asideMenu, setAsideMenu] = useState(false);
  const [loading, setLoading] = useState(true);

  const { theme } = useTheme();

  //api
  const perfilApi = PerfilApi();
  const catalogosApi = CatalogosApi();
  const cestasAbandonadasApi = CestasAbandonadasApi();

  //paginacao
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itensPorPagina, setItensPorPagina] = useState(50);

  const [cestasAbandonadas, setCestasAbandonadas] = useState<[]>([]);
  const [cestasAbandonadasFiltradas, setCestasAbandonadasFiltradas] = useState<
    Array<any> | undefined
  >(undefined);

  const [optionsUsuarios, setOptionsUsuarios] = useState<
    { name: string; value: string }[]
  >([]);
  const [optionsCatalogos, setOptionsCatalogos] = useState<
    { name: string; value: string }[]
  >([]);

  const [camposAtivos, setCamposAtivos] = 
    useState<camposListagem[]>(
      localStorage.getItem("camposAtivosCestas") ?
        JSON.parse(localStorage.getItem("camposAtivosCestas")!) as camposListagem[] :
        camposPersonalizados.map(campo => campo.campo));
  const [camposAtivosAux, setCamposAtivosAux] = useState<camposListagem[]>(copy(camposAtivos));

  //filtros
  const [filtroPesquisa, setFiltroPesquisa] = useState("");
  const [filtroUsuario, setFiltroUsuario] = useState("");
  const [filtroCatalogo, setFiltroCatalogo] = useState("");
  const [filtroClientesIdentificados, setFiltroClientesIdentificados] =
    useState("true");
  const [dataInicio, setDataInicio] = useState(
    `${moment().subtract(30, "days").format("YYYY-MM-DD")} 00:00:00`
  );
  const [dataFim, setDataFim] = useState(
    `${moment().format("YYYY-MM-DD")} 23:59:59`
  );

  //auxiliares
  const [filtroUsuarioAux, setFiltroUsuarioAux] = useState("");
  const [filtroCatalogoAux, setFiltroCatalogoAux] = useState("");
  const [filtroClientesIdentificadosAux, setFiltroClientesIdentificadosAux] =
    useState("true");
  const [filtroPesquisaAux, setFiltroPesquisaAux] = useState("");

  //table
  const [tableColumns, setTableColumns] = useState<
    Array<{ key: string; label: string }>
  >([]);

  const init = async (
    dataInicio: string,
    dataFim: string,
    filtroUsuario: string,
    filtroCatalogo: string,
    filtroClientesIdentificados: string,
    camposAtivos: camposListagem[]
  ) => {
    setLoading(true);
    const [usuarios, catalogos, cestasAbandonadas] =
      await Promise.all([
        perfilApi.usuarios(),
        catalogosApi.getCatalogosFiltroPedidos(),
        cestasAbandonadasApi.getCestasAbandonadas(
          camposAtivos,
          { inicio: dataInicio, fim: dataFim },
          {
            usuario: filtroUsuario,
            catalogo: filtroCatalogo,
            clientes_identificados:
              filtroClientesIdentificados === "true" ? 1 : 0,
          }
        ),
      ]);

    setCestasAbandonadas(cestasAbandonadas);

    setOptionsUsuarios([
      { name: "Todos os usuários", value: "" },
      ...usuarios.map((usuario: any) => ({
        name: usuario.nome,
        value: usuario.nome,
      })),
    ]);

    setOptionsCatalogos([
      { name: "Todos os catálogos", value: "" },
      ...catalogos.map((catalogo: any) => ({
        name: catalogo.descricao,
        value: catalogo.id,
      })),
    ]);

    setTableColumns(
      camposPersonalizados
        .filter((campo) => camposAtivos.includes(campo.campo))
        .map((campo) => ({
          key: campo.campo,
          label: campo.nome,
          mask: campo.mask,
        }))
    );

    setTotalPages(Math.ceil(cestasAbandonadas.length / itensPorPagina));
    setCurrentPage(1);
  };

  const filtrarData = (data: {
    inicio: string;
    fim: string;
    placeholder: string;
  }) => {
    setDataInicio(data.inicio);
    setDataFim(data.fim);
    localStorage.setItem("pedidos-dataPlaceholder", data.placeholder);

    init(
      data.inicio,
      data.fim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  };

  const onAplicarFiltro = (
    filtroUsuario: string,
    filtroCatalogo: string,
    filtroClientesIdentificados: string
  ) => {
    setFiltroUsuario(filtroUsuario);
    setFiltroCatalogo(filtroCatalogo);
    setFiltroClientesIdentificados(filtroClientesIdentificados);

    init(
      dataInicio,
      dataFim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  };

  const handleDetalhesCesta = (cesta: { [key in camposListagem]: any }) => {
    const encodedNome = cesta.nome_razao_social ? encodeURIComponent(cesta.nome_razao_social) : "";

  
    navigate(
      `/painel/sacolas-abandonadas/sacola-abandonada/${
        cesta.id_cesta_abandonada
      }${
        "/" + encodedNome
      }`
    );
  };
  
  const handlePaginacao = (page: number) => {
    setCurrentPage(page);
  };

  const handleAplicarCampos = async () => {
    localStorage.setItem("camposAtivosCestas", JSON.stringify(camposAtivos));
    init(
      dataInicio,
      dataFim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  };

  //filtro de pesquisa
  useEffect(() => {
    if (filtroPesquisa === "") {
      setCestasAbandonadasFiltradas(undefined);
      if (cestasAbandonadas) {
        setTotalPages(Math.ceil(cestasAbandonadas.length / itensPorPagina));
        setCurrentPage(1);
      }
      setLoading(false);
    } else {
      const filtro = filtroPesquisa.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
      // filtra todos as cestas
      const novasCestasAbandonadasFiltradas =
        cestasAbandonadas.filter((cesta) => {
          if (
            Object.values(cesta).some((value) =>
              String(value).toLowerCase().includes(filtro)
            )
          )
            return cesta;
        }) || [];

      if (novasCestasAbandonadasFiltradas.length !== 0) {
        setCestasAbandonadasFiltradas(novasCestasAbandonadasFiltradas);
        setTotalPages(
          Math.ceil(novasCestasAbandonadasFiltradas.length / itensPorPagina) ||
            0
        );
      } else {
        setCestasAbandonadasFiltradas([]);
        setTotalPages(0);
      }

      setCurrentPage(1);
      setLoading(false);
    }
  }, [filtroPesquisa, cestasAbandonadas]);

  useEffect(() => {
    init(
      dataInicio,
      dataFim,
      filtroUsuario,
      filtroCatalogo,
      filtroClientesIdentificados,
      camposAtivos
    );
  }, []);

  return (
    <>
      <ContainerCestasAbandonadas theme={theme}>
        <div className="filtros-container">
          <div className="filtros">
            <div className="filtroPesquisa">
              <Input
                type={"text"}
                value={filtroPesquisaAux}
                icone="icons/search.svg"
                placeholder={
                  "Pesquisar por cliente, email, telefone ou catálogo"
                }
                onChange={(newValue) => {
                  newValue === filtroPesquisa
                    ? setLoading(false)
                    : setLoading(true);
                  setFiltroPesquisaAux(newValue);
                }}
                onDebounce={(newValue) => setFiltroPesquisa(newValue)}
                debounceTime={500}
              ></Input>
            </div>
            <div className="filtro">
              <Filtros
                onAplicar={() => {
                  onAplicarFiltro(
                    filtroUsuarioAux,
                    filtroCatalogoAux,
                    filtroClientesIdentificadosAux
                  );
                }}
                onCancelar={() => {
                  setFiltroUsuarioAux(filtroUsuario);
                  setFiltroCatalogoAux(filtroCatalogo);
                  setFiltroClientesIdentificadosAux(
                    filtroClientesIdentificados
                  );
                }}
              >
                <div className="containerSelects">
                  <Select
                    options={optionsUsuarios}
                    value={filtroUsuarioAux}
                    label="Usuário"
                    placeholder="Todos os usuários"
                    onChange={(newValue) => setFiltroUsuarioAux(newValue)}
                  ></Select>
                  <Select
                    options={optionsCatalogos}
                    value={filtroCatalogoAux}
                    label="Catálogo"
                    placeholder="Todos os catálogos"
                    onChange={(newValue) => setFiltroCatalogoAux(newValue)}
                  ></Select>
                  <Select
                    options={[
                      { name: "Clientes identificados", value: "true" },
                      { name: "Clientes não identificados", value: "false" },
                    ]}
                    value={filtroClientesIdentificadosAux}
                    label="Cestas"
                    placeholder="Clientes identificados"
                    onChange={(newValue) =>
                      setFiltroClientesIdentificadosAux(newValue)
                    }
                  ></Select>
                </div>
              </Filtros>
            </div>
            <div className="filtro-data">
              <FiltrosData
                onAplicar={(newValue) => filtrarData(newValue)}
                value={{
                  inicio: dataInicio,
                  fim: dataFim,
                  placeholder: "Últimos 30 dias",
                }}
              ></FiltrosData>
            </div>
          </div>
          <div className="inf-visiveis-container">
            <label
              className="inf-visiveis"
              onClick={() => setAsideMenu(!asideMenu)}
            >
              <label className="texto">Configurar informações visíveis</label>
              <SlidersSVG />
            </label>
          </div>
          <AsideMenu
            isOpen={asideMenu}
            setIsOpen={setAsideMenu}
            titulo="Minhas Sacolas Abandonadas /"
            subtitulo="Informações visíveis"
            disabled={camposAtivos.length === 0}
            onAplicar={handleAplicarCampos}
            onCancelar={() => {
              setCamposAtivos(camposAtivosAux);
            }}
            onOpen={() => {
              setCamposAtivosAux(copy(camposAtivos));
            }}
          >
            <div className="container-toggles">
              <div className="texto">
                Selecione abaixo quais colunas <br /> deseja que estejam
                visíveis
              </div>
              <div className="campos scrollBonito">
              {camposPersonalizados.map(
                (
                  campo: {
                    nome: string | (() => string);
                    campo: camposListagem;
                  },
                  index: number
                ) => {
                    return (
                      <div key={index}>
                        <label className="campo">
                          <Toggle
                            value={camposAtivos.includes(campo.campo)}
                            onChange={(newValue) => {
                              if (newValue) {
                                setCamposAtivos([...camposAtivos, campo.campo]);
                              } else {
                                setCamposAtivos(
                                  camposAtivos.filter(
                                    (campoAtivo) => campoAtivo !== campo.campo
                                  )
                                );
                              }
                            }}
                          />
                          {typeof campo.nome === "function"
                            ? campo.nome()
                            : campo.nome}
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </AsideMenu>
        </div>
        <div className="containerTable">
          {loading ? (
            <SkeletonTableCestas />
          ) : (
            <Table
              button={true}
              page={currentPage}
              columns={tableColumns}
              sortable={true}
              selectable={false}
              data={
                (cestasAbandonadasFiltradas !== undefined
                  ? cestasAbandonadasFiltradas
                  : cestasAbandonadas) || []
              }
              itensPorPagina={itensPorPagina}
              onButtonClicked={(item) => handleDetalhesCesta(item)}
              onDoubleClicked={(item) => handleDetalhesCesta(item)}
            />
          )}
        </div>
        <FooterPaginas>
          <div className="paginacao-table">
            <div className="container-paginacao-table">
              <div className="flex-container">
                <span>
                  <Paginacao
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePaginacao}
                  ></Paginacao>
                </span>
                {cestasAbandonadas && cestasAbandonadas.length > 0 && (
                  <span className="valor">
                    <div className="valor-flex">
                      <span className="texto">Valor total:</span>
                      <span>
                        {(cestasAbandonadas &&
                          cestasAbandonadas.length &&
                          cestasAbandonadas
                            .reduce((acumulador: number, cesta: any) => {
                              return (
                                acumulador +
                                (Number.parseFloat(cesta.valor_total) || 0)
                              );
                            }, 0)
                            .toFixed(2)
                            .replace(".", ",")) ||
                          ""}
                      </span>
                    </div>
                  </span>
                )}
              </div>
            </div>
          </div>
        </FooterPaginas>
      </ContainerCestasAbandonadas>
    </>
  );
};

export default CestasAbandonadas;
