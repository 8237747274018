import { max } from "moment";
import styled from "styled-components";

const transitionTime = "2s";
const SkeletonConfiguracoesContainer = styled.div`
  display: contents;
  .tree-skeleton {
    width: 100%;
    height: 100%;
    border-radius: 3rem 0 0 3rem;
    background-color: ${(props) => props.theme.colors.white};
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    animation-delay: calc(var(--i) * 0.01s);
  }
  .configs-skeleton {
    padding: 10rem 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 6rem;
    width: 100%;
    height: 100%;
    row-gap: 2rem;
    grid-template-areas:
      "sk1 sk1"
      "sk2 sk2"
      "sk3 sk3"
      "sk4 sk5"
      "sk6 sk6"
      "sk7 sk7"
      "sk8 sk8"
      "sk9 sk9"
      "null null"
      "null null"
      "sk10 sk10"
      "sk11 sk11"
      "sk12 sk12"
      "sk13 sk13"
      "sk14 sk14"
      "sk15 sk16"
      "sk17 sk17"
      "sk18 sk19"
      "sk20 sk20"
      "sk0 sk0";
    .config-skeleton {
      background-color: ${(props) => props.theme.colors.white};
      animation: pulsate ${transitionTime} ease-out infinite alternate;
      animation-delay: calc(var(--i) * 0.01s);
      width: 100%;
      height: 100%;
      border-radius: 3rem;
    }
  }
  @media (max-width: 1024px) {
    .tree-skeleton {
      display: none;
    }
    .configs-skeleton {
      grid-template-areas: none;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 13rem);
      grid-auto-rows: auto;
      padding: 0rem 0rem;
      gap: 1px;
      .config-skeleton {
        height: 100%;
        width: 100%;
        border-radius: 0rem;
        grid-area: auto !important;
        &:nth-of-type(n + 6) {
          display: none;
        }
        &:first-of-type {
          border-radius: 3rem 3rem 0rem 0rem;
        }
        &:nth-of-type(n + 5) {
          border-radius: 0rem 0rem 3rem 3rem;
        }
      }
    }
  }
`;

export { SkeletonConfiguracoesContainer };
