import { useContext, useState } from "react";
import { ReactComponent as Plus } from "../../assets/newImages/icons/plus.svg";
import { FloatingButtonContainer } from "./styles";
import { ScrollDirectionContext } from "../../contexts/ScrollDirection/ScrollDirectionContext";
import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ReactComponent as OptionsSVG } from "../../assets/newImages/icons/options.svg";

interface FloatingButtonProps {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  className?: string;
  target?: string;
  href?: string;
  subButton?: boolean;
  onClickSubButton?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  onClick,
  className,
  href,
  target,
  subButton,
  onClickSubButton,
}) => {
  const scrollDir = useContext(ScrollDirectionContext).direction;
  const { theme } = useTheme();

  return (
    <>
      <FloatingButtonContainer
        theme={theme}
        className={`${scrollDir === "up" && "floating-ativo"} ${
          className && className.toString()
        }`}
      >
        {subButton && (
          <div
            className="seletor-mobile"
            onClick={onClickSubButton ? onClickSubButton : () => {}}
          >
            <OptionsSVG className="icone-floating" />
          </div>
        )}
        {href ? (
          <Link
            to={href}
            target={target}
            className={``}
            onClick={onClick}
          >
            <Plus className="icone-floating" />
          </Link>
        ) : (
          <div
            className={``}
            onClick={onClick}
          >
            <Plus className="icone-floating" />
          </div>
        )}
      </FloatingButtonContainer>
    </>
  );
};

export default FloatingButton;
