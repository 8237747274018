import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

const NotificacoesContainer = styled.div<{ theme: ThemeType }>`
  padding: 5rem 8rem;
  width: 90vw;
  max-width: 80rem;
  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media(max-width: 768px){
    padding: 2rem 3rem;
  }
  .titulo {
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 768px){
      font-size: 2.5rem;
    }
    span{
      color: ${(props) => props.theme.colors.titleColor};
    }
    .toggle-notificacoes{
      font-size: 2.3rem;
      font-weight: normal;
      text-align: right;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.brandSecondary};
    }
    &.center{
      justify-content: center;
      color: ${(props) => props.theme.colors.titleColor};
      margin-bottom: 4rem;
    }
    font-weight: bold;
  }
  .notificacoes{
    width: 100%;
    padding: 0 2rem;
    max-height: 70vh;
    .sem-notificacoes{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      height: 20rem;
    }
    .data{
      width: 100%;
      display: flex;
      font-weight: bold;
      font-size: 3rem;
      margin: 1.5rem 0;
    }
    .textos{
      text-align: left;
      width: 100%;
      .texto{
        margin: 1rem 0;
        width: 100%;
        .notificacao-container{
          display: flex;
          width: 100%;
          .notificacao-hora{
            font-size: 2.3rem;            
          }
          .conteudo-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: -2px;
            margin-left: 2rem;
            .notificacao-titulo{
              font-size: 2.5rem;
              color: ${(props) => props.theme.colors.brandSecondary};
              font-weight: bold;
            }
            .notificacao-texto{
              text-align: justify;
            }
          }
        }
      }
    }
    
  }
  .salvar{
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .toggle{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    label{
      margin-right: 3rem;
    }
    
  }
  .texto {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    @media(max-width: 768px){
      font-size: 2rem;
    }
    
  }
  .botoes {
    padding: 3rem 0 5rem 0;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 80%;
      height: 1px;
      background-color: ${(props) => props.theme.colors.elements};
      top: 100%;
    }
    > a {
      width: 100%;
      text-decoration: none;
    }
  }
  a {
    width: 100%;
    text-decoration: none;
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    @media(max-width: 768px){
      font-size: 2rem;
    }
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 768px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 345px) {
    padding: 5rem 3rem;
  }
`;

export { NotificacoesContainer };

