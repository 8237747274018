import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import { ReactComponent as Arrow } from "../../../assets/newImages/icons/arrowLeft.svg";
import { Button } from "../../../components/Button";
import Form from "../../../components/Form";
import LoadingComFundo from "../../../components/Loading/LoadingComFundo/LoadingComFundo";
import Modal from "../../../components/Modal/Modal";
import Titulo from "../../../components/Titulo/Titulo";
import { ToggleConfigurar } from "../../../components/Toggle/ToggleConfigurar/ToggleConfigurar";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { IntegracaoContext } from "../../../contexts/Integracao/IntegracaoContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import ModaisConfig from "./ModaisConfig/ModaisConfig";
import "./styles";
import { ImportarPorErpContainer } from "./styles";

interface ImportarPorErpProps {
    onClose: () => void;
}

const ImportarPorErp: React.FC<ImportarPorErpProps> = ({ onClose }) => {
    const { theme } = useTheme();

    const [modalConfig, setModalConfig] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState<
        "bling" | "tiny" | "macro" | ""
    >("");

    const [formValid, setFormValid] = useState(false);

    const { configuracoes, setConfiguracoes, salvarConfiguracoes, loading } =
        useContext(ConfiguracoesContext);
    const {
        activeChaveIntegracao,
        chaveIntegracao,
        setActiveChaveIntegracao,
        onChangeIntegracao,
        onChangeIntegracaoBling,
        onChangeIntegracaoBlingV2,
        onChangeIntegracaoTiny,
    } = useContext(IntegracaoContext);

    const submitForm = async () => {
        await salvarConfiguracoes();
        Swal.fire({
            title: "Sucesso!",
            text: "Configurações salvas com sucesso!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
        })
        onClose();
    };

    const isIntegracaoValid = () => {
        return !(activeChaveIntegracao && !chaveIntegracao)
    }

    const isBlingValid = () => {
        return !(configuracoes.dados_bling.ativo && (
            !configuracoes.dados_bling.chave || 
            !configuracoes.dados_bling.codigo_loja_bling ||
            !configuracoes.dados_bling.multiplicador
        ))
    }

    const isBlingV2Valid = () => {
        return !(configuracoes.dados_bling_v2!.ativo && (
            !configuracoes.dados_bling_v2!.chave || 
            !configuracoes.dados_bling_v2!.codigo_loja_bling ||
            !configuracoes.dados_bling_v2!.multiplicador
        ))
    }

    const isTinyValid = () => {
        return !(configuracoes.dados_tiny_v2.ativo && (
            !configuracoes.dados_tiny_v2.chave || 
            !configuracoes.dados_tiny_v2.tabela_id ||
            !configuracoes.dados_tiny_v2.identificador_integrador ||
            !configuracoes.dados_tiny_v2.multiplicador
        ))
    }

    return (
        <ImportarPorErpContainer theme={theme}>
            {loading ? (
                <LoadingComFundo />
            ) : (
                <>
                    <Modal
                        width={"80rem"}
                        maxWidth="100%"
                        isOpen={modalConfig}
                        setModal={setModalConfig}
                        backdrop="static"
                        padding="5rem"
                    >
                        <ModaisConfig
                            isBlingValid={isBlingValid}
                            isTinyValid={isTinyValid}
                            isIntegracaoValid={isIntegracaoValid}
                            selectedConfig={selectedConfig}
                            onClose={() => setModalConfig(!modalConfig)}
                        />
                    </Modal>
                    <Form
                        id="formApis"
                        setFormValid={setFormValid}
                        onSubmit={submitForm}
                    >
                        <Titulo
                            title="Meus produtos /"
                            subtitle="Adicionar através do ERP"
                        />
                        <div className="texto">
                            Integre seu sistema de gestão (ERP) através da nossa
                            API de integração de dados e sincronize pedidos e/ou
                            produtos.
                        </div>
                        <ul>
                            <li>
                                Acesse nossa documentação para integração via
                                API,{" "}
                                <a
                                    href="https://api.conectavenda.com.br/docs/"
                                    target="_blank"
                                >
                                    clicando aqui.
                                </a>
                            </li>
                        </ul>
                        <div className="toggles">
                            <ToggleConfigurar
                                value={activeChaveIntegracao}
                                nome="Gerar Chave de API"
                                isValid={isIntegracaoValid()}
                                invalidText="Preencha os campos obrigatórios"
                                onConfigurar={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedConfig("macro");
                                    setModalConfig(true);
                                }}
                                onChange={onChangeIntegracao}
                            />
                            <ToggleConfigurar
                                value={configuracoes.dados_bling_v2 !== undefined ? configuracoes.dados_bling_v2.ativo : configuracoes.dados_bling.ativo}
                                isValid={configuracoes.dados_bling_v2 !== undefined ? isBlingV2Valid() : isBlingValid()}
                                invalidText="Preencha os campos obrigatórios"
                                nome="Integração Bling"
                                onConfigurar={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedConfig("bling");
                                    setModalConfig(true);
                                }}
                                onChange={configuracoes.dados_bling_v2 !== undefined ? onChangeIntegracaoBlingV2 : onChangeIntegracaoBling}
                            />
                            <ToggleConfigurar
                                value={configuracoes.dados_tiny_v2.ativo}
                                isValid={isTinyValid()}
                                invalidText="Preencha os campos obrigatórios"
                                nome="Integração Tiny"
                                onConfigurar={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedConfig("tiny");
                                    setModalConfig(true);
                                }}
                                onChange={onChangeIntegracaoTiny}
                            />
                        </div>
                        <div className="botoes">
                            <div className="arrow" onClick={() => onClose()}>
                                <Arrow />
                                voltar
                            </div>
                            <Button
                                title="Salvar e continuar"
                                className={`${!formValid ? "disabled" : ""}`}
                                loading={loading}
                                type="submit"
                            />
                        </div>
                    </Form>
                </>
            )}
        </ImportarPorErpContainer>
    );
};

export default ImportarPorErp;
