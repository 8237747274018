import { createContext } from "react";
import { Relatorio, Tipo } from "../../types/Relatorios";

export type RelatoriosContextType = {
  gerarRelatorio: (id_relatorio: string, variaveis: {}) => Promise<any>;
  exportarRelatorio: (id_relatorio: string, variaveis: {}) => Promise<any>;
  loading: boolean;
  relatorios: Relatorio[];
  tipos: Tipo[];
};

export const RelatoriosContext = createContext<RelatoriosContextType>(null!);
