import React, { useEffect, useState } from "react";
import LoadingSRC from "../../assets/jsons/Loading.json";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { NotificacoesApi } from "../../hooks/notificacoesApi";
import "./styles";
import { NotificacoesContainer } from "./styles";

import { Controls, Player } from "@lottiefiles/react-lottie-player";
import OneSignal from "react-onesignal";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { isIosSafari, isRunningInPWA } from '../../services/Functions';
import { Toggle } from "../Toggle/Toggle";


interface NotifiacoesProps {
  onClose?: () => void;
}

const Notificacoes: React.FC<NotifiacoesProps> = ({onClose}) => {
  const animation = LoadingSRC;
  const { theme } = useTheme();
  const [notificacoes, setNotificacoes] = useState<any>({});
  const [notificacoesHabilitadas, setNotificacoesHabilitadas] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingHabilitar, setLoadingHabilitar] = useState(false);
  let isSafariIos = isIosSafari();
  let isPwa = isRunningInPWA();

  const habilitarDesabilitar = async (habilitar: boolean) => {
    if (OneSignal.User.PushSubscription.id) {
      setLoadingHabilitar(true);
      var root = document.getElementsByTagName("html")[0];
      root.classList.add("loading");

      setTimeout(async () => {
        await NotificacoesApi().habilitarDesabilitar(
          OneSignal.User.PushSubscription.id as string,
          habilitar
        );
        setNotificacoesHabilitadas(habilitar);
        setLoadingHabilitar(false);
  
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: `Notificações ${habilitar ? "habilitadas" : "desabilitadas"}!`,
          timer: 1500,
          showConfirmButton: false,
        });
  
        root.classList.remove("loading");
      }, 0);
    } else {
      Swal.fire({
        title: "Erro",
        icon: "error",
        text: "Erro ao tentar habilitar as notificações, atualize a página e tente novamente"
      })
    }
  };

  useEffect(() => {
    function eventListener(event) {
      if(event === true){
        habilitarDesabilitar(true);
      }
    }

    const init = async () => {

      let promises: Promise<any>[] = [NotificacoesApi().getNotificacoes()];
      if (OneSignal.User.PushSubscription.id) {
        promises.push(NotificacoesApi().isHabilitado(OneSignal.User.PushSubscription.id));
      }
  
      const [notificacoes, habilitado] = await Promise.all(promises);
      setNotificacoes(notificacoes);
      setNotificacoesHabilitadas(habilitado ? habilitado : false);
  
      setLoading(false);
      setLoadingHabilitar(false);
      
      OneSignal.Notifications.addEventListener("permissionChange", eventListener);
    };
    
    init();

    return () => {
      OneSignal.Notifications.removeEventListener("permissionChange", eventListener);
    }
  }, []);
  
  const onChangeNotificacoesHabilitadas = async (newValue: boolean) => {
    let denied = OneSignal.Notifications.permissionNative === "denied";
    let permission = OneSignal.Notifications.permission;

    if(newValue){
      if(isSafariIos && !isPwa){
        Swal.fire({
          title: "Ops, parece que você está em um dispositivo iOS",
          icon: "warning",
          text: "Para habilitar as notificações, você precisa adicionar o Conecta Venda à tela inicial do seu dispositivo, confira o tutorial clicando no botão abaixo",
          confirmButtonText: "Tutorial",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if(result.isConfirmed){
            window.open("https://painel-api.conectavenda.com.br/pdfs/Ativa%C3%A7%C3%A3oNotifica%C3%A7%C3%B5es-iOs.pdf", "_blank");
          }
        });

        return;
      }

      if(denied){
        Swal.fire({
          title: "Você precisa habilitar as notificações do navegador",
          icon: "warning",
          text: "Clique no botão ao lado da barra de endereço e permita as notificações",
          confirmButtonText: "Ok",
        })
      } else if (!permission){
        OneSignal.Slidedown.promptPush({
          force: true,
        });
      } else {
        habilitarDesabilitar(true);
      }
    } else {
      habilitarDesabilitar(false);
    }
  }

  return (
    <NotificacoesContainer theme={theme}>
      <>
        {loading ? (
          <div className="loading">
              <Player
              autoplay
              loop
              src={animation}
              style={{ height: "80px", width: "80px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          </div>
        ) : (
          <>
            <div className="titulo">
              <span>Notificações</span>
              <label className="toggle-notificacoes">
                Habilitar
                <Toggle
                  onChange={onChangeNotificacoesHabilitadas}
                  value={notificacoesHabilitadas}
                  disabled={loadingHabilitar}
                />
              </label>
            </div>
            <div className="notificacoes scrollBonito">
              {(!notificacoes || notificacoes.length === 0) && (
                <div className="sem-notificacoes">Sem notificações</div>
              )}
              {notificacoes &&
                Object.keys(notificacoes).map((data) => (
                  <div key={data}>
                    <div className="data">{data}</div>
                    <div className="textos">
                      {notificacoes[data].map((notification, index) => (
                        <div className="texto">
                          {
                            notification.link ? (
                              <Link onClick={onClose} to={notification.link} key={index}>
                                <div className="notificacao-container">
                                  <div className="notificacao-hora">{notification.hora}</div>
                                  <div className="conteudo-wrapper">
                                    <div className="notificacao-titulo">{notification.titulo}</div>
                                    <div className="notificacao-texto">{notification.mensagem}</div>
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              <div className="notificacao-container" key={index}>
                                <div className="notificacao-hora">{notification.hora}</div>
                                <div className="conteudo-wrapper">
                                  <div className="notificacao-titulo">{notification.titulo}</div>
                                  <div className="notificacao-texto">{notification.mensagem}</div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </>
    </NotificacoesContainer>
  );
};

export default Notificacoes;
