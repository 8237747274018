import Swal from "sweetalert2";
import "./styles.scss";
import { useRef, useState } from "react";
import { useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { Button } from "../Button";
import React from "react";
import { FileReaderResult, PromiseFileReader } from "../../services/Functions";

export interface Produto {
  id_produto: string;
  referencia: string;
  pasta: string;
}
export interface Image {
  file: File;
  base64: string;
  imagem: string;
  size: number;
  status: "erro" | "sucesso" | "carregando" | "advertencia";
  uploadStatus?: "erro" | "sucesso" | "carregando";
  produtos?: Produto[];
  error?:
    | "FORMATO_INVALIDO"
    | "IMAGEM_MUITO_GRANDE"
    | "TAMANHO_INVALIDO"
    | "PROPORCAO_INVALIDA"
    | "PODE_CROPAR"
    | "ERROR_FILE_READER"
    | "ERRO_IMPORTACAO"
    | "NENHUM_PRODUTO_ATRELADO";
  extension: string;
  progress?: number;
}
interface MultipleImageUploadProps {
  images?: Image[];
  acceptFormats: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  aspectRatio?: number;
  maxHeight?: number;
  onChange: (newImages: Image[]) => void;
  className?: string;
  style?: React.CSSProperties;
  onDragIn?: (e) => void;
  onDragOut?: (e) => void;
  onDragOver?: (e) => void;
  onDragDrop?: (e) => void;
  maxBytes?: number;
  children?: React.ReactNode;
  onLoading: (loading: boolean) => void;
  errorMargin?: number; //margem para ja mandar a imagem sem cropar caso esteja no tamanho ideal
}

export const MultipleImageUpload: React.FC<MultipleImageUploadProps> = ({
  images,
  acceptFormats,
  onChange,
  minWidth,
  minHeight,
  maxWidth,
  maxBytes,
  maxHeight,
  onDragIn,
  onDragOut,
  onDragOver,
  onDragDrop,
  className,
  errorMargin = 0,
  style,
  aspectRatio,
  children,
  onLoading,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLLabelElement>,
  ): void => {
    onLoading(true);

    let files: FileList | null = null;

    if ((event as React.DragEvent<HTMLLabelElement>).dataTransfer) {
      files = (event as React.DragEvent<HTMLLabelElement>).dataTransfer.files;
    } else if ((event as React.ChangeEvent<HTMLInputElement>).target) {
      files = (event as React.ChangeEvent<HTMLInputElement>).target.files;
    }

    let newImages: Image[] = [];
    let imagesPromises: {
      promise: Promise<any>;
      originalImage: Image;
    }[] = [];

    if (files) {
      for (let i = 0; i < files.length; i++) {
        let image: Image = {
          file: files.item(i)!,
          base64: "",
          imagem: files.item(i)!.name,
          size: 0,
          status: "carregando",
          extension: "",
        };
        //checar se ja existe uma imagem com o mesmo nome
        if (images) {
          const imageAlreadyExists = images.some(
            (imagem) => imagem.imagem === image.imagem,
          );
          if (imageAlreadyExists) {
            continue;
          }
        }

        if (!isValid(image)) {
          newImages.push(image);
        } else {
          imagesPromises.push({
            promise: PromiseFileReader(image.file, {
              maxWidth: maxWidth,
              maxHeight: maxHeight,
              minWidth: minWidth,
              minHeight: minHeight,
              errorMargin: errorMargin,
            }).then((result) => ({ ...result })),
            originalImage: image,
          });
        }
      }

      Promise.allSettled(imagesPromises.map((p) => p.promise))
        .then((results) => {
          results.forEach((result, index) => {
            const originalImage = imagesPromises[index].originalImage;
            if (result.status === "fulfilled") {
              const resultData = result.value as FileReaderResult;
              if (resultData.consideration === "TAMANHO_IDEAL") {
                newImages.push({
                  ...originalImage,
                  base64: resultData.base64,
                  imagem: resultData.imagem,
                  size: resultData.size,
                  status: "sucesso",
                });
              } else if (resultData.consideration === "PODE_CROPAR") {
                newImages.push({
                  ...originalImage,
                  base64: resultData.base64,
                  imagem: resultData.imagem,
                  size: resultData.size,
                  status: "advertencia",
                  error: "PODE_CROPAR",
                });
              } else if (resultData.consideration === "PROPORCAO_INVALIDA") {
                newImages.push({
                  ...originalImage,
                  base64: resultData.base64,
                  imagem: resultData.imagem,
                  size: resultData.size,
                  status: "advertencia",
                  error: "PROPORCAO_INVALIDA",
                });
              } else if (resultData.consideration === "TAMANHO_INVALIDO") {
                newImages.push({
                  ...originalImage,
                  base64: resultData.base64,
                  imagem: resultData.imagem,
                  size: resultData.size,
                  status: "erro",
                  error: "TAMANHO_INVALIDO",
                });
              } else {
                newImages.push({
                  ...originalImage,
                  base64: resultData.base64,
                  imagem: resultData.imagem,
                  size: resultData.size,
                  status: "sucesso",
                });
              }
            } else {
              newImages.push({
                ...originalImage,
                status: "erro",
                error: result.reason,
              });
            }
          });
          onLoading(false);
        })
        .finally(() => {
          onChange(newImages);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Ocorreu um erro ao upar as imagens!`,
      });
      onLoading(false);
    }
    if ((event as React.ChangeEvent<HTMLInputElement>).target) {
      (event as React.ChangeEvent<HTMLInputElement>).target.value = "";
    }
  };

  const isValid = (image: Image) => {
    const formats = acceptFormats.split(",").map((format) => format.trim());
    const extension =
      image.file && (image.file.name.split(".").pop() as string).toLocaleLowerCase();

    image.extension = extension === "jpg" ? "jpeg" : extension;
    if (image.file && !formats.includes("." + image.extension)) {
      image.status = "erro";
      image.error = "FORMATO_INVALIDO";
      return false;
    } else if (image.file && maxBytes && image.file.size > maxBytes) {
      image.status = "erro";
      image.error = "IMAGEM_MUITO_GRANDE";
      return false;
    }
    return true;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDragOver && onDragOver(e);
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDragIn && onDragIn(e);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDragOut && onDragOut(e);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onDragDrop && onDragDrop(e);

    handleChange(e);
  };

  return (
    <>
      <label
        className={className}
        style={style}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {children}
        <input
          ref={inputFileRef}
          style={{ display: "none" }}
          type="file"
          onChange={handleChange}
          multiple={true}
          accept={acceptFormats}
        ></input>
      </label>
    </>
  );
};
