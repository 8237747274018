import React from "react";
import { Button as StyledButton } from "./styles";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import src from "../../assets/jsons/Loading.json";
import { useTheme } from "../../contexts/Theme/ThemeContext";

interface ButtonProps {
  title: string;
  className?: string;
  onClick?: (event) => void;
  type?: "button" | "submit" | "reset";
  width?: string;
  padding?: string;
  disabled?: boolean;
  fontSize?: string;
  loading?: boolean;
}

export const Button = ({
  title,
  onClick,
  type = "button",
  width = "",
  padding = "7rem",
  disabled = false,
  fontSize = "2.5rem",
  className,
  loading = false,
}: ButtonProps) => {
  const { theme } = useTheme();
  return (
    <StyledButton
      onClick={(e) => {
        if(loading) {
          e.preventDefault()
          e.stopPropagation()
          return
        } else {
          onClick && onClick(e)
        }
      }}
      type={type}
      style={{
        width: width,
        paddingLeft: padding,
        paddingRight: padding,
        opacity: `${disabled ? "0.7" : "1"}`,
        fontSize: fontSize,
        cursor: loading ? "not-allowed" : "pointer",
      }}
      theme={theme}
      disabled={disabled}
      className={className}
    >
      <div style={{ visibility: loading ? "hidden" : "visible"}}>{title}</div>
      {loading && (
        <div className="loading">
          <Player
            autoplay
            loop
            src={src}
            style={{
              height: "3rem",
              width: "3rem",
              position: "static",
              right: "1rem",
              top: "0",
              bottom: "0",
              margin: "auto",
            }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
      )}
    </StyledButton>
  );
};
