import React, { useEffect } from "react";
import "./styles";
import { CodigoRastreioContainer, ModalContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Input } from "../../Input";
import { PedidosApi } from "../../../hooks/pedidosApi";
import LoadingSRC from "../../../assets/jsons/Loading.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Modal from "../../Modal/Modal";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Botoes } from "../../../pages/Produtos/Produto/cadastrosBox/listasDePreco/styles";
import { Button } from "../../Button";
import Form from "../../Form";
import { max } from "moment";

interface CodigoRastreioProps {
  codigo: any;
  id_pedido: string | number;
  disabled?: boolean;
}

const CodigoRastreio: React.FC<CodigoRastreioProps> = ({
  codigo,
  id_pedido,
  disabled = false,
}) => {
  const { theme } = useTheme();
  const animation = LoadingSRC;

  const pedidos = PedidosApi();

  const [codigoRastreio, setCodigoRastreio] = React.useState<string>(codigo);
  const [codigoRastreioAux, setCodigoRastreioAux] = React.useState<string>("");
  const [formValid, setFormValid] = React.useState<boolean>(false);

  const [modalCodigoRastreio, setModalCodigoRastreio] =
    React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const adicionarCodigoRastreio = () => {
    if(disabled) return;
    setModalCodigoRastreio(true);
  };

  const aplicarCodigoRastreio = async () => {
    setLoading(true);

    const newCodigoRastreio = codigoRastreioAux;
    setCodigoRastreio(newCodigoRastreio);
    setModalCodigoRastreio(false);

    try {
      await pedidos.atualizarCodigoDeRastreio(id_pedido, newCodigoRastreio);
      Swal.fire({
        icon: "success",
        title: "Código de rastreio adicionado com sucesso!",
        showCancelButton: false,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro ao adicionar código de rastreio!",
        showCancelButton: false,
      });
    }

    setLoading(false);
  };

  const copiarCodigo = () => {
    try {
      navigator.clipboard.writeText(codigoRastreio);
      Swal.fire({
        icon: "success",
        title: "Código copiado com sucesso",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro ao copiar código!",
        text:
          "Mas você ainda pode tentar copiar selecionando: " + codigoRastreio,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    setCodigoRastreio(codigo);
  }, [codigo]);

  return (
    <>
      <Modal
        isOpen={modalCodigoRastreio}
        setModal={setModalCodigoRastreio}
        width={"98vw"}
        maxWidth="75rem"
        onUseEffect={() => setCodigoRastreioAux(codigoRastreio)}
      >
        <ModalContainer theme={theme}>
          <Titulo
            titulo={`${
              codigoRastreio ? "Editar" : "Adicionar"
            } código de rastreio`}
            subtitulo=""
            justifyContent="center"
          />
          <Form
            id="formCodigoRastreio"
            onSubmit={aplicarCodigoRastreio}
            setFormValid={setFormValid}
          >
            <Input
              value={codigoRastreioAux}
              onChange={(newValue) => setCodigoRastreioAux(newValue)}
              maxLength={45}
              showMaxLength={false}
              placeholder="Código de rastreio"
            />
            <div className="botoes">
              <div
                className="cancelar"
                onClick={() => {
                  setCodigoRastreioAux(codigoRastreio);
                  setModalCodigoRastreio(false);
                }}
              >
                Cancelar
              </div>
              <Button title="Aplicar" onClick={aplicarCodigoRastreio} />
            </div>
          </Form>
        </ModalContainer>
      </Modal>
      <CodigoRastreioContainer theme={theme}>
        {loading ? (
          <>
            <Player
              autoplay
              loop
              src={animation}
              style={{ height: "3rem", width: "3rem" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          </>
        ) : (
          <>
            <div className="adicionar" onClick={adicionarCodigoRastreio}>
              {codigoRastreio ? <>{codigoRastreio}</> : <>Adicionar</>}
            </div>
          </>
        )}
      </CodigoRastreioContainer>
    </>
  );
};

export default CodigoRastreio;
