import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const CodigoRastreioContainer = styled.div<{theme: ThemeType}>`
    .adicionar{
        color: ${({theme}) => theme.colors.brandPrimary};
        cursor: pointer;
    }
`;

const ModalContainer = styled.div<{theme: ThemeType}>`
    padding: 3rem;
    .botoes{
        display: flex;
        justify-content: space-between;
        padding: 0 3rem;
        align-items: center;
        margin-top: 5rem;
        .cancelar{
            cursor: pointer;
            font-size: 2.5rem;
        }
    }
`;

export { 
    CodigoRastreioContainer,
    ModalContainer
};