import { createContext } from "react";
import { Arquivo, Campo, Dado, EtapaImportacao, SeparatorTypes } from "../../types/ImportacaoPlanilha";

export type ProdutosPlanilhaContextType = {
  etapa: EtapaImportacao;
  setEtapa: (etapa: EtapaImportacao) => void;
  camposSelect: { name: string; value: string }[];
  arquivo: Arquivo;
  setArquivo: (arquivo: Arquivo) => void;
  colunas: string[];
  mappedCampos: Array<Campo | undefined>;
  setColunas: (colunas: string[]) => void;
  dados: string[][];
  separator: SeparatorTypes | "";
  setSeparator: (separator: SeparatorTypes) => void;
  setDados: (dados: string[][]) => void;
  onLoadArquivo: () => void;
  qtdCamposObrigatoriosFaltantes: number;
  qtdDadosInvalidos: number;
  qtdDadosValidos: number;
  qtdColunasInvalidas: number;
  dadosValidados: Dado[][];
  campos: Campo[];
  tabelaSelecionada: string;
  setTabelaSelecionada: (tabela: string) => void;
  removerProdutos: boolean;
  setRemoverProdutos: (remover: boolean) => void;
  slidingActive: boolean;
  setSlidingActive: (active: boolean) => void;
};

export const ProdutosPlanilhaContext = createContext<ProdutosPlanilhaContextType>(null!);
