import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ProdutosPlanilhaContext } from '../../../contexts/ProdutosPlanilha/ProdutosPlanilhaContext';
import { useTheme } from '../../../contexts/Theme/ThemeContext';
import { produtosApi } from '../../../hooks/produtosApi';
import { Button } from '../../Button';
import { FooterPaginas } from '../../FooterPaginas/FooterPaginas';
import Form from '../../Form';
import LoadingComFundo from '../../Loading/LoadingComFundo/LoadingComFundo';
import ImportarArquivo from '../ImportarArquivo/ImportarArquivo';
import InformacoesArquivo from '../InformacoesArquivo/InformacoesArquivo';
import TabelaDePreco from '../TabelaDePreco/TabelaDePreco';
import ConfiguracoesAvancadas from './ConfigsAvancadas/ConfigsAvancadas';
import './styles';
import { ImportacaoMainContainer } from './styles';

interface ImportacaoMainProps {
}

const ImportacaoMain: React.FC<ImportacaoMainProps> = ({}) => {
    const {etapa, onLoadArquivo, slidingActive, setSlidingActive, dadosValidados, mappedCampos, tabelaSelecionada, removerProdutos, qtdDadosValidos} = useContext(ProdutosPlanilhaContext);

    const {} = useContext(ProdutosPlanilhaContext);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);
    const {theme} = useTheme();
    const slider = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();
    const api = produtosApi();

    const baixarModeloCsv = () => {
        api.baixarModeloCsv().then(
            (response) => {},
            (error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ocorreu um erro inesperado, atualize a página e tente baixar novamente!",
                });
            },
        );
    };

    const iniciarImportacao = async () => {
        if(!qtdDadosValidos) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não há produtos válidos para importar!",
            });
            return;
        }
        Swal.fire({
            title: "Importando produtos",
            text: `Deseja importar os ${qtdDadosValidos} produtos? (colunas e linhas inválidas serão descartadas)`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                //filtrando apenas os dados validos e removendo inclusive as colunas que não foram mapeadas
                let validDados = dadosValidados.filter((linha) => linha.every(coluna => coluna.valid))
        
                let produtos = validDados.map((linha) => {
                    let produto = {};
                    linha.forEach((coluna, index) => {
                        if(mappedCampos[index] !== undefined) {
                            produto[mappedCampos[index].value] = coluna.value;
                        }
                    });
                    return produto;
                });
        
                try {
                    await api.importarProdutosEmMassa(produtos, tabelaSelecionada, removerProdutos);
                    let result = await Swal.fire({
                        icon: "success",
                        title: "Sucesso!",
                        text: "Produtos importados com sucesso! Gostaria de baixar a planilha atualizada?",
                        showCancelButton: true,
                        confirmButtonText: "Baixar planilha",	
                        cancelButtonText: "Não",
                    })
                    if(result.isConfirmed) {
                        let header = Object.keys(produtos[0]);
                        header = header.map((coluna) => mappedCampos.find((campo) => campo?.value === coluna)?.label) as string[];
                        let csv = header.join(";") + "\n";
                        csv += produtos.map((produto) => Object.values(produto).join(";")).join("\n");

                        console.log(csv)

                        //o \ufeff é um BOM (Byte Order Mark) que é necessário para que o excel reconheça a codificação do arquivo (pra que o utf-8 funcione corretamente)
                        const blob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=UTF-8' });

                        console.log(blob)

                        const url = URL.createObjectURL(blob);

                        const link = document.createElement("a");
                        link.href = url;
                        link.download = "produtos-importados.csv";
                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                    }
                    navigate("/painel/produtos");

                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ocorreu um erro inesperado, atualize a página e tente importar novamente!",
                    });
                }
                setLoading(false);
            }
        });


    }

    if(loading) return <LoadingComFundo mensagem="Importando produtos!" subMensagem='Isso pode demorar alguns instantes'  position="fixed" />

    return (
        <ImportacaoMainContainer theme={theme}>
            <>
                <Form
                    className="form-tabela-de-preco"
                    id="form-tabela-de-preco"
                    setFormValid={setFormValid}
                    onSubmit={() => {
                        if(slidingActive) iniciarImportacao();
                        else {
                            onLoadArquivo();
                            setSlidingActive(true);
                            slider.current?.scrollIntoView({behavior: "smooth"});
                        }
                    }}
                >
                    <ImportarArquivo />
                    <div className="grid-container">
                        <TabelaDePreco/>
                        <div className="sliding-wrapper" ref={slider}>
                            <div className={`sliding-container ${slidingActive && "sliding-active"}`}>
                                <InformacoesArquivo/>
                            </div>
                        </div>
                    </div>
                    <FooterPaginas>
                        <div className="footer">
                            <Button 
                                title="Baixar modelo .csv"
                                padding="5rem"
                                onClick={baixarModeloCsv} 
                                className="baixar-csv" 
                            />
                            <div className="botoes-container">
                                <div className='modalConfigAvancada'>
                                    <Button
                                        title="Configurações avançadas"
                                        padding="5rem"
                                        className='remover-produtos'
                                        onClick={() => { 
                                            setIsModalOpen (true);
                                        }}
                                    />
                                    {isModalOpen && (
                                      <div className="modal-overlay">
                                          <ConfiguracoesAvancadas 
                                          onClose={() => setIsModalOpen(false)} 
                                          />
                                      </div>
                                      )}
                                </div>
                                <Button
                                    title="Carregar imagens"
                                    padding="5rem"
                                    onClick={() => navigate("/painel/produtos/importar-imagens")}
                                />
                                <Button
                                    type="submit"
                                    className={`${!formValid && "disabled"} button-avancar`}
                                    title={`${!slidingActive ? "Salvar e continuar" : "Importar"}`}
                                />
                            </div>
                        </div>
                    </FooterPaginas>
                </Form>
            </>
        </ImportacaoMainContainer>
    )
}

export default ImportacaoMain;