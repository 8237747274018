import React, { useContext, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { phoneMask } from "../../../services/Functions";
import { instagramMask } from "../../../services/Masks";
import { validateHex, validatePhone } from "../../../services/MasksValidations";
import { unmaskInstagram, unmaskToNumbers } from "../../../services/Unmasks";
import ColorPicker from "../../ColorPicker/ColorPicker";
import { Input } from "../../Input";
import { MockupCatalogo } from "../../Mockups/MockupCatalogo/MockupCatalogo";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import Quill from "../../Quill/Quill";
import { Select } from "../../Select";
import ImageUploadLogo from "../../Upload/ImageUploadLogo/ImageUploadLogo";
import { InformacoesDaMarcaContainer } from "./styles";
import IntlTelInput from "intl-tel-input/react";
import "intl-tel-input/styles";
import NumeroInternacionalContainer from "../../IntlTelInput/styles";
import NumeroInternacional from "../../IntlTelInput/intl-tel-input";

interface InformacoesDaMarcaProps {
  configIn: "empresa" | "configuracoes";
}

const InformacoesDaMarca: React.FC<InformacoesDaMarcaProps> = ({
  configIn,
}) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const [quillLength, setQuillLength] = useState(0);

  const [cor_primariaAux, setCor_primariaAux] = useState(
    configuracoes.catalogo.cor_primaria
  );
  const [cor_secundariaAux, setCor_secundariaAux] = useState(
    configuracoes.catalogo.cor_secundaria
  );

  const [opcoesRamosDeAtividades, setOpcoesRamosDeAtividades] = useState([
    { value: "Acessórios e joias", name: "Acessórios e joias" },
    { value: "Alimentos e bebidas", name: "Alimentos e bebidas" },
    { value: "Arte e artesanato", name: "Arte e artesanato" },
    { value: "Beleza e cuidados pessoais", name: "Beleza e cuidados pessoais" },
    { value: "Brinquedos e jogos", name: "Brinquedos e jogos" },
    { value: "Material de limpeza", name: "Material de limpeza" },
    { value: "Moda e vestuário", name: "Moda e vestuário" },
    { value: "Móveis e decoração", name: "Móveis e decoração" },
    { value: "Papelaria e escritório", name: "Papelaria e escritório" },
    { value: "Pet shop", name: "Pet shop" },
    { value: "Saúde e suplementos", name: "Saúde e suplementos" },
    {
      value: "Utensílios e objetos em geral",
      name: "Utensílios e objetos em geral",
    },
    { value: "Outro", name: "Outro" },
  ]);

  const handleImageUpload = (base64: string, imagem: string, size: number) => {
    setConfiguracoes({
      ...configuracoes,
      dados_empresa: {
        ...configuracoes.dados_empresa,
        informacoes_gerais: {
          ...configuracoes.dados_empresa.informacoes_gerais,
          logotipo: {
            base64: base64,
            imagem: imagem,
            size: size,
          },
        },
      },
    });
  };

  const handleInformacoesGeraisChange = (newValue: string, name?: string) => {
    setConfiguracoes({
      ...configuracoes,
      dados_empresa: {
        ...configuracoes.dados_empresa,
        informacoes_gerais: {
          ...configuracoes.dados_empresa.informacoes_gerais,
          [name!]: newValue,
        },
      },
    });
  };

  const handleRedesSociaisChange = (newValue: string, name?: string) => {
    setConfiguracoes({
      ...configuracoes,
      dados_empresa: {
        ...configuracoes.dados_empresa,
        redes_sociais: {
          ...configuracoes.dados_empresa.redes_sociais,
          [name!]: newValue,
        },
      },
    });
  };

  const handleColorChange = (cor: string, numero: number) => {
    const newCatalogo = {
      ...configuracoes.catalogo,
      cor_primaria: numero === 1 ? cor : configuracoes.catalogo.cor_primaria,
      cor_secundaria:
        numero === 2 ? cor : configuracoes.catalogo.cor_secundaria,
    };

    const isDisabled =
      cor.toUpperCase() === "#FFFFFF" ||
      validateHex(newCatalogo.cor_primaria) === false ||
      validateHex(newCatalogo.cor_secundaria) === false ||
      newCatalogo.cor_primaria === newCatalogo.cor_secundaria;

    setConfiguracoes({ ...configuracoes, catalogo: newCatalogo });
    setDisabled(isDisabled);
  };

  const [colorPicker1, setColorPicker1] = useState(false);
  const [colorPicker2, setColorPicker2] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { theme } = useTheme();

  return (
    <>
      <InformacoesDaMarcaContainer configIn={configIn} theme={theme}>
        <div className="InformacoesDaMarca">
          <div className="informacoes">
            <Titulo
              titulo="Informações da marca"
              subtitulo="Sua empresa"
              justifyContent="flex-start"
            />
            <div className="inputs-container">
              <div className="input-container">
                <Input
                  required={true}
                  onChange={handleInformacoesGeraisChange}
                  type={"text"}
                  name="nome"
                  value={configuracoes.dados_empresa.informacoes_gerais.nome}
                  placeholder={"Nome da Marca"}
                  error={""}
                  maxLength={25}
                  debounceTime={1000}
                  showMaxLength={false}
                ></Input>
              </div>
              <div className="select-container">
                <Select
                  placeholder="Ramo de Atividade"
                  options={opcoesRamosDeAtividades}
                  value={
                    configuracoes.dados_empresa.informacoes_gerais
                      .ramo_atividade
                  }
                  onChange={handleInformacoesGeraisChange}
                  name={"ramo_atividade"}
                  required={true}
                />
              </div>
              <div className="input-container">
                <Quill 
                  className="quill-editor-2"
                  formats={["bold", "italic", "underline", "list"]}
                  placeholder={"Descrição"}
                  required={false}
                  maxLength={200}
                  value={configuracoes.dados_empresa.informacoes_gerais.descricao}
                  onChange={(newContent) => {
                    setConfiguracoes({
                      ...configuracoes,
                      dados_empresa: {
                        ...configuracoes.dados_empresa,
                        informacoes_gerais: {
                          ...configuracoes.dados_empresa.informacoes_gerais,
                          descricao: newContent,
                        },
                      },
                    });
                  }}
                />
              </div>
              <div className="input-container-nacional" style={{width: "100%",}}>
                <NumeroInternacional
                  type={"tel"}
                  required={true}
                  name={"tel"}
                  value={configuracoes.dados_empresa.redes_sociais.whatsapp}
                 setConfiguracoes={setConfiguracoes}
                />
              </div>
              {/* <div className="input-container">
                <Input
                  required={true}
                  onChange={handleRedesSociaisChange}
                  invalidText="Preencha um número de telefone válido"
                  type={"tel"}
                  name="whatsapp"
                  value={configuracoes.dados_empresa.redes_sociais.whatsapp}
                  placeholder={"WhatsApp"}
                  error={""}
                  mask={{
                    mask: phoneMask,
                    maskValidation: validatePhone,
                    unmask: unmaskToNumbers,
                  }}
                ></Input>
              </div> */}
              <div className="input-container">
                <Input
                  required={true}
                  onChange={handleRedesSociaisChange}
                  type={"text"}
                  name="instagram"
                  mask={{
                    mask: instagramMask,
                    unmask: unmaskInstagram,
                  }}
                  value={configuracoes.dados_empresa.redes_sociais.instagram}
                  placeholder={"@Instagram"}
                  error={""}
                  maxLength={30}
                  showMaxLength={false}
                ></Input>
              </div>
              <div className="input-container">
                <div className="image-upload">
                  <ImageUploadLogo
                    configIn={configIn}
                    value={
                      configuracoes.dados_empresa.informacoes_gerais.logotipo[
                        "base64"
                      ]
                        ? configuracoes.dados_empresa.informacoes_gerais
                            .logotipo["base64"]
                        : configuracoes.dados_empresa.informacoes_gerais
                            .logotipo
                    }
                    onChange={(base64, imagem, size) =>
                      handleImageUpload(base64, imagem, size)
                    }
                  ></ImageUploadLogo>
                </div>
                <div className="colors-container">
                  <div className="containerBranco">
                    <div
                      className="bolinha"
                      onClick={() => {
                        setColorPicker1(true);
                      }}
                      style={{
                        backgroundColor: configuracoes.catalogo.cor_primaria,
                        borderColor:
                          configuracoes.catalogo.cor_primaria.toUpperCase() ==
                          "#FFFFFF"
                            ? "#A7A7A7"
                            : configuracoes.catalogo.cor_primaria,
                      }}
                    />
                  </div>
                  <div className="containerBranco">
                    <div
                      className="bolinha"
                      onClick={() => {
                        setColorPicker2(true);
                      }}
                      style={{
                        backgroundColor: configuracoes.catalogo.cor_secundaria,
                        borderColor:
                          configuracoes.catalogo.cor_secundaria.toUpperCase() ==
                          "#FFFFFF"
                            ? "#A7A7A7"
                            : configuracoes.catalogo.cor_secundaria,
                      }}
                    />
                  </div>
                  {colorPicker1 && (
                    <ModalRelativo
                      normalModal={configIn === "configuracoes" ? true : false}
                      width="40rem"
                      cancelar={true}
                      background="none"
                      isOpen={colorPicker1}
                      setIsOpen={setColorPicker1}
                      botaoConfirmar="Salvar"
                      titulo={"Cor primária"}
                      subtitulo={"Menu, botões e ícones"}
                      top="20rem"
                      left="30rem"
                      disabled={disabled}
                      onUseEffect={() => {
                        setCor_primariaAux(configuracoes.catalogo.cor_primaria);
                      }}
                      onCancel={() => {
                        setConfiguracoes({
                          ...configuracoes,
                          catalogo: {
                            ...configuracoes.catalogo,
                            cor_primaria: cor_primariaAux,
                          },
                        });
                      }}
                      disabledMessage={
                        validateHex(configuracoes.catalogo.cor_primaria)
                          ? "Não há contraste suficiente"
                          : "Preencha um código hexadecimal válido"
                      }
                    >
                      <ColorPicker
                        color={configuracoes.catalogo.cor_primaria}
                        onChange={(cor: any) => {
                          handleColorChange(cor.hex, 1);
                        }}
                        onChangeInput={(cor: string) => {
                          handleColorChange(cor, 1);
                        }}
                      />
                    </ModalRelativo>
                  )}
                  {colorPicker2 && (
                    <ModalRelativo
                      normalModal={configIn === "configuracoes" ? true : false}
                      width="40rem"
                      cancelar={true}
                      background="none"
                      isOpen={colorPicker2}
                      setIsOpen={setColorPicker2}
                      botaoConfirmar="Salvar"
                      titulo={"Cor secundária"}
                      subtitulo={"Textos e ícones"}
                      top="20rem"
                      left="30rem"
                      disabled={disabled}
                      onUseEffect={() => {
                        setCor_secundariaAux(
                          configuracoes.catalogo.cor_secundaria
                        );
                      }}
                      onCancel={() => {
                        setConfiguracoes({
                          ...configuracoes,
                          catalogo: {
                            ...configuracoes.catalogo,
                            cor_secundaria: cor_secundariaAux,
                          },
                        });
                      }}
                      disabledMessage={
                        validateHex(configuracoes.catalogo.cor_secundaria)
                          ? "Não há contraste suficiente"
                          : "Preencha um código hexadecimal válido"
                      }
                    >
                      <ColorPicker
                        color={configuracoes.catalogo.cor_secundaria}
                        onChange={(cor: any) => {
                          handleColorChange(cor.hex, 2);
                        }}
                        onChangeInput={(cor: string) => {
                          handleColorChange(cor, 2);
                        }}
                      />
                    </ModalRelativo>
                  )}
                  Editar Cores
                </div>
              </div>
            </div>
          </div>
          <div className="mockup-container">
            <MockupCatalogo
              menu={true}
              banner={false}
              configuracoes={configuracoes}
            />
          </div>
        </div>
      </InformacoesDaMarcaContainer>
    </>
  );
};
export default InformacoesDaMarca;
