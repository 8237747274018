import React, { useContext, useState } from "react";
import "./styles";
import { PagamentoCombinarContainer } from "./styles";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import { deepCopy } from "../../../services/Functions";
import { Input } from "../../Input";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Dropdown from "../../Dropdowns/Dropdown";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { CatalogoContext } from "../../../contexts/Catalogo/CatalogoContext";
import Configuracoes from "../../../types/Configuracoes";

interface PagamentoCombinarProps {
  configIn: "empresa" | "configuracoes" | "catalogo";
}

const PagamentoCombinar: React.FC<PagamentoCombinarProps> = ({ configIn }) => {
  const configsEmpresa = useContext(ConfiguracoesContext);
  const configsCatalogo = useContext(CatalogoContext);

  const { theme } = useTheme();

  const configuracoes =
    configIn === "catalogo"
      ? configsCatalogo.catalogo
      : configsEmpresa.configuracoes;
  const setConfiguracoes =
    configIn === "catalogo"
      ? configsCatalogo.setCatalogo
      : configsEmpresa.setConfiguracoes;
  const ignoreValidation =
    configIn === "catalogo"
      ? false
      : !(configuracoes as Configuracoes).pagamentoCombinarAtivo;

  const [modalACombinar, setModalACombinar] = useState(false);

  const [prazoAuxiliar, setPrazoAuxiliar] = useState(
    configuracoes.intermediadores.a_combinar?.prazo_contato || 0,
  );

  return (
    <PagamentoCombinarContainer configIn={configIn} theme={theme}>
      <div className="titulo">Pagamento a combinar</div>
      <div className="containerToggleConfigurar">
        <ToggleConfigurar
          ignoreValidations={ignoreValidation}
          nome={"Contatar o cliente"}
          value={configuracoes.intermediadores.a_combinar?.ativo || false}
          isValid={
            configuracoes.intermediadores.a_combinar?.ativo &&
            !configuracoes.intermediadores.a_combinar.prazo_contato
              ? false
              : true
          }
          invalidText="Configure um prazo para entrar <br/> em contato com o cliente."
          onChange={(newValue) => {
            setConfiguracoes(
              deepCopy(configuracoes, {
                intermediadores: { a_combinar: { ativo: newValue } },
              }),
            );
            if (configIn === "configuracoes") {
              newValue ? setModalACombinar(true) : setModalACombinar(false);
            }
          }}
          onConfigurar={() => {
            setModalACombinar(!modalACombinar);
          }}
        />
      </div>
      {(configIn === "configuracoes" || configIn === "catalogo") && (
        <Dropdown active={modalACombinar}>
          <div className="descricao">
            Prazo para você entrar em contato com o cliente.
          </div>
          <Input
            ignoreValidations={ignoreValidation}
            type={"number"}
            value={
              configuracoes.intermediadores.a_combinar?.prazo_contato &&
              Number.parseInt(
                configuracoes.intermediadores.a_combinar?.prazo_contato,
              ) > 0
                ? configuracoes.intermediadores.a_combinar.prazo_contato.toString()
                : ""
            }
            placeholder={"2"}
            required={true}
            alignLabel="left"
            label="Dias"
            onChange={(newValue) => {
              setConfiguracoes(
                deepCopy(configuracoes, {
                  intermediadores: { a_combinar: { prazo_contato: newValue } },
                }),
              );
            }}
          />
        </Dropdown>
      )}
      {modalACombinar && configIn === "empresa" && (
        <ModalRelativo
          isOpen={modalACombinar}
          setIsOpen={setModalACombinar}
          titulo={"A combinar"}
          subtitulo={"Prazo para você entrar em contato <br/> com o cliente."}
          width="auto"
          center={true}
          disabled={!configuracoes.intermediadores.a_combinar?.prazo_contato}
          disabledMessage="Insira um prazo para continuar."
          botaoConfirmar="Salvar e continuar"
          botaoCancelar="Voltar"
          cancelar={true}
          padding="5rem 10rem"
          onUseEffect={() => {
            setPrazoAuxiliar(
              configuracoes.intermediadores.a_combinar?.prazo_contato || "0",
            );
          }}
          onCancel={() => {
            setConfiguracoes(
              deepCopy(configuracoes, {
                intermediadores: {
                  a_combinar: { prazo_contato: prazoAuxiliar },
                },
              }),
            );
          }}
        >
          <div className="diasContainer">
            <div className="flexContainer">
              <div>Dias</div>
              <Input
                type={"number"}
                value={
                  configuracoes.intermediadores.a_combinar?.prazo_contato &&
                  Number.parseInt(
                    configuracoes.intermediadores.a_combinar?.prazo_contato,
                  ) > 0
                    ? configuracoes.intermediadores.a_combinar.prazo_contato.toString()
                    : ""
                }
                placeholder={"2"}
                required={true}
                alignLabel="center"
                onChange={(newValue) => {
                  setConfiguracoes(
                    deepCopy(configuracoes, {
                      intermediadores: {
                        a_combinar: { prazo_contato: newValue },
                      },
                    }),
                  );
                }}
              />
            </div>
          </div>
        </ModalRelativo>
      )}
    </PagamentoCombinarContainer>
  );
};

export default PagamentoCombinar;
