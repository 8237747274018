import React, { useState, useEffect } from "react";
import "./styles.scss";

interface ScrollContainerXProps {
  speed: number;
  children: React.ReactNode;
}

const ScrollContainerX: React.FC<ScrollContainerXProps> = ({
  speed,
  children,
}) => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [mouseMoved, setMouseMoved] = useState(false);
  const movementThreshold = 5; // you can adjust this value

  const handleMouseLeave = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setIsDown(false);
    e.currentTarget.classList.remove("active");
    e.currentTarget.classList.remove("grab");
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDown(true);
    setStartX(e.clientX - e.currentTarget.offsetLeft);
    setStartY(e.clientY - e.currentTarget.offsetTop);
    setScrollLeft(e.currentTarget.scrollLeft);
    setMouseMoved(false); // reset flag on mousedown
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Only dispatch the click event if the mouse did not move beyond the threshold.
    if (!mouseMoved) {
      e.currentTarget.dispatchEvent(new MouseEvent("click", { bubbles: true })); // dispatch click event if mouse hasn't moved
    }
    setIsDown(false);
    e.currentTarget.classList.remove("active");
    e.currentTarget.classList.add("grab");
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.clientX - e.currentTarget.offsetLeft;
    const y = e.clientY - e.currentTarget.offsetTop;
    const walk = (x - startX) * speed; // scroll-fast
    e.currentTarget.scrollLeft = scrollLeft - walk;
    if (
      Math.abs(x - startX) > movementThreshold ||
      Math.abs(y - startY) > movementThreshold
    ) {
      setMouseMoved(true); // set flag if mouse moved more than the threshold
    }
  };

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.currentTarget.classList.add("grab");
  };

  return (
    <div
      className="ScrollContainerX scrollBonitoBottom grab"
      style={{ overflowX: "scroll", transition: "scroll-left 0.5s ease-out;" }}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
    >
      {children}
    </div>
  );
};

export default ScrollContainerX;
