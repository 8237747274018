import { createContext } from "react";

export type ListasDePrecoContextType = {
  ListasDePreco: any[];
  init: () => void;
};

export const ListasDePrecoContext = createContext<ListasDePrecoContextType>(
  null!,
);
