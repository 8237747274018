import { useContext, useEffect, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import { ContainerPedidosConfigsContainer } from "./styles";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import { Toggle } from "../../Toggle/Toggle";
import { copy, boolean } from "../../../services/Functions";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface PedidoProps {
  configIn: "empresa" | "configuracoes";
}

interface Campo {
  ativo: boolean;
  campo: string;
  nome: string;
  obrigatorio: boolean;
}

export const Pedido: React.FC<PedidoProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const [configuracoesAuxiliar, setConfiguracoesAuxiliar] =
    useState(configuracoes);

  const [modalEndereco, setModalEndereco] = useState(false);
  const [modalPessoaFisica, setModalPessoaFisica] = useState(false);
  const [modalPessoaJuridica, setModalPessoaJuridica] = useState(false);

  const [endereco, setEndereco] = useState(false);
  const [pessoaFisica, setPessoaFisica] = useState(false);
  const [pessoaJuridica, setPessoaJuridica] = useState(false);

  const { theme } = useTheme();

  const [camposEndereco, setCamposEndereco] = useState<Campo[]>([]);
  const [camposLivres, setCamposLivres] = useState<Campo[]>([]);

  useEffect(() => {
    const newCamposEndereco = configuracoes.campos_pedido_geral.filter(
      (campo) =>
        [
          "cep",
          "estado",
          "cidade",
          "endereco",
          "numero",
          "complemento",
          "bairro",
        ].includes(campo.campo.toLowerCase())
    );

    let newCamposLivres = configuracoes.campos_pedido_geral.filter((campo) =>
      ["email", "telefone", "observacao"].includes(campo.campo.toLowerCase())
    );

    newCamposLivres.forEach((item) => {
      if (item.campo === "telefone") {
        item.obrigatorio = true;
        item.ativo = true;
      }
    });

    setCamposEndereco(newCamposEndereco);
    setCamposLivres(newCamposLivres);

    setEndereco(
      newCamposEndereco.some((item) => boolean(item.ativo) === true)
        ? true
        : false
    );
    setPessoaFisica(
      configuracoes.campos_pedido_pessoa_fisica.some(
        (item) => boolean(item.ativo) === true
      )
        ? true
        : false
    );
    setPessoaJuridica(
      configuracoes.campos_pedido_pessoa_juridica.some(
        (item) => boolean(item.ativo) === true
      )
        ? true
        : false
    );
  }, [
    configuracoes.campos_pedido_geral,
    configuracoes.campos_pedido_pessoa_fisica,
    configuracoes.campos_pedido_pessoa_juridica,
  ]);

  return (
    <>
      <ContainerPedidosConfigsContainer configIn={configIn} theme={theme}>
        {configIn === "empresa" && (
          <Titulo titulo="Pedido" subtitulo="Configure seu catálogo"></Titulo>
        )}
        <div className="containerFlex">
          <div className="texto">
            Selecione quais dados perguntar {configIn === "empresa" && <br />}
            no fechamento do pedido
          </div>
          <div className="containerToggles">
            {camposLivres.map((item, index) => {
              return (
                <div className="containerToggle">
                  <ToggleConfigurar
                    disabled={item.obrigatorio}
                    key={index}
                    nome={item.nome}
                    value={item.ativo}
                    onChange={(newValue) => {
                      const copia = copy(configuracoes);
                      copia.campos_pedido_geral[index].ativo = newValue;

                      // Se o campo alterado for 'nome' ou 'email', ajustar o outro campo
                      if (item.campo === "telefone" || item.campo === "email") {
                        const outroCampoIndex =
                          copia.campos_pedido_geral.findIndex(
                            (campo) =>
                              campo.campo ===
                              (item.campo === "telefone" ? "email" : "telefone")
                          );

                        // Se o campo alterado está sendo desabilitado, garantir que o outro esteja habilitado
                        if (!newValue && outroCampoIndex !== -1) {
                          copia.campos_pedido_geral[outroCampoIndex].ativo =
                            true;
                        }
                      }

                      setConfiguracoes(copia);
                    }}
                  />
                </div>
              );
            })}
            {/* endereço */}
            <div className="containerToggle">
              <ToggleConfigurar
                disabled={
                  camposEndereco.some((item) => item.obrigatorio === true)
                    ? true
                    : false
                }
                nome={"Endereço"}
                value={endereco}
                onChange={(newValue) => {
                  setEndereco(newValue);
                  if (!newValue) {
                    const copia = copy(configuracoes);
                    copia.campos_pedido_geral
                      .filter((campo) =>
                        camposEndereco.some(
                          (item) => item.campo === campo.campo
                        )
                      )
                      .map((item, index) => {
                        copia.campos_pedido_geral[index + 3].ativo = newValue;
                      });
                    setConfiguracoes(copia);
                  }
                }}
                onConfigurar={() => {
                  setModalEndereco(true);
                }}
              />
            </div>

            {/* pessoa fisica */}
            <div className="containerToggle">
              <ToggleConfigurar
                disabled={
                  (configuracoes.campos_pedido_pessoa_fisica.filter(
                    (item) => item.obrigatorio === true
                  ).length > 1
                    ? true
                    : false) || pessoaJuridica === false
                }
                nome={"Pessoa física"}
                value={pessoaFisica}
                onChange={(newValue) => {
                  setPessoaFisica(newValue);
                  if (!newValue) {
                    const copia = copy(configuracoes);
                    copia.campos_pedido_pessoa_fisica.map((item, index) => {
                      copia.campos_pedido_pessoa_fisica[index].ativo = newValue;
                    });
                    setConfiguracoes(copia);
                  } else {
                    const copia = copy(configuracoes);
                    copia.campos_pedido_pessoa_fisica[0].ativo = newValue;
                    setConfiguracoes(copia);
                  }
                }}
                onConfigurar={() => {
                  setModalPessoaFisica(true);
                }}
              />
            </div>

            {/* Pesso Juridica */}
            <div className="containerToggle">
              <ToggleConfigurar
                disabled={
                  (configuracoes.campos_pedido_pessoa_juridica.filter(
                    (item) => item.obrigatorio === true
                  ).length > 1
                    ? true
                    : false) || pessoaFisica === false
                }
                nome={"Pessoa jurídica"}
                value={pessoaJuridica}
                onChange={(newValue) => {
                  setPessoaJuridica(newValue);
                  if (!newValue) {
                    const copia = copy(configuracoes);
                    copia.campos_pedido_pessoa_juridica.map((item, index) => {
                      copia.campos_pedido_pessoa_juridica[index].ativo =
                        newValue;
                    });
                    setConfiguracoes(copia);
                  } else {
                    const copia = copy(configuracoes);
                    copia.campos_pedido_pessoa_juridica[0].ativo = newValue;
                    setConfiguracoes(copia);
                  }
                }}
                onConfigurar={() => {
                  setModalPessoaJuridica(true);
                }}
              />
            </div>
          </div>
          {modalEndereco && (
            <ModalRelativo
              normalModal={configIn === "configuracoes" ? true : false}
              width="50rem"
              center={true}
              isOpen={modalEndereco}
              setIsOpen={setModalEndereco}
              titulo={"Endereço"}
              subtitulo={
                "Selecione quais dados perguntar <br/> no fechamento do pedido"
              }
              cancelar={true}
              onUseEffect={() => {
                setConfiguracoesAuxiliar(copy(configuracoes));
              }}
              onCancel={() => {
                setConfiguracoes(copy(configuracoesAuxiliar));
              }}
            >
              <div className="toggles">
                {camposEndereco.map((item, index) => {
                  return (
                    <ToggleConfigurar
                      disabled={item.obrigatorio}
                      key={index}
                      nome={item.nome}
                      value={item.ativo}
                      onChange={(newValue) => {
                        const copia = copy(configuracoes);
                        copia.campos_pedido_geral[index + 3].ativo = newValue;
                        setConfiguracoes(copia);
                      }}
                    />
                  );
                })}
              </div>
            </ModalRelativo>
          )}
          {modalPessoaFisica && (
            <ModalRelativo
              normalModal={configIn === "configuracoes" ? true : false}
              width="50rem"
              center={true}
              isOpen={modalPessoaFisica}
              setIsOpen={setModalPessoaFisica}
              titulo={"Pessoa física"}
              subtitulo={
                "Selecione quais dados perguntar <br/> no fechamento do pedido"
              }
              cancelar={true}
              onUseEffect={() => {
                setConfiguracoesAuxiliar(copy(configuracoes));
              }}
              onCancel={() => {
                setConfiguracoes(copy(configuracoesAuxiliar));
              }}
            >
              <div className="toggles">
                {configuracoes.campos_pedido_pessoa_fisica.map(
                  (item, index) => {
                    return (
                      <ToggleConfigurar
                        disabled={item.obrigatorio}
                        key={index}
                        nome={item.nome}
                        value={item.ativo}
                        onChange={(newValue) => {
                          const copia = copy(configuracoes);
                          copia.campos_pedido_pessoa_fisica[index].ativo =
                            newValue;
                          setConfiguracoes(copia);
                        }}
                      />
                    );
                  }
                )}
              </div>
            </ModalRelativo>
          )}
          {modalPessoaJuridica && (
            <ModalRelativo
              normalModal={configIn === "configuracoes" ? true : false}
              width="50rem"
              center={true}
              isOpen={modalPessoaJuridica}
              setIsOpen={setModalPessoaJuridica}
              titulo={"Pessoa jurídica"}
              subtitulo={
                "Selecione quais dados perguntar <br/> no fechamento do pedido"
              }
              cancelar={true}
              onUseEffect={() => {
                setConfiguracoesAuxiliar(copy(configuracoes));
              }}
              onCancel={() => {
                setConfiguracoes(copy(configuracoesAuxiliar));
              }}
            >
              <div className="toggles">
                {configuracoes.campos_pedido_pessoa_juridica.map(
                  (item, index) => {
                    return (
                      <ToggleConfigurar
                        disabled={item.obrigatorio}
                        key={index}
                        nome={item.nome}
                        value={item.ativo}
                        onChange={(newValue) => {
                          const copia = copy(configuracoes);
                          copia.campos_pedido_pessoa_juridica[index].ativo =
                            newValue;
                          setConfiguracoes(copia);
                        }}
                      />
                    );
                  }
                )}
              </div>
            </ModalRelativo>
          )}
        </div>
      </ContainerPedidosConfigsContainer>
    </>
  );
};
