import React, { useContext, useEffect, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Input } from "../../Input";
import { copy } from "../../../services/Functions";
import { ContainerFreteEspecial } from "./styles";
import { FreteEspecial as FreteEspecialType } from "../../../types/FreteEspecial";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { cepMask, currencyMask } from "../../../services/Masks";
import { validateCep } from "../../../services/MasksValidations";
import { unmaskToNumbers } from "../../../services/Unmasks";

interface FreteEspecialProps {
  index: number;
  onExclusaoFreteEspecial: (newLenght) => void;
  configIn: "empresa" | "configuracoes";
}

const FreteEspecial: React.FC<FreteEspecialProps> = ({
  onExclusaoFreteEspecial,
  index,
  configIn,
}) => {
  const { fretes_especiais, setFretes_especiais, configuracoes } =
    useContext(ConfiguracoesContext);

  function handleInputChange(newValue: string, name?: string): void {
    let newFretesEspeciais = [...fretes_especiais];
    newFretesEspeciais[indexFrete][name!] = newValue;
    setFretes_especiais(newFretesEspeciais);
  }

  const { theme } = useTheme();

  const [indexFrete, setIndexFrete] = useState<number>(index);

  useEffect(() => {
    if (index === -1) {
      const newFretesEspeciais: FreteEspecialType[] = [...fretes_especiais];
      newFretesEspeciais.push({
        descricao: "",
        cep_inicio: "",
        cep_fim: "",
        prazo: "",
        valor: "",
        peso_minimo: "",
        peso_maximo: "",
        ativo: true,
      });
      setFretes_especiais(newFretesEspeciais);
      setIndexFrete(newFretesEspeciais.length - 1);
    }
  }, [index]);

  return (
    <>
      <ContainerFreteEspecial configIn={configIn} theme={theme}>
        {fretes_especiais.length > 0 && fretes_especiais[indexFrete] && (
          <div className="containerFreteEspecial">
            {configIn === "empresa" && (
              <Titulo
                titulo="Frete Especial"
                subtitulo={"Configure os dados do seu frete especial"}
                flexDirection="column-reverse"
              />
            )}
            <Input
              type={""}
              maxLength={70}
              showMaxLength={false}
              value={fretes_especiais[indexFrete].descricao}
              placeholder={"Nome do Frete Especial"}
              onChange={handleInputChange}
              label="Descrição"
              alignLabel="left"
              required={configuracoes.frete.freteEspecialAtivo}
              ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
              name="descricao"
            ></Input>
            <div className="gridInput">
              <Input
                type={""}
                value={fretes_especiais[indexFrete].cep_inicio.toString()}
                placeholder={"00000-000"}
                onChange={handleInputChange}
                label="CEP Início"
                alignLabel="left"
                mask={{
                  mask: cepMask,
                  maskValidation: validateCep,
                  unmask: unmaskToNumbers,
                }}
                required={configuracoes.frete.freteEspecialAtivo}
                ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
                name="cep_inicio"
              ></Input>
              <Input
                type={""}
                value={fretes_especiais[indexFrete].cep_fim.toString()}
                placeholder={"99999-999"}
                mask={{
                  mask: cepMask,
                  maskValidation: validateCep,
                  unmask: unmaskToNumbers,
                }}
                onChange={handleInputChange}
                label="CEP Fim"
                alignLabel="left"
                required={configuracoes.frete.freteEspecialAtivo}
                ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
                name="cep_fim"
              ></Input>
              <Input
                type={"number"}
                value={
                  Number.parseInt(fretes_especiais[indexFrete].prazo) > 0
                    ? fretes_especiais[indexFrete].prazo.toString()
                    : ""
                }
                placeholder={"2 dias"}
                max={999}
                onChange={handleInputChange}
                label="Prazo de Entrega"
                alignLabel="left"
                required={configuracoes.frete.freteEspecialAtivo}
                ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
                name="prazo"
              ></Input>
              <Input
                type={""}
                mask={{
                  mask: currencyMask,
                  unmask: unmaskToNumbers,
                }}
                value={
                  Number.parseFloat(fretes_especiais[indexFrete].valor) >= 0
                    ? fretes_especiais[indexFrete].valor.toString()
                    : ""
                }
                placeholder={"R$ 0,00"}
                name="valor"
                onChange={handleInputChange}
                label="Valor"
                required={false}
                ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
                alignLabel="left"
              ></Input>
              <Input
                type={"number"}
                value={
                  fretes_especiais[indexFrete].peso_minimo || "0"
                }
                placeholder={"0"}
                min={0}
                max={9999999}
                name="peso_minimo"
                required={configuracoes.frete.freteEspecialAtivo}
                ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
                onChange={handleInputChange}
                label="Peso mínimo (g)"
                alignLabel="left"
              ></Input>
              <Input
                type={"number"}
                value={
                  Number.parseFloat(fretes_especiais[indexFrete].peso_maximo) >
                  0
                    ? fretes_especiais[indexFrete].peso_maximo.toString()
                    : ""
                }
                placeholder={"1000"}
                max={9999999}
                required={configuracoes.frete.freteEspecialAtivo}
                ignoreValidations={!configuracoes.frete.freteEspecialAtivo}
                name="peso_maximo"
                onChange={handleInputChange}
                label="Peso máximo (g)"
                alignLabel="left"
              ></Input>
            </div>
            <div className="exclusao">
              <span
                onClick={() => {
                  let newFretes_especiais = [...fretes_especiais];
                  newFretes_especiais.splice(indexFrete, 1);
                  setFretes_especiais(newFretes_especiais);
                  onExclusaoFreteEspecial(newFretes_especiais.length);
                }}
              >
                Excluir{" "}
                {fretes_especiais[indexFrete].descricao
                  ? fretes_especiais[indexFrete].descricao
                  : "regra de frete especial"}
              </span>
            </div>
          </div>
        )}
      </ContainerFreteEspecial>
    </>
  );
};
export default FreteEspecial;
