import styled from "styled-components";

export const ViewMenuLateral = styled.aside`
  display: contents;
  .view-menu-lateral {
    position: fixed;
    z-index: 200;
    width: 7%;
    min-width: 120px;
    height: 100vh;
    transition: 0.2s;
    
    .fixedContainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      background: ${(props) => props.theme.colors.background2};
      padding: 4rem 0px 4rem 0px;
      border-radius: 0px 45px 45px 0px;
      left: 0;

      .logoConecta {
        svg {
          height: 10rem;
          width: 10rem;
          object-fit: contain;
          fill: ${(props) => props.theme.colors.brandPrimary};
        }
        cursor: pointer;
      }

      .menus,
      .logoPerfil {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        width: 100%;

        .menuContainer {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          color: ${(props) => props.theme.colors.brandSecondary};
          font-size: 1rem;
          padding: 3rem 0px;
          width: 100%;
          cursor: pointer;
          &::after {
            content: "";
            border-radius: 30px;
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
            background-color: ${(props) => props.theme.colors.titleColor};
            transform: scaleY(0);
            transition: 250ms ease-in-out;
          }

          .menu {
            width: 5rem;
            min-width: 5rem;
            height: 5rem;
            min-height: 5rem;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          .nomeMenu {
            font-size: 2rem;
          }
        }

        .ativo {
          color: ${(props) => props.theme.colors.titleColor};
          font-weight: bold;
          display: flex;
          flex-wrap: nowrap;
          white-space: normal;
          &::after {
            transform: scaleY(1);
          }

          .icone-svg {
            fill: ${(props) => props.theme.colors.titleColor};
          }
        }
      }
    }

    @media (max-width: 1024px) {
      position: fixed;
      width: 100%;
      height: 8rem;
      z-index: 200;
      overflow: hidden;
      transform: translateY(100%);
      left: 0;
      bottom: 0;
      &.ios{
        height: 11rem;
        .fixedContainer{
          padding: 0px 4rem 2rem 4rem;
        }
      }

      .fixedContainer {
        flex-direction: row;
        justify-content: center;
        padding: 0px 4rem 0px 4rem;
        border-radius: 45px 45px 0px 0px;
        width: 100%;

        .menus,
        .logoPerfil {
          flex-direction: row;
          justify-content: space-evenly;
          height: 100%;
          width: 100%;

          .menuContainer {
            height: 100%;
            flex-direction: row;
            width: auto;
            padding: 1rem 3rem;

            &::after {
              content: "";
              border-radius: 30px;
              position: absolute;
              left: auto;
              right: auto;
              top: 0;
              width: 100%;
              height: 3px;
              opacity: 0;
              transform: scaleX(0);
              background-color: ${(props) => props.theme.colors.titleColor};
              transition: 500ms ease;
            }

            .menu {
              @media (max-width: 650px) {
                width: 4rem;
                min-width: 4rem;
                height: 4rem;
                min-height: 4rem;
              }
            }
            .nomeMenu {
              height: auto;
              transition: 500ms ease;
              opacity: 0;
              display: none;
            }
          }

          .ativo {
            @media (max-width: 1024px) {
              .nomeMenu {
                opacity: 1;
                display: block;
                transform: scaleX(1);
                width: 100%;
                animation: 500ms reveal ease;
                overflow: hidden;

                @keyframes reveal {
                  from {
                    width: 0px;
                  }
                  to {
                    width: 100%;
                  }
                }
              }
              height: 100%;

              &::after {
                content: "";
                border-radius: 30px;
                position: absolute;
                left: auto;
                right: auto;
                top: 0;
                width: 100%;
                height: 3px;
                opacity: 1;
                background-color: ${(props) => props.theme.colors.titleColor};
                transform: scaleX(1);

                @media (max-width: 450px) {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottomAtivo {
    @media (max-width: 1024px) {
      transform: translateY(0);
    }
  }
`;
