import { useState, useRef, useEffect } from "react";
import { InfiniteComponent } from "./styles";

interface ComponentInfiniteScrollProps {
  children: React.ReactNode;
  className?: string;
  unload?: boolean;
  root?: React.RefObject<Element>;
  rootMargin?: string;
}

const ComponentInfiniteScroll: React.FC<ComponentInfiniteScrollProps> = ({
  children,
  className,
  unload = false,
  root,
  rootMargin,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  let observer: IntersectionObserver | null = null;
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (componentRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // when is in viewport
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              if (unload) {
                setIsVisible(false);
              }
            }
          });
        },
        {
          root: root ? root.current : null,
          rootMargin: rootMargin ? rootMargin : "0px",
        },
      );

      observer.observe(componentRef.current);
    }
    // Clean up the observer on unmount
    return () => {
      if (componentRef.current && observer) {
        observer.unobserve(componentRef.current);
      }
    };
  }, [componentRef]);

  return (
    <>
      <InfiniteComponent
        className={className}
        style={{ opacity: isVisible ? "1" : "0" }}
        ref={componentRef}
      >
        {isVisible && children}
      </InfiniteComponent>
    </>
  );
};

export default ComponentInfiniteScroll;
