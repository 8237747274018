import styled from 'styled-components';
import theme from "../../Themes/light";
import { ThemeType } from '../../types/Theme';

const tamanhoColunaPrincipal = 40;
const TableProdutosContainer = styled.div
    <{ theme: ThemeType, colunasComVariacao: number, template: 'principal' | 'impressao' }>`
    width: 90%;
    
    table{
        min-width: 100%;
        thead{
            tr{
                th{
                    color: ${({ theme }) => theme.colors.brandSecondary};
                    margin-bottom: 1rem;
                    text-align: center;
                }
            }
        }
        tfoot{
            .flex-footer{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 3rem;
                font-weight: bold;
                .info{
                    color: ${({ theme }) => theme.colors.brandSecondary};
                    font-size: 2.5rem;
                    display: flex;
                    svg{
                        margin-right: 0.5rem;
                        width: 2rem;
                        fill: ${({ theme }) => theme.colors.titleColor};
                    }
                }
            }
        }
        .row-simulated{
            display: grid;
            grid-template-columns: 50% repeat(4, 12.5%);
            column-gap: 1rem;
            .flex-produto {
                width: 100%;
                display: grid;
                grid-template-columns: 15rem auto;
                gap: 2rem;
                align-items: start;
                justify-items: start;
                text-align: left;
                font-size: 2.5rem;
                color: ${({ theme }) => theme.colors.brandSecondary};
        
                .produto-imagem {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    .imagem-produto-container{
                        width: 100%;
                        border-radius: 2rem;
                        overflow: hidden;
                        > img {
                            cursor: pointer;
                            width: 100%;
                            height: 15rem;
                            object-fit: scale-down;
                        }
                    }
                }
                .informacoes-produto{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1.5rem;
                    padding-right: 2rem;
                    line-height: 1.2;
                    .descricao{
                        p{
                            margin: 0;
                        }
                        *{
                            line-height: 1.2;
                        }
                    }
                }
            }
            
            .flex-variacoes{
                height: auto;
                min-width: 10rem;
                text-align: center;
                display: flex;
                align-items: flex-end;
                color: ${({ theme }) => theme.colors.brandSecondary};
                padding-top: 1rem;
                span{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        margin-left: 0.5rem;
                        width: 2rem;
                        fill: ${({ theme }) => theme.colors.titleColor};
                    }
                }
            }
            .observacao{
                position: relative;
                padding-bottom: 1rem;
                grid-column: span 4;
                min-width: 100%;
                width: min-content;
                text-align: left;
                span{
                    width: 100%;
                    position: relative;
                    display: inline-block;
                    color: ${({ theme }) => theme.colors.brandSecondary};
                }
                .separator-line-obs{
                    position: absolute;
                    top: calc(100% - 2px);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    border-radius: 50px;
                    background-color: ${({ theme }) => theme.colors.elements};
                }
            }
        }
        .separator-line{
            grid-column: 1 / -1;
            width: 100%;
            height: 2px;
            margin: 1rem 0;
            border-radius: 50px;
            background-color: ${({ theme }) => theme.colors.elements};
        }
    }
    @media print{
        width: 100%;
        page-break-before: avoid;
        table{
            thead{
                tr{
                    th{
                        color: ${theme.colors.brandSecondary} !important;
                        margin-bottom: 5px;
                        font-size: 11px;
                    }
                }
            }
            tfoot{
                .flex-footer{
                    gap: 12px;
                    font-weight: bold !important;
                    margin-top: 10px;
                    .info{
                        color: ${theme.colors.brandSecondary} !important;
                        font-size: 11px;
                    }
                }
            }
            .row-simulated{

                .flex-produto {
                    grid-template-columns: 75px auto;
                    gap: 15px;
                    font-size: 11px;
                    color: ${theme.colors.brandSecondary} !important;
                    .produto-imagem {
                        .imagem-produto-container{
                            border-radius: 15px;
                            > img {
                                cursor: pointer;
                                width: 100%;
                                height: 75px;
                                object-fit: scale-down;
                            }
                        }
                    }
                    .informacoes-produto{
                        color: ${theme.colors.brandSecondary} !important;
                        gap: 5px;
                        line-height: 1.2;
                        padding-right: 10px;
                        div{
                            color: ${theme.colors.brandSecondary} !important;
                            b{
                                color: ${theme.colors.brandSecondary} !important;
                            }
                        }
                        .descricao{
                            font-size: 8px !important;
                            color: ${theme.colors.description} !important;
                            *{
                                color: ${theme.colors.description} !important;
                                margin: 0;
                                line-height: 1.2;
                            }
                        }
                    }
                }
            }
            .separator-line{
                height: 1px;
                /* margin: 5px 0; */
                /* border-radius: 50px; */
                background-color: ${theme.colors.brandSecondary} !important;
                &:last-of-type, &:first-of-type{
                    display: block;
                }
            }
            .flex-variacoes{
                font-size: 11px;
                text-align: center;
                color: ${theme.colors.brandSecondary} !important;
                padding-top: 8px;
                span{
                    text-align: center;
                    color: ${theme.colors.brandSecondary} !important;
                }
            }
            .observacao{
                font-size: 8px;
                padding-bottom: 10px;
                span{
                    b{
                        font-size: 8px;
                        color: ${theme.colors.brandSecondary} !important;
                    }
                    font-size: 8px;
                    color: ${theme.colors.brandSecondary} !important;
                }
                .separator-line-obs{
                    height: 1px;
                    background-color: ${theme.colors.elements} !important;
                }
            }
        }
    }
`;

export {
    TableProdutosContainer
};

