import { ChangeEvent, useContext, useState } from "react";
import { Input } from "../../Input";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { Toggle } from "../../Toggle/Toggle";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import { copy } from "../../../services/Functions";
import Hide from "../../Uteis/Hide";
import ContainerConfigCorreios from "./styles";
import Dropdown from "../../Dropdowns/Dropdown";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { cepMask } from "../../../services/Masks";
import { validateCep } from "../../../services/MasksValidations";
import { unmaskToNumbers } from "../../../services/Unmasks";
import PopoverInfo from "../../PopoverInfo/PopoverInfo";

interface ConfigCorreiosProps {
  configIn: "empresa" | "configuracoes";
}

export const ConfigCorreios: React.FC<ConfigCorreiosProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const [modalConfigAvancadas, setModalConfigAvancadas] = useState(false);

  const [configuracoesAvancadasAuxiliar, setConfiguracoesAvancadasAuxiliar] =
    useState<{
      opcoes: [
        { ativo: boolean; tipo: string; descricao: string; codigo: string },
        { ativo: boolean; tipo: string; descricao: string; codigo: string },
        { ativo: boolean; tipo: string; descricao: string; codigo: string },
        { ativo: boolean; tipo: string; descricao: string; codigo: string },
      ];
      dimensoes: {
        comprimento: string;
        largura: string;
        altura: string;
        peso: string;
      };
    }>({
      opcoes: [...configuracoes.frete.opcoes],
      dimensoes: { ...configuracoes.frete.dimensoes },
    });

  const { theme } = useTheme();

  const isConfigsValid = () => {
    return (
      Number(configuracoes.frete.configuracoes.cep_origem) > 0 &&
      Number(configuracoes.frete.dimensoes.comprimento) > 0 &&
      Number(configuracoes.frete.dimensoes.altura) > 0 &&
      Number(configuracoes.frete.dimensoes.largura) > 0 &&
      Number(configuracoes.frete.dimensoes.peso) > 0 &&
      configuracoes.frete.opcoes.filter((opcao) => opcao.ativo).length > 0 &&
      validateCep(configuracoes.frete.configuracoes.cep_origem)
    );
  };

  const handleInputCepChange = (newValue: string, name?: string) => {
    setConfiguracoes({
      ...configuracoes,
      frete: {
        ...configuracoes.frete,
        configuracoes: {
          ...configuracoes.frete.configuracoes,
          [name!]: newValue,
        },
      },
    });
  };

  const handleToggleChange = (newValue: Boolean, index: number) => {
    const copia = copy(configuracoes);
    copia.frete.opcoes[index].ativo = newValue;
    setConfiguracoes(copia);
  };

  const handleInputChange = (newValue: string, name?: string) => {
    //check if value is a number
    if (isNaN(Number.parseInt(newValue))) return;

    if (Number.parseInt(newValue) <= 99999) {
      setConfiguracoes({
        ...configuracoes,
        frete: {
          ...configuracoes.frete,
          dimensoes: { ...configuracoes.frete.dimensoes, [name!]: newValue },
          configuracoes: {...configuracoes.frete.configuracoes, [name!]: newValue},
        },
      });
    }
  };

  return (
    <>
      <ContainerConfigCorreios configIn={configIn} theme={theme}>
        <div className="textoOpcoesCorreios">
          <div className="conteudo">
            O contrato dos Correios funciona apenas para os níveis prata, bronze e ouro. <br />
            Caso você tenha algum deles, preencha os dados do seu contrato com os Correios para configurar os
            fretes. 
          </div>
        </div>
        <div className="opcoesAvancadasCorreios">
          <div className="opcao">
            Seguro
            <Toggle
              value={configuracoes.frete.configuracoes.seguro}
              onChange={(newValue) => {
                setConfiguracoes({
                  ...configuracoes,
                  frete: {
                    ...configuracoes.frete,
                    configuracoes: {
                      ...configuracoes.frete.configuracoes,
                      seguro: newValue,
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="contratoComCorreios">
          <div className="titulo">Dados do contrato</div>
          <div className="grid-inputs">
            <div className="containerInput">
              <Input
                maxLength={75}
                showMaxLength={false}
                name="numeroContrato"
                type="text"
                value={configuracoes.frete.configuracoes.codigo_administrativo}
                required={true}
                placeholder={""}
                label="Token"
                alignLabel="left"
                ignoreValidations={!configuracoes.frete.correiosAtivo}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      configuracoes: {
                        ...configuracoes.frete.configuracoes,
                        codigo_administrativo: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
            <div className="containerInput">
              <Input
                maxLength={75}
                type="text"
                showMaxLength={false}
                name="numeroContrato"
                label="Contrato"
                alignLabel="left"
                value={configuracoes.frete.configuracoes.contrato}
                required={true}
                placeholder={""}
                ignoreValidations={!configuracoes.frete.correiosAtivo}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      configuracoes: {
                        ...configuracoes.frete.configuracoes,
                        contrato: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
            <div className="containerInput">
              <Input
                maxLength={75}
                showMaxLength={false}
                required={true}
                name="numeroContrato"
                type="text"
                label="Usuário"
                alignLabel="left"
                value={configuracoes.frete.configuracoes.usuario}
                placeholder={""}
                ignoreValidations={!configuracoes.frete.correiosAtivo}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      configuracoes: {
                        ...configuracoes.frete.configuracoes,
                        usuario: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
            <div className="containerInput">
              <Input
                maxLength={75}
                showMaxLength={false}
                name="numeroContrato"
                required={true}
                type="text"
                label="Cartão de postagem"
                alignLabel="left"
                value={configuracoes.frete.configuracoes.cartao}
                placeholder={""}
                ignoreValidations={!configuracoes.frete.correiosAtivo}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    frete: {
                      ...configuracoes.frete,
                      configuracoes: {
                        ...configuracoes.frete.configuracoes,
                        cartao: newValue,
                      },
                    },
                  });
                }}
              />
            </div>
            {configuracoes.frete.opcoes.filter((opcao) => opcao.ativo).map((opcao, index) => {
              return (
                <div className="containerInput">
                  <Input
                    maxLength={75}
                    showMaxLength={false}
                    required={true}
                    ignoreValidations={!configuracoes.frete.correiosAtivo}
                    type="text"
                    label={`Código ${opcao.descricao}`}
                    alignLabel="left"
                    value={opcao.codigo}
                    placeholder={`Código ${opcao.descricao}`}
                    onChange={(newValue) => {
                      const copia = copy(configuracoes);
                      copia.frete.opcoes[index].codigo = newValue;
                      setConfiguracoes(copia);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="avancadas"
            onClick={() => {
              setModalConfigAvancadas(true);
            }}
          >
            Configurações Avançadas
            <div className="input-aux-wrapper">
              <Input
                invalidText="Configure seu frete"
                isValid={{
                  isValidFunction: isConfigsValid,
                  dependencies: [
                    configuracoes.frete.configuracoes.cep_origem,
                    configuracoes.frete.dimensoes.comprimento,
                    configuracoes.frete.dimensoes.altura,
                    configuracoes.frete.dimensoes.largura,
                    configuracoes.frete.dimensoes.peso,
                    configuracoes.frete.opcoes,
                  ],
                }}
                ignoreValidations={!configuracoes.frete.correiosAtivo}
                className="input-aux"
                type={"text"}
                value={""}
                placeholder={""}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
        {modalConfigAvancadas && (
          <ModalRelativo
            cancelar={true}
            width="80rem"
            maxWidth="90%"
            normalModal={configIn === "configuracoes" ? true : false}
            center={true}
            isOpen={modalConfigAvancadas}
            setIsOpen={setModalConfigAvancadas}
            titulo={"Configurações Avançadas"}
            subtitulo={"Correios"}
            onUseEffect={() => {
              setConfiguracoesAvancadasAuxiliar({
                opcoes: [...configuracoes.frete.opcoes],
                dimensoes: { ...configuracoes.frete.dimensoes },
              });
            }}
            onCancel={() => {
              setConfiguracoes({
                ...configuracoes,
                frete: {
                  ...configuracoes.frete,
                  opcoes: [...configuracoesAvancadasAuxiliar.opcoes],
                  dimensoes: { ...configuracoesAvancadasAuxiliar.dimensoes },
                },
              });
            }}
            disabled={!isConfigsValid()}
            disabledMessage="Preencha todos os campos e selecione uma opção">
            <div className="cepContainer">
              CEP de origem
              <div className="containerInput">
                <Input
                  maxLength={75}
                  showMaxLength={false}
                  name="cep_origem"
                  type="text"
                  value={configuracoes.frete.configuracoes.cep_origem}
                  placeholder={"99200-000"}
                  onChange={handleInputCepChange}
                  required={true}
                  ignoreValidations={!configuracoes.frete.correiosAtivo}
                  mask={{
                    mask: cepMask,
                    unmask: unmaskToNumbers,
                  }}
                />
              </div>
            </div>
            <div className="titulo">Opções de Frete</div>
            <div className="opcoesDeFrete">
              {configuracoes.frete.opcoes.map((opcao, index) => {
                return (
                  <div className="opcao-container">
                    <label className="opcao" key={index}>
                      <Toggle
                        value={opcao.ativo}
                        onChange={(newValue) => {
                          handleToggleChange(newValue, index);
                        }}
                      ></Toggle>
                      {opcao.descricao}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="titulo">Embalagem</div>
            <div className="embalagemContainer">
              <Input
                maxLength={75}
                showMaxLength={false}
                type={"number"}
                name="comprimento"
                value={
                  Number.parseFloat(configuracoes.frete.dimensoes.comprimento) >
                  0
                    ? configuracoes.frete.dimensoes.comprimento.toString()
                    : ""
                }
                placeholder={"Comprimento (cm)"}
                onChange={handleInputChange}
                label="Comprimento (cm)"
                required={true}
                ignoreValidations={!configuracoes.frete.correiosAtivo}
              ></Input>
              <div className="larguraAltura">
                <div className="inputContainer">
                  <Input
                    maxLength={75}
                    showMaxLength={false}
                    type={"number"}
                    name="altura"
                    label="Altura (cm)"
                    value={
                      Number.parseFloat(configuracoes.frete.dimensoes.altura) >
                      0
                        ? configuracoes.frete.dimensoes.altura.toString()
                        : ""
                    }
                    placeholder={"Altura (cm)"}
                    onChange={handleInputChange}
                    required={true}
                    ignoreValidations={!configuracoes.frete.correiosAtivo}
                  ></Input>
                </div>
                <div className="inputContainer">
                  <Input
                    maxLength={75}
                    showMaxLength={false}
                    type={"number"}
                    label="Largura (cm)"
                    name="largura"
                    value={
                      Number.parseFloat(configuracoes.frete.dimensoes.largura) >
                      0
                        ? configuracoes.frete.dimensoes.largura.toString()
                        : ""
                    }
                    placeholder={"Largura (cm)"}
                    onChange={handleInputChange}
                    required={true}
                    ignoreValidations={!configuracoes.frete.correiosAtivo}
                  ></Input>
                </div>
              </div>
              <Input
                maxLength={75}
                showMaxLength={false}
                type={"number"}
                label="Peso (gr)"
                name="peso"
                value={
                  Number.parseFloat(configuracoes.frete.dimensoes.peso) > 0
                    ? configuracoes.frete.dimensoes.peso.toString()
                    : ""
                }
                placeholder={"Peso (gr)"}
                onChange={handleInputChange}
                ></Input>               
                <Input
                  maxLength={75}
                  showMaxLength={false}
                  labelIcon={
                    <PopoverInfo
                      mensagem="Esse prazo é referente a separação dos produtos e será adicionado ao prazo estimado da logística escolhida."
                      width="78rem"
                      className="popoverCorreios"
                    />
                  }
                  type={"number"}
                  label="Prazo de expedição"
                  name="diasEntregaCorreios"
                  value={
                    configuracoes.frete.configuracoes.diasEntregaCorreios !== undefined &&
                    Number(configuracoes.frete.configuracoes.diasEntregaCorreios) > 0
                      ? configuracoes.frete.configuracoes.diasEntregaCorreios.toString()
                      : ""
                  }
                  placeholder={"Dias para separação do pedido"}
                  onChange={handleInputChange}
                />
              </div>
          </ModalRelativo>
        )}
      </ContainerConfigCorreios>
    </>
  );
};
