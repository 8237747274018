import styled from "styled-components";
import theme from "../../Themes/light";

const { colors } = theme;

export const FloatingButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 50;
  width: 8rem;
  height: 8rem;
  padding: 2.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.brandPrimary};
  bottom: 5rem;
  right: 5rem;
  transition: all 0.3s ease;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  transform: translateY(100%) translateX(25%) scale(0);

  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.brandPrimary};
  }

  .icone-floating {
    width: 100%;
    height: 100%;
    fill: ${colors.white};
    transition: all 0.3s ease;
    margin-top: 6px;
  }

  @media (max-width: 1024px) {
    bottom: 10rem;
  }

  &.floating-ativo {
    transform: translateY(0%) translateX(0%) scale(1);

    .icone-floating {
      transform: translateY(0%) translateX(0%) scale(1);
    }
  }
  .seletor-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 5rem;
    height: 5rem;
    padding: 1.5rem;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.brandPrimary};
    bottom: 120%;
    transition: all 0.3s ease;
    box-shadow:
      rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors.brandPrimary};
    }
    .icone-floating {
      width: 50rem;
      height: 50rem;
      fill: white;
      transition: all 0.3s ease;
    }
  }
`;
