import styled, { css } from "styled-components";

const commonStyles = css`
  margin-top: 5rem;
  width: 100rem;
  max-width: 90vw;

  .online {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > .titulo {
      color: ${(props) => props.theme.colors.brandPrimary};
      font-size: 2.5rem;
      font-weight: bold;
    }
  }

  .online {
    > .wrapperToggleConfigurarPagamento {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      padding: 1rem 0;

      &:not(:last-of-type) {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 70%;
          height: 2px;
          background-color: ${(props) => props.theme.colors.elements};
          border-radius: 50px;
        }
      }
    }

    .chaveSecretaContainer {
      padding: 3rem 0;
    }
  }
`;

export const ContainerPagamentoContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      max-width: none;
      margin-top: 0;
      .containerPagamento {
        padding: 3rem 0 0 0;
        width: 100%;
        .titulo {
          display: none;
        }
        .online {

          position: relative;
          .wrapperToggleConfigurarPagamento {
            display: flex;
            align-items: center;
            width: 100%;
            .containerToggleConfigurar {
              width: 100%;
              .nome {
                width: 100%;
                color: ${(props) => props.theme.colors.brandSecondary};
                font-weight: bold;
              }
            }
          }
        }
      }
    `}
`;

// Add other styled components for the nested elements if necessary