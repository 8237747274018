import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { ReactComponent as Arrow } from "../../assets/newImages/icons/arrowLeft.svg";
import Banner from "../../assets/newImages/novidades/banner-2.jpg";
import BannerMobile from "../../assets/newImages/novidades/bannerMobile-2.jpg";
import Curseduca from "../../components/Curseduca/Curseduca";
import Modal from "../../components/Modal/Modal";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { NovidadesApi } from "../../hooks/novidadesApi";
import { PERMISSOES } from "../../services/Consts";
import Carousel from "./Carousel";
import ModalEntregaRetirada from "./ModalNovidades/ModalNovidades";
import { ContainerNovidades } from "./styles";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
  
  // Aplique os estilos ao container do carrossel
  #carousel-container {
    padding-right: 20px; // Espaço para a pré-visualização do próximo item
    overflow-y: hidden;
    overflow-x: scroll;
    
    // Oculta o scroll horizontal
    scrollbar-width: none; 
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

interface Parceiro {
  link: string;
  empresa: string;
  segmento: string;
  svg: any;
  texto: string;
}

interface NovidadesProps {}

const Novidades: React.FC<NovidadesProps> = ({}) => {
  const { theme } = useTheme();
  const [isOpenModalNovidades, setIsOpenModalNovidades] = React.useState(false);
  const [modalCurseduca, setModalCurseduca] = React.useState<boolean>(false);
  const apiNovidades = NovidadesApi();
  const {empresa, loading: loadingEmpresa} = useContext(EmpresaContext)
  const [loaded, setLoaded] = React.useState(false);
  const [modalNovidades, setModalNovidades] = React.useState<{
    title?: string | undefined;
    subtitle?: string;
    text: string;
    href: string;
  }>({
    title: "",
    subtitle: "",
    text: "",
    href: "",
  });
  const { permissoesUsuario } = useContext(AuthContext);
  const [novidades, setNovidades] = React.useState<any[]>([]);

  const [parceiros, setParceiros] = React.useState<Parceiro[]>([]);


  function animate(
    container: HTMLElement,
    targetScroll: number,
    startTime: number | null,
    time: number
  ) {
    if (startTime === null) startTime = time;
    const progress = time - startTime;
    const currentScroll =
      container.scrollLeft +
      ((targetScroll - container.scrollLeft) * progress) / 500; // 500ms duration
    container.scrollLeft = currentScroll;

    if (progress < 500) {
      requestAnimationFrame((time) =>
        animate(container, targetScroll, startTime, time)
      );
    }
  }

  function smoothScroll(index) {
    const containers =
      document.querySelectorAll<HTMLElement>(".ScrollContainerX");
    if (containers) {
      const container = containers[index];
      const targetScroll = container.scrollLeft + 200; // 200 pixels to the right

      requestAnimationFrame((time) =>
        animate(container, targetScroll, null, time)
      );
    }
  }

  function setModalParceiro(parceiro: Parceiro) {
    setModalNovidades({
      href: parceiro.link || "",
      text: parceiro.texto,
      title: "Benefícios dos nossos parceiros /",
      subtitle: parceiro.empresa,
    });
    setIsOpenModalNovidades(true);
  }

  const [conteudos, setConteudos] = React.useState<any[]>([]);

  async function getInstaFeed(token) {
    const url = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${token}`;
    const response = await axios.get(url);
    setConteudos(
      response.data.data
        .filter(
          (item: any) =>
            item.media_type === "IMAGE" || item.media_type === "CAROUSEL_ALBUM"
        )
        .slice(0, 9)
    );
  }

  function formatarDataEmPortugues(data: Date | string): string {
    const mesesEmPortugues: string[] = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    // Converte a entrada para um objeto Date, se já não for um
    const dataObj: Date = data instanceof Date ? data : new Date(data);

    const mes: string = mesesEmPortugues[dataObj.getMonth()];
    const ano: number = dataObj.getFullYear();

    return `${mes} de ${ano}`;
  }

  const onClickBanner = () => {
    if(empresa?.id_curseduca !== null){
      window.open("https://clubeconectado.conectavenda.com.br/login", "_blank");
    } else {
      setModalCurseduca(true);
    }
  };

  useEffect(() => {
    const init = async () => {
      apiNovidades.getNovidades().then((response) => {
        setNovidades(response.novidades);
        setParceiros(response.parceiros);
        getInstaFeed(response.novidades[0].token_instagram);
        setLoaded(true);
      });
    };
    init();
  }, []);
  

  if (!loaded || loadingEmpresa) return <div>Carregando...</div>;

  return (
    <>
      <ContainerNovidades theme={theme}>
        <Modal
          isOpen={modalCurseduca}
          setModal={setModalCurseduca}
          width={"75rem"}
          maxWidth="98vw"
        >
          <Curseduca onFechar={() => setModalCurseduca(false)}/>
        </Modal>
        <div className="banner" onClick={onClickBanner}>
          <img className="mobile" src={Banner} alt="" />
          <img className="desktop" src={BannerMobile} alt="" />
        </div>
        <div className="titulo-novidades unico">
          <strong>Novidades</strong>
          <div className="hideMobile">|</div>
          <span>Confira as atualizações da nossa plataforma</span>
        </div>
        <div className="atualizacoes">
          {novidades.map((novidade, index) => (
            <div className="cardAtualizacao">
              <div className="texto">
                <div className="topo">
                  <div className="titulo">
                    <strong>{novidade.titulo}</strong>
                  </div>
                  <div className="data">
                    {formatarDataEmPortugues(novidade.data)}
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: novidade.texto_externo,
                  }}
                ></div>
              </div>
              {novidade.texto_interno && (
                <div className="final">
                  <div
                    className="saiba-mais"
                    onClick={() => {
                      setModalNovidades({
                        href: "https://api.whatsapp.com/send?phone=555434434563&text=Olá,+estou+no+painel+do+Conecta+Venda,+gostaria+de+saber+mais+sobre+as+novidades+da+plataforma.",
                        text: novidade.texto_interno,
                        title: 'Novidades /',
                        subtitle:  novidade.titulo,
                      });
                      setIsOpenModalNovidades(true);
                    }}
                  >
                    Saiba mais
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
          <>
            <div className="titulo-novidades">
              <strong>Vantagens</strong>
              <div className="hideMobile">|</div>
              <span>Confira os benefícios dos nossos parceiros para você</span>
              <div
                className="arrow"
                onClick={() => {
                  smoothScroll(0);
                }}
              >
                <Arrow />
              </div>
            </div>
            <GlobalStyle />
            <div id="carousel-container">
              <Carousel
                items={parceiros.map((item, index) => (
                  <div className="parceiro" key={index}>
                    <div
                      className="imagem"
                      dangerouslySetInnerHTML={{ __html: item.svg }}
                    ></div>
                    <div className="titulo">
                      <div>{item.segmento}</div>
                    </div>
                    <a
                      className="conhecer"
                      onClick={() => setModalParceiro(item)}
                    >
                      <div>Conhecer</div>
                    </a>
                  </div>
                ))}
              />
            </div>
          </>
        )}
        {/* <div className="titulo-novidades">
          <strong>Conteúdos</strong>
          <div className="hideMobile">|</div>
          <span>Confira nossos conteúdos das redes sociais</span>
          <div
            className="arrow"
            onClick={() => {
              smoothScroll(1);
            }}
          >
            <Arrow />
          </div>
        </div> */}
        <GlobalStyle />
        {/* <div id="carousel-container">
          <Carousel
            items={conteudos.map((conteudo, index) => (
              <a
                key={conteudo.id}
                href={conteudo.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {conteudo.media_type === "IMAGE" ||
                conteudo.media_type === "CAROUSEL_ALBUM" ? (
                  <div className="conteudo" key={index}>
                    <img src={conteudo.media_url} alt="" />
                  </div>
                ) : (
                  <div className="conteudo" key={index}>
                    <video src={conteudo.media_url} />
                  </div>
                )}
              </a>
            ))}
          />
        </div> */}
        <Modal
          isOpen={isOpenModalNovidades}
          setModal={setIsOpenModalNovidades}
          width={"auto"}
        >
          <ModalEntregaRetirada
            onClose={() => setIsOpenModalNovidades(!isOpenModalNovidades)}
            text={modalNovidades.text}
            href={modalNovidades.href}
            title={modalNovidades.title || ""}
            subtitle={modalNovidades.subtitle}
          />
        </Modal>
      </ContainerNovidades>
    </>
  );
};

export default Novidades;
