import React, { useContext, useEffect, useState } from "react";
import "./styles.ts";
import { RelatoriosContext } from "../../contexts/Relatorios/RelatoriosContext";
import Tabs from "../../components/Tabs/Tabs";
import { Relatorio as RelatorioType } from "../../types/Relatorios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Relatorio from "./Relatorio/Relatorio";
import { Select } from "../../components/Select";
import RelatoriosContainer from "./styles.ts";
import SkeletonRelatorios from "../../components/Skeletons/SkeletonRelatorios/SkeletonRelatorios";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";

interface RelatoriosProps {}

const Relatorios: React.FC<RelatoriosProps> = ({}) => {
  const { theme } = useTheme();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { relatorios, tipos, loading } = useContext(RelatoriosContext);
  const [relatorioAtivo, setRelatorioAtivo] = useState<RelatorioType>();

  const [activeTab, setActiveTab] = useState("");

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    setRelatorioAtivo(undefined);
    navigate(`/painel/relatorios`);
  };

  useEffect(() => {
    if (tipos.length) setActiveTab(tipos[0].id);
  }, [tipos]);

  useEffect(() => {
    if (location.pathname === "/painel/relatorios") {
      setRelatorioAtivo(undefined);
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      const relatorio = relatorios.find((relatorio) => relatorio.id === id);
      if (relatorio) {
        setRelatorioAtivo(relatorio);
        setActiveTab(relatorio.tipo);
      }
    }
  }, [id, relatorios]);

  return (
    <>
      <RelatoriosContainer theme={theme}>
        {loading ? (
          <SkeletonRelatorios></SkeletonRelatorios>
        ) : (
          <>
            <Tabs
              className="hideMobile"
              tabs={tipos.map((tipo) => {
                return {
                  name: tipo.nome,
                  value: tipo.id,
                };
              })}
              tabsNumber={tipos.length}
              value={activeTab}
              onChange={handleTabChange}
            ></Tabs>
            <Select
              options={tipos.map((tipo) => {
                return {
                  name: tipo.nome,
                  value: tipo.id,
                };
              })}
              value={activeTab}
              onChange={(value) => handleTabChange(value)}
              className="hideDesktop"
            />
            <div className="relatorios-container">
              {tipos.map((tipo) => (
                <div
                  className={`tipo ${
                    activeTab === tipo.id && !relatorioAtivo && "ativo"
                  }`}
                >
                  {relatorios
                    .filter((relatorio) => relatorio.tipo === tipo.id)
                    .map((relatorio) => (
                      <Link
                        to={`/painel/relatorios/${relatorio.id}`}
                        className={`relatorio-container 
                                        ${
                                          activeTab === tipo.id &&
                                          !relatorioAtivo &&
                                          "ativo"
                                        }
                                        ${
                                          relatorioAtivo?.id === relatorio.id &&
                                          "ativo-relatorio"
                                        }`}
                        onClick={() => {
                          setRelatorioAtivo(relatorio);
                        }}
                      >
                        <div className="titulo">{relatorio.nome}</div>
                        <div className="descricao">{relatorio.descricao}</div>
                      </Link>
                    ))}
                </div>
              ))}
              {relatorioAtivo && (
                <>
                  <div className="tipo ativo">
                    <div className={`relatorio-container ativo`}>
                      <div className="titulo">{relatorioAtivo.nome}</div>
                      <div className="descricao">
                        {relatorioAtivo.descricao}
                      </div>
                    </div>
                    <Relatorio relatorio={relatorioAtivo}></Relatorio>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </RelatoriosContainer>
    </>
  );
};

export default Relatorios;
