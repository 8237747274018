import React, { useState } from "react";
import "./styles.ts";
import TextAreaComponentContainer from "./styles.ts";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
interface InputProps {
  type: string;
  value: string;
  placeholder: string;
  onChange: (newValue: string, name?: string) => void;
  name?: string;
  error?: string;
  label?: string;
  alignLabel?: "left" | "center" | "right";
  sizeLabel?: "small" | "medium" | "large";
  disabled?: boolean;
  icone?: string;
  labelIcon?: React.ReactNode;
  maxLength?: number;
  pattern?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
export const Textarea: React.FC<InputProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  labelIcon,
  error,
  disabled = false,
  icone,
  maxLength,
  pattern,
  onKeyDown,
  alignLabel,
  sizeLabel,
}) => {
  const { theme } = useTheme();

  let fontLabel = sizesLabel();

  function sizesLabel() {
    switch (sizeLabel) {
      case "small":
        return "1.5rem";
      case "medium":
        return "2.5rem";
      case "large":
        return "3rem";
      default:
        return "2.5rem";
    }
  }

  const handleTextareaChange = (event: {
    target: { value: any; name?: any };
  }) => {
    //remove emojis and special characters
    const regexEmojis = /[\uD800-\uDFFF]./;

    let newValue = event.target.value;
    newValue = newValue.replace(regexEmojis, "");

    name ? onChange(newValue, name) : onChange(newValue);
  };

  return (
    <>
      <TextAreaComponentContainer theme={theme}>
        {label && (
          <div
            className={`label ${alignLabel && alignLabel}`}
            style={{ fontSize: fontLabel }}
          >
            {label}
            {labelIcon && labelIcon}
          </div>
        )}
        <div className={`textarea ${icone ? "comIcone" : ""}`}>
          <textarea
            name={name}
            className="scrollBonito"
            value={value}
            onChange={handleTextareaChange}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            style={{ paddingRight: maxLength ? "12rem" : "" }}
          /> 
          {icone && (
            <div className="iconeContainer">
              <img
                className="icone"
                src={require(`../../assets/newImages/${icone}`)}
              />
            </div>
          )}
          {maxLength && (
            <div className="maxLenght">
              {value ? value.length : 0}/{maxLength}
            </div>
          )}
          {error && <span className="error">{error}</span>}
        </div>
      </TextAreaComponentContainer>
    </>
  );
};
