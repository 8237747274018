import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

const ModalNovidadesContainer = styled.div<{ theme: ThemeType }>`
  padding: 5rem;
  width: 100rem;
  max-width: 100%;
  .texto {
    color: ${({ theme }) => theme.colors.brandSecondary};
    font-size: 2.5rem;
    text-align: justify;
    padding: 5rem 0;
    span {
      font-weight: bold;
    }
  }
  .botoes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: center;
      svg {
        stroke: ${({ theme }) => theme.colors.brandSecondary};
      }
    }
  }
`;

export { ModalNovidadesContainer };
