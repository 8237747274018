import { api } from "./useApi";

export const CestasAbandonadasApi = () => ({
  getCamposPersonalizados: async () => {
    try {
      const response = await api.get(
        "/usuario/configuracoes_campos_personalizados_cestas",
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  setCamposPersonalizados: async (campos: {}) => {
    try {
      const response = await api.post(
        "/usuario/atualizar_campos_personalizados_cestas",
        { campos: campos },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  getCestasAbandonadas: async (
    campos: string[],
    datas: { inicio: string; fim: string },
    filtro: {
      usuario: string;
      catalogo: string;
      clientes_identificados: number;
    },
  ) => {
    try {
      const response = await api.post("cestas_abandonadas/listar", {
        campos: campos,
        datas: datas,
        filtro: filtro,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  getCestaAbandonada: async (id_cesta: string) => {
    try {
      const response = await api.post("cesta_abandonada/listar", {
        id_cesta: id_cesta,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  atualizarObservacaoInternaCesta: async (
    id_cesta: string,
    observacao: string,
  ) => {
    try {
      const response = await api.post("cesta_abandonada/inserir_observacao", {
        id_cesta_abandonada: id_cesta,
        observacao: observacao,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});
