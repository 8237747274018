import React, { useState } from "react";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { ParceriaMacro } from "../../../../components/ParceriaMacro";
import Tabs from "../../../../components/Tabs/Tabs";
import { ImageUploadProduto } from "../../../../components/Upload/imageUploadProduto/ImageUploadProduto";
import "./styles.scss";

interface MidiasBoxProps {
  adicionaFoto: (newImages: {
    base64: string;
    imagem: string;
    size: number;
  }) => void;
  adicionaVideo: (newValue: string) => void;
}

export const MidiasBox: React.FC<MidiasBoxProps> = ({
  adicionaFoto,
  adicionaVideo,
}) => {
  const [activeTab, setActiveTab] = useState("foto");

  const [video, setVideo] = useState("");
  const [tabs, setTabs] = useState([
    { name: "Adicionar foto", value: "foto" },
    { name: "Adicionar vídeo", value: "video" },
  ]);

  const [foto, setFoto] = useState("");

  return (
    <div className="view-MidiasBox">
      <Tabs
        tabs={tabs}
        value={activeTab}
        onChange={(newValue) => {
          setActiveTab(newValue);
          if (newValue !== "avancado") {
            setTabs(tabs.filter((tab) => tab.value !== "avancado"));
          }
        }}
        tabsNumber={window.innerWidth <= 992 ? 2 : 3}
      />
      {activeTab === "foto" && (
        <div className="adicionar-foto">
          <ImageUploadProduto
            key={1}
            value={foto}
            aspectRatio={1 / 1}
            minWidth={800}
            minHeight={800}
            resize={{ width: 900, height: 900 }}
            onChange={adicionaFoto}
          />
        </div>
      )}
      {activeTab === "video" && (
        <div className="adicionar-video">
          <Input
            label="Adicione um vídeo do Youtube"
            placeholder="Cole aqui o link do vídeo"
            sizeLabel="medium"
            type="text"
            name="sku"
            value={video}
            onChange={(newValue) => {
              setVideo(newValue);
            }}
          />
          <Button
            title="Adicionar Vídeo"
            className="botao_alterar"
            width="fit-content"
            fontSize="2.5rem"
            onClick={() => {
              adicionaVideo(video);
            }}
          />
        </div>
      )}

      <ParceriaMacro />
    </div>
  );
};
