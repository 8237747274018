import React, { FC, useEffect, useMemo, useState } from 'react';
import './styles';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { SliderContainer } from './styles';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import debounce from "lodash/debounce";
import { currencyMask } from '../../services/Masks';
import { unmaskCurrency, unmaskToNumbers } from '../../services/Unmasks';

interface SliderProps {
    id?: string;
    className?: string;
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: [number, number];
    value?: [number, number];
    onThumbDragStart?: () => void;
    onThumbDragEnd?: () => void;
    onRangeDragStart?: () => void;
    onRangeDragEnd?: () => void;
    disabled?: boolean;
    rangeSlideDisabled?: boolean;
    thumbsDisabled?: [boolean, boolean];
    orientation?: 'horizontal' | 'vertical';
    debounceTime?: number;
    onDebounce?: (value: [number, number]) => void;
    onDebounceStart?: (value: [number, number]) => void;
}

const Slider: FC<SliderProps> = ({
    id = null,
    className = null,
    min = 0,
    max = 100,
    step = 1,
    defaultValue = [0, 100],
    value,
    onThumbDragStart,
    onThumbDragEnd,
    onRangeDragStart,
    onRangeDragEnd,
    disabled = false,
    rangeSlideDisabled = false,
    thumbsDisabled = [false, false],
    orientation = 'horizontal',
    debounceTime = 500,
    onDebounce,
    onDebounceStart,
}) => {
    const { theme } = useTheme();

    const [minValue, setMinValue] = useState(defaultValue[0]);
    const [maxValue, setMaxValue] = useState(defaultValue[1]);

    const debouncedOnChange = useMemo(
        () => debounce((unmaskedValue) => {
            onDebounce && debounceTime && onDebounce(unmaskedValue);
        }, debounceTime),
        [debounceTime, onDebounce]
    );

    const onInput = (value) => {
        setMinValue(value[0]);
        setMaxValue(value[1]);
        onDebounceStart && onDebounceStart(value);
        debouncedOnChange(value);
    };

    const handleMinInputChange = (e) => {
        let unmaskedValue = e.target.value.replace(/[^\d.]/g, "");
        unmaskedValue = currencyMask(unmaskedValue);
        unmaskedValue = unmaskCurrency(unmaskedValue);
        
        const newVal = Math.min(unmaskedValue, maxValue);
        setMinValue(newVal);

        onInput([newVal, maxValue]);
    };

    const handleMaxInputChange = (e) => {
        let unmaskedValue = e.target.value.replace(/[^\d.]/g, "");
        unmaskedValue = currencyMask(unmaskedValue);
        unmaskedValue = unmaskCurrency(unmaskedValue);

        const newVal = Math.max(unmaskedValue, minValue);
        if(newVal > max) return;
        setMaxValue(newVal);

        onInput([minValue, newVal]);
    };

    useEffect(() => {
        setMaxValue(max);
    }, [max]);

    useEffect(() => {
        if (value) {
            setMinValue(value[0]);
            setMaxValue(value[1]);
        }
    }, [value]);

    return (
        <SliderContainer theme={theme}>
            <div className="input-slider-container min-value">
                <div className="input-wrapper">
                    <input
                        className='value'
                        type="text"
                        step={step}
                        value={currencyMask((minValue || 0).toFixed(2), "R$")}
                        onChange={handleMinInputChange}
                        disabled={disabled}
                        min={min}
                        max={max}
                    />
                </div>
            </div>
            <RangeSlider
                id={id}
                className={className}
                min={min}
                max={max}
                step={step}
                defaultValue={[minValue, maxValue]}
                value={[minValue, maxValue]}
                onInput={(values) => onInput(values)}
                onThumbDragStart={onThumbDragStart}
                onThumbDragEnd={onThumbDragEnd}
                onRangeDragStart={onRangeDragStart}
                onRangeDragEnd={onRangeDragEnd}
                disabled={disabled}
                rangeSlideDisabled={rangeSlideDisabled}
                thumbsDisabled={thumbsDisabled}
                orientation={orientation}
            />
            <div className="input-slider-container max-value">
                <div className="input-wrapper">
                    <input
                        className='value'
                        type="text"
                        step={step}
                        value={currencyMask((maxValue || 0).toFixed(2), "R$")}
                        onChange={handleMaxInputChange}
                        disabled={disabled}
                        min={min}
                        max={max}
                    />
                </div>
            </div>
        </SliderContainer>
    );
};

export default Slider;
