import styled, { DefaultTheme } from "styled-components";
import theme from "../../Themes/light";

export const ViewHome = styled.div<{ paginas: number; theme: DefaultTheme }>`
  font-family: "D-DIN";
  position: relative;
  padding: 0rem 10rem;
  padding-bottom: 5rem;

  .tituloHome {
    font-size: 3rem;
    text-align: left;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.titleColor};
    margin-bottom: 1rem;
    margin-top: 6rem;
    grid-column: 1 / -1;
  }

  .menus-container {
    .menus {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 5rem;
      display: flex;
      > a {
        display: contents;
      }
      .menu,
      .menuCriacao {
        flex-grow: 1;
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        gap: 3rem;
        border-radius: 24px;
        padding: 5rem 0px;
        text-align: left;
        height: 15rem;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        .image-container {
          width: 5rem;
          height: 5rem;

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: ${({ theme }) =>
              theme.mode == "light"
                ? theme.colors.brandPrimary
                : theme.colors.brandSecondary};
          }
        }

        .nomeMenu {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 2.5rem;
          width: 26rem;
          color: ${({ theme }) =>
            theme.mode == "light"
              ? theme.colors.brandPrimary
              : theme.colors.brandSecondary};
        }
      }

      .menuCriacao {
        background: rgba(13, 82, 41, 0.05);
        background: ${({ theme }) =>
          theme.mode == "light"
            ? "rgba(13, 82, 41, 0.05)"
            : theme.colors.white};
        justify-content: flex-end;
        gap: 3rem;

        .nomeMenuCriacao {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.5rem;
          color: ${({ theme }) =>
            theme.mode == "light"
              ? theme.colors.brandPrimary
              : theme.colors.brandSecondary};
          text-align: center;
        }

        .guiaDeCriacaoContainer {
          min-width: 0;
          display: flex;
          align-items: center;
          height: 100%;
          position: relative;
          margin-right: 3rem;

          .guiaDeCriacaoImagem {
            position: relative;
            width: 30rem;
          }
        }

        @media (max-width: 1285px) {
          gap: 0px;

          .guiaDeCriacaoContainer {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .novidades {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 30px;
    .novidade {
      img {
        width: 100%;
        height: 100%;
        max-height: 50rem;
        border-radius: 30px;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }

  .botaoCompartilhar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    font-size: 3rem;
    background-color: ${({ theme }) => theme.colors.brandPrimary};
    border-radius: 40px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    margin: 9rem 0 0 0;
    padding: 1rem 0px;
    transform: scale(1);
    transition: 0.3s;
      &:hover {
       transform: scale(1.01) ;
       transition: 0.3s;
      }
  }

  @media (max-width: 1024px) {
    padding: 1rem 5rem;

    > .titulo {
      margin-top: 1rem;
    }

    .tituloHome {
      margin-top: 1rem;
    }

    .menus-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;

      .menus {
        @media (max-width: 1024px) {
          display: contents;
        }

        .menu,
        .menuCriacao {
          gap: 2rem;
          height: 10vh;
          width: 100%;
          min-height: 10rem;
          padding: 1rem 3rem;

          .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            min-width: 3rem;
            min-height: 3rem;
            height: 3rem;
          }

          .nomeMenu {
            width: 23rem;
            justify-content: flex-start;
          }
        }

        .menuCriacao {
          justify-content: center;
          gap: 3rem;

          .nomeMenuCriacao {
            width: auto;
            text-align: center;
          }

          .guiaDeCriacaoContainer {
            min-width: 0;
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;

            .guiaDeCriacaoImagem {
              position: relative;
              width: 15rem;
              min-width: 15rem;
            }

            @media (max-width: 530px) {
              gap: 1rem;

              .guiaDeCriacaoContainer {
                .guiaDeCriacaoImagem {
                  position: relative;
                  width: 9rem;
                  min-width: 9rem;
                }
              }
            }
          }
        }
      }

      @media (max-width: 465px) {
        display: flex;
        flex-direction: column;

        .menus {
          .menu,
          .menuCriacao {
            gap: 2rem;
            height: 5vh;
            min-height: 10rem;
            padding: 1rem 3rem;

            .nomeMenu {
              width: 23rem;
              justify-content: flex-start;
            }
          }
          .menuCriacao {
            position: relative;
            justify-content: center;
            .nomeMenuCriacao {
              padding-right: 2rem;
            }
            .guiaDeCriacaoContainer {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      .novidades {
        grid-template-columns: 1fr 1fr;
        .novidade {
          &.ultima {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }

    @media (max-width: 485px) {
      padding: 1rem 3rem;
    }
  }
`;
