import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

const ContainerPagSeguroAutorizado = styled.div<{ theme: ThemeType }>`
  color: ${({ theme }) => theme.colors.brandSecondary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-size: 2.5rem;
  span {
    font-size: 4rem;
    font-weight: bold;
  }
`;

export { ContainerPagSeguroAutorizado };
