import styled from "styled-components";

const PedidosImpressaoContainer = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    display: block;
  }
`;

export default PedidosImpressaoContainer;
