import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;

  .buttonContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 7rem;
  }
`;
