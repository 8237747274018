import { UilLinkH } from "@iconscout/react-unicons";
import React, {
  useContext,
  useEffect,
  useState
} from "react";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as CatalogoSVG } from "../../assets/newImages/home/catalog.svg";
import { ReactComponent as PedidoSVG } from "../../assets/newImages/home/pedido.svg";
import { ReactComponent as ProdutosSVG } from "../../assets/newImages/home/produtos.svg";
import { ReactComponent as RelatoriosSVG } from "../../assets/newImages/home/relatorios.svg";
import { ReactComponent as SacolaSVG } from "../../assets/newImages/home/sacola.svg";
import SkeletonHome from "../../components/Skeletons/SkeletonHome/SkeletonHome.tsx";
import { AuthContext } from "../../contexts/Auth/AuthContext.tsx";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { HeaderContext } from "../../contexts/Header/HeaderContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { configuracoesApi } from "../../hooks/configuracoesApi.ts";
import { PERMISSOES } from "../../services/Consts.tsx";
import { staginEnv } from "../../services/Functions.tsx";
import "./styles.ts";
import { ViewHome } from "./styles.ts";

const Home: React.FC = () => {
  const { theme } = useTheme();

  const { permissoesUsuario, loading: loadingPermissoes } =
    useContext(AuthContext);
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const apiConf = configuracoesApi();
  const [temProdutos, setTemProdutos] = useState(false);

  const { setModalCriacao, modalCriacao } = useContext(HeaderContext);

  const paginas = [
    {
      id_permissao: 13,
      id_menu: 4,
      name: "Meus pedidos",
      page_ref: "painel/pedidos",
      icon: PedidoSVG,
      tipo: "gerenciar",
    },
    {
      id_permissao: 21,
      id_menu: 8,
      name: "Sacolas abandonadas",
      page_ref: "painel/sacolas-abandonadas",
      icon: SacolaSVG,
      tipo: "gerenciar",
    },
    {
      id_permissao: 15,
      id_menu: 7,
      name: "Meus relatórios",
      page_ref: "painel/relatorios",
      icon: RelatoriosSVG,
      tipo: "gerenciar",
    },
    {
      id_permissao: 16,
      id_menu: 2,
      name: "Meus produtos",
      page_ref: "painel/produtos",
      icon: ProdutosSVG,
      tipo: "configurar",
    },
    {
      id_permissao: 14,
      id_menu: 3,
      name: "Meus catálogos",
      page_ref: "painel/catalogos",
      icon: CatalogoSVG,
      tipo: "configurar",
    },
  ].filter((pagina) => permissoesUsuario.includes(pagina.id_permissao));

  const [mostrarPopUp, setMostrarPopUp] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const init = async () => {
      const temProdutos = await apiConf.verificar_step_criacao();
      if (temProdutos?.produtos) {
        setTemProdutos(true);
      } else {
        setTemProdutos(false);
      }
    };

    init();
    
  }, []);
  return (
    <>
      <ViewHome theme={theme} paginas={paginas.length + 1}>
        {loading || loadingPermissoes ? (
          <SkeletonHome />
        ) : (
          <>
            <div className="tituloHome hideDesktop">Gerenciar e Configurar</div>
            <div className="tituloHome hideMobile">Gerenciar</div>
            <div className="menus-container">
              <div className="menus">
                {paginas
                  .filter((pagina) => pagina.tipo === "gerenciar")
                  .map((pagina, index) => (
                    <>
                      <Link
                        to={pagina.page_ref}
                        key={pagina.id_menu}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="menu">
                          <div className="image-container">
                            {React.createElement(pagina.icon)}
                          </div>
                          <div className="nomeMenu">
                            <div className="nome">{pagina.name}</div>
                          </div>
                        </div>
                      </Link>
                    </>
                  ))}
              </div>
              <div className="tituloHome hideMobile">Configurar</div>
              <div className="menus">
                {paginas
                  .filter((pagina) => pagina.tipo === "configurar")
                  .map((pagina, index) => (
                    <>
                      <Link
                        to={pagina.page_ref}
                        key={pagina.id_menu}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="menu">
                          <div className="image-container">
                            {React.createElement(pagina.icon)}
                          </div>
                          <div className="nomeMenu">
                            <div className="nome">{pagina.name}</div>
                          </div>
                        </div>
                      </Link>
                    </>
                  ))}
                {permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
                  <div
                    className="menuCriacao"
                    onClick={() => setModalCriacao(!modalCriacao)}
                  >
                    <div className="nomeMenuCriacao">
                      <div className="nomeCriacao">Guia de criação</div>
                    </div>
                    <div className="guiaDeCriacaoContainer">
                      {theme.mode == "light" ? (
                        <>
                          <img
                            className="guiaDeCriacaoImagem hideMobile"
                            src={
                              require(
                                `../../assets/newImages/home/IconeGuia.svg`
                              ).default
                            }
                          />
                          <img
                            className="guiaDeCriacaoImagem hideDesktop"
                            src={
                              require(
                                `../../assets/newImages/home/IconeGuiaMobile.svg`
                              ).default
                            }
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="guiaDeCriacaoImagem hideMobile"
                            src={
                              require(
                                `../../assets/newImages/home/IconeGuia_dark.svg`
                              ).default
                            }
                          />
                          <img
                            className="guiaDeCriacaoImagem hideDesktop"
                            src={
                              require(
                                `../../assets/newImages/home/IconeGuiaMobile_dark.svg`
                              ).default
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="tituloHome">Novidades</div>
            <div className="novidades">
              <div className="novidade">
                <img
                  src={require(
                    `../../assets/newImages/home/novidades/1new${
                      theme.mode == "dark" ? "dark" : ""
                    }.jpg`
                  )}
                  alt=""
                />
              </div>
              <Link className="novidade" to="/novidades">
                <img
                  src={require(
                    `../../assets/newImages/home/novidades/2${
                      theme.mode == "dark" ? "dark" : ""
                    }.jpg`
                  )}
                  alt=""
                />
              </Link>
              <Link className="novidade ultima" to="/novidades">
                <img
                  src={require(
                    `../../assets/newImages/home/novidades/3${
                      theme.mode == "dark" ? "dark" : ""
                    }.jpg`
                  )}
                  alt=""
                />
              </Link>
            </div>
            {empresa?.catalogo_principal &&
              temProdutos &&
              permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
                <div
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `${empresa?.dominio_https}${empresa?.catalogo_principal}${staginEnv()}`
                      )
                      .then(
                        (res) => {
                          Swal.fire({
                            title: "Link copiado com sucesso!",
                            text: "Agora você pode compartilhar o link do seu catálogo principal com seus clientes.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        },
                        (err) => {
                          console.log("Erro ao copiar link", err);
                          Swal.fire({
                            title: "Erro ao copiar link!",
                            text:
                              "Mas você ainda pode copiar o link do seu catálogo principal manualmente. O link é: " +
                              `${empresa.dominio_https}${empresa.catalogo_principal}`,
                            icon: "error",
                            confirmButtonText: "Ok",
                          });
                        }
                      );
                  }}
                  className={`botaoCompartilhar hideMobile`}
                >
                  {permissoesUsuario.includes(PERMISSOES.USUARIOS_GERENTE) && (
                    <>
                      {" "}
                      <UilLinkH className="icone"></UilLinkH> Compartilhar
                      catálogo principal da conta
                    </>
                  )}
                </div>
              )}
          </>
        )}
      </ViewHome>
    </>
  );
};

export default Home;
