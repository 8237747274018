import React, {
  FunctionComponent,
  LazyExoticComponent,
  SVGProps,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import debounce from "lodash/debounce";

import StyledInputComponents from "./styles";
import PopoverForm from "../PopoverForm/PopoverForm";
import { useTheme, ThemeProvider } from "../../contexts/Theme/ThemeContext";
import { ReactComponent as SearchSVG } from "../../assets/newImages/icons/search.svg";
import { ReactComponent as EyeSvg } from "../../assets/newImages/icons/olho-de-tandera.svg";

export interface InputMask {
  param?(value: any, param: any): unknown;
  mask: (value: string, param?: any) => string;
  unmask?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => string;
  maskValidation?: (value: string, inputElement: HTMLInputElement) => boolean;
}
export interface InputProps {
  type: string;
  value?: string;
  placeholder: string;
  theme?: any;
  onChange: (
    newValue: string,
    name?: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  name?: string;
  error?: string;
  label?: string;
  alignLabel?: "left" | "center" | "right";
  sizeLabel?: "small" | "medium" | "large";
  disabled?: boolean;
  icone?: string;
  labelIcon?: React.ReactNode;
  maxLength?: number;
  minLength?: number;
  showMaxLength?: boolean;
  pattern?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  mask?: InputMask;
  required?: boolean;
  checked?: boolean;
  id?: string;
  isValid?: {
    isValidFunction: (value: string) => boolean | Promise<boolean>;
    dependencies: any[];
  };
  invalidText?: string;
  disabledCheckbox?: boolean;
  max?: number | string;
  min?: number | string;
  debounceTime?: number;
  ignoreValidations?: boolean;
  allowEmojis?: boolean;
  className?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  step?: number | string;
  onDebounce?: (value: string) => void;
  allowEye?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const withThemeInput = (Component) => (props: InputProps) => {
  // fazer esse HOC pra poder usar o theme como prop pro caso de usar o input fora do contexto de ThemeProvider,
  // como no caso do SweetAlert2
  let theme;
  try {
    const { theme: contextTheme } = useTheme();
    theme = contextTheme;
  } catch {
    theme = props.theme;
  }

  return <Component {...props} theme={theme} />;
};

const InputComponent: React.FC<InputProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  theme,
  placeholder,
  error,
  disabled = false,
  icone,
  maxLength,
  pattern,
  onKeyDown,
  alignLabel,
  sizeLabel,
  showMaxLength = true,
  mask,
  required,
  isValid,
  invalidText,
  debounceTime,
  minLength,
  disabledCheckbox,
  onDebounce,
  onFocus,
  checked,
  id,
  ignoreValidations,
  allowEmojis = false,
  max,
  className,
  min,
  labelIcon,
  onBlur,
  step,
  allowEye = false,
}) => {
  let fontLabel = sizesLabel();
  const [errorMessage, setErrorMessage] = useState("Campo inválido");
  const [disabledInput, setDisabledInput] = useState(disabled);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inputType, setInputType] = useState(type);

  function sizesLabel() {
    switch (sizeLabel) {
      case "small":
        return "1.5rem";
      case "medium":
        return "2.5rem";
      case "large":
        return "3rem";
      default:
        return "2.5rem";
    }
  }

  const applyMask = (value) => {
    if (typeof value === "number") return value;

    if (!value) return value;

    if (!allowEmojis && value)
      value = value.toString().replace(/[\uD800-\uDFFF]./g, "");
    if (mask && mask.mask) {
      return mask.mask(value, mask.param);
    } else {
      return value;
    }
  };

  const handleValidations = (
    event,
    unmaskedValue,
    inputElement: HTMLInputElement
  ) => {
    if (!ignoreValidations) {
      if (mask && mask.maskValidation) {
        if (!mask.maskValidation(unmaskedValue, inputElement)) {
          setErrorMessage(inputElement.validationMessage);
          return;
        }
      }
      if (unmaskedValue !== undefined) {
        if (
          value &&
          maxLength &&
          inputRef.current &&
          value.length > maxLength
        ) {
          inputElement.setCustomValidity("Máximo de caracteres excedido");
          setErrorMessage("Máximo de caracteres excedido");
          return;
        }

        if (
          value &&
          minLength &&
          inputRef.current &&
          value.length < minLength
        ) {
          inputElement.setCustomValidity("Mínimo de caracteres não atingido");
          setErrorMessage("Mínimo de caracteres não atingido");
          return;
        }
      }

      if (unmaskedValue !== undefined && isValid) {
        if (!isValid.isValidFunction(unmaskedValue)) {
          inputElement.setCustomValidity(
            invalidText ? invalidText : "Preencha este campo"
          );
          setErrorMessage(invalidText ? invalidText : "Preencha este campo");
          return;
        }
      }
    }

    inputElement.setCustomValidity("");
    setErrorMessage("Campo inválido");
  };

  const debouncedOnChange = useMemo(
    () =>
      debounce((unmaskedValue) => {
        onDebounce && debounceTime && onDebounce(unmaskedValue);
      }, debounceTime),
    [debounceTime]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let unmaskedValue = event.target.value;

    //if input type is number, remove all non numeric characters
    if (event.target.type === "number") {
      unmaskedValue = unmaskedValue.replace(/[^\d.]/g, "");
      //handling max value also
      if (max) {
        if (Number.parseInt(unmaskedValue) > Number(max)) {
          unmaskedValue = unmaskedValue
            .toString()
            .substring(0, unmaskedValue.toString().length - 1);
        }
      }
    }
    //remove emojis
    if (!allowEmojis)
      unmaskedValue = unmaskedValue.replace(/[\uD800-\uDFFF]./g, "");

    if (mask) unmaskedValue = mask.mask(unmaskedValue);
    if (mask && mask.unmask) {
      unmaskedValue = mask.unmask(unmaskedValue, event);
    }

    name ? onChange(unmaskedValue, name, event) : onChange(unmaskedValue);
    onDebounce && debounceTime && debouncedOnChange(unmaskedValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  useEffect(() => {
    setDisabledInput(disabled);
  }, [disabled]);

  useEffect(() => {
    if (invalidText) {
      setErrorMessage(invalidText);
    }
  }, [invalidText]);

  useEffect(() => {
    if (inputRef.current) {
      handleValidations(null, inputRef.current.value, inputRef.current);
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  }, [value, required, ignoreValidations, isValid?.dependencies]);

  useEffect(() => {
    if (value && inputRef.current) {
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  }, []);

  return (
    <>
      <StyledInputComponents theme={theme}>
        <label
          className={`${type !== "radio" && "inputComponentContainer"} ${
            type === "radio" && "input-radio"
          } ${disabledInput && "disabledInput"}`}
        >
          {label && (
            <div
              className={`label ${alignLabel && alignLabel}`}
              style={{ fontSize: fontLabel }}
            >
              {disabledCheckbox && (
                <input
                  type="checkbox"
                  checked={!disabledInput}
                  onChange={(e) => {
                    setDisabledInput(!e.target.checked);
                  }}
                />
              )}
              {label}
              {labelIcon && labelIcon}
            </div>
          )}
          <div
            className={`input ${(icone || type === "password") && "comIcone"}`}
          >
            <input
              type={inputType}
              name={name}
              step={step}
              className={`inputText ${className}`}
              value={applyMask(value)}
              checked={checked}
              onChange={handleInputChange}
              placeholder={placeholder}
              disabled={disabledInput}
              onBlur={onBlur}
              onFocus={onFocus}
              maxLength={maxLength}
              pattern={pattern}
              style={{
                paddingRight: maxLength && showMaxLength ? "12rem" : "",
              }}
              onKeyDown={handleKeyDown}
              id={id}
              max={max}
              min={min}
              required={ignoreValidations ? false : required}
              minLength={minLength}
              ref={inputRef}
            />
            <PopoverForm mensagem={errorMessage} />
            {icone && (
              <div className="iconeContainer">
                <SearchSVG className="icone" />
              </div>
            )}
            {type === "password" && (
              <div
                className="iconeContainer"
                onClick={() => {
                  inputType === "password"
                    ? setInputType("text")
                    : setInputType("password");
                }}
              >
                <Suspense fallback={<div></div>}>
                  {allowEye ? <EyeSvg className="icone" /> : <></>}
                </Suspense>
              </div>
            )}
            {maxLength && value && showMaxLength && (
              <div
                className="maxLenght"
                style={{
                  color: value.length > maxLength ? "red" : "",
                }}
              >
                {value && `${value.length}/${maxLength}`}
              </div>
            )}
            {error && <span className="error">{error}</span>}
          </div>
        </label>
      </StyledInputComponents>
    </>
  );
};

export const Input = withThemeInput(InputComponent);
