import styled from "styled-components";

export const ContainerEntregaRetirada = styled.div`
  margin-top: 5rem;
  width: 100rem;
  max-width: 90vw;

  .entrega,
  .retirada {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > .titulo {
      color: ${(props) => props.theme.colors.brandPrimary};
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .entrega {
    > .containerToggleConfigurar {
      position: relative;
      padding: 1rem 0;
      justify-content: center;
      color: ${(props) => props.theme.colors.brandSecondary};

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 70%;
          height: 2px;
          background-color: ${(props) => props.theme.colors.elements};
          border-radius: 50px;
        }
      }
    }
  }

  .retirada {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

// Add other styled components for the nested elements if necessary
