import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { SkeletonCadastroProdutoContainer } from "./styles";

interface SkeletonCadastroProdutoProps {}

const SkeletonCadastroProduto: React.FC<SkeletonCadastroProdutoProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      <SkeletonCadastroProdutoContainer 
        theme={theme}
      >
        <div className="grid1">
          {Array(6)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  style={
                    {
                      "--i": `${index}`,
                      gridArea: `zika${index}`,
                    } as React.CSSProperties
                  }
                  key={index}
                  className="item-skeleton"
                />
              );
            })}
        </div>
      </SkeletonCadastroProdutoContainer>
    </>
  );
};

export default SkeletonCadastroProduto;
