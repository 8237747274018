import React, { useEffect, useState } from "react";
import "./styles";
import { StepsCriacaoContainer } from "./styles";
import { configuracoesApi } from "../../hooks/configuracoesApi";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { ReactComponent as Check } from "../../assets/newImages/header/check.svg";
import SkeletonGuiaCriacao from "../Skeletons/SkeletonGuiaCriacao/SkeletonGuiaCriacao";
import theme from "../../Themes/light";
import { getTextOfJSDocComment } from "typescript";
import { Link, useNavigate, useNavigation } from "react-router-dom";

interface StepsCriacaoProps {
  handleClose?: (e) => void;
}

const StepsCriacao: React.FC<StepsCriacaoProps> = ({ handleClose }) => {
  const api = configuracoesApi();

  const navigate = useNavigate();

  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);

  const labels: { [key: string]: { label: string; link: string } } = {
    empresa: {
      label: "Sua empresa",
      link: "painel/configuracoes/informacoesdamarca",
    },
    entrega_retirada: {
      label: "Entrega e retirada",
      link: "painel/configuracoes/correios",
    },
    pagamento: {
      label: "Pagamento",
      link: "painel/configuracoes/pagamentoonline",
    },
    catalogo: { label: "Configure seu catálogo", link: "painel/catalogos" },
    produtos: { label: "Adicione seus produtos", link: "painel/produtos" },
  };

  const [steps, setSteps] = useState<{
    empresa: boolean;
    entrega_retirada: boolean;
    pagamento: boolean;
    catalogo: boolean;
    produtos: boolean;
  }>({
    empresa: false,
    entrega_retirada: false,
    pagamento: false,
    catalogo: false,
    produtos: false,
  });

  const [porcentagemConcluida, setPorcentagemConcluida] = useState(0);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const response = await api.verificar_step_criacao();
      const porcentagem =
        Object.values(response!).filter((value) => value === true).length * 20;
      setPorcentagemConcluida(porcentagem);
      setSteps(response!);
      setLoading(false);
    };
    init();
  }, []);

  if (loading) return <SkeletonGuiaCriacao />;

  return (
    <StepsCriacaoContainer theme={theme}>
      <div className="subtitulo">
        Sua empresa está {porcentagemConcluida}% concluída!
      </div>
      <div className="bolinhas">
        {Object.keys(steps).map((step, index) => {
          return (
            <Link
              to={labels[step].link}
              className="bolinha-wrapper"
              onClick={handleClose}
            >
              <div
                className={`bolinha-container ${
                  steps[step] ? "concluida" : ""
                }`}
              >
                {steps[step] && <Check className="check" />}
              </div>
              <div className={`label ${steps[step] ? "concluido" : ""}`}>
                {labels[step].label}
              </div>
            </Link>
          );
        })}
      </div>
    </StepsCriacaoContainer>
  );
};

export default StepsCriacao;
