import { api } from "./useApi";

export const PerfilApi = () => ({
  usuarios: async () => {
    let response = await api.post("/usuario/todos");
    if (response.data) {
      response.data = response.data.map((item: any) => {
        item.tipo ? item.tipo = item.tipo.toString() : item.tipo = "";
        return item;
      });
    }
    return response.data;
  },
  confereSenha: async (senha: string) => {
    let response = await api.post("/usuario/confere_senha", { senha });
    return response.data;
  },
  salvarUsuario: async (usuario: object) => {
    let response = await api.post("/usuario/criar", { usuario });
    return response.data;
  },
  verificaNomeUsuario: async (usuario: string) => {
    let response = await api.post("/usuario/verifica_usuario_cadastro", {
      usuario,
    });
    return response.data;
  },
  verificaPeriodicidade: async () => {
    let response = await api.post("/empresa/periodicidade");
    return response.data;
  },
  verificaLimiteLicenca: async () => {
    let response = await api.post("/usuario/verificar-limite-licencas");
    return response.data;
  },
  removerUsuario: async (usuario: number) => {
    let response = await api.post("/usuario/remover", { usuario });
    return response.data;
  },
  habilitarUsuarioSuporte: async () => {
    let response = await api.post("/usuario/habilitar_usuario_suporte");
    return response.data;
  },
  desabilitarUsuarioSuporte: async () => {
    let response = await api.post("/usuario/desabilitar_usuario_suporte");
    return response.data;
  },
  verificaUsuarioSuporte: async () => {
    let response = await api.post("/usuario/verifica_usuario_suporte");
    return response.data;
  },
});
