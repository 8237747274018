import React from "react";
import SkeletonRelatoriosContainer from "./styles.ts";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";

interface SkeletonRelatoriosProps {}

const SkeletonRelatorios: React.FC<SkeletonRelatoriosProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <SkeletonRelatoriosContainer theme={theme}>
      {Array(9)
        .fill(0)
        .map((_, index) => {
          return (
            <div
              className="skeleton-relatorios-item"
              style={{ "--i": `${index}` } as React.CSSProperties}
              key={index}
            />
          );
        })}
    </SkeletonRelatoriosContainer>
  );
};

export default SkeletonRelatorios;
