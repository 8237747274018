import React, { useState, useRef, ChangeEventHandler } from "react";
import ContainerToggleConfigurar from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

import { Toggle } from "../Toggle";

interface ToggleConfigurarProps {
  nome?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  onConfigurar?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  required?: boolean;
  isValid?: boolean | ((value: boolean) => boolean);
  ignoreValidations?: boolean;
  invalidText?: string;
}

export const ToggleConfigurar: React.FC<ToggleConfigurarProps> = ({
  nome,
  value,
  onChange,
  onConfigurar,
  disabled,
  required,
  isValid,
  invalidText,
  ignoreValidations,
}) => {
  const { theme } = useTheme();

  const handleConfigurar = (e) => {
    if (value && onConfigurar) {
      onConfigurar(e);
    } else {
      onChange(!value);
    }
  };

  return (
    <>
      <ContainerToggleConfigurar
        className="containerToggleConfigurar"
        theme={theme}
      >
        {nome && <div className="nome">{nome}</div>}
        <Toggle
          isValid={isValid}
          invalidText={invalidText}
          onChange={onChange}
          value={value}
          disabled={disabled}
          required={required}
          ignoreValidations={ignoreValidations}
        />
        <div
          className={`configurar ${value && "ativo"} ${
            !onConfigurar && "hidden"
          }`}
          onClick={handleConfigurar}
        >
          Configurar
        </div>
      </ContainerToggleConfigurar>
    </>
  );
};
