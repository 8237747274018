import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const SliderContainer = styled.div<{theme: ThemeType}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    align-items: center;
    .range-slider{
        height: 1rem;
        background: ${({theme}) => theme.colors.brandPrimary};
    }
    .range-slider__thumb{
        width: 3rem;
        height: 3rem;
        background: ${({theme}) => theme.colors.brandPrimary};
    }
    .range-slider__range{
        display: none;
    }
    .input-slider-container{
        font-size: 2.5rem;
        color: ${({theme}) => theme.colors.brandSecondary};
        display: flex;
        align-items: center;
        width: 100%;
        .input-wrapper{
            .value{
                border: none;
                background: none;
                padding: 0;
                margin: 0;
                font-size: inherit;
                font-size: 2.5rem;
                &:focus{
                    outline: none;
                    background: none;
                }
            }
        }
    }
    .min-value{
        justify-content: flex-start;
    }
    .max-value{
        justify-content: flex-end;
        .input-wrapper{
            .value{
                text-align: right;
            }
        }
    }
`;

export { 
    SliderContainer,
};