import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import Swal from "sweetalert2";
import { Button } from "../../components/Button/index.tsx";
import { AuthContext } from "../../contexts/Auth/AuthContext.tsx";
import { ViewLogin } from "./styles.ts";

const Login: React.FC = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pagLogin, setPagLogin] = useState(true);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email && password) {
      const data = await auth.signin(email, password);
      if (data && data === "LOGADO") {
        await auth.validateToken();
        localStorage.removeItem("produtosSettings");
        navigate("/");
      } else {
        const text = (() => {
          switch (data) {
            case "VERSAO_ANTIGA":
              return "Empresa na versão antiga!";
            case "LOGIN_FALHOU":
              return "Usuário ou senha inválidos!";
            case "TOO_MANY_REQUESTS":
              return "Muitas tentativas de login, tente novamente em alguns minutos!";
            default:
              return "Erro inesperado ao fazer login!";
          }
        })();
        
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: text,
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (Boolean(localStorage.getItem("caiu_sessao")) && !Swal.isVisible()) {
        Swal.fire({
          title: "Sua sessão expirou!",
          text: "Por favor, faça login novamente.",
          icon: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          localStorage.removeItem("caiu_sessao");
        });
      }
    }, 1500);
  }, []);

  return (
    <ViewLogin>
      <div className="row">
        <div className="col-xs-12 col-md-offset-8 col-md-4">
          <div className="texto">
            <br /> Seu acesso para<br></br>vender mais online<br></br>começa
            aqui!
          </div>
        </div>
        <div className=" col-xs-offset-1 col-xs-10 col-md-offset-8 col-md-4 col-sm-offset-2 col-sm-8 ">
          <div className="login">
            <div className="form-group logo">
              <img
                src={require(`../../assets/images/Icone12-01.svg`).default}
                className="img-responsive center-block logo-svg"
                title="Logo login"
              ></img>
            </div>
            {pagLogin && (
              <Form onSubmit={handleLogin}>
                <FormGroup className="form-group">
                  <Input
                    type="text"
                    placeholder="Usuário"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    required
                    autoComplete="off"
                    autoFocus
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    required
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Button
                    className="login-button"
                    type="submit"
                    disabled={!email || !password}
                    title="Entrar"
                  ></Button>
                </FormGroup>
                <FormGroup className="form-group">
                  <div 
                    className="esqueciSenha" 
                    onClick={() => window.open('https://api.whatsapp.com/send?phone=555434434563&text=Ol%C3%A1,%20gostaria%20de%20recuperar%20minha%20senha')}>
                    Esqueceu sua senha?
                  </div>
                </FormGroup>
              </Form>
            )}
          </div>
        </div>
      </div>
    </ViewLogin>
  );
};

export default Login;
