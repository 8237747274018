import styled from "styled-components";

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 3rem;
  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  .voltar {
    display: inline-flex;
    height: 100%;
    align-items: center;
    color: ${(props) =>
      props.theme.mode == "light"
        ? props.theme.colors.brandPrimary
        : props.theme.colors.brandSecondary};
    font-size: 2rem;
    border: 1px solid ${(props) => props.theme.colors.brandSecondary};
    border-radius: 50px;
    gap: 2rem;
    padding: 0 2rem;
    margin-right: 1rem;
    font-weight: normal;
    text-decoration: none;

    .arrow {
      height: 100%;
      font-weight: bold;
      stroke: ${(props) => props.theme.colors.brandSecondary};
    }
  }
  .saudacao {
    color: ${(props) =>
      props.theme.mode == "light"
        ? props.theme.colors.brandPrimary
        : props.theme.colors.brandSecondary};
    > span {
      font-weight: bold;
    }
  }
  .palavra {
    .barra {
      padding: 0px 2rem;
      font-size: 3.5rem;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  .laranja {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;

    a {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${(props) => props.theme.colors.titleColor};
      font-weight: bold;
    }
  }

  .verde {
    a {
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  .bold {
    a {
      font-weight: bold;
    }
  }
  @media (max-width: 1024px) {
    padding: 0 5rem;
    .verde {
      &:not(:nth-last-child(2)) {
        display: none;
      }
    }
    .palavra {
      .barra {
        padding: 0px 1rem;
      }
    }
  }
`;
