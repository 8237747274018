import React, { useContext } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { MockupCatalogo } from "../../Mockups/MockupCatalogo/MockupCatalogo";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Select } from "../../Select";
import { Toggle } from "../../Toggle/Toggle";
import { ContainerVisualVariacoesContainer } from "./styles";

interface VisualVariacoesProps {
  configIn: "empresa" | "configuracoes";
}

const VisualVariacoes: React.FC<VisualVariacoesProps> = ({ configIn }) => {
  const { theme } = useTheme();

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const qtdVariacoes = [
    { name: "1 variações", value: "1" },
    { name: "2 variações", value: "2" },
    { name: "3 variações", value: "3" },
    { name: "4 variações", value: "4" },
    { name: "5 variações", value: "5" },
    { name: "6 variações", value: "6" },
    { name: "7 variações", value: "7" },
    { name: "8 variações", value: "8" },
    { name: "9 variações", value: "9" },
    { name: "10 variações", value: "10" },
    { name: "Todas", value: "999" },
  ];

  return (
    <>
      <ContainerVisualVariacoesContainer configIn={configIn} theme={theme}>
        <div className="informacoes">
          {configIn === "empresa" && (
            <Titulo
              titulo="Visual"
              subtitulo="Configure seu catálogo"
              justifyContent="flex-start"
            />
          )}
          <div className="subtitulo">Quantidade de variações visíveis</div>
          <Select
            label="Escolha a quantidade mostrada por padrão nos catálogos"
            sizeLabel="medium"
            options={qtdVariacoes}
            value={configuracoes.catalogo.limite_variacoes.toString()}
            placeholder="4 variações"
            required={true}
            onChange={(newValue) => {
              setConfiguracoes({
                ...configuracoes,
                catalogo: {
                  ...configuracoes.catalogo,
                  limite_variacoes: newValue,
                },
              });
            }}
          ></Select>
          <label>
            <div className="toggleContainer">
              <Toggle
                value={configuracoes.catalogo.mostrar_estoque}
                onChange={(newValue) => {
                  setConfiguracoes({
                    ...configuracoes,
                    catalogo: {
                      ...configuracoes.catalogo,
                      mostrar_estoque: newValue,
                    },
                  });
                }}
              ></Toggle>
              Mostrar estoque dos produtos
            </div>
          </label>
        </div>
        <div className="mockup-container">
          <MockupCatalogo
            menu={false}
            banner={false}
            informacoesVariacoes={true}
            configuracoes={configuracoes}
          />
        </div>
      </ContainerVisualVariacoesContainer>
    </>
  );
};

export default VisualVariacoes;
