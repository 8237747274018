import React, { useContext } from 'react';
import { ProdutosPlanilhaContext } from '../../../../contexts/ProdutosPlanilha/ProdutosPlanilhaContext';
import Swal from 'sweetalert2';
import { Toggle } from '../../../Toggle/Toggle';
import { useTheme } from '../../../../contexts/Theme/ThemeContext';
import { ConfiguracoesAvancadasContainer, ModalBackdropContainer, Botoes } from './styles';
import Titulo from '../../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo.tsx';
import { ReactComponent as Voltar } from "../../../../assets/newImages/icons/angle-down.svg"
import { Button } from '../../../Button/index.tsx';

interface ConfiguracoesAvancadasProps {
  onClose: () => void;

}

const ConfiguracoesAvancadas: React.FC<ConfiguracoesAvancadasProps> = ({ onClose }) => {
  const { removerProdutos, setRemoverProdutos } = useContext(ProdutosPlanilhaContext);
  const {theme} = useTheme();

  const handleToggleChange = (newValue: boolean) => {
    if (newValue) {
      Swal.fire({
        title: "Tem certeza?",
        text: "Ao habilitar essa opção, todos os produtos da conta cadastrados anteriormente serão excluídos.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Remover Produtos",
        customClass: {
          confirmButton: 'confirm-class',
          cancelButton: 'cancel-class'
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setRemoverProdutos(newValue);
        } else {
          setRemoverProdutos(false);
        }
      });
    } else {
      setRemoverProdutos(newValue);
    }
  };
  
  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalBackdropContainer onClick={handleBackdropClick}>
      <ConfiguracoesAvancadasContainer theme={theme}>
          <Titulo
              titulo="Remover produtos"
              subtitulo="Atenção, essa ação é irreversivel."
              flexDirection="column-reverse"

          />
          <div className="remover-produtos">
              <label>
              <Toggle
                  value={removerProdutos}
                  onChange={handleToggleChange}
              />
              Remover produtos existentes
              </label>
              <p className='texto'>
              <span>
                  Se habilitada essa opção, serão excluídos todos os<br /> produtos da conta cadastrados anteriormente.
              </span>
              <br />
              Após a remoção, os produtos que estão no arquivo de<br /> importação
              serão cadastrados.
              </p>
          </div>
          <Botoes theme={theme}>
            <div
            className='cancelar'
            title='Voltar'
            onClick={onClose}
            >
            <Voltar /> Voltar
            </div>
          </Botoes>
          
      </ConfiguracoesAvancadasContainer>
    </ModalBackdropContainer>
  );
};

export default ConfiguracoesAvancadas;
