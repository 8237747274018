import ContainerCatalogosListagemSkeleton from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonCatalogosProps {}

const SkeletonCatalogos: React.FC<SkeletonCatalogosProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <>
      {Array(10)
        .fill(0)
        .map((_, index) => {
          return (
            <ContainerCatalogosListagemSkeleton
              style={{ "--i": `${index}` } as React.CSSProperties}
              key={index}
              theme={theme}
            />
          );
        })}
    </>
  );
};

export default SkeletonCatalogos;
