import { useContext } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Select } from "../../Select";
import { Toggle } from "../../Toggle/Toggle";
import { ContainerVisualContainer } from "./style";

interface VisualProps {
  configIn: "empresa" | "configuracoes";
}

export const Visual: React.FC<VisualProps> = ({ configIn }) => {
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const valuesProdutos = [
    {
      nome: "Menor preço",
      chave: "produto_variacoes[0].variacao_preco",
      inverso: false,
    },
    {
      nome: "Maior preço",
      chave: "produto_variacoes[0].variacao_preco",
      inverso: true,
    },
    { nome: "Mais novos", chave: "produto_data_publicacao", inverso: true },
    { nome: "Mais antigos", chave: "produto_data_publicacao", inverso: false },
    { nome: "Nome A-Z", chave: "produto_nome", inverso: false },
    { nome: "Nome Z-A", chave: "produto_nome", inverso: true },
    { nome: "Descrição A-Z", chave: "produto_descricao", inverso: false },
    { nome: "Descrição Z-A", chave: "produto_descricao", inverso: true },
    { nome: "SKU A-Z", chave: "produto_referencia", inverso: false },
    { nome: "SKU Z-A", chave: "produto_referencia", inverso: true },
  ];

  const valuesVariacoes = [
    { nome: "Por ordem de cadastro", chave: "variacao_id", inverso: false },
    { nome: "Variação A-Z", chave: "variacao_descricao", inverso: false },
    { nome: "Variação Z-A", chave: "variacao_descricao", inverso: true },
    {
      nome: "Menor preço",
      chave: "variacao_preco_sem_desconto",
      inverso: false,
    },
    {
      nome: "Maior preço",
      chave: "variacao_preco_sem_desconto",
      inverso: true,
    },
  ];

  const ordensProdutos = [
    { name: "Menor preço", value: "Menor preço" },
    { name: "Maior preço", value: "Maior preço" },
    { name: "Mais novos", value: "Mais novos" },
    { name: "Mais antigos", value: "Mais antigos" },
    { name: "Nome A-Z", value: "Nome A-Z" },
    { name: "Nome Z-A", value: "Nome Z-A" },
    { name: "Descrição A-Z", value: "Descrição A-Z" },
    { name: "Descrição Z-A", value: "Descrição Z-A" },
    { name: "SKU A-Z", value: "SKU A-Z" },
    { name: "SKU Z-A", value: "SKU Z-A" },
  ];

  const ordensVariacoes = [
    { name: "Por ordem de cadastro", value: "Por ordem de cadastro" },
    { name: "Variação A-Z", value: "Variação A-Z" },
    { name: "Variação Z-A", value: "Variação Z-A" },
    { name: "Menor preço", value: "Menor preço" },
    { name: "Maior preço", value: "Maior preço" },
  ];

  const { theme } = useTheme();

  return (
    <>
      <ContainerVisualContainer configIn={configIn} theme={theme}>
        {configIn === "empresa" && (
          <Titulo titulo="Visual" subtitulo={"Configure seu catálogo"} />
        )}
        <div className="informacoes">
          <div className="subtitulo">Ordenações no catálogo</div>
          <Select
            label="Ordenação dos produtos"
            sizeLabel="medium"
            placeholder="Nome A-Z"
            options={ordensProdutos}
            value={configuracoes.ordenacao_catalogo.nome}
            onChange={(value) => {
              setConfiguracoes({
                ...configuracoes,
                ordenacao_catalogo: valuesProdutos.find(
                  (item) => item.nome === value,
                )!,
              });
            }}
          ></Select>
          <Select
            label="Ordenação das variações"
            sizeLabel="medium"
            placeholder="Por ordem de cadastro"
            options={ordensVariacoes}
            value={configuracoes.ordenacao_catalogo_variacoes.nome}
            onChange={(value) => {
              setConfiguracoes({
                ...configuracoes,
                ordenacao_catalogo_variacoes: valuesVariacoes.find(
                  (item) => item.nome === value,
                )!,
              });
            }}
          ></Select>
        </div>
        <div className="toggleContainer">
          <Toggle
            value={configuracoes.download_fotos}
            onChange={(value) => {
              setConfiguracoes({
                ...configuracoes,
                download_fotos: value,
              });
            }}
          ></Toggle>
          Permitir download de fotos e zoom
        </div>
      </ContainerVisualContainer>
    </>
  );
};
