import { useContext, useEffect, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { ContainerPoliticaDeTrocaeDevolucaoContainer } from "./styles";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import ReactQuill from "react-quill";
import { Input } from "../../Input";

interface PoliticaDeTrocaeDevolucaoProps {
  configIn: "empresa" | "configuracoes";
}

export const PoliticaDeTrocaeDevolucao: React.FC<PoliticaDeTrocaeDevolucaoProps> = ({
  configIn,
}) => {
  const {
    politica_de_troca_e_devolucao,
    setPolitica_de_troca_e_devolucao,
    configuracoes,
  } = useContext(ConfiguracoesContext);
  const [inputAux, setInputAux] = useState(
    (politica_de_troca_e_devolucao || "").replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, "")
  );

  const { theme } = useTheme();

  return (
    <>
      <ContainerPoliticaDeTrocaeDevolucaoContainer
        configIn={configIn}
        theme={theme}
      >
        {configIn === "empresa" && (
          <Titulo
            titulo="Política De Troca e Devolução"
            subtitulo={"Configure seu catálogo"}
          />
        )}
        <div className="texto">
          Deixe claro para seu cliente sua política de troca e devolução. Essa
          política dá segurança para seu cliente na hora da compra, passando
          maior credibilidade de sua marca.
        </div>
        <div className="containerQuill">
          <ReactQuill
            className="quill-editor"
            formats={["bold", "italic", "underline", "list"]}
            value={politica_de_troca_e_devolucao}
            onChange={(newValue) => {
              setPolitica_de_troca_e_devolucao(newValue);
              setInputAux(
                newValue.replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, ""),
              );
            }}
          ></ReactQuill>
          <div className="hidden-input-wrapper">
            <Input
              className="hidden-input"
              invalidText="Preencha este campo"
              isValid={{
                isValidFunction: (value) => {
                  return configuracoes.lgpd.obrigatorio && !value.length
                    ? false
                    : true;
                },
                dependencies: [configuracoes.lgpd.obrigatorio, inputAux],
              }}
              placeholder=""
              onChange={(newValue) => {
                setInputAux(newValue);
              }}
              type="text"
              value={inputAux}
            />
          </div>
        </div>        
      </ContainerPoliticaDeTrocaeDevolucaoContainer>
    </>
  );
};
