import { Catalogo, CatalogoUnico, FiltroCatalogos, FiltroOrdemCatalogos } from "../types/Catalogo";
import { api } from "./useApi";

export const CatalogosApi = () => ({
  getCatalogos: async (
    search: string,
    filter: FiltroCatalogos,
    order: FiltroOrdemCatalogos,
    limit: number,
    offset: number
  ) => {
    let response = await api.post("/catalogo/listagem", {
      search: search,
      filter: filter,
      order: order,
      limit: limit,
      offset: offset,
    });

    return response.data as { catalogos: Catalogo[]; criadores: any[] };
  },

  getCatalogo: async (id_catalogo: string) => {
    let response = (
      await api.post("/catalogo/unico", { id_catalogo: id_catalogo })
    ).data as CatalogoUnico;
    if (typeof response.imagem === "string" || !response.imagem)
      response.imagem = [response.imagem].filter(
        (imagem) => imagem !== "" && imagem !== null,
      );
    if (typeof response.imagem_desktop === "string" || !response.imagem_desktop)
      response.imagem_desktop = [response.imagem_desktop].filter(
        (imagem) => imagem !== "" && imagem !== null,
      );
    if(response.configuracoes === null) response.configuracoes = {adicionarTodosProdutos: true, mostrarPrecoOriginal: false}
    return response;
  },

  salvarCatalogo: async (catalogo: CatalogoUnico) => {
    const {
      outros_usuarios,
      identificacao,
      principal,
      duplicado,
      ...newCatalogo
    } = catalogo;
    const ids_usuarios = outros_usuarios.map((usuario) => usuario.id);

    let response = await api.post("catalogo/criar", {
      catalogo: newCatalogo,
      ids_usuarios: ids_usuarios,
      identificacao: identificacao,
    });
    return response.data;
  },

  removerCatalogo: async (id_catalogo: string) => {
    let response = await api.post("/catalogo/remover", {
      id_catalogo: id_catalogo,
    });
    return response.data;
  },

  getOutrosUsuarios: async (id_catalogo: string) => {
    let response = (await api.post("/usuario/usuarios_replicar_catalogo")).data;

    return response;
  },

  getCatalogosFiltroPedidos: async () => {
    let response = await api.post("/catalogo/todos_filtro_pedidos");
    return response.data;
  },

  setCatalogoPrincipal: async (id_catalogo: string) => {
    let response = await api.post("/catalogo/atualizarCatalogoPrincipal", {
      id_catalogo: id_catalogo,
    });
    return response.data;
  },
});
