import styled, { css } from "styled-components";
import { ThemeType } from "../../../types/Theme";

const commonStyles = css`
  width: 100rem;
  max-width: 90vw;

  > .containerFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > .texto {
      margin-top: 4rem;
      margin-bottom: 2rem;
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.brandSecondary};
    }

    > .containerToggles {
      margin-bottom: 4rem;
      > .containerToggle {
        position: relative;
        padding: 2rem 0;
        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 40rem;
            height: 2px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-color: ${(props) => props.theme.colors.elements};
            border-radius: 50px;
          }
        }
      }
    }

    .toggles {
      display: flex;
      gap: 2rem;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .containerToggleConfigurar {
        .nome {
          color: ${(props) => props.theme.colors.brandSecondary};
        }
      }
    }
  }
`;

export const ContainerPedidosConfigsContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
  theme: ThemeType;
}>`
  ${commonStyles}
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      > .containerFlex {
        padding: 3rem 0 0 0;
        > .texto {
          width: 100%;
          text-align: left;
          margin: 0;
        }
        .containerToggles {
          width: 100%;
          > .containerToggle {
            width: 100%;
            padding: 0;
            &::after {
              display: none;
            }
            .containerToggleConfigurar {
              width: 100%;
              .nome {
                width: 100%;
                color: ${(props) => props.theme.colors.brandSecondary};
                font-weight: bold;
              }
            }
            .hidden {
              display: block !important;
              visibility: hidden;
              pointer-events: none;
            }
          }
        }
      }
    `}
`;

// Add other styled components for the nested elements if necessary
