import { useContext, useMemo } from "react";
import { ConfiguracoesContext } from "../../contexts/Configuracoes/ConfiguracoesContext.tsx";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { Node } from "../../types/Node";
import ContainerTree from "./styles.ts";

interface TreeProps {
  nodes: Node[];
  onChange: (node: Node) => void;
  activeNode: string;
  className?: string;
}

const Tree: React.FC<TreeProps> = ({
  nodes,
  activeNode,
  onChange,
  className,
}) => {
  const { theme } = useTheme();

  const { configuracoes } = useContext(ConfiguracoesContext);

  const activeNodeId = useMemo(
    () => activeNode || nodes[0].id,
    [activeNode, nodes]
  );

  const { empresa } = useContext(EmpresaContext);

  return (
    <>
      <ContainerTree className={className} theme={theme}>
        {nodes.map((node) => (
          <div className="node">
            <div
              className="node-name"
              onClick={() =>
                node.children?.length
                  ? onChange(node.children[0])
                  : onChange(node)
              }
            >
              {node.name}
            </div>
            {node.children && (
            <div className="node-children">
                {node.children.map((child) => {
                    return (
                        <div
                            className={`child ${activeNodeId === child.id && "child-active"}`}
                            onClick={() => onChange(child)}
                        >
                            <div className="child-name">{child.name}</div>
                        </div>
                    );
                })}
            </div>
            )}
          </div>
        ))}
      </ContainerTree>
    </>
  );
};

export default Tree;
