import React from "react";
import "./styles";
import { SkeletonCompartilharContainer } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface SkeletonCompartilharProps {}

const SkeletonCompartilhar: React.FC<SkeletonCompartilharProps> = ({}) => {
  const { theme } = useTheme();

  return (
    <SkeletonCompartilharContainer theme={theme}>
      {Array(4)
        .fill(0)
        .map((_, index) => {
          return index === 2 ? (
            <div className="balls">
              {Array(5)
                .fill(0)
                .map((_, index) => {
                  return (
                    <div
                      className="ball"
                      style={{ "--i": `${index}` } as React.CSSProperties}
                      key={index + 4}
                    ></div>
                  );
                })}
            </div>
          ) : (
            <div
              className="skeleton"
              style={{ "--i": `${index}` } as React.CSSProperties}
              key={index}
            />
          );
        })}
    </SkeletonCompartilharContainer>
  );
};

export default SkeletonCompartilhar;
