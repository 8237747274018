import React from "react";
import Sliding from "./styles.ts";

interface SlidingBoxProps {
  children: React.ReactNode;
  active: boolean;
  width?: string;
  direction: "left" | "right" | "top" | "bottom";
}

export const SlidingBox = ({
  children,
  active,
  direction,
  width,
}: SlidingBoxProps) => {
  return (
    <Sliding>
      <div
        className={`${direction} ${active ? "ativo" : ""}`}
        style={{ width: width }}
      >
        {children}
      </div>
    </Sliding>
  );
};
