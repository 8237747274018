import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const PopoverInfoContainer = styled.div<{theme: ThemeType, mensagem: string, style?: React.CSSProperties, className?: string}>`
    margin-left: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    &::after {
        content: "${({mensagem}) => mensagem}";
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        text-align: center;
        z-index: 3;
        bottom: 150%;
        left: 0;
        color: ${(props) => props.theme.colors.brandSecondary};
        background-color: ${(props) => props.theme.colors.elements};
        padding: 1rem;
        white-space: nowrap;
        border-radius: 5rem;
    }
    
    &::before {
        content: "";
        position: absolute;
        pointer-events: none;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        bottom: 140%;
        left: 1rem; 
        background-color: ${(props) => props.theme.colors.elements};
        width: 1rem;
        height: 1rem;
        transform: rotate(45deg);
    }
    
    @media (max-width: 1024px) {
        &::after {
            white-space: normal;
            width: auto;
            min-width: 20rem;
            left: 50%;
            transform: translateX(-50%);
            padding: 2rem;
        }
        &::before {
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
    }
    
    &:hover {
        &::after {
            opacity: 1;
        }
        &::before {
            opacity: 1;
        }
    }
`;

export { 
    PopoverInfoContainer,
};
