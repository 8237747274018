import styled from "styled-components";
import { ThemeType } from "../../../../types/Theme";

export const ParabensContainer = styled.div<{ theme: ThemeType }>`
  padding: 5rem 0 0 0;
  width: 100rem;
  max-width: 90vw;

  .tituloParabens {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.brandPrimary};
    span {
      color: ${({ theme }) => theme.colors.titleColor};
      font-size: 3rem;
    }
  }

  .texto {
    font-size: 2.2rem;
    text-align: justify;
    padding: 3rem 10rem;
    margin-top: 1rem;
    line-height: 1.2;
  }

  @media (max-width: 550px) {
    .texto {
      padding: 4rem;
    }
  }
`;
