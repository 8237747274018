import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const TabelaDePrecoContainer = styled.div<{theme: ThemeType}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .arquivo-container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        .arquivo{
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: "D-DIN-Bold";
            font-size: 2.5rem;
            color: ${({ theme }) => theme.colors.brandSecondary};
        }
        .alterar-arquivo-button{
            padding: 0;
            background-color: ${({ theme }) => theme.colors.elements};
            color: ${({ theme }) => theme.colors.brandSecondary};
        }
        .escolher-arquivo-button{
            padding: 0.5rem;
        }
    }
    .selects{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        width: 100%;
        margin: 4rem 0 0 0;
    }
    .footer-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cancelar {
            color: ${({ theme }) => theme.colors.brandSecondary};
            cursor: pointer;
        }
    }
    .remover-produtos {
        width: 100%;
        margin: 4rem 0 0 0;
        padding: 4rem 0 0 0;
        display: flex;
        gap: 2rem;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            align-self: center;
            width: 80%;
            height: 2px;
            background-color: ${({ theme }) => theme.colors.placeholder};
            border-radius: 10rem;
            top: 0;
        }
        label {
            font-family: "D-DIN-Bold";
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            color: ${({ theme }) => theme.colors.brandSecondary};
        }
        p {
            color: ${({ theme }) => theme.colors.brandSecondary};
            text-align: justify;
            span {
                font-weight: bold;
            }
        }
    }
`;

export {
    TabelaDePrecoContainer
};

