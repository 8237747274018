import styled from "styled-components";
import theme from "../../Themes/light";

const transitionTime = "0.3s";
const ContainerTabs = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;

  .tabs {
    display: grid;
    font-size: 3rem;
    color: ${(props) => props.theme.colors.brandSecondary};
    align-items: center;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${(props) => props.theme.colors.elements};
      border-radius: 50px;
    }

    .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 100%;
      cursor: pointer;

      .tab-name {
        animation: reveal ${transitionTime} ease-in-out;
      }

      &::after {
        content: "";
        position: relative;
        z-index: 1;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        transition: transform ${transitionTime} ease-in-out;
        border-radius: 50px;
      }

      @keyframes reveal {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }

    .active-tab {
      color: ${(props) => props.theme.colors.titleColor};
      font-weight: bold;

      &::after {
        transform: scaleX(1);
        background-color: ${(props) => props.theme.colors.titleColor};
      }
    }
  }
`;

export default ContainerTabs;
