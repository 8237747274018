import React, { useEffect, useRef, useState } from 'react';
import './styles';
import { QuillContainer } from './styles';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import PopoverForm from '../PopoverForm/PopoverForm';

interface QuillProps extends ReactQuillProps{
    className?: string;
    value: string;
    maxLength?: number;
    required?: boolean;
    onChange: (value: string) => void;
}

const Quill: React.FC<QuillProps> = ({
    className,
    value,
    onChange,
    maxLength=undefined,
    required=false,
    ...quillProps
}) => {

   const {theme} = useTheme();

   const textAreaRef = useRef<HTMLTextAreaElement>(null);
   const quillRef = useRef<ReactQuill>(null);
   const [length, setLength] = useState(0);

   const validateTextArea = () => {
        if(textAreaRef.current && quillRef.current){
            let quillText = quillRef.current.getEditor().getText(); 
            textAreaRef.current.setCustomValidity('');
            if(maxLength && length > maxLength){
                console.log("maximo caracteres")
                textAreaRef.current.setCustomValidity('Máximo de caracteres excedido');
                return;
            }
            
            let numberSpaces = (quillText.match(/\n/g) || []).length
            if(numberSpaces >= 7){
                console.log("maximo linhas")
                textAreaRef.current.setCustomValidity('Máximo de linhas excedido');
                return;
            } 
        }
   };

   // setar o length do quill inicialmente
   useEffect(() => {
       if(quillRef.current){
            setLength(quillRef.current.getEditor().getLength());

            if(maxLength){
            }
        }
    }, [maxLength]);

    useEffect(() => {
        validateTextArea();
    }, [length]);

    return (
        <QuillContainer 
            className={className} 
            theme={theme}
            length={length}
            maxLength={maxLength}
        >
            {maxLength && (
                <div className="react-quill-length">
                    {<span>{length}/{maxLength}</span>}
                </div>
            )}
            <ReactQuill
                {...quillProps}
                ref={quillRef}
                className="quill-editor-2"
                value={value}
                onChange={(newContent, delta, source, editor) => {
                    setLength(editor.getLength()-1);
                    onChange(newContent)
                }}
            ></ReactQuill>
            <textarea 
                ref={textAreaRef}
                className='hidden-textarea'
                value={Array(length).fill('a').join('')}
                required={required}
            />
            <PopoverForm 
                mensagem={textAreaRef.current?.validationMessage || 'Campo inválido'}
                position={'70%'}
            />
        </QuillContainer >
    )
}

export default Quill;