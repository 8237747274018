import styled from 'styled-components';
import { ThemeType } from '../../types/Theme';

const PopoverAbsoluteContainer = styled.div<{theme: ThemeType, mensagem: string, width: string|number}>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after{
        content: "${({mensagem}) => mensagem}";
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        text-align: center;
        z-index: 3;
        bottom: 150%;
        width: ${({width}) => width};
        color: ${(props) => props.theme.colors.brandSecondary};
        background-color: ${(props) => props.theme.colors.elements};
        padding: 1rem;
        border-radius: 5rem;
    }
    &::before{
        content: "";
        position: absolute;
        pointer-events: none;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        bottom: 140%;
        background-color: ${(props) => props.theme.colors.elements};
        width: 3rem;
        height: 3rem;
        transform: rotate(45deg);
    }
    @media (max-width: 1024px) {
        &::after{
            width: 40rem;
            right: 0;
            padding: 2rem;

        }
        &::before{
            right: 1rem;
            transform: rotate(10deg);
        }
    }
    &:hover{
        &::after{
            opacity: 1;
        }
        &::before{
            opacity: 1;
        }
    }
`;

export { 
    PopoverAbsoluteContainer,
};