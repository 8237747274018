import styled from "styled-components";
import theme from "../../../Themes/light";

const transitionTime = "1s";
const SkeletonPedidoCestaContainer = styled.div`
  padding: 10rem 10rem;
  width: 100%;

  .flex-container-skeleton {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem min(7vw, 15rem);
    justify-content: space-between;
    width: 100%;
    gap: 2rem;

    .text-skeleton {
      width: 40rem;
      animation: pulsate ${transitionTime} ease-out infinite alternate;
      border-radius: 5px;
      height: 5rem;

      @keyframes pulsate {
        0% {
          background-color: ${(props) => props.theme.colors.skeleton1};
        }

        100% {
          background-color: ${(props) => props.theme.colors.skeleton2};
        }
      }
    }
  }

  .container-skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 40rem;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
    border-radius: 24px;
    margin-top: 5rem;
    padding: 2rem 0;

    @keyframes pulsate {
      0% {
        background-color: ${(props) => props.theme.colors.skeleton1};
      }

      100% {
        background-color: ${(props) => props.theme.colors.skeleton2};
      }
    }
  }

  :nth-child(2n) {
    height: 20rem;
  }

  .linha {
    width: 100%;
    height: 2px;
    border-radius: 50px;
    animation: pulsate ${transitionTime} ease-out infinite alternate;
  }

  @media (max-width: 1024px) {
    padding: 5rem 3rem 12rem 3rem;
  }
`;

export default SkeletonPedidoCestaContainer;
