import axios from "axios";
// Verificar se está em localhost
const hostname = window.location.hostname;

let baseURL;

if (hostname === "localhost" || hostname === "127.0.0.1") {
  baseURL = "http://localhost/conecta-painel-api/api/";
} else if (hostname === "www.painel-dev.conectavenda.com.br") {
  baseURL = "https://painel-api-dev.conectavenda.com.br/";
} else if (hostname === "bs-local.com") {
  baseURL =  "http://bs-local.com/conecta-painel-api/api/";
} else {
  baseURL = "https://painel-api.conectavenda.com.br/";
}

const api = axios.create({
  baseURL: baseURL,
  withCredentials: hostname === "localhost" || hostname === "bs-local.com" ? false : true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    config.headers["conecta-session"] = localStorage.getItem("conecta-session") || "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    if (response.headers["conecta-session"]) {
      localStorage.setItem("conecta-session", response.headers["conecta-session"]);
    }
    return response;
  },
  function (error) {
    if(error.response) {
      if (
        (error.response.data.error === "ACAO_NAO_PERMITIDA" ||
          error.response.data.error === "SESSION_EXPIRADA") &&
        (window.location.pathname !== "/login" && window.location.pathname !== "/" && !Boolean(localStorage.getItem("caiu_sessao")))
      ) {
        localStorage.setItem("caiu_sessao", "true");
      }
    }
    return Promise.reject(error);
  },
);

export { api, baseURL, hostname };

