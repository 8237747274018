import React, { useState, useEffect } from "react";
import "./styles.ts";
import { Input } from "../../../../../components/Input/index.tsx";
import { Button } from "../../../../../components/Button/index.tsx";
import { ReactComponent as Voltar } from "../../../../../assets/newImages/icons/angle-down.svg";
import { categoriasApi } from "../../../../../hooks/categoriasApi.ts";
import { produtosApi } from "../../../../../hooks/produtosApi.ts";
import Form from "../../../../../components/Form/index.tsx";
import { Toggle } from "../../../../../components/Toggle/Toggle.tsx";
import Titulo from "../../../../../components/Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo.tsx";
import { boolean } from "../../../../../services/Functions";
import Modal from "../../../../../components/Modal/Modal.tsx";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Error from "../../../../../assets/jsons/Error.json";
import { ModalReativar } from "./styles.ts";
import {
  CriarCategoriaContainer,
  ContainerEditarListas,
  GridListas,
  Header,
  Lista,
  TituloLista,
  Botoes,
} from "./styles.ts";
import { useTheme } from "../../../../../contexts/Theme/ThemeContext.tsx";
import { noSpecialCharacters } from "../../../../../services/Masks.tsx";
import Swal from "sweetalert2";

interface CategoriaBoxProps {
  setCategoriasBox: (newValue: boolean) => void;
  setNovaCategoria: (newValue: number) => void;
  categorias: any[];
}

export const CategoriaBox: React.FC<CategoriaBoxProps> = ({
  setCategoriasBox,
  categorias,
}) => {
  const { theme } = useTheme();
  const [categoria, setCategoria] = React.useState<string>("");
  const [categoriaIdEditando, setIdCatetegoriaEditando] =
    React.useState<number>(0);
  const [categoriasSelect, setCategoriasSelect] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [adicionandoCategoria, setAdicionandoCategoria] =
    React.useState<boolean>(false);
  const [reativarProdutos, setReativarProdutos] =
    React.useState<boolean>(false);
  const [quantidadeReativando, setQuantidadeReativando] =
    React.useState<number>(0);

  const [modalReativarProdutos, setModalReativarProdutos] =
    React.useState<boolean>(false);

  const handleCriar = async (reativar_produtos = false) => {
    if (categoria.length == 0) return;
    setLoading(true);

    let categoria_encontrada = categoriasSelect.find(
      (categoria_) => categoria_.descricao === categoria
    );

    if (
      categoria_encontrada &&
      categoria_encontrada.descricao === categoria &&
      categoria_encontrada.id === "0"
    ) {
      setAdicionandoCategoria(false);
      setLoading(false);
      setCategoriasSelect(
        categoriasSelect.map((categoria_) => {
          if (categoria_.id === categoria_encontrada.id) {
            categoria_.ativo = 1;
          }
          return categoria_;
        })
      );
      return;
    }

    setLoading(false);

    categoriasApi()
      .verificarNomeCategoria(categoria)
      .then((response) => {
        if (response.pastas_iguais) {
          setQuantidadeReativando(Number(response.quantidades_produtos));
          setModalReativarProdutos(true);
          return;
        } else {
          criarNovaCategoria(categoria);
          setAdicionandoCategoria(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro ao verificar o nome da categoria.",
        });
        setLoading(false);
      });
  };

  function criarNovaCategoria(categoria, reativarProdutos = false) {
    let categorias_cadastradas = categoriasSelect.find(
      (categoria_) => categoria_.descricao === categoria
    );

    if (categorias_cadastradas) {
      categorias_cadastradas.ativo = 1;
      categorias_cadastradas.reativar_produtos = reativarProdutos;
      return;
    }

    if (categoriaIdEditando !== 9999) {
      categoriasSelect[categoriaIdEditando].descricao = categoria;
      categoriasSelect[categoriaIdEditando].ativo = 1;
      categoriasSelect[categoriaIdEditando].reativar_produtos =
        reativarProdutos;
      return;
    }

    const novaCategoria = {
      descricao: categoria,
      ativo: 1,
      id: "0",
      qtd_produtos: quantidadeReativando,
      reativar_produtos: reativarProdutos,
    };

    setCategoriasSelect((categoriasAnteriores) => [
      ...categoriasAnteriores,
      novaCategoria,
    ]);

    setQuantidadeReativando(0);
  }

  useEffect(() => {
    const init = async () => {
      const result_categorias = await produtosApi().getCategorias();
      setCategoriasSelect(result_categorias);
    };
    init();
  }, []);

  const handleEditarCategorias = async () => {
    categoriasApi()
      .editarCategorias(categoriasSelect)
      .then(
        (response) => {
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Categorias atualizadas com sucesso.",
          });
          setCategoriasBox(false);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Ocorreu um erro ao atualizar as categorias.",
          });
          setCategoriasBox(false);
        }
      );
  };

  return adicionandoCategoria ? (
    <CriarCategoriaContainer theme={theme}>
      <div className="titulo">Nova categoria</div>
      <div className="texto">
        Adicione um nome para identificar sua categoria
      </div>
      <div className="inputs">
        <div className="input">
          <Input
            label="Nome da categoria"
            sizeLabel="medium"
            type="text"
            maxLength={64}
            name="nome"
            mask={{
              mask: noSpecialCharacters,
            }}
            value={categoria}
            allowEmojis={true}
            onChange={(newValue) => {
              setCategoria(newValue);
            }}
            placeholder="Nome da categoria"
          />
        </div>
      </div>
      <div className="botoes">
        <div
          className="cancelar"
          onClick={() => {
            setAdicionandoCategoria(false);
          }}
        >
          <Voltar /> Voltar
        </div>
        <Button
          title="Salvar"
          loading={loading}
          className="botao_alterar"
          width="50%"
          onClick={handleCriar}
          disabled={categoria.length == 0}
        />
      </div>
      <Modal
        isOpen={modalReativarProdutos}
        setModal={() => setModalReativarProdutos}
        children={
          <>
            <ModalReativar theme={theme}>
              <Player
                autoplay
                loop
                src={Error}
                style={{ height: "80px", width: "80px" }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
              <div className="texto">
                <strong>
                  Ops, já existe uma categoria com o nome {`"${categoria}"`}{" "}
                  <br />
                  Você gostaria de reativá-la?
                </strong>
              </div>
              <div className="reativar">
                <input
                  checked={reativarProdutos}
                  type="checkbox"
                  onChange={(newValue) => {
                    setReativarProdutos(newValue.target.checked);
                  }}
                ></input>
                <span>Quero também reativar os produtos vinculados a ela.</span>
              </div>
              <Botoes theme={theme}>
                <div
                  className="cancelar"
                  onClick={() => {
                    setModalReativarProdutos(false);
                    setLoading(false);
                  }}
                >
                  <Voltar /> Voltar
                </div>
                <Button
                  title="Salvar"
                  type="button"
                  onClick={() => {
                    criarNovaCategoria(categoria, reativarProdutos);
                    setModalReativarProdutos(false);
                    setAdicionandoCategoria(false);
                    setLoading(false);
                    setReativarProdutos(false);
                  }}
                  width="50%"
                  className={"botao_alterar"}
                />
              </Botoes>
            </ModalReativar>
          </>
        }
        width="35vw"
        align="center"
        justify="center"
        animation="opacity 400ms cubic-bezier(0.4, 0, 0.2, 1)"
        margin="0rem 2rem 0rem 2rem"
      />
    </CriarCategoriaContainer>
  ) : (
    <ContainerEditarListas theme={theme}>
      <Form
        className="form"
        id="form-listas"
        setFormValid={setFormValid}
        onSubmit={handleEditarCategorias}
      >
        <Titulo
          titulo="Categorias"
          subtitulo="Configure as categorias da sua conta."
          flexDirection="column-reverse"
        />
        <GridListas>
          {categoriasSelect.map((categoria, index) => {
            return (
              <div key={categoria.id || index}>
                {index === 0 && (
                  <Header>
                    <TituloLista>Nome</TituloLista>
                    <TituloLista>Produtos</TituloLista>
                    <TituloLista>Situação</TituloLista>
                  </Header>
                )}
                <Lista>
                  <TituloLista>
                    <span
                      onClick={() => {
                        setCategoria(categoria.descricao);
                        setAdicionandoCategoria(true);
                        setIdCatetegoriaEditando(index);
                      }}
                    >
                      {categoria.descricao}
                    </span>
                  </TituloLista>
                  <TituloLista>
                    <span>{categoria.qtd_produtos}</span>
                  </TituloLista>
                  <TituloLista>
                    <div className="center">
                      <Toggle
                        value={categoria.ativo}
                        isValid={categoriasSelect.some(
                          (categoria) =>
                            categoria.ativo === "1" ||
                            categoria.ativo === 1 ||
                            boolean(categoria.ativo) === true
                        )}
                        invalidText="Deve haver pelo menos </br> uma categoria ativa."
                        onChange={async (newValue) => {
                          if (Number(categoria.qtd_produtos) > 0 && !newValue) {
                            Swal.fire({
                              icon: "error",
                              title: `Ao desativar esta categoria, todos os produtos que a utilizam serão desativados. Deseja continuar?`,
                              showCancelButton: true,
                              confirmButtonText: "Continuar",
                              denyButtonText: "Cancelar",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setCategoriasSelect(
                                  categoriasSelect.map((categoria_) => {
                                    if (categoria_.id === categoria.id) {
                                      categoria_.ativo = newValue;
                                    }
                                    return categoria_;
                                  })
                                );
                              }
                            });
                          } else {
                            setCategoriasSelect(
                              categoriasSelect.map((categoria_, index_) => {
                                if (index_ === index) {
                                  categoria_.ativo = newValue;
                                }
                                return categoria_;
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </TituloLista>
                </Lista>
              </div>
            );
          })}
        </GridListas>
        <div className="linha-pai">
          <div className="linha"></div>
        </div>
        <div className="nova-tabela">
          <span
            onClick={() => {
              setCategoria("");
              setIdCatetegoriaEditando(9999);
              setAdicionandoCategoria(true);
            }}
          >
            Adicionar nova categoria
          </span>
        </div>
        <Botoes theme={theme}>
          <div
            className="cancelar"
            onClick={() => {
              setCategoriasBox(false);
            }}
          >
            <Voltar /> Voltar
          </div>
          <Button
            title="Salvar"
            type="submit"
            width="50%"
            className={`botao_alterar ${!formValid && "disabled"}`}
          />
        </Botoes>
      </Form>
    </ContainerEditarListas>
  );
};
