import React, { useContext, useEffect, useState } from 'react';
import './styles';
import { CurseducaContainer } from './styles';
import { useTheme } from '../../contexts/Theme/ThemeContext';
import { EmpresaContext } from '../../contexts/Empresa/EmpresaContext';
import { BotoesContainer } from '../../styled/Styled';
import { Button } from '../Button';
import { curseducaApi } from '../../hooks/curseducaApi';
import Form from '../Form';
import { Input } from '../Input';
import { validateEmail } from '../../services/MasksValidations';
import Swal from 'sweetalert2';

interface CurseducaProps {
    onFechar: () => void;
}

const Curseduca: React.FC<CurseducaProps> = ({onFechar}) => {

    const {theme} = useTheme();
    const {empresa, loading: loadingEmpresa} = useContext(EmpresaContext)
    const [loading, setLoading] = useState(false);
    const api = curseducaApi();
    const [formValid, setFormValid] = useState(false);
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const onCadastrar = async () => {
        setLoading(true);
        const res = await api.cadastrar(email, senha);
        if(res.error && res.error === 'SENHA_INVALIDA'){
            Swal.fire({
                title: 'Ops...',
                text: 'A senha informada é inválida. Lembre-se que a senha deve ser a mesma utilizada no Conecta Venda.',
                icon: 'error',
                timer: 3000,
            });
        } else {
            if(!res.ja_cadastrado === undefined || !res.id_membro){
                Swal.fire({
                    title: 'Ops...',
                    text: 'Ocorreu um erro inesperado ao tentar cadastrar o membro. Tente novamente!',
                    icon: 'error',
                    timer: 3000,
                });
            } else {
                if(res.ja_cadastrado){
                    await Swal.fire({
                        title: 'Atençao!',
                        text: `Este email já estava cadastrado na plataforma Clube Conectado e foi reativado, caso não lembre a senha que era utilizada vá até a plataforma e clique em "Esqueci minha senha".`,
                        icon: 'warning',
                        showConfirmButton: true,     
                    });
                } else {
                    await Swal.fire({
                        title: 'Sucesso!',
                        text: `Você foi cadastrado com sucesso na plataforma Clube Conectado.`,
                        icon: 'success',
                        showConfirmButton: true,     
                    });
                }
                window.open("https://clubeconectado.conectavenda.com.br/login", "_blank");
                window.location.reload();
            }
        }
        setLoading(false);
        console.log(res)
    }

   useEffect(() => {
        setEmail(empresa?.email_notificacao || '');
    }, [empresa]);

    return (
        <CurseducaContainer theme={theme}>
            <Form id='form-curseduca' onSubmit={onCadastrar} setFormValid={setFormValid}>
                <div className="titulo">
                    Clube Conectado
                </div>
                <div className="texto">
                    Preencha as informações abaixo para que possamos criar a sua conta na plataforma Clube Conectado. <br />
                    <b>A senha deve ser a mesma utilizada no Conecta Venda.</b>
                </div>
                <div className="inputs">
                    <Input 
                        type="text"
                        label="Email"
                        placeholder="Digite seu email"
                        value={email}
                        invalidText="Email inválido"
                        isValid={{
                            isValidFunction: validateEmail,
                            dependencies: [email]
                        }}
                        onChange={(newValue) => setEmail(newValue)}
                        required={true}
                    />
                    <Input 
                        type="password"
                        label="Senha utilizada no Conecta Venda"
                        placeholder="Digite sua senha"
                        value={senha}
                        onChange={(newValue) => setSenha(newValue)}
                        required={true}
                    />
                </div>
                <BotoesContainer theme={theme}>
                    <div className="cancelar" onClick={onFechar}>
                        Cancelar
                    </div>
                    <Button 
                    title='Cadastrar' 
                    type="submit"
                    loading={loading}
                    className={`${!formValid ? 'disabled' : ''}`}/>
                </BotoesContainer>
            </Form>
        </CurseducaContainer >
    )
}

export default Curseduca;