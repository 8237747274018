import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const ImportarArquivoContainer = styled.div<{theme: ThemeType}>`
    font-size: 2.5rem;
    text-align: justify;
    width: 100%;
    color: ${({ theme }) => theme.colors.brandSecondary};
    .tituloContainer {
        .titulo {
            gap: 0rem !important;
            padding: 0;
        .subtitulo {
            line-height: 1.5rem;
            display: flex;
        }
        .laranja {
            justify-content: flex-start;
        }
        }
    }
    ul {
        li {
            margin-bottom: 1rem;
        }
    }
    span {
        font-weight: bold;
    }
    a {
        color: ${({ theme }) => theme.colors.brandPrimary};
        text-decoration: none;
    }
    p {
        margin-bottom: 1rem;
        display: grid;
        &:first-of-type{
        position: relative;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
            &:before {
            position: absolute;
            justify-self: center;
            content: "";
            width: 80%;
            height: 2px;
            background-color: ${({ theme }) => theme.colors.placeholder};
            border-radius: 10rem;
            top: 100%;
        }
        }
    }
    .atencao {
        color: ${({ theme }) => theme.colors.brandPrimary};
        position: relative;
        margin-top: 5rem;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
            align-self: flex-start;
        }
        ul {
            align-self: flex-start;
            li {
                margin: 0;
            }
        }
    }
    @media (max-width: 613px) {
      .footer{
        flex-direction: column;
        gap: 2rem;
        .baixar-csv {
            width: 100%;
        }
        .botoes-container {
            width: 100%;
            flex-direction: column;
            gap: 3rem;
            .botoes {
                width: 100%;
                flex-direction: column;
                gap: 2rem;
                justify-content: flex-start;
            }
        }
      }
    }
`;

export {
    ImportarArquivoContainer
};
