import styled, { css } from "styled-components";
import theme from "../../../Themes/light";
import InformacoesDaMarca from "./InformacoesDaMarca";

const { colors } = theme;

const commonStyles = css`
  display: contents;
  width: 100%;
  .InformacoesDaMarca {
    padding: 5rem 10rem;
    width: 200rem;
    max-width: 90vw;
    display: grid;
    grid-template-columns: calc(60% - 6rem) calc(40% - 6rem);
    gap: 12rem;
    .informacoes {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5rem;
      min-width: 0;
      .inputs-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 2rem;
        .select-container,
        .input-container {
          width: 100%;
          font-size: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          column-gap: 2rem;
          row-gap: 2rem;

          .image-upload {
            flex-grow: 1;
            min-width: 2rem;
          }

          .colors-container {
            flex-grow: 1;
            gap: 1rem;
            font-size: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: ${(props) =>
              props.theme.mode == "light"
                ? props.theme.colors.placeholder
                : props.theme.colors.brandSecondary};
            background-color: ${(props) => props.theme.colors.white};
            height: 6rem;
            padding: 1rem 4rem;
            border-radius: 50px;

            .containerBranco {
              display: flex;
              width: 4rem;
              height: 4rem;

              .bolinha {
                width: 100%;
                height: 100%;
                border: 1px solid #a7a7a7;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
        }
        .quill-wrapper {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          .lenght-quill {
            position: absolute;
            font-size: 2.5rem;
            padding: 10px 10px 0 0;
            color: ${colors.placeholder};
          }
        }
      }
    }

    .mockup-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 1024px) {
      grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
      gap: 2rem;
      padding: 3rem 2rem;
      .informacoes {
        > .titulo {
          margin-bottom: 1rem;
        }
      }
    }
    @media (max-width: 750px) {
      padding: 3rem 2rem;
      grid-template-columns: 100%;
      max-width: 100%;
      gap: 5rem;
      .informacoes {
        gap: 0rem;
      }
    }
  }
`;

export const InformacoesDaMarcaContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      .InformacoesDaMarca {
        width: 100%;
        max-width: 100%;
        display: flex;
        padding: 0;
        gap: 0;
        justify-content: space-evenly;
        align-items: center;
        .informacoes {
          gap: 0rem;
          padding: 0 3rem;
        }
        .mockup-container {
          max-width: 55rem;
        }
        @media (max-width: 1024px) {
          .mockup-container {
            display: none;
          }
          .informacoes {
            padding: 0;
            .tituloContainer {
              .titulo {
                margin-bottom: 0;
                padding: 0 0 3rem 0;
                .subtitulo {
                  display: none;
                }
                .barra {
                  display: none;
                }
              }
            }
          }
        }
      }
    `};
  ${({ configIn }) => configIn === "empresa" && css``};
`;
