import styled from 'styled-components';
import { ThemeType } from '../../../types/Theme';

const InformacoesArquivoContainer = styled.div<{theme: ThemeType}>`
    color: ${({theme}) => theme.colors.brandSecondary};
    font-size: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
    align-items: flex-start;
    .informacao{
        display: flex;
        gap: 1rem;
        span{
            width: 2rem;
            color: ${({theme}) => theme.colors.brandDanger};
        }
        .accept{
            color: ${({theme}) => theme.colors.brandPrimary};
        }
    }
    .ajustar-produtos{
        color: ${({theme}) => theme.colors.brandSecondary};
        padding: 0.5rem;
        background-color: ${({theme}) => theme.colors.elements};
    }
`;

export {
    InformacoesArquivoContainer
};

