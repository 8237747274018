import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/newImages/icons/arrowLeft.svg";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../contexts/Theme/ThemeContext";
import { BreadcrumbsContainer } from "./styles";

const Breadcrumbs: React.FC = () => {
  const { theme } = useTheme();

  const location = useLocation();
  const { id, name, duplicated } = useParams();

  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const nomeUsuario = empresa?.usuario;

  const [breadcrumbs, setBreadcrumbs] = useState<Array<any>>([]);

  function verificaNovoProduto() {
    var url = window.location.href;
    var regex = /\/0$/;
    return regex.test(url);
  }

  const getPeriodoDoDia = () => {
    const horaAtual = new Date().getHours();

    if (horaAtual >= 5 && horaAtual < 12) {
      return "Bom dia";
    } else if (horaAtual >= 12 && horaAtual < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  };

  const routes = [
    //primarias
    { path: "painel", label: "Meu painel", link: "/" },
    { path: "perfil", label: "Meu perfil", link: "/perfil" },
    { path: "novidades", label: "Novidades", link: "/novidades" },
    { path: "compartilhar", label: "Compartilhar", link: "/compartilhar" },
    //secundarias
    { path: "pedidos", label: "Pedidos", link: "painel/pedidos" },
    {
      path: "configuracoes",
      label: "Configurar",
      link: "painel/configuracoes",
    },
    {
      path: "usuarios",
      label: "Usuários e assinatura",
      link: "/perfil/usuarios",
    },
    { path: "pedidos", label: "Meus pedidos", link: "/painel/pedidos" },
    {
      path: "sacolas-abandonadas",
      label: "Sacolas abandonadas",
      link: "/painel/sacolas-abandonadas",
    },
    { path: "catalogos", label: "Meus Catálogos", link: "/painel/catalogos" },
    { path: "produtos", label: "Meus produtos", link: "/painel/produtos" },
    {
      path: "produto",
      label: `${verificaNovoProduto() ? "Novo produto" : "Editando Produto"}`,
      link: "/painel/produtos/produto/",
    },
    {
      path: "pedido",
      label: `Pedido #${id}`,
      link: `/painel/pedidos/pedido/${id}`,
      onVoltar: "/painel/pedidos",
    },
    {
      path: "relatorios",
      label: "Meus relatórios",
      link: "/painel/relatorios",
      onVoltar: id ? "/painel/relatorios" : "",
    },
    //terciarias
    {
      path: "informacoes-erp",
      label: "Informações do ERP",
      link: "/painel/produtos/informacoes-erp",
      onVoltar: "/painel/produtos",
    },
    {
      path: "importar-imagens",
      label: "Carregar imagens",
      link: "/painel/produtos/importar-imagens",
      onVoltar: id ? "/painel/produtos/importar-imagens" : "/painel/produtos",
    },
    {
      path: "importar-produtos",
      label: "Adicionar em massa",
      link: "/painel/produtos/importar-produtos",
      onVoltar: "/painel/produtos"
    },
    {
      path: "sacola-abandonada",
      label: `${name ? "Sacola de " + name : "Sacola #" + id}`,
      link: `/painel/sacolas-abandonadas/sacola-abandonada/${id}`,
      onVoltar: "/painel/sacolas-abandonadas",
    },
    {
      path: "catalogo",
      label: `${name} ${duplicated ? "- Cópia" : ""}`,
      link: `/painel/catalogos/catalogo/${id}/${encodeURIComponent(name ? name : '')}${
        duplicated ? "/duplicado" : ""
      }`,
      onVoltar: "/painel/catalogos",
    },
    //quaternarias
    {
      path: "ajustar",
      label: "Ajustar produtos",
      link: "/painel/produtos/importar-produtos/ajustar",
      onVoltar: "/painel/produtos/importar-produtos"
    },
  ];

  useEffect(() => {
    if (location) {
      let newBreadcrumbs: Array<Object> = [];
      let paths = location.pathname.split("/").filter((str) => Boolean(str));
      paths.forEach((path) => {
        newBreadcrumbs.push(routes.filter((route) => route.path == path)[0]);
      });
      newBreadcrumbs = newBreadcrumbs.filter(
        (breadcrumb) => breadcrumb != undefined,
      );

      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location.pathname]);

  return (
    <BreadcrumbsContainer theme={theme}>
      {location.pathname == "/" && (
        <div className="saudacao hideMobile">
          {getPeriodoDoDia()}, <span>{nomeUsuario}</span>
        </div>
      )}
      {location.pathname != "/" && breadcrumbs.length >= 1 && (
        <>
          {breadcrumbs.some((breadcrumb) => breadcrumb.onVoltar) && (
            <Link
              to={
                breadcrumbs.filter((breadcrumb) => breadcrumb.onVoltar)[breadcrumbs.filter((breadcrumb) => breadcrumb.onVoltar).length - 1]
                  .onVoltar
              }
              className="voltar"
            >
              <Arrow className="arrow" />
              <span>Voltar</span>
            </Link>
          )}
          {breadcrumbs.map((breadcrumb, index, arr) => (
            <span
              key={index}
              className={`palavra ${
                arr.length > 1
                  ? index == arr.length - 1
                    ? "laranja"
                    : "verde"
                  : "verde bold"
              }`}
            >
              <Link to={breadcrumb.link} style={{ textDecoration: "none" }}>
                {breadcrumb.label}
              </Link>
              {index !== arr.length - 1 && <span className="barra">/</span>}
            </span>
          ))}
        </>
      )}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
