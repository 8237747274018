import styled, { css } from "styled-components";
import theme from "../../../Themes/light"; // substitua pelo caminho correto do arquivo de tema

const commonStyles = css`
  width: 100rem;
  max-width: 100%;
  .informacoes {
    padding: 4em 0;
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mensagemLink {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    @media (max-width: 650px) {
      width: 95%;
    }
  }

  .tituloMensagem {
    color: ${(props) => props.theme.colors.brandPrimary};
    font-size: 2.5rem;
    font-weight: bold;
  }

  .textarea {
    position: relative;
    margin-top: 2rem;
    resize: none;
    width: 100%;
    border-radius: 20px;
    padding: 1rem;
    height: 10rem;
    outline: none;
    border: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.elements};
    }
  }
`;

export const ContainerCompartilhamento = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      max-width: 100%;
      .informacoes {
        padding: 3rem 0 0 0;
        .descricao {
          width: 100%;
          text-align: left;
        }
      }
      .mensagemLink {
        gap: 1rem;
        width: 100%;
        .tituloMensagem {
          width: 100%;
          color: ${(props) => props.theme.colors.brandSecondary};
          font-weight: normal;
          text-align: left;
        }
        .texto {
          width: 100%;
        }
      }
    `};
`;
