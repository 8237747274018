import styled, { css } from "styled-components";
import theme from "../../../Themes/light";

const { colors } = theme;

const commonStyles = css`
  padding: 5rem 10rem;
  display: grid;
  grid-template-columns: calc(60% - 6rem) calc(40% - 6rem);
  gap: 12rem;
  width: 200rem;
  max-width: 90vw;

  .informacoes {
    font-size: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    min-width: none;
    .tituloContainer{
      .titulo{
        padding: 0;
      }
    }
    > label {
      > .toggleContainer {
        margin-top: 6rem;
        color: ${(props) => props.theme.colors.brandSecondary};
        gap: 1rem;
        display: flex;
      }
    }

    > .subtitulo {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      color: ${(props) => props.theme.colors.brandPrimary};
      font-weight: bold;
    }
  }

  .mockup-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
    gap: 2rem;
    padding: 3rem 2rem;
  }

  @media (max-width: 750px) {
    padding: 3rem 5rem;
    grid-template-columns: repeat(auto-fit, 100%);
    gap: 5rem;
  }
`;

export const ContainerVisualVariacoesContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      max-width: 100%;
      padding: 4rem 0 0 0;
      gap: 4rem;
      grid-template-columns: minmax(calc(45% - 2rem), 100%) 60rem;
      .informacoes {
        justify-content: center;
        .subtitulo {
          color: ${(props) => props.theme.colors.brandSecondary};
          margin: 0;
        }
        > label {
          width: 100%;
          margin: 0;
          padding: 0;
          .toggleContainer {
            align-items: center;
            margin: 0;
          }
        }
      }
      @media (max-width: 1024px) {
        grid-template-columns: 100%;
        padding: 3rem 0rem;
        .mockup-container {
          display: none;
        }
      }

      @media (max-width: 750px) {
        padding: 3rem 0rem;
      }
    `};
`;
