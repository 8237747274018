import styled from "styled-components";

const FreteGratisMainContainer = styled.div`
  position: relative;
  width: 100%;
  .dropdown {
    .novo {
      cursor: pointer;
      font-size: 2.5rem;
      margin-top: 0;
      text-align: left;
      color: ${(props) => props.theme.colors.brandPrimary};
    }
  }
`;

export { FreteGratisMainContainer };
