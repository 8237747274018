import { Button } from "../../../Button";
import { StepperMainContainer } from "./styles";
import arrowLeft from "../../../../assets/newImages/icons/arrowLeft.svg";
import { useEffect, useContext, useRef, useState } from "react";
import { ConfiguracoesContext } from "../../../../contexts/Configuracoes/ConfiguracoesContext";
import { copy, deepCopy, limparConfigsLocalStorage } from "../../../../services/Functions";
import { ModaisConfiguracoesProps } from "../../../../types/ModaisConfiguracoesProps";
import Form from "../../../Form";
import Configuracoes from "../../../../types/Configuracoes";
import { FreteGratis } from "../../../../types/FreteGratis";
import { FreteEspecial } from "../../../../types/FreteEspecial";
import { ConfigsPrimeiroAcessoContext } from "../../../../contexts/ConfigsPrimeiroAcesso/ConfigsPrimeiroAcessoContext";
import { useTheme } from "../../../../contexts/Theme/ThemeContext";

interface ModalProps {
  array: Array<any>;
  modalAtivo: ModaisConfiguracoesProps;
  children: React.ReactNode;
  simNao?: boolean;
  salvarCancelar?: boolean;
  avancarText?: string;
  onAvancar?: (paramsHistory: any[]) => void;
  voltarText?: string;
  onVoltar?: (paramsHistory: any[]) => void;
  pularText?: string;
  onPular?: (paramsHistory: any[]) => void;
  cancelarText?: string;
  onCancelar?: (
    configuracoes: Configuracoes,
    fretes_gratis: Array<FreteGratis>,
    fretes_especiais: Array<FreteEspecial>,
    paramsHistory: any[],
  ) => void;
  salvarText?: string;
  onSalvar?: () => void;
}

const Stepper: React.FC<ModalProps> = ({
  array,
  children,
  avancarText,
  voltarText,
  pularText,
  simNao,
  onAvancar,
  onPular,
  onVoltar,
  salvarCancelar,
  onCancelar,
  cancelarText,
  onSalvar,
  salvarText,
  modalAtivo,
}) => {
  //explicação: sempre que o usuário trocar de modal, o estado que estava no modal anterior é salvo no localStorage e recuperado caso ele chame a funcão de cancelar
  //tendo assim uma forma dinâmica de salvar ou cancelar e assim chamando a api apenas no final de tudo

  const { theme } = useTheme();
  const configuracoesContext = useContext(ConfiguracoesContext);
  const configsPrimeiroAcessoContext = useContext(ConfigsPrimeiroAcessoContext);

  const {
    configuracoes,
    setConfiguracoes,
    fretes_especiais,
    setFretes_especiais,
    fretes_gratis,
    setFretes_gratis,
    politica_de_privacidade,
    setPolitica_de_privacidade,
    loading,
  } = useContext(ConfiguracoesContext);

  const { paramsHistory } = useContext(ConfigsPrimeiroAcessoContext);

  let configuracoesAuxiliar: Array<Configuracoes> = localStorage.getItem(
    "configuracoesAuxiliar",
  )
    ? JSON.parse(localStorage.getItem("configuracoesAuxiliar") as string)
    : [];
  let fretes_especiaisAuxiliar: Array<Array<FreteEspecial>> =
    localStorage.getItem("fretes_especiaisAuxiliar")
      ? JSON.parse(localStorage.getItem("fretes_especiaisAuxiliar") as string)
      : [];
  let fretes_gratisAuxiliar: Array<Array<FreteGratis>> = localStorage.getItem(
    "fretes_gratisAuxiliar",
  )
    ? JSON.parse(localStorage.getItem("fretes_gratisAuxiliar") as string)
    : [];

  const salvarLocalStorage = (oldIndex) => {
    localStorage.setItem("configuracoes", JSON.stringify(configuracoes));
    localStorage.setItem("fretes_gratis", JSON.stringify(fretes_gratis));
    localStorage.setItem("fretes_especiais", JSON.stringify(fretes_especiais));
    localStorage.setItem(
      "politica_de_privacidade",
      politica_de_privacidade.toString(),
    );

    configuracoesAuxiliar[oldIndex] = copy(configuracoes);
    fretes_especiaisAuxiliar[oldIndex] = deepCopy(fretes_especiais);
    fretes_gratisAuxiliar[oldIndex] = deepCopy(fretes_gratis);

    localStorage.setItem(
      "configuracoesAuxiliar",
      JSON.stringify(configuracoesAuxiliar),
    );
    localStorage.setItem(
      "fretes_gratisAuxiliar",
      JSON.stringify(fretes_gratisAuxiliar),
    );
    localStorage.setItem(
      "fretes_especiaisAuxiliar",
      JSON.stringify(fretes_especiaisAuxiliar),
    );
  };

  const restaurarConfigsAntigas = (index) => {
    setConfiguracoes(copy(configuracoesAuxiliar[index])); // restaura as configurações antigas
    setFretes_especiais(deepCopy(fretes_especiaisAuxiliar[index])); // restaura os fretes especiais antigos
    setFretes_gratis(deepCopy(fretes_gratisAuxiliar[index])); // restaura os fretes grátis antigos
  };

  const [formValid, setFormValid] = useState(false);

  const index = array.findIndex(
    (item) => item.localizador === modalAtivo.localizador,
  );

  const preenchimentoStyle = {
    width: `${(index * 100) / (array.length - 1)}%`,
  };

  const handleOnAvancar = () => {
    if (loading) return;
    if (formValid) {
      if (modalAtivo.localizador === "criarProduto") {
        //salvar no último step
        configuracoesContext.salvarConfiguracoes().then(
          (res) => {
            if (res) {
              limparConfigsLocalStorage();
              onAvancar && onAvancar(paramsHistory.current);
            }
          },
          (err) => {
            console.log(err);
          },
          );
        }
      //se for criarProduto, tem que esperar salvar pra seguir senao o componente pode desmontar antes de limpar o storage
      modalAtivo.localizador !== "criarProduto" && onAvancar && onAvancar(paramsHistory.current);
    }
  };

  const handleOnVoltar = () => {
    if (loading) return;
    if (onVoltar) {
      onVoltar(paramsHistory.current);
    }
  };

  const handleOnPular = () => {
    if (loading) return;
    if (modalAtivo.localizador === "criarProduto") {
      //salvar no último step
      configuracoesContext.salvarConfiguracoes().then(
        (res) => {
          if (res) {
            limparConfigsLocalStorage();
          }
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      restaurarConfigsAntigas(
        indexHistory.current[indexHistory.current.length - 2],
      );
    }
    if (onPular) {
      onPular(paramsHistory.current);
    }
  };

  const cancelarCalled = useRef(false);
  const indexHistory = useRef([index]);

  const handleOnCancelar = () => {
    if (loading) return;
    cancelarCalled.current = true;
    if (onCancelar) {
      //passar o historico de configs que tava antes de entrar no modal atual
      onCancelar(
        configuracoesAuxiliar[
          indexHistory.current[indexHistory.current.length - 2]
        ],
        fretes_gratisAuxiliar[
          indexHistory.current[indexHistory.current.length - 2]
        ],
        fretes_especiaisAuxiliar[
          indexHistory.current[indexHistory.current.length - 2]
        ],
        paramsHistory.current,
      );
    }
  };

  useEffect(() => {
    //salvar no localStorage o estado atual no index anterior ao modal atual
    if (!loading)
      salvarLocalStorage(indexHistory.current[indexHistory.current.length - 1]);
    indexHistory.current.push(index);

    if (cancelarCalled.current) {
      restaurarConfigsAntigas(index);
      cancelarCalled.current = false;
    }

    localStorage.setItem("indexModais", index.toString());
    localStorage.setItem(
      "paramsHistory",
      JSON.stringify(paramsHistory.current),
    );
  }, [index]);

  return (
    <>
      <Form
        id="form-configs-inicias"
        name="form-configs-inicias"
        setFormValid={setFormValid}
        onSubmit={handleOnAvancar}
      >
        <StepperMainContainer theme={theme}>
          {!simNao && !salvarCancelar && (
            <>
              <div className="stepperContainer">
                <div className="sombra">
                  <div
                    style={preenchimentoStyle}
                    className="preenchimento"
                  ></div>
                </div>
              </div>
              {children}
              <div className="navigationContainer">
                <div
                  onClick={handleOnVoltar}
                  className="voltar"
                  style={{ display: onVoltar === undefined ? "none" : "flex" }}
                >
                  <img src={arrowLeft} />{" "}
                  <span>{voltarText ? voltarText : "Voltar"}</span>
                </div>
                <div className="avancar">
                  {pularText && (
                    <div className="pular" onClick={handleOnPular}>
                      {pularText ? pularText : "Pular"}
                    </div>
                  )}
                  <Button
                    className={`${(!formValid || loading) && "disabled"}`}
                    type="submit"
                    padding={"10rem"}
                    title={avancarText ? avancarText : "Avançar"}
                  ></Button>
                </div>
              </div>
            </>
          )}
          {simNao && !salvarCancelar && (
            <>
              <div className="childrenContainer">{children}</div>
              <div className="navigationContainer">
                <div onClick={handleOnPular} className="nao">
                  <span>{pularText ? pularText : "Não"}</span>
                </div>
                <div className="sim">
                  <Button
                    onClick={handleOnAvancar}
                    className={`${loading && "disabled"}`}
                    padding={"10rem"}
                    title={avancarText ? avancarText : "Sim"}
                  ></Button>
                </div>
              </div>
            </>
          )}
          {salvarCancelar && !simNao && (
            <>
              <div className="childrenContainer">{children}</div>
              <div className="navigationContainerSalvarCancelar">
                <div className="salvar">
                  <Button
                    className={`${(!formValid || loading) && "disabled"}`}
                    type="submit"
                    padding={"10rem"}
                    title={salvarText ? salvarText : "Salvar"}
                  ></Button>
                </div>
                <div onClick={handleOnCancelar} className="cancelar">
                  {cancelarText ? cancelarText : "Cancelar"}
                </div>
              </div>
            </>
          )}
        </StepperMainContainer>
      </Form>
    </>
  );
};

export default Stepper;
