import styled from "styled-components";

const VariacoesBoxContainer = styled.div`
  padding-bottom: 20rem;
  .tabs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: flex-end;
    .tab {
      width: 100%;

      .titulo {
        text-align: center;
        color: ${(props) => props.theme.colors.titleColor};
        font-weight: normal;
      }

      .destaque {
        height: 2px;
        background-color: #dbdbdb;
        width: 100%;

        &.ativo {
          height: 2px;
          background-color: ${(props) => props.theme.colors.titleColor};
        }
      }
    }

    margin-bottom: 30px;
  }

  .texto {
    font-size: 2.5rem;
    text-align: left;
    margin: 2rem 0;
  }

  .variacoes {
    white-space: nowrap;

    .nova_variacao {
      display: flex;
      justify-content: flex-start;

      .adicionar {
        background-color: ${(props) => props.theme.colors.white};
        text-align: center;
        padding: 1rem 4rem;
        border-radius: 15px;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1024px) {
    padding-bottom: 0rem;
  }
`;

export default VariacoesBoxContainer;
