import { createContext } from "react";
import { FiltrosProdutos, Produto } from "../../types/Produto";
import { Categoria } from "../../types/Categoria";
import { CatalogoUnico } from "../../types/Catalogo";
import { Intermediadores } from "../../types/Configuracoes";
import ListaDePreco from "../../types/ListaDePreco";

export type CatalogoContextType = {
  optionsAjustePreco: { value: string; name: string }[];
  ajustePreco: string;
  setAjustePreco: React.Dispatch<React.SetStateAction<string>>;
  indice: number | string;
  setIndice: React.Dispatch<React.SetStateAction<number | string>>;
  catalogo: CatalogoUnico;
  setCatalogo: React.Dispatch<React.SetStateAction<CatalogoUnico>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  outrosUsuarios: { email: string; id: string }[];
  listasDePrecos: ListaDePreco[];
  empresaIntermediadores: Intermediadores | undefined;
  listasDePrecos: ListaDePreco[];
  setOutrosUsuarios: React.Dispatch<
    React.SetStateAction<{ email: string; id: string }[]>
  >;
  salvarCatalogo: () => Promise<string>;
  removerCatalogo: () => Promise<void>;
  init: () => Promise<void>;
};

export const CatalogoContext = createContext<CatalogoContextType>(null!);
