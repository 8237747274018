import { api } from "./useApi";

export const pagSeguroApi = () => ({
  getLinkAutorizacao: async () => {
    try {
      const response = await api.post("pagseguro/link_autorizacao");
      const { pagseguro_auth_link, pagseguro_configurado } = response.data;
      return {
        linkAutorizacao: pagseguro_auth_link,
        ativado: pagseguro_configurado,
      };
    } catch (error) {
      console.log(error);
      return { linkAutorizacao: "", ativado: false };
    }
  },
});
