import styled from "styled-components";
import theme from "../../Themes/light";
import { rgba } from "../../services/Functions";

const transitionTime = "0.3";

const ViewSelect = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;

  .selectAuxiliar {
    position: absolute;
    bottom: 0;
    opacity: 0;
    width: 0;
    pointer-events: none;

    &:focus + .select {
      border-color: ${(props) => props.theme.colors.brandPrimary};
    }
  }

  .left {
    margin-left: 2rem;
    justify-content: flex-start;
  }

  .center {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
    margin-right: 2rem;
  }

  .label-select {
    font-size: 2.5rem;
    font-weight: 400;
    width: 100%;
    color: ${(props) => props.theme.colors.brandSecondary};
    display: flex;
    text-align: left;
    white-space: normal;
    svg{
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4rem;
    border: 1px solid transparent;
    border-radius: 10rem;
    cursor: pointer;
    transition: border-color ${transitionTime};
    background-color: ${(props) =>
    props.theme.mode == "light"
      ? props.theme.colors.white
      : props.theme.colors.white};
    height: 6rem;

    .placeholder {
      font-size: 2.5rem;
      color: ${(props) => props.theme.colors.placeholder};
    }

    .search-input {
      width: 100%;
      font-size: 2.5rem;
      border: none;
      outline: none;
      background-color: transparent;
      color: ${(props) => props.theme.colors.brandSecondary};
      padding: 0;
      margin: 0;
    }
  }

  .select:hover,
  .select:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.brandPrimary};
  }

  .icon {
    width: 20px;
    height: 20px;

    svg {
      fill: ${(props) => props.theme.colors.elements};
      width: 100%;
      height: 100%;
    }
  }

  .options {
    font-size: 2.5rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: ${(props) =>
    props.theme.mode == "light" ? "#fff" : props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.brandSecondary};
    border-top: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition:
      opacity ${transitionTime},
      transform ${transitionTime};
  }

  .focused {
    background-color: ${rgba(theme.colors.titleColor, 0.3)} !important;
  }

  .option,
  .optionsContainer {
    font-size: 2.5rem;
    width: inherit;
    cursor: pointer;
    background-color: ${(props) =>
    props.theme.mode == "light" ? "white" : props.theme.colors.white};
    color: ${(props) => props.theme.colors.brandSecondary};
    transition: background-color ${transitionTime};
    text-align: left;
    overflow: hidden;

    &.ativo {
      width: 100%;
      border-radius: 3rem;
      z-index: 205;
      padding: 0rem 0rem;
      position: absolute;
      top: 100%;
      max-height: 200px; /* altura máxima */
      overflow-y: scroll; /* adiciona scroll */
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }

    .option-text {
      padding: 0.5rem 4rem;
    }

    @media (max-width: 1024px) {
      .option-text {
        padding: 1rem 4rem;
      }
    }
  }

  .openUpwards {
    top: auto;
    bottom: 100%;
  }

  .select {
    .icon {
      transition: transform ${transitionTime};
    }
    .selecionado {
      color: ${(props) => props.theme.colors.brandSecondary};
    }

    &.ativo {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .options {
    display: none;

    &.ativo {
      display: block;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
export { ViewSelect };
