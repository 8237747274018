import styled from "styled-components";
import theme from "../../Themes/light";

const transitionTime = "0.3s";
const Sliding = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  pointer-events: none;
  > .right,
  .left,
  .top,
  .bottom {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(500%);
    transition: transform ${transitionTime} ease-in-out;
    width: 100%;
    max-height: 0;
    visibility: hidden;

    &.ativo {
      pointer-events: all;
      max-height: unset;
      transform: translateX(0);
      visibility: visible;
    }
  }
  @media (max-width: 1024px) {
    position: relative;
    > .right,
    .left,
    .top,
    .bottom {
      position: fixed;
    }
  }
`;

export default Sliding;
