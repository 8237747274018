import { max } from "moment";
import styled, { css } from "styled-components";

export const MainContainerModalChaveIntegracao = styled.div`
  position: relative;
  min-height: 25rem;
`;

export const ContainerModalChaveIntegracao = styled.div`
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.brandSecondary};
  .configs {
    margin: 4rem 0;
    .titulo_erp {
      font-weight: bold;
      padding-bottom: 1rem;
      padding-top: 4rem;
    }
    .mensagem_outros {
      margin-top: 5px;
      a {
        text-decoration: underline;
      }
    }
    .config {
      display: flex;
      margin: 2rem 0;
      .title {
        margin-left: 2rem;
      }
    }
  }
  .texto {
    margin-top: 4rem;
    text-align: justify;
  }
  .row-flex {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: center;
    cursor: pointer;
    padding-top: 4rem;
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      > svg {
        padding-top: 0.3rem;
        width: 2rem;
        height: 2rem;
        stroke: ${(props) => props.theme.colors.brandSecondary};
      }
    }
    .btn-salvar {
      white-space: nowrap;
    }
  }
`;
