import { PerfilContext } from "./PerfilContext";
import { useState, useEffect } from "react";
import { PerfilApi } from "../../hooks/perfilApi";

export const PerfilProvider = ({ children }: { children: JSX.Element }) => {
  const api = PerfilApi();
  const [Perfil, setPerfil] = useState<any>([]);

  useEffect(() => {
    const init = async () => {
      const res = await api.usuarios();
      setPerfil(res);
    };
    init();
  }, []);

  const getUsuarios = async () => {
    return await api.usuarios();
  };

  const confereSenha = async (senha: string) => {
    return await api.confereSenha(senha);
  };

  return (
    <PerfilContext.Provider value={{ getUsuarios, confereSenha }}>
      {children}
    </PerfilContext.Provider>
  );
};
