import React, { useContext, useEffect, useState } from 'react';
import './styles';
import { DepositosContainer } from './styles';
import { useTheme } from '../../../../contexts/Theme/ThemeContext';
import { Button } from '../../../Button';
import { blingApi } from '../../../../hooks/blingApi';
import Swal from 'sweetalert2';
import LoadingComFundo from '../../../Loading/LoadingComFundo/LoadingComFundo';
import { ToggleConfigurar } from '../../../Toggle/ToggleConfigurar/ToggleConfigurar';
import { ConfiguracoesContext } from '../../../../contexts/Configuracoes/ConfiguracoesContext';
import Form from '../../../Form';

interface DepositosProps {
    onClose: () => void;
}

const Depositos: React.FC<DepositosProps> = ({onClose}) => {

    const api = blingApi();

    const {configuracoes, setConfiguracoes} = useContext(ConfiguracoesContext);
    
    const [isFormValid, setIsFormValid] = useState(false);
    const [depositosAux, setDepositosAux] = useState<Array<{
        desconsiderarSaldo: boolean;
        descricao: string;
        id: number;
        padrao: boolean;
        situacao: number;
    }>>(configuracoes.dados_bling_v2!.buscar_de_depositos as never);

    const {theme} = useTheme();
    const [loading, setLoading] = useState(true);
    const [depositos, setDepositos] = useState<Array<{
        desconsiderarSaldo: boolean;
        descricao: string;
        id: number;
        padrao: boolean;
        situacao: number;
    }>>([]);

    const salvar = () => {

    }
   
   const buscarDepositos = async () => {
        try {
            const response = await api.buscarDepositos();
            setDepositos(response);
            //se não tiver nenhum depósito selecionado, selecionar todos
            if(configuracoes.dados_bling_v2?.buscar_de_depositos.length === 0){
                setConfiguracoes({
                    ...configuracoes,
                    dados_bling_v2: {
                        ...configuracoes.dados_bling_v2!,
                        buscar_de_depositos: response.map(deposito => deposito.id) as never
                    }
                });
            }
        } catch (error) {
           Swal.fire({
               title: 'Erro',
               text: 'Ocorreu um erro ao buscar os depósitos. Tente novamente.',
               icon: 'error',
               confirmButtonText: 'Ok'
           });
           onCancelar();
       }
   }

    const onCancelar = () => {
        //voltar para o estado anterior
        setConfiguracoes({
            ...configuracoes,
            dados_bling_v2: {
                ...configuracoes.dados_bling_v2!,
                buscar_de_depositos: depositosAux as never
            }
        });

        onClose();
    }

    useEffect(() => {
        const init = async () => {
            await buscarDepositos();
            setLoading(false);
        }
        init();
    }, []);

    return (
        <DepositosContainer theme={theme} loading={loading}>
            {loading ? (
                <LoadingComFundo />
            ) : (
                <Form id='form-depositos' setFormValid={setIsFormValid} onSubmit={onClose} >
                    <div className="titulo">
                        Configurar depósitos
                    </div>
                    <div className="texto">
                        Selecione de quais depósitos você deseja importar o estoque dos produtos.
                    </div>
                    {configuracoes.dados_bling_v2!.buscar_de_depositos !== undefined && (
                        <div className="depositos">
                            {depositos.map((deposito, index) => (
                                <div key={index} className="deposito">
                                    <ToggleConfigurar
                                        invalidText='Escolha um depósito'
                                        required={configuracoes.dados_bling_v2!.buscar_de_depositos!.length === 0}
                                        value={configuracoes.dados_bling_v2!.buscar_de_depositos!.includes(deposito.id as never)}
                                        nome={deposito.descricao}
                                        onChange={(newValue) => {
                                            const novosDepositos = [...configuracoes.dados_bling_v2!.buscar_de_depositos!];
                                            if(newValue){
                                                novosDepositos.push(deposito.id as never);
                                            } else {
                                                const index = novosDepositos.indexOf(deposito.id as never);
                                                novosDepositos.splice(index, 1);
                                            }
                                            setConfiguracoes({
                                                ...configuracoes,
                                                dados_bling_v2: {
                                                    ...configuracoes.dados_bling_v2!,
                                                    buscar_de_depositos: novosDepositos as never
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="botoes">
                        <div className="cancelar" onClick={onCancelar}>
                            Cancelar
                        </div>
                        <Button 
                            title="Salvar"
                            type='submit'
                            className={isFormValid ? '' : 'disabled'}
                            onClick={(e) => {e.stopPropagation()}}
                        />
                    </div>
                </Form>
            )}
        </DepositosContainer >
    )
}

export default Depositos;