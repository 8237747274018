import styled from "styled-components";

const ContainerToggleConfigurar = styled.div`
  font-size: 2.5rem;
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  .nome {
    width: 25rem;
    text-align: left;
  }

  .configurar {
    transition: 500ms;
    color: ${(props) =>
      props.theme.colors.elements}; // assuming elements is part of the theme
    margin-left: 1rem;
  }

  .ativo {
    cursor: pointer;
    color: ${(props) =>
      props.theme.colors
        .brandPrimary}; // assuming brandPrimary is part of the theme
  }
`;

export default ContainerToggleConfigurar;
