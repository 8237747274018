import React, { useContext } from "react";
import Modal from "../Modal";
import {
  HeaderTermosContainer,
  ModalTermosUsoContainer,
  ModalTermosUsoWrapper,
} from "./styles";
import { useEffect } from "react";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import { useState } from "react";
import { Plano } from "../../../types/Plano";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { Button } from "../../Button";
import LoadingComFundo from "../../Loading/LoadingComFundo/LoadingComFundo";

interface ModalProps {
  onAceitarTermosDeUso: (aceitou: boolean, plano: Plano) => void;
}

const ModalTermosDeUso: React.FC<ModalProps> = ({ onAceitarTermosDeUso }) => {
  const { empresa, plano_empresa} = useContext(EmpresaContext);

  const { theme } = useTheme();

  const [concordoTermos, setConcordoTermos] = useState(false);
  const [plano, setPlano] = useState<Plano>({
    periodicidade: null,
    qtd_licenca: null,
    tipo_contrato: null,
    forma_pagamento: null,
    valor: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      setPlano(await plano_empresa());

      setLoading(false);
    };

    init();
  }, []);

  return (
    <>
      <Modal
        isOpen={true}
        setModal={() => {}}
        width={"lg"}
        overflow="hidden"
        animation="comeFromTop 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        backdrop={"static"}
        background={{
          backgroundColor: `rgba(46, 46, 46, 0.2)`,
          backdropFilter: "blur(8px)",
        }}
      >
        <ModalTermosUsoWrapper theme={theme}>
          {loading ? (
            <LoadingComFundo></LoadingComFundo>
          ) : (
            <>
              <HeaderTermosContainer theme={theme}>
                <div className="titulo">
                  Aceite os termos de uso para utilizar o sistema.
                </div>
                <div className="table-termos">
                  <div className="termos-header">QUADRO DE RESUMO</div>
                  <div className="table-wrapper scrollBonitoBottom">
                    <table>
                      <thead>
                        <tr className="cabecalho">
                          <th>Valor</th>
                          <th>Periodicidade</th>
                          <th>Quantidade de licenças</th>
                          <th>Modalidade</th>
                          <th>Forma de pagamento</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(plano.valor)}
                          </td>
                          <td>{plano.periodicidade}</td>
                          <td>{plano.qtd_licenca}</td>
                          <td>{plano.tipo_contrato}</td>
                          <td>{plano.forma_pagamento}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </HeaderTermosContainer>
              <ModalTermosUsoContainer theme={theme}>
                <div className="termosUso">
                  <div className="texto scrollBonito">
                    <p>
                      <u>
                        <b>TERMOS DE USO:</b>
                      </u>
                    </p>
                    <p>
                      O CONTRATANTE deverá ler a íntegra destes Termos e
                      Condições de Uso, pois contêm informações importantes
                      sobre seus direitos e obrigações. Quando acessar utilizar
                      a plataforma “CONECTA VENDA”, você necessariamente
                      concorda em cumpri-los, estando sujeito a todos os teores
                      e disposições aqui nominados, sob pena de integral
                      responsabilização em caso de inobservância. A aceitação
                      plena destas condições de utilização é requisito
                      fundamental para contratação dos serviços oferecidos pela
                      doravante denominada CONTRATADA.
                    </p>

                    <p>
                      O CONTRATANTE declara e garante, para todos os fins de
                      direito, que possui capacidade jurídica de submeter-se aos
                      presentes Termos de Uso, bem como para arcar com a
                      totalidade dos ônus contraídos.
                    </p>

                    <p>
                      <b>
                        CONTRATADA(S), OPERADORA e DESENVOLVEDORA: CONECTA VENDA
                        SERVIÇOS INTERNET LTDA
                      </b>
                      , pessoa jurídica de Direito Privado, inscrita no CNPJ sob
                      o nº. 42.469.657/0001-42, com endereço profissional à Rua
                      Doutor Luiz Augusto Puperi, n°. 360, Cep: 99200-000,
                      Guaporé/RS e,{" "}
                      <b>MAZZUCO E TREVISO PRODUÇÕES DE FOTOGRAFIAS LTDA ME</b>,
                      pessoa jurídica de Direito Privado, inscrita no CNPJ sob o
                      nº. 14.665.385/0001-10, com endereço profissional junto à
                      Rua Félix Engel Filho, n°.188, 1º andar, Bairro Frei
                      Caneca, Cep: 99.200-000, Guaporé/RS.
                    </p>

                    <p>
                      <b>01. DO OBJETO:</b>
                    </p>
                    <p>
                      <b>01.1</b> O presente instrumento tem como objeto a
                      definição de normas referentes ao uso da plataforma
                      chamada “CONECTA VENDA”, à qual será definida como
                      ”plataforma" ou “sistema”, com uso exclusivo na Internet,
                      tendo sua destinação direcionada para vendas de
                      mercadorias ou serviços, de propriedade do CONTRATANTE, a
                      qual está sujeita a totalidade de regras e diretrizes
                      descritas pela CONTRATADA junto a presente normativa de
                      regras.
                    </p>

                    <p>
                      <b>02. DAS DEFINIÇÕES:</b>
                    </p>
                    <p>
                      <b>02.1</b> Define-se como “plataforma”, sendo um sistema
                      desenvolvido pela CONTRATADA para operar, de forma online,
                      compartilhado entre vários CONTRATANTES, através do
                      endereço de internet{" "}
                      <a target="_blank" href="https://conectavenda.com.br/">
                        {" "}
                        www.conectavenda.com.br{" "}
                      </a>
                    </p>
                    <p>
                      <b>02.2</b> O CONTRATANTE é a pessoa física ou jurídica
                      que irá contratar a plataforma, sempre através do
                      pagamento de uma assinatura, objetivando acesso ao
                      ambiente administrativo, onde poderá cadastrar a
                      totalidade dos dados de seus produtos/serviços e ainda
                      configurar o ambiente com as opções que possui.
                    </p>
                    <p>
                      <b>02.3</b> O catálogo, trata-se de um conjunto de
                      produtos/serviços que o CONTRATANTE irá selecionar em sua
                      base de itens, o qual foi previamente cadastrado junto a
                      plataforma.
                    </p>

                    <p>
                      <b>03. RESPONSABILIDADES DA CONTRATADA:</b>
                    </p>
                    <p>
                      <b>03.1</b> São responsabilidades da CONTRATADA: a)
                      Realizar as devidas correções no que concerne às falhas ou
                      impropriedades da plataforma, bem como a devida
                      atualização (em caso de erro não detectado em versões
                      anteriores); b) fornecer todo o suporte técnico solicitado
                      pelo CONTRATANTE, ou qualquer atendimento, referente a
                      plataforma, sempre de segunda-feira à sexta-feira, no
                      horário compreendido entre 08hs:00min às 11hs:00min e
                      13hs:00min às 17hs:00min; c) manter o total sigilo sem
                      nunca espalhar, divulgar, transferir, fornecer ou ceder, a
                      qualquer título, quaisquer dados ou informações do
                      CONTRATANTE, nem tampouco de seus clientes contidos junto
                      ao banco de dados, ou os contraídos por força do presente
                      instrumento.
                    </p>

                    <b>04. RESPONSABILIDADES DO CONTRATANTE:</b>
                    <p>
                      <b>04.1</b> São responsabilidades do O CONTRATANTE: a)
                      Cumprir a íntegra do contrato, remunerando a CONTRATADA,
                      nos exatos termos descritos junto ao plano anteriormente
                      defino entre as partes; b) buscar sempre fazer uso da
                      plataforma de acordo com suas finalidades e exigências
                      técnicas; c) ser responsável pelos dados e informações
                      armazenados junto a sua conta na plataforma; d)
                      responsabilizar-se por qualquer infração legal, seja no
                      âmbito civil, penal, trabalhista, previdenciário, autoral
                      e afins, que eventualmente seja cometida na utilização da
                      plataforma.
                    </p>

                    <p>
                      <b>05. PREÇO E CONDIÇÕES DA MENSALIDADE:</b>
                    </p>
                    <p>
                      <b>05.1</b> O CONTRATANTE resta obrigado a pagar para
                      CONTRATADA a íntegra do valor descrito no quadro resumo
                      (informado no primeiro acesso ao sistema ou na atualização
                      dos Termos de Aceite) de forma recorrente, com a
                      periodicidade ali descrita, relativos a hospedagem,
                      suporte e manutenção da plataforma.
                    </p>
                    <p>
                      <b>05.2</b> A CONTRATADA ficará responsável pela definição
                      e contratação dos serviços de hospedagem.
                    </p>
                    <p>
                      <b>05.3</b> Quanto ao pagamento da mensalidade, este
                      dar-se-á sempre através de Boleto Bancário, Cartão de
                      Crédito, PIX ou outro meio indicado pela CONTRATADA. A
                      emissão da Nota Fiscal fica condicionada, no caso de
                      clientes Pós-Pagos, na criação ou emissão da cobrança. No
                      caso dos clientes Pré-pagos, a emissão dar-se-á após a
                      confirmação do efetivo pagamento.
                    </p>
                    <p>
                      <b>05.4</b> O atraso no pagamento acarretará na
                      paralização dos serviços, podendo ocorrer o bloqueio do
                      acesso a qualquer ambiente disponibilizado pelo
                      contratado, o qual ocorrerá após a notificação da
                      inadimplência em um prazo de até três dias, através do
                      envio de e-mail, contato telefônico ou mensagem por Watts
                      App.
                    </p>
                    <p>
                      <b>05.5</b> Facultará a CONTRATADA atualização referente a
                      cobrança dos valores acordados entre as partes, após o
                      período de 12 meses da aceite contratual, o qual será ou
                      não reajustado através do acúmulo do índice do IPCA do
                      período.
                    </p>
                    <p>
                      <b>05.6</b> O vencimento do pagamento estará descrito
                      junto ao quadro resumo (informado no primeiro acesso ao
                      sistema ou na atualização dos Termos de Aceite).
                    </p>
                    <p>
                      <b>05.7</b> Fica desde já definido, que o instrumento
                      contratual aqui nominado será por prazo indeterminado.
                    </p>

                    <p>
                      <b>06. DA ÁREA ADMINISTRATIVA:</b>
                    </p>
                    <p>
                      <b>06.1</b> Deverá a empresa CONTRATADA disponibilizar ao
                      CONTRATANTE área de administração (virtual), com acesso
                      mediante um <b>LOGIN</b> e uma <b>SENHA MASTER</b> (perfil
                      Gerente).
                    </p>
                    <p>
                      <b>06.2</b> A proteção e conservação de senhas fica de
                      inteira responsabilidade do CONTRATANTE, o qual deverá
                      utilizá-las de modo diligente no correto acesso aos
                      serviços disponibilizados. Em caso de uso indevido das
                      senhas por pessoa estranha, tal prática exime a CONTRATADA
                      de qualquer responsabilidade.
                    </p>
                    <p>
                      <b>06.3</b> O CONTRATANTE fica limitado a utilização da
                      plataforma, não possuindo nenhum direito referente ao
                      código fonte ou arquivos gerados pela CONTRATADA. Tal
                      limitação, como cadastro de produtos, imagens de
                      mercadorias e histórico de pedidos não se refere as
                      informações básicas ou descrições cadastradas junto ao
                      sistema pelo CONTRATANTE.
                    </p>

                    <p>
                      <b>07. DAS CLÁUSULAS GERAIS:</b>
                    </p>
                    <p>
                      <b>07.1</b> A CONTRATADA fica desde já autorizada para
                      divulgar em seus meios de portfólio com a discriminação
                      dos seus respectivos dados os serviços realizados ao
                      CONTRATANTE, salvo negativa expressa.
                    </p>
                    <p>
                      <b>07.2</b> Durante a vigência do presente instrumento
                      contratual, o CONTRATANTE terá direito a um suporte sem
                      qualquer custo, com um limite de 2 horas por mês, para
                      esclarecimentos de dúvidas, apresentação de sugestões ou
                      críticas, bem como melhorias que entender necessárias para
                      a evolução dos serviços oferecidos pela CONTRATADA.
                    </p>
                    <p>
                      <b>07.3</b> A não utilização da plataforma no curso da
                      vigência contratual, não eliminará o direito da CONTRATADA
                      de exigir o adimplemento dos valores pactuados pelas
                      partes junto ao quadro resumo.
                    </p>

                    <p>
                      <b>08. RESCISÃO OU SUSPENSÃO DO CONTRATO:</b>
                    </p>
                    <p>
                      <b>08.1</b> No caso de rescisão em contratos denominados
                      “Pré-pagos”, ou seja, com pagamento de forma antecipada,
                      não haverá qualquer devolução nem abatimento dos valores
                      anteriormente pactuados entre as partes.
                    </p>
                    <p>
                      <b>08.2</b> No caso de rescisão em contratos denominados
                      “Pós-pagos”, ou seja, com pagamento posterior à utilização
                      mensal, serão cobrados os dias ou meses proporcionais à
                      utilização do sistema.
                    </p>
                    <p>
                      <b>08.3</b> Em caso de rescisão, a CONTRATADA, se assim
                      desejar, fica autorizada a excluir de imediato todos os
                      dados inseridos pelo CONTRATANTE na plataforma.
                    </p>

                    <p>
                      <b>09. DAS ALTERAÇÕES CONTRATUAIS:</b>
                    </p>
                    <p>
                      <b>09.1</b> Fica desde já definido que eventuais
                      atualizações nos Termos de Uso poderão ser realizadas
                      mediante nova confirmação, à qual será solicitada para o
                      usuário com “perfil gerente” logo após o login feito no
                      sistema.
                    </p>
                    <p>
                      <b>09.2</b> A CONTRATADA deverá ser sempre notificada
                      previamente de forma expressa ou via meio digital, sobre
                      eventual alteração no endereço de e-mail do CONTRATANTE,
                      sob pena de não se caracterizar a ausência de notificação
                      prévia à eventual alteração contratual.
                    </p>

                    <p>
                      <b>10. DAS INTEGRAÇÕES COM SOFTWARES DE TERCEIROS:</b>
                    </p>
                    <p>
                      <b>10.1</b> No caso do CONTRATANTE realizar a contratação
                      de empresas terceirizadas para a prestação de serviços
                      para meios de pagamento, integrações ERP, ou outros
                      softwares que podem vir a se integrar à plataforma, fica
                      facultada a CONTRATADA, aceitação da integração, sendo que
                      a recusa não gera direito reparatório à CONTRATANTE ou a
                      empresa terceirizada.
                    </p>
                    <p>
                      <b>10.2</b> Compete também a parte CONTRATANTE,
                      fiscalização periódica do perfeito funcionamento das
                      integrações, independente do causador da falha de
                      prestação de serviço, excluindo-se também qualquer direito
                      reparatório.
                    </p>
                    <p>
                      <b>10.3</b> Fica reservado o direito da CONTRATADA, que
                      acaso ocorra o aceite de integrações de sistemas, possa
                      cobrar um valor adicional a título de configuração, sempre
                      mediante a aprovação prévia do CONTRANTE.
                    </p>

                    <p>
                      <b>11. DAS DISPOSIÇÕES FINAIS:</b>
                    </p>
                    <p>
                      <b>11.1</b> O CONTRATANTE declara estar ciente de que os
                      serviços oferecidos pela CONTRATADA são fornecidos através
                      da internet, sendo de sua exclusiva responsabilidade
                      aquisição de equipamentos, contratação de provedor e
                      demais atributos técnicos para acesso aos seus serviços.
                    </p>
                    <p>
                      <b>11.2</b> A suspensão de conexão à rede internet do
                      CONTRATANTE não trará qualquer responsabilidade para a
                      CONTRATADA quando da interrupção ou de eventuais danos
                      dela decorrentes.
                    </p>
                    <p>
                      <b>11.3</b> Da mesma maneira, a ocorrência de caso
                      fortuito ou força maior que venham a impedir a CONTRATADA
                      em prestar seus serviços como previstos no presente
                      contrato, são fatores excludentes de sua responsabilidade.
                    </p>
                    <p>
                      <b>11.4</b> A CONTRATADA poderá oferecer descontos
                      promocionais no valor de seus serviços a seu exclusivo
                      critério, sem que em caso de retorno ao preço normal venha
                      a se constituir reajuste ou aumento de preço.
                    </p>
                    <p>
                      <b>11.5</b> O serviço disponibilizado pela CONTRATADA está
                      em permanente processo de aperfeiçoamento. Novas
                      implementações de serviços ou recursos poderão ser
                      gratuitos ou onerosos. Quando gratuitos, implicam em
                      imediata e automática disponibilização aos usuários. No
                      caso de onerosos, dependerão sempre da prévia aceitação do
                      CONTRATANTE
                    </p>
                    <p>
                      <b>11.6</b> Qualquer tolerância ou concessão entre as
                      partes, quando não expressamente manifestada, não motivam
                      precedentes para alteração do presente instrumento.
                    </p>
                    <p>
                      <b>11.7</b> A CONTRATADA reserva-se o direito de, a seu
                      critério, alterar, editar ou deletar qualquer informação,
                      documento ou outro conteúdo de sua titularidade,
                      disponibilizado na plataforma, ou de titularidade dos
                      usuários, caso sejam considerados ofensivos ou contrários
                      a seus preceitos, ou que possam gerar reação negativa do
                      consumidor ou prejudicar sua imagem, sem que assista ao
                      usuário qualquer direito de oposição nem indenização.
                    </p>
                    <p>
                      <b>11.8</b> O serviço da CONTRATADA consiste em fornecer
                      ao CONTRATANTE o acesso a Plataforma, sendo de sua
                      exclusiva responsabilidade os assuntos relativos ao seu
                      negócio, sejam relativos a estratégia de marketing,
                      produção, disponibilização de estoque, controle de
                      qualidade, envio do produto ao cliente, política de
                      devolução, política de privacidade, garantia, pagamento de
                      tributos, indenizações ou obrigações de quaisquer outras
                      naturezas.
                    </p>
                    <p>
                      <b>11.9</b> Além das obrigações previstas nestes Termos de
                      Uso, o usuário deverá: a) Sempre prezar pelo bom uso e
                      funcionamento da plataforma CONECTA VENDA, tendo como
                      referência o comportamento esperado do usuário ao longo de
                      sua interação com o serviço e com os demais usuários; b)
                      respeitar o conteúdo dos outros compartilhadores do
                      sistema, não podendo imitar, plagiar, copiar, reproduzir,
                      apagar, distribuir, transmitir, vender, revender, publicar
                      ou criar obras derivadas de quaisquer produtos
                      disponibilizados por outros usuários; c), não
                      comercializar produtos proibidos por lei ou outros que
                      façam alusão àqueles, nem comercializar produtos regulados
                      sem a devida autorização dos órgãos competentes; d) não
                      manipular os preços dos produtos anunciados; e) nominar as
                      características essenciais do produto ou do serviço,
                      incluídos, caso existam, os riscos à saúde e à segurança
                      dos consumidores, conforme definido junto a Lei n°.
                      8.078/90.
                    </p>
                    <p>
                      <b>11.10</b> Os CONTRATANTES declaram que ao utilizarem os
                      serviços disponibilizados pela CONTRATADA, serem maiores
                      de 18 anos e em pleno exercício de sua capacidade para a
                      prática de atos civis no caso de pessoas físicas e,
                      estarem devidamente representadas por seus
                      sócios-administradores ou outros representantes legais, no
                      caso de pessoas jurídicas.
                    </p>
                    <p>
                      <b>11.11</b> Da mesma forma é obrigação dos CONTRATANTES
                      realizar o cadastro com informações verdadeiras e procurar
                      mantê-las atualizadas e revisadas sempre que possível e,
                      em caso de qualquer utilização não autorizada em sua conta
                      ou qualquer quebra de segurança, deverá comunicar
                      prontamente à CONTRATADA.
                    </p>
                    <p>
                      <b>11.12</b> Em caso de descumprimento das obrigações aqui
                      previstas, o CONTRATANTE fica sujeito à suspensão ou
                      cancelamento do anúncio do produto ou do serviço
                      disponibilizado junto a plataforma da CONTRATADA, podendo,
                      conforme a gravidade, ocorrer à suspensão ou cancelamento
                      de seu cadastro.
                    </p>
                    <p>
                      <b>11.13</b> A CONTRATADA não assume nenhuma
                      responsabilidade pela identidade de qualquer dos
                      CONTRATANTES, tampouco sobre quaisquer questões relativas
                      aos produtos e serviços por esses comercializados.
                    </p>
                    <p>
                      <b>11.14</b> A CONTRATADA não será, em hipótese alguma,
                      responsável pelo conteúdo veiculado junto à plataforma,
                      seja escrito ou em formato de imagem, ou, ainda, por
                      terceiros a partir de seus logins e senhas.
                    </p>
                    <p>
                      <b>11.15</b> O CONTRATANTE fica obrigado, em caso de a
                      CONTRATADA ser incluída em demanda judicial ou
                      administrativa, movida por clientes diretos daquele, a
                      demandar sua imediata exclusão do polo passivo da demanda,
                      assumindo integral responsabilidade pelo possível litígio
                      ou processo.
                    </p>
                    <p>
                      <b>11.16</b> Sempre que o pedido de exclusão da CONTRATADA
                      do polo passivo de possível demanda judicial ou
                      administrativa não lograr êxito, ou o CONTRATANTE se
                      furtar à obrigação de requerer sua exclusão, este será
                      responsável por quaisquer custos advindos da contratação
                      de advogados para exercício do direito de defesa e, por
                      eventual condenação que recaia sobre a mesma, devendo
                      neste caso, ser indenizada em sua totalidade, incluindo
                      honorários advocatícios contratuais e sucumbenciais.
                    </p>
                    <p>
                      <b>11.17</b> A CONTRATADA tomará medidas visando prevenir
                      a introdução de vírus ou outros materiais destrutivos
                      junto a plataforma, mas não garante que o acesso ao
                      software esteja completamente livre de erros, ataques de
                      hackers, script ou outros quaisquer que impeçam a
                      visualização correta do conteúdo disponibilizado na
                      plataforma, isentando-se a CONTRATADA de qualquer
                      responsabilidade por danos diretos ou indiretos
                      ocasionados.
                    </p>
                    <p>
                      <b>11.18</b> A CONTRATADA trabalha para manter a
                      plataforma em pleno funcionamento, sem, entretanto,
                      prestar qualquer garantia de funcionamento ininterrupto,
                      nem tampouco assegurar que estará disponível a qualquer
                      momento ou imune a erros de conexão ou problemas
                      tecnológicos na internet sobre os quais não possui
                      qualquer interferência ou poder de auxiliar na reparação,
                      ficando assim, isenta de possíveis ações indenizatórias.
                    </p>
                    <p>
                      <b>11.19</b> A CONTRATADA reserva-se o direito de
                      restringir a disponibilidade da plataforma ou recursos a
                      ela relacionados caso seja necessário para integridade de
                      seus servidores ou em caso de manutenção ou por melhoria
                      no funcionamento da plataforma.
                    </p>
                    <p>
                      <b>11.20</b> A CONTRATADA adota todos os meios visando a
                      proteção dos arquivos coletados, porém, pelas
                      características técnicas da internet, o CONTRATANTE
                      declara ter ciência de que ainda assim existe risco de que
                      terceiros não autorizados venham a violar tais mecanismos
                      de proteção e obtenham acesso a essas informações, motivo
                      pelo qual fica desobrigada de possível responsabilidade ou
                      reparação por eventual perda definitiva destes dados.
                    </p>
                    <p>
                      <b>11.21</b> O CONTRATANTE declara estar ciente que todo o
                      suporte decorrente de problemas na plataforma será
                      realizado através dos telefones constantes junto ao
                      website da CONTRATADA.
                    </p>
                    <p>
                      <b>11.22</b> Para ter total acesso a plataforma, o
                      CONTRATANTE deverá fornecer informações pessoais para
                      efetivação cadastral, e para tal declara que as
                      informações fornecidas são verdadeiras, devendo sempre
                      mantê-las atualizadas.
                    </p>
                    <p>
                      <b>11.23</b> As partes contratantes elegem o Foro de
                      Guaporé/RS, para dirimir todas as questões resultantes
                      deste contrato.
                    </p>
                  </div>
                  <div className="rodape">
                    <div
                      className="concordar"
                      onClick={() => setConcordoTermos(!concordoTermos)}
                    >
                      <div className="checkboxBonito">
                        <input
                          type="checkbox"
                          checked={concordoTermos}
                          onChange={() => setConcordoTermos(!concordoTermos)}
                        ></input>
                      </div>
                      <div className="textoConcordo">Concordo</div>
                    </div>
                    <Button
                      title="Aceitar e continuar"
                      onClick={() =>
                        onAceitarTermosDeUso(concordoTermos, plano)
                      }
                    />
                  </div>
                </div>
              </ModalTermosUsoContainer>
            </>
          )}
        </ModalTermosUsoWrapper>
      </Modal>
    </>
  );
};

export default ModalTermosDeUso;
