import moment from "moment";
import { Crop } from "react-image-crop";
import Swal from "sweetalert2";

export function generateHashCode(numberOfCharacters: number): string {
    let characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let hash = "";
    for (let i = 0; i < numberOfCharacters; i++) {
        hash += characters.charAt(
            Math.floor(Math.random() * characters.length),
        );
    }
    return hash;
}

export function limparConfigsLocalStorage() {
    localStorage.removeItem("configuracoes");
    localStorage.removeItem("fretes_gratis");
    localStorage.removeItem("fretes_especiais");
    localStorage.removeItem("politica_de_privacidade");
    localStorage.removeItem("configuracoesAuxiliar");
    localStorage.removeItem("fretes_gratisAuxiliar");
    localStorage.removeItem("fretes_especiaisAuxiliar");
    localStorage.removeItem("indexModais");
    localStorage.removeItem("paramsHistory");
}

export function staginEnv() {
    return window.location.hostname.includes("dev") ? "?env=staging" : "";
}

export function isHorarioComercial() {
    const data = moment();
    const rangeHoras = [
        { start: "07:30", end: "11:45" },
        { start: "13:00", end: "17:30" },
    ];

    if (data.day() !== 0 && data.day() !== 6) {
        for (let range of rangeHoras) {
            if (data.isBetween(moment(range.start, "HH:mm"), moment(range.end, "HH:mm"))) {
                return true;
            }
        }
    }

    return false;
}


export function rgba(hex, alpha) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function boolean(value: any) {
    return value === "true" || value === true || value === 1 || value === "1";
}

export function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : "#000000";
}

export function swallDadosSalvos() {
    Swal.fire({
        title: "Sucesso!",
        text: "Dados alterados com sucesso!",
        icon: "success",
        timer: 1250,
        showConfirmButton: false,
    });
}

export function obterMiniaturaVideoYouTube(link) {
    // Pega Thumbnail do vídeo do YouTube
    let miniaturaVideo = link.split("/")[3];
    miniaturaVideo = miniaturaVideo.split("=");
    // Verifica se link veio do mobile (que é diferente)
    miniaturaVideo =
        miniaturaVideo.length == 2 ? miniaturaVideo[1] : miniaturaVideo[0];
    miniaturaVideo = "https://img.youtube.com/vi/" + miniaturaVideo + "/0.jpg";
    return miniaturaVideo;
}



export function formatPhone(phone: string): string {
    // Remove tudo que não for dígito do número de telefone
    const cleaned = phone.replace(/\D/g, "");

    // Limita a string limpa a 11 dígitos
    const limited = cleaned.substring(0, 11);

    // Verifica se o número de telefone tem 10 ou 11 dígitos
    const length = limited.length;
    if (length === 10) {
        // Aplica a máscara para um número de telefone com 10 dígitos
        return limited.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else if (length === 11) {
        // Aplica a máscara para um número de telefone com 11 dígitos
        return limited.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else {
        // Retorna o número de telefone original se ele não tiver 10 ou 11 dígitos
        return phone;
    }
}

export const formatCurrency = ({ nextState }) => {
    const { value } = nextState || {};

    let amountFormatted = value?.replace?.(/\D/g, "");
    amountFormatted = amountFormatted?.replace?.(/^0+/g, "");

    if (amountFormatted?.length === 2) {
        return {
            ...nextState,
            value: `R$ ${amountFormatted}`,
            selection: {
                start: amountFormatted.length + 3,
                end: amountFormatted.length + 3,
            },
        };
    }

    const amountFormattedWithComma = amountFormatted?.replace?.(
        /(?=\d{2})(\d{2})$/,
        ",$1",
    );
    const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1.",
    );

    if (amountFormattedWithDot) {
        return {
            ...nextState,
            value: `R$ ${amountFormattedWithDot}`,
            selection: {
                start: amountFormattedWithDot.length + 3,
                end: amountFormattedWithDot.length + 3,
            },
        };
    }

    return nextState;
};
export function nacionalPhoneMask(phone) {
    phone = phone.replace(/\D/g, '');

    if (phone.length > 4) {
        phone = phone.substring(0, 0) + ' ' + phone.substring(2, 4) + ' ' + phone.substring(4, 9) + '-' + phone.substring(9);
    }

    return phone;
}


export const phoneMask = (value: string) => {
    const digitsOnly = value.replace(/\D+/g, "");
    if (digitsOnly.length === 0) {
        return "";
    }
    const formatted = digitsOnly
        .replace(/^(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})$/, "$1$2$3$4")
        .replace(/^(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4,5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");
    return formatted;
};

export function copy(o) {
    let output, v, key;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
        v = o[key];
        output[key] = typeof v === "object" ? copy(v) : v;
    }
    return output;
}

export function deepCopy(o, propertiesToMerge = {}) {
    let output, v, key;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
        v = o[key];
        output[key] =
            typeof v === "object" && !Array.isArray(v) ? deepCopy(v) : v;
    }
    for (key in propertiesToMerge) {
        v = propertiesToMerge[key];
        output[key] =
            typeof v === "object" && !Array.isArray(v) && output[key]
                ? deepCopy(output[key], v)
                : v;
    }
    return output;
}

export function isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
}

function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

export function deepMerge(object1, object2) {
    for (let key in object2) {
        // If key is not present in object1, assign it the value from object2
        if (!(key in object1)) {
            object1[key] = object2[key];
        } else if (
            typeof object1[key] === "object" &&
            typeof object2[key] === "object"
        ) {
            // If the key is an object in both object1 and object2, recurse
            deepMerge(object1[key], object2[key]);
        }
    }
}

export function removeAccents(string: string) {
    const hexAccentsMap = {
        a: /[\xE0-\xE6]/g,
        A: /[\xC0-\xC6]/g,
        e: /[\xE8-\xEB]/g,
        E: /[\xC8-\xCB]/g,
        i: /[\xEC-\xEF]/g,
        I: /[\xCC-\xCF]/g,
        o: /[\xF2-\xF6]/g,
        O: /[\xD2-\xD6]/g,
        u: /[\xF9-\xFC]|ü/g,
        U: /[\xD9-\xDC]|Ü/g,
        c: /\xE7/g,
        C: /\xC7/g,
        n: /\xF1/g,
        N: /\xD1/g,
    };

    // ensure that it's a string
    string = String(string);
    for (let character in hexAccentsMap) {
        let regex = hexAccentsMap[character];
        string = string.replace(regex, character);
    }
    return string;
}

export function cleanToFilter(string) {
    return removeAccents(string)
        .toLocaleLowerCase()
        .trim()
        // .replace(/[^a-zA-Z0-9 ]/g, "")
        // .replace(/\b(\w+)\s+\1\b/g, "$1")
        // .replace(/(.)\1+/g, "$1");
}


// IMAGE HANDLING ---------------------------------------------------------------
// REMOVE ACCENTS, ESPECIAL CHARACTERS, SPACES
const cleanFileReaderName = (string: string): string => {
    // remove accents
    const hexAccentsMap: { [key: string]: RegExp } = {
        a: /[\xE0-\xE6]/g,
        A: /[\xC0-\xC6]/g,
        e: /[\xE8-\xEB]/g,
        E: /[\xC8-\xCB]/g,
        i: /[\xEC-\xEF]/g,
        I: /[\xCC-\xCF]/g,
        o: /[\xF2-\xF6]/g,
        O: /[\xD2-\xD6]/g,
        u: /[\xF9-\xFC]|ü/g,
        U: /[\xD9-\xDC]|Ü/g,
        c: /\xE7/g,
        C: /\xC7/g,
        n: /\xF1/g,
        N: /\xD1/g,
    };

    string = String(string);
    for (const character in hexAccentsMap) {
        const regex = hexAccentsMap[character];
        string = string.replace(regex, character);
    }
    // remove spaces and specials
    return string.replace(/ |'|"|!|@|#|\$|%|¨|&|\*|\(|\)|=/gi, "");
};

const validateSize = (
    result: string | ArrayBuffer,
    minWidth?: number,
    minHeight?: number,
    maxWidth?: number,
    maxHeight?: number,
    errorMargin?: number,
    aspectRatio?: number,
): Promise<
    "TAMANHO_IDEAL" | "TAMANHO_INVALIDO" | "PROPORCAO_INVALIDA" | "PODE_CROPAR"
> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            if (!errorMargin) errorMargin = 0;
            if (
                minWidth &&
                Math.abs(image.width - minWidth) <= errorMargin &&
                minHeight &&
                Math.abs(image.height - minHeight) <= errorMargin
            ) {
                resolve("TAMANHO_IDEAL"); //imagem ja esta no tamanho ideal
            } else if (
                aspectRatio &&
                image.width / image.height !== aspectRatio
            ) {
                resolve("PROPORCAO_INVALIDA");
            } else if (
                (minWidth && image.width < minWidth) ||
                (minHeight && image.height < minHeight) ||
                (maxWidth && image.width > maxWidth) ||
                (maxHeight && image.height > maxHeight)
            ) {
                resolve("TAMANHO_INVALIDO");
            } else {
                resolve("PODE_CROPAR");
            }
        };
        image.onerror = () => {
            reject("ERRO_AO_TESTAR_TAMANHO");
        };
        image.src = result as string;
    });
};

// PROMISE FILEREADER, resolves async
interface PromiseFileReaderOptions {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    errorMargin?: number;
    aspectRatio?: number;
}
export interface FileReaderResult {
    base64: string;
    imagem: string;
    size: number;
    consideration?:
        | "TAMANHO_IDEAL"
        | "PODE_CROPAR"
        | "TAMANHO_INVALIDO"
        | "PROPORCAO_INVALIDA";
}
export const PromiseFileReader = (
    file: File,
    options: PromiseFileReaderOptions = {},
): Promise<FileReaderResult> =>
    new Promise((resolve, reject) => {
        const {
            minWidth,
            maxWidth,
            minHeight,
            maxHeight,
            errorMargin,
            aspectRatio,
        } = options;
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                const fileName = cleanFileReaderName(file.name);
                if (minWidth || maxHeight || maxWidth || minHeight) {
                    validateSize(
                        reader.result!,
                        minWidth,
                        minHeight,
                        maxWidth,
                        maxHeight,
                        errorMargin,
                        aspectRatio,
                    )
                        .then((res) => {
                            resolve({
                                base64: reader.result as string,
                                imagem: fileName,
                                size: file.size,
                                consideration: res,
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    resolve({
                        base64: reader.result as string,
                        imagem: fileName,
                        size: file.size,
                    });
                }
            },
            false,
        );

        reader.addEventListener(
            "error",
            () => {
                reject("ERROR_FILE_READER");
            },
            false,
        );

        reader.readAsDataURL(file);
    });

export function isRunningInPWA() {
    const isIOSStandalone = ('standalone' in window.navigator) && (window.navigator.standalone);

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    return isIOSStandalone || isStandalone;
}

export function isIosSafari(): boolean {
    const userAgent = window.navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    
    const isNotEdge = !(window as any).MSStream;

    return isIOS && isSafari && isNotEdge;
}

export function getCroppedImg(
    image: HTMLImageElement,
    crop: Crop,
    extension: string,
    resize?: { width: number; height: number },
) {
    const cropX = image.naturalWidth * (crop.x! / 100);
    const cropY = image.naturalHeight * (crop.y! / 100);
    const cropWidth = image.naturalWidth * (crop.width! / 100);
    const cropHeight = image.naturalHeight * (crop.height! / 100);

    const canvas = document.createElement("canvas");
    canvas.width = (resize && resize.width < cropWidth) ? resize.width : cropWidth;
    canvas.height = (resize && resize.height < cropHeight) ? resize.height : cropHeight;

    const ctx = canvas.getContext("2d")!;

    ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        canvas.width,
        canvas.height,
    );

    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    console.error("Canvas is empty");
                    reject(new Error("Canvas is empty"));
                } else {
                    resolve(blob);
                }
            },
            `image/${extension}`,
            "1",
        );
    });
}

export class Semaphore {
    private tasks: (() => void)[] = [];
    count: number;

    constructor(max: number) {
        this.count = max;
    }

    async acquire() {
        return new Promise((resolve) => {
            const task = () => {
                if (this.count > 0) {
                    this.count--;
                    resolve("");
                } else {
                    this.tasks.push(task);
                }
            };
            task();
        });
    }

    release() {
        this.count++;
        if (this.tasks.length > 0) {
            const nextTask = this.tasks.shift();
            if (nextTask) {
                nextTask();
            }
        }
    }
}
