import styled, { css } from "styled-components";

const commonStyles = css`
  width: 100rem;
  max-width: 90vw;
  .informacoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50rem;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
    position: relative;
    padding-bottom: 5rem;
    .tituloContainer{
      .titulo{
        padding: 0;
      }
    }
    > .subtitulo {
      color: ${(props) => props.theme.colors.brandPrimary};
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 40rem;
      height: 2px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-color: ${(props) => props.theme.colors.elements};
      border-radius: 50px;
    }
  }

  > .toggleContainer {
    display: flex;
    color: ${(props) => props.theme.colors.brandSecondary};
    font-size: 2.5rem;
    margin-top: 5rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
  }
`;

export const ContainerVisualContainer = styled.div<{
  configIn: "empresa" | "configuracoes";
}>`
  ${commonStyles};
  ${({ configIn }) =>
    configIn === "configuracoes" &&
    css`
      width: 100%;
      max-width: 100%;
      .informacoes {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
        padding: 2rem 0rem;
        width: 100%;
        max-width: 100%;
        .subtitulo {
          display: none;
        }
        &::after {
          display: none;
        }
      }
      .toggleContainer {
        margin: 0;
        padding: 0 0 0 0rem;
        justify-content: flex-start;
      }
    `};
`;
